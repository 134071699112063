<template>
  <div class="supplier-content-wrapper driver-content-wrapper">
    <div class="driverHeader" 
        style="background:none !important;
               display:block !important;
               padding-bottom:0px">
        <div class="classHeadTop">
             <router-link 
                class="gobacknow" 
                to="/drivers/01">
                <img 
                    width="6.5px" 
                    src="@/assets/icons/arrowLeft.svg">
                Go Back
            </router-link>
        </div>

        <v-toolbar 
                class="containerdetailheader">
				<v-toolbar-title v-if="completed"
                    class="titleheader">                   
                    <span 
                        class="nameheader" 
                        style=" font-size:24px;
                                width: auto;
                                margin-right:12px;
                                display:inline-block;
                                color:#4A4A4A">
                                <span @click="showLegs">                              
                                </span> {{driver_name}}
                    </span>                         
                    <template>
                        <span v-if="getLocFrom !== 'notactive'">
                          <span 
                              class="connectedDriver commonDriverStatus" 
                              v-if="connectedStatus==1"
                              >
                              Connected
                          </span>
                          <span 
                              class="notconnectedDriver commonDriverStatus" 
                              v-else
                              >
                              Not Connected
                          </span>
                        </span>
                        <span v-else>
                          <span
                              class="notactiveDriver commonDriverStatus" 
                              v-if="activatedStatus"
                              >
                              Activated
                          </span>
                          <span
                              class="notactiveDriver commonDriverStatus" 
                              v-else
                              >
                              Deactivated
                          </span>
                        </span>
                        <span                                    
                            style="background:none !important;"                                   
                            class="doprodownreport"                                   
                        >  
                        <span                    
                            :class="checktheclass()">
                            <span 
                                class="scheduletext">
                                Notifications
                            </span> 
                            <span
                                class="ON_text">                                      
                                {{onAndOffText()}}
                            </span>                        
                            <span>                             
                                <v-menu 
                                    :nudge-width="138"							
                                    nudge-left="126"
                                    nudge-top="-10"
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">                             
                                        <span 
                                        v-bind="attrs"
                                        v-on="on"
                                        style="position:relative;
                                            top:4.1px;">
                                        <img src="@/assets/icons/Chevron Down2.svg">                      
                                        </span> 
                                    </template>
                                    <v-list>
                                        <div class="dropdownreport">
                                            <ul class="listmenu">
                                                <li @click="ONandOFF" style="text-align:center"
                                                    >
                                                    <span                                                                
                                                        class="headtextlist sendreport">
                                                        {{onAndOffDropdown()}}
                                                    </span>
                                                </li>                                                     
                                            </ul>
                                        </div>
                                    </v-list>
                                </v-menu>
                            </span>                  
                        </span>
                        </span>                
                    </template>
                </v-toolbar-title>
				<v-spacer></v-spacer>
                <v-btn 
                    color="primary" 
                    class="btn-white" 
                    style="margin-right:6px;"
                    @click="sendEmail"
                    >
                    <img style="margin-right:4px;" 
                    src="@/assets/icons/editpencil.svg">
                    Send Email
                </v-btn> 
                <v-menu offset-y nudge-width="130" nudge-left="130">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="btn-white">
              <img src="@/assets/icons/more-blue.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item style="padding-left:10px" class="trucking_DropDown">              
              <v-list-item-title v-if="getLocFrom !== 'notactive'"
                @click="runAction(connectedStatus ? 'Disconnect' : 'Connect')"
                class=""               
                >               
                 {{connectedStatus ? 'Disconnect' : 'Connect'}}
               </v-list-item-title>
               <v-list-item-title
                 class=""    
                 @click="runAction(activatedStatus ? 'Deactivate' : 'Activate')"           
                >                 
                 {{dropdownStatus}}           
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>              
        </v-toolbar> 
        <div v-if="completed"
               
                class="Headerbottomtext"
                style="background:none !important;
                        position:relative;
                        top: -15px;
                        font-size:12px">
                    <span 
                        class="reportscheduletag"
                        >
                        <span style="color:#6D858F;
                                     font-style: normal;
                                     font-weight: 600;
                                     font-size: 12px;">DRIVER ID</span>
                                     <span class="shiflkey">{{ driver_id !=='' || driver_id !=null ? '#'+driver_id : '' }}</span>
                    </span>
                    <span 
                        class="telephoneholder">
                        <img src="@/assets/icons/phone.svg"> 
                        <span class="shiflkey">{{phone_number}}</span>
                    </span>
                    <span
                        class="emailholder">
                        <img src="@/assets/icons/email.svg"> 
                        <span class="shiflkey">{{getemailsdata}}</span>                       
                    </span>
             </div>
    </div>

       <v-data-table
            :headers="headers"
            mobile-breakpoint="400"
            :items="drivers"
            class="suppliers-table driver-table elevation-1"
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
        >
        <template v-slot:[`header.name`]="{ header }">
            {{ header.text }}<i class="classsort"></i>
        </template>
        <template v-slot:top>
            <v-toolbar flat>
                <v-tabs>
                    <v-tab>Schedule History</v-tab>                  
                </v-tabs>
                                <!-- <Tab 
                :pageCurrent="pageCurrent"
                @currentPage="currenttab"                  
                />
                <v-spacer></v-spacer>
                <Search
                    placeholder="Search Drivers"
                    className="search custom-search"
                    :inputData.sync="search"
                /> -->
            </v-toolbar>
        </template>
      
      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">{{ item.name !== "" ? item.name + " " + item.last_name : "--" }}</p>
      </template>

      <template v-slot:[`item.shiflcontainer`]="{ item }">
        <p  
          v-html="containers(item)"          
          class="mb-0">           
        </p>        
      </template>

      <template v-slot:[`item.pickup_scheduled_date_time`]="{ item }">
        <p  
            v-html="pickupon(item)"           
            class="mb-0">          
        </p>
      </template>

      <template v-slot:[`item.delivery_scheduled_date_time`]="{ item }">
        <p 
          class="mb-0"
          v-html="deliveryon(item)" 
          >
        </p>
      </template>

      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="getDriverLoading">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
            v-if="getDriverLoading"
          >
          </v-progress-circular>
        </div>
      </template>
    </v-data-table>

    <Pagination
      v-if="(typeof drivers !='undefined')"
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    />

    <Dialog     
      :dialogData.sync="dialogHolder"  
      :driverID="driverID"  
      :bus="bus"
      :fetchCompleted.sync="fetchCompleted"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "../../Pagination.vue";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL
import Dialog from "./dialog/driverHolder.vue";
import moment from "moment";
//import _ from "lodash";
export default {
  name: "DriversDesktopTable",
  props: ["items", "isMobile", "itemData", "reloadcomponent",'bus'],
  components: { 
    Pagination,
    Dialog
  },
  data: () => ({
    completed: 0,
    labelnotification:'',
    fetchCompleted:0,
    driverID:0,
    dialogHolder:false,
    activatedStatus:0,
    connectedStatus:0,
    phone_number: '',
    driver_name:'',
    getemailsdata: '',
    driver_id:'',
    details: {
        id:'',
        name: '',
        trucker_id: '',
        email: '',
        lat: '',
        long: '',
        user_id:'',
        phone_number:'',
        last_name:'',
        status: 0,
        driver: {        
        }
      },
    datapass: [],
    getphone: '09109916799',
  
    page: 1,
    pageCount: 0,
    itemsPerPage: 8,
    thecurrent:{page:0},
    startfetching: false,    
    DriverData: [],
    search: "",
    //the_checkclass:'off_value',
      the_checkclass: 'reportscheduletag',
    ON_TEXT:'ON',
    headers: [     
      {
        text: "Schedule ID",
        align: "start",
        value: "unique_id",
        sortable: true,
        width: "10%",
        fixed: true,
      },
     {
        text: "Container/Ref#",
        align: "start",
        value: "shiflcontainer",
        sortable: false,
        width: "10%",
        fixed: true,
      },
      {
        text: "Pickup On",
        align: "start",
        value: "pickup_scheduled_date_time",
        sortable: false,
        width: "19%",
        fixed: true,
      },    
       {
        text: "Delivery On",
        align: "start",
        value: "delivery_scheduled_date_time",
        sortable: false,
        width: "19%",
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      getDrivers:"drivers/getDrivers",
      getDriverLoading:"drivers/getDriverLoading",
      getBackState:"drivers/getBackState",
      getNotificationStatus:"drivers/getNotificationStatus",     
      getpassData:"drivers/getpassData", 
      getLocFrom: "drivers/getLocFrom",
      getNoficationResult: "drivers/getNoficationResult",
      getDetailsNotification: "drivers/getDetailsNotification",
      getDriverLegShipment: "drivers/getDriverLegShipment"
    }),
    dropdownStatus:{
      get(){        
        return this.activatedStatus == 1 ? 'Deactivate' : 'Reactivate Account'     
      }
    },
    drivers: {
      get() {
        return this.getDriverLoading ? [] : this.getDriverLegShipment.drivers;
      },
      set(value) {
        if(value) return [];
      },
    },
  },
  watch:{
    fetchCompleted(c){
      if(c){
        this.connectedStatus = c 
      }
    }
  },
  created() {},
  methods: {
    ...mapActions({
      fetchDrivers: "drivers/fetchDrivers",
      saveNotification: "drivers/putNotificationStatus",
      detailNotification: "drivers/detailNotification",
      fetchLegShipment: "drivers/FetchDriverWithLegAndShipment",
    }),  
    async sendEmail(){       
      await axios
        .post(`${poBaseUrl}/sendEmailDriver`,{driverID:this.driverID})
        .then(function (response) {         
            if (response) {
                console.log(response)
            }
        }).catch(error => {
            console.log(error)
        })
    } ,
    containers(item){
     return '<div>'+item.shipment.trucker_container +'</div>'+'<div>'+item.shipment.shifl_ref +'</div>'
    },
    pickupon(item){
      var svg = '<svg style="position: relative;top:3px;margin-right:5px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                '<path d="M8.00005 0.799988C11.9766 0.799988 15.2 4.02415 15.2 7.99999C15.2 11.9758 11.9766 15.2 8.00005 15.2C4.02349 15.2 0.800049 11.9758 0.800049 7.99999C0.800049 4.02415 4.02349 0.799988 8.00005 0.799988ZM8.00005 2.00023C4.69165 2.00023 2.00029 4.69159 2.00029 7.99999C2.00029 11.3084 4.69165 13.9997 8.00005 13.9997C11.3084 13.9997 13.9998 11.3084 13.9998 7.99999C13.9998 4.69159 11.3084 2.00023 8.00005 2.00023ZM7.46005 3.67999C7.73329 3.67999 7.95934 3.88327 7.99512 4.14675L8.00005 4.21999V7.99999H10.34C10.6381 7.99999 10.88 8.24191 10.88 8.53999C10.88 8.81323 10.6768 9.03928 10.4133 9.07506L10.34 9.07999H7.46005C7.18681 9.07999 6.96076 8.87671 6.92498 8.61323L6.92005 8.53999V4.21999C6.92005 3.92191 7.16197 3.67999 7.46005 3.67999Z" fill="#819FB2"/></svg>';
      if(item){
        if(item.arrival_at_pickup_location_date_time == null && item.arrival_at_delivery_location_date_time !=null){
          return '<div style="color:#4A4A4A">'+item.address +'</div>'+
                 '<div style="color:#6D858F">'+moment(item.arrival_at_delivery_location_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'
        }else if(item.arrival_at_delivery_location_date_time == null && item.arrival_at_pickup_location_date_time ==null){
            return  '<div>'+item.address +'</div>'+
                    '<div style="color:#6D858F"><div class="tooltip">'+svg+'<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>' + moment(item.pickup_scheduled_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'         
        }else if(item.arrival_at_pickup_location_date_time !== null && item.arrival_at_delivery_location_date_time ==null){
              return '<div style="color:#4A4A4A">'+item.address +'</div>'+
                 '<div style="color:#6D858F">'+moment(item.arrival_at_pickup_location_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'
        }
      } 
    },
    deliveryon(item){
      var svg = '<svg style="position: relative;top:3px;margin-right:5px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                '<path d="M8.00005 0.799988C11.9766 0.799988 15.2 4.02415 15.2 7.99999C15.2 11.9758 11.9766 15.2 8.00005 15.2C4.02349 15.2 0.800049 11.9758 0.800049 7.99999C0.800049 4.02415 4.02349 0.799988 8.00005 0.799988ZM8.00005 2.00023C4.69165 2.00023 2.00029 4.69159 2.00029 7.99999C2.00029 11.3084 4.69165 13.9997 8.00005 13.9997C11.3084 13.9997 13.9998 11.3084 13.9998 7.99999C13.9998 4.69159 11.3084 2.00023 8.00005 2.00023ZM7.46005 3.67999C7.73329 3.67999 7.95934 3.88327 7.99512 4.14675L8.00005 4.21999V7.99999H10.34C10.6381 7.99999 10.88 8.24191 10.88 8.53999C10.88 8.81323 10.6768 9.03928 10.4133 9.07506L10.34 9.07999H7.46005C7.18681 9.07999 6.96076 8.87671 6.92498 8.61323L6.92005 8.53999V4.21999C6.92005 3.92191 7.16197 3.67999 7.46005 3.67999Z" fill="#819FB2"/></svg>';
      if(item){
        if(item.arrival_at_pickup_location_date_time == null && item.arrival_at_delivery_location_date_time !=null){
            return '<div style="color:#4A4A4A">'+item.address +'</div>'+
                   '<div style="color:#6D858F">'+moment(item.arrival_at_delivery_location_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'
        }else if(item.arrival_at_delivery_location_date_time == null && item.arrival_at_pickup_location_date_time ==null){
            return '<div style="color:#4A4A4A">'+item.address +'</div>'+
                   '<div style="color:#6D858F"><div class="tooltip">' +svg+
                   '<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>'+ moment(item.delivery_scheduled_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'
        }else if(item.arrival_at_pickup_location_date_time !== null && item.arrival_at_delivery_location_date_time ==null){
            return '<div style="color:#4A4A4A">'+item.address +'</div>'+
                   '<div style="color:#6D858F"><div class="tooltip">' + svg +'<span class="tooltiptextdriver tooltip-top">Scheduled</span></div>'+ moment(item.delivery_scheduled_date_time).utc(false).format("H:MA, MMM D, YYYY")+'</div>'
        }
      }
    },
    async ONandOFF(){
      await this.saveNotification( this.$route.params.id );
      if(this.getNoficationResult=='completed'){
        await this.detailNotification({id:this.$route.params.id})
      }
    },
    async runAction(action){          
      if(action=='Connect'){
        this.connectDriver(this.driverID);
      }else{
        let url = (action == 'Disconnect') ? 'disconnectDriver' : ((action === 'Deactivate') ? 'deactivateDriver' : 'activateDriver' )
        this.connectedStatus = ((action === 'Disconnect') ? 0 : 1)
        this.activatedStatus = ((action === 'Deactivate') ? 0 : 1)
        await axios
        .put(`${poBaseUrl}/`+url + '/' + this.driverID
        )
        .then(function (response) {         
          if (response) {
            console.log(response)
          }
        }).catch(error => {
            console.log(error)
        })
        }
    },
    async showLegs(){          
      await axios
        .get(`${poBaseUrl}/getDriverDispatch`
        )
        .then(function (response) {         
            if (response) {
                console.log(response)
            }
        }).catch(error => {
            console.log(error)
        })
    },
    getcurrentpage(current){
      this.thecurrent = { page:-1 }    
      this.$nextTick(() => {
        this.thecurrent = {page:current}
      });     
    },        
    checktheclass(){
       return (this.getDetailsNotification.state == 1) ? 'reportscheduletag' : 'off_value'
    },
    onAndOffText(){
       return (this.getDetailsNotification.state == 1) ? 'ON' : 'OFF'
    },
    onAndOffDropdown(){
       return (this.getDetailsNotification.state == 1) ? 'Turn Off' : 'Turn On'
    },  
    connectDriver(id){
      this.dialogHolder = true;
      this.driverID = id;
    }, 
  },
  async mounted(){ 
    await this.fetchLegShipment({id:this.$route.params.id});
    let datapass = this.getpassData; 
    //let getLocFrom = this.getLocFrom;
    
    // notactive == getLocFrom;
    let dis = this;
    
    //getpassData.name
    if(datapass.length < 1 ){
      let url = 'getsingleDriverByID';   
      await this.detailNotification({id:this.$route.params.id}) 
      await axios
      .get(`${poBaseUrl}/` + url + '/' + this.$route.params.id
      )
      .then(function (response) {         
          if (response) {
            if(typeof response.data.drivers !='undefined'){            
              dis.driver_name = response.data.drivers[0].name
              dis.driver_id =  response.data.drivers[0].driver.dah_id
              dis.phone_number = response.data.drivers[0].phone_number
              dis.getemailsdata = response.data.drivers[0].email
              dis.driverID = response.data.drivers[0].id

              if(response.data.drivers[0].driver != null){
                dis.connectedStatus = 1 // connected
              }else if(response.data.drivers[0].driver == null){
                dis.connectedStatus = 0 // disconnected 
              }        

              if(response.data.drivers[0].status){
                dis.activatedStatus = 1 // reverse action
              }else{
                dis.activatedStatus = 0 // reverse
              }
            }
          }       
      }).catch(error => {
        console.log(error)
      })
    } else {      
        dis.driverID = datapass.name.id
        dis.driver_name  = datapass.name 
        dis.driver_id    = datapass.driver.dah_id
        dis.phone_number = datapass.phone_number
        dis.getemailsdata= datapass.email  
       
        if(datapass.driver !=null){
          dis.connectedStatus = 1   // connected
        }else{
          dis.connectedStatus = 0   // disconnected 
        }
       
        // check if deactivated or activated
        if(datapass.status){
          dis.activatedStatus = 1 // activated
        }else{
          dis.activatedStatus = 0 // deactivated
        }
    }

    this.ON_TEXT = (this.getDetailsNotification.state == 1) ? 'ON' : 'OFF'
    this.labelnotification = (this.getDetailsNotification.state == 1) ? 'Turn Off' : 'Turn On'
    this.completed = 1
    //set current page
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
<style>
.classsort {
	background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
	margin-left: 4px;
	width: 21px;
	height: 17px;
	display: block;
	position: relative;
	top: 3px;
	left: 1px;
	float: right;
}

.assignUnassignFilter{
  display: flex;
  border: 1px solid #B4CFE0;
  border-radius: 5px;
  margin: 0;
  margin-left: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0px;
  caret-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.assignUnassignFilter div.active {
  background-color: #EBF2F5 !important;
  color: #0171A1;
}

.assignUnassignFilter div:first-child {
  padding: 8px 10px 8px 9px !important;
  border-radius: 5px 0 0 5px !important;
}

.assignUnassignFilter div:hover {
  cursor: pointer;
  background-color: #EBF2F5 !important;
}

.assignUnassignFilter div {
  padding: 8px 10px 8px 12px !important;
  border-right: 1px solid #B4CFE0;
  font-size: 12px;
  color: #4A4A4A;
  -moz-user-select: none;
  -ms-user-select: none;
}

.trucking_DropDown .v-list-item__title{
  width: 100% !important;
  color: #4A4A4A !important
}

.gobacknow { font-size: 14px; text-decoration:none !important}
.classHeadTop {
    padding: 12px;
    padding-bottom: 12px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.connectedDriver {
    background: #F0FBFF;
    /* Blue/Dark Blue */
    border: 1px solid #0171A1;
}

.notconnectedDriver {
    background: #FFF2F2;
    /* Accent/Red */
    border: 1px solid #F93131;
}

.notactiveDriver {
    background: #F7F7F7;
    /* BW/Border Grey */
    border: 1px solid #D8E7F0;
}

.commonDriverStatus {
   font-size: 10px;
border-radius: 17px;
text-transform: uppercase;
padding-left: 11px;
padding-right: 12px;
padding-top: 8px;
padding-bottom: 8px;
position: relative;
top: -6px;
    
    border-radius: 99px;
    margin-right: 12px;
    margin-left: 8px;
}
</style>
<style lang="scss">
.containerdetailheader {
    background: none !important;
    padding-left: 0px;
    .v-toolbar__content {
        padding-left:0px !important;
        padding-right:0px !important;
        .titleheader {
            width: 100%;
            .reportscheduletag {
                font-size: 10px;
                border: 1px solid #16B442;
                border-radius: 17px;
                text-transform: uppercase;
                padding-left: 11px;
                padding-right: 12px;
                padding-top: 8px;
                padding-bottom: 8px; 
                background:#EBFAEF;  
                position: relative;
                top: -6px;            
                .scheduletext {
                   color:#4A4A4A;
                   font-weight: 600;
                }
                .ON_text {
                    color: #16B442;
                    margin-left: 5px;
                    margin-right: 4px;
                    font-weight: 600;
                }
            }
            .off_value {
                font-size: 10px;
                border: 1px solid #D8E7F0;
                border-radius: 17px;
                text-transform: uppercase;
                padding-left: 11px;
                padding-right: 12px;
                padding-top: 8px;
                padding-bottom: 8px; 
                background:#fff;  
                position: relative;
                top: -6px;            
                .scheduletext {
                   color:#4A4A4A;
                   font-weight: 600;
                }
                .ON_text {
                    color: #6D858F;
                    margin-left: 5px;
                    margin-right: 4px;
                    font-weight: 600;
                }
            }
        }
    }
}

.Headerbottomtext {
    font-size: 12px !important;
    margin-top:7px;
    padding-left:0px;
}

.telephoneholder {
    padding-left:20px;
    padding-right:19px;
    img {
        position: relative;
        top: 3px;
    }
}

.emailholder {
    padding-left:5px;
    img {
        position: relative;
        top: 3px;
    }
}

.titleheader {
    padding-left: 0px;
}

.gobacknow {
    text-decoration: none !important;
    font-size: 14px;
}
.mdi-arrow-up {
	display: none !important;
}
.font-medium {
    color:#4A4A4A
}
.containerID{
    color:#6D858F;
}
.classsort {
	background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
	margin-left: 4px;
	width: 21px;
	height: 17px;
	display: block;
	position: relative;
	top: 3px;
	left: 1px;
	float: right;
}

.tabcontainers{
    border-bottom:2px solid #EBF2F5
}
.title-tab {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: none !important;
}

.table_customer {
    margin-top:0px;
}

.table_customer  table thead th {
    text-transform: none !important;
    font-size:14px !important;
}
.shiflkey{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left:6px;
    color:#4A4A4A
}

.dropdownreport{
    
}
.headtextlist {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color:#0171A1;
    cursor: pointer;
}
.listmenuborderline {
    border-bottom: 1px solid #eee;
    margin-bottom: 0px;
    padding-bottom: 0px;
.list_item {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #6D858F;
        margin-bottom: 4px !important;
        padding-bottom: 6px;
    }
}
.listmenu {
    padding-left:0px !important;
    .cmanage {
        cursor: pointer;
        padding-top:5px;
        padding-bottom:5px;
    }
}
.cmon_item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.numbericon {
    border-radius: 40px;
    background: rgb(1, 113, 161);
    color: rgb(255, 255, 255);
    width: 21px;
    margin-left: 2px;
    font-size: 12px;
}

.doprodownshow {display: inline-block;}
.scheduled_tag {
    color:#6D858F;
    font-weight: 700;
    font-size: 14px;
}

	.v-tooltip__content {		
            background: #4A4A4A !important;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
			&::after {		
                content:'';
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #4A4A4A transparent transparent transparent;

			}
		
	}

  .tooltip {
    position: relative;
    display: inline-block;    
  }

  .tooltip .tooltiptextdriver {
    visibility: hidden;
    width: 120px;
    background-color: #4A4A4A;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 999;
    bottom: 114%;
    left: 35%;
    margin-left: -60px;

    opacity: 0;
    transition: opacity 1s;
  }
.tooltip:hover .tooltiptextdriver {
  visibility: visible;
  opacity: 1;
}
  .tooltip .tooltiptextdriver::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4A4A4A transparent transparent transparent;
  }

  .tooltip:hover .tooltiptextdriver {
    visibility: visible;
  }
</style>