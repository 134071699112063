<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="620px"
    content-class="add-container-dialog move-do-dialog"
    v-resize="onResize"
    :retain-focus="false"
  >
    <v-card class="add-supplier-card">        
      <v-card-title>
        <span class="headline"> Move Delivery Order </span>
        <button icon dark class="btn-close" @click="close">
          <v-icon>mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text class="py-4">
        <v-form
          ref="form"
          id="createContainer"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-radio-group v-model="modelcheckbox" mandatory hide-details="auto" class="mt-0">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0 pt-2">
                <v-radio
                  @change="getradio(1)"
                  class="radioChoices"
                  label="Move & Create New DO"
                  value="radio-1"
                  :ripple="false"
                >
                </v-radio>
                <p class="textClass">
                  Selected container's details will be copied to the New
                  Delivery Order.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                <v-radio
                  @change="getradio(2)"
                  class="radioChoices"
                  label="Move to existing DO"
                  value="radio-2"
                  :ripple="false"
                >
                </v-radio>
                <p class="textClass">
                  Move the selected containers and their details to an existing
                  Delivery Order.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col 
                v-if="choosenmovetoexistingdo"
                cols="12" 
                sm="12" 
                md="12" 
                style="padding-left:44px"
                class="pb-0 pt-0"
                >  
                    <label class="label_delivery_order" for="">DELIVERY ORDER</label>
                  <v-autocomplete
                      class="containersizemodal deliveryorderselect"                   
                      v-model="getselectedeliveryDO"
                      :items="getalldeliveryoder"
                      item-text="shifl_ref"
                      item-value="id"
                      placeholder="Select Delivery Order"
                      solo
                      append-icon="ic-chevron-down"                    
                    >

                      <template slot="item" slot-scope="{ item }">
                        <template>
                          <div 
                            class="listDropdown" 
                            style="width:100%" 
                          >
                            <span style="font-size:14px">
                                DO #{{checkitem(item)}}
                            </span>                           
                          </div>                         
                        </template>
                      </template>
                    </v-autocomplete>             
              </v-col> -->
            </v-row>
          </v-radio-group>
        </v-form>
        
      </v-card-text>
      <v-card-actions style="border-top: 2px solid #ebf2f5 !important">
        <div class="text-left buttonActions d-flex align-center justify-start" style="padding-right: 0px; padding-left: 0px">
          <v-btn
            style="caret-color: transparent; font-size: 14px !important;"
            class="btn-blue"
            text
            @click="save"
            :disabled="disabled"
          >
            <span>
              <span style="caret-color: transparent">
                <v-progress-circular
                  :size="15"
                  color="#fff"
                  :width="2"
                  indeterminate
                  v-if="isFetching"
                  style="margin-right: 3px"
                >
                </v-progress-circular>
                Continue
              </span>
            </span>
          </v-btn>
          <v-btn class="btn-white" style="font-size: 14px !important;" text @click="close" v-if="!isMobile">
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../../utils/globalMethods";
//import _ from "lodash";
export default {
  name: "Movedialog",
  props: ["dialogData", "ContainerSize",'locType'],
  components: {},
  data() {
    return {
      callApiForDeliveryLoc: true,
      disabled: false,
      radiochoosen: "",
      getselectedeliveryDO: "",
      movedo: "createdo",
      choosenmovetoexistingdo: 0,
      modelcheckbox: "",
      searchPlace: "",
      loadingLocationPlaces: false,
      places: [],
      containersize: "",
      container_number: "",
      container_size: "",
      container_weight: "",
      container_volume: "",
      container_qty: "",
      DeliveryTo: "",
      typeError: "", // type of error return from api
      isFetching: 0,
      valid: true,
      value: [],
      isMobile: false,
      rules: [(v) => !!v || "Input is required."],
      numberRules: [
        (v) => {
          var reg = new RegExp("^[0-9]+$");
          if (!reg.test(v)) {
            return "Input should be a number";
          }
          return true;
        },
      ],
      container_number_rules: [
        (text) => {
          if (text == "") return "Container # is required.";
          let msg =
            "The first 4 characters are alpha and followed by 7 digits.";
          if (text.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
              /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
              return true;
            }
            return msg;
          }
          return "First 4 characters are alpha and followed by 7 digits.";
        },
      ],
      selectRules: [(v) => !!v || "This field is required"],
      deliveryto: [(v) => !!v || "Delivery To is required"],
    };
  },
  watch: {
    getselectedeliveryDO(c) {
      if (c) {
        this.disabled = false;
      }
    },
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
  },
  computed: {
    ...mapGetters({
      getCustomers: "customers/getCustomers",
      getCustomersLoading: "customers/getCustomerLoading",
      getalldeliveryorders: "deliveryorder/getalldeliveryorders",
    }),
    getalldeliveryoder: {
      get() {
        console.log(this.getalldeliveryorders);
        return this.getalldeliveryorders.data;
      },
    },
    itemsDropdownSize: {
      get() {
        return this.ContainerSize;
      },
    },
    dialog: {
      get() {
        return this.dialogData;
      },
      set(value) {
        if (!value) {
          this.$emit("update:dialogData", false);
        } else {
          this.$emit("update:dialogData", true);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCustomers: "customers/fetchCustomers",
      createCustomers: "customers/createCustomers",
      fetchDeliveryOrders: "deliveryorder/fetchDeliveryOrders",
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    checkitem(t) {
      return t.shifl_ref;
    },
    getradio(c) {
      if (c == 2) {
        this.getselectedeliveryDO = "";
        this.disabled = false;
        this.choosenmovetoexistingdo = 1;
      } else {
        this.choosenmovetoexistingdo = 0;
        this.disabled = false;
      }
    },
    close() {
      this.$refs.form.resetValidation();
      this.$emit("update:dialogData", false);
    },
    save() {
      let statevalue = {};
      if (this.choosenmovetoexistingdo == 0) {
        statevalue = {
          state: "createdo",
          selected: "",
          chosen: this.choosenmovetoexistingdo,
        };
      } else {
        //var dis = this;
        //var getobject = _.filter(dis.getalldeliveryorders.data,function(o){return o.id==dis.getselectedeliveryDO;});
        this.choosenmovetoexistingdo = 1;
        statevalue = {
          state: "moveDO",
          //shifl_ref: getobject[0].shifl_ref,
          //selected:this.getselectedeliveryDO,
          chosen: this.choosenmovetoexistingdo,
        };
      }
      this.$emit("clickContinue", statevalue);
    },
    // async save(){
    //   var statevalue;
    //   if(this.choosenmovetoexistingdo != 1){
    //       this.choosenmovetoexistingdo = 0
    //       statevalue =  {
    //                       state:'createdo',
    //                       selected:''
    //                     };
    //   }else{
    //       var dis = this;
    //       var getobject = _.filter(dis.getalldeliveryorders.data,function(o){return o.id==dis.getselectedeliveryDO;});
    //       this.choosenmovetoexistingdo = 1
    //       statevalue =  {
    //                       state:'moveDO',
    //                       shifl_ref: getobject[0].shifl_ref,
    //                       selected:this.getselectedeliveryDO
    //                     };
    //   }
    //   console.log(statevalue)
    //   this.$emit('movecontainer',statevalue);
    // },
    setToDefault() {
      this.$emit("setToDefault");
    },
    resetform() {
      this.container_number = "";
      this.container_size = "";
      this.container_weight = "";
      this.container_volume = "";
      this.container_qty = "";
      this.DeliveryTo = "";
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;

            this.places = [...json.features];
            // this.places = [...json.features, ...customInput];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
  },
  async mounted() {
    //getall-delivery-order
    if(typeof this.locType !='undefined' && !this.locType){
      await this.fetchDeliveryOrders(this.locType);
    }else{
      await this.fetchDeliveryOrders(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
@import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../assets/scss/pages_scss/deliveryorder/deliveryAddDialog.scss";

.v-autocomplete__content.v-menu__content {
  border-radius: 4px !important;
}

.v-dialog {
  &.add-container-dialog.move-do-dialog {
    .v-card {
      .v-card__text {
        .v-input {
          .v-input__control {
            .v-input__slot {
              .v-label {
                color: #4a4a4a !important;
                font-size: 14px !important;
                font-family: 'Inter-Regular', sans-serif !important;
              }

              .v-input--radio-group__input {
                .v-radio {
                  .v-input--selection-controls__input {
                    margin-right: 4px !important;
                  }

                  // icons
                  i {
                      &.mdi-radiobox-blank {
                          width: 26px !important;
                          padding: 3px 3px 3px 0 !important;
                          justify-content: flex-start;

                          &::before {
                              content: '' !important;
                              background-image: url('/radio-empty-1.svg') !important;
                              background-position: center !important;
                              background-repeat: no-repeat !important;
                              background-size: cover !important;
                              width: 18px !important;
                              height: 18px !important;
                          }
                      }

                      &.mdi-radiobox-marked {
                          width: 26px !important;
                          padding: 3px 3px 3px 0 !important;
                          justify-content: flex-start;

                          &::before {
                              content: '' !important;
                              background-image: url('/radio-checked-1.svg') !important;
                              background-position: center !important;
                              background-repeat: no-repeat !important;
                              background-size: cover !important;
                              width: 18px !important;
                              height: 18px !important;
                          }
                      }
                  }
                }

                .textClass {
                  padding-left: 29px !important;
                }
              }              
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped>
.text-field {
  color: red !important;
  /* add whatever properties you want */
}

.v-dialog__content .v-textarea .v-input__slot {
  border: 1px solid #b4cfe0 !important;
}

.containersizemodal .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #b4cfe0 !important;
}
.containersizemodal .v-icon {
  font-size: 13px;
}
.textClass {
  color: #6d858f;
  font-size: 12px;
  padding-left: 30px;
}

.radioChoices {
  margin-bottom: 0px !important;
}
.v-label {
  font-size: 14px !important;
}

.label_delivery_order {
  color: #819fb2;
  font-weight: 600;
  font-size: 10px;
}

.deliveryorderselect {
  height: 53px;
}
</style>
