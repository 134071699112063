<template>
  <div d-flex 
        class="supplier-content-wrapper 
               driver-content-wrapper 
               chassis-container-wrapper"
    >
    <div class="driverHeader">
      <h2>{{titlePage}}</h2>
      <v-btn
        v-if="getTruckerId"
        color="primary addyardbutton"
        class="btn-blue add-supplier"
        @click="addChassisDialog()"
      >
        <img src="@/assets/icons/plus-button-icon.svg">&nbsp; {{chassisName}}
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="getChassis"
      class="yard-table 
             suppliers-table 
             ddriver-table 
             elevation-1"
      :page.sync="page"
      hide-default-footer
       @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"     
    >
      <template v-slot:[`item.number`]="{ item }">
        <p class="mb-0">{{ item.number !== "" ? item.number : "--" }}</p>
      </template>

      <template v-slot:[`item.trucker_owned`]="{ item }">   
        <p v-if="item.trucker_owned==1" class="mb-0">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="green" xmlns="http://www.w3.org/2000/svg">
              <path style="fill:#157ca8"  d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM15.5416 8.66664L10.625 13.5833L8.45836 11.4166C8.13618 11.0945 7.61382 11.0945 7.29164 11.4166C6.96945 11.7388 6.96945 12.2612 7.29164 12.5834L10.0416 15.3334C10.3638 15.6555 10.8862 15.6555 11.2084 15.3334L16.7084 9.83336C17.0305 9.51118 17.0305 8.98882 16.7084 8.66664C16.3862 8.34445 15.8638 8.34445 15.5416 8.66664Z" fill="#0171A1"/>
          </svg>
        </p>
        <p v-else class="mb-0"> 
          <svg width="20" height="20"  viewBox="0 0 24 24" fill="grey" xmlns="http://www.w3.org/2000/svg">
            <path style="fill:#b5b3b3" d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM15.5416 8.66664L10.625 13.5833L8.45836 11.4166C8.13618 11.0945 7.61382 11.0945 7.29164 11.4166C6.96945 11.7388 6.96945 12.2612 7.29164 12.5834L10.0416 15.3334C10.3638 15.6555 10.8862 15.6555 11.2084 15.3334L16.7084 9.83336C17.0305 9.51118 17.0305 8.98882 16.7084 8.66664C16.3862 8.34445 15.8638 8.34445 15.5416 8.66664Z" fill="#819FB2"/>
          </svg>
        </p>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <p class="mb-0">{{ item.type !== "" ? item.type : "--" }}</p>
      </template>
      <template v-slot:[`item.actions`]="{ item }" style="position: fixed">
        <div class="d-flexs justify-center">
          <v-btn small width="55"
            style="margin-right: 10px; min-height: 30px !important;"
            @click="editChassis(item)"
            class="truckerMoreBtn"
          >
            <img class="" src="../../../assets/icons/yards_edit.svg" alt="" />
            <span
              class="EditButton"
              >Edit</span
            >
          </v-btn>
          <v-menu offset-y nudge-left="97"  nudge-top="-5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" class="truckerMoreBtn">
                <img src="../../../assets/icons/more_dot.svg" alt="" />
              </v-btn>
            </template>
            <v-list class="trucking_DropDown" style="overflow: hidden">
              <v-btn
                @click="deleteChassis(item)"
                style="background-color: #fff; height: 45px"
              >
                <v-list-item 
                    style="padding-left: 0px;
                           width: 96px;
                           padding-right:15px;
                           text-transform:none;"
                  >
                  <img src="../../../assets/icons/Delete.svg" alt="" />
                  <v-list-item-title
                    class="trucking_DropDownDelete"
                    style=""
                  >
                  {{deleteText}}
                  </v-list-item-title>
                </v-list-item>
              </v-btn>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:no-data>
        <div 
          class="no-data-preloader mt-4" 
          v-if="getChassisLoading">
            <v-progress-circular
              :size="40"
              color="#0171a1"
              indeterminate
              v-if="getChassisLoading"
            >
            </v-progress-circular>
        </div>
      </template>
    </v-data-table>
    <Pagination 
      :pageData.sync="page" 
      :lengthData.sync="pageCount" 
      :isMobile="isMobile" 
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Pagination from '../../Pagination.vue'
import globalMethods from '../../../utils/globalMethods'
export default {
  components: {
    Pagination,
  },
  props: ["isMobile"],
  data: () => {
    return {
      
      deleteText: 'Delete',
      editText: 'Edit',
      titlePage: 'Chassis',
      chassisName: 'New Chassis',
      boxtype:'',
      itemsPerPage:9,
      alertbox: false,
      pageCount: 0,
      page: 1,
      headers: [
        {
          text: "Number",
          align: "start",
          value: "number",
          width: "20%",
          fixed: true,
        },
        {
          text: "Chassis Type",
          align: "start",
          value: "type",
          sortable: false,
          width: "40%",
          fixed: true,
        },
          {
          text: "Company Owned",
          align: "start",
          value: "trucker_owned",
          sortable: false,
          width: "10%",
          fixed: true,
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
          width: "10%",
          fixed: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getChassis: "chassis/getList",
      getChassisLoading: "chassis/getChassisLoading",
      getalert_box: "chassis/getalert_box",
      get_alert_box_text: "chassis/get_alert_box_text",
      get_alert_type: "chassis/get_alert_type",
      getTruckerId: "chassis/getTruckerId",
    }),
  },
  methods: {
    ...globalMethods, 
    ...mapActions("chassis", ["getChassisFromApi", "changeAlertBoxStatus"]),
    editChassis(item) {
      this.$emit("editChassis", item);
    },
    addNeww() {
      this.getStatusOfBox;      
    },
    deleteChassis(item) {
      this.$emit("showDeleteDialog", item);
    },
    addChassisDialog() {
      this.$emit("addChassisDialog");
    },
  },
  mounted() {    
    this.getChassisFromApi();  
  },
  watch: {
    getalert_box() {
      setTimeout(() => {
        this.changeAlertBoxStatus(false);
      }, 2000);
    },
  },
};
</script>
<style scoped>
.truckerMoreBtnn {
  background-color: brown;
}
.delete {
  background: #4A4A4A !important;
}

.pagination-wrapper {
  height: 48px !important;
}

.pagination-wrapper .v-pagination {
  position: relative !important;
  top: -6px !important;
}

.trucking_DropDownDelete {
  color: #f93131;
  font-size: 12px !important;                      
  font-weight: Regular;
  font-weight: 500;
  margin-left:0px;
  text-align:left;
  padding-left:6px;
}

.EditButton {
  text-transform: capitalize;
  color: #0171a1;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500, Medium;
  line-height: 18px;
  margin-left:2px;
}
</style>