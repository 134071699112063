<template>
	<div class="containers-inner-wrapper compact-view-wrapper">
		<div class="container-compact-tabs-wrapper">
			<v-tabs v-if="retabs" class="container-compact-tabs" v-model="activeTab">
				<v-tab v-for="(n, i) in tabItems" :key="i" @click="getCurrentTab(n.name, i, n)">
					<span>{{ n.name=='Assigned' ? 'Scheduled' : n.name }}</span>

					<span class="tab-count-wrapper" v-if="n.name !== 'Completed' && n.name !== 'Cancelled'">
						<span class="tab-count-value" v-if="n.count">
							{{ n.count }}
						</span>
					</span>
				</v-tab>
			</v-tabs>
		</div>

		<div class="table-content-wrapper">
			<div class="loading-overlay" v-if="isShowLoadingUI && !isUpdatingCells">
				<v-progress-circular
					:size="30"
					color="#0171a1"
					indeterminate>
				</v-progress-circular>
			</div>

			<div class="search-component-container">
				<v-tabs class="container-sub-tabs" v-model="subTabIndex">
					<v-tab v-for="(n, i) in tabItems[activeTab].sub_tab" :key="i" @click="getCurrentSubTab(n, i)">
						<span>{{ n.name }}</span>

						<span class="tab-count-value"
							v-if="tabItems[activeTab].sub_tab[i] && tabItems[activeTab].sub_tab[i].count !== 0 &&
							currentSelectTab !== 'Scheduled'">
							<span v-if="tabItems[activeTab].sub_tab[i]" style="color: #fff;">
								{{ tabItems[activeTab].sub_tab[i].count }}
							</span>
						</span>
					</v-tab>
				</v-tabs>

				<SearchComponent
					placeholder="Search Containers"
					:searchValue.sync="search"
					:handleSearchComponent="true" />
			</div>
			<v-divider style="border-color: #EBF2F5 !important;"></v-divider>

			<div id="data-grid-demo">
				<DxDataGrid v-if="reload"
					ref="dataGridReference"
					id="gridContainer"
					:data-source="dataSource"
					:show-borders="true"
					:errorRowEnabled="false"
					:on-focused-cell-changing="onFocusedCellChanging"
					key-expr="ID"
					@cell-prepared="onCellPrepared"
					width="100%"
					noDataText=""
					@editing-start="onEditingStart"
					@content-ready="onContentReady"
					@editor-preparing="onEditorPreparing"
					@row-updating="onRowUpdating"
					@cellHoverChanged="onCellHoverChanged($event)"
					:loadPanel="loadPanelOptions"
					:scrolling="scrollingOptions">
					<!-- :showColumnHeaders="dataSource.length > 0" -->
					<!-- <DxFilterRow :visible="true" /> -->
					<!-- <DxHeaderFilter :visible="true" /> -->

					<DxEditing
						:allow-updating="true"
						mode="cell"
						start-edit-action="dblClick"
						:use-icons="true" />

					<!-- Start of Default Columns -->
					<DxColumn data-field="Container"
						:width="150"
						caption="Container"
						cell-template="container-template"
						:allow-sorting="false">
						<DxRequiredRule message="Container is required" />

						<DxStringLengthRule
							:min="11"
							:max="11"
							message="The first 4 characters are alpha and followed by 7 digits." />

						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodContainer" />
					</DxColumn>

					<DxColumn data-field="MBL"
						:width="170"
						caption="MBL"
						cell-template="mbl-template"
						:allow-sorting="false">
						<DxRequiredRule message="MBL is required" />
					</DxColumn>

					<DxColumn data-field="SizeID"
						:width="90"
						caption="Size"
						:allow-sorting="false">
						<DxLookup
							:data-source="sizeLists"
							value-expr="id"
							display-expr="name" />
					</DxColumn>

					<DxColumn data-field="Type"
						:width="90"
						:allowEditing="false"
						caption="Type"
						:allow-sorting="false" />

					<DxColumn data-field="DO"
						:width="90"
						:allowEditing="false"
						:allow-sorting="false"
						caption="DO ID"
						cell-template="do-template" />

					<DxColumn data-field="Ref"
						:width="100"
						:allowEditing="false"
						:allow-sorting="false"
						caption="Ref#" />

					<DxColumn data-field="CustomerID"
						:width="220"
						:allow-sorting="false"
						caption="Customer" >
						<DxRequiredRule message="Customer is required" />
						<DxLookup
							:data-source="customerLists"
							value-expr="id"
							display-expr="company_name">
						</DxLookup>
					</DxColumn>

					<DxColumn data-field="ConsigneeName"
						:width="200"
						:allow-sorting="false"
						caption="Consignee"
						:calculate-display-value="calculateDisplayValueConsignee">
						<!-- cell-template="consignee-cell-template" -->
						<DxLookup
							:data-source="getCurrentConsignees"
							display-expr="name"
							value-expr="id"
							value="name">
						</DxLookup>
					</DxColumn>

					<DxColumn data-field="CarrierID"
						:width="140"
						:allow-sorting="false"
						caption="Carrier">
						<DxLookup
							:data-source="carrierLists"
							value-expr="id"
							display-expr="name" />
					</DxColumn>
					<!-- End of Default Columns -->

					<!-- Start of hiding/showing columns (conditional) -->
					<DxColumn data-field="Lfdp"
						:width="120"
						data-type="date"
						caption="LFD"
						cell-template="date-cell-template"
						:visible="currentSelectTab === 'Scheduled'"
						name="LfdpField2">
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodLfd"
							:reevaluate="true" />
					</DxColumn>

					<DxColumn data-field="Lfdr"
						:width="120"
						data-type="date"
						caption="PDD"
						cell-template="date-cell-template"
						:visible="currentSelectTab === 'Scheduled'"
						name="LfdrField2">
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodPdd"
							:reevaluate="true" />
					</DxColumn>

					<DxColumn data-field="Driver"
						:width="180"
						:allow-sorting="false"
						caption="Driver"
						:visible="currentSelectTab === 'Scheduled'"
						:calculate-display-value="calculateDisplayValueDriver">
						<DxLookup
							:data-source="driverLists"
							value-expr="id"
							display-expr="name" />
					</DxColumn>

					<DxColumn data-field="Status"
						:width="100"
						:allow-sorting="false"
						:allow-editing="false"
						caption="Status"
						:visible="currentSelectTab === 'Scheduled'"
						cell-template="current-status-template">
						<!-- <DxLookup
							:data-source="terminals"
							value-expr="id"
							display-expr="name" /> -->
					</DxColumn>			

					<DxColumn data-field="Mode"
						:width="100"
						:allow-editing="false"
						caption="Mode"
						:visible="currentSelectTab === 'Scheduled'"
						v-if="currentSelectTab === 'Scheduled'">
					</DxColumn>

					<DxColumn data-field="TypeDelivery"
						:width="140"
						:allow-editing="false"
						caption="Type Of Delivery"
						:visible="currentSelectTab === 'Scheduled'"
						v-if="currentSelectTab === 'Scheduled'">
					</DxColumn>

					<!-- Current Location / Pick up On Location Field -->
					<DxColumn data-field="Location"
						:width="(currentSelectTab === 'Scheduled' || currentSelectTab === 'At Yard') ? 250 : 150"
						:allow-sorting="false"
						:allow-editing="(currentSelectTab === 'Scheduled' || currentSelectTab === 'At Yard') ? false : true"
						:caption="currentSelectTab === 'Scheduled' ? 'Pickup Location' : 'Current Location'"
						:visible="!isTabLFDCaption"
						:cell-template="currentSelectTab !== 'At Yard' ? 'current-location-template' : 'atyard-location-template'">
						<DxLookup
							:data-source="terminals"
							value-expr="id"
							display-expr="name" />
					</DxColumn>

					<DxColumn data-field="LocationDateTime"
						:width="160"
						:allow-editing="false"
						data-type="datetime"
						caption="Pickup Time"
						:visible="currentSelectTab === 'Scheduled'"
						:calculate-display-value="calculateDisplayValuePickupTime">
						<!-- cell-template="pickuptime-cell-template" -->
					</DxColumn>

					<DxColumn data-field="DeliveryLocation"
						:width="250"
						:allow-sorting="false"
						:allow-editing="false"
						caption="Delivery On"
						:visible="currentSelectTab === 'Scheduled'"
						cell-template="current-delivery-template">
					</DxColumn>

					<DxColumn data-field="DeliveryDateTime"
						:width="160"
						:allow-editing="false"
						data-type="datetime"
						caption="Delivery Time"
						:visible="currentSelectTab === 'Scheduled'"
						:calculate-display-value="calculateDisplayValueDeliveryTime">
					</DxColumn>
					<!-- cell-template="delivery-cell-template" -->

					<!-- ETA Field -->
					<DxColumn data-field="Eta"
						:width="120"
						data-type="date"
						caption="ETA"
						cell-template="date-cell-template"
						:visible="isShowETA">
						<DxRequiredRule message="ETA is required" />
						<!-- sort-order="asc"  -->
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodEta"
							:reevaluate="true" />
					</DxColumn>

					<!-- LFD field -->
					<DxColumn data-field="Lfdp"
						:width="110"
						data-type="date"
						:caption="isTabLFDCaption ? 'LFD P' : 'LFD'"
						cell-template="date-cell-template"
						:visible="isShowLfdp">
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodLfd" />
					</DxColumn>

					<DxColumn data-field="discharged"
						:width="35"
						:allow-sorting="false"
						dataType="boolean"
						editorType="dxCheckBox"
						header-cell-template="dischargedHeaderTemplate"
						v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'" />

					<DxColumn data-field="released"
						:width="35"
						:allow-sorting="false"	
						dataType="boolean"					
						header-cell-template="releasedHeaderTemplate"
						v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'" />

					<DxColumn data-field="gate_fees"
						:width="35"
						:allow-sorting="false"
						dataType="boolean"
						header-cell-template="feesHeaderTemplate"
						v-if="currentSelectTab === 'At Port' && selected_sub_tab.name === 'Pending'" />

					<!-- For Completed Tab -->
					<DxColumn data-field="LocationDateTime"
						:width="160"
						:allow-editing="false"
						data-type="datetime"
						caption="Picked Up"
						:visible="currentSelectTab === 'Completed'"
						v-if="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValuePickupTime"
						name="LocationDateTimeField2">
					</DxColumn>

					<DxColumn data-field="DeliveryDateTime"
						:width="160"
						:allow-editing="false"
						data-type="datetime"
						caption="Delivered On"
						:visible="currentSelectTab === 'Completed'"
						v-if="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValueDeliveryTime"
						name="DeliveryDateTimeField2">
					</DxColumn>

					<!-- For Cancelled Tab -->
					<DxColumn data-field="Opened"
						:width="120"
						:allow-editing="false"
						data-type="date"
						caption="Opened"
						cell-template="date-cell-template"
						:visible="currentSelectTab === 'Cancelled'">
					</DxColumn>

					<DxColumn data-field="Closed"
						:width="120"
						:allow-editing="false"
						data-type="date"
						caption="Closed"
						cell-template="date-cell-template"
						:visible="currentSelectTab === 'Cancelled'">
					</DxColumn>

					<!-- At Yard Tab -->
					<DxColumn data-field="ArrivedAt"
						:width="160"
						caption="Arrived At"
						:allowEditing="false"
						v-if="currentSelectTab === 'At Yard' || currentSelectTab === 'At the Customer'"
						:calculate-cell-value="calculateDisplayArrivedAtData">
						<!-- data-type="datetime" -->
						<!-- :calculate-cell-value="calculateArrivedAtData" -->
					</DxColumn>

					<DxColumn data-field="Mode"
						:width="100"
						:allow-editing="false"
						caption="Mode"
						:visible="currentSelectTab === 'At the Customer'"
						v-if="currentSelectTab === 'At the Customer'">
					</DxColumn>

					<!-- Empty Notified -->
					<DxColumn data-field="EmptyNotified"
						:width="160"
						caption="Empty Notified"
						data-type="datetime"
						v-if="currentSelectTab === 'At the Customer' && selected_sub_tab.name === 'empty'"
						:calculate-display-value="calculateDisplayValueEmptyNotified">
					</DxColumn>

					<DxColumn data-field="Lfdr"
						:width="110"
						data-type="date"
						:caption="isTabLFDCaption ? 'LFD R' : 'Return LFD'"
						:visible="isShowLfdr"
						:calculate-display-value="calculateDisplayValueLFDR">
						<!-- cell-template="date-cell-template" -->
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackMethodPdd" />
					</DxColumn>

					<!-- Empty Returned for Completed -->
					<DxColumn data-field="EmptyNotified"
						:width="160"
						caption="Empty Returned"
						:allow-editing="false"
						data-type="datetime"
						:visible="currentSelectTab === 'Completed'"
						:calculate-display-value="calculateDisplayValueEmptyNotified"
						name="EmptyNotifiedField2">
					</DxColumn>

					<!-- Direct / Prepull for Completed -->
					<DxColumn data-field="DirectPrepull"
						:width="160"
						caption="Direct / Prepull"
						:allow-editing="false"
						:visible="currentSelectTab === 'Completed'">
					</DxColumn>

					<!-- Scheduled -->
					<DxColumn data-field="Scheduled"
						:width="140"
						:allow-sorting="false"
						:allow-editing="true"
						caption="Scheduled"
						data-type="datetime"
						:visible="isShowScheduledColumn"
						:calculate-display-value="calculateDisplayValueScheduled"> 
						<DxCustomRule
							type="custom"
							message=""
							:validation-callback="validationCallBackScheduled" />
					</DxColumn>

					<DxColumn cell-template="action-cell"
						:width="70"
						type="buttons"
						caption="Action"
						:allow-filtering="false" />

					<DxScrolling column-rendering-mode="infinite" />

					<template #container-template="{ data }">
						<div class="d-flex justify-space-between align-center">
							<span class="text-uppercase">{{ data.value }}</span>

							<button class="button-copy-container d-flex align-center"
								@click="copyContainerToClipboard(data)">
								<img src="@/assets/icons/copy-clipboard.svg" id="i-icon-copy" width="18px" height="18px" alt="Copy">
							</button>
						</div>
					</template>

					<template #mbl-template="{ data }">
						<span class="text-uppercase">{{ data.value }}</span>
					</template>

					<template #do-template="{ data }">
						<span class="text-uppercase text-start">DO{{ data.value }}</span>
					</template>

					<template #consignee-cell-template="{ data }">
						<span class="consignee-display">{{ getValue(data) }}</span>
					</template>

					<template #date-cell-template="{ data }">
						<span> {{ getDateFormat(data.value) === '' ? 'N/A' : getDateFormat(data.value) }} </span>
					</template>

					<template #lfdr-template="{ data }">
						<span>{{ data.value !== '' ? data.value : 'N/A' }}</span>
					</template>

					<template #pickuptime-cell-template="{ data }">
						<div class="pickup_on_data tooltip-location" style="font-size: 14px;">
							{{ pickupOn(data.row.data, data.row.data, 'time') }}
						</div>
					</template>

					<template #delivery-cell-template="{ data }">
						<div class="pickup_on_data tooltip-location" style="font-size: 14px;">
							{{ deliveryon(data.row.data, 'time') }}
						</div>
					</template>

					<template #current-location-template="{ data }">
						<div>
							<v-tooltip bottom offset-y right content-class="terminal-at-tooltip" v-if="currentSelectTab !== 'Scheduled'">
								<template v-slot:activator="{ on, attrs }">
									<p class="overflow-hidden-ellipsis mb-0" style="font-size: 14px;" v-bind="attrs" v-on="on">
										{{ data.row.data.terminal_id != null ?
											getTerminalData(data.row.data.terminal_id, 'nickname') : "--"
										}}
									</p>
								</template>
								<p class="mb-0" style="font-size: 14px;">
									{{ data.row.data.terminal_id != null ?
										getTerminalData(data.row.data.terminal_id, 'name') : "--"
									}}
									<span>
										{{ data.row.data.terminal_id != null ?
											getTerminalData(data.row.data.terminal_id, 'firms') : ""
										}}
									</span>
								</p>
								<p class="mb-0" style="font-size: 12px;">
									{{ data.row.data.terminal_id != null ?
										getTerminalData(data.row.data.terminal_id, 'address') : ""
									}}
								</p>
							</v-tooltip>

							<div v-else> <!-- if location is terminal (show nickname in the cell and show details in tooltip on hover) -->
								<div class="tooltip-for-pickup" v-if="checklength_dispatch(data.row.data.dispatch_schedule)">
									<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on" >
												<div class="pickup_on_data tooltip-location" style="font-size: 14px;">
													{{ pickupOn(data.row.data, data.row.data, 'location') }}
												</div>
											</span>
										</template>

										<span>
											<p class="mb-0" style="font-size: 14px;">
												{{ data.row.data.terminal != null ? data.row.data.terminal.name : "--" }}
												<span style="font-size: 14px;">
													{{ data.row.data.terminal != null ? data.row.data.terminal.firms_code : "" }}
												</span>
											</p>
											<p class="mb-0" style="font-size: 12px;">
												{{ data.row.data.terminal != null ? data.row.data.terminal.address : "" }}
											</p>
										</span>
									</v-tooltip>
								</div>

								<div v-else> <!-- if location is not terminal (show whole location details in tooltip on hover) -->
									<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on" >
												<div class="pickup_on_data non-tooltip" style="font-size: 14px;">
													{{ pickupOn(data.row.data, data.row.data, 'location') }}
												</div>
											</span>
										</template>

										<div class="pickup_on_data non-tooltip">
											{{ pickupOn(data.row.data, data.row.data, 'location') }}
										</div>
									</v-tooltip>
								</div>
							</div>
						</div>
					</template>

					<template #atyard-location-template="{ data }">
						<div class="current-location-template-wrapper">
							<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on" >
										<div class="pickup_on_data non-tooltip" style="font-size: 14px;">
											{{ getLastArrivedLeg(data.row.data) !== null ? getLastArrivedLeg(data.row.data).address : "" }}
										</div>
									</span>
								</template>

								<div class="pickup_on_data non-tooltip">
									{{ getLastArrivedLeg(data.row.data) !== null ? getLastArrivedLeg(data.row.data).address : "" }}
								</div>
							</v-tooltip>
						</div>
					</template>

					<template #current-delivery-template="{ data }">
						<v-tooltip bottom offset-y right content-class="terminal-at-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" >
									<div class="pickup_on_data non-tooltip">
										{{ deliveryon(data.row.data, 'location') }}
									</div>
								</span>
							</template>

							<div class="pickup_on_data non-tooltip">
								{{ deliveryon(data.row.data, 'location') }}
							</div>
						</v-tooltip>
					</template>

					<!-- <template #current-assigned-template="{ data }">
						<div class="AssignTo classTop">
							<p class="Assign mb-0">
								<span :class="`driver${data.row.data.id}`">
									{{ getAssignedToDriver(data.row.data, data.row.data).driver }}
								</span>
							</p>
						</div>
					</template> -->

					<template #current-status-template="{ data }">
						<span :id="`status${data.row.data.id}`"
							:class="getAssignedToStatus(data.row.data, data.row.data)">
							{{ sugomo(data.row.data) }}
						</span>
					</template>

					<template #action-cell="{ data }">
						<div class="action-cell-wrapper">
							<div class="d-flex justify-center align-center" v-if="isShowMenuTab">
								<button @click="goToDetailsPage(data)" title="View" style="height: 16px;">
									<i class="dx-icon dx-icon-customicon view"></i>
								</button>
							</div>

							<div class="d-flex justify-center align-center" v-else>
								<!-- <button class="mr-1">
									<img src="../../../../../assets/icons/email_icon_send.svg" alt=""
										style="width: 15px !important; height: 15px !important;">
								</button> -->

								<v-menu bottom left offset-y content-class="datagrid-lists-menu">
									<template v-slot:activator="{ on, attrs }">
										<v-btn :ripple="false" icon v-bind="attrs" v-on="on" width="25px" height="unset">
											<i class="dx-icon dx-icon-customicon dots"></i>
										</v-btn>
									</template>

									<v-list class="datagrid-lists">
										<v-list-item v-if="!isAnyLegAvailable(data)" @click="addFirstLeg(data)">
											<div class="d-flex" style="margin-bottom: 2px;">
												<img src="@/assets/icons/add-icon-black.svg" alt="Add" class="mr-3" width="11px" height="11px">
											</div>
											<v-list-item-title>
												Add Leg
											</v-list-item-title>
										</v-list-item>

										<v-list-item v-else @click="editFirstLeg(data)">
											<img src="@/assets/icons/edit-black.svg" alt="Edit" class="mr-2" width="15px" height="15px">
											<v-list-item-title style="padding-top: 2px;">
												Edit Leg
											</v-list-item-title>
										</v-list-item>

										<v-list-item @click="goToDetailsPage(data)">
											<img src="@/assets/icons/visibility-black.svg" alt="View" class="mr-2" width="15px" height="15px">
											<v-list-item-title>
												View Details
											</v-list-item-title>
										</v-list-item>

										<v-list-item @click="openDialog(data)" v-if="currentSelectTab === 'At the Customer'">
											<img src="@/assets/icons/mark-empty-black.svg" alt="Mark Empty" class="mr-2" width="15px" height="15px">
											<v-list-item-title>
												{{ selected_sub_tab.name === 'full' ? 'Mark Empty' : 'Unmark as Empty'}}
											</v-list-item-title>
										</v-list-item>

										<v-list-item v-if="currentSelectTab === 'Scheduled'" @click="markAsArrivedFirstLeg(data)">
											<img src="@/assets/icons/mark-empty-black.svg" alt="Mark Empty" class="mr-2" width="15px" height="15px">
											<v-list-item-title>
												Mark as Arrived
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</template>

					<!-- header templates -->
					<template #dischargedHeaderTemplate="{ }">
						<div class="header-template-wrapper-customized">
							<div class="discharged-header">
								<img src="@/assets/icons/discharge-icon-header.svg" alt="Discharged">
							</div>
						</div>
					</template>

					<template #releasedHeaderTemplate="{ }">
						<div class="header-template-wrapper-customized">
							<div class="released-header">
								<img src="@/assets/icons/submit-doc.svg" alt="Released">
							</div>
						</div>
					</template>

					<template #feesHeaderTemplate="{ }">
						<div class="header-template-wrapper-customized">
							<div class="gate-fees-header">
								<img src="@/assets/icons/circle-outline-check.svg" alt="Gate Fees">
							</div>
						</div>
					</template>

					<DxPaging :enabled="false" :visible="false" />
				</DxDataGrid>

				<DxTooltip :ref="tooltipRefCopy" position="top" :hide-on-outside-click="true">
					<template>
						<span>Click to Copy</span>
					</template>
				</DxTooltip>

				<DxTooltip :ref="tooltipRefName" position="top" :hide-on-outside-click="true">
					<template>
						<span>Click To Edit</span>
					</template>
				</DxTooltip>

				<DxTooltip :ref="tooltipRefNameNoEdit" position="top" :hide-on-outside-click="true">
					<template>
						<span>Non Editable</span>
					</template>
				</DxTooltip>

				<DxTooltip :ref="tooltipRefNameDisabled" position="top" :hide-on-outside-click="true">
					<template>
						<span>Cannot be edited. <br/> Please schedule a leg first.</span>
					</template>
				</DxTooltip>

				<DxTooltip :ref="tooltipHeaders" position="top" :hide-on-outside-click="true">
					<template>
						<span>Discharged</span>
					</template>
				</DxTooltip>

				<DxTooltip :ref="tooltipDateInfo" position="top" :hide-on-outside-click="true">
					<template>
						<span></span>
					</template>
				</DxTooltip>

				<PaginationComponent
					:totalPage.sync="lastPage"
					:currentPage.sync="page"
					@handlePageChange="handlePageChange"
					:isMobile="isMobile"
					v-if="isShowPagination" />
			</div>
		</div>

		<EditLeg
			:showEditLegModal="showEditLegModal"
			:driverList="getDrivers"
			:chassisList="getChassis"
			:responseChassis="responseChassis"
			:modedropdown="modedropdown"
			:typeDropdown="typeDropdown"
			:pickupLocation="editingLegPickupLocation"
			:leg="editingLeg"
			:currentEditingLegNum="currentEditingLegIndex"
			:markasrrive="markasrrived"
			:editIndex="editIndex"
			@close="() => {
				showEditLegModal = false;
				editingLeg = null;
				markasrrived = 0;
			}"
			@saveEditedLeg="saveEditedLeg"
			@saveNewChassis="saveNewChassis"
			:responseObjectMeta="responseObjectMeta"
			:eta="getEditingShipmentEta"
		/>

		<AddNewLeg
			:showAddLegModal="showAddLegModal"
			:driverList="getDrivers"
			:chassisList="getChassis"
			:responseChassis="responseChassis"
			:modedropdown="modedropdown"
			:getObject="null"
			:typeDropdown="typeDropdown"
			:pickupLocation="getPickupLocation"
			:lastLeg="lastLeg"
			@close="showAddLegModal = false"
			@addNewLeg="addNewLeg"
			@saveNewChassis="saveNewChassis"
			:bus="null"
			:responseObjectMeta="responseObjectMeta"
			:eta="getEditingShipmentEta"
			:dialogData="editingShipment ? editingShipment.dispatch_schedule : editingShipment"
		/>

		<EmptyDialog
			:dialog.sync="dialog"
			:itemData="dialogData"
			:isLoading="isSubmitting"
			@closeDialog="closeDialog"
			@updateContainerEmpty="updateContainer"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchComponent from "../../../../SearchComponent/SearchComponent.vue";
import globalMethods from '../../../../../utils/globalMethods';
import PaginationComponent from '../../../../PaginationComponent/PaginationComponent.vue';
import AddNewLeg from '../../Dialog/AddNewLeg.vue';
import EditLeg from '../../Dialog/EditLeg.vue';
import EmptyDialog from "../../Dialog/EmptyDialog.vue";

import moment from 'moment'
import jQuery from 'jquery'
import _ from 'lodash'
import iziToast from "izitoast"

import axios from "axios";
var cancel;
var CancelToken = axios.CancelToken;
const poBaseUrl = process.env.VUE_APP_PO_URL;

// devextreme imports start
import DxDataGrid, {
	DxColumn,
	DxEditing,
	DxScrolling,
	DxLookup,
	DxPaging,
	// DxFilterRow,
	// DxHeaderFilter,
} from 'devextreme-vue/data-grid';

import {
	DxRequiredRule,
	DxCustomRule,
	DxStringLengthRule
} from 'devextreme-vue/validator';

import DxTooltip from "devextreme-vue/tooltip"
import { on } from "devextreme/events";
import CustomStore from 'devextreme/data/custom_store';

let pendingClick;
let cellCounter = 0
let currentConsignees = []

export default {
	name: "ContainersDesktopCompact",
	props: [
		"items",
		"isMobile",
		"tabitemdata",
		"newtabitem",
		"contentData",
		"allcomopents",
		"customers",
		"terminals",
		"magdriver",
		"shipmentsize",
		//"magdropterminal",
		"mgaFiles",
		"responseObjectMeta",
	],
	components: {
		EmptyDialog,
		EditLeg,
		AddNewLeg,
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxScrolling,
		DxRequiredRule,
		DxLookup,
		DxTooltip,
		SearchComponent,
		PaginationComponent,
		DxCustomRule,
		DxStringLengthRule,
		DxPaging,
		// DxFilterRow,
		// DxHeaderFilter,
	},
	data: () => ({
		_customerNewID:0,
		lastUpdateObject:{},
		reload:1,
		currentIndex:0,
		currentDataleg:{ legs: ''},
		editIndex: true,
		oldPage: 1,
		markasrrived: 0,
		magdriverFilter: [],
		magdropterminal: [],
		foundsearch: false,
		metaobject: [],
		typeFilter: 0,
		sub_tab: [],
		selected_sub_tab: {},
		updatedData: 0,
		retabs: 1,
		currentStateClick: "",
		loopUnassigned: 0,
		loopAssign: 0,
		lenthDispatch: 0,
		alert: 1,
		filterAssign: ["All", "Assign", "Unassigned"],
		isDisabled: true,
		dialog: false,
		dialogData: [],
		isSubmitting: false,
		page: 1,
		pageCount: 0,
		search: "",
		tabs: [
			"Pending",
			"Available",
			"In Yard full ",
			"Assigned",
			"Dropped full",
			"Dropped empty",
			"Pending billing",
			"Completed",
			"Cancelled",
		],
		activeTab: 0,
		currentSelectTab: "All",
		tabdata: [],
		tabClicked: false,
		typeDropdown: [
			{
				label: "Customer",
				value: "Customer",
			},
			{
				label: "Yard",
				value: "Yard",
			},
			{
				label: "To Port",
				value: "Empty Return",
			},
		],
		modedropdown: [
			{
				id: 1,
				name: "Drop",
			},
			{
				id: 2,
				name: "Live",
			},
		],
		responseChassis: null,
		showEditLegModal: false,
		editingLeg: null,
		editingShipment: null,
		showAddLegModal: false,
		lastLeg: null,
		newContainersData: [],
		oldContainersData: [],
		shouldLoad: true,
		loadingData: false,
		updatedPageCount: false,
		lastPage: 1,
		oldselectedTab: "",
		selectedActiveInactiveFilter: { label: 'All', value: 'all'},
		activeInactiveFilter: [{ label: 'All', value: 'all'}, { label: 'Only Actives', value: 'active'}, {label: 'Only Inactives', value: 'inactive'}],
		selectedCustomerFilter: {id: 0, company_name: 'Select'},
		isDatePickerOpen: false,
		selectedEtaRange: [],
		pickerLabel: '',
		nudgePos: "120%",
		// for data grid table
		editOnKeyPress: false,
		enterKeyActions: ['startEdit', 'moveFocus'],
		enterKeyDirections: ['none', 'column', 'row'],
		enterKeyDirection: 'none',
		enterKeyAction: 'moveFocus',
		tooltipRefName: 'tooltip',
		tooltipRefNameNoEdit: 'tooltipA',
		tooltipRefNameDisabled: 'tooltipDisabled',
		tooltipHeaders: 'tooltipHeaders',
		tooltipRefCopy: 'tooltipCopy',
		tooltipDateInfo: 'tooltipDateInfo',
		pendingClick: 0,
		isCellEditing: false,
		buttonItems: [
			{ icon: "check", name: "confirmButton" },
			{ icon: "close", name: "rejectButton" },
		],
		cellUpdateFieldValue: {
			Container: {
				field: 'trucker_container',
				model: '',
				display_name: 'Container'
			},
			MBL: {
				field: 'mbl_num',
				model: '',
				display_name: 'MBL'
			},
			SizeID: {
				field: 'container_size_id',
				model: '',
				display_name: 'Size'
			},
			CustomerID: {
				field: 'customer_id',
				model: '',
				display_name: 'Customer'
			},
			CarrierID: {
				field: 'carrier_id',
				model: '',
				display_name: 'Carrier'
			},
			Eta: {
				field: 'eta',
				model: '',
				display_name: 'ETA'
			},
			Lfdp: {
				field: 'last_free_date',
				model: '',
				display_name: 'LFD P'
			},
			Lfdr: {
				field: 'per_diem_date',
				model: '',
				display_name: 'LFD R'
			},
			LfdpSched: {
				field: 'last_free_date',
				model: '',
				display_name: 'LFD P'
			},
			LfdrSched: {
				field: 'per_diem_date',
				model: '',
				display_name: 'LFD R'
			},
			ConsigneeName: {
				field: 'consignee_id',
				model: '',
				display_name: 'Consignee'
			},
			discharged: {
				field: 'discharged',
				model: '',
				display_name: 'Discharged'
			},
			released: {
				field: 'fully_released',
				model: '',
				display_name: 'Released'
			},
			gate_fees: {
				field: 'pier_pass_paid',
				model: '',
				display_name: 'Gate Fees'
			},
			Location: {
				field: 'terminal_id', // or location_at?
				model: '',
				display_name: 'Current Location'
			},
			EmptyNotified: {
				field: '',
				model: 'App\\Shipment',
				display_name: 'Empty Notified'
			},
			Scheduled: {
				field: '',
				model: "App\\Shipment",
				display_name: 'Scheduled'
			},
			LocationDateTime: {
				field: 'pickup_time',
				model: '',
				display_name: 'Pickup Time'
			},
			DeliveryDateTime: {
				field: 'delivery_time',
				model: '',
				display_name: 'Delivery Time'
			},
			Driver: {
				field: 'driver',
				model: '',
				display_name: 'Driver'
			}
		},
		currentSelectedCell: null,
		subTabIndex: 0,
		loadPanelOptions: {
			text: 'Loading data...',
			showPane: false,
			shading: true,
			shadingColor: '#fff',
			position: {
				my: 'top',
				at: 'top',
				of: '#data-grid-demo .dx-datagrid-content'
			},
			animation: 'disabled'
		},
		scrollingOptions: {
			showScrollbar: 'always',
			useNative: false,
		},
		typingTimeout: 0,
		isShowPagination: false,
		isUpdatingCells: false,
		isShowLoadingUI: false,
		loadedContainersData: {},
		currentTab: "",
		compactViewTabs: [
			{
				name: "All",
				count: 0,
				sub_tab: []
			},
			{
				name: "Scheduled",
				count: 0,
				sub_tab: [
					{
						label: "All",
						name: "All",
						count: 0,
					},
					{
						label: "Unassigned",
						name: "Unassigned",
						count: 0,
					},
					{
						label: "Assigned",
						name: "Assigned",
						count: 0,
					},
					{
						label: "In-Progress",
						name: "In-Progress",
						count: 0,
					},
				]
			},
			{
				name: "At Port",
				count: 0,
				sub_tab: [
					{
						label: "Pending",
						name: "Pending",
						count: 0,
					},
					{
						label: "Available",
						name: "available",
						count: 0,
					},
				]
			},
			{
				name: "At Yard",
				count: 0,
				sub_tab: [
					{
						label: "Full",
						name: "full",
						count: 0,
					},
					{
						label: "Empty",
						name: "empty",
						count: 0,
					},
				]
			},
			{
				name: "At the Customer",
				count: 0,
				sub_tab: [
					{
						label: "Full",
						name: "full",
						count: 0,
					},
					{
						label: "Empty",
						name: "empty",
						count: 0,
					},
				]
			},
			{
				name: "Pending billing",
				count: 0,
				sub_tab: []
			},
			{
				name: "Completed",
				count: 0,
				sub_tab: []
			},
			{
				name: "Cancelled",
				count: 0,
				sub_tab: []
			},
		],
		scheduledLastLegID: "",
		isLegUpdated: 0,
	}),
	computed: {
		...mapGetters({
			getPoLoading: "containers/getPoLoading",
			gettabcounter: "containers/gettabcounter",
			getAllcontainer: "containers/getAllContainers",
			getAllcontainerStatus: "containers/getAllcontainersStatus",
			getTabLoading: "containers/getTabLoading",
			getCurrentSelectTab: "containers/getCurrentSelectedTab",
			getScheduledFilterState: "containers/getScheduledFilterState",
			getDrivers: "common/getDrivers",
			getChassis: "common/getChassis",
			getTerminals: "common/getTerminals",
			getCarriers: "containers/getAllCarriers",
			getAllConsignees: 'containers/getAllConsignees',
			getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
			getUpdateIndexesLoading: 'containers/getUpdateIndexesLoading',
			getCustomers: 'customers/getCustomers',
		}),
		newData: {
			get() {
				return this.newContainersData;
			},
		},
		dataSource: {
			get() {      
				let data = []
				if (this.newContainersData.length > 0) {
					data = this.newContainersData.map(v => {
						let consingee_name = ''
						let consignee_id = ''
						let do_id = ''
						let schedule_date = ''
						let arrived_at = ''
						let emptyNotified = ''
						let driver = ''
						let driver_id = ''
						let pickupTime = null
						let deliveryTime = null
						let prepullStatus = ""
						let mode = ''
						let typeDelivery = ''

						consingee_name = v.deliveryorder !== null && v.deliveryorder.consignee !== null
							? v.deliveryorder.consignee.name : ''

						consignee_id = v.deliveryorder !== null && v.deliveryorder.consignee_id !== null
							? v.deliveryorder.consignee_id : null

						do_id = v.deliveryorder !== null && v.deliveryorder.shifl_ref !== null
							? v.deliveryorder.shifl_ref : 'N/A'

						if (this.currentSelectTab === 'At the Customer') {
							schedule_date = this.getAtTheCustomerScheduledDate(v)
							arrived_at = this.getATCTimeArrived(v)
						} else {
							schedule_date = this.getDefaultScheduledDate(v)
							arrived_at = this.getTimeArrived(v)
						}

						// for delivery time
						if (this.currentSelectTab !== 'Completed') {
							deliveryTime = this.deliveryon(v, 'time')
							emptyNotified = this.getEmptyNotified(v)
							pickupTime = this.pickupOn(v, v, 'time')
						} else {
							let lastArrivedLeg = this.getLastArrivedLeg(v)
							let lastArrivedToCustomer = this.getLastArrivedLegCustomer(v)
							let firstArrivedLeg = this.getFirstLegArrivalPickup(v)

							if (lastArrivedLeg !== null) { // for empty return
								let date = this.getDateFormat(lastArrivedLeg.arrival_at_delivery_location_date)
								let time = this.convertDefaultTime(lastArrivedLeg.arrival_at_delivery_location_time)
								let finalDisplay = this.getDateTimeFinalDisplay(date, time)
								emptyNotified = finalDisplay
							}

							if (lastArrivedToCustomer !== null) { // for delivered on
								let date = this.getDateFormat(lastArrivedToCustomer.arrival_at_delivery_location_date)
								let time = this.convertDefaultTime(lastArrivedToCustomer.arrival_at_delivery_location_time)
								let finalDisplay = this.getDateTimeFinalDisplay(date, time)
								deliveryTime = finalDisplay
							}

							if (firstArrivedLeg !== null) { // for pickup time

								// let currentDate = 
								let date = this.getDateFormat(firstArrivedLeg.arrival_at_pickup_location_date)
								let time = this.convertDefaultTime(firstArrivedLeg.arrival_at_pickup_location_time)
								let finalDisplay = this.getDateTimeFinalDisplay(date, time)
								pickupTime = finalDisplay
							}							
						}
						
						driver = this.getAssignedToDriver(v, v).driver_name
						driver_id = this.getAssignedToDriver(v, v).driver_id
						prepullStatus = this.getDirectPrepullStatus(v)
						mode = this.getMode(v)
						typeDelivery = this.getTypeDelivery(v)
						
						return {
							ID: v.id,
							Container: v.trucker_container !== null ? v.trucker_container : 'N/A',
							SizeID: v.container_size_id !== null ? v.container_size_id : '',
							MBL: v.mbl_num !== null ? v.mbl_num : 'N/A',
							DO: do_id,
							Ref: v.shifl_ref !== null ? v.shifl_ref : 'N/A',
							CustomerID: v.customer_id !== null ? v.customer_id : 'N/A',
							CarrierID: v.carrier_id !== null ? v.carrier_id : 'N/A',
							ConsigneeName: consingee_name,
							ConsigneeID: consignee_id,
							Eta: v.eta !== null ? v.eta : '',
							Lfdr: v.per_diem_date !== null && this.checkifValid(v.per_diem_date) ? this.getDateFormat(v.per_diem_date): '',
							Lfdp: v.last_free_date !== null && this.checkifValid(v.last_free_date) ? this.getDateFormat(v.last_free_date) : '',
							discharged: v.discharged == 1 ? true : false,
							released: v.fully_released == 1 ? true : false,
							gate_fees: v.pier_pass_paid == 1 ? true : false,
							Type: v.is_export === 1 ? 'Export' : 'Import',
							Scheduled: schedule_date,
							ArrivedAt: arrived_at,
							EmptyNotified: emptyNotified,
							DeliveryLocation: '',
							Driver: driver,
							DriverID: driver_id,
							Status: '',
							LocationDateTime: pickupTime,
							DeliveryDateTime: deliveryTime,
							DirectPrepull: prepullStatus,
							Opened: v.opened_at !== null ? this.getDateFormat(v.opened_at) : "",
							Closed: v.closed_at !== null ? this.getDateFormat(v.closed_at) : "",
							Mode: mode,
							TypeDelivery: typeDelivery,
							...v
						}
					})
				}

				return data
			}
		},
		customerLists() {
			let data = []

			if (this.customers.length > 0) 
				data = this.customers			

			return data
		},
		driverLists() {
			let data = []

			if (this.magdriver.length > 0) 
				data = this.magdriver			

			return data
		},
		dataSourceConsignee() {
			let data = []

			if (typeof this.getAllConsigneesLists !== 'undefined' && this.getAllConsigneesLists !== null &&
				typeof this.getAllConsigneesLists.results !== 'undefined' && this.getAllConsigneesLists.results !== null) {
				data = this.getAllConsigneesLists.results
			}

			return data
		},
		sizeLists() {
			let data = []

			if (this.shipmentsize !== null && this.shipmentsize.length > 0) {
				data = this.shipmentsize
			}

			return data
		},
		carrierLists() {
			let data = []

			if (typeof this.getCarriers !== 'undefined' && this.getCarriers !== null &&
				typeof this.getCarriers.data !== 'undefined' && this.getCarriers.data !== null) {
				data = this.getCarriers.data
			}

			return data
		},
		tabItems() {
			// let tabs = this.newtabitem
			// return tabs

			var s = this.compactViewTabs;

			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.data !== "undefined" &&
					this.getAllcontainerStatus.data.length !== "undefined") {
					var dataTabs = Object.values(this.getAllcontainerStatus.data);

					if (dataTabs) {
						this.compactViewTabs.map((element) => {
							if (element.name !== "") {
								let thename = "";

								if (element.name == "All") {
									thename = "all_tab";
								} else if (element.name == "At Port") {
									thename = "Pending,available";
								} else if (element.name == "Yard full") {
									thename = "in_yard_full";
								} else if (element.name == "At Yard") {
									thename = "at_yard";
								} else if (element.name == "Available") {
									thename = "available";
								} else if (element.name == "Assigned") {
									thename = "assigned";
								} else if (element.name == "Scheduled") {
									thename = "assigned";
								} else if (element.name == "At the Customer") {
									thename = "at_the_customer";
								} else if (element.name == "Completed") {
									thename = "completed";
								} else if (element.name == "Cancelled") {
									thename = "cancelled";
								} else {
									var c = element.name.toLowerCase();
									thename = c.charAt(0).toUpperCase() + c.slice(1);
								}
								const result = dataTabs.filter((find) => thename.split(",").includes(find.name));
								element.count = 0;

								Object.values(result).forEach(function (f) {
									if (["at_the_customer", "at_yard"].includes(f.name)) {
										element.sub_tab.map(v => {
											if (v.name === "full") {
												v.count = f.full.count
											} else {
												v.count = f.empty.count
											}
										})
									} else
										element.sub_tab.forEach(function (s_tab) {
											if (s_tab.name.toLowerCase() == f.name.toLowerCase()) {
												s_tab.count = parseInt(f.count);
											}
										});
									element.count += parseInt(f.count);
								});

								if (element.name == "Assigned") {
									element.name = "Scheduled";
								}
							}
						});
						s = this.compactViewTabs;
					}
				}
			}
			return s;
		},
		isShowMenuTab() {
			let show = true

			let fieldsShow = ['All', 'Pending billing', 'Completed', 'Cancelled']
			let shouldFieldShow = _.find(fieldsShow, c => c === this.currentSelectTab)

			if (shouldFieldShow !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		showTableTemporarily() {
			let show = true

			let fieldsShow = ['All', 'Scheduled', 'At Port', 'At Yard', 'At the Customer', 'Pending billing']
			let shouldFieldShow = _.find(fieldsShow, c => c === this.currentSelectTab)

			if (shouldFieldShow !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isShowETA() {
			let show = true
			let showTabs = ['At Yard', 'At the Customer', "Scheduled"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = false
			} else {
				show = true
			}

			return show
		},
		isShowScheduledColumn() {
			let show = true
			let showTabs = ['At Port', 'At Yard', 'At the Customer']
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true

				if (findShowTab === 'At Port' && this.selected_sub_tab.name !== 'available') {
					show = false
				}
			} else {
				show = false
			}

			return show
		},
		isShowLfdp() {
			let show = true
			let showTabs = ['All', 'At Port', "Pending billing", "Completed"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isShowLfdr() {
			let show = true
			let showTabs = ['All', 'At Yard', "At the Customer", "Pending billing", "Completed"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		isTabLFDCaption() { // if these are the current tabs, show LFD R as caption
			let show = true
			let showTabs = ['All', "Pending billing", "Completed", "Cancelled"]
			let findShowTab = _.find(showTabs, c => c === this.currentSelectTab)

			if (findShowTab !== undefined) {
				show = true
			} else {
				show = false
			}

			return show
		},
		// edit leg
		getEditingShipmentEta: {
			get() {
				if (this.editingShipment && this.editingShipment.eta) {
					return moment(this.editingShipment.eta)
					.utc(false)
					.format("YYYY-MM-DD");
				}
				return null;
			},
		},
		editingLegPickupLocation() {
			if (this.editingLeg != null && this.editingShipment != null) {
				let legIndex = this.currentEditingLegIndex;

				try {
					if (legIndex == 0)
						return this.getTerminals.find((t) => t.id == this.editingShipment.terminal_id).name;
				} catch (err) {
					return "";
				}

				if (this.editingShipment.dispatch_schedule != "") {
					let dispatch_schedule = null;

					try {
						dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);
					} catch (err) {
						console.log(err);
					}

					if (dispatch_schedule) {
						let legs = dispatch_schedule.legs || {};

						if (legs.length > 0) {
							let address = legs[0].address;
							for (let i = 1; i < legs.length; i++) {
								if (legs[i].id == this.editingLeg.id) {
									return address;
								}
								address = legs[i].address;
							}
						}
					}
				}
			}
			return "";
		},
		currentEditingLegIndex() {
			if (this.editingLeg != null && this.editingShipment != null) {
				if (this.editingShipment.dispatch_schedule != "") {
					let dispatch_schedule = null;

					try {
						dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);
					} catch (err) {
						console.log(err);
					}
					if (dispatch_schedule) {
						let legs = dispatch_schedule.legs || {};
						if (legs.length > 0) {
							for (let i = 0; i < legs.length; i++) {
								if (legs[i].id == this.editingLeg.id) {
									return i;
								}
							}
						}
					}
				}
			}
			return 0;
		},
		getPickupLocation() {
			if (this.editingShipment != null) {
				try {
					if (this.editingShipment.dispatch_schedule) {
						let dispatch_schedule = JSON.parse(this.editingShipment.dispatch_schedule);

					if (dispatch_schedule && typeof dispatch_schedule.legs != "undefined" &&
						dispatch_schedule.legs.length > 0)
						return dispatch_schedule.legs[dispatch_schedule.legs.length - 1].address;
					}

					if (this.editingShipment.terminal_id)
						return this.getTerminals.find((t) => t.id == this.editingShipment.terminal_id).name;
				} catch (err) {
					console.log(err);
				}
			}
			return "";
		},
		isShowSearchComponent() {
			if (this.currentSelectTab === 'Scheduled' || this.currentSelectTab === 'Pending billing' ||
				this.currentSelectTab === 'Completed' || this.currentSelectTab === 'Cancelled') {
				return false
			} else {
				if (this.dataSource.length > 0) {
					return true
				} else {
					if (this.search === '') {
						return false
					} else {
						return true
					}
				}
			}
		},
		isShowDivider() {
			if (this.sub_tab.length > 0) {
				return true
			} else {
				if (this.currentSelectTab === 'Scheduled' || this.currentSelectTab === 'Pending billing' ||
					this.currentSelectTab === 'Completed' || this.currentSelectTab === 'Cancelled') {
					return false
				} else {
					if (this.dataSource.length > 0) {
						return true
					} else {
						return false
					}
				}
			}
		},
	},
	methods: {
		...mapActions({
			fetchavailable: "containers/fetchAvailable",
			fetchyardfull: "containers/yardfull",
			fetchassigned: "containers/assigned",
			fetchdroppedfull: "containers/droppedfull",
			fetchdroppedempty: "containers/droppedEmpty",
			fetchpendingbilling: "containers/pendingBilling",
			fetchcompleted: "containers/completed",
			storeEditData: "containers/passData",
			storeCurrentTabSelected: "containers/currentTabContainer",
			setFilter: "containers/setScheduleFilterState",
			setContainerEmptyStatus: "containers/setContainerEmptyStatus",
			fetchConsignees: 'containers/fetchConsignees',
			updateTableIndexes: 'containers/updateTableIndexes',
			createConsignee: 'containers/createConsignee',
			fetchCustomers: 'customers/fetchCustomers',
		}),
		...globalMethods,
		async getCurrentTab(id, i, tab) {
			this.page = 1
			this.search = ""
			this.isUpdatingCells = false

			if (this.currentSelectTab !== id) {
				this.newContainersData = !this.isUpdatingCells ? [] : this.newContainersData
				if (cancel !== undefined) {
					cancel("cancel_previous_request")
				}

				this.sub_tab = tab.sub_tab !== undefined ? tab.sub_tab : [];
				if (tab.sub_tab.length > 0) this.selected_sub_tab = tab.sub_tab[0];
				if (!this.getTabLoading) {
					if (["Cancelled", "Completed"].includes(id)) {
						this.selected_sub_tab = {};
					}
					this.currentSelectTab = id;
					this.tabClicked = true;
					this.storeCurrentTabSelected({
						id: id,
						activeTab: i,
						sub_tab: this.selected_sub_tab
					});
				}
				this.subTabIndex = 0
				setTimeout(() => {
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
				}, 1)
			}
		},
		async getCurrentSubTab(subtab, i) {
			this.search = ""
			this.isUpdatingCells = false
			this.page = 1

			if (this.selected_sub_tab !== subtab) {
				this.newContainersData = !this.isUpdatingCells ? [] : this.newContainersData
				if (cancel !== undefined) {
					cancel("cancel_previous_request")
				}

				let current_tab_item = this.getCurrentSelectTab
				this.subTabIndex = i
				this.selected_sub_tab = subtab

				this.storeCurrentTabSelected({
					id: current_tab_item.id,
					activeTab: current_tab_item.activeTab,
					sub_tab: subtab,
					subTabIndex: i
				});
				setTimeout(() => {
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
				}, 1)
			}
		},
		convertDateFormatDefault(date) {
			if (date !== '' && date !== null) {
				return moment(date).format('MM/DD/YY')
			} else {
				return 'N/A'
			}
		},
		/** 
		 *  Alex added 
		 */
		detectchangesReloadchanges(){
				delete this.loadedContainersData[this.currentTab];
				delete this.loadedContainersData['assigned-schedule'];
				delete this.loadedContainersData['unassigned-schedule'];
				delete this.loadedContainersData['all-scheduled'];				
				delete this.loadedContainersData['all'];
		},
		getDateFormat(date) {
			// if (date) {
			// 	if (this.checkifValid(date, "MM/DD/YY")) {
			// 		return moment(date).utc(false).format("MM/DD/YY");
			// 	} else {
			// 		return "";
			// 	}
			// }
			// return "";

			// display exact same date from the API
			return this.convertDateFormatDefault(date)
		},
		// check to time
		checkTotime(data) {
			if (typeof data != "undefined") {
				return this.timeConvert(data);
			} else {
				return "";
			}
		},
		// time convert
		timeConvert(dateString) {
			const d = dateString;
			const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

			if (validHHMMstring(d)) {
				const timeString12hr = new Date("1970-01-01T" + d + "Z").toLocaleTimeString("en-US", {
					timeZone: "UTC",
					hour12: true,
					hour: "numeric",
					minute: "numeric",
				});
				return timeString12hr.replace(/\s/g, "");
			} else {
				return "";
			}
		},
		convertDefaultTime(time) {
			if (time !== '' && time !== null) {
				return moment(time, ["HH:mm"]).format("hh:mm A")
			} else {
				return ''
			}
		},
		addDays(dateTime, count_days) { // for adding days to date passed in params method
			return new Date(new Date(dateTime).setDate(dateTime.getDate() + count_days));
		},
		subtractDays(dateTime, count_days) {
			return new Date(new Date(dateTime).setDate(dateTime.getDate() - count_days));
		},
		selectedTabData(tab, subTab = this.selected_sub_tab, page) {
			// without loading 
			if (tab == "All") {
				if(typeof this.loadedContainersData["all"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("all", page)
				} else {
					this.getSelectedTabData("all", page);
				}
			} else if (tab == "At Port") {
				if (subTab.label == "Pending") {
					if(typeof this.loadedContainersData["at-port-pending"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-port-pending", page)
					} else {
						this.getSelectedTabData("at-port-pending", page);
					}
					} else {
					if(typeof this.loadedContainersData["at-port-available"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-port-available", page)
					} else {
						this.getSelectedTabData("at-port-available", page);
					}
				}
			} else if (tab == "At Yard") {
				if (subTab.name == "full") {
					if(typeof this.loadedContainersData["at-yard-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-yard-full", page)
					} else {
						this.getSelectedTabData("at-yard-full", page);
					}
				} else {
					if(typeof this.loadedContainersData["at-yard-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-yard-empty", page)
					} else {
						this.getSelectedTabData("at-yard-empty", page);
					}
				}
			} else if (tab == "At the Customer") {
				if (subTab.name == "full") {
					if(typeof this.loadedContainersData["at-customer-full"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-customer-full", page)
					} else {
						this.getSelectedTabData("at-customer-full", page);
					}
				} else {
					if(typeof this.loadedContainersData["at-customer-empty"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("at-customer-empty", page)
					} else {
						this.getSelectedTabData("at-customer-empty", page);
					}
				}
			} else if (tab == "Pending billing") {
				if(typeof this.loadedContainersData["pending-billing"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("pending-billing", page)
				} else {
					this.getSelectedTabData("pending-billing", page);
				}
			} else if (tab == "Completed") {
				if(typeof this.loadedContainersData["completed"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("completed", page)
				} else {
					this.getSelectedTabData("completed", page);
				}
			} else if (tab == "Cancelled") {
				if(typeof this.loadedContainersData["cancelled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
					this.getLoadedData("cancelled", page)
				} else {
					this.getSelectedTabData("cancelled", page);
				}
			} else if (tab == "Scheduled") {
				if (subTab == "Assigned" || subTab.name == "Assigned") {
					if(typeof this.loadedContainersData["assigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("assigned-schedule", page)
					} else {
						this.getSelectedTabData("assigned-schedule", page);
					}
				} else if (subTab == "Unassigned" || subTab.name == "Unassigned") {
					if(typeof this.loadedContainersData["unassigned-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("unassigned-schedule", page)
					} else {
						this.getSelectedTabData("unassigned-schedule", page);
					}
				} else if (subTab == "In-Progress" || subTab.name == "In-Progress") {
					if(typeof this.loadedContainersData["in-transit-schedule"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("in-transit-schedule", page)
					} else {
						this.getSelectedTabData("in-transit-schedule", page);
					}
				} else {
					if(typeof this.loadedContainersData["all-scheduled"] !== 'undefined' && this.search === "" && !this.isLegUpdated) {
						this.getLoadedData("all-scheduled", page)
					} else {
						this.getSelectedTabData("all-scheduled", page);
					}
				}
			}

			// with loading part
			// if (tab == "All") {
			// 	this.getSelectedTabData("all", page);
			// } else if (tab == "At Port") {
			// 	if (subTab.label == "Pending") {
			// 		this.getSelectedTabData("at-port-pending", page);
			// 	} else {
			// 		this.getSelectedTabData("at-port-available", page);
			// 	}
			// } else if (tab == "At Yard") {
			// 	if (subTab.name == "full") {
			// 		this.getSelectedTabData("at-yard-full", page)
			// 	} else {
			// 		this.getSelectedTabData("at-yard-empty", page);
			// 	}
			// } else if (tab == "At the Customer") {
			// 	if (subTab.name == "full") {
			// 		this.getSelectedTabData("at-customer-full", page);
			// 	} else {
			// 		this.getSelectedTabData("at-customer-empty", page);
			// 	}
			// } else if (tab == "Pending billing") {
			// 	this.getSelectedTabData("pending-billing", page);
			// } else if (tab == "Completed") {
			// 	this.getSelectedTabData("completed", page);
			// } else if (tab == "Cancelled") {
			// 	this.getSelectedTabData("cancelled", page);
			// } else if (tab == "Scheduled") {
			// 	if (subTab == "Assign" || subTab.name == "Assign") {
			// 		this.getSelectedTabData("assigned-schedule", page);
			// 	} else if (subTab == "Unassigned" || subTab.name == "Unassigned") {
			// 		this.getSelectedTabData("unassigned-schedule", page);
			// 	} else {
			// 		this.getSelectedTabData("all-scheduled", page);
			// 	}
			// }
		},
		async getSelectedTabData(tab, page) { // call API for each tabs
			this.oldContainersData = this.newData;
			this.shouldLoad = true
			this.oldPage = this.page
			this.currentTab = tab

			let passedData = {
				method: "get",
				url: `${poBaseUrl}/shipment-tabs/${tab}`,
				params: {
					page,
					customer_id: this.selectedCustomerFilter.id,
					status: this.selectedActiveInactiveFilter.value,
					etaStart : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '',
					etaEnd : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : '',
					per_page: 20
				},
				cancelToken: new CancelToken(function executor(c) {
					cancel = c
				}),
			}

			if (this.search !== null && this.search !== "") {
				passedData.params.search = this.search

				await axios(passedData)
				.then((response) => {
					this.page = page;
					this.lastPage = response.data.results.last_page;
					if (response.data.results.last_page > 1) {
						this.pageCount = response.data.results.last_page;
					}

					let datum = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].data : []
					let pages = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].page : []
					datum.push(response.data.results.data)
					pages.push(page)

					this.loadedContainersData[tab] = {
						data: datum,
						page: pages,
						pageCount: response.data.results.last_page,
						lastPage: response.data.results.last_page
					}

					this.newContainersData = response.data.results.data;
				})
				.catch((error) => {
					if (typeof error.data !== 'undefined') {
						alert(error.data.message);
					}
				});
			} else {
				if (typeof page == 'undefined')
					page = 1;

				await axios(passedData)
				.then((response) => {
					if(typeof response.data.components !== "undefined") {
						this.magdropterminal = response.data.components.loccationfilter;
					}

					if (typeof response.data.driversDropdown !== "undefined") {
						this.magdriverFilter = response.data.driversDropdown;
					}

					this.page = page;
					this.lastPage = response.data.results.last_page;

					if (this.pageCount !== response.data.results.last_page) {
						this.pageCount = response.data.results.last_page;
					}

					let datum = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].data : []
					let pages = typeof this.loadedContainersData[tab] !== 'undefined' ? this.loadedContainersData[tab].page : []
					datum.push(response.data.results.data)
					pages.push(page)

					this.loadedContainersData[tab] = {
						data: datum,
						page: pages,
						pageCount: response.data.results.last_page,
						lastPage: response.data.results.last_page
					}

					this.newContainersData = response.data.results.data;
				})
				.catch((error) => {
					if (typeof error.data !== 'undefined') {
						alert(error.data.message);
					}
				});
			}

			this.shouldLoad = false;
			this.isDisabled = false;
			this.isUpdatingCells = false;
			this.isLegUpdated = 0
		},
		getLoadedData(tab, page) {
			console.log(page)
			console.log(this.loadedContainersData)

			if(!this.loadedContainersData[tab].page.includes(page)) {
				this.getSelectedTabData(tab, page)
			} else {
				this.currentTab = tab
				this.page = page
				this.newContainersData = this.loadedContainersData[tab].data[this.getLoadedDataPage()]
				this.lastPage = this.loadedContainersData[tab].lastPage
				this.pageCount = this.loadedContainersData[tab].pageCount

				setTimeout(() => {
					this.isDisabled = false
				}, 100)
			}
		},
		getLoadedDataPage() {
			let index = this.loadedContainersData[this.currentTab].page.findIndex((p) => {
				return p === this.page
			})

			return index
		},
		goToDetailsPage(e) {
			localStorage.setItem("queryData", this.currentSelectTab);
			this.storeEditData({
				items: e.data,
				objectMeta: this.metaobject,
				files: this.mgaFiles,
				drivers: this.magdriver,
				terminals: this.terminals,
				customers: this.customers,
				shipmentsize: this.shipmentsize,
				currentState: this.currentSelectTab,
			});
			this.$router.push("/container/edit/" + e.data.id).catch(()=>{});
		},
		// data grid methods
		onFocusedCellChanging(e) {
			if (this.currentSelectTab === 'All' || this.currentSelectTab === 'Pending billing') {
				let exlcudedColumnIndexes = [3, 4, 5, 12]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else if (this.currentSelectTab === 'Scheduled') {
				let exlcudedColumnIndexes = [3, 4, 5, 12, 13, 14, 15, 16, 17, 18]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else if (this.currentSelectTab === 'At Port') {
				let exlcudedColumnIndexes = [3, 4, 5, 12, 13, 14, 15]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else if (this.currentSelectTab === 'At Yard' || this.currentSelectTab === 'At the Customer') {
				let exlcudedColumnIndexes = this.currentSelectTab === 'At Yard' ?
					[3, 4, 5, 9, 10, 12, 13] : [3, 4, 5, 10, 11, 13, 14]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else if (this.currentSelectTab === 'Cancelled') {
				let exlcudedColumnIndexes = [3, 4, 5, 10, 11, 12]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else if (this.currentSelectTab === 'Completed') {
				let exlcudedColumnIndexes = [3, 4, 5, 11, 12, 14, 15, 16]
				let isColumnExcluded = exlcudedColumnIndexes.find(c => c === e.newColumnIndex)

				if (isColumnExcluded !== undefined) { // DO and Ref
					e.isHighlighted = false;
				} else {
					e.isHighlighted = true;
				}
			} else {
				e.isHighlighted = true;
			}
		},
		onCellPrepared(e) {
			if (e.rowType !== "data") {
				return;
			} else if (e.rowType === "data") {
				var self = this;
				// let customer_id = e.data.CustomerID

				if (e.column.dataField !== "Ref" && e.column.dataField !== "DO" && e.column.caption !== 'Action' &&
					e.column.dataField !== 'Type' && e.column.caption !== "Picked Up" && e.column.caption !== "Delivered On" && 
					e.column.caption !== "Empty Returned" && e.column.dataField !== "DirectPrepull" && e.column.caption !== "Opened" &&
					e.column.caption !== "Closed" && e.column.caption !== "Mode" && e.column.caption !== "Status" && 
					e.column.caption !== "Pickup Location" && e.column.caption !== "Pickup Time" && e.column.caption !== "Delivery On" &&
					e.column.caption !== "Delivery Time") {
						
					let today = new Date()
					let todayConvert = this.getDateFormat(today)

					let defaultBg = "#FFFFFF"
					let color = "#4a4a4a"
					let border = ""

					// Start - for changing background colors
					if (e.column.dataField === "Lfdp" || e.column.dataField === "Lfdr") {
						if (!this.isTabLFDCaption) {
							let data = e.data
							let dispatch_schedule = data.dispatch_schedule !== null ? JSON.parse(data.dispatch_schedule) : null

							if (dispatch_schedule !== null) {
								let legs = dispatch_schedule.legs || []
								let leg = null
								let legIndex = null

								try {
									if (legs.length > 0) {
										leg = legs.find(leg => !leg.completed)

										if (leg !== undefined) {
											let index = _.findIndex(legs, e => e.id === leg.id)
											legIndex = index + 1
										}
									}
								} catch (e) {
									console.log(e, 'error');
								}

								let lfdDate = e.column.dataField === "Lfdp" ? e.data.Lfdp : e.data.Lfdr

								if (e.column.dataField === "Lfdp") {
									if (legIndex === 1) {
										if (moment(lfdDate).isSame(todayConvert)) {
											defaultBg = "#D68A1A"
											color = "#ffffff"
											border = "#EBF2F5"
										} else if (moment(todayConvert).isAfter(lfdDate)) {
											defaultBg = "#F93131"
											color = "#ffffff"
											border = "#EBF2F5"
										}
									}
								} else {
									if (legIndex > 1) {
										if (moment(lfdDate).isSame(todayConvert)) {
											defaultBg = "#D68A1A"
											color = "#ffffff"
											border = "#EBF2F5"
										} else if (moment(todayConvert).isAfter(lfdDate)) {
											defaultBg = "#F93131"
											color = "#ffffff"
											border = "#EBF2F5"
										}
									}
								}
							}

							e.cellElement.bgColor = defaultBg // set cell background
							e.cellElement.style.color = color
							e.cellElement.style.borderColor = border
						}
					}

					if (e.column.dataField === "ArrivedAt") {
						var arrivedDate = this.getDateFormat(e.data.ArrivedAt)
						let result = ""
						// var new_date = moment(moment(date).fromNow()) - shows days ago

						if (arrivedDate !== "" && arrivedDate !== "N/A") {
							result = moment(todayConvert).diff(moment(arrivedDate), 'd')

							if (result > 0) {
								if (result >= 7 && result <= 14 ) {
									defaultBg = "#D68A1A"
									color = "#FFFFFF"
									border = "#EBF2F5"
								} else if (result > 14) {
									defaultBg = "#F93131"
									color = "#FFFFFF"
									border = "#EBF2F5"
								}
							}
						}
						e.cellElement.bgColor = defaultBg // set cell background
						e.cellElement.style.color = color
						e.cellElement.style.borderColor = border

						on(e.cellElement, "click", arg => {
							if (pendingClick) {
								clearTimeout(pendingClick);
							}

							if (arg.originalEvent.detail == 1) {
								// single click
								self.$refs[this.tooltipRefNameNoEdit].instance.hide();
							} else if (arg.originalEvent.detail == 2) {
								// double click
								self.$refs[this.tooltipRefNameNoEdit].instance.show(arg.target);
							}
						});
					}

					if (e.column.dataField === "EmptyNotified") {
						if (!this.isTabLFDCaption) {
							var notifiedDate = this.getDateFormat(e.data.EmptyNotified)
							let result = ""

							if (notifiedDate !== "") {
								result = moment(todayConvert).diff(moment(notifiedDate), 'd')

								if (result > 0) {
									if (result <= 4) {
										defaultBg = "#D68A1A"
										color = "#FFFFFF"
										border = "#EBF2F5"
									} else if (result >= 5) {
										defaultBg = "#F93131"
										color = "#FFFFFF"
										border = "#EBF2F5"
									}
								}
							}
							e.cellElement.bgColor = defaultBg // set cell background
							e.cellElement.style.color = color
							e.cellElement.style.borderColor = border
						}
					}
					// End - for changing background colors

					if (e.column.dataField === 'discharged' || e.column.dataField === 'released') {
						jQuery(e.cellElement).addClass('checkboxes-td') // set cell class
					}

					if (e.column.dataField === 'gate_fees') {
						jQuery(e.cellElement).addClass('checkboxes-td gate') // set cell class
					}

					if (e.column.dataField === "Scheduled") {
						if (e.value === 'Not Scheduled' || e.text === 'Not Scheduled') {
							jQuery(e.cellElement).addClass('not-scheduled') // set cell class
						}

						let first_unarrived_leg = this.getFirstUnArrivedLeg(e.data);

						if (first_unarrived_leg === null) {
							e.column.allowEditing = false
							e.cellElement.bgColor = "#F5F9FC" // set cell background

							if (!e.component._isCellEditing) {
								e.component._isCellEditing = false;
								return;
							}
						} else {
							on(e.cellElement, "click", arg => {
								if (pendingClick) {
									clearTimeout(pendingClick);
								}

								if (arg.originalEvent.detail == 1) {
									// single click
									self.$refs[this.tooltipRefNameNoEdit].instance.hide();
								} else if (arg.originalEvent.detail == 2) {
									// double click
									self.$refs[this.tooltipRefNameNoEdit].instance.show(arg.target);
								}
							});
						}
					}

					if (this.currentSelectTab === "At Yard" && e.column.caption === "Current Location") {
						on(e.cellElement, "click", arg => {
							if (pendingClick) {
								clearTimeout(pendingClick);
							}

							if (arg.originalEvent.detail == 1) {
								// single click
								self.$refs[this.tooltipRefNameNoEdit].instance.hide();
							} else if (arg.originalEvent.detail == 2) {
								// double click
								self.$refs[this.tooltipRefNameNoEdit].instance.show(arg.target);
							}
						});
					}

					// on click
					on(e.cellElement, "click", async (arg) => {
						if (pendingClick) {
							clearTimeout(pendingClick);
						}

						if (arg.target.id !== 'i-icon-copy') {
							if (this.currentSelectedCell === null) {
								this.currentSelectedCell = e
								cellCounter+=1
							} else {
								if (this.currentSelectedCell.columnIndex === e.columnIndex &&
									this.currentSelectedCell.rowIndex === e.rowIndex) {
									let countUntil = 2

									if (cellCounter < countUntil) {
										cellCounter+=1
									}
								} else {
									cellCounter = 1
									this.currentSelectedCell = e
								}
							}
						} else {
							cellCounter = 0
						}

						if (cellCounter === 1) {
							if (arg.target.id !== 'i-icon-copy') {
								if (e.column.allowEditing) {
									if (e.column.dataField !== 'discharged' && e.column.dataField !== 'released' &&
										e.column.dataField !== 'gate_fees') {
										self.$refs[this.tooltipRefName].instance.show(arg.target);
									} else 
										self.$refs[this.tooltipRefName].instance.hide();
								} else 
									self.$refs[this.tooltipRefName].instance.hide();
							} else 
								self.$refs[this.tooltipRefName].instance.hide();

						} else if (cellCounter === 2) {
							self.$refs[this.tooltipRefName].instance.hide();

							if (e.column.allowEditing) {
								if (!e.component._isCellEditing) {
									if (cellCounter === 2) {
										e.component._isCellEditing = true;
										e.component.editCell(e.rowIndex, e.column.dataField);
									}

									e.component._isCellEditing = false;
									return;
								}
							}
						}
					});
				} else {
					if (e.column.caption !== 'Action') {
						on(e.cellElement, "click", arg => {
							if (pendingClick) {
								clearTimeout(pendingClick);
							}

							if (arg.originalEvent.detail == 1) {
								// single click
								self.$refs[this.tooltipRefNameNoEdit].instance.hide();
							} else if (arg.originalEvent.detail == 2) {
								// double click
								self.$refs[this.tooltipRefNameNoEdit].instance.show(arg.target);
							}
						});
					}
				}
			}
		},
		onCellHoverChanged(e) { // when hovering cell method
			if (e.rowType === 'data') {
				var self = this;

				if (e.column.name === 'Container') {
					if (e.eventType === 'mouseover') {
						jQuery(e.cellElement).addClass('hovered')

						if (e.event.target.id === 'i-icon-copy') {
							self.$refs[this.tooltipRefCopy].instance.show(e.event.target)
						}
					} else {
						jQuery(e.cellElement).removeClass('hovered')
						self.$refs[this.tooltipRefCopy].instance.hide();
					}
				}

				if (e.column.dataField === "Scheduled") { // show tooltip message to add a leg first
					let first_unarrived_leg = this.getFirstUnArrivedLeg(e.data);

					if (first_unarrived_leg === null) {
						if (e.eventType === 'mouseover') {
							self.$refs[this.tooltipRefNameDisabled].instance.show(e.event.target);
						} else {
							self.$refs[this.tooltipRefNameDisabled].instance.hide();
						}
					} else {
						self.$refs[this.tooltipRefNameDisabled].instance.hide();
					}
				}

				// for dates
				// if (e.column.dataField === "Lfdr") {
				// 	if (e.cellElement.bgColor !== "") {
				// 		let lfdrDate = moment(e.value).format("MM-DD-YYYY")
				// 		var new_date = moment(lfdrDate).fromNow()

				// 		if (e.eventType === 'mouseover') {
				// 			self.$refs[this.tooltipDateInfo].instance._$popupContent[0].innerText = new_date
				// 			self.$refs[this.tooltipDateInfo].instance.show(e.event.target);
				// 		} else {
				// 			self.$refs[this.tooltipDateInfo].instance.hide();
				// 		}
				// 	}
				// }
			}

			// tooltip for header
			if (e.rowType === "header") {
				var headerSelf = this;
				let text = ''

				let columns = ['discharged', 'released', 'gate_fees']
				let isColumnExcluded = columns.find(c => c === e.column.name)

				if (isColumnExcluded !== undefined) {
					if (e.eventType === 'mouseover') {
						if (e.column.name === 'discharged') {
							text = 'Discharged'
						} else if (e.column.name === 'released') {
							text = 'Released'
						} else if (e.column.name === 'gate_fees') {
							text = 'Gate Fees'
						}

						headerSelf.$refs[this.tooltipHeaders].instance._$popupContent[0].innerText = text
						headerSelf.$refs[this.tooltipHeaders].instance.show(e.event.target)
					} else {
						headerSelf.$refs[this.tooltipHeaders].instance.hide()
					}
				}
			}
		},
		onEditingStart(e) {
			e.component.isEditing = true
			this.isCellEditing = true
		},
		onContentReady: function(e) {
			if (this.shouldLoad) {
				this.isShowPagination = false
				this.isShowLoadingUI = true
			} else {
				// e.component.endCustomLoading()
				// e.component.getScrollable().scrollTo(0) // reset scrollbar position on click tabs

				var dataGridHeaders = document.querySelector(".dx-datagrid-headers");
				var noData = document.querySelector(".dx-datagrid-nodata");
				if (noData) {
					if (this.dataSource.length === 0) {
						// noData.classList.remove('dx-hidden')
						noData.innerText = 'No available data'
						dataGridHeaders.classList.add('dx-hidden')
					}
				}

				var gridData = document.querySelector("#gridContainer");
				if (e.component.getVisibleRows().length > 0) {
					this.isShowLoadingUI = false
					dataGridHeaders.classList.remove('dx-hidden')

					if (this.lastPage > 1) {
						setTimeout(() => {
							gridData.classList.add('has-multiple-pages')
							this.isShowPagination = true
						}, 50);
					} else {
						gridData.classList.remove('has-multiple-pages')
						this.isShowPagination = false
					}
				} else {
					gridData.classList.remove('has-multiple-pages')
					this.isShowPagination = false

					if (!this.shouldLoad) {
						this.isShowLoadingUI = false
					}
				}
			}

			var isEditing = e.component.getVisibleRows().filter(function(r) {
				return r.isEditing == true;
			});

			if (isEditing.length == 0) {
				// outside click cancelled content
				isEditing = false
				this.isCellEditing = false
			}
		},
		onEditorPreparing: function(e) {
			if (e.parentType == "dataRow") {
				e.editorOptions.onFocusOut = function() {
					setTimeout(function() {
						// check with a delay
						var isEditing = e.component.getVisibleRows().filter(function(r) {
							return r.isEditing == true;
						});
						if (isEditing.length == 0) {
							// outside click cancelled
							isEditing = false
							this.isCellEditing = false
						}
					}, 300);
				}

				// show min date for lfd and pdd
				if (e.dataField === "Lfdp") {
					// set min and max date for lfd field
					e.editorOptions.allowClearing = true; // allow accept no value
					let minDate = this.getDateFormat(e.row.data.Eta)
					let maxDate = this.getDateFormat(e.row.data.Lfdr)
					let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';
					let maxDateAddDays = maxDate !== 'N/A' || maxDate !== null ? new Date(maxDate) : '';
					e.editorOptions.min = minDate !== '' ? this.addDays(minDateAddDays, 1) : '';
					e.editorOptions.max = maxDate !== '' ? this.subtractDays(maxDateAddDays, 1) : '';
				}

				if (e.dataField === "Lfdr") {
					e.editorOptions.allowClearing = true; // allow accept no value
					// let minDate = e.row.data.Lfdp !== '' ? this.getDateFormat(e.row.data.Lfdp) : null
					// let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';

					// let minDateETA = this.getDateFormat(e.row.data.Eta)
					// let minDateAddDaysETA = minDateETA !== 'N/A' || minDateETA !== null ? new Date(minDateETA) : '';

					// e.editorOptions.min = minDate !== '' ? this.addDays(minDateAddDays, 1) : 
					// 	(minDateAddDaysETA !== '' ? this.addDays(minDateAddDaysETA, 1) : '');

					// if lfd is null or empty, get ETA value
					let minDate = e.row.data.Lfdp !== '' ? this.getDateFormat(e.row.data.Lfdp) : this.getDateFormat(e.row.data.Eta)
					let minDateAddDays = minDate !== 'N/A' || minDate !== null ? new Date(minDate) : '';
					e.editorOptions.min = minDate !== '' ? this.addDays(minDateAddDays, 1) : '';
				}

				if (e.dataField === 'ConsigneeName') {
					e.editorOptions.allowClearing = true;
					e.editorOptions.dropDownOptions = {
						toolbarItems: [{
							toolbar:'bottom',
							location: 'left',
							widget: 'dxButton',
							options: {
								text: '+ Add New',
								onClick: () => {
									this.addNewConsigneeItem(e)
								}
							}
						}]
					};
				}

				if (e.dataField === 'CustomerID') {
					e.editorOptions.dropDownOptions = {
						toolbarItems: [{
							toolbar:'bottom',
							location: 'left',
							widget: 'dxButton',
							options: {
								text: '+ Add New',
								onClick: () => {
									this.addNewCustomer(e)
								}
							}
						}]
					};
				}

				if (e.dataField === 'discharged' || e.dataField === 'released' || e.dataField === 'gate_fees') {
					e.editorOptions.elementAttr = {
						"class": 'custom-checkbox-class'
					}
				}
				
				if (e.editorName === 'dxSelectBox' || e.editorName === 'dxDateBox') { // auto show dropdown on edit mode
					e.editorOptions.onFocusIn = function (args) {
						args.component.open();
					}
				}

				if (e.dataField === 'CarrierID' || e.dataField === 'Location') {
					e.editorOptions.dropDownOptions = {
						width: "300px"
					};
				}

				// for calling API
				// e.editorOptions.onValueChanged = function () {
				// 	this.isUpdatingCells = true

				// 	console.log("should update here");
                //     // e.component.cellValue(e.row.rowIndex, "FirstName", "AnotherID");  
                // }
			}
		},
		async onRowUpdating(e) { // do the update here - call update addPickup
			this.isUpdatingCells = true
			let { newData, oldData } = e

			if (newData !== null) {
				let entries = Object.entries(newData)

				if (entries.length > 0) {
					let findFieldName = entries[0][0]
					let fieldName = this.cellUpdateFieldValue[findFieldName].field
					let displayName = this.cellUpdateFieldValue[findFieldName].display_name

					let fieldValue = entries[0][1]

					let payload = {
						id: oldData.ID,
						field: fieldName,
						value: fieldValue,
						displayName,
						for_dispatch: false
					}

					let excludeFromSavingFields = ['LFD P', 'LFD R', 'ETA', 'Discharged', 'Released', 'Gate Fees']
					let isFieldExcluded = excludeFromSavingFields.find(c => c === displayName)

					if (isFieldExcluded === undefined) {
						if (displayName === 'Consignee') {
							payload.model = "App\\DeliveryOrder"
							payload.id = oldData.delivery_order_id

							if (typeof newData.ConsigneeName === 'string') {
								// get and assign the new consignee id
								payload.value = oldData.NewConsigneeID
							}
						} if (displayName === 'Customer') {
							if (typeof newData.CustomerID === 'string') {
								// get and assign the new consignee id
								payload.value = oldData.NewCustomerId
							}
						} else if (displayName === 'Empty Notified') {
							if (payload.value !== null) {
								let convertValue = moment(payload.value).format('YYYY-MM-DD hh:mm'); // convert date and time to this format
								let splitValue = convertValue.split(' '); // split date and time
								let date = moment(splitValue[0]).format('YYYY-MM-DD');
								let time = splitValue[1];

								let newPayload = {
									type: 'multiple',
									data: [{
										model: 'App\\Shipment',
										id: oldData.ID,
										fields: [
											{
												field: 'container_empty_date',
												value: date
											},
											{
												field: 'container_empty_time',
												value: time
											}
										]
									}],
									displayName
								}

								payload = newPayload
							}
						} else if (displayName === 'Scheduled') {
							if (payload.value !== null) {
								let convertValue = moment(payload.value).format('YYYY-MM-DD hh:mm'); // convert date and time to this format
								let splitValue = convertValue.split(' '); // split date and time
								let date = moment(splitValue[0]).format('YYYY-MM-DD');
								let time = splitValue[1];

								let scheds = null;
								let leg_id = null
								let legs = [];

								try {
									scheds = JSON.parse(oldData.dispatch_schedule);
								} catch (err) {
									scheds = null;
								}

								// get the leg id
								if (this.currentSelectTab === 'At the Customer') {
									legs = scheds.legs[scheds.legs.length - 1]
									leg_id = legs.id
								} else {
									legs = scheds ? scheds.legs || [] : [];

									if (["Available"].includes(this.currentSelectTab) ||
										["Available"].includes(this.selected_sub_tab.label) ) {
										leg_id = legs[0].id ? legs[0].id : null
									} else if (
										["Dropped empty", "Yard full", "At Yard"].includes(this.currentSelectTab) ||
										["Dropped empty", "Yard full", "At Yard"].includes(this.selected_sub_tab.label)) {

										let first_unarrived_leg = this.getFirstUnArrivedLeg(oldData);
										console.log(first_unarrived_leg, 'first_unarrived_leg');
									}
								}

								let newPayload = {
									shipment_id: oldData.ID,
									leg_id,
									fields: { from_date: date, to_time: time },
									displayName,
									for_dispatch: true
								}

								payload = newPayload
							}
						} else if (displayName === 'Driver') {
							if (payload.value !== null) {
								let scheds = null
								let leg_id = null

								try {
									scheds = JSON.parse(oldData.dispatch_schedule);
								} catch (err) {
									scheds = null;
								}

								// get the first not completed leg
								let getChedule = scheds !== null ? this.getClearnDispatch(scheds) : null;
								if (getChedule !== null) {
									leg_id = getChedule.id
								}
															
								let newPayload = {
									shipment_id: oldData.ID,
									leg_id,
									fields: { driver: payload.value, driver_status: "Pending" },
									displayName,
									for_dispatch: true
								}
								payload = newPayload
							}
						}

						this.callUpdateIndexAPI(payload)
					} else {
						if (fieldValue !== null) {
							if (isFieldExcluded !== 'Discharged' && isFieldExcluded !== 'Released' &&
								isFieldExcluded !== 'Gate Fees') {
								let fieldValueConvert = moment(fieldValue).format('YYYY-MM-DD')
								let oldDataDateConvert = moment(oldData[fieldName]).format('YYYY-MM-DD')

								if (fieldValueConvert !== oldDataDateConvert) {
									payload.value = fieldValueConvert
									this.callUpdateIndexAPI(payload)
								}
							} else {
								this.updateMilestone(payload)
							}
						} else {
							if (isFieldExcluded === 'LFD P' || isFieldExcluded === 'LFD R') {
								let oldDataValue = isFieldExcluded === 'LFD P' ? oldData.Lfdp : oldData.Lfdr
								let compareOldDataVal = oldDataValue === "" ? null : oldDataValue

								if (payload.value !== compareOldDataVal) {
									this.callUpdateIndexAPI(payload)
								}
							}
						}
					}
				}
			}
		},
		editorPrepared: () => {
			// this is for showing the buttons soon for saving or cancelling - pass e as params above

			// if (e.parentType === 'dataRow' && e.editorName === 'dxTextBox') {
			// 	const CustomButtonsApp = createApp({
			// 		setup() {
			// 			return () => h(CustomButtons, {type: 'Primary', dataGrid: e.component})
			// 		}
			// 	});

			// 	const wrapper = document.createElement("div");
			// 	wrapper.style.position = 'absolute';
			// 	CustomButtonsApp.mount(wrapper);
			// 	e.editorElement.appendChild(wrapper);
			// }

			// console.log(e, 'editor prepared');

			// if (e.parentType === 'dataRow' && (e.editorName === 'dxTextBox' || e.editorName === 'dxSelectBox')) {
			// 	// working code but issue with style
			// 	var ComponentClass = Vue.extend(CustomButtons)
			// 	var instance = new ComponentClass({
			// 		props: { type: 'primary', dataGrid: e.component }
			// 		// props: { type: 'primary' }
			// 	})
			// 	instance.$mount()
			// 	e.editorElement.appendChild(instance.$el);
			// }

			// if (options.parentType == 'dataRow' && options.dataField === 'ConsigneeName') {
			// 	// options.editorElement.dxSelectBox('instance').option('onValueChanged', function (e) {
			// 	// 	//your actions here
			// 	// 	console.log(e);
			// 	// });

			// 	console.log(options.editorElement.dxSelectBox('instance'));
			// }
		},
		validationCallBackMethodContainer(e) {
			let columnName = e.column.name
			let value = e.value

			if (columnName === 'Container') { // for container
				let result = false
				e.rule.message = "The first 4 characters are alpha and followed by 7 digits."

				if (value !== '' && value.length === 11) {
					if (/^[a-zA-Z]*$/.test(value.substring(0, 4)) &&
						/^[0-9]*$/.test(value.substring(4, value.length))) {
						result = true
					}
				} else {
					result = false
				}

				return result
			}

			return true
		},
		validationCallBackMethodEta(e) {
			// console.log(e);
			let currentValue = e.data.Eta
			let lfdpValue = e.data.Lfdp
			let lfdrValue = e.data.Lfdr

			if (currentValue !== null) {
				let valueConvert = moment(currentValue).format('YYYY/MM/DD')
				let lfdpConvert = lfdpValue !== "" && lfdpValue !== null ? moment(lfdpValue).format('YYYY/MM/DD') : null
				let lfdrConvert = lfdrValue !== "" && lfdrValue !== null ? moment(lfdrValue).format('YYYY/MM/DD') : null

				if (lfdpConvert !== null) {
					if (moment(valueConvert).isBefore(lfdpConvert)) {
						return true
					} else {
						e.rule.message = "ETA must be earlier than Last Free Date"
						return false
					}
				} else {
					if (lfdrConvert !== null) {
						if (moment(valueConvert).isBefore(lfdrConvert)) {
							return true
						} else {
							e.rule.message = "ETA must be earlier than Per Diem Date"
							return false
						}
					}
				}
			}

			return true
		},
		validationCallBackMethodLfd() {
			// let currentValue = e.data.Lfdp
			// let lfdrValue = e.data.Lfdr
			// // let etaValue = e.data.Eta

			// if (currentValue !== null) {
			// 	let valueConvert = moment(currentValue).format('YYYY/MM/DD')
			// 	let lfdrConvert = lfdrValue !== "" && lfdrValue !== null ? moment(lfdrValue).format('YYYY/MM/DD') : null
			// 	// let etaConvert = moment(etaValue).format('YYYY-MM-DD')

			// 	if (lfdrConvert !== null) {
			// 		if (moment(valueConvert).isAfter(lfdrConvert) ||
			// 			moment(valueConvert).isSame(lfdrConvert)) {
			// 			e.rule.message = "Last Free Date must be earlier than Per Diem Date"
			// 			return false
			// 		} else {
			// 			return true
			// 		}
			// 	} else {
			// 		return true 
			// 	}
			// }

			return true
		},
		validationCallBackMethodPdd() {
			// let currentValue = e.data.Lfdr
			// let ldfpValue = e.data.Lfdp

			// if (currentValue !== null) {
			// 	let valueConvert = moment(currentValue).format('YYYY/MM/DD')
			// 	let ldfpConvert = ldfpValue !== "" && ldfpValue !== null ? moment(ldfpValue).format('YYYY/MM/DD') : null

			// 	if (ldfpConvert !== null) {
			// 		if (moment(valueConvert).isBefore(ldfpConvert) ||
			// 			moment(valueConvert).isSame(ldfpConvert)) {
			// 			e.rule.message = "Per Diem Date must be later than Last Free Date"
			// 			return false
			// 		} else if (moment(valueConvert).isAfter(ldfpConvert)) {
			// 			return true
			// 		} else {
			// 			return true
			// 		}
			// 	} else {
			// 		return true
			// 	}
			// }

			return true
		},
		validationCallBackScheduled() {
			// console.log(e);
			// if (this.currentSelectTab === 'Scheduled') {
			// 	let currentValue = e.data.LfdpSched
			// 	let ldfrValue = e.data.LfdrSched
			// }
			// let currentValue = e.data.Lfdp
			// let ldfrValue = e.data.Lfdr
			// let etaValue = e.data.Eta

			// if (currentValue !== null) {
			// 	let valueConvert = moment(currentValue).format('YYYY-MM-DD')
			// 	let ldfrConvert = ldfrValue !== "" || ldfrValue !== null ? moment(ldfrValue).format('YYYY-MM-DD') : null
			// 	let etaConvert = moment(etaValue).format('YYYY-MM-DD')

			// 	if (moment(valueConvert).isAfter(etaConvert)) {
			// 		if (ldfrConvert !== null) {
			// 			if (moment(valueConvert).isBefore(ldfrConvert)) {
			// 				return true
			// 			} else {
			// 				e.rule.message = "LFD P should be earlier than LFD R"
			// 				return false
			// 			}
			// 		} else {
			// 			return true
			// 		}
			// 	} else if (moment(valueConvert).isBefore(etaConvert) ||
			// 		moment(valueConvert).isSame(etaConvert)) {
			// 		e.rule.message = "LFD P should be later than ETA"
			// 		return false
			// 	}
			// }

			return true
		},
		async callUpdateIndexAPI(payload) { // method for calling update index API
			// let { displayName, ...otherData } = payload
			this.isUpdatingCells = true

			try {
				await this.updateTableIndexes(payload)
		
				this.detectchangesReloadchanges();

				this.notificationMessageCustomSuccess(`Container has been updated successfully.`)
				this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page);
			} catch (error) {
				this.notificationCustom(error)
			}
		},
		updateMilestone(item) {			
			let fieldindex = '';
			switch(item.field){
				case "discharged":
				fieldindex = 'discharged'
				break
				case "pier_pass_paid":
				fieldindex = 'pier_pass_paid'
				break
				case "fully_released":
				fieldindex = 'fully_released';
				break;
			}

			axios.post(`${poBaseUrl}/milestones-update/${item.id}`, {
				id: item.id,
				value: item.value,
				field: item.field,
			})
			.then(async (res) => {
				if (res.status == 200) {
					this.notificationMessageCustomSuccess(`${item.displayName} has been updated successfully.`)
					let data = res.data.data

					let temp = this.newContainersData;
					const currentIndex = _.findIndex(this.newContainersData, (c) => c.id ===  item.id );	
				
					// discharged
					// pier_pass_paid
					if(fieldindex !=''){
						temp[currentIndex][fieldindex] = item.value					
						this.newContainersData.splice( currentIndex , 1 , temp[ currentIndex ]) 					
						this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp 
					}

					if ((data.discharge || data.discharge === 1) &&
						(data.fully_released || data.fully_released === 1) &&
						(data.peir_pass_paid || data.peir_pass_paid === 1)) {
							const previousSecondElementOfTheArray = this.newContainersData.splice(currentIndex, 1);											
							this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = previousSecondElementOfTheArray 

							this.detectchangesReloadchanges();		
						//await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
						this.$emit("reinitializeTable");
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		getValue(e) { // display the consignee value
			if (e.value !== '' && e.text === '') {
				return e.value
			} else {
				if (e.text !== '') {
					return e.text
				}
			}
		},
		copyContainerToClipboard(data) {
			let text = data.value
			navigator.clipboard.writeText(text).then(() => {
				this.notificationCustom('Container has been copied.')
			}, function(err) {
				console.error('Async: Could not copy text: ', err);
			});
		},
		// array move
		moveArrayIndex(arr, from, to) {
			return arr.map((item, i) => i === to ? arr[from] :
				(i >= Math.min(from, to) && i <= Math.max(from, to) ?
				arr[i + Math.sign(to - from)] : item));
		},
		async addNewConsigneeItem(data) { // creating consignee in column consignee cell dropdown
			let lookupText = document.getElementsByClassName("dx-texteditor-input")[0].value

			if (data !== null) {
				if (lookupText === '') {
					this.notificationCustom('Please input consignee name')
				} else {
					if (lookupText !== 'N/A') {
						let findConsigneeValue = _.find(currentConsignees, e => e.name === lookupText)

						if (findConsigneeValue !== undefined) {
							let message = `"${lookupText}" already exists. Enter a different Consignee Name`
							this.notificationCustom(message)
						} else {
							let payload = {
								name: lookupText,
								customer_id: data.row.data.CustomerID
							}
							let toastData = {
								message: 'Creating Consignee...',
								timeout: 2000,
								class: 'creating-consignee'
							}
							this.notificationCustomAlert(toastData)

							await this.createConsignee(payload).then(async (res) => {
								if (res) {
									// hide creating consignee toast once creating is successful
									var toast = document.querySelector('.iziToast.creating-consignee');
									iziToast.hide({}, toast);

									let response = res.data
									this.notificationMessageCustomSuccess(res.message)

									// assign new consignee id
									data.row.data.NewConsigneeID = response.id
									// set cell value
									data.component.cellValue(data.row.rowIndex, "ConsigneeName", response.name);
								}
							})
						}
					} else {
						this.notificationCustom('Please input consignee name')
					}
				}
			}
		},
		async addNewCustomer(data) {
			let lookupText = document.getElementsByClassName("dx-texteditor-input")[0].value

			if (data !== null) {
				if (lookupText === '') {
					this.notificationCustom('Please input customer name')
				} else {
					// console.log(lookupText, this.customerLists);

					if (lookupText !== 'N/A') {
						let findCustomer = _.find(this.customerLists, e => e.company_name === lookupText)

						if (findCustomer !== undefined) {
							let message = `"${lookupText}" already exists. Enter a different Customer Name`
							this.notificationCustom(message)
						} else {
							let formData = new FormData()
							formData.append('name', lookupText)
							formData.append('central_customer_key', "")
							formData.append('email', "");
							formData.append('pnumber', "")

							let toastData = {
								message: 'Creating Customer...',
								timeout: 2000,
								class: 'creating-consignee'
							}
							this.notificationCustomAlert(toastData)
							
							await axios.post(`${poBaseUrl}/createCustomer`, formData)
							.then((response) => {
								return response.data
							}).catch(error => {
								if(typeof error.type !=undefined) this.typeError = error.type
								if(typeof error.error != undefined){
									this.errorContent = error.msg
								}
							}).then(response => {
								if (response) {
									var toast = document.querySelector('.iziToast.creating-consignee');
									iziToast.hide({}, toast);

									this.notificationMessageCustomSuccess("Customer has been added.")
									data.row.data.NewCustomerId = response.customer_id
									data.component.cellValue(data.row.rowIndex, "CustomerID", lookupText);
									this.$emit("reinitializeTable");
									// this.fetchCustomers()
								}
							})
						}
					} else {
						this.notificationCustom('Please input customer name')
					}
				}
			}
		},
		// checking values
		isAnyLegAvailable(item) {
			let { row } = item
			let { data } = row

			if (data.dispatch_schedule != "" && data.dispatch_schedule != null) {
				let dispatch_schedule = null;
				try {
					dispatch_schedule = JSON.parse(data.dispatch_schedule);
				} catch (err) {
					console.log(err);
				}

				if (dispatch_schedule) {
					let legs = dispatch_schedule.legs || [];
					let len = 0;

					try {
						len = legs.length;
					} catch (err) {
						console.log(err);
					}
					if (len > 0) {
						return legs.find(leg => !leg.completed)
					}
				}
			}
			return false;
		},
		getTerminalData(id, type) {
			if (id) {
				let terminal = this.getTerminals.find(t => t.id == id)
				if (terminal) {
					if (type === 'name') {
						return terminal.name
					} else if (type === 'nickname') {
						return terminal.nickname !== null ? terminal.nickname : '--'
					} else if (type === 'address') {
						return terminal.address
					} else {
						return terminal.firms_code
					}
				}
			}
			return ""
		},
		getLastArrivedLeg(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = legs.length - 1; i >= 0; i--) {
					if (legs[i].completed) {
						return legs[i];
					}
				}
			}

			return null;
		},
		getFirstUnArrivedLeg(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = 0; i < legs.length; i++) {
					if (!legs[i].completed) {
						return legs[i];
					}
				}
			}
			return null;
		},
		getATCTimeArrived(item) {
			let scheds = null;
			try {
				scheds = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				scheds = null;
			}

			var time = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_time : null;
			var date =  scheds !== null ? scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_date : null;

			let newTime = ''
			let newDate = ''
			let finalDisplay = ''

			// check time
			if (time !== undefined && time !== "") {
				var bd = moment().format("YYYY-MM-DD");
				var checkdate = moment(bd + " " + time).utc(true).isValid();
				newTime = checkdate ? moment(bd + " " + time).format("hh:mm A") : "";
			} else {
				newTime = ""
			}

			// check date
			if (date !== undefined && date !== "") {
				if (this.checkifValid(date, "MM/DD/YY")) {
					newDate = moment(date).utc(true).format("MM/DD/YY");
				} else {
					newDate = ""
				}
			} else {
				newDate = ""
			}

			// check for final display
			if (newDate !== '') {
				if (newTime === '')
					finalDisplay = newDate
				else
					finalDisplay = newTime + ' ' + newDate
			} else {
				finalDisplay = ''
			}

			return finalDisplay
		},
		getTimeArrived(item) {
			let last_arrived_leg = this.getLastArrivedLeg(item);
			let time = "";
			let date = "";
			let finalDisplay = ""

			if (last_arrived_leg) {
				time = last_arrived_leg.arrival_at_delivery_location_time
				date = last_arrived_leg.arrival_at_delivery_location_date

				let newDate = this.getDateFormat(date)
				let newTime = this.convertDefaultTime(time)

				if (newDate !== '') {
					if (newTime === '')
						finalDisplay = newDate
					else
						finalDisplay = newTime + ' ' + newDate
				} else {
					finalDisplay = ''
				}
			}

			return finalDisplay
		},
		getEmptyNotified(data) {
			let { container_empty, container_empty_date, container_empty_time } = data
			container_empty = container_empty === 1 ? true : false

			let date = ''
			let time = ''
			let finalDisplay = null

			if (container_empty) {
				date = container_empty_date !== null ? this.getDateFormat(container_empty_date) : ''
				time = container_empty_time !== null ? moment(container_empty_time, ["HH:mm"]).format("hh:mm A") : ''
				// time = time !== null || time !== '' ? this.checkTotime(container_empty_time) : '' - utc ?

				if (date !== '') {
					if (time !== '' || time !== null) {
						finalDisplay = date + ' ' + time
					} else {
						finalDisplay = date
					}
				} else {
					finalDisplay = null
				}
			}

			return finalDisplay
		},
		getDefaultScheduledDate(item) { // getScheduledDate
			let dispatch_schedule = null;

			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
			let not_scheduled = null;
			let finalDisplay = null

			if (["Available"].includes(this.currentSelectTab) ||
				["Available"].includes(this.selected_sub_tab.label)) {
				if (legs.length > 0 && legs[0] !== undefined) {
					let date = legs.length > 0 && (legs[0].from_date !== null || legs[0].from_date !== "") ? this.getDateFormat(legs[0].from_date) : ''
					let time = legs.length > 0 && (legs[0].to_time !== null || legs[0].to_time !== "") ? moment(legs[0].to_time, ["HH:mm"]).format("hh:mm A") : ''

					if (date !== '') {
						if (time !== '' || time !== null) {
							finalDisplay = date + ' ' + time
						} else {
							finalDisplay = date
						}
					} else {
						finalDisplay = null
					}
				}

				return finalDisplay
			} else if (
				["Dropped empty", "Yard full", "At Yard"].includes(this.currentSelectTab) ||
				["Dropped empty", "Yard full", "At Yard"].includes(this.selected_sub_tab.label)) {

				let first_unarrived_leg = this.getFirstUnArrivedLeg(item);

				if (!(first_unarrived_leg && first_unarrived_leg.from_date)) {
					return not_scheduled;
				}
				if (["Yard full", "At Yard"].includes(this.currentSelectTab) ||
					["Yard full", "At Yard"].includes(this.selected_sub_tab.label)) {

					let date = this.getDateFormat(first_unarrived_leg.from_date);
					let time = moment(first_unarrived_leg.to_time, ["HH:mm"]).format("hh:mm A");
					// let time = this.checkTotime(first_unarrived_leg.to_time);

					if (date !== '') {
						if (time !== '' || time !== null) {
							finalDisplay = date + ' ' + time
						} else {
							finalDisplay = date
						}
					} else {
						finalDisplay = null
					}

					return finalDisplay
				}
				return this.getDateFormat(first_unarrived_leg.from_date);
			}
		},
		getAtTheCustomerScheduledDate(item) { // getScheduleDate
			let scheds = null;

			try {
				scheds = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				scheds = null;
			}

			var date = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.from_date : null;
			var time = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.to_time : null;
			var is_arrived = scheds !== null ? scheds.legs[scheds.legs.length - 1]?.completed : null;
			let finalDisplay = null

			// Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
			if (this.checkifValid(date, "MM/DD/YY") && !is_arrived) {
				let newDate = date !== null ? this.getDateFormat(date) : ''
				let newTime = time !== null ? moment(time, ["HH:mm"]).format("hh:mm A") : ''

				if (newDate !== '') {
					if (newTime !== '' || newTime !== null) {
						finalDisplay = newDate + ' ' + newTime
					} else {
						finalDisplay = newDate
					}
				} else {
					finalDisplay = null
				}
			} else
				return finalDisplay;
		},
		checkifValid(string, format) {
			return moment(moment(string).format(format), format, true).isValid();
		},
		// for legs
		addFirstLeg(item) {
			let { row } = item
			let { data } = row

			this.editingShipment = data;
			this.showAddLegModal = true;

			try {
				let ds = JSON.parse(this.editingShipment.dispatch_schedule);
				if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
					this.lastLeg = ds.legs[ds.legs.length - 1];
				else this.lastLeg = null;
			} catch (err) {
				console.log(err);
				this.lastLeg = null;
			}
			this.$emit("retrieveContainerDetails", data);
		},
		markAsArrivedFirstLeg(item){
			this.markasrrived = 1
			this.editFirstLeg(item)
		},
		editFirstLeg(item) {
			let { row } = item
			let { data } = row

			if (data.dispatch_schedule != "" && data.dispatch_schedule != null) {
				let dispatch_schedule = null;
				try {
					dispatch_schedule = JSON.parse(data.dispatch_schedule);
				} catch (err) {
					console.log(err);
				}

				if (dispatch_schedule) {
					let legs = dispatch_schedule.legs || [];
					let len = 0;
					let leg = null;
					try {
						len = legs.length;
						if (len > 0) {
							leg = legs.find(leg => !leg.completed)
						}
					} catch (err) {
						console.log(err);
					}
					if (len > 0 && leg) {
						this.editLeg(data, leg);
					}
				}
			}
		},
		editLeg(item, leg) {		
			
			const indexResult = _.findIndex(this.newContainersData, (c) => c.id ===  item.id );			
			let dispatch_schedule = JSON.parse(item.dispatch_schedule);		
			const result = _.findIndex(dispatch_schedule.legs, (c) => c.id ===  leg.id );

			if(result != -1 ){ 
				this.currentIndex = indexResult;
				this.currentLegID = leg.id;			
			}
			this.currentDataleg.legs = item.dispatch_schedule;

			this.$emit("retrieveContainerDetails", item);
			this.editingLeg = leg;
			this.editingShipment = item;
			this.showEditLegModal = true;
		},
		makegenerate() {
			return new Date().valueOf();
		},
		saveEditedLeg(leg) {
			let ob = {
				id: leg.id,
				schedule_id: this.editingLeg.schedule_id,
				driver_status: leg.driverstatus,
				type: leg.selectedType,
				address: leg.address,
				date: leg.delivery_scheduled_date,
				time: leg.delivery_scheduled_time,
				driver: leg.selectedDriver ? leg.selectedDriver : "",
				chassis: leg.chassis ? leg.chassis : "",
				mode: leg.selectedMode == 1 ? "Dropped" : "Live",
				completed: leg.arrival_at_delivery_location_date ? true : false,
				from_date: leg.pickup_scheduled_date,
				to_time: leg.pickup_scheduled_time,
				arrival_at_pickup_location_date:
				leg.arrival_at_pickup_location_date || "",
				arrival_at_pickup_location_time:
				leg.arrival_at_pickup_location_time || "",
				departure_from_pickup_location_date:
				leg.departure_from_pickup_location_date || "",
				departure_from_pickup_location_time:
				leg.departure_from_pickup_location_time || "",
				arrival_at_delivery_location_date:
				leg.arrival_at_delivery_location_date || "",
				arrival_at_delivery_location_time:
				leg.arrival_at_delivery_location_time || "",
				departure_from_delivery_location_date:
				leg.departure_from_delivery_location_date || "",
				departure_from_delivery_location_time:
				leg.departure_from_delivery_location_time || "",
				address_model_type: leg.address_model_type || null,
				address_model_id: leg.address_model_id || null,
			};

			let temp = this.newContainersData;
			let ds = JSON.parse(this.currentDataleg.legs)
			const result = _.findIndex(ds.legs, (c) => c.id ===  this.currentLegID); 			

			axios.put(`${poBaseUrl}/legs/${leg.id}`, {
				leg_data: ob,
				shipment_id: this.editingShipment.id,
			})
			.then((res) => {
				if (res.status == 200) {
					if(result != -1 ){
						ds.legs.splice(result, 1, ob)						
						temp[this.currentIndex]['dispatch_schedule'] = JSON.stringify(ds) 					
						this.newContainersData.splice( this.currentIndex , 1 , temp[ this.currentIndex ]) 					
						this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp 
					}
					
					this.isLegUpdated = 1
					this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
					this.notificationCustom("Container has been successfully updated!");
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.then(async () => {
				this.editingLeg = null;
				this.editingShipment = null;
				this.showEditLegModal = false;
				if (this.currentSelectTab == 'Scheduled'){
					console.log('Scheduled tab');
					// this.setSetFilterActive(this.currentStateClick)
				} else {
					// Commented By Alex
					// await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
					this.$emit("reinitializeTable");
				}
			});
		},
		saveNewChassis(data) {
			axios.post(`${poBaseUrl}/chassis-save`, data)
			.then((response) => {
				this.notificationCustom(response.data.message);
				this.responseChassis = response.data.chassis;
				this.fetchChassis();
			})
			.catch((error) => {
				console.log(error);
			});
		},
		addNewLeg(leg) {
			let dispatch_schedule = null,
			id = null,
			legs = [],
			ds = null;

			if (this.editingShipment != null) {
				try {
					ds = JSON.parse(this.editingShipment.dispatch_schedule);
					if (typeof ds.id != "undefined" && ds.id) id = ds.id;
					if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
					legs = ds.legs;
				} catch (err) {
					console.log(err);
					id = this.makegenerate();
				}
				dispatch_schedule = {
					id: id,
					location_from: this.editingShipment.terminal_id,
					last_free_date: this.editingShipment.last_free_date,
					per_diem_date: this.editingShipment.per_diem_date,
					legs: legs,
				};
			}

			let ob = {
				id: this.makegenerate(),
				schedule_id: dispatch_schedule.id,
				type: leg.selectedType,
				address: leg.address,
				date: leg.delivery_scheduled_date,
				time: leg.delivery_scheduled_time,
				driver: leg.selectedDriver ? leg.selectedDriver : "",
				chassis: leg.chassis,
				mode: leg.selectedMode == 1 ? "Dropped" : "Live",
				completed: false,
				from_date: leg.pickup_scheduled_date,
				to_time: leg.pickup_scheduled_time,
				arrival_at_pickup_location_date: leg.arrival_at_pickup_location_date,
				arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
				departure_from_pickup_location_date: leg.departure_from_pickup_location_date,
				departure_from_pickup_location_time: leg.departure_from_pickup_location_time,
				arrival_at_delivery_location_date: leg.arrival_at_delivery_location_date,
				arrival_at_delivery_location_time: leg.arrival_at_delivery_location_time,
				departure_from_delivery_location_date: leg.departure_from_delivery_location_date,
				departure_from_delivery_location_time: leg.departure_from_delivery_location_time,
				address_model_type: leg.address_model_type || null,
				address_model_id: leg.address_model_id || null,
			};
			dispatch_schedule.legs.push(ob);

			let temp = this.newContainersData;
			//let ds = JSON.parse(this.currentDataleg.legs)
			const indexdata = _.findIndex(this.newContainersData, (c) => c.id ===  this.editingShipment.id); 

			axios.post(`${poBaseUrl}/dispatch-save/${this.editingShipment.id}`, {
				data: JSON.stringify(dispatch_schedule),
			})
			.then((res) => {
				if (res.status == 200) {
					temp[indexdata]['dispatch_schedule'] = JSON.stringify(dispatch_schedule) 					
					this.newContainersData.splice( indexdata , 1 , temp[indexdata]) 					
					this.loadedContainersData[this.currentTab].data[this.getLoadedDataPage()] = temp 

					this.isLegUpdated = 1
					//this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
					this.notificationCustom("Container has been successfully updated!");
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.then(async () => {
				this.editingLeg = null;
				this.editingShipment = null;
				this.showAddLegModal = false;

				if (this.currentSelectTab == 'Scheduled') {
					console.log('Scheduled tab');
					// this.setSetFilterActive(this.currentStateClick)
				} else {
					await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
					// this.$emit("reinitializeTable");
				}
			});
		},
		// mark as empty
		openDialog(item) {
			let { row } = item
			let { data } = row
			this.dialog = true;
			this.dialogData = data;
		},
		closeDialog() {
			this.dialog = false;
			this.dialogData = []
		},
		async updateContainer(item, dateTime) {
		
			let payload = {
				item: item,
				dateTime: dateTime,
			};

			
			this.isSubmitting = true;
			axios.post(`${poBaseUrl}/container-empty/` + payload.item.id, payload.dateTime)
			.then(async (res) => {
				if (res.status == 200) {
					this.notificationCustom("Container has been successfully updated!");
					
					delete this.loadedContainersData["at-customer-full"];
					delete this.loadedContainersData["at-customer-empty"];

					await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
					await this.$emit("reinitializeTable");
				}
			})
			.catch((err) => {
				console.log(err);
			});
			// await this.setContainerEmptyStatus(payload)
			// .then(async () => {
			// 	this.notificationCustom("Container has been successfully updated!");
			// 	await this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page)
			// 	await this.$emit("reinitializeTable");
			// });
			this.isSubmitting = false;
			this.dialog = false;
		},
		// fetching
		getCurrentConsignees: (options) => ({
			store: new CustomStore({
				key: 'id',
				loadMode: 'raw',
				load: () => {
					let customer_id = options.data ? options.data.CustomerID : null
					currentConsignees = []

					if (customer_id !== null) {
						return axios.get(`${poBaseUrl}/get-consignees/${customer_id}`)
						.then(res => {
							if (res.data) {
								currentConsignees = res.data.results
								return res.data.results
							}
						})
						.catch(() => {
							currentConsignees = []
							throw 'Network error'
						});
					}
				},
			}),
			// filter: options.data ? ['CustomerID', '=', options.data.CustomerID] : null,
		}),
		calculateDisplayValueConsignee(v) {
			return v.ConsigneeName !== '' ? v.ConsigneeName : 'N/A'
		},
		calculateDisplayValueEmptyNotified(v) {
			return v.EmptyNotified !== null && v.EmptyNotified !== '' ? v.EmptyNotified : 'N/A'
		},
		calculateDisplayValueScheduled(v) {
			return v.Scheduled !== null && v.Scheduled !== '' && v.Scheduled !== undefined ? v.Scheduled : 'Not Scheduled'
		},
		calculateDisplayValuePickupTime(v) {
			return v.LocationDateTime !== null && v.LocationDateTime !== '' && v.LocationDateTime !== undefined ? v.LocationDateTime : 'N/A'
		},
		calculateDisplayValueDeliveryTime(v) {
			return v.DeliveryDateTime !== null && v.DeliveryDateTime !== '' && v.DeliveryDateTime !== undefined ? v.DeliveryDateTime : 'N/A'
		},
		calculateDisplayValueLFDR(v) {
			let val = ""
			if (v.Lfdr !== "" && v.Lfdr !== null) {
				val = v.Lfdr
			} else {
				if (this.currentSelectTab === "At Yard") {
					val = this.selected_sub_tab.name === "full" ? "Pending" : "Not Available"
				} else {
					val = "N/A"
				}
			}
			return val
		},
		calculateDisplayArrivedAtData(v) {
			return v.ArrivedAt !== null && v.ArrivedAt !== '' && v.ArrivedAt !== undefined ? v.ArrivedAt : 'N/A'
		},
		calculateDisplayValueDriver(v) {
			return v.Driver !== "" ? v.Driver : 'Unassigned'
		},
		// Scheduled methods
		sugomo(item) {
			if (item !== null && item.dispatch !== undefined) {
				let status = _.first(item.dispatch.legs);    
				if (item.dispatch.legs.length > 0) {
					return status.driver_status;
				}
			}
			return "Pending"
		},
		// deliveryon(item, isFor) { // old code for backup
		// 	if (item !== null && item.dispatch !== undefined) {
		// 		if (item.dispatch.legs.length > 0) {
		// 			var akoy = this, parsejson = _.first(item.dispatch.legs);

		// 			const dateon =  new Date(parsejson.delivery_scheduled_date_time);
		// 			// IF NULL ANG pickup_scheduled_date_time KUHA SA DISPATCH_SCHEDULE 
		// 			let the_date =  dateon.getUTCFullYear() +'-' + ( dateon.getUTCMonth() + 1)  + '-' + dateon.getDate();
		// 			let the_time =  dateon.toLocaleTimeString();
		// 			let address = parsejson.address;

		// 			var s = "";
		// 			let date = "", time = "";

		// 			if (typeof the_date != "undefined" && the_date != "") {
		// 				date = the_date;
		// 				time = "";
		// 				if (typeof the_time != "undefined" && the_time != "") {
		// 					time = the_time;
		// 				}
		// 			}

		// 			var theDate = null
		// 			if (akoy.checkTotime(date) == "--" && akoy.fromdate(time) == "--") {
		// 				theDate = null;
		// 			} else {
		// 				if (akoy.convertDefaultTime(time) == "") {
		// 					theDate = akoy.fromdate(date);
		// 				} else {
		// 					theDate = akoy.fromdate(date) + ' ' + akoy.convertDefaultTime(time);
		// 				}
		// 			}
					
		// 			var xlenthtaddress = address // f.address;
		// 			if (isFor === 'location')
		// 				s += xlenthtaddress
		// 			else
		// 				s += theDate

		// 			return s;
		// 		}
		// 	}
		// },
		// pickupOn(item, mainItem, isFor) { // old code backup
		// 	if (item !== null && item.dispatch !== undefined) {
		// 		var akoy = this, 
		// 		getfirst = _.first(item.dispatch.legs);
		// 		akoy.lenthDispatch = 0;
		// 		// var l = item.dispatch.legs.length

		// 		if (item.dispatch.legs.length > 0) {
		// 			var ii = "", address = '';
		// 			var terminalName = '';

		// 			if (getfirst !== null) {
		// 				// get the terminal name
		// 				if (getfirst.is_first_leg) {
		// 					if (mainItem.terminal !== null) {
		// 						Object.keys(mainItem).forEach(function(key) {
		// 							if (key == 'terminal') {
		// 								terminalName = (mainItem['terminal'].nickname);
		// 							}
		// 						});
		// 					}
		// 				} else {
		// 					address = getfirst.pickup_location;
		// 				}
		// 			}

		// 			/** Get the legs */
		// 			var datePick = {};

		// 			if (getfirst.arrival_at_pickup_location_date != null) {
		// 				datePick.date = getfirst.arrival_at_pickup_location_date;
		// 				datePick.time = getfirst.arrival_at_pickup_location_time;                           
		// 			} else {
		// 				if (getfirst.pickup_scheduled_date_time != null) {                 
		// 					const date =  new Date(getfirst.pickup_scheduled_date_time);
		// 					// IF NULL ANG pickup_scheduled_date_time KUHA SA DISPATCH_SCHEDULE 
		// 					datePick.date =  date.getUTCFullYear() +'-' + ( date.getUTCMonth() + 1)  + '-' + date.getDate();
		// 					datePick.time =  date.toLocaleTimeString();                
		// 				} else {
		// 				// get the dispatch_schedule
		// 					let parsedispatch = JSON.parse(item)
		// 					if (parsedispatch.legs.length > 0) {
		// 						const result = parsedispatch.legs.find(( e ) => e.id =  getfirst.unique_id);     
		// 						datePick.date = result.from_date;
		// 						datePick.time = result.to_time;
		// 					} else {
		// 						datePick.date = '--';
		// 						datePick.time = '--';
		// 					}
		// 				}
		// 			}

		// 			if (typeof terminalName === "undefined" || terminalName === null || terminalName === "") {
		// 				terminalName = "--";
		// 			}

		// 			address = address.length > 0 ? address : terminalName;

		// 			// is_first_leg
		// 			// var hasDriver = akoy.findDriverClass(getfirst.driver);
		// 			var datedate = null;

		// 			if (akoy.convertDateformat(datePick.time, "isDate") == "--" && 
		// 				akoy.getpickup(datePick.date) == "--") {
		// 				datedate = null;
		// 			} else {
		// 				if (akoy.convertDateformat(datePick.time, "isDate") == "--") {
		// 					datedate = akoy.getpickup(datePick.date);
		// 				} else {
		// 					if (akoy.getpickup(datePick.date) == "--") {
		// 						datedate = null;
		// 					} else {
		// 						datedate = akoy.getpickup(datePick.date) + ' ' + akoy.convertDefaultTime(datePick.time);
		// 					}
		// 				}
		// 			}
		// 			var lenthtaddress = address;
		// 			if (isFor === 'location')
		// 				ii += lenthtaddress
		// 			else
		// 				ii += datedate

		// 			return ii;
		// 		}
		// 	}
		// },
		deliveryon(item, isFor) {
			if (item !== null && item.dispatch !== undefined) {
				if (item.dispatch.legs.length > 0) {
					var akoy = this, parsejson = _.first(item.dispatch.legs);
					var isDateTimeSeparated = false;
					var s = "";
					let address = parsejson.address;

					let date = ""
					let time = ""

					if (parsejson.arrival_at_delivery_location_date_time !== null &&
						parsejson.arrival_at_delivery_location_date_time !== "") {
						date = parsejson.arrival_at_delivery_location_date_time;
						isDateTimeSeparated = false;
					} else {
						if (parsejson.delivery_scheduled_date_time !== null &&
							parsejson.delivery_scheduled_date_time !== "") {       
							date = parsejson.delivery_scheduled_date_time
							isDateTimeSeparated = false;              
						} 
						// else {
						// 	// get the dispatch_schedule
						// 	let parsedispatch = JSON.parse(item)
						// 	isDateTimeSeparated = true;
						// 	if (parsedispatch.legs.length > 0) {
						// 		const result = parsedispatch.legs.find(( e ) => e.id =  parsejson.unique_id);     
						// 		date = result.from_date;
						// 		time = result.to_time;
						// 	} else {
						// 		date = '--';
						// 		time = '--';
						// 	}
						// }
					}

					var theDate = null
					if (isDateTimeSeparated) {
						if (akoy.checkTotime(date) == "--" && akoy.fromdate(time) == "--") {
							theDate = null;
						} else {
							if (akoy.convertDefaultTime(time) == "") {
								theDate = akoy.fromdate(date);
							} else {
								theDate = akoy.fromdate(date) + ' ' + akoy.convertDefaultTime(time);
							}
						}
					} else {
						theDate = moment(date).format("MM/DD/YY hh:mm A")
					}
					
					var xlenthtaddress = address // f.address;
					if (isFor === 'location')
						s += xlenthtaddress
					else
						s += theDate

					return s;
				}
			}
		},
		pickupOn(item, mainItem, isFor) {
			if (item !== null && item.dispatch !== undefined) {
				var akoy = this, 
				getfirst = _.first(item.dispatch.legs);
				akoy.lenthDispatch = 0;

				if (item.dispatch.legs.length > 0) {
					var ii = "", address = '', isDateTimeSeparated = false;
					var terminalName = '';

					if (getfirst !== null) {
						// get the terminal name
						if (getfirst.is_first_leg) {
							if (mainItem.terminal !== null) {
								Object.keys(mainItem).forEach(function(key) {
									if (key == 'terminal') {
										terminalName = (mainItem['terminal'].nickname);
									}
								});
							}
						} else {
							address = getfirst.pickup_location;
						}
					}

					/** Get the legs */
					var datePick = {};

					if (getfirst.arrival_at_pickup_location_date_time !== null) {
						datePick.date = getfirst.arrival_at_pickup_location_date_time;
						isDateTimeSeparated = false;
					} else {
						if (getfirst.pickup_scheduled_date_time !== null) {       
							datePick.date = getfirst.pickup_scheduled_date_time
							isDateTimeSeparated = false;
							// const date =  new Date(getfirst.pickup_scheduled_date_time);
							// // IF NULL ANG pickup_scheduled_date_time KUHA SA DISPATCH_SCHEDULE 
							// datePick.date =  date.getUTCFullYear() +'-' + ( date.getUTCMonth() + 1)  + '-' + date.getDate();
							// datePick.time =  date.toLocaleTimeString();                
						} else {
						// get the dispatch_schedule
							let parsedispatch = JSON.parse(item)
							isDateTimeSeparated = true;
							if (parsedispatch.legs.length > 0) {
								const result = parsedispatch.legs.find(( e ) => e.id =  getfirst.unique_id);     
								datePick.date = result.from_date;
								datePick.time = result.to_time;
							} else {
								datePick.date = '--';
								datePick.time = '--';
							}
						}
					}

					if (typeof terminalName === "undefined" || terminalName === null || terminalName === "") {
						terminalName = "--";
					}

					address = address.length > 0 ? address : terminalName;

					// is_first_leg
					var datedate = null;

					if (isDateTimeSeparated) {
						if (akoy.convertDateformat(datePick.time, "isDate") == "--" && 
							akoy.getpickup(datePick.date) == "--") {
							datedate = null;
						} else {
							if (akoy.convertDateformat(datePick.time, "isDate") == "--") {
								datedate = akoy.getpickup(datePick.date);
							} else {
								if (akoy.getpickup(datePick.date) == "--") {
									datedate = null;
								} else {
									datedate = akoy.getpickup(datePick.date) + ' ' + akoy.convertDefaultTime(datePick.time);
								}
							}
						}
					} else {
						datedate = moment(datePick.date).format("MM/DD/YY hh:mm A")
					}
					
					var lenthtaddress = address;
					if (isFor === 'location')
						ii += lenthtaddress
					else
						ii += datedate

					return ii;
				}
			}
		},
		checklength_dispatch(item){
			var parse = this.getparse(item);
			var ret = 0;
			if(parse !== null) {
				if (typeof parse.legs !='undefined') {
					if (parse.legs.length) {
						Object.values(parse.legs).forEach(function(f) {
							if (typeof f.x_element != 'undefined') {
								ret = 0;
							} else {
								ret = 1
							}
						})
					}
					return ret
				}
			}
		},
		/* PARSE THE ITEM */
		getparse(item) {
			if (item != "") {
				try {
					return JSON.parse(item);
				} catch (err) {
					// Uncaught SyntaxError: JSON.parse: unexpected character at
					// line 1 column 2 of the JSON data
					return {};
				}
			}
		},
		findDriverClass(f) {
			var des = this.magdriver,
			c = "",
			r = des.filter((find) => find.id == f);
			if (r.length > 0) {
				c = "hasDriver ";
				this.loopUnassigned = 1;
			} else {
				c = "hasNoDriver ";
				this.loopAssign = 1;
			}
			this.lenthDispatch = this.loopUnassigned + this.loopAssign; // count number of assigned and unassigned
			return c;
		},
		convertDateformat(data, isDate) {
			if (typeof data != "undefined") {
				var thedate = "--";
				if (isDate == "isDate") {
					thedate = this.timeConvert(data);
				}
				return thedate;
			} else {
				return "--";
			}
		},
		getpickup(date) {
			if (typeof date != "undefined") {
				//return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
				if (this.checkifValid(date, "MMM DD, YYYY")) {
					// return moment(date).utc(true).format("MMM DD, YYYY");
					return this.getDateFormat(date)
				} else {
					return "--";
				}
			} else {
				return "--";
			}
		},
		fromdate(date) {
			if (typeof date != "undefined") {
				//return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
				// return moment(date).utc(true).format("MMM DD, YYYY");
				return this.getDateFormat(date)
			} else {
				return "--";
			}
		},
		getAssignedToDriver(item) {
			var des = this.getDrivers,
			kani = this,
			driver_id = "",
			driver_name = "",
			status = "";

			if (item.dispatch !== undefined) {
				if (item.dispatch.legs.length > 0) {
					var decodeleg = JSON.parse(item.dispatch_schedule);
					let filtered_array = _.filter(decodeleg.legs, function(o) {
							return !o.completed;
						}
					);

					let getfirst = _.first(filtered_array);

					if (typeof getfirst !='undefined'){
						var s = ""
						var r = des.filter((find) => find.id == getfirst.driver);

						if (r.length > 0) {
							if (getfirst.driver_status !== "Accepted") {
								status = getfirst.driver_status
							}
							
							Object.values(r).forEach(function (ff) {
								driver_name = kani.nameTransform(ff.name)
								s = ff.id // get id of driver
							});
						} else {
							s = 'Unassigned'
						}            
						return {
							driver_id: s,
							driver_name,
							status: status
						};
					}
				}
			}			

			return { driver_id, driver_name, status };
		},
		getAssignedToStatus(item) {
			var des = this.getDrivers,
			statusClass = "",
			finalStatusClass = "";

			if (item !== null && item.dispatch !== undefined) {
				var ti = item.dispatch.legs;

				if (ti.length > 0) {
					let getfirst = _.first(item.dispatch.legs);  
					var r = des.filter((find) => find.id == getfirst.driver_id);

					if (r.length > 0) {
						if (getfirst.driver_status !== undefined) {
							if (getfirst.driver_status !== "Accepted") {
								statusClass = getfirst.driver_status
							} else {
								statusClass = "Accepted-Status"
							}
						} else {
							statusClass = "Pending"
						}
					}

					finalStatusClass = `assigned-status ${statusClass}`
				}
			}
			return finalStatusClass
		},
		getDriverStatus(driver) {
			if(driver.driver === null) {
				return ""
			} else if(driver.driver !== null) {
				return "Connected"
			} else if(!driver.status) {
				return "Non-active"
			} else return "Active"
		},
		nameTransform(text) {
			return text
				.toLowerCase()
				.split(" ")
				.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
				.join(" ");
		},
		handlePageChange() {
			// if (this.page !== this.oldPage) {
				this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, this.page);
			// }
		},
		getScheduledLeg(item) {
			this.scheduledLastLegID = item.id;
		},
		getClearnDispatch(item) {
			var decodeleg = typeof item === 'string' ? JSON.parse(item) : item;

			let filtered_array = _.filter(
				decodeleg.legs, function(o) { return !o.completed; }
			);

			let c = _.first(filtered_array)
			return c;
		},
		getDirectPrepullStatus(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}
			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
			if (legs.length > 0) {
				if (legs[0].type) {
					if (legs[0].type == 'Customer') return 'Direct'
					else return 'Prepull'
				}
			}
			return null;
		},
		getMode(item) {
			if (item !== null) {      
				if (this.currentSelectTab === "Scheduled") {
					let getfirst = _.first(item.dispatch.legs);
					let fobject = getfirst;  

					return fobject.mode ? (fobject.mode == "Dropped" ? "Drop" : fobject.mode) : "";	
				} else if (this.currentSelectTab === "At the Customer") {
					let scheds = null;
					let mode = "N/A";

					try {
						scheds = JSON.parse(item.dispatch_schedule);
					} catch (err) {
						scheds = null;
					}
					if (scheds !== null) {
						scheds.legs.map((value) => {
						if (value.completed) {
							mode = value.mode === "Live" ? "Live" : "Drop";
						}
						});
					}
					return mode;
				} else {
					return ""
				}
			}
		},
		getTypeDelivery(item) {
			if (item !== null) {
				if (this.currentSelectTab === "Scheduled") {
					let getfirst = _.first(item.dispatch.legs);
					let fobject = getfirst;  

					return fobject.type == "Empty Return" ? "To Port" : `To ${fobject.type}`;
				}
			}
		},
		getDateTimeFinalDisplay(date, time) {
			let finalDisplay = null

			if (date !== '') {
				if (time !== '' || time !== null) {
					finalDisplay = date + ' ' + time
				} else {
					finalDisplay = date
				}
			} else {
				finalDisplay = null
			}

			return finalDisplay
		},
		getLastArrivedLegCustomer(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = legs.length - 1; i >= 0; i--) {
					if (legs[i].completed && legs[i].type === "Customer") {
						return legs[i];
					}
				}
			}

			return null;
		},
		getFirstLegArrivalPickup(item) {
			let dispatch_schedule = null;
			try {
				dispatch_schedule = JSON.parse(item.dispatch_schedule);
			} catch (err) {
				dispatch_schedule = null;
			}

			let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];

			if (legs.length > 0) {
				for (let i = 0; i < legs.length; i++) {
					if (legs[i].completed && (legs[i].type === "Port" || legs[i].type === "Empty Return")) {
						return legs[i];
					}
				}
			}

			return null;
		}
	},
	watch: {
		search() {
			if (cancel !== undefined) {
                cancel("cancel_previous_request")
            }

			clearTimeout(this.typingTimeout)
            this.typingTimeout = setTimeout(() => {
                this.pageCount = 1;
				this.shouldLoad = true
				this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
            }, 300)
		},
		pageCount: {
			handler(newValue, oldValue) {
				if (this.search !== "") {
					if (newValue !== 0) {
						this.updatedPageCount = 1;
					} else if (oldValue > this.lastPage) {
						this.updatedPageCount = 1;
					} else if (this.lastPage > oldValue) {
						this.updatedPageCount = this.lastPage;
					} else {
						this.updatedPageCount = 1;
					}
				} else {
					if (newValue > 1) {
						this.updatedPageCount = newValue;
					} else this.updatedPageCount = this.lastPage;
				}
			},
			immediate: true,
		},
		activeTab() {
			if (this.oldselectedTab !== this.currentSelectTab) {
				this.isDisabled = true;
			}
		},
		getPoLoading: function (value) {
			if (value) {
				this.isDisabled = true;
			} else {
				this.isDisabled = false;
			}
		},
		shouldLoad: {
			handler(newValue) {
				this.loadingData = newValue;
			},
			immediate: true,
		},
		updatedData(c) {
			if (c) {
				this.retabs = 0
				this.$nextTick(() => {
					var text = this.getCurrentSelectTab;
					this.isUpdatingCells = false

					if (Object.keys(text).length !== 0) {
						this.activeTab = parseInt(text.activeTab);
						this.currentSelectTab = text.id;

						if (text.sub_tab === undefined) {
							let current_tab_item = this.tabItems.find((f) => f.name == this.currentSelectTab);
							this.selected_sub_tab = current_tab_item.sub_tab[0];
							this.subTabIndex = 0
						} else {
							if (text.sub_tab !== undefined) {
								if (typeof text.sub_tab === 'string') {
									this.selected_sub_tab = {
										label: text.sub_tab,
										name: text.sub_tab,
										count: 0,
									}
									this.subTabIndex = text.subTabIndex
								} else {
									this.selected_sub_tab = this.getCurrentSelectTab.sub_tab
									this.subTabIndex = this.getCurrentSelectTab.subTabIndex
								}
							}
						}

						this.retabs = 1;
						this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
					} else {
						// set the default tab to All tabs
						this.storeCurrentTabSelected({
							id: "All",
							activeTab: 0,
							sub_tab: [],
							subTabIndex: 0
						});

						this.activeTab = 0;
						this.currentSelectTab = "All";
						this.selected_sub_tab = []
						this.subTabIndex = 0
						this.retabs = 1;
						this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
					}
				});
			}
		},
	},
	async mounted() {
		this.$store.dispatch("page/setPage", "containers");
	},
	updated() {
		if (this.sub_tab.length == 0) {
			let current_tab_item = this.tabItems.find((f) => f.name == this.currentSelectTab);
			// let current_sub_tab = this.getCurrentSelectTab.sub_tab

			if (current_tab_item && current_tab_item.sub_tab && current_tab_item.sub_tab.length > 0) {
				this.sub_tab = current_tab_item.sub_tab;

				if (this.selected_sub_tab === undefined) {
					this.selected_sub_tab = current_tab_item.sub_tab[0];
					this.subTabIndex = 0
				}
			}
		}

		this.updatedData = 1
	}
};
</script>
<style lang="scss">
@import "../../../../../assets/scss/buttons.scss";
@import "../scss/containerCompact.scss";
</style>
