<template>
    <div class="supplier-content-wrapper customer-page-wrapper">
        <div class="driverHeader">
            <h2>Customers</h2>
            <div class="d-flex justify-end align-center">
                <span
                    v-if="selected.length > 0"
                    class="clear-text-setup text-d-blue mr-2"
                    @click="clearSelection">
                    Clear Selection
                </span>
                <v-btn
                    v-if="isclickcheckbox && selected.length > 0"
                    :disabled="selected.length === 0"
                    :class="'btn-white saveCreateBtn mr-2 font-weight-500'"
                    depressed
                    @click="openSRSD">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="width-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>

                    Set Reporting Schedule
                </v-btn>
                <v-btn color="primary" class="btn-blue add-supplier addCustomer" @click.stop="addCustomer">
                    + Add New Customer
                </v-btn>
            </div>
        </div>

        <v-data-table
            v-model="selected"
            :headers="headers"
            mobile-breakpoint="769"
            :items="customers"
            class="suppliers-table driver-table elevation-1 customer-page-table"
            :class="customers.length === 0 ? 'no-data' : ''"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            item-key="name"
            show-select>            
            <!-- @dblclick:row="editItem2" -->

            <template v-slot:[`header.name`]="{ header }">
				{{ header.text }}<i class="classsort"></i>
			</template>

            <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>

					<!-- <Search style="230px"
						placeholder="Search Customers"
						className="search custom-search"
						:isnputData.sync="search" /> -->

                    <div class="customer-search-component">
                        <SearchComponent
                            placeholder="Search Customers"
                            :searchValue.sync="search"
                            :handleSearchComponent="true" />
                    </div>
                </v-toolbar>
            </template>

            <template v-slot:[`item.name`]="{ item }">
                <p class="mb-0 text-capitalize">{{ item.name !== '' ? item.name : '--' }}</p>
            </template>

            <template v-slot:[`item.phone_number`]="{ item }">
                <p class="mb-0">{{ item.phone_number !== '' ? item.phone_number : '--' }}</p>
            </template>

			<template v-slot:[`item.emailsaddress`]="{ item }">
                <div class="email-wrapper" v-if="item.emails !== ''">
                    <div class="email-container" v-for="(email, index) in item.emails" :key="index">
                        <p v-if="email==''" class="mb-0" style="color: #4A4A4A;">--</p>
                        <p else class="mb-0" style="color: #4A4A4A;">{{ email }}</p>
                    </div>
                </div>
            </template>

			<template v-slot:[`item.shipments_object`]="{ item }">
                <div class="email-wrapper">
                    <div class="email-container">
                        {{ getLS(item) }}
                    </div>
                </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <!-- <div class="d-flex justify-end align-center actions-customer-wrapper">
                    <v-btn
                        class="customer-action-btn-edit mr-2"
                        style="caret-color:transparent"
                        @click.stop="editCustomer(item)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1 w-6  text-d-blue">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                        <span class="text-setup  text-d-blue">Edit</span>
                    </v-btn>

                    <div v-if="item.shipments_object !== null" style="cursor: not-allowed;">
                        <v-tooltip
                            left
                            content-class="primary tooltip-left tooltip-wrapper">
                            <template v-slot:activator="{ on}">
                                <span v-on="on">
                                    <v-btn
                                        class="customer-action-btn delete"
                                        style="caret-color:transparent"
                                        @click.stop="deleteCustomer(item)"
                                        :disabled="disnodisalbe"
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1 w-6  text-d-danger">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                        <span class="text-setup text-d-danger">Delete</span>
                                    </v-btn>
                                </span>
                            </template>
                            <span style="font-size:12px;">This user has shipments associated</span>
                        </v-tooltip>
                    </div>
                    <div v-else>
                        <div>
                            <v-btn
                                class="customer-action-btn delete"
                                style="caret-color:transparent"
                                @click.stop="deleteCustomer(item)"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-1 w-6  text-d-danger">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                <span class="text-setup text-d-danger">Delete</span>
                            </v-btn>
                        </div>
                    </div>
                </div> -->

                <div class="action-button-wrapper-customer d-flex justify-end align-center pr-2">	
                    <v-menu offset-y bottom left content-class="customer-menu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btn-white" 
                                style="height: 30px !important; min-width: 35px !important; padding: 4px 8px !important;">
                                <img src="@/assets/icons/more-blue.svg" alt="" />
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item class="customer-item-wrapper">
                                <v-list-item-title
                                    class="trucking-archived-item"
                                    @click="showrow(item)">
                                    <span style="padding-right:3px">
                                        <svg style="position: relative; top: 2px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_9327_10539)">
                                                <path d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z" fill="#6D858F"/>
                                            </g>
                                        </svg>
                                    </span> <span class="ml-1" style="color: #4a4a4a !important;">View</span>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="customer-item-wrapper">
                                <v-list-item-title
                                    class="trucking-archived-item"
                                    @click="editCustomer(item)">
                                    <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
                                    </svg> <span style="margin-left: 10px; color: #4a4a4a !important;">Edit</span>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="customer-item-wrapper" v-if="item.shipments_object !== null" style="cursor: not-allowed;">
                                <!-- <v-list-item-title class="">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="16" height="16" viewBox="0 0 24 24" stroke="#b4cfe0" class="mr-1 text-d-danger">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg> <span style="margin-left: 5px; color: #b4cfe0 !important;">Delete</span>
                                </v-list-item-title> -->

                                <v-tooltip left content-class="primary tooltip-wrapper">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <v-list-item-title class="">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="16" height="16" viewBox="0 0 24 24" stroke="#b4cfe0" class="mr-1 text-d-danger">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg> <span style="margin-left: 5px; color: #b4cfe0 !important;">Delete</span>
                                            </v-list-item-title>
                                        </span>
                                    </template>
                                    <span style="font-size:12px;">User {{ item.name }} has shipments associated</span>
                                </v-tooltip>
                            </v-list-item>

                            <v-list-item class="customer-item-wrapper" v-else>
                                <v-list-item-title class="" @click="deleteCustomer(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="16" height="16" viewBox="0 0 24 24" stroke="currentColor" class="mr-1 text-d-danger">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg> <span style="margin-left: 5px; color: #F93131;">Delete</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

            <template v-slot:no-data>
                <div class="no-data-preloader mt-4">
                    <v-progress-circular
                        :size="40"
                        color="#0171a1"
                        indeterminate
                        v-if="getCustomersListsLoading">
                    </v-progress-circular>

                    <div class="pt-6" v-if="!getCustomersListsLoading && customers.length === 0">
                        No customers available
                    </div>
                </div>
            </template>
        </v-data-table>

		<!-- <Pagination
            v-if="customers.length !== 0"
			:pageData.sync="getCurrentPage"
			:lengthData.sync="getTotalPage"
			:isMobile="isMobile"
		/> -->

        <PaginationComponent
            :totalPage.sync="getTotalPage"
            :currentPage.sync="getCurrentPage"
            @handlePageChange="handlePageChange"
            :isMobile="isMobile"
            v-if="customers.length > 0" />

        <SetReportingScheduleDialog
            :openDialog="openDialog"
            :selected="selected"
            @closeOpenDialog="closeSRSD"
            @removeSelected="removeSelected"
        />
    </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
// import Search from '../../Search.vue'
import SearchComponent from "../../SearchComponent/SearchComponent.vue"
import PaginationComponent from '../../PaginationComponent/PaginationComponent.vue'
import moment from "moment";
import SetReportingScheduleDialog from '../../CustomerComponents/SetReportingScheduleDialog.vue';
import globalMethods from '../../../utils/globalMethods';
const APIBaseUrl = process.env.VUE_APP_PO_URL

var cancel;
var CancelToken = axios.CancelToken;

export default {
    name: "CustomerDesktopTable",
    props: [
        'items',
        'isMobile',
        'reloadcomponent'
    ],
	components: {
		// Search,
        SearchComponent,
		PaginationComponent,
        SetReportingScheduleDialog,
	},
    data: () => ({
        disnodisalbe: true,
        disalbeme:true,
        isclickcheckbox:false,
		page: 1,
        startfetching: 1,
        pageCount: 0,
        itemsPerPage: 15,
        headers: [
            {
                text: "Name",
                align: "start",
                value: "name",
                sortable: true,
                width: "25%",
                fixed: true
            },
            {
                text: "Phone Number",
                align: "start",
                value: "phone_number",
                sortable: false,
                width: "20%",
                fixed: true
            },
            {
                text: "Email",
                align: "start",
                value: "emailsaddress",
                sortable: true,
                width: "20%",
                fixed: true
            },
            {
                text: "Latest Shipment",
                align: "start",
                value: "shipments_object",
                sortable: true,
                width: "20%",
                fixed: true
            },
            {
                text: "",
                align: "end",
                value: "actions",
                sortable: false,
                width: "10%",
                fixed: true
            },
        ],
		search: '',
        Customers:[],
        selected: [],
        openDialog: false,
        typingTimeout: 0
    }),
    computed: {
        ...mapGetters({
            getCustomers: 'customers/getCustomers',
            getCustomersLoading: 'customers/getCustomerLoading',
            getCustomersListsLoading: 'customers/getCustomersListsLoading'
        }),
        customers: {
            get() {
                return this.items
            },
            set(value) {
                this.$emit('items', value)
            }
        },
        getCurrentPage: {
            get() {
                let current_page = 1
                let customers = this.getCustomers

                if (typeof customers.current_page !== 'undefined' && customers.current_page !== null) {
                    current_page = customers.current_page
                }

                return current_page
            },
            set() {
                return {}
            }
        },
        getTotalPage: {
            get() {
                let total = 1
                let customers = this.getCustomers

                if (typeof customers.last_page !== 'undefined' && customers.last_page !== null) {
                    total = customers.last_page
                }

                return total
            }
        },
    },
    watch: {
        search() {
			if (cancel !== undefined) {
                cancel("cancel_previous_request")
            }
            
            this.setSearchedCustomersLoading(true)
			clearTimeout(this.typingTimeout)
            this.typingTimeout = setTimeout(() => {
                this.callSearchAPI()
            }, 300)
		},
    },
    created() {},
    methods: {
        ...mapActions({
           fetchCustomersv2: 'customers/fetchCustomersv2',
           storeEditData: "customers/passData",
           fetchCustomersSearched: 'customers/fetchCustomersSearched',
           setSearchedCustomersLoading: 'customers/setSearchedCustomersLoading',
           setCustomersSearchedVal: 'customers/setCustomersSearchedVal'
        }),
        ...globalMethods,
        addCustomer() {
            this.$emit('addCustomer')
        },
        editCustomer(item) {
            item.emails = item.emails == '--' ? '' : item.emails
            this.$emit('editCustomer', item)
        },
        deleteCustomer(item) {
            this.$emit('deleteCustomer', item)
        },
        editItem(item) {
            this.editedIndex = this.suppliers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        async customerListing() {
            let array = [];
            var letme = this;
            await axios.get(`${APIBaseUrl}/customersList`).then((response) => {
                return response;
            })
            .catch(error => {
                this.response = error;
            }).then(response =>{
                if(typeof response !='undefined')
                {
                    response['data'].forEach((value) =>
                    {
                        var text = value.emails;
                        const myArray = text.split(",");
                        let m =  {
                            customer_id: value.id,
                            central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
                            name: value.compnay_name,
                            phone_number: value.phone == null ? '--': value.phone,
                            emails: Object.assign({}, myArray),
                            emailsaddress: value.emails == '' ? '--': value.emails,
                            shipments: value.shipments
                        }
                        array.push(m)
				});
                    letme.Customers = array;
                }
                this.startfetching = false
            })
        },
        deleteItem(item) {
            this.editedIndex = this.suppliers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.suppliers.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        toUpperCase(str){
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
		onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        setToDefault() {
            this.editedItem = this.defaultItem
            this.close()
            this.dialog = true
        },
        getLS(item) {
            let ls = ''
            if(typeof item.shipments_object !='undefined'){
                let legs = {legs: []}
                try{
                    legs = item.shipments_object !== null ? JSON.parse(item.shipments_object.dispatch_schedule) : {legs: []}  
                }catch(err){
                    console.log(err)
                }

                if(legs.legs.length > 0) {
                    legs.legs.map(leg => {
                        if(leg.type === 'Customer') {
                            if(leg.departure_from_delivery_location_date !== "") {
                                ls = moment(leg.departure_from_delivery_location_date).utc(false).format('MMM DD, YYYY')
                            }
                            else if(leg.arrival_at_delivery_location_date !== "") {
                                ls = moment(leg.arrival_at_delivery_location_date).utc(false).format('MMM DD, YYYY')
                            }
                            else {
                                ls = moment(leg.from_date).utc(false).format('MMM DD, YYYY')
                            }
                        }
                        else {
                            ls = '--'
                        }
                    })
                }
                else {
                    return '--'
                }
            }

            return ls
        },
        clearSelection() {
            this.selected = []
        },
        openSRSD() {
            this.openDialog = true
        },
        closeSRSD() {
            this.openDialog = false
        },
        removeSelected(key) {
            this.selected.splice(key, 1)
        },
        showrow(item){
                this.storeEditData({
                    items:item,
                });
                this.$router.push("/customer-details/" + item.customer_id);
        },
        editItem2(event, {item}) {
            if(event){
                this.storeEditData({
                    items:item,
                });
                this.$router.push("/customer-details/" + item.customer_id);
            }
        },
        callSearchAPI() {
            let passedData = {
                method: "get",
                url: `${APIBaseUrl}/v2/customersList`,
                params: {
                    search: this.search
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            }
            
            this.fetchCustomersSearched(passedData)
            // this.setSearchedCustomersLoading(false)
        },
        handlePageChange(page) {
            if (page !== this.getCurrentPage) {
                this.fetchCustomersv2(page)
            }
        }
    },
    async mounted() {
        // set current page
        this.$store.dispatch("page/setPage", "customer");

        if(this.reloadcomponent){
            await this.fetchCustomersv2()
        }
    },
    updated() {
        if(this.selected.length > 0){
            this.isclickcheckbox = true;
        }
        // console.log(this.getCustomersListsLoading);
    }
}
</script>

<style lang="scss">
.supplier-container.customer-wrapper {
    .customer-page-wrapper {
        .v-data-table.customer-page-table {
            .v-toolbar__content {
                border-bottom: 2px solid #EBF2F5 !important;
                padding-right: 16px !important;

                .customer-search-component {
                    .search-wrapper-global-component {
                        width: 300px !important;
                    }
                }
            }

            .v-data-table__wrapper {
                height: calc(100vh - 292px);
                overflow-y: auto;

                table {
                    thead {
                        tr {
                            th {
                                box-shadow: none !important;
                                border-bottom: 2px solid #EBF2F5 !important;
                                padding: 8px 12px !important;
                                height: 35px;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                cursor: auto !important;
                                padding: 12px !important;

                                .actions-customer-wrapper {
                                    button {
                                        letter-spacing: 0 !important;
                                        height: 30px !important;

                                        .text-setup {
                                            font-size: 12px !important;
                                        }

                                        .w-6 {
                                            width: 12px !important;
                                            height: 12px;
                                        }

                                        &.v-btn--disabled {
                                            background-color: transparent !important;
                                            pointer-events: none;

                                            .text-setup,
                                            svg {
                                                color: #B4CFE0 !important;
                                            }
                                        }
                                    }
                                }
                            }

                            &.v-data-table__empty-wrapper {
                                td {
                                    border-bottom: none !important;
                                }

                                &:hover {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                }
            }

            &.no-data {
                .v-data-table__wrapper {
                    height: calc(100vh - 235px) !important;
                    border-bottom: 1px solid #EBF2F5 !important;
                }
            }
        }
    }
}

.v-menu__content.customer-menu {
	min-width: 150px !important;

    .customer-item-wrapper {
        min-height: 36px;
        cursor: pointer;
        
        .v-list-item__title {
            font-size: 14px !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
    }
}
</style>

<style>
.v-application .v-tooltip__content.tooltip-wrapper.menuable__content__active {
    background-color: #0171A1 !important;
    border-color: #0171A1 !important;
    opacity: 1;
}
.tooltip-top::before {
    border-right: solid 8px transparent;
    border-left: solid 8px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
}
.tooltip-top.error::before{
    border-top: solid 8px #ff6060;
}

.tooltip-top.success::before{
    border-top: solid 8px #1B5E20;
}
.tooltip-top.secondary::before{
    border-top: solid 8px #3f3f3f;
}

.tooltip-top.primary::before{
    border-top: solid 8px #0171A1;
}

.tooltip-top.info::before{
    border-top: solid 8px #0171A1;
}


.tooltip-bottom::before{
    border-right: solid 8px transparent;
    border-left: solid 8px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    bottom: 100%;
    left: 50%;
    height: 0;
    width: 0;
}

.tooltip-bottom.error::before{
    border-bottom: solid 8px #ff6060;
}

.tooltip-bottom.success::before{
    border-bottom: solid 8px #1B5E20;
}
.tooltip-bottom.secondary::before{
    border-bottom: solid 8px #3f3f3f;
}

.tooltip-bottom.primary::before{
    border-bottom: solid 8px #0171A1;
}

.tooltip-bottom.info::before{
    border-bottom: solid 8px #0171A1;
}


.tooltip-right::before{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-left: solid 8px transparent;
}

.tooltip-right.error::before{
    border-right: solid 8px #ff6060 !important;
}

.tooltip-right.success::before{
    border-right: solid 8px #1B5E20 !important;
}
.tooltip-right.secondary::before{
    border-right: solid 8px #3f3f3f !important;
}

.tooltip-right.primary::before{
    border-right: solid 8px #0171A1 !important;
}

.tooltip-right.info::before{
    border-right: solid 8px #0171A1 !important;
}



.tooltip-left::before{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-right: solid 8px transparent;
}

.tooltip-left.error::before{
    border-left: solid 8px #ff6060 !important;
}

.tooltip-left.success::before{
    border-left: solid 8px #1B5E20 !important;
}
.tooltip-left.secondary::before{
    border-left: solid 8px #3f3f3f !important;
}

.tooltip-left.primary::before{
    border-left: solid 8px #0171A1 !important;
}

.tooltip-left.info::before{
    border-left: solid 8px #0171A1 !important;
}
.v-tooltip__content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 0px !important;
  border-style: solid;
  border-color: #4A4A4A transparent transparent transparent;
}
</style>

<style>
.v-data-table__selected {
    background: #F0FBFF !important;
}

.customer-action-btn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    width: 70px;
    height: 30px !important;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    box-shadow: none;
    border-radius: 4px;
    background: #FFFFFF !important;
    text-transform: capitalize;
}
.customer-action-btn-edit {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 55px !important;
    max-width: 55px !important;
    height: 30px !important;
    border: 1px solid #B4CFE0;
    box-shadow: none;
    border-radius: 4px;
    background: #FFFFFF !important;
    text-transform: capitalize;
}
.clear-text-setup {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.text-setup {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
}
.text-d-blue {
    color: #0171A1;
}
.text-d-danger {
    color: #F93131;
}
.width-6 {
    width: 15px;
}
.w-6 {
    width: 0.595rem;
}
.h-6 {
height: 6px;
}
.search-component-wrapper {
    width: 233px !important;
}
.classsort {
	background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
	margin-left: 4px;
	width: 21px;
	height: 17px;
	display: block;
	position: relative;
	top: 3px;
	left: 1px;
	float: right;
}
/* .customer-wrapper {
  -webkit-user-select: none; Safari 
  -ms-user-select: none; IE 10 and IE 11
  user-select: none; Standard syntax 
} */
</style>
