<template>
    <div 
        v-if="reftable">
        <v-row 
            style="margin-bottom:5px;">
            <v-col 
                :md="getstate">
                <h3 
                    class="headerContainer" 
                    style="font-size:16px;padding-top:7px">Containers</h3>
            </v-col>      
            <v-col 
                v-if="hachecked && !hideshowmove && !iscreate"              
              md="6" 
              style="display:flex;padding-right:6px;"   
            >                 
                <div 
                        v-if="hachecked && !hideshowmove && !iscreate && !ismovetoexisting"
                        style="width:100%;display:flex"  
                >                   
                    <span 
                        @click="clearselection" 
                        style="position:relative;left:12px;color:#0171A1;font-size:14px;padding-top:9px;
                            cursor:pointer;width:100%;text-align:right"
                    >
                    Clear Selection
                    </span> 
                    <div style="border:1.6px solid #B4CFE0;
                            border-radius:4px;
                            text-align:center;
                            cursor:pointer;
                            margin-left:34px;
                            padding-top:9px;
                            padding-bottom:9px;
                            width:75%">
                        <div 
                            @click="movetootherdo"
                            style="color:#0171A1;font-size:14px;width:100%"

                            > 
                            Move to other DO 
                        </div>  
                    </div>         

                </div>
            </v-col>
        <v-progress-circular
        :size="20"
        :width="2"
        color="#0171a1"
        v-if="itemlistdown.length ==0"
        indeterminate       
        >
        </v-progress-circular> 
        </v-row>
        <table 
            class="containertable" 
            style="width:100%;
                   width: 100%;
                   border-collapse: collapse;
                   border-spacing:0"
            >
            <thead>
                <tr> 
                    <th  
                        v-if="istriggerededit==0"
                        style="width:4%;
                               padding-left:2px;"
                        >
                        <v-checkbox 
                            v-model="selectAll">
                        </v-checkbox>
                    </th>
                    <th 
                        style="width:21%">
                        Container #
                    </th>
                    <th style="width: 10.2%;">
                        Size
                    </th>
                    <th 
                        class="text-right"
                        style="width: 19%">
                        Weight
                    </th>
                    <th
                        class="text-right"
                        style="width: 19%">
                        Volume
                    </th>
                    <th 
                        class="text-right"
                        style="width: 13%">
                        QTY
                    </th>
                     <th
                        style="width:13%">
                        <span 
                            style="display:none;">
                            Action
                        </span>                        
                    </th>
                </tr>
            </thead>
        <tbody>
        <tr            
            class="tablecontainer" 
            style="padding-left:19px;
                   margin-top:20px"                                
            v-for="(category,index) in itemlistdown" 
            :key="index"  
            :class="roottablecontainer+'_'+index" 
            >    
            <td 
                class="colorRow" 
                colspan="7"
            >
            <table 
                cellpadding="0" 
                cellspacing="0" 
                class="innertable" 
                style="width:100%"
                >
                <tr> 
                <td 
                    v-if="istriggerededit==0"
                    style="width:4% !important"
                    > 
                    <v-checkbox 
                        v-model="category.checked" 
                        @change="getchange($event,index)"                                         
                    >
                    </v-checkbox> 
                </td>                        
                <td 
                    style="width:21%"
                    @click="checkindex(index)"
                    >
                    <!-- <div
                        style="position:relative;
                               min-height:32px;
                               height:32px;
                               top:6px"
                    >                           
                        <div
                            style="color:#4A4A4A">
                            {{category.container_number}}
                        </div>
                        <img
                            style="position:absolute;
                                   right:11px;
                                   top:8px;"
                            class="" 
                            src="../../../assets/icons/arrow_down.svg"
                        >
                    </div>            -->
                    <div
                        class="flex"
                    >                           
                        <div
                            style="color:#4A4A4A">
                            {{category.container_number}}
                        </div>
                        <img
                            class="ml-3" 
                            src="../../../assets/icons/arrow_down.svg"
                        >
                    </div>           
                </td>  
                <td 
                    style="width:10.2%">               
                    <div 
                        style="color:#4A4A4A">
                        {{getthesizename(category.container_size)}}
                    </div>
                </td>
                <td 
                    class="text-right"
                    style="width: 19%"> 
                    <div 
                        style="color:#4A4A4A">
                        {{category.container_weight}} <span class="units-color">KG</span>
                    </div>
                </td>
                <td
                    class="text-right"
                    style="width: 19%">
                    <div 
                        style="color:#4A4A4A">
                        {{category.container_volume}} <span class="units-color">CBM</span>
                    </div>
                </td>
                <td 
                    class="text-right"
                    style="width: 13%;"> 
                    <div 
                        style="color:#4A4A4A">
                        {{category.container_qty}}
                    </div>
                </td> 
                <td 
                    class="lastchild text-center" 
                    style="width: 13%;"
                    >
                    <span v-if="!category.checked"
                        @click="execeditnow(index)" 
                        style="padding-left:15px;
                               padding-left:15px;cursor:pointer">                    
                        <svg 
                            width="16"
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7706 1.22941C15.7431 2.20196 15.7431 3.77878 14.7706 4.75133L5.57307 13.9488C5.17412 14.3478 4.67424 14.6308 4.12688 14.7677L1.27416 15.4808C0.818185 15.5948 0.405162 15.1818 0.519156 14.7258L1.23234 11.8731C1.36918 11.3258 1.6522 10.8259 2.05116 10.4269L11.2487 1.22941C12.2212 0.256862 13.798 0.256862 14.7706 1.22941ZM10.3681 3.87072L2.93163 11.3074C2.69226 11.5468 2.52245 11.8467 2.44034 12.1751L1.97883 14.0212L3.82488 13.5597C4.15329 13.4776 4.45322 13.3077 4.69259 13.0684L12.1288 5.63141L10.3681 3.87072ZM12.1291 2.10989L11.2484 2.99037L13.0091 4.75106L13.8901 3.87085C14.3764 3.38457 14.3764 2.59617 13.8901 2.10989C13.4038 1.62362 12.6154 1.62362 12.1291 2.10989Z" fill="#0171A1"/>
                        </svg>
                    </span>                
                    <span v-if="(istriggerededit==0 || istriggerededit==3) && !category.checked"
                        @click="deleteClear(index)" 
                        style="padding-left:15px;
                               padding-left: 15px; cursor:pointer">                                     
                        <svg 
                            width="14" 
                            height="16" 
                            viewBox="0 0 14 16" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.375 2.77273C8.375 2.01961 7.75939 1.40909 7 1.40909C6.24061 1.40909 5.625 2.01961 5.625 2.77273H4.70833C4.70833 1.51753 5.73435 0.5 7 0.5C8.26565 0.5 9.29166 1.51753 9.29166 2.77273H13.4167C13.6698 2.77273 13.875 2.97623 13.875 3.22727C13.875 3.47831 13.6698 3.68182 13.4167 3.68182H12.9088L11.7208 13.8902C11.614 14.8077 10.8307 15.5 9.89953 15.5H4.10047C3.16925 15.5 2.38596 14.8077 2.27922 13.8902L1.09025 3.68182H0.583333C0.358328 3.68182 0.171192 3.52102 0.132384 3.30898L0.125 3.22727C0.125 2.97623 0.330203 2.77273 0.583333 2.77273H8.375ZM11.9848 3.68182H2.01425L3.18984 13.786C3.24321 14.2447 3.63486 14.5909 4.10047 14.5909H9.89953C10.3651 14.5909 10.7568 14.2447 10.8102 13.786L11.9848 3.68182ZM5.625 5.95455C5.85 5.95455 6.03714 6.09524 6.07595 6.28078L6.08333 6.35227V11.9205C6.08333 12.1401 5.87813 12.3182 5.625 12.3182C5.39999 12.3182 5.21286 12.1775 5.17405 11.9919L5.16666 11.9205V6.35227C5.16666 6.13261 5.37187 5.95455 5.625 5.95455ZM8.375 5.95455C8.6 5.95455 8.78714 6.09524 8.82595 6.28078L8.83333 6.35227V11.9205C8.83333 12.1401 8.62813 12.3182 8.375 12.3182C8.14999 12.3182 7.96286 12.1775 7.92405 11.9919L7.91666 11.9205V6.35227C7.91666 6.13261 8.12187 5.95455 8.375 5.95455Z" fill="#F93131"/>
                        </svg>
                    </span>                
                </td>        
                </tr>
                <tr>
                    <td 
                        colspan="7">
                        <div 
                            style="" 
                            v-if="currentshow(index)" 
                            class="data-element">
                            <div 
                                style="border-bottom:1px solid #eee;
                                       font-size:10px;
                                       color:#6D858F" 
                                class="item">Goods Delivery Address:
                            </div>
                            <div
                                style="color:#4A4A4A;
                                       font-size:12px;"
                                class="item">{{category.delivery_to}}
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
          </td>                              
        </tr>
        </tbody>
        </table>
        <MoveDialog
            :dialogData.sync="dialog"  
            @movecontainer="createdo"
            @clickContinue="continueClick"  
            :locType="isdialogDos"
        />

        <Movdedialogerror
            :dialogData.sync="dialogshowerrordialog"   
            @confirmDialog="continueDialog"            
        />
        <Showconfirmdelete
            :dialogData.sync="showdeleteconfirm" 
            @deleteconfim="deleteconfim"            
            @close="closedeleteconfirm"    
        />

        <ShowSelectMovetoExisting   
            :dialogData.sync="showSelectMovetoExisting"
            @save="saveDOcontainer"          
        /> 
    </div>
</template>
<script>
import globalMethods from '../../../utils/globalMethods'
import MoveDialog from './dialog/MoveDialog.vue'
import Movdedialogerror from './dialog/Movdedialogerror.vue'
import Showconfirmdelete from './dialog/showdeletemodal.vue'
import ShowSelectMovetoExisting from "./dialog/MovetoexistingDialog.vue";
import jQuery from 'jquery'
import Vue from "vue";
export default {
    name: 'ListContainers',
    props: ['containerList',
            'ContainerSize',
            'disableEditContainers',
            'bus',
            'iscreate',
            'noerrorForm',
            'ismovetoexisting',
            'istriggerededit',
            'updated',
            'haschanges'
           ],
    components: {
        MoveDialog,
        Movdedialogerror,
        Showconfirmdelete,
        ShowSelectMovetoExisting  
    },
    data()
    {
        return {
        isdialogDos:0,
        showSelectMovetoExisting:0,    
        choosenindexdelete:0,
        showdeleteconfirm:0,    
        dialogshowerrordialog: 0,
        disableni: true,
        hideshowmove: 0,
        dialog:0,
        hachecked:0,
        dataArray:[],
        checkbox1:false,
        showrow:false,
        page: 1,
        checkni: 0,
        selectAll:0,
        startfetching: 1,
        pageCount: 0,
        itemsPerPage: 9,       
        eventBus: new Vue(),
        disableselectsize:0,
        reftable:1,
        currentindexshow:99999,
        roottablecontainer:'blueteam',
        containersize:'',
        container_number:'',
        container_size:'',
        container_weight:'',
        container_volume:'',
        container_qty:'',
        DeliveryTo:'',
        typeError:'', // type of error return from api       
        isFetching:0,            
        valid: true, 
        value: [],
        isMobile: false,
        headers: [             
            {
                text: "Container#",
                align: "start",
                value: "container_number",
                sortable: true,
                width: "20%",
                fixed: true
            },
            {
                text: "Size",
                align: "start",
                sortable: true,
                value: "container_size",
                width: "20%",
                fixed: true
            },
            {
                text: "Weight",
                align: "start",
                value: "container_weight",
                sortable: false,
                width: "24%",
                fixed: true
            },
            {
                text: "Volume",
                align: "start",
                value: "container_volume",
                sortable: true,
                width: "20%",
                fixed: true
            },
            {
                text: "QTY",
                align: "start",
                value: "container_qty",
                sortable: false,
                width: "9%",
                fixed: true
            },
        ]    
    }},   
    watch:{       
        containerList(v,c){
           console.log(v.length)
           console.log(c.length)
           
        },     
        selectAll(VALUE){               
            if(VALUE || !VALUE){               
                this.containerList.map((e) => {
                    e.checked = VALUE;                   
                })
                
                this.hachecked = (VALUE==true)? 1:0
            }          
        },     
    },
    computed: {  
        getstate:{
            get(){
                if(this.hideshowmove && !this.noerrorForm){
                    return 9;
                }
                return 6;
            }
        },
        itemlistdown:{
            get () {               
                // return this.containerList              
                if(this.containerList.length>0){
                    this.containerList.map((e)=>{
                        e.checked = 0;                        
                    })
                }
                return this.containerList;
            },
        },   
        itemsDropdownSize:{
            get () {
                return this.ContainerSize
            },
        },      
    },   
    methods: {        
        ...globalMethods, 
         deleteClear(i){           
            // this.containerList.splice(i, 1);  
            // this.$emit("tellthelengthobject",{
            //         value:this.containerList.length,                   
            // }); 
            if(i){
                this.showdeleteconfirm = 1;    
                this.choosenindexdelete = i;   
            }           
            // tell parent that deleted          
        },
        continueClick(x){
          if(x.chosen){
            // SHOW THE MODAL MOVE TO EXISTING DIALOG IF THE CHOSEN IS THE RADIO 2
            this.showSelectMovetoExisting = 1 // show move dialog existing show
            this.dialog = 0; // move dialog hidden          
          }else{  
            // CHOSEN RADIO 1         
            this.createdo(x);
          }
        },
        saveDOcontainer(item){
            if(item.state !== 'createdo'){
                this.showSelectMovetoExisting = 0 
                this.$emit('movecontainer',{
                                    state: 'moveDo',
                                    shifl_ref:item.shifl_ref,
                                    selected: item.selected
                                }
                            );
            }
        },
        deleteconfim(x){
            if(x){
                let i = this.choosenindexdelete
                this.containerList.splice(i, 1);  
                this.$emit("tellthelengthobject",{
                    value:this.containerList.length,  
                }); 
                this.showdeleteconfirm = 0
            }
        },
        getthesizename(item){         
            var c = this.ContainerSize.find((e) => e.id == parseInt(item));
            if(typeof c !='undefined'){
                return c.name
            }          
        },
        disabledropdown(x){ 
            var returnbool = true;                  
            if(this.calleditableELe == x){
                returnbool = false;
            }           
            return returnbool
        },
        closedeleteconfirm(e){
            if(e){               
                this.showdeleteconfirm = 0
            }
        },
        checkeditableni(x){ 
            var returnbool = true;                  
            if(this.calleditableELe == x){
                returnbool = false;
            }           
            return returnbool
        },
        continueDialog(){
            this.dialogshowerrordialog = 0;
        },
         createdo(item){           
            if(item.state == 'createdo'){
                this.dialog = 0
                this.hideshowmove = 1;
                this.$emit('movecontainer',{state: 'createdo'});
            }else{
                this.dialog = 0
                this.hideshowmove = 1;
                this.$emit('movecontainer',{
                                state: 'moveDo',
                                shifl_ref:item.shifl_ref,
                                selected: item.selected
                            }
                        );
                this.$emit('movecontainer',{state: 'moveDo',shifl_ref:item.shifl_ref,selected: item.selected});
            }
        },
        currentshow(index){
            if(index ==  this.currentindexshow)
            return 1;
        },
        execeditnow(index){
            this.edit(index)
        },
        movetootherdo(){            
            var length_containers = this.itemlistdown.length;
            var count = 0;
            var checkundefined=0;
                
            this.itemlistdown.forEach(element => {
                if(element.checked){
                   count++;
                }
                if(typeof element.container_id =='undefined'){
                    checkundefined = 1;
                }
            });           
           
            if(count===length_containers && this.noerrorForm == false){  
                if(checkundefined){                  
                    this.$emit('update:updated',4); // detect changes
                }  else{
                    this.$emit('update:updated',3); // move not allowed if all checkboxes are checked
                }               
            }else{
                if(this.haschanges && this.noerrorForm == false){              
                    this.$emit('update:updated',4); // detect changes
                }else{
                    if(this.noerrorForm){
                         this.$emit('update:updated',5); // show error
                    }else{
                        this.dialog = 1 // show move dialog
                    }
                }
            }  
              
        },
        clearselection(){
            this.containerList.map((e)=> { e.checked = 0 }) 
            this.reftable = 0;   
             this.$nextTick(() => {
                this.selectAll = 0;
                this.reftable = 1;               
            });          
        },
        getchange(e,index){
            this.containerList[index].checked = e;           
            this.reftable  = 0

            let datavaluecheked = this.containerList.filter((e)=> e.checked == 1)            
            if( datavaluecheked.length ){
                this.hachecked = 1 // show selection and move to  other document 
            }else {              
                this.hachecked = 0

                if(this.selectAll){
                    this.selectAll = 0;
                }
            }
       
            this.$nextTick(() => {
                this.reftable = 1;               
            });
                   
        },
        edit(index){            
            this.calleditableELe=index;           
            this.reftable = 0 
            // remove this , dili na ipa reload para ang focus naa gihapon
            this.$nextTick(() => {
                this.reftable = 1;               
            }); 
            //showEditnow
            this.$emit("showEditnow",{
                                        data:this.containerList,
                                        index:index,
                                        show:1
                                     });
        },
        checkindex(index){
            this.currentindexshow = ( this.currentindexshow == index) ? -1 : index           
            jQuery('.tablecontainer').css({'background':'none'})
            jQuery(".blueteam_"+index).css({'background':'#F0FBFF'});
        },     
        close() {
            this.$refs.form.resetValidation()
            this.$emit('update:dialogData', false)
        },  
              
    },
    mounted(){  
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../assets/scss/pages_scss/deliveryorder/deliveryAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>

<style>
.text-field{
    color: red !important;
    /* add whatever properties you want */
}

.v-dialog__content .v-textarea .v-input__slot {
    border:1px solid #B4CFE0 !important;
}

.containersizemodal {
    font-size: 14px;
}
.containersizemodal  .v-input__slot {
    box-shadow: none !important;
    border:1px solid #B4CFE0 !important;
}
.containersizemodal .v-icon{
    font-size: 13px;
}

.mainWrapperListContainers {
    margin-top:15px
}
.mainWrapperListContainers .v-select--is-menu-active{
    background:none !important;
}
.containersizemodal .v-input__control  .v-input__slot .v-select__slot .v-input__append-inner 
.v-input__icon  .ic-chevron-down::before {
    font-size: 16px !important
}
.clanapud {
    width: 100%;
}
.tablecontainer:hover {
    background:#F0FBFF !important; 
}
.ic-chevron-down {
    font-size:16px !important;
}

 .theme--light.v-icon {
  color: #0171A1 !important;
}

.v-input--selection-controls__ripple.green--text {
   color: #0171A1 !important;
}

.containertable thead th {
    text-align: left
}

.innertable tr td {
    border-collapse: collapse;
    padding: 2px;
}

.innertable .v-input--checkbox{
    padding-top: 6px;
    margin-top: 0px;
}

.containertable th .v-input--checkbox {
     height: 29px !important;
     margin-top: 0px;
}
.innertable .v-input__control {
    height: 29px !important;
}

.colorRow {
    border-bottom:2px solid #EBF2F5;
    
}
.containertable th{
    border-top: 2px solid #EBF2F5;
    padding-top: 4px;
    padding-bottom: 5px;
    border-bottom: 2px solid #EBF2F5 !important;
    color:#6D858F;
    font-size:14px;
}
.data-element {
    background: rgb(255, 255, 255);
    margin-top: 7px;
    width: 91.5%;
    margin-bottom: 18px;
    padding-left: 12px;
    margin-left: 36px
}
.v-label{
  font-size: 14px !important;
}
.units-color {
    color: #6D858F
}
</style>