<template>
    <v-dialog
        v-model="dialogDatepicker"
        width="430"
        content-class="dialogNewDatepicker"
        persistent
    >                          
        <v-card>  
            <div style="padding-top: 24px;
                        padding-left:14px">
            <span style="display:inline-block;
                            width:261px;
                            color:rgb(74, 74, 74);
                            font-size: 20px;
                            font-weight: 600;"
            >
                Pickup Schedule Date
            </span>
            <span 
                :disabled='isDisabled'
                @click="resettodefault"
                style="cursor:pointer;" 
                class="resetbutton">
                Reset to default
                </span>
            </div>                         
            <v-card-text 
                class="datepickercardtext" 
                style="padding:0px;"
            >
            <div id="showcase-wrapper">
                    <div
                        id="myCalendarWrapper" 
                        class="myCalendarWrapper">
                        <div 
                            id="centerdivheader">
                            <p 
                                style="text-align:center;"
                            >
                                {{pickdate}}
                            </p>
                        </div>
                        <div 
                            id="calendar-wrapper"
                        >
                            <div 
                                id="headerCalendar"
                                >
                                <h4>
                                    {{montheheader}}
                                </h4>
                                <section 
                                    id="navigation-wrapper">
                                    <button 
                                        id="previous-month" 
                                        aria-label="Go to previous month">
                                        <span 
                                            @click="clickP" 
                                            class="arrowleft">
                                        </span>
                                    </button>
                                    <button 
                                        id="next-month" 
                                        aria-label="Go to next month"
                                        >
                                        <span 
                                            @click="clickN" 
                                            class="arrowRight">
                                        </span>
                                    </button>
                                </section>
                            </div>
                            <div id="calendar">
                                <div id="calendar-days">
                                    <span>Mon</span>
                                    <span>Tue</span>
                                    <span>Wed</span>
                                    <span>Thu</span>
                                    <span>Fri</span>
                                    <span>Sat</span>
                                    <span>Sun</span>
                                    </div>
                                    <div id="calendar-grid">
                                        <time v-for="(item,index) 
                                             in theloopdays" 
                                             :key="index" 
                                             :class="classthisName(item)" 
                                             @click="runtimepick(item)">
                                           {{item.date}}
                                        </time>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </v-card-text>                           
            <v-card-actions 
                    style="border:0px !important;
                           border: 0px none !important;
                           padding:4px 15px 15px 15px"
            >                            
                <v-btn
                color="primary"
                text
               :disabled="isActive"             
                @dblclick="confirm"
                style="background:#0171A1 !important;
                       padding:10px 16px 10px 16px !important;
                       color:#fff;width: 48%;                                      
                       margin-right: 1px; height:40px;"
                >
                    Confirm
                </v-btn>
                <v-btn
                color="primary cancelbtn"
                text
               
                @click="close"
                style="background:#fff;
                       height:40px;
                       padding: 10px 16px;
                       color:#0171A1;
                       width: 50%"
                >
                Cancel
                </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: "CreatePicker",
  props: [
    "dialogDatepicker",
    "defaultData"
  ],
  components: {},
  data() {
    return {
     isActive: true,   
     isDisabled:0,
     day:new Date().getDay(),
     month:new Date().getMonth(),
     year:new Date().getFullYear(),
     today:'',
     value:'',
     min:0,
     max:0,
     calendarDayElementType:'time',
     listOfAllDaysAsText:[],
     listOfAllMonthsAsText:[],
     theloopdays:[],
     montheheader:'',
     monthNames:["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"],
     pickdate:'Pick a date from calendar below',
     default_month:new Date().getMonth(),
     default_year:new Date().getFullYear(),
     currentelementclass: '',
     dialoginitshow: 0,
     thecurrentdatastring:'',
    }
  },
  watch: {
    pickdate(c){
        if(c === ''){
            this.isActive = true
        } else if(c=='Pick a date from calendar below'){
            this.isActive = true
        }else if(c !==''){
            this.isActive = false
        }
    },
    dialogDatepicker(c){
        if(c){  
            if(this.defaultData !=''){
                if(this.validateDate(this.defaultData)){               
                var sp        = (this.defaultData).split('-');
                this.month    = parseInt(sp[1]) - 1;
                this.year     = parseInt(sp[0]);
                this.day      = String(sp[2]).padStart("2",0);
                this.pickdate = this.monthNames[this.month] + 
                                '  ' +  
                                ( this.day)+', '+ this.year // reset the pickdate to default
                }
            }
            this._insertDaysIntoGrid(1);           
        }else{
            // i klos then risit 
            var date = new Date();  
            this.day   = date.getDay()
            this.month = date.getMonth();
            this.year  = date.getFullYear();
            this.pickdate = 'Pick a date from calendar below';           
        }
    }
  },
  mounted() { 
    var date = new Date();
    this._formatDateToInit(date);
    this.day   = date.getDay()
    this.month = date.getMonth();
    this.year  = date.getFullYear();  
    this.today = date;
    // The calendars value should always be the current date.
    this.value = date;
    // Ranges for the calendar (optional).
    const nextYear  = new Date().getFullYear() + 1
    this.min = new Date();
    this.max = new Date(nextYear, 10);
    this._formatDateToInit(this.min);
    this._formatDateToInit(this.max);
    var beginningOfYearOnMonday = new Date('2018-01-01');
    var locale = 'en-US';
    this.listOfAllDaysAsText = [...Array(7).keys()].map(this._toTranslatedWeekday(beginningOfYearOnMonday, locale, true))
    this.listOfAllMonthsAsText = [...Array(12).keys()].map(this._toTranslatedMonth(beginningOfYearOnMonday, locale))  
  },
  methods: {
    resettodefault(){
        if(this.defaultData !=''){
            let string = (this.defaultData);
            let sp = string.split('-');
            this.month = (sp[1] - 1), this.year = (sp[0]),this.day = (sp[2]),[]
            this.pickdate = this.monthNames[this.month] + '  ' +  ( this.day)+', '+ this.year // reset the pickdate to default
        }else{
            let datenew = new Date();
            this.month = datenew.getMonth(), this.year = datenew.getFullYear(),this.day = datenew.getDate(),[] 
            this.pickdate = 'Pick a date from calendar below';
        }       
        this._insertDaysIntoGrid(1); 
    },
    confirm(){
        
        let item = new Date(this.pickdate);
        let str = String(item.getMonth() + 1);
        let newstring = str.padStart(2, "0") 
        let monthpad = String(item.getDate());
        let paddstring = monthpad.padStart(2, "0")         
        this.$emit("passbackdata",item.getFullYear() + '-'+ newstring +'-' + paddstring)
        this.$emit("close");
    },
    classthisName(name){  
        
        let datecurrent = String(name.year) +'-'+ String(name.month + 1)+'-'+ String(name.date)            
        
        if (String(name.date) + String(name.month) === String(this.currentelementclass.date) + String(this.currentelementclass.month)) {       
            if(this.thecurrentdatastring == datecurrent){
                return "selected";
            }
            return "selected";
        }
        
        if(this.thecurrentdatastring === datecurrent){
            return "xcurrentName";
        }
        return name.classname;
    },
    close() {
      this.$emit("close");
    },
    _formatDateToInit(date) {
        if (!date) return;
        date.setHours(0, 0, 0);
    },
    validateDate(dateStr){
        if(typeof dateStr =='undefined') return false;
        let str = dateStr.split('T');
        dateStr = str[0];
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateStr.match(regex) === null) {
            return false;
        }
        const date = new Date(dateStr);
        const timestamp = date.getTime();
        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
        }
        return date.toISOString().startsWith(dateStr);     
    },
    clickP(){
        // DILI KA PWEDI MAKAVIEW SA PREVIOUS MONTH LESS THAN THE CURRENT
        var current = new Date();
        if(current.getFullYear() <= this.year){
            if(current.getFullYear() == this.year){                       
                if(current.getMonth() < this.month ){
                    this.tawagasiprev(1);
                }else{
                   // this.tawagasiprev(1);
                }
            }else{
                this.tawagasiprev(1);
            }
        }  else{
           // this.tawagasiprev(1);
        } 
        // uncomment if enable bisag previous month less than current
        
    },
    clickN(){
        if (this.month === 11) {
            this.month = 0;
            this.year =  parseInt(this.year) + 1;
        } else {
            this.month = parseInt(this.month) + 1;
        }
        this.renewgrid();
    },
    renewgrid(){
        this.date = new Date(this.year, this.month);       
        this.day = this.date.getDay();
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();       
        var that = this;
        window.requestAnimationFrame(function () {           
            that._insertDaysIntoGrid(0);
        })
    },
    _getDaysInMonth(month, year) {
        if ((!month && month !== 0) || (!year && year !== 0)) return;
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    },
    tawagasiprev(allowpre){
        if(allowpre){
            if (this.month === 0) {
                this.month = 11;
                this.year = parseInt(this.year) - 1;
            } else {
                this.month = parseInt(this.month) - 1;
            }
            this.renewgrid();  
        }
    },
    _toTranslatedWeekday(beginningOfYearOnMonday, locale, showShortWeekdays) {
        var weekdayFormat = showShortWeekdays ? 'short' : 'long'
        return function(dayOfWeekIndex) {
            return new Intl.DateTimeFormat(locale, { weekday: weekdayFormat })
                .format(new Date(beginningOfYearOnMonday.getFullYear(), 
                beginningOfYearOnMonday.getMonth(), 
                beginningOfYearOnMonday.getDate() + dayOfWeekIndex))
        }
    },
    _toTranslatedMonth(beginningOfYearOnMonday, locale) {
        return function(monthIndex) {
            return new Intl.DateTimeFormat(locale, { month: 'long' })
                .format(new Date(beginningOfYearOnMonday.getFullYear(), 
                beginningOfYearOnMonday.getMonth() + monthIndex, 
                beginningOfYearOnMonday.getDate()))
        }
    },
    runtimepick(item){
        var isturn = 0;
        switch(item.classname){
            case "disabled":
            case "disablePrev":
            isturn = 1;
            break;
        }
        if(isturn) return 0;
       
        if(item.isback){           
            this.clickP();
        }
        if(item.isNext){           
            this.clickN();
        }          
        this.currentelementclass = item
        this.pickdate = item.fulldatestring
    },
    _insertDaysIntoGrid(c) {   
        if(c) this.currentelementclass = ''; // if show dialog then reset to empty para sa atuo selected
        this.montheheader = this.monthNames[this.month] + ' ' + this.year;
        var arrayOfDays   = this._getDaysInMonth(this.month,this.year);
        var firstDayOfMonth = arrayOfDays[0].getDay();
      
        // Converting Sunday (0 when using getDay()) to 7 to make it easier to work with.
        firstDayOfMonth = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;
        if (1 < firstDayOfMonth) {
            arrayOfDays = Array(firstDayOfMonth - 1).fill(false, 0).concat(arrayOfDays);
        }        
        /*
            0 = January
            1 = February
            2 = March
            3 = April
            4 = May
            5 = June
            6 = July
            7 = August
            8 = September
            9 = October
            10 = November
            11 = December
        */
        // current  
        const thedate = new Date();
        const currentYear = this.year;
        var numberofdays = new Date(currentYear,this.month,0).getDate();
        var mondays = firstDayOfMonth - 1;
        var count = 0;
        var finalcount = 0;
        var seven = 7;
        var ogirinallength = arrayOfDays.length;
        // computation previous
        // var equal = 31 - ( 3 -  0);
        // 31 - ( 3 - 0) = 28
        // 31 - ( 3 - 1) = 29
        // 31 - ( 3 - 2) = 30
        // 31 - ( 3 - 3) = 31 
      
        var indexofprev = [];
        // pre
        arrayOfDays.map((date,index) => {            
            if(count == 7){
                count = 0;
            }
            if(date == false){               
                let h = numberofdays - ( mondays - ( index + 1) );                              
                let date1  = new Date(currentYear,this.month - 1,h ); 
                arrayOfDays[index] = date1   
                indexofprev.push(index);            
            }
            count++;
            finalcount = count;
        });   
        //sunod
        var finaldays = seven - finalcount;      
        for(var i = 1; i <= finaldays;i++){
            var date2  = new Date(currentYear,this.month + 1,i);  
            arrayOfDays[arrayOfDays.length + i] = date2  
        }
        this.theloopdays =[];       
        arrayOfDays.forEach((date,index) => {            
            var className = '';
            var datevalue = '';
            var getMonth  = '';
            var  getDate  = '';
            var isback    = 0;
            var isNext    = 0;
         
            var Date = date.toString().split(' ')[2];
            var thedatadate = '';
            if( Date.charAt( 0 ) === '0' ){
                thedatadate = Date.slice( 1 )
            }else{
                thedatadate = Date
            }
                               
            var dateIsBetweenAllowedRange = (this.min || this.max ) && (date.toString() !== this.today.toString() && 
                                            (date < this.min || date > this.max))
           
            // if karon na month and year karon pud
            if (dateIsBetweenAllowedRange && ( this.year == currentYear ) ) {
                if(mondays > index ){                   
                    className = 'disablePrev';
                    datevalue = thedatadate;
                    isback = 0; 
                }else{
                    className = 'disabled';
                    datevalue = thedatadate;                    
                }
                getMonth  = date.getMonth()
            } else {
                if( (ogirinallength) <= index ){                   
                    isNext = 1;
                    className = 'disableNext';
                    datevalue = thedatadate;
                }else{                   
                    if(indexofprev.includes(index)){                       
                         className = 'PrevEnable';
                         datevalue = thedatadate;
                         isback = 1;
                    }else{                        
                        // if their is default data then make
                        if(date.getDate() === thedate.getDate()){ 
                            if(this.defaultData ==''){
                                className = 'selected';
                            }
                        }else{
                            className = 'xx';
                        }                        
                        datevalue = thedatadate;
                        isback = 0;
                        isNext = 0;
                    }
                }
                getMonth  = date.getMonth()
            }       
            getDate  = date.getDate()
           
            if(this.defaultData !=''){
                let str = String(getMonth + 1);
                let c   = str.padStart(2, "0") 
                var cdate =  String(getDate).padStart("2",0)
                var stringdate = date.getFullYear()+'-'+(c)+'-'+cdate; 
                if(stringdate == this.defaultData){                   
                    className = 'selected';
                }
            }
            let datecurrent = thedate.getFullYear() +'-'+ String(thedate.getMonth() + 1)+'-'+ String(thedate.getDate())
            let datecurrentloop = date.getFullYear() +'-'+ String(date.getMonth() + 1)+'-'+ String(date.getDate())
            
            this.thecurrentdatastring = datecurrent;
            
            if(datecurrentloop === datecurrent){
                className = 'xcurrentName'
            }
            this.theloopdays.push({classname:className,
                                   date:datevalue,
                                   year: date.getFullYear(),
                                   month: getMonth,
                                   isback: isback,
                                   isNext: isNext,
                                   fulldatestring: this.monthNames[getMonth] + ' ' + getDate +', ' + date.getFullYear(),
                                   index: index});         
        })            
    }
  }
};
</script>
<style>
    .add-option-btn:hover {
        background: rgba(180, 207, 224, 0.3);
        cursor: pointer;
    }
</style>
<style>
    .resetbutton {
        padding: 9px 13px;         
        height: 40px;
        background: #F5F9FC;
        border: 1px solid #819FB2;
        border-radius: 4px;
        font-size: 14px;
        color:#0171A1;
        display:inline-block;
        padding-left: 16px;
        padding-right: 16px;
    }
    #headerCalendar {
        display: flex;
        border-bottom: 1px solid #EBF2F5 !important;
        height: 30px;
        margin-left:12px;
        margin-right:12px;
    }
    #headerCalendar h4 {
        width: 334px;
        padding-left: 12px;
        border-bottom: 1px solid #EBF2F5 !important;
    }
    #headerCalendar #navigation-wrapper {
        width: 60px;
        border-bottom: 1px solid #EBF2F5 !important;
        text-align:right;
        padding-top:6px;
    }
    #headerCalendar #navigation-wrapper #previous-month {
        width: 40px;
    }
    header {
        display: flex ;
        grid-template-columns: 1fr 4em;
        grid-template-rows: 3em 1fr;
        box-sizing: inherit;
        padding: 0;
        margin: 0;
    }
    .dialogNewDatepicker  {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
        supported by Chrome, Edge, Opera and Firefox */
    }
    #myCalendarWrapper {
        width: 100%;           
        padding: 2px 2px;
        position: relative;
        font-size: 1rem;
        padding-top:18px;
        margin: 2px auto 3px;
        height: 391px !important;
    }
    
    #calendar_header {
        display: grid;
        grid-template-columns: 1fr 4em;
        grid-template-rows: 3em 1fr;
        box-sizing: inherit;
        padding: 0;
        margin: 0;
    }
    </style>
    <style>      
    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
    #calendar-wrapper {
        width: 100%;
        display: grid;
        grid-template-rows: 2.5em 1fr;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        font-size: inherit;
  }
    .dialogNewDatepicker .v-card__actions .v-btn__content {
        color:#ffffff !important;
        text-transform: none !important;
    }
    .dialogNewDatepicker .v-card__actions .text--cancelbtn{
        border: 1px solid #B4CFE0;
    }
    .dialogNewDatepicker .v-card__actions .text--cancelbtn .v-btn__content {
        color:#0171A1 !important;
        text-transform: none !important;
    }
  
    #calendar-wrapper header {
        grid-row: 1/2;
        font-size: 2em;
        box-sizing: inherit;
    }
    #calendar-wrapper header h4 {
        padding: 0;
        margin: 0;
        font-size:17px;
    }
    #calendar {
        display: grid;
        grid-template-columns: 1fr 4em;
        grid-template-rows: 3em 1fr;
        box-sizing: inherit;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    #calendar #calendar-days{
    /* #calendar #calendar-grid { */
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        padding: 0 1em;
        box-sizing: inherit;
        margin: 0;
        height: 25px;
        position: relative;
        left: 0px;
    }
    #calendar #calendar-days {
        justify-items: center;
        grid-row: 1/2;
        grid-column: 1/2;
        margin-top:7px;
    }
    #calendar #calendar-days span {
        color:#819FB2
    }
#calendar #calendar-grid {
   display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    justify-items: flex-start;
    grid-row: 2/-1;
    grid-column: 1/2;
    width: 100%;
    padding: 0 1em;
    position: relative;
    top:-14px;
}
#calendar #calendar-grid > time {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;   
    padding: 1px 1px;
    width: 36.6px;
    /* background-color: rgba(108, 108, 108, 0.02); */
    border-radius: 3px;
    border: 2px solid transparent;
    transition: box-shadow 200ms, border 100ms;
    box-shadow: 1px 1px rgba(0, 0, 0, 0);
    box-sizing: inherit;
    height: 33px;
    margin-left:10px;
    color:#4A4A4A;
    font-family: 'Inter-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px !important;
    margin-top: 6px;
    margin-right: 10px;
}
#calendar #calendar-grid time.selected,
#calendar #calendar-grid time.selected:focus,
#calendar #calendar-grid time.selected_is_next,
#calendar #calendar-grid time.selected_is_next:focus,
#calendar #calendar-grid time.selected_is_back,
#calendar #calendar-grid time.selected_is_back:focus  {
    outline: none;
    border: 1px solid #0171A1;
    box-sizing: inherit;
    background: #F0FBFF;
    color:#0171A1;
}
#calendar #calendar-grid time.disabled,
#calendar #calendar-grid time.disabled:focus {
    outline: none;
    border: 2px solid transparent;
    box-sizing: inherit;
    cursor: not-allowed;
    /* background-color: rgba(30, 39, 51, 0.2);
    box-shadow: 1px 1px rgba(0, 0, 0, 0); */
}
#calendar #calendar-grid > *:hover {
    border: 2px solid rgba(72, 72, 72, 0.5);
    /* box-shadow: 1px 1px rgba(0, 0, 0, 0.2); */
    box-sizing: inherit;
}
#calendar #navigation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-row: 2/-1;
    grid-column: 2/-1;
    box-sizing: inherit;
    padding: 1em 0 3.5em;
    margin: 0;
}
#calendar #navigation-wrapper button {
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 3em;
    width: 1.6em;    
    box-sizing: inherit;
    padding: 0.1em;
    margin: 0;
}
#calendar #navigation-wrapper button:hover {
    opacity: 0.75;
}
#calendar #navigation-wrapper button svg {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 1.6em;
    display: block;
    box-sizing: inherit;
}
#calendar #navigation-wrapper button:first-of-type {
    transform: rotate(180deg);
}
#calendar #navigation-wrapper button[disabled] svg {
    cursor: not-allowed;
    fill: rgba(30, 39, 51, 0.2);
}
.myCalendarWrapper #centerdivheader {
  margin-bottom: 32px;
  margin-top: 9px;
  color: #4A4A4A;
  font-size:16px;
}
#centerdivheader p {
  font-size:16px;
}
.arrowleft {
  background: url("../assets/icons/arrowLeft.svg") no-repeat;
}
.arrowRight {
  background: url("../assets/icons/arrowRight.svg") no-repeat;
}
#headerCalendar  #navigation-wrapper #previous-month .arrowleft {
    width: 16px;
    height: 18px;
    display: block;
}
#headerCalendar  #navigation-wrapper #next-month .arrowRight {
    width: 16px;
    height: 18px;
    display: block;
}
time.disablePrev {
  color:#B4CFE0 !important;
  cursor:not-allowed !important;
}
time.disableNext, time.PrevEnable
{
  color:#B4CFE0 !important; 
}

.v-btn--disabled  { 
    background:grey !important;
}

.xcurrentName {
    background:rgb(1, 113, 161) !important;
    color:#fff !important;
}
</style>