<template>
    <div  class="text-center ma-2">               
        <v-snackbar 
            v-if="dialogData"          
            v-model="snackbar"            
            color="#EBFAEF"
            timeout=2000
            style="color:#16B442"
            content-class="saveclass"
            width="203px"
            min-width="350px"
            >            
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0002 0.833984C15.0628 0.833984 19.1668 4.93804 19.1668 10.0007C19.1668 15.0633 15.0628 19.1673 10.0002 19.1673C4.93755 19.1673 0.833496 15.0633 0.833496 10.0007C0.833496 4.93804 4.93755 0.833984 10.0002 0.833984ZM12.9515 7.22285L8.85433 11.32L7.0488 9.51451C6.78031 9.24603 6.34501 9.24603 6.07653 9.51451C5.80804 9.783 5.80804 10.2183 6.07653 10.4868L8.36819 12.7785C8.63668 13.0469 9.07198 13.0469 9.34046 12.7785L13.9238 8.19512C14.1923 7.92663 14.1923 7.49133 13.9238 7.22285C13.6553 6.95436 13.22 6.95436 12.9515 7.22285Z" fill="#16B442"/>
            </svg>&nbsp;Container has been successfully added.
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name: 'containersave',
    data(){
      return {
        snackbar:true, 
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData'], 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.saveclass {
    color:#16B442;
    display: flex;
}
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}
.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}
.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}
.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>