<template>
    <div class="charges">       
        <v-row class="topheaderboxes" no-gutters>
            <div 
                v-for="(items,index) in cards"
                :key="index"
                class="col-md-2 mt-4 parentsize"             
            >            
                <div
                    class="topbox col-md-4 col-lg-4"                  
                    >                   
                    <div>
                        <div class="mb-4 header_title">
                        {{items.header}}
                        </div>
                        <div class="mb-1 boxtext">
                            {{items.value}}
                        </div>        
                    </div>   
                  
                </div> 
            </div>
        </v-row>
                  
        <v-row class="charges-invoice-bill">
            <div  class="col-md-6 float-md-left invoiceColumn">
                <v-row>
                        <v-col>
                        <v-card
                        class="pa-2 cardInvoice"                       
                        tile
                        elevation="0"
                        >
                        <span class="tablheadertext">Invoices</span>
                        </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="addInvoiceColumn">
                        <v-card
                        class="pr-0 pt-2"
                        
                        tile
                        elevation="0"
                        >
                        <v-btn @click="opendialog(1)" class="btn-white AddInvoiceBtn" elevation="0">
                            Add Invoice
                        </v-btn>
                        </v-card>
                    </v-col>
                </v-row>

                <v-data-table
                     class="invoiceThead"
                    :headers="headers"
                    :items="desserts"
                    hide-default-footer
                    disable-pagination             
                ></v-data-table>

            </div>
            <v-col md="5" col="5" class="ml-md-auto billColumn">
                <v-row>
                        <v-col>
                        <v-card
                        class="pa-2"
                        elevation="0"
                        tile
                        >
                            <span class="tablheadertext">Bills</span>
                        </v-card>
                        </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="addBillColumn ">
                        <v-card
                        class="pa-2"
                        elevation="0"
                        tile
                        >
                            <v-btn @click="opendialog(2)" class="btn-white AddInvoiceBtn" elevation="0">
                            Add Bill
                        </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
                 <v-data-table
                  class="invoiceThead billTable"
                    :headers="billheaders"
                    :items="billdata"
                    hide-default-footer
                    loading-text="true"
                    disable-pagination   
                              
                ></v-data-table>
            </v-col>
           </v-row>        
            <v-row no-gutters class="">
                <v-col class="footer" cols="12" md="12">
                    <v-btn  class="btn-blue"
                    depressed
                    @click="clicsubmit('save')"
                    color="primary">
                    
                    Save
                    </v-btn>
                        <v-btn class="btn-white saveAndAnother"
                    depressed
                        @click="clicsubmit('saveAndAnother')"
                    color="primary">
                    Save & Add Another
                    </v-btn>

                        <v-btn class="btn-white createCancelBtn"
                    depressed
                    @click="cancel"
                    color="primary">
                    Cancel
                    </v-btn>
                </v-col>
            </v-row>
       
        <div class="text-center">
            <v-dialog
            v-model="dialog"
            width="80%"
            height="100vh"
            style="overflow:hidden"
            >
              <v-card >
                <v-card-title class="text-h55">
                   {{ addInvoicemodal ? "Add Invoice" : 'Add Bill'}}
                </v-card-title>
                
                 <div  style=" overflow:hidden;" height="100%">
                   <v-form @submit.prevent="submit" v-model="valid" id="sform" ref="form"> 
                       <v-row no-gutters v-if="addInvoicemodal">
                           <div class="col-md-3 col-lg-3 col-xl-3 leftColumn">
                                <small>INVOICE #</small>
                                 <v-text-field
                                  placeholder="Enter Invoice Number" 
                                  name="po_num" 
                                  :rules="invoice_number"   
                                  hide-details="auto"
                                  v-model="invoiceModelSelected"                                
                                ></v-text-field>

                                <small>INVOICE DATE</small>
                                <v-menu
                                v-model="modal"
                                :close-on-content-click="true"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"                                  
                                     append-icon="ic-calendar"
                                    readonly
                                    class="datefield"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>

                               <!-- <small>INVOICE DATE</small>
                                <v-dialog
                                    ref="dialog"
                                    v-model="modal"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px">
                                     <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            placeholder="05/21/2021"                                  
                                            append-icon="ic-calendar"
                                            readonly
                                            name="ETA"
                                            class="datefield"
                                            v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template> 
                                    <v-date-picker
                                    v-model="date"
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="modal = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>  
                                -->

                                <small>DUE DATE</small>
                                <!--<v-dialog
                                    ref="dialog_due_date"
                                    v-model="modal_due_date"
                                    :return-value.sync="date_sync_due"
                                    persistent
                                    width="290px">
                                     <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_sync_due"
                                            placeholder="05/21/2021"                                  
                                            append-icon="ic-calendar"
                                            readonly
                                            name="ETA"
                                            class="datefield"
                                            v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template> 
                                    <v-date-picker
                                    v-model="date_sync_due"
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="modal_due_date = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog_due_date.save(date_sync_due)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog> --> 

                                <v-menu
                                v-model="modal_due_date"
                                :close-on-content-click="true"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date_sync_due"                                  
                                     append-icon="ic-calendar"
                                    readonly
                                    class="datefield"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>


                                <small>CUSTOMER</small>                           
                                <v-combobox                                                        
                                :items="itemsDropdown"  
                                item-text= 'name'
                                v-model="customerSelected"
                                item-value='id' 
                                :rules="custumerRules" 
                                class=""                              
                                placeholder="Select Customer" 
                                solo 
                                hide-details="auto"                                                   
                                append-icon="ic-chevron-down"                               
                                ></v-combobox>   
                              

                                <small>CUSTOMER EMAIL</small>
                                 <v-text-field
                                 placeholder="Enter Email Address" 
                                 name="po_num" 
                                 :rules="customerEmail"                                  
                                 hide-details="auto"
                                 ></v-text-field>
                                
                                <small>BILLING ADDRESS</small> 
                                <v-textarea 
                                :rules="billing_address"
                                placeholder="Type Full Address"
                                rows="2"
                                name="Address"
                                class="BillingAddress" 
                                hide-details="auto"
                                ></v-textarea> 

                                <small>TERMS</small>                           
                                <v-combobox                                  
                                item-text= 'name'
                                item-value='id'                               
                                placeholder="Select Customer" 
                                solo      
                                class=""  
                                :rules="terms"                         
                                hide-details="auto"                      
                                append-icon="ic-chevron-down"                               
                                ></v-combobox>   
                              
                           </div>
                            <v-col class="col-md-9 rightColumnInvoice">  
                                <table cellpadding="5" cellspacing="0" class="tableInvoiceservice">
                               <thead>
                                   <tr>
                                        <th style="width:28%">
                                           SERVICE
                                       </th>
                                       <th style="width:13%; text-align:right">
                                           QUANTITY
                                       </th>
                                        <th style="width:12%; text-align:right">
                                           UNIT PRICE
                                       </th>
                                        <th style="width:10%; text-align:right">
                                          AMOUNT
                                       </th>                                                                           
                                   </tr>
                               </thead>
                                <tbody>
                                    <tr v-for="(item,i) in tablerow"  :key='item.id'>                                       
                                         <td>                                             
                                              <v-combobox                                                  
                                                item-text= 'name'
                                                item-value='id'    
                                                :items="services"                           
                                                placeholder="Select Service" 
                                                solo 
                                                hide-details="auto" 
                                                :rules="servicesRules"
                                                class="selectService"                     
                                                append-icon="ic-chevron-down"                               
                                                ></v-combobox> 
                                        </td>
                                        <td>
                                             <v-text-field
                                                placeholder="00"
                                                v-model="item.serviceQTY"                                  
                                                @keyup="computeservice"
                                                :rules="qtyRules"
                                                class="text_align_right"                                           
                                                name="trucker_container"
                                                hide-details="auto"                                                
                                            ></v-text-field>  
                                        </td>
                                        <td> 
                                             <v-text-field
                                                placeholder="$ 00"                                  
                                                :rules="unitRules"
                                                @keyup="computeservice"
                                                v-model="item.servicePrice" 
                                                class="text_align_right"    
                                                name="trucker_container"
                                                hide-details="auto"                                                 
                                            ></v-text-field>                                            
                                        </td>
                                        <td class="amountTD">
                                            <span class="amount">$ {{ callamount(item) }}</span>
                                            <span v-if="tablerow.length > 1" @click="removeLine(i)" class="close"> <i class="ic-close"></i></span>
                                        </td>                                       
                                       
                                    </tr>
                                     <tr>                                        
                                        <td class="addservice_row" colspan="5">
                                            <div class="addService">                                               
                                                <span  @click="addservices" class="btn-white smallBtnwhite"> <i class="ic-plus"></i> Add Service</span>
                                            </div>
                                        </td>                                        
                                    </tr>
                                </tbody>
                                <tfoot class="tfoot">
                                     <tr>
                                    <td colspan="3">
                                        <span class="footerLine subTotalText">Sub Total</span>
                                    </td>
                                    <td>
                                      <span class="footerAmount">  $ {{subTotal}} </span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                            <span class="footerLine"> Tax</span>
                                    </td>
                                    <td>
                                      <span class="footerAmount">  0.00</span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                          <span class="footerLine"> Discount</span>
                                    </td>
                                    <td>
                                       <span class="footerAmount"> 0.00</span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                        <span class="grandTotaltext">Total</span>
                                    </td>
                                    <td>
                                    <span class="grandTotaltext">$ {{grandTotal}}</span>
                                    </td>
                               </tr>
                                </tfoot>
                           </table> 

                           <div class="mt-5">
                               <small>NOTE <span>(Optional)</span></small>
                            <v-textarea 
                                    v-model="noteInvoice"                                   
                                    placeholder="Note"
                                    rows="2"     
                                    :rules="noteRules"                             
                                    name="custom_notes"
                                    hide-details="auto"
                                    ></v-textarea>   
                           </div> 

                           <div class="">
                                <small>ATTACHMENTS <span>(Optional)</span> Accept PDF Only</small><span style="color:red;font-size:12px;margin-left:5px" v-if="errorFile">Invalid File Type</span>
                                <div style="display:flex" class="uploadElement flex w-full h-screen items-left justify-left text-left" id="app">
                                <div style="width:32%" class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                                    <input style="display:none" type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                                    class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept="application/pdf" />
                                
                                    <label for="assetsFieldHandle" class="block cursor-pointer">
                                    <div class="browserInput dragfileElement">
                                         <span class="browseFile">Browse Or Drop File</span>
                                         <span class="btn-white2 browseButton"><i class="ic-upload"></i> Upload</span> 
                                    </div>
                                    </label>                                   
                                </div>
                                 <div style="width:60%">
                                        <ul class="mt-4 displayItem" v-if="this.filelist.length" v-cloak>
                                    <li class="text-sm p-1" v-for="(file,index) in filelist "   :key="index">
                                        {{file.name}}<button class="ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file"><i class="ic-close"></i> </button>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                               
                           </div>
                           </v-col>
                       </v-row>
                   </v-form>  






                       <!--  billing -->
                    <v-form @submit.prevent="submit" v-model="valid" id="sform" ref="form2">
                       <v-row no-gutters v-if="!addInvoicemodal">
                           <div class="col-md-3 col-lg-3 col-xl-3 leftColumn">
                                <small>BILL #</small>
                                 <v-text-field
                                  placeholder="Enter Bill Number" 
                                  name="bill_num" 
                                  :rules="bill_number"   
                                  hide-details="auto"
                                  v-model="billNumberModelSelected"                                
                                ></v-text-field>

                                <small>BILL DATE</small>
                                <v-dialog
                                    ref="dialog"
                                    v-model="modalBill"
                                    :return-value.sync="date"
                                    persistent
                                    width="290px">
                                     <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            placeholder="05/21/2021"                                  
                                            append-icon="ic-calendar"
                                            readonly
                                            name="ETA"
                                            class="datefield"
                                            v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template> 
                                    <v-date-picker
                                    v-model="date"
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="modal = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>  

                              

                                <small>DUE DATE</small>
                                <v-dialog
                                    ref="dialog_due_date"
                                    v-model="modal_due_date"
                                    :return-value.sync="date_sync_due"
                                    persistent
                                    width="290px">
                                     <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_sync_due"
                                            placeholder="05/21/2021"                                  
                                            append-icon="ic-calendar"
                                            readonly
                                            name="ETA"
                                            class="datefield"
                                            v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template> 
                                    <v-date-picker
                                    v-model="date_sync_due"
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="modal_due_date = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog_due_date.save(date_sync_due)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>  

                                <small>CUSTOMER</small>                           
                                <v-combobox                                                        
                                :items="itemsDropdown"  
                                item-text= 'name'
                                v-model="customerSelected"
                                item-value='id' 
                                :rules="custumerRules" 
                                class=""                              
                                placeholder="Select Customer" 
                                solo 
                                hide-details="auto"                                                   
                                append-icon="ic-chevron-down"                               
                                ></v-combobox>   
                              

                                <small>CUSTOMER EMAIL</small>
                                 <v-text-field
                                 placeholder="Enter Email Address" 
                                 name="po_num" 
                                 :rules="customerEmail"                                  
                                 hide-details="auto"
                                 ></v-text-field>
                                
                                <small>BILLING ADDRESS</small> 
                                <v-textarea 
                                :rules="billing_address"
                                placeholder="Type Full Address"
                                rows="2"
                                name="Address"
                                class="BillingAddress" 
                                hide-details="auto"
                                ></v-textarea> 

                                <small>TERMS</small>                           
                                <v-combobox                                  
                                item-text= 'name'
                                item-value='id'                               
                                placeholder="Select Customer" 
                                solo      
                                class=""  
                                :rules="terms"                         
                                hide-details="auto"                      
                                append-icon="ic-chevron-down"                               
                                ></v-combobox>   
                              
                           </div>
                            <v-col class="col-md-9 rightColumnInvoice">  
                                <table WITH="100%" cellpadding="5" cellspacing="0" class="tableInvoiceservice">
                               <thead>
                                   <tr>
                                        <th style="width:28%">
                                           CATEGORY
                                       </th>
                                       <th style="width:13%; text-align:right">
                                           QUANTITY
                                       </th>
                                        <th style="width:12%; text-align:right">
                                           UNIT PRICE
                                       </th>
                                        <th style="width:10%; text-align:right">
                                          AMOUNT
                                       </th>                                                                           
                                   </tr>
                               </thead>
                                <tbody>
                                    <tr v-for="(item,i) in tablerow"  :key='item.id'>                                       
                                         <td>                                             
                                              <v-combobox                                                  
                                                item-text= 'name'
                                                item-value='id'    
                                                :items="category"                           
                                                placeholder="Select Category" 
                                                solo 
                                                hide-details="auto" 
                                                :rules="categoryRules"
                                                class="selectService"                     
                                                append-icon="ic-chevron-down"                               
                                                ></v-combobox> 
                                        </td>
                                        <td>
                                             <v-text-field
                                                placeholder="00"
                                                v-model="item.serviceQTY"                                  
                                                @keyup="computeservice"
                                                :rules="qtyRules"
                                                class="text_align_right"                                           
                                                name="trucker_container"
                                                hide-details="auto"                                                
                                            ></v-text-field>  
                                        </td>
                                        <td> 
                                             <v-text-field
                                                placeholder="$ 00"                                  
                                                :rules="unitRules"
                                                @keyup="computeservice"
                                                v-model="item.servicePrice" 
                                                class="text_align_right"    
                                                name="trucker_container"
                                                hide-details="auto"                                                 
                                            ></v-text-field>                                            
                                        </td>
                                        <td class="amountTD">
                                            <span class="amount">$ {{ callamount(item) }}</span>
                                            <span v-if="tablerow.length > 1" @click="removeLine(i)" class="close"> <i class="ic-close"></i></span>
                                        </td>                                       
                                       
                                    </tr>
                                     <tr>                                        
                                        <td class="addservice_row" colspan="5">
                                            <div class="addService">                                               
                                                <span  @click="addservices" class="btn-white smallBtnwhite"> <i class="ic-plus"></i> Add Item</span>
                                            </div>
                                        </td>                                        
                                    </tr>
                                </tbody>
                                <tfoot class="tfoot">
                                     <tr>
                                    <td colspan="3">
                                        <span class="footerLine subTotalText">Sub Total</span>
                                    </td>
                                    <td>
                                      <span class="footerAmount">  $ {{subTotal}} </span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                            <span class="footerLine"> Tax</span>
                                    </td>
                                    <td>
                                      <span class="footerAmount">  0.00</span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                          <span class="footerLine"> Discount</span>
                                    </td>
                                    <td>
                                       <span class="footerAmount"> 0.00</span>
                                    </td>
                               </tr>
                                <tr>
                                    <td colspan="3">
                                        <span class="grandTotaltext">Total</span>
                                    </td>
                                    <td>
                                    <span class="grandTotaltext">$ {{grandTotal}}</span>
                                    </td>
                               </tr>
                                </tfoot>
                           </table> 
                                                     
                           </v-col>
                       </v-row>
                       <v-row class="footer-action">
                           <v-col class="col-md-12 headertopborder">                             
                                <button class="btn-blue" style="position: relative;top: -1px;" 
                                color="primary">
                                   Save
                                </button>
                                <button class="btn-white">
                                   Save & Add Another
                                </button>
                                <v-btn style="position: relative;top: -2px;"  class="btn-white" @click="hideme">
                                   Cancel
                                </v-btn>
                           </v-col>
                       </v-row>
                   </v-form>
                </div>
                </v-card>
            </v-dialog>
         </div>          
    </div>
</template>
<script>
//import axios from "axios";

export default {
    name: "charges_main",  
    props: ['items','mode'],
    components: {           
    },   
    delimiters: ['${', '}'], // Avoid Twig conflicts
    data: () => ({
        menu2: false,
        billNumberModelSelected: '',
        bill_number:[
             v => !!v || 'Bill Number is required',
             v => !/[^a-zA-Z0-9]/.test( v ) || 'Bill Number should be digits.'
        ],       
        addInvoicemodal : false,       
        errorFile: false,
        allFieldsValid: true,
        invoiceModelSelected: '',
        valid: true,
        customerSelected: '',
        unitRules:[
            v => !!v || 'Unit Price is required',
            v => /^-{0,1}\d*\.{0,1}\d+$/.test(v)  || 'Should be in correct value.',  
        ],
        qtyRules:  [
            v => !!v || 'Quantity is required',
            v => !/[^0-9]/.test( v ) || 'Quantity should be digits.'],     
        noteRules:  [
             v =>  !v ? true : v.length < 200 || 'Should not exceeds to 200 allowed characters',      
        ],
        custumerRules:[
            v => !!v || 'Customer is required',  
        ],
        servicesRules:[
            v => !!v || 'Services is required',  
        ],
        terms:[
            v => !!v || 'Terms is required',  
        ],
        invoice_number: [
            v => !!v || 'Invoice # is required',   
        ],
        customerEmail: [
            v => !!v || 'E-mail is required',   
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        billing_address:[
            v => !!v || 'Billing Address is required',  
        ],
        filelist:[],
        noteInvoice:"",
        billingAddress:'',
        datadropdown:[],
        itemsDropdown:[],
        TERMINAL_AT:[],
        containerSize:[],
        containerType:[],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_sync_due:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        tablerow:[{service:'',serviceQTY:233,servicePrice:450.00,amount:0},
        ],
        services:[  
                 {name:'service 1'},
                 {name:'service 2'},
                 {name:'service 3'},
                 {name:'service 4'},
                 {name:'service 5'},
                 {name:'service 6'},
                 {name:'service 7'},
                 {name:'service 8'},
                 {name:'service 9'},
                 {name:'service 10'},
                ],
        categoryRules: '',
        category:[  
                 {name:'Category 1'},
                 {name:'Category 2'},
                 {name:'Category 3'},
                 {name:'Category 4'},
                 {name:'Category 5'},
                 {name:'Category 6'},
                 {name:'Category 7'},
                 {name:'Category 8'},
                 {name:'Category 9'},
                 {name:'Category 10'},
                ],
        subTotal:0,
        tax:0,
        discount:0,
        grandTotal:0,
        inputTrigger:0,
        changeTrigger: false,
        modal: false,
        modalBill:false,
        modal_due_date: false,
        dialog: false,
          cards3: [{header:'Income',value:500.50},
                {header:'Total Bill',value:400.50},
              ],  

        cards: [{header:'Income',value:'$500.50'},
                {header:'Total Bill',value:'$400.50'},
                {header:'Profit',value:'$100.50'}],  
        tabscurrent:2,  
        tab: 2,
        tabsNavigation: [
          'Header Info', 'Dispatch', 'Charges',
        ],
    }),  
     watch: {    
         dialog(v){
            if(!v){
                this.$refs.form.resetValidation()
                this.$refs.form.reset()
            }
         },     
         inputTrigger(){
            this.callTrigger(this.tablerow);
         }, 
         tablerow(dta){
            this.callTrigger(dta);           
         },        
       datadropdown: function(newVal){                         
          this.getPrelims(newVal)            
       }
	}, 
    computed: { 
       
        headers_services(){
          return   [
            {
                text: "SERVICE",
                align: "left",
                sortable: false,
                value: "DATE",
                width: "45%", 
                fixed: true
            },
            {
                text: "QUANTITY",
                align: "start",
                sortable: false,
                value: "INVOICE",
                width: "20%", 
                fixed: true
            },
            {
                text: "UNIT PRICE",
                align: "start",
                sortable: false,
                value: "DUE_DATE",
                width: "20%", 
                fixed: true
            },
            {
                text: "AMOUNT",
                align: "start",
                sortable: false,
                value: "TOTAL",
                width: "20%", 
                fixed: true
            },       
        ]
        },
         headers(){
          return   [
            {
                text: "DATE",
                align: "left",
                sortable: false,
                value: "DATE",
                width: "8%", 
                fixed: true
            },
            {
                text: "INVOICE#",
                align: "start",
                sortable: false,
                value: "INVOICE",
                width: "13%", 
                fixed: true
            },
            {
                text: "DUE DATE",
                align: "start",
                sortable: false,
                value: "DUE_DATE",
                width: "12%", 
                fixed: true
            },
            {
                text: "TOTAL",
                align: "start",
                sortable: false,
                value: "TOTAL",
                width: "12%", 
                fixed: true
            },
            {
                text: "STATUS",
                align: "start",
                sortable: false,
                value: "STATUS",
                width: "25%", 
                fixed: true
            },
                     
        ]
        },
         desserts(){
         return [
          {
            DATE: '05/21/2020',
            INVOICE: 706721,
            DUE_DATE: '05/21/2020',
            TOTAL: '$500.50',
            STATUS: 'Paid',           
          },
             
        ]},
         billheaders(){
          return   [
            {
                text: "DATE",
                align: "left",
                sortable: false,
                value: "DATE",
                width: "8%", 
                fixed: true
            },
            {
                text: "BILL",
                align: "start",
                sortable: false,
                value: "INVOICE",
                width: "13%", 
                fixed: true
            },
            {
                text: "DUE DATE",
                align: "start",
                sortable: false,
                value: "DUE_DATE",
                width: "12%", 
                fixed: true
            },
            {
                text: "AMOUNT",
                align: "start",
                sortable: false,
                value: "TOTAL",
                width: "12%", 
                fixed: true
            },
          
                     
        ]
        },
         billdata(){
         return [
          {
            DATE: '05/21/2020',
            INVOICE: 706721,
            DUE_DATE: '05/21/2020',
            TOTAL: '$500.50',
                  
          },
             
        ]}
    },
    created(){       
       
    },
    methods: { 
    callamount(item)
    {
        const qty = item.serviceQTY =='' ? 0 : item.serviceQTY;
        const servicePrice = item.servicePrice =='' ? 0 : item.servicePrice; 
       
        var answer=  parseInt(qty) * parseInt(servicePrice);
        if(isNaN(answer))
        {        
           return 0;
        } 
        return answer;
    },
    submit(){
        if(this.addInvoicemodal){
            if(this.$refs.form.validate() && this.allFieldsValid == true)
            {
                alert('submit now')            
            }
        }else {
            if(this.$refs.form2.validate() )
            {
                alert('submit now2')            
            }
        }
    },    
    submitnow(){              
        
    },  
    onChange() {
      this.filelist = [...this.$refs.file.files];
      let table = this.filelist;
      let errorCount =0;
        Object.keys(table)
        .forEach(function eachKey(key){           
            if(table[key].type !='application/pdf')
            {
               errorCount++;
            }
        });        
        if(errorCount> 0) {
           this.errorFile =1
           this.allFieldsValid = false;
        }  else{
           this.errorFile =0
           this.allFieldsValid = true;
        }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      if( this.filelist.length == 0){
          this.errorFile = 0
          this.allFieldsValid = true;
      }
    },
    dragover(event) {
      event.preventDefault();    
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {    
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();    
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
      },
        hideme(){
            this.dialog = 0;
        },
        computeservice(){ 
            this.inputTrigger++;
        },
        /*dragover(event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
        }
        },
        */
         linetotal(item){  
            return  parseInt(item.serviceQTY) * parseInt(item.servicePrice)
        },
        /*drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
        },*/
          /*  hideme(){
                this.dialog = 0;
            },*/
           /* computeservice(){           
                //this.tablerow = this.tablerow
                this.inputTrigger++;
            },*/
            removeLine(lineID){
                this.tablerow.splice(lineID,1);
            },
           /* linetotal(item){            
            // this.subTotal = parseInt(item.serviceQTY) * parseInt(item.servicePrice)
                return  parseInt(item.serviceQTY) * parseInt(item.servicePrice)
            },*/
            addservices(){
                this.tablerow.push({service:'',serviceQTY:10,servicePrice:20,amount:0})                     

        },        
         opendialog(n){
             if(n==1){
                 this.addInvoicemodal = 1;
             }    
             
             if(n==2){
                 this.addInvoicemodal = 0;
             }
             this.dialog = true;   
                            
         },
         cancel() {
             this.$router.push('/containers')    
        },
        callTrigger(data){
            var table = data;           
            var alltable = 0;
             Object.keys(table)
            .forEach(function eachKey(key) {  
              alltable += parseInt(table[key].serviceQTY) * parseInt(table[key].servicePrice);
            });
            this.subTotal = alltable ;
            this.grandTotal = alltable - ( this.discount + this.tax ) ;
        },
        getPrelims: function(data) { 
          let response = data;       
         
        let customers = (response.data.customers);
        let DataCustomer=[];
            customers.forEach((value, key) => {                                  
            DataCustomer.push({id:key,name:value.company_name});
        });

            this.itemsDropdown = DataCustomer;

            let terminal_at = (response.data.terminal);
                terminal_at.forEach((value, key) => {                                  
                this.TERMINAL_AT.push({id:key,name:value.name});
            });

            let size = (response.data.size);
                size.forEach((value, key) => {                                  
                this.containerSize.push({id:key,name:value.name});
            });

            let containertype = (response.data.type);
                containertype.forEach((value, key) => {                                  
                this.containerType.push({id:key,name:value.name});
            });

            let truckersID = (response.data.trucker);
                truckersID.forEach((value, key) => {                                  
                this.truckers.push({id:key,name:value.name});
            });                
            this.startloading = 0; 
            },
            tabsnavigationClick(current){
                if(current==0){
                    this.$router.push('/container/add/new') 
                } else if(current==1){
                    this.$router.push('/containers/dispatch') 
                } else if(current==2){
                    this.$router.push('/containers/charges') 
                }
                this.tabscurrent = current;
            },
        },     
        mounted(){    
            this.inputTrigger = 1; 
            this.$store.dispatch("page/setPage", "containers");          
        }
};
</script>

<style>
    .charges {
        background: #fff;
    }
    table thead th span {font-size: 12px !important;}
    table thead {
        background-color: #F7F7F7;
    }

    .leftColumn {
        background: #F7F7F7;
        padding: 19px !important;
        padding-top: 9px !important;
        zoom: 100%;
    }

    form {
         zoom: 100%;
    }

    .leftColumn input {
        background: #fff;
    }
</style>