<template>
    <v-dialog 
        persistent 
        v-model="dialog" 
        max-width="480px" 
        content-class="delete-driver-dialog dialogYard"  
        :retain-focus="false"
        >
        <v-card 
          style="padding-bottom:8px">
          <v-card-text 
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >            
            <svg               
                width="44" 
                height="44" 
                viewBox="0 0 44 44" 
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M21.9999 0.400391C33.9292 0.400391 43.5999 10.071 43.5999 22.0004C43.5999 33.9297 33.9292 43.6004 21.9999 43.6004C10.0706 43.6004 0.399902 33.9297 0.399902 22.0004C0.399902 10.071 10.0706 0.400391 21.9999 0.400391ZM21.9999 3.63989C11.8597 3.63989 3.6394 11.8602 3.6394 22.0004C3.6394 32.1406 11.8597 40.3609 21.9999 40.3609C32.1401 40.3609 40.3604 32.1406 40.3604 22.0004C40.3604 11.8602 32.1401 3.63989 21.9999 3.63989ZM21.992 18.7588C22.812 18.7583 23.4901 19.3672 23.5979 20.1577L23.6128 20.3775L23.6206 32.2591C23.6212 33.1537 22.8965 33.8793 22.0019 33.8799C21.1819 33.8804 20.5038 33.2715 20.396 32.481L20.3811 32.2612L20.3733 20.3796C20.3728 19.4851 21.0975 18.7594 21.992 18.7588ZM22.0009 11.2048C23.192 11.2048 24.1576 12.1704 24.1576 13.3616C24.1576 14.5527 23.192 15.5184 22.0009 15.5184C20.8097 15.5184 19.8441 14.5527 19.8441 13.3616C19.8441 12.1704 20.8097 11.2048 22.0009 11.2048Z" fill="#0171A1"/>
            </svg>


            <h2 
              class="delivery_header"
              style="">Accepted Delivery Order</h2>
            <p 
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                   DO #{{item.shifl_ref}} has been accepted.
            </p>
            
          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="continueDialog" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Close
                    </span>
                </span>
            </v-btn>      
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Shownotification',
    data(){
      return {
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData','closeDialog','item'], 
    computed: { 
        dialog: {
            get () {               
              return this.dialogData
            } ,
            set(value) {
                if (!value) {
                    this.$emit("update:dialogData",false);
                } else {
                    this.$emit("update:dialogData",true);
                }
            },       
        },                       
    },
    methods: {      
        continueDialog() {             
          this.$emit("update:closeDialog",true); 
        },              
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>