<template>
    <div class="pagination-wrapper pt-3">
        <v-pagination
            v-model="page"           
            :length="pageCount"
            prev-icon="Previous"           
            next-icon='mdi-chevron-right'         
            :total-visible="!isMobile ? '11' : '5' ">
        </v-pagination>
    </div>    
</template>

<script>
import jQuery from 'jquery'
export default {
    props: ['pageData', 'lengthData', 'isMobile'],
	name: 'Pagination',
	components: {
		// vueDropzone: vueDropzone
	},
	data: () => ({
        
	}),
	computed: {
        page: {
            get() {
                
                return this.pageData
            },
            set (value) {
                this.$emit('update:pageData', value)
                if (this.$store.state.page.currentPage == 'shipments') {
                    this.$store.dispatch("page/setCurrentShipmentPage", value)
                }
            }
        },
        pageCount: {
            get() {
                return this.lengthData
            },
            set (value) {
                this.$emit('update:lengthData', value)
            }
        }
    },        
	watch: {

    },        
	created () {
        
	},
	methods: {

	},
	mounted(){       
       jQuery(document).find(".v-pagination li:first-child button i").addClass('firstNavi mdi mdi-chevron-left');
       jQuery(document).find(".v-pagination li:last-child button i").addClass('lastNavi');
       jQuery( "<span style='font-size:14px;color:#0171A1' class='nextArrow'>Next</span>" ).insertBefore( ".v-pagination li:last-child button i" );
    },
	updated() {
        
	}
}
</script>

<style>
.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    padding: 15px 24px;
    align-content: center; 
    border: 1px solid #d2e3ed;
    /* border-top: none; */
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    zoom: 0.95 !important;
}

.pagination-wrapper .v-pagination li button {
    width: 100%;
    /* font-size: 14px; */
    font-size: 16px;
    box-shadow: none;
}

.pagination-wrapper .v-pagination li button:focus {
    outline: 0 !important;
}

.pagination-wrapper .v-pagination li button i {
    /* font-size: 14px; */
    font-size: 14px !important;
    font-style: normal;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 500;
}

.pagination-wrapper .v-pagination__navigation i {
    color: #0171a1 !important;
}

.pagination-wrapper .v-pagination__navigation--disabled i {
    color: #B4CFE0 !important;
}

.v-pagination__item {
    color: #0B6085 !important;
    outline: none !important;
    font-size: 14px !important;
}

.theme--light.v-pagination .v-pagination__item--active {
    color: #14262E !important;
    background-color: transparent !important;
    border: none;   
    outline: none !important;
    font-weight: bold;
}

.theme--light.v-pagination .v-pagination__item--active:focus {
    border: none !important;
    outline: none !important;
}
.v-main ul.v-pagination li:first-child button  i.ic-chevron-left::before {
    position:relative;
    top:0px;
}
.theme--light.v-pagination .v-pagination__item {
    padding-top: 0px !important;
}

.v-pagination__navigation--disabled .prev-control {
    font-size: 14px;
    font-family: "Inter-Medium", sans-serif !important;
    color:#B4CFE0
}

.pagination-wrapper :not(.v-pagination__navigation--disabled) .prev-control,
.pagination-wrapper :not(.v-pagination__navigation--disabled) .next-control{ 
    font-size: 14px !important;
    font-family: "Inter-Medium", sans-serif !important;
    color:#0171A1 !important
}

.theme--light.v-pagination .v-pagination__item--active{
    font-size: 14px !important;
    color:#14262E !important;
     font-family: "Inter-Regular", sans-serif !important;
}

/* li:first-child button.v-pagination__navigation i::before{
  content: '\2039';
  font-size: 26px;
  position: relative;
  top: -1.6px;
  left:-5px;
} */

/* li:last-child button.v-pagination__navigation i::after{
  content: '\203A' !important;
  font-size: 20px;
  position: relative;
  top: -1.5px;
  opacity: 1;
  background: none;
  left: 7px;
} */

.yard-container .pagination-wrapper ul {
    position: relative !important;
    top:-6px
}

.nextArrow {
    font-size: 14px !important;
    font-style: normal;
    font-family: "Inter-Medium", sans-serif;
    font-weight: 500;
}

.mdi-chevron-right::before,
.mdi-chevron-left::before{
    font-size: 21px;
}
</style>
