<template>
    <v-dialog persistent scrollable v-model="openDialog" max-width="1000px" content-class="add-customer-dialog set-reporting-sched" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">            
            <v-card-title>
                <span class="headline">Set Reporting Schedule</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text class="action-border" style="padding: unset;">
                <v-form ref="form" id="updateCustomer" v-model="valid" action="#" @submit.prevent="">
                    <v-row no-gutters style="height: 284px;">
                        <v-col
                            cols="12"
                            sm="4"
                            class="ml-6 mt-3"
                        >
                            <label class="head-text">Recipients</label>
                            <div>
                                <div v-for="(items, i) in selected" :key="i" class="mb-1 mt-1 row">
                                    <div class="col-sm-9">
                                        <div class="mb-2">
                                            <label class="general-texts">{{ items.name }}</label>
                                        </div>
                                        <div class="row mt-1 pl-3" v-if="getEmailAdd(items.emailsaddress) !== '--'">
                                            <span class="email-tags">{{ getEmailAdd(items.emailsaddress) }}</span>
                                            <span v-show="getEmailsLen(items.emails) > 0" class="ml-1 email-tags">
                                                +{{ getEmailsLen(items.emails) }} more
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 text-end">
                                        <span @click="removeSelected(i)" class="text-d-danger srs-remove-btn pr-4">
                                            <v-icon color="#F93131" size="20px">mdi-close</v-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-spacer class="srsd-spacer"></v-spacer>
                        <v-col
                            cols="12"
                            sm="7"
                            class="ml-6"
                        >
                            <v-radio-group
                                v-model="formfields.reporting"
                                v-on:input="$emit('input', $event)"
                            >
                                <v-radio
                                    class="general-texts"
                                    label="Turn off for all"
                                    value="0"
                                    :ripple="false"
                                >
                                    <label class="general-texts" slot="label">Turn off for all</label>
                                </v-radio>
                                <v-radio
                                    class="general-texts"
                                    label="Turn on for all"
                                    value="1"
                                    :ripple="false"
                                >
                                    <label class="general-texts" slot="label">Turn on for all</label>
                                </v-radio>
                            </v-radio-group>

                            <div v-if="formfields.reporting === '1'" class="bg-on-reporting ml-1">
                                <div class="row">
                                    <div class="col-sm-4 pr-0">
                                        <label for="" class="text-item-label">Frequency</label>
                                        <v-autocomplete
                                            ref="chassisDrop"
                                            v-model="formfields.frequency"
                                            :items="frequencies"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="Frequency"
                                            solo
                                            append-icon="ic-chevron-down"
                                            >
                                            <div slot="no-data"></div>
                                        </v-autocomplete>
                                    </div>
                                    <div class="col-sm-4 pr-0">
                                        <label for="" class="text-item-label">Day</label>
                                        <v-autocomplete
                                            ref="chassisDrop"
                                            v-model="formfields.day"
                                            :items="isMonthly ? monthlyDays : days"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="Day"
                                            solo
                                            append-icon="ic-chevron-down"
                                            >
                                            <div slot="no-data"></div>
                                            <div slot="item" slot-scope="{ item }">
                                                <span v-show="isMonthly">Day</span> {{ item }}
                                            </div>
                                        </v-autocomplete>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="" class="text-item-label">Time</label>
                                        <v-autocomplete
                                            ref="chassisDrop"
                                            v-model="formfields.time"
                                            :items="timeslots"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="HH:MM"
                                            solo
                                            append-icon="ic-clock"
                                            >
                                            <div slot="no-data"></div>
                                        </v-autocomplete>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: -37px;">
                                    <div class="col-sm-6 ml-2">
                                        <div class="row">
                                            <input
                                                @change="handleCheck()"
                                                class="empty-checkbox my-checkbox"
                                                type="checkbox"
                                                :checked="formfields.send_me_copy"
                                                style="margin-top: 7px;"
                                            />
                                            <span class="checkbox-label mt-1">Send me a copy</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="d-flex justify-start align-center buttonActions" style="padding: unset">
                    <v-btn class="btn-blue"
                        @click="savesetting"
                        text>
                        <span>
                            <span style="font-size: 14px !important;">
                                    <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                >
                                </v-progress-circular>
                                Save
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                        Cancel
                    </v-btn>
                </div>
            </v-card-actions>            
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import globalMethods from '../../utils/globalMethods'
import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'SetReportingScheduleDialog',
    props: ['openDialog', 'selected'],
    data: () => ({
        isMobile: false,
        isFetching: false,
        valid: false,
        formfields: {
            reporting: 0,
            frequency: null,
            day: null,
            time: null,
            monthly: null,
            send_me_copy: false
        },
        frequencies: [
            'Daily',
            'Weekly',
            'Monthly',
            // 'Annually'
        ],
        days: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
        timeslots: [],
        monthlyDays: [],
        isMonthly: false
    }),
    computed: {
        ...mapGetters({
            // getCustomers: 'customers/getCustomers',
            // getCustomersLoading: 'customers/getCustomerLoading'
        }),
    },
    methods: {
        ...mapActions({
            //  fetchCustomers: 'customers/fetchCustomers'
        }),
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$emit('closeOpenDialog')
        },
        timeSlots() {
            var timeStops = [];
            var startTime = moment().set({hour:1,minute:0,second:0,millisecond:0})
            var endTime = moment().set({hour:23,minute:0,second:0,millisecond:0})
            
            timeStops.push(startTime.format('HH:mm'))
            while(startTime.format('HH:mm') !== endTime.format('HH:mm')) {
                startTime.add(15, 'minutes');
                timeStops.push(startTime.format('HH:mm'));
            }
            
            this.timeslots = timeStops
        },
        DaysInMonth() {
            for(var i = 1; i<=30;i++) this.monthlyDays.push(i)
        },
        savesetting(){
            console.log(this.selected)
             if(this.$refs.form.validate()){
            //var state = (this.toggleNewValue) ? true: false;
            var  object = {
                formfields: this.formfields,
                selected: this.selected,
                locationfrom: 'index'
            }
            this.isFetching = 1;
            axios.post(`${APIBaseUrl}/saveIndexManageReportSettings`,object)
            .then((response) => {
                if(response){
                    // this.$emit('update:tooggle',
                    //     {
                    //         state:state,
                    //         copy:this.getsendcopy,
                    //         frequency:this.initialfrequencymodel,
                    //         day:this.initialfrequencymodel == 3 ? this.monthlydayselected:this.dayselected,
                    //         time:this.timeselected,
                    //         type: 'details'   
                    //     }
                    // );
                    this.$refs.form.resetValidation();
                    this.dispatchNotifcation('Automated Recurring Reports have successfully been set')
                    this.$emit('closeOpenDialog')
                }
                 this.isFetching = 0;
            }); 
             }
        },
        handleCheck() {
            this.formfields.send_me_copy = !this.formfields.send_me_copy
        },
        getEmailAdd(item) {
            var eas = item.split(',')

            return eas[0]
        },
        getEmailsLen(item) {
            var len = Object.keys(item).length

            return len-1
        },
        removeSelected(key) {
            this.$emit('removeSelected', key)
        }
    },
    watch: {
        selected(nv) {
            if(Object.keys(nv).length === 0) {
                this.close()
            }
        },
        formfields: { 
            handler(nv) {
                if(nv.frequency === 'Monthly') {
                    this.isMonthly = true
                }
                else {
                    this.isMonthly = false
                }
            },
            deep: true
        }
    },
    mounted() {
        this.timeSlots()
        this.DaysInMonth()
    },
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';

.head-text {
    font-weight: 600;
    font-size: 16px;
    // line-height: 24px;
    // display: flex;
    // align-items: center;
    color: #4A4A4A;
}
.general-texts {
    font-weight: 400;
    font-size: 14px;
    // line-height: 24px;
    // display: flex;
    // align-items: center;
    color: #4A4A4A;
}
.email-tags {
    flex-direction: row;
    align-items: center;
    padding: 3px 8px;
    background: #F7F7F7;
    border: 1px solid #EBF2F5;
    border-radius: 4px;
    color: #4a4a4a;
    font-size: 12px;
}
.srsd-spacer {
    border-left: 2px solid #E1ECF0;
    height: 100px;
    max-width: 0px;
    height: auto !important;
}
.action-border {
    border-bottom: 3px solid #E1ECF0 !important;
}
.bg-on-reporting {
    padding: 12px 16px;
    height: 120px;
    background: #F7F7F7;
    border-radius: 4px;
    max-width: 497px;
}
div[role="combobox"] {
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
    box-shadow: none !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4A4A4A;
}
.v-select--is-menu-active {
    background: #F7F7F7 !important;
}

.my-checkbox {
    position: relative !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 5px;
    box-sizing: content-box;
    overflow: hidden;
}
.my-checkbox:before {
    content: "";
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    transition: 0.2s border-color ease;
}
.my-checkbox:checked:before {
    border-color: #0171a1;
    transition: 0.5s border-color ease;
}
.my-checkbox:disabled:before {
    border-color: #ccc;
    background-color: #ccc;
}
.my-checkbox:after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    background-color: #0171a1;
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    transform: translate(-50%, -50%) scale(0);
}
.my-checkbox[type="checkbox"]:before {
    border-radius: 5px;
}
.my-checkbox[type="checkbox"]:after {
    width: 9.6px;
    height: 16px;
    border-radius: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
    background-color: transparent;
    box-shadow: 4px 4px 0 0 #0171a1;
}
.my-checkbox[type="checkbox"]:checked:after {
    -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
    animation: toggleOnCheckbox 0.2s ease forwards;
}
.my-checkbox[type="checkbox"].filled:before {
    border-radius: 4px;
    transition: 0.2s border-color ease, 0.2s background-color ease;
}
.my-checkbox[type="checkbox"].filled:checked:not(:disabled):before {
    background-color: #0171a1;
}
.my-checkbox[type="checkbox"].filled:not(:disabled):after {
    box-shadow: 4px 4px 0 0 white;
}
@-webkit-keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}
@keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}
.checkbox-label {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #4A4A4A;
}
.srs-remove-btn {
    cursor: pointer;
}
.v-autocomplete__content .v-list-item--active {
    color: #1976d2 !important;
}
</style>
