<template>
    <v-dialog persistent style="caret-color:transparent" v-model="dialogDelete" max-width="480px" content-class="delete-driver-dialog delete-customer-dialog" v-resize="onResize" :retain-focus="false">
        <v-card>
          <v-card-text class="pt-5 pb-0">
            <img src="../../assets/icons/warming.svg" />
            
            <h2>Delete Customer</h2>
<p class="notificationDelete">Do you want to delete <strong>{{editedItemData.name}}</strong> from the customer list? They will no longer have access to Shifl platform and this action cannot be undone.</p>
             <span style="color:red" v-if="errorFound">{{errorMessage}}</span>    
          </v-card-text>
          <v-card-actions>
             
            <v-btn @click="clickDelete" class="btn-blue">
                <span>                   
                    <span>
                        <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="startloading"
                                    style="margin-right:3px"
                                    >                       
                        </v-progress-circular>
                        Delete                                       
                    </span>
                </span>
            </v-btn>
            <v-btn style="margin-left:5px !important" class="btn-white" text @click="close" v-if="!isMobile">
                Cancel
            </v-btn>                
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex'
import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'SupplierDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData', 'getCurrentPage'],
    components: {      
    },
    data: () => ({
        errorFound: 0,
        startloading: 0,
        deletedcompany: '',
        valid: true,       
        options: [],
        value: [],
        isMobile: false,           
       
    }),
    computed: {            
        dialogDelete: {
            get () {             
                return this.dialogData
            },
            set (value) {              
                if (!value) {                  
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        },           
    },
    methods: {       
        ...globalMethods,       
        ...mapActions({
            fetchCustomersv2: 'customers/fetchCustomersv2',
        }),
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        clickDelete(){ 
            this.startloading =1;           
            let id = this.editedItemData.customer_id;           
            var dis = this;
            dis.errorMessage  ='';
            dis.errorFound = 0;

            axios.delete(`${APIBaseUrl}/deleteCustomer/` + id)            
            .then((response) => {
                return response.data
            }).catch(error => {               
                dis.errorFound = 1;
                dis.errorMessage = error.message;                
            }).then(response => {                      
                dis.startloading =0;   
                
                if(dis.errorMessage ===''){                   
                    if(typeof response !='undefined'){  
                        Object.values(response).forEach(key => {                                                                           
                            if(key == 'Successful'){                                  
                                // dis.dispatchNotifcation('<i class="ic-delete"></i>&nbsp;<span class="text-message">Customer has been deleted.</span>') 
                                dis.notificationCustom("Customer has been deleted.")
                                dis.close()                                  
                                dis.$emit('update:notifyDeleteitem',id);             
                                this.fetchCustomersv2(this.getCurrentPage)
                            }
                        })                       
                    }
                }
            })        
        },
        close() {          
            this.$emit('update:dialogData', false)
        },  
        setToDefault() {
            this.$emit('setToDefault')
        }
    },
    watch: { 
        dialogDelete(c){
            if(c){
                this.errorMessage  ='';
                this.errorFound = 0;
            }
        }           
    },   
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>