<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Edit User</span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Name</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  class="DialogInputfield"
                  hide-details="auto"
                  v-model="editUserItemList.user"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                <label class="text-item-label">Email Address</label>
                <v-text-field
                  outlined
                  v-model="editUserItemList.emailAddress"
                  dense
                  :rules="inputRules"
                  class="DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                <label class="text-item-label">User Group</label>
                <v-text-field
                  outlined
                  v-model="editUserItemList.userGroup"
                  dense
                  :rules="inputRules"
                  class="DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            style="
              padding-left: 4px;
              padding-left: 11px;
              margin-top: 5px;
              border-top: 3px solid #ebf2f5;
            "
          >
            <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn class="btn-blue" text>
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        style="margin-right: 3px"
                        v-if="loading"
                      >
                      </v-progress-circular>
                      Submit
                    </span>
                  </span>
                </span>
              </v-btn>
              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialogEdit", "editUserItemList"],
  data: () => {
    return {
      loading: false,
      errorFound: 0,
      errorMessage: "",
      errorContent: "",
      errorSaving: 0,
      valid: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
