 <template>
        <v-layout row justify-center>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="480"
                content-class="mark-empty-dialog"
                >
                <v-card class="card_dialog_delete">
                    <v-card-text class="pt-5 mb-2">
                        <h2 style="font-size: 24px !important;">{{ this.itemData.container_empty ? 'Unmark' : 'Mark' }} As Empty</h2>
                    </v-card-text>

                    <v-card-text v-if="!this.itemData.container_empty" class="pb-0 mb-5">
                        <span class="text--secondary text-md-body-1">Input date and time container empty</span>
                    </v-card-text>
                    <v-card-text v-else>
                        <span class="text--secondary text-md-body-1">
                            Do you want to {{ this.itemData.container_empty ? 'unmark' : 'mark as empty?' }} the schedule leg
                            <strong>#{{ getLegID() }}</strong>?
                        </span>
                    </v-card-text>

                    <div v-show="!this.itemData.container_empty" class="datetime-field-groups mb-5 px-6">
                        <div class="d-flex align-center">
                            <DxDateBox 
                                type="date" 
                                v-model="emptyDate"
                                class="mark-empty-dxdate date mr-2"
                                :class="isEmpty ? 'input-disabled' : ''"
                                :disabled="isEmpty"
                                :openOnFieldClick="true"
                                :use-mask-behavior="true" 
                                display-format="yyyy-MM-dd"
                                placeholder="Select Date" />
                                
                            <DxDateBox 
                                type="time"
                                v-model="emptyTime"
                                class="mark-empty-dxdate"
                                :class="isEmpty ? 'input-disabled' : ''"
                                :disabled="isEmpty"
                                :openOnFieldClick="true"
                                :use-mask-behavior="true" 
                                placeholder="Select Time" />
                        </div>

                        <div class="empty-dialog-checkbox-wrapper py-2">
                            <v-checkbox 
                                v-model="isEmpty"
                                hide-details="auto"
                                label="Mark Empty without recording time"
                                class="mt-0"
                                :ripple="false">
                            </v-checkbox>
                        </div>
                    </div>

                    <!-- <div v-show="!this.itemData.container_empty" class="datetime-field-groups mb-5 pl-1">
                        <div class="flex flex-col ml-5 mr-5">
                            <div class="flex flex-column">
                                <label class="text-item-label">Date</label>
                                <v-text-field
                                    class="initialDropdown mr-5 pt-0"
                                    v-model="emptyDate"
                                    placeholder="Set Date"
                                    append-icon="ic-calendar"
                                    readonly
                                    hide-details
                                    @click="openDatePicker"
                                >
                                </v-text-field>
                                <small v-if="errors.includes('emptyDate')" class="text-red">This field is required</small>

                                <DatePickerDialog
                                    :open="isDatePickerOpen"
                                    :multiple="false"
                                    :label="'Pick Empty Date'"
                                    @confirm="confirmDatePicker"
                                    @close="closeDatePicker"
                                />
                            </div>

                            <v-dialog
                                ref="emptyTime"
                                v-model="modalEmptyTime"
                                :return-value.sync="emptyTime"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="flex flex-column">
                                        <label class="text-item-label">Time</label>
                                        <v-text-field
                                            class="initialDropdown mr-5 pt-0"
                                            hide-details
                                            v-model="emptyTime"
                                            placeholder="Set Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </div>
                                </template>
                                <v-time-picker
                                    v-if="modalEmptyTime"
                                    v-model="emptyTime"
                                    full-width
                                >
                                    <v-btn
                                        v-show="emptyTime"
                                        text
                                        color="warning"
                                        class="mr-3 ml-0"
                                        @click="$refs.emptyTime.save('')"
                                    >
                                        Remove
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modalEmptyTime = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="
                                        $refs.emptyTime.save(
                                            emptyTime
                                        )
                                        "
                                    >
                                        OK
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </div>
                        <div class="custom-error-text ml-5" v-show="custom_error_show">
                            {{ errors["emptyDate"] }}
                        </div>
                    </div> -->

                    <v-card-actions class="pb-4">
                        <v-btn
                            class="btn-blue"
                            text
                            @click="clickMark(itemData)"
                            :disabled="isLoading"
                        >
                        {{ this.itemData.container_empty ? 'Unmark' : 'Mark' }} As Empty
                        </v-btn>
                        <v-btn
                            class="btn-white"
                            text
                            @click="close">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
</template>
<script>
import _ from "lodash";
// import DatePickerDialog from '../Dialog/DatePickerDialog.vue';
import DxDateBox from 'devextreme-vue/date-box';
import moment from "moment"

export default {
    name: "EmptyDialog",
    props: ['dialog', 'isLoading', 'itemData'],
    components: {
        // DatePickerDialog
        DxDateBox
    },
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "containers");
    },
    data: () => ({
        isMobile: false,
        valid: true,
        emptyDate: null,
        emptyTime: null,
        modalEmptyDate: false,
        modalEmptyTime: false,
        errors: [],
        custom_error_show: false,
        isDatePickerOpen: false,
        isEmpty: false
    }),
    watch: {
        // emptyDate(nv) {
        //     console.log(nv)
        //     // if(nv !== null || nv !== '') {
        //     //     this.errors.pop()
        //     // }
        // }
    },
    methods: {
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        getLegID() {
            var scheds = this.itemData.dispatch_schedule ? JSON.parse(this.itemData.dispatch_schedule) : undefined
            return scheds?.id ?? undefined
        },
        clickMark() {
            if(this.itemData.container_empty) {
                let dateTime = {
                    date: null,
                    time: null,
                }
                this.$emit('updateContainerEmpty', this.itemData, dateTime)
            }
            else {
                if(this.emptyDate !== null && this.emptyDate !== '') {
                    let dateTime = {
                        date: moment(this.emptyDate).format("YYYY-MM-DD"),
                        time: this.emptyTime !== null ? moment(this.emptyTime, ["hh:mm A"]).format("HH:mm") : ""
                    }
                    this.$emit('updateContainerEmpty', this.itemData, dateTime)
                }
                else {
                    // this.errors.push('emptyDate')
                    let dateTime = {
                        date: "",
                        time: "",
                    }

                    this.$emit('updateContainerEmpty', this.itemData, dateTime)
                }
            }
        },
        close() {
            this.emptyDate = null
            this.emptyTime = null
            this.$emit('closeDialog', this.itemData)
            this.isEmpty = false
        },
        dateRules(value) {
            return {
                emptySchedule: () => {
                    let msg = "";
                    if (_.isEmpty(value)) {
                        msg = "This field is required";
                        this.errors["emptyDate"] = msg;
                        this.errors["emptySchedule"] = true;
                        this.custom_error_show = true
                    }
                    else {
                        this.errors["emptySchedule"] = true;
                        this.errors["emptyDate"] = '';
                        this.custom_error_show = false
                    }
                    return !_.isEmpty(value)
                },
            }

        },
        openDatePicker() {
            this.isDatePickerOpen = true
        },
        closeDatePicker() {
            this.isDatePickerOpen = false
        },
        confirmDatePicker(date) {
            this.emptyDate = date
            this.isDatePickerOpen = false
        }
    },
};
</script>

<style lang="scss">
.v-dialog.mark-empty-dialog {
    .v-card {
        .v-card__text {
            h2 {
                font-family: 'Inter-Medium', sans-serif !important;
                font-weight: unset;
                font-size: 26px;
            }

            span {
                &.text--secondary {
                    font-family: 'Inter-Regular', sans-serif !important;
                    letter-spacing: normal !important;
                    font-size: 14px !important;
                    color: #4a4a4a !important;
                }
            }
        }

        .mark-empty-dxdate {
            height: 42px;
            border: 1px solid #B4CFE0;
            border-radius: 4px;

            input {
                font-family: 'Inter-Regular', sans-serif;
                caret-color: #4a4a4a;
                color: #4a4a4a;
                padding-left: 12px;
            }

            .dx-placeholder {
                color: #B4CFE0 !important;
                
                &::before {
                    padding-left: 12px;
                }
            }

            &.date {
                .dx-texteditor-buttons-container {
                    .dx-dropdowneditor-button {
                        width: 45px;

                        .dx-dropdowneditor-icon {
                            width: 45px;
                            color: #4a4a4a;

                            &::before {
                                content: "";
                                background-image: url('/icons/calendar-black.svg') !important;
                                background-position: center !important;
                                background-repeat: no-repeat !important;
                                background-size: cover !important;
                                width: 18px !important;
                                height: 18px !important;
                            }
                        }
                    }          
                }
            }

            &.input-disabled {
                background-color: #ebf2f5;
                border: 1px solid #ebf2f5;
            }
        }

        .empty-dialog-checkbox-wrapper {
            .v-input {
                .v-input__control {
                    .v-input__slot {
                        label {
                            font-size: 14px !important;
                            color: #4a4a4a;
                        }

                        i {
                            &.mdi-checkbox-blank-outline {
                                width: 26px !important;
                                padding: 3px !important;

                                &::before {
                                    content: '' !important;
                                    background-image: url('/checkbox-empty-icon-1.svg') !important;
                                    background-position: center !important;
                                    background-repeat: no-repeat !important;
                                    background-size: cover !important;
                                    width: 16px !important;
                                    height: 16px !important;
                                }
                            }

                            &.mdi-checkbox-marked {
                                width: 26px !important;
                                padding: 3px !important;

                                &::before {
                                    content: '' !important;
                                    background-image: url('/checkbox-filled-icon-1.svg') !important;
                                    background-position: center !important;
                                    background-repeat: no-repeat !important;
                                    background-size: cover !important;
                                    width: 16px !important;
                                    height: 16px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        input {
            font-family: 'Inter-Regular', sans-serif !important;
            font-size: 14px !important;
            line-height: 20px !important;
        }

        .v-card__actions {
            button {
                font-size: 14px !important;

                &.btn-white {
                    margin-left: 0 !important;
                }
            }
        }
    }
}
</style>