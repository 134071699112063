<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">Update Chassis </span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
               <v-col style="color:red;" 
                v-if="errorFound" 
                cols="12" 
                sm="12" 
                md="12" 
                class="pb-3 pt-1"
                >
                {{errorMessage}}
              </v-col> 
              <v-col 
                cols="12" 
                sm="12" 
                md="12" 
                class="pb-3 pt-1"
                >
                <label class="text-item-label">Number</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"   
                  v-on:keypress="ISALPHANUMERIC($event)"              
                  v-model="editChassisItemList.number"
                  class="DialogInputfield"
                  name="name"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col> 

              <v-col 
                cols="12" 
                sm="12" 
                class="pt-0 pb-0" 
                md="12"
                >
                <label class="text-item-label">Chassis Type</label>                     
                <v-autocomplete
                  v-model="editChassisItemList.chassis_type_id"
                  :items="getChassisType"   
                  class="inputselect"             
                  item-text='name'
                  item-value='id'
                  placeholder="Select Chassis Type"
                  solo                 
                  append-icon="ic-chevron-down"
                >
							</v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-2 mt-0" md="12">
                 <label class="text-item-label">Company Owned</label>  
                  <v-checkbox style="margin-top:0px"
                    v-model="trucker_owned"
                    label=""
                  >
                  </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions 
              style="padding-left:4px;
                     padding-left:11px; 
                     margin-top:5px;
                     border-top:3px solid #EBF2F5"
                     >
            <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn class="btn-blue" text @click="save">
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        v-if="getUpdateChassisLoading"
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Submit
                    </span>
                  </span>
                </span>
              </v-btn>

              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["dialogEdit", "editChassisItemList"],
  data: () => {
    return {
      trucker_owned: 1,
      errorFound: 0,
      errorMessage: '',
      errorContent: "",
      errorSaving: 0,
      valid: true,
      chassisType: 0,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v.length > 3 ) || "Minimum of 4 characters",
        (v) => ( v.length <  20 ) || "Maximum of 20 alphanumeric characters",
        (v) => /^[A-Za-z0-9]+$/.test(v) || 'Aphanumeric only',
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUpdateChassisLoading: "chassis/getUpdateChassisLoading",
      getChassisType: "chassis/getChassisType",
      get_alert_box_text: "chassis/get_alert_box_text",
      getdetails: "chassis/getdetails",
    }),
  },
  watch: {
    dialogEdit(c){
      if(c){ 
        this.trucker_owned = parseInt(this.editChassisItemList.trucker_owned)
      }
      if(!c || c){
        this.errorFound = 0;  
      }
    },
    getdetails(newValue) {        
      if (newValue.status === 1) {
        this.$emit("close");
      }
    },
  }, 
  methods: {
    ...mapActions("chassis", ["editChassisItem"]),
    close() {
      this.$emit("close");
    },
    ISALPHANUMERIC(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    async save() {     
     
      var findme = this.getChassisType.find(d => d.id == this.editChassisItemList.chassis_type_id)
      this.editChassisItemList.type =   findme.name 
      this.editChassisItemList.trucker_owned =  (this.trucker_owned == true ) ? 1 : 0;
      
      if (this.$refs.form.validate()) {      
        await this.editChassisItem(this.editChassisItemList);        
        if(this.getdetails.status){
          this.close();
          this.$emit("syncAction",{created:0,msg: this.get_alert_box_text});
        } else {
          this.errorFound = 1;    
          this.errorMessage = this.getdetails.msg
        }
      }
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
.v-select--is-menu-active {
  background: none !important;
  border: 0px !important;
}
.v-text-field__slot input {
  font-size: 14px !important;
}
.v-input__slot{
  border-radius: 6px;
  height: 28px !important;
  min-height: 39px !important;
}
</style>
