<template>
  <div class="dispatch-container">
    <AddNewLeg
      :showAddLegModal="showAddLegModal"
      :driverList="driver"
      :chassisList="chassis"
      :responseChassis="responseChassis"
      :responseObjectMeta="responseObjectMeta"
      :modedropdown="modedropdown"
      :getObject="getObject"
      :typeDropdown="typeDropdown"
      :pickupLocation="getPickupLocation"
      :lastLeg="getLastleg()"
      @close="showAddLegDialog('showAddLegModal', false)"
      @addNewLeg="addNewLeg"
      :dialogData.sync="dialogitem"
      :triggeredData.sync="triggeredData"
      @saveNewChassis="saveNewChassis"
      :dispatchData="dispatch_schedule"
      :bus="bus"
      :eta="eta"
      :fromDispatch="true"
    />
    <EditLeg
      :showEditLegModal="showEditLegModal"
      :driverList="driver"
      :chassisList="chassis"
      :responseChassis="responseChassis"
      :responseObjectMeta="responseObjectMeta"
      :modedropdown="modedropdown"
      :typeDropdown="typeDropdown"
      :pickupLocation="editingLegPickupLocation"
      :leg="editingLeg"
      :currentEditingLegNum="currentEditingLegNum"
      :eta="eta"
      :markasrrive="markasrrived"
      @close="
        () => {
          showEditLegModal = false;
          editingLeg = null;
        }
      "
      :dispatchData="dispatch_schedule"
      @saveEditedLeg="saveEditedLeg"
      :dialogData.sync="dialogitem"
      :triggeredData.sync="triggeredData"
      @saveNewChassis="saveNewChassis"
      :fromDispatch="true"
      :shouldClearJustAddedFields="shouldClearJustAddedFields"
    />
    <!-- /// -->
    <div class="spinner mt-4" v-if="getTabLoading">
      <v-progress-circular
        :size="40"
        color="#0171a1"
        indeterminate
        v-if="getTabLoading"
      >
      </v-progress-circular>
    </div>
    <v-form
      id="form"
      v-if="displayDispatchContent == false"
      ref="form"
      v-model="valid"
      @submit.prevent
    >
      <v-row>
        <!-- <v-col class="leftcol" style="border-right:0px !important;" md="4">
          <div class="selectBox">
            <small>LOCATION FROM</small>
            <v-autocomplete
              v-model="locationFrom"
              :items="terminalDropdown"
              :rules="terminalRules"
              item-text="name"
              item-value="id"
              placeholder="Select Terminal"
              solo
              append-icon="ic-chevron-down"
            ></v-autocomplete>
          </div>
        </v-col> -->
        <!-- <v-col class="leftcol" md="4"> -->
        <!-- <small>LAST FREE DATE</small> -->
        <!-- <v-dialog
            ref="last_free_date"
            v-model="modal_lfd"
            :return-value.sync="last_free_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="last_free_date"
                placeholder="05/21/2021"
                append-icon="ic-calendar"
                readonly
                :rules="[
                  dateRules(last_free_date, 'Last Free Date')
                    .shouldBeLaterThanETA,
                ]"
                class="lastfreedate"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="last_free_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_lfd = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs['last_free_date'].save(last_free_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->
        <!-- <v-text-field
            v-model="last_free_date"
            placeholder="05/21/2021"
            append-icon="ic-calendar"
            :rules="[
              dateRules(last_free_date, 'Last Free Date').shouldBeLaterThanETA,
            ]"
            class="lastfreedate"
            @click:append="showcustom(last_free_date, 'last_free_date')"
          >
          </v-text-field> -->
        <!-- </v-col>
        <v-col class="leftcol" md="4"> -->
        <!-- <small>PER DIEM DATE</small> -->
        <!-- <v-dialog
            ref="per_diem_date"
            v-model="modal_pdd"
            :return-value.sync="per_diem_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="per_diem_date"
                placeholder="05/21/2021"
                append-icon="ic-calendar"
                readonly
                :rules="[
                  dateRules(per_diem_date, 'Per Diem Date')
                    .shouldBeLaterThanETA,
                  dateRules(per_diem_date, 'Per Diem Date')
                    .shouldBeLaterThanLFD,
                ]"
                class="lastfreedate"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="per_diem_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_pdd = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs['per_diem_date'].save(per_diem_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->
        <!-- <v-text-field
            v-model="per_diem_date"
            placeholder="05/21/2021"
            append-icon="ic-calendar"
            :rules="[
              dateRules(per_diem_date, 'Per Diem Date').shouldBeLaterThanETA,
              dateRules(per_diem_date, 'Per Diem Date').shouldBeLaterThanLFD,
            ]"
            class="lastfreedate"
            @click:append="showcustom(per_diem_date, 'per_diem_date')"
          >
          </v-text-field> -->
        <!-- </v-col>-->
      </v-row>
      <v-row>
        <v-col class="leftcol px-2 pt-0" style="border-right: none !important;" md="12">
          <div class="d-flex align-center justify-space-between py-3">
            <h2 class="pl-2 pb-0" style="font-family: 'Inter-Medium', sans-serif !important; color: #4a4a4a;">Schedules</h2>
            <!-- <h2>Schedule Leg</h2> -->
            <button class="btn-white" style="font-family: 'Inter-Medium', sans-serif !important;" @click="showAddLegDialog('showAddLegModal', true)">
              + Add Leg
            </button>
            <!-- @click="showAddLegModal = true" -->
          </div>
          <table cellpadding="5" cellspacing="0" class="tabledispatch">
            <thead>
              <tr>
                <th class="text-start pl-4 pr-0 text-uppercase" style="width: 4%">SL #</th>
                <th class="text-uppercase" style="width: 15%">Delivery Location</th>
                <th class="text-uppercase px-2" style="width: 8%">Pickup on</th>
                <th class="text-uppercase px-2" style="width: 9%">Delivery on</th>
                <th class="text-uppercase px-2" style="width: 10%">DRIVER</th>
                <th class="text-uppercase px-2" style="width: 4%">MODE</th>
                <th class="text-uppercase px-2" style="width: 6%">TYPE</th>
                <th class="text-center text-uppercase px-2" style="width: 4%">Arrived</th>
                <!-- <th class="text-center text-uppercase px-2" style="width: 15%">Proof of Delivery</th> -->
                <th class="text-end px-2" style="width: 4%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tablerow" :key="item.id">
                <td class="text-start pr-0 py-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <span class="counterRow" style="font-size: 14px;">{{
                    String(i + 1).padStart(2, "0")
                  }}</span>
                </td>
                <td class="py-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <span>{{ address[i].value || "" }}</span>
                </td>
                <td class="pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <div
                    v-if="
                      getLegs[i].arrival_at_pickup_location_date ||
                      getLegs[i].arrival_at_pickup_location_time
                    "
                  >
                    <span>
                      {{
                        getDateFormat(
                          getLegs[i].arrival_at_pickup_location_date,
                          true
                        )
                      }}</span
                    >
                    <div class="text-dark-gray">
                      {{
                        checkTotime(getLegs[i].arrival_at_pickup_location_time)
                      }}
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="getLegs[i].from_date">
                      Scheduled
                    </div>
                    <div class="text-dark-gray" v-else>-</div>
                    <span class="text-dark-gray">
                      {{ getDateFormat(getLegs[i].from_date, true) }}
                    </span>
                  </div>
                </td>
                <td class="pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <div
                    v-if="
                      getLegs[i].arrival_at_delivery_location_date ||
                      getLegs[i].arrival_at_delivery_location_time
                    "
                  >
                    <span>
                      {{
                        getDateFormat(
                          getLegs[i].arrival_at_delivery_location_date,
                          true
                        )
                      }}</span
                    >
                    <div class="text-dark-gray">
                      {{
                        checkTotime(
                          getLegs[i].arrival_at_delivery_location_time
                        )
                      }}
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-dark-gray" v-if="getLegs[i].date">
                      Scheduled
                    </div>
                    <div class="text-dark-gray" v-else>-</div>
                    <span class="text-dark-gray">
                      {{ getDateFormat(getLegs[i].date, true) }}
                    </span>
                  </div>
                </td>
                <td class="pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <span
                    :class="
                      typeof driverselecter[i].value != 'undefined'
                        ? driverselecter[i].value.id == 0
                          ? 'text-gray'
                          : ''
                        : 'Unassinged'
                    "
                  >
                    {{
                      typeof driverselecter[i].value != "undefined"
                        ? driverselecter[i].value.name
                        : "Unassinged"
                    }}
                  </span>
                </td>
                <td class="pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <span> {{ mode[i].value == "Live" ? "Live" : "Drop" }} </span>
                </td>
                <td class="pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <span>
                    {{
                      dispatch_type[i].value
                        ? typeDropdown.find(
                            (e) => e.value == dispatch_type[i].value
                          ).label
                        : dispatch_type[i].value
                    }}
                  </span>
                </td>
                <td class="text-center pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <div v-if="selectedcheckbox[i].value" class="d-flex justify-center align-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="green"
                    >
                      <path
                        d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12 2.65C6.83614 2.65 2.65 6.83614 2.65 12C2.65 17.1639 6.83614 21.35 12 21.35C17.1639 21.35 21.35 17.1639 21.35 12C21.35 6.83614 17.1639 2.65 12 2.65ZM10.625 13.5833L15.5416 8.66664C15.8638 8.34445 16.3862 8.34445 16.7084 8.66664C17.0013 8.95953 17.0279 9.41786 16.7882 9.74083L16.7084 9.83336L11.2084 15.3334C10.9155 15.6263 10.4571 15.6529 10.1342 15.4132L10.0416 15.3334L7.29164 12.5834C6.96945 12.2612 6.96945 11.7388 7.29164 11.4166C7.58453 11.1237 8.04286 11.0971 8.36583 11.3368L8.45836 11.4166L10.625 13.5833L15.5416 8.66664L10.625 13.5833Z"
                      />
                    </svg>
                  </div>
                  <span v-else> - </span>
                </td>
                <!-- <td class="pa-2 text-center" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <div class="pod-col-wrapper" v-if="item.pod !== null">
                    <div class="pod-col-content">
                      <div class="d-flex align-center mr-2">
                        <img src="@/assets/icons/document-gray.svg" height="15px" width="15px" alt="">

                        <div class="doc-name-wrapper">
                          <p class="doc-name">{{ getPODName(item, "name") }}</p><span>{{ getPODName(item, "ext") }}</span>
                        </div>
                      </div>

                      <div class="d-flex align-center">
                        <button type="button" title="Preview" class="btn-black" style="width: unset !important;" 
                          @click="previewPOD(item)">
                          <img src="@/assets/icons/visibility-darkblack.svg" height="14px" width="14px" alt="">
                        </button>
                        <button type="button" title="Download" class="btn-black ml-2" style="width: unset !important;"
                          @click="downloadPOD(item)">
                          <img src="@/assets/icons/download-black.svg" height="13px" width="13px" alt="">
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-else>-</div>
                </td> -->
                <td class="text-end pa-2" style="border-bottom: 1px solid #EBF2F5 !important; vertical-align: middle;">
                  <!-- <div class="flex">
                    <button class="button-outline-sm" @click="showEditLeg(i)">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                          fill="none"
                          style=""
                        >
                          <path
                            d="M13.4165 2.58353C14.1945 3.36157 14.1945 4.62302 13.4165 5.40106L6.05846 12.7591C5.7393 13.0782 5.33939 13.3047 4.9015 13.4141L2.61933 13.9847C2.25455 14.0759 1.92413 13.7455 2.01532 13.3807L2.58587 11.0985C2.69534 10.6606 2.92176 10.2607 3.24093 9.94154L10.5989 2.58353C11.377 1.80549 12.6384 1.80549 13.4165 2.58353ZM9.89448 4.69657L3.94531 10.6459C3.75381 10.8374 3.61796 11.0774 3.55227 11.3401L3.18307 12.8169L4.6599 12.4477C4.92264 12.382 5.16258 12.2462 5.35407 12.0547L11.303 6.10513L9.89448 4.69657ZM11.3033 3.28791L10.5988 3.9923L12.0073 5.40085L12.7121 4.69668C13.1011 4.30766 13.1011 3.67693 12.7121 3.28791C12.3231 2.89889 11.6923 2.89889 11.3033 3.28791Z"
                            fill="#0171A1"
                          />
                        </svg>
                      </div>
                      <label> Edit </label>
                    </button>
                    <button
                      class="button-sm ml-5"
                      :class="
                        i == tablerow.length - 1
                          ? 'text-red'
                          : 'text-light-gray'
                      "
                      @click="deleteLeg(i)"
                    >
                      <i
                        class="ic-delete mr-2"
                        :class="
                          i == tablerow.length - 1
                            ? 'text-red'
                            : 'text-lieght-gray'
                        "
                      ></i>
                      <b class="">Delete</b>
                    </button>
                  </div> -->

                  <div class="d-flex justify-end align-center">
                    <v-menu offset-y bottom left content-class="dispatch-menu" min-width="130px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="btn-white mr-0" 
                          style="height: 30px !important; min-width: 35px !important; padding: 4px 8px !important;">
                          <img src="@/assets/icons/more-blue.svg" alt="" />
                        </v-btn>
                      </template>

                      <v-list>
                          <v-list-item class="dispatch-tab-wrapper" @click="showEditLeg(i)">
                            <v-list-item-title class="dispatch-tab-item">
                              <span class="d-flex align-center" style="padding-right:3px">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    style="">
                                    <path
                                      d="M13.4165 2.58353C14.1945 3.36157 14.1945 4.62302 13.4165 5.40106L6.05846 12.7591C5.7393 13.0782 5.33939 13.3047 4.9015 13.4141L2.61933 13.9847C2.25455 14.0759 1.92413 13.7455 2.01532 13.3807L2.58587 11.0985C2.69534 10.6606 2.92176 10.2607 3.24093 9.94154L10.5989 2.58353C11.377 1.80549 12.6384 1.80549 13.4165 2.58353ZM9.89448 4.69657L3.94531 10.6459C3.75381 10.8374 3.61796 11.0774 3.55227 11.3401L3.18307 12.8169L4.6599 12.4477C4.92264 12.382 5.16258 12.2462 5.35407 12.0547L11.303 6.10513L9.89448 4.69657ZM11.3033 3.28791L10.5988 3.9923L12.0073 5.40085L12.7121 4.69668C13.1011 4.30766 13.1011 3.67693 12.7121 3.28791C12.3231 2.89889 11.6923 2.89889 11.3033 3.28791Z"
                                      fill="#0171A1"/>
                                  </svg>
                              </span> <span class="ml-1">Edit</span>
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item class="dispatch-tab-wrapper" @click="deleteLeg(i)" v-if="i == tablerow.length - 1"
                            :class="
                              i == tablerow.length - 1
                                ? 'text-red'
                                : 'text-light-gray'
                            ">
                            <v-list-item-title class="dispatch-tab-item">
                              <i class="ic-delete mr-2"
                                :class="
                                  i == tablerow.length - 1
                                    ? 'text-red'
                                    : 'text-light-gray'
                                ">
                              </i> <span>Delete</span>
                            </v-list-item-title>
                          </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <v-dialog v-model="resultDialog" width="50%">
        <v-card>
          <v-card-text style="padding-top: 33px">
            Schedule leg has been successfully Saved.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="resultDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <CreatePicker
      :dialogDatepicker="dialogDatepicker"
      :defaultData="pickerDefaultdata"
      @passbackdata="getdata"
      @close="closeDialogpicker"
    />

    <PreviewDoc 
      :item="previewItem"
      :dialogData.sync="openPreviewDialog"
      @close="closePreviewDoc"/>
  </div>
</template>
<script>
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;
//import MaskedInput from 'vue-masked-input'
//const poBaseUrl = process.env.VUE_APP_PO_URL
import globalMethods from "../../../../utils/globalMethods";
import moment from "moment";
import AddNewLeg from "../Dialog/AddNewLeg";
import EditLeg from "../Dialog/EditLeg";
import CreatePicker from "../../../Createpicker.vue";
import PreviewDoc from "../Dialog/PreviewDoc.vue"
import _ from "lodash";
export default {
  name: "create_containers",
  props: [
    "items",
    "thedrivers",
    "thechassis",
    "theTerminals",
    "bus",
    "itemTerminalID",
    "getObject",
    "eta",
  ],
  components: {
    AddNewLeg,
    EditLeg,
    CreatePicker,
    PreviewDoc
  },
  data() {
    return {
      isNewlyAdded: false,
      shouldClearJustAddedFields: false,
      previewItem: null,
      openPreviewDialog: false,
      markasrrived:0,
      pickerDefaultdata: "",
      currentstatepicker: "",
      dialogDatepicker: false,
      getTabLoading: true,
      hideButton: false,
      hasliveError: false,
      ltext1: "Schedule Leg #",
      ltext2: "must be dropped",
      textaddLeg: "Add Schedule Leg #",
      textAddlegDropped: "and set mode to dropped",
      driverselecter: [
        {
          value: "",
        },
      ],
      dispatch_type: [
        {
          value: "",
        },
      ],
      typeDropdown: [
        { label: "Customer", value: "Customer" },
        { label: "Yard", value: "Yard" },
        { label: "To Port", value: "Empty Return" },
      ],
      resultDialog: false,
      dispatchData: [],
      locationFrom: this.itemTerminalID,
      value: "",
      dispatchValue: [],
      hasDispatch: false,
      displayDispatchContent: false,
      last_free_date: "",
      per_diem_date: "",
      selectedcheckbox: [
        {
          value: "",
        },
      ],
      maskValue: [
        {
          value: "",
        },
      ],
      mode: [
        {
          value: "",
        },
      ],
      checkboxselected: [
        {
          value: 1,
        },
      ],
      modedropdown: [
        {
          id: 1,
          name: "Drop",
        },
        {
          id: 2,
          name: "Live",
        },
      ],
      driver: [],
      locationdropdown: [],
      enableBefore: false,
      time: [
        {
          value: "",
        },
      ],
      modal: [
        {
          value: false,
        },
      ],
      modal_lfd: false,
      modal_pdd: false,
      modal_time: [
        {
          value: false,
        },
      ],
      terminalRules: [(v) => !!v || "This field is required"],
      address: [
        {
          value: "",
        },
      ],
      tablerow: [],
      formFieldlength: 0,
      startloading: 1,
      clickOrigin: "",
      clicksubmit: false,
      submitisclick: false,
      selectedmilestone: [],
      terminal: [],
      rulesDates: [(v) => !!v || "Date and Time is required"],
      addressRules: [(v) => !!v || "Address is required"],
      daterules: [(v) => v === "__:__:__" || "Date is required"],
      truckersField: "",
      select: "",
      terminalSelected: "",
      currentDropdownState: "",
      trucker_idSelected: "",
      valid: true,
      tabscurrent: 1,
      tab: 1,
      tabsNavigation: ["Header Info", "Dispatch", "Charges"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      container_type: "",
      select_terminal_at: "",
      dialog: false,
      search: "",
      searchData: "",
      selectedTasks: [],
      truckers: [],
      legs: [],
      showAddLegModal: false,
      dispatch_schedule: null,
      showEditLegModal: false,
      editingLeg: null,
      currentEditingLegNum: null,
      dispatch_schedule_init: null,
      location_from_init: null,
      per_diem_date_init: null,
      last_free_date_init: null,
      chassis: [],
      dispatch_items: [],
      dialogitem: [],
      triggeredData: 0,
      responseChassis: null,
      responseObjectMeta: [],
    };
  },
  computed: {
    terminalDropdown: {
      get() {
        return this.theTerminals;
      },
    },
    getLegs() {
      return this.dispatch_schedule.legs || {};
    },
    getPickupLocation() {
      if (!this.locationFrom) return "";

      if (this.dispatch_schedule != null) {
        let legs = this.dispatch_schedule.legs;
        if (legs.length > 0) {
          return legs[legs.length - 1].address || "";
        }
      }

      let terminal = this.getTerminal(this.locationFrom);
      return terminal ? terminal.name + "\n" + terminal.address : "";
    },
    editingLegPickupLocation() {
      if (!this.locationFrom) return "";
      // let legs = this.dispatch_schedule.legs || {};
      // let i = this.currentEditingLegNum;
      // if (i && i > 0) {
      //   return legs[i - 1].address || "";
      // } else {
      //   let terminal = this.getTerminal(this.locationFrom);
      //   // console.log(terminal)
      //   return terminal ? terminal.name + "\n" + terminal.address : "";
      //   //return terminal ? terminal.name : "";
      // }

      //let legs = this.dispatch_schedule.legs !== null  {};
      if (this.dispatch_schedule !== null) {
        let legs = this.dispatch_schedule.legs;
        if (legs.length > 0) {
          let i = this.currentEditingLegNum;
          if (i && i > 0) {
            return legs[i - 1].address || "";
          }
        }
      }

      let terminal = this.getTerminal(this.locationFrom);
      return terminal ? terminal.name + "\n" + terminal.address : "";
    },
  },
  created() {
    if (_.isEmpty(this.items)) {
      this.dispatch_schedule = {
        id: this.makegenerate(),
        location_from: "",
        last_free_date: "",
        per_diem_date: "",
        legs: [],
      };
      this.dispatch_schedule_init = _.cloneDeep(this.dispatch_schedule);
    }
    this.bus.$on("saveChanges", (args) => {
      if (args) {
        this.dispatch_schedule.location_from = this.locationFrom;
        this.dispatch_schedule.last_free_date = this.last_free_date_init;
        this.dispatch_schedule.per_diem_date = this.per_diem_date_init;
        if (this.$refs.form.validate())
          if (this.validateLegs()) {
            this.saveDispatch();
          }
      }
    });
  },
  methods: {
    ...globalMethods,
    showAddLegDialog(model, val) {
      this[model] = val
      
      if (val) {
        this.isNewlyAdded = true
      } else {
        this.isNewlyAdded = false
      }

      this.shouldClearJustAddedFields = false
    },
    getPODName(item, type) {
      let showName = ""

      if (item !== null) {
        if (item.pod !== null) {
          let namesplit = item.pod.filename.split('.')

          if (namesplit.length > 0) {
            if (type === "name") showName = namesplit[0]
            else showName = namesplit[namesplit.length - 1]
          }
        }
      }
      return showName
    },
    async downloadPOD(item) {
      console.log(item, 'download clicked');

      if (item !== null) {
        if (item.pod !== null) {
          let dispatch_leg_id = item.pod.leg_id

          axios({
            url: `${poBaseUrl}/driver/download-on-trucking/pod?dispatch_id=${+ dispatch_leg_id}`,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            console.log(response);
            // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            // var fileLink = document.createElement('a');
          
            // fileLink.href = fileURL;
            // fileLink.setAttribute('download', 'file.jpg');
            // document.body.appendChild(fileLink);
          
            // fileLink.click();
          }).catch((e) => {
            console.log(e, 'error');
          });

          // axios({
          //   url: `${poBaseUrl}/driver/download-on-trucking/pod?dispatch_id=${+ dispatch_leg_id}`,
          //   method: "GET",
          // }).then((response) => {
          //   window.URL = window.URL || window.webkitURL;
          //   console.log(response);
          //   // var json = response.data;
          //   // c[0] = new File([json], "file.pdf", {
          //   //   lastModified: new Date().getTime(),
          //   //   type: "application/pdf",
          //   // });
          //   // this.filelist = c;
          // }).catch((error) => {
          //   // this.response = error;
          //   console.log(error, 'error');
          // });
        }
      }
    },
    async previewPOD(item) {
      console.log(item, 'preview clicked');
      this.openPreviewDialog = true
      this.previewItem = item
    },
    closePreviewDoc() {
      this.openPreviewDialog = false
      this.previewItem = null
    },
    isDispatchChanged() {
      return (
        JSON.stringify(this.dispatch_schedule) !=
        JSON.stringify(this.dispatch_schedule_init)
        //this.location_from_init != this.locationFrom ||
        //this.last_free_date_init != this.last_free_date ||
        //this.per_diem_date_init != this.per_diem_date
      );
    },
    validateDate(dateStr) {
      if (typeof dateStr == "undefined") return false;
      let str = dateStr.split("T");
      dateStr = str[0];
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateStr.match(regex) === null) {
        return false;
      }
      const date = new Date(dateStr);
      const timestamp = date.getTime();
      if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
      }
      return date.toISOString().startsWith(dateStr);
    },
    validateLegs() {
      if (this.dispatch_schedule) {
        if (
          this.dispatch_schedule.legs &&
          this.dispatch_schedule.legs.length > 0
        ) {
          if (
            this.dispatch_schedule.legs[this.dispatch_schedule.legs.length - 1]
              .mode == "Live"
          ) {
            this.dispatchNotificationError("The last leg can't be Live");
            return false;
          }
        }
      }
      return true;
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    getdata(item) {
      if (this.currentstatepicker == "last_free_date") {
        this.last_free_date = item;
      }
      if (this.currentstatepicker == "per_diem_date") {
        this.per_diem_date = item;
      }
    },
    getLastleg() {
      let legs = this.dispatch_schedule
        ? this.dispatch_schedule.legs || []
        : [];
      if (legs.length > 0) {
        return legs[legs.length - 1];
      }
      return null;
    },
    dateRules(value, fieldName) {
      return {
        shouldBeLaterThanLFD: () => {
          if (typeof value == "undefined") return true;
          if (value == "") return true;
          if (!this.validateDate(value)) {
            return "Invalid Date ex: YYYY-mm-dd";
          }
          return _.isEmpty(value)
            ? true
            : moment.utc(value).isAfter(moment.utc(this.last_free_date)) ||
                fieldName + " should be later than Last Free Date";
        },
        shouldBeLaterThanETA: () => {
          if (typeof value == "undefined") return true;
          if (value == "") return true;
          if (!this.validateDate(value)) {
            return "Invalid Date ex: YYYY-mm-dd";
          }
          return _.isEmpty(value)
            ? true
            : moment.utc(value).isAfter(moment.utc(this.eta)) ||
                fieldName + " should be later than ETA";
        },
        cantBeEarlierThanETA: () =>
          _.isEmpty(value)
            ? true
            : moment.utc(value).isAfter(moment.utc(this.eta)) ||
              moment.utc(value).isSame(moment.utc(this.eta)) ||
              fieldName + " can't be earlier than ETA",
      };
    },
    deleteLeg(i) {
      if (this.dispatch_schedule.legs.length == i + 1) {
        this.dispatch_schedule.legs.pop();
        this.callitem();
      }
    },
    saveEditedLeg(leg) {
      //alert('naa diri')
      if (!this.dispatch_schedule) {
        this.dispatch_schedule = {
          id: this.makegenerate(),
          location_from: this.locationFrom,
          last_free_date: this.last_free_date,
          per_diem_date: this.per_diem_date,
          legs: [],
        };
      } else {
        this.dispatch_schedule.location_from = this.locationFrom;
        this.dispatch_schedule.last_free_date = this.last_free_date;
        this.dispatch_schedule.per_diem_date = this.per_diem_date;
      }

      let ob = {
        id: leg.id,
        schedule_id: this.dispatch_schedule.id,
        type: leg.selectedType,
        address: leg.address,
        date: leg.delivery_scheduled_date,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis ? leg.chassis : "",
        driver_status: leg.driverstatus ? leg.driverstatus : "",
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: leg.arrival_at_delivery_location_date ? true : false,
        from_date: leg.pickup_scheduled_date,
        to_time: leg.pickup_scheduled_time,
        addressLongitude: leg.addressLongitude || null,
        addressLatitude: leg.addressLatitude || null,
        driverCanSeeDeliveryOrder: leg.driverCanSeeDeliveryOrder,
        driver_instructions: leg.driver_instructions,
        pod_required: leg.pod_required,
        schedule_assigned_date_time: leg.schedule_assigned_date_time,
        schedule_accepted_date_time: leg.schedule_accepted_date_time,
        schedule_completed_date_time: leg.schedule_completed_date_time,
        next_leg_pickup_type: leg.next_leg_pickup_type,
        arrival_at_pickup_location_date:
          leg.arrival_at_pickup_location_date || "",
        arrival_at_pickup_location_time:
          leg.arrival_at_pickup_location_time || "",
        departure_from_pickup_location_date:
          leg.departure_from_pickup_location_date || "",
        departure_from_pickup_location_time:
          leg.departure_from_pickup_location_time || "",
        arrival_at_delivery_location_date:
          leg.arrival_at_delivery_location_date || "",
        arrival_at_delivery_location_time:
          leg.arrival_at_delivery_location_time || "",
        departure_from_delivery_location_date:
          leg.departure_from_delivery_location_date || "",
        departure_from_delivery_location_time:
          leg.departure_from_delivery_location_time || "",
        address_model_type: leg.address_model_type || null,
        address_model_id: leg.address_model_id || null,
      };
      this.dispatch_schedule.legs[leg.legNum] = ob;
      this.dialogitem = this.dispatch_schedule;
      this.triggeredData = 1;
      this.callitem();
      this.showEditLegModal = false;
      this.currentEditingLegNum = null;
      this.editingLeg = null;
    },
    showEditLeg(i) {
      this.editingLeg = this.dispatch_schedule.legs[i] || {};
      this.currentEditingLegNum = i;
      this.showEditLegModal = true;
    },
    getEitherValue(a, b) {
      if (_.isEmpty(a)) {
        return b;
      }
      return a;
    },
    addNewLeg(leg) {
      // console.log(leg);
      if (!this.dispatch_schedule) {
        this.dispatch_schedule = {
          id: this.makegenerate(),
          location_from: this.locationFrom,
          last_free_date: this.last_free_date,
          per_diem_date: this.per_diem_date,
          legs: [],
        };
      } else {
        this.dispatch_schedule.location_from = this.locationFrom;
        this.dispatch_schedule.last_free_date = this.last_free_date;
        this.dispatch_schedule.per_diem_date = this.per_diem_date;
      }

      let ob = {
        id: this.makegenerate(),
        schedule_id: this.dispatch_schedule.id,
        type: leg.selectedType,
        address: leg.address,
        addressLongitude: leg.addressLongitude,
        addressLatitude: leg.addressLatitude,
        driver_instructions: leg.driver_instructions,
        date: leg.delivery_scheduled_date,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis,
        driver_status: leg.driverstatus ? leg.driverstatus : "",
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: leg.arrival_at_delivery_location_date ? true : false,
        driverCanSeeDeliveryOrder: leg.driverCanSeeDeliveryOrder,
        pod_required: leg.pod_required,
        from_date: leg.pickup_scheduled_date,
        to_time: leg.pickup_scheduled_time,
        next_leg_pickup_type: leg.next_leg_pickup_type,
        schedule_assigned_date_time: leg.schedule_assigned_date_time,
        schedule_accepted_date_time: leg.schedule_accepted_date_time,
        schedule_completed_date_time: leg.schedule_completed_date_time,
        arrival_at_pickup_location_date: leg.arrival_at_pickup_location_date,
        arrival_at_pickup_location_time: leg.arrival_at_pickup_location_time,
        departure_from_pickup_location_date:
          leg.departure_from_pickup_location_date,
        departure_from_pickup_location_time:
          leg.departure_from_pickup_location_time,
        arrival_at_delivery_location_date:
          leg.arrival_at_delivery_location_date,
        arrival_at_delivery_location_time:
          leg.arrival_at_delivery_location_time,
        departure_from_delivery_location_date:
          leg.departure_from_delivery_location_date,
        departure_from_delivery_location_time:
          leg.departure_from_delivery_location_time,
        address_model_type: leg.address_model_type || null,
        address_model_id: leg.address_model_id || null,
        is_newly_added_leg: true
      };
      this.dispatch_schedule.legs.push(ob);
      this.dialogitem = this.dispatch_schedule;
      this.triggeredData = 1;
      this.callitem();
      this.showAddLegModal = false;
    },
    saveDispatch(message = "Dispatch successfully updated!") {
      axios
        .post(`${poBaseUrl}/dispatch-save/` + this.$route.params.id, {
          data: JSON.stringify(this.dispatch_schedule),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .then((response) => {
          if (response.status) {
            // this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">'+message+'</span>')
            this.notificationMessage(
              '<span class="text-message">' + message + "</span>"
            );
            this.$router.push("/containers");
            this.shouldClearJustAddedFields = true
          }
        });
    },
    saveNewChassis(data) {
      axios
        .post(`${poBaseUrl}/chassis-save`, data)
        .then((response) => {
          this.dispatchNotifcation(
            '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
              response.data.message +
              "</span>"
          );
          this.responseChassis = response.data.chassis;
          this.getChassis();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // check to time
    checkTotime(data) {
      if (typeof data != "undefined" && data) {
        return this.timeConvert(data);
      } else {
        return "-";
      }
    },
    // time convert
    timeConvert(dateString) {
      const timeString = dateString;
      let timeString12hr = new Date(
        "1970-01-01T" + timeString + "Z"
      ).toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
      timeString12hr = timeString12hr === "Invalid Date" ? "" : timeString12hr
      return timeString12hr.replace(/\s/g, "");
    },
    getDateFormat(date, utc = false) {
      if (date) {
        return moment(date).utc(utc).format("MMM DD, YYYY");
      }
      return "-";
    },
    async getTerminals() {
      axios
        .get(`${poBaseUrl}/terminals`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.response = error;
        })
        .then((response) => {
          this.locationdropdown = [];
          if (typeof response["data"] != "undefined") {
            response["data"].forEach((value) => {
              let id = value.id;
              let name = value.name;
              if (id == this.locationFrom) {
                this.value = value;
                this.terminal = id;
              }
              this.locationdropdown.push({
                id: id,
                name: name,
              });
            });
          }
        });
    },
    async getDrivers() {
      axios
        .get(`${poBaseUrl}/drivers`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.response = error;
        })
        .then((response) => {
          this.driver = [];
          response["data"].forEach((value) => {
            let id = [value][0].id;
            let name = [value][0].name;
            this.driver.push({
              id: id,
              name: name,
            });
          });
          // transfered here,too early the dispatch has been populated, after driver fetch is done populate the driver dropdown
          // and call the other
          this.callitem();
        });
    },
    async getChassis() {
      axios
        .get(`${poBaseUrl}/getcontainers-chassis`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.response = error;
        })
        .then((response) => {
          this.chassis = [];
          response.data.chassis.map((value) => {
            this.chassis.push({
              id: value.id,
              name: value.number,
            });
          });
        });
    },
    dispatchNotificationError(msg) {
      this.notificationError('<span class="text-message">' + msg + ".</span>");
      this.dialog = 0;
    },
    clicsubmit(origin) {
      var dispatchID = this.makegenerate();
      var object = {
        id: dispatchID,
        location_from: this.locationFrom,
        last_free_date: this.last_free_date,
        per_diem_date: this.per_diem_date,
        legs: [],
      };
      // why ? if error the hasliveError is true, if everything is fixed, reset back to false
      this.hasliveError = false;
      if (this.$refs.form.validate() && this.hasliveError == false) {
        this.startloading = 1;
        var lengthThis = this.address.length,
          obj;
        for (let i = 0; i < lengthThis; i++) {
          let gennewId = this.makegenerate();
          if (typeof this.driverselecter[i].id !== "undefined") {
            obj = this.driverselecter[i].id;
          } else {
            if (typeof this.driverselecter[i].value === "object") {
              obj = this.driverselecter[i].value.id;
            } else {
              obj = this.driverselecter[i].value;
            }
          }
          //checking mode
          let getMode = this.mode[i].id == 1 ? "dropped" : "live";
          if (getMode == "live") {
            var incrementOne = 1;
            //var sl = i + 2;
            if (typeof this.mode[i + incrementOne] !== "undefined") {
              let getLastleg =
                typeof this.mode[i + incrementOne].id == "undefined"
                  ? this.mode[i + incrementOne].id
                  : this.mode[i + incrementOne].id;
              if (getLastleg !== 1) {
                this.dispatchNotifcationError(
                  "Please fill in the consecutive leg when Scheduling a Live"
                );
                this.hasliveError = true;
                return 0;
              }
            } else {
              this.dispatchNotifcationError(
                "Please fill in the consecutive leg when Scheduling a Live"
              );
              this.hasliveError = true;
              return 0;
            }
          }
          let objectnew = {
            id: gennewId,
            schedule_id: dispatchID,
            type: this.dispatch_type[i].value,
            address: this.address[i].value,
            date: this.tablerow[i].dategetTerminal,
            time: this.maskValue[i].value,
            driver: obj,
            mode: this.mode[i].id == 1 ? "Dropped" : "Live",
            completed:
              this.selectedcheckbox[i].value == ""
                ? false
                : this.selectedcheckbox[i].value,
          };
          object.legs.push(objectnew);
        }
        const data = object;
        const myJSON = {
          data: JSON.stringify(data),
        };
        axios
          .post(`${poBaseUrl}/dispatch-save/` + this.$route.params.id, myJSON)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            if (response.status) {
              this.dispatchNotifcation(
                '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Dispatch successfully updated!</span>'
              );
              //this.notificationMessage('<span class="text-message">Dispatch successfully updated!</span>')
              if ("saveAndAnother" !== origin) {
                this.$router.push("/containers");
              }
            }
          });
      }
    },
    makegenerate() {
      return new Date().valueOf();
    },
    cancel() {
      this.$router.push("/containers");
    },
    formReset() {
      this.$router.push("/containers");
    },
    getCurrentTab(id) {
      this.currentSelectTab = id;
    },
    gotoCreate() {
      this.$router.push("/containers/create");
    },
    tabsnavigationClick(current) {
      if (current == 0) {
        this.$router.push("/container/add/new");
      } else if (current == 1) {
        this.$router.push("/containers/dispatch");
      } else if (current == 2) {
        this.$router.push("/containers/charges");
      }
      this.tabscurrent = current;
    },
    callitem() {
      if (!(_.isEmpty(this.items) && _.isEmpty(this.dispatch_schedule))) {
        let json = null;
        if (this.dispatch_schedule) {
          json = this.dispatch_schedule;
        } else {
          json = JSON.parse(this.items);
          this.dispatch_schedule = json;
          this.dispatch_schedule_init = _.cloneDeep(json);
        }
        if (typeof json.location_from != "undefined") {
          let s = this.getTerminal(json.location_from);
          if (typeof s != "undefined") {
            this.terminal = s.name;
          }
          this.locationFrom = json.location_from;
          this.terminalSelected = json.location_from;
        }
        this.dispatchValue = json.legs;
        this.displayDispatchContent = false;
        this.last_free_date = json.last_free_date;
        this.per_diem_date = json.per_diem_date;
        this.legs = json.legs;
        var lenglegs = json.legs;
        var lengtoflegs = lenglegs.length;
        // RESET ALL ELEMENTS
        this.address = [];
        this.dispatch_type = [];
        this.driverselecter = [];
        this.mode = [];
        this.selectedcheckbox = [];
        this.maskValue = [];
        this.tablerow = [];
        this.modal = [];
        this.modal_time = [];
        for (let i = 0; i < lengtoflegs; i++) {
          let pod = typeof lenglegs[i].pod !== "undefined" ? lenglegs[i].pod : null
          this.address.push({
            value: lenglegs[i].address,
          });
          this.dispatch_type.push({
            value: lenglegs[i].type,
          });
          this.mode.push({
            id: lenglegs[i].mode == "Dropped" ? 1 : 2,
            value: lenglegs[i].mode,
          });
          // add this to make dropdown driver autopopulated
          this.driverselecter.push({
            value: this.getDriverInfo(lenglegs[i].driver),
          });
          this.selectedcheckbox.push({
            value: lenglegs[i].completed,
          });
          this.maskValue.push({
            value: lenglegs[i].time,
          });
          this.tablerow.push({
            dialog: "dialog",
            date: lenglegs[i].date,
            modal: false,
            modal_time: false,
            model: "04/23/2021",
            address: "address",
            time: "time",
            driver: "driver",
            mode: "mode",
            completed: 1,
            pod
          });
          this.modal.push({
            value: false,
          });
          this.modal_time.push({
            value: false,
          });
        }
        this.hideTabnow(false);
      } else {
        this.hideTabnow(false);
      }
    },
    getDriverInfo: function (id) {
      return this.driver.find((d) => d.id == id);
    },
    getTerminal: function (id) {
      return this.locationdropdown.find((d) => d.id == id);
    },
    hideTabnow(arg) {
      this.getTabLoading = arg;
    },
    async getContainerDetails() {
      let container_id = this.$router.history.current.params.id;
      axios
        .get(`${poBaseUrl}/getcontainer/` + container_id)
        .then((response) => {
          this.responseObjectMeta = response.data.alldata;
        })
        .catch((error) => {
          this.response = error;
        });
    },
  },
  watch: {},
  async mounted() {
    this.dialogitem = this.items;
    if (this.theTerminals.length) {
      var response = this.theTerminals;
      this.locationdropdown = [];
      if (typeof response != "undefined") {
        response.forEach((value) => {
          let id = value.id;
          let name = value.name;
          let address = value.address;
          if (id == this.locationFrom) {
            this.value = value;
            this.terminal = id;
          }
          this.locationdropdown.push({
            id: id,
            name: name,
            address: address,
          });
        });
      }
    }
    /**
     * Allow unassigned in dispatch
     */
    this.driver.push({
      id: 0,
      name: "Unassigned",
    });
    if (this.thedrivers.length) {
      var driverni = this.thedrivers;

      driverni.forEach((value) => {
        // let id = [value][0].id;
        // let name = [value][0].name;
        // this.driver.push({
        //   id: id,
        //   name: name,
        // });
        this.driver.push(value)
      });
      // transfered here,too early the dispatch has been populated, after driver fetch is done populate the driver dropdown
      // and call the other
      // when mounted called lately
      this.locationFrom = parseInt(this.itemTerminalID);
    }

    this.getChassis();
    this.getContainerDetails();
    this.callitem();
    // set inital values
    this.location_from_init = this.locationFrom;
    this.last_free_date_init = this.last_free_date;
    this.per_diem_date_init = this.per_diem_date;

    this.$store.dispatch("page/setPage", "containers");
  },
  updated() {
    // console.log(this.tablerow);
  }
};
</script>
<style lang="scss">
.v-menu__content.dispatch-menu {
  min-width: 150px !important;

    .dispatch-tab-wrapper {
        min-height: 36px;
        cursor: pointer;

        &.text-light-gray {
          cursor: unset;

          .v-list-item__title {
            color: #b4cfe0 !important;
          }
        }
        
        .v-list-item__title {
          font-size: 14px !important;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #4a4a4a;
        }
    }
}

.pod-col-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .pod-col-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EBF2F5;
    height: 30px;
    border-radius: 4px;
    min-width: 210px;
    padding: 2px 8px;

    .doc-name-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .doc-name {
        margin-bottom: 0;
        padding-left: 6px;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: context-menu;
      }

      span {
        height: unset !important;
      }
    }   

    .btn-black {
      padding: 0 !important;
      height: 14px !important;
      width: 18px !important;
    }
  }
}
</style>

<style>
.disPlayDispatchContent {
  background: #fff;
  padding: 20px;
}

.itemTitle {
  font-weight: bold;
  padding-right: 10px;
  width: 100px;
}

.wrapperDispatchContent {
  padding-bottom: 10px;
  font-size: 12px;
}

.rootWrapperd {
  padding: 10px;
}

.rootWrapper {
  border-bottom: 1px solid #eee;
  padding: 10px;
}

.footeraddleg {
  margin-top: 18px;
}

.footeraddleg span {
  font-size: 12px !important;
}

.footeraddleg i {
  font-size: 12px !important;
}

.text-dark-gray {
  color: #6d858f !important;
}

.text-light-gray {
  color: #b4cfe0 !important;
}

.text-gray {
  color: #819fb2 !important;
}
.ic-calendar::before {
  font-size: 16px !important;
}
</style>
