<template>
    <v-dialog persistent v-model="dialog" max-width="590px" content-class="add-supplier-dialog add-driver-dialog" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-form id="createdriver" ref="form" v-model="valid" action="#" @submit.prevent="">
                <v-card-title style=" caret-color: transparent;">
                    <span class="headline">{{ formTitle }}</span>

                    <button icon dark class="btn-close" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <p v-if="errorSaving" style="color:red">{{errorContent}}</p>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-3 pt-0">
                            <label class="text-item-label">First Name</label>
                            <v-text-field
                                placeholder="Enter First name"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="driverName"
                                :rules="[fullnameValidate]"
                                name="name"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-3 pt-0">
                            <label class="text-item-label">Last Name</label>
                            <v-text-field
                                placeholder="Enter First name"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="last_name"
                                :rules="[fullnameValidate]"
                                name="name"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>



                        <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                            <label class="text-item-label">Email Address</label>
                                <v-text-field
                                placeholder="Enter Email Address"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="driverEmailAddress"
                                :rules="emailRules"
                                name="email"
                                hide-details="auto">
                                </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-2 pt-1">
                            <label class="text-item-label">Phone Number</label>
                            <vue-tel-input-vuetify
                                class="text-fields DialogInputfield"
                                type="number"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :valid-characters-only="true"
                                :rules="numberRules"
                                v-bind="telProps"
                                v-model="editedItem.phone_number" />
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                     <!-- <v-col class="text-right buttonActions" style="padding-right:0px">
                        <v-btn class="btn-blue" text @click="save">
                            <span v-if="editedIndexData === -1">
                                <span>
                                     <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >
                                    </v-progress-circular>
                                    Add Driver
                                </span>
                            </span>
                        </v-btn>

                        <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                            Cancel
                        </v-btn>
                     </v-col> -->
                    <div class="text-right buttonActions" style="padding:0px">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5 mr-2">
                                        <v-btn class="btn-blue" text @click="save">
                                            <span v-if="editedIndexData === -1">
                                                <span>
                                                    <v-progress-circular
                                                    :size="15"
                                                    color="#fff"
                                                    :width="2"
                                                    indeterminate
                                                    v-if="isFetching"
                                                    style="margin-right:3px"
                                                    >
                                                    </v-progress-circular>
                                                    Add Driver
                                                </span>
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="col-md-5">
                                        <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                                            Cancel
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'DriverDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData'],
    components: {
        VueTelInputVuetify
    },
    data: () => ({
        passwordMsg : 'Minimum of 8 characters,at least one numeric digit and a special character.',
        passwordModel: '',
        hasError: false,
        passwordRequired: false,
        driverName: '',
        last_name: '',
        isFetching:0,
        errorMessage:'',
        errorSaving: 0,
        errorContent: '',
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "Input is required.",
            v => !v || /^([\w]{3,})+\s+([\w\s]{3,})+$/i.test(v) || 'Invalid Full Name'
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 25,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        emailRules:[
            (v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        numberRules: [
            (v) => !!v || "Input is required.",
            (v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        driverEmailAddress: ''
    }),
    computed: {
        ...mapGetters({
        }),
        formTitle () {
            return this.editedIndexData === -1 ? 'Add Driver' : 'Edit Driver'
        },
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        }
    },
    methods: {
        ...mapActions({
        }),
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        fullnameValidate(val){
            if(val !=''){
               if (!val.match(/^[a-z\d\-_\s]+$/i))
                  return 'Invalid Name'
            }else
              return 'This field is required'
            return true;
        },
        passwordRules(inputtxt){
            if(inputtxt==''){
                this.hasError = true;
                this.passwordRequired = true;
                return 'Password is required.';
            } else if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(inputtxt) ){
                this.hasError = false;
                return true;
            }else {
                this.hasError = true;
                this.passwordRequired = false;
                return true;
            }
        },
        close() {
            this.$refs.form.resetValidation()
            this.driverName  = '';
            this.last_name = '';
            this.editedItem.phone_number = '';
            this.driverEmailAddress = '';
            this.passwordModel = '';
            this.passwordRequired =  false;
            this.hasError = false;
            this.$emit('update:dialogData', false)
        },
        async save() {
            this.errorSaving = 0;

            this.$refs.form.validate()

            this.editedIndexData = -1;

            setTimeout(async () => {
                if (this.$refs.form.validate()) {
                        try {
                            if (this.editedIndexData === -1) {
                               await this.adddriver(this.driverEmailAddress)
                            } else {
                               this.close()
                            }
                        } catch (e) {
                            this.notificationError(e)
                        }
                }
            }, 200)
        },
       async adddriver(email){
            var dis = this;
            let formData = new FormData(document.getElementById('createdriver'))
                formData.append('name', this.driverName)
                formData.append('last_name', this.last_name)
                formData.append('email',email);
                formData.append('phone_number',this.editedItem.phone_number)

            this.isFetching = 1;
            this.errorMessage = '';
            axios.post(`${APIBaseUrl}/createDriver`,formData)
            .then((response) => {

                return response.data

            }).catch(error => {

                if(typeof error.error.email !="undefined"){
                    this.errorMessage = error.error.email[0]
                } else{
                   this.errorMessage = error.msg
                }

            }).then(response => {

                dis.startloading = 0;
                dis.errorSaving = 1;
                dis.errorContent = this.errorMessage

                if( dis.errorContent =='' && response ){
                    dis.$refs['form'].resetValidation()
                    dis.driverName = '';
                    this.last_name = '';
                    dis.editedItem.phone_number = '';
                    dis.driverEmailAddress = '';
                    dis.$emit('update:notifysavedparent',1);
                    dis.close()
                    dis.editedIndexData = -1
                    dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Driver has been added.</span>')
                }
                dis.isFetching = 0;
            })
        },
        setToDefault() {
            this.$emit('setToDefault')
        }
    },
    watch: {
    }

}
</script>

<style lang="scss">

@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/drivers/driverAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
