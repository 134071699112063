<template>
  <div>
    <v-dialog
      persistent
      v-model="showAddLegModal"
      max-width="1200px"
      content-class="leg-pop-up leg-modal-wrapper add"
      :retain-focus="false"
      scrollable
    >
      <v-card>        
        <div class="">
          <v-card-title>
            <!-- <div class="flex">
              <div>
                <span
                  class="text-h5 pl-3 pr-3"
                  style="padding-top: 5px !important; display: inline-block"
                >
                  Add Leg</span
                >
              </div>
              <div v-if="isCompleted">
                <span class="marked-as-arrived-badge"> Arrived </span>
              </div>
            </div> -->

            <div class="d-flex align-center">
            <span class="headline" style="font-size: 22px !important;">
              Add Leg
            </span>

            <div v-if="isCompleted" class="ml-2">
              <span class="marked-as-arrived-badge">
                Arrived
              </span>
            </div>
          </div>
          </v-card-title>
        </div>
        <v-card-text
          class="anl-input-group"
          style="caret-color: transparent; padding: 0"
        >
          <v-form id="addFormleg" ref="form" @submit.prevent>
            <div style="display: flex; justify-content: center">
              <div style="flex-basis: 32%; font-size: 10px" class="bg-gray px-5 py-4 anl-input-group-left">
                <!-- <div class="dropdown">
                <div style="color: #819fb2" class="pt-4 pb-1 label">Driver</div>
                <v-autocomplete
                  class=""
                  v-model="selectedDriver"
                  :items="driverList"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Driver"
                  solo
                  append-icon="ic-chevron-down"
                  :rules="requiredRules"
                ></v-autocomplete>
              </div> -->
                <div style="display: flex" class="mb-2">
                  <div style="flex-basis: 58%; margin-right: 8px">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="label">
                        Driver
                      </div>
                      <v-autocomplete
                        class=""
                        v-model="selectedDriver"
                        :items="driverList"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Driver"
                        solo
                        append-icon="ic-chevron-down"
                        :rules="requiredRules"
                      >
                        <template slot="item" slot-scope="{ parent, item }">
                          <span class="">
                            <!-- {{ parent.genFilteredText(item.name+" "+(item.last_name !== null ? item.last_name : "")) }} -->
                            {{ item.name }}
                            <span
                              v-if="getLinkActive(item)"
                              class="ml-3 warning--text driverStatus"
                              >{{
                                parent.genFilteredText(getStatus(item))
                              }}</span
                            >
                            <span
                              v-if="getLinkActive(item)"
                              class="ml-3 mr-5 label activeRef"
                            >
                              {{ parent.genFilteredText(getRef(item)) }}
                              <span
                                class="pl-1 linkIcon"
                                @click="handleShipmentLink(getShipment(item))"
                              >
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.5 9.5H2.5V2.5H6V1.5H2.5C1.945 1.5 1.5 1.95 1.5 2.5V9.5C1.5 10.05 1.945 10.5 2.5 10.5H9.5C10.05 10.5 10.5 10.05 10.5 9.5V6H9.5V9.5ZM7 1.5V2.5H8.795L3.88 7.415L4.585 8.12L9.5 3.205V5H10.5V1.5H7Z"
                                    fill="#0171A1"
                                  />
                                </svg>
                              </span>
                            </span>
                          </span>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                  <div style="flex-basis: 42%;">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="label">
                        Status
                      </div>
                      <v-autocomplete
                        class=""
                        v-model="driverstatus"
                        :items="driverListstatus"
                        item-text="name"
                        item-value="name"
                        placeholder="Driver Staus"
                        solo
                        append-icon="ic-chevron-down"
                        :rules="requiredRules"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div style="display: flex" class="pb-1">
                  <div style="flex-basis: 58%; margin-right: 8px">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="pb-1 label">
                        Type of delivery
                      </div>
                      <v-autocomplete
                        class=""
                        :rules="[
                          ...requiredRules,
                          generalRules(selectedType).typeRule,
                        ]"
                        :items="typeDropdown"
                        item-text="label"
                        item-value="value"
                        placeholder="Select Type"
                        solo
                        append-icon="ic-chevron-down"
                        v-model="selectedType"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div style="flex-basis: 42%;">
                    <div class="dropdown">
                      <div style="color: #819fb2" class="pb-1 label">Mode</div>
                      <v-autocomplete
                        class=""
                        :items="modedropdown"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Mode"
                        solo
                        append-icon="ic-chevron-down"
                        v-model="selectedMode"
                        :rules="requiredRules"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="dropdown">
                  <div style="color: #819fb2" class="pb-1 label">Chassis</div>
                  <v-autocomplete
                    ref="chassisDrop"
                    class=""
                    v-model="selectedChassis"
                    :items="chassisList"
                    item-text="name"
                    item-value="id"
                    placeholder="Select Chassis"
                    solo
                    append-icon="ic-chevron-down"
                    :search-input.sync="getSearchedChassis"
                  >
                    <div slot="no-data"></div>
                    <div
                      v-if="hasNewChassis"
                      class="v-list-item v-list-item__title add-option-btn py-2"
                      slot="append-item"
                      @click="handleNewChassis"
                      style="font-size: 14px;"
                    >
                      <!-- + Add New &nbsp;<strong>{{ newChassis }}</strong> -->
                      <span class="pr-1" style="color: #0171A1;">+ Add New</span>&nbsp;<strong style="color: #4a4a4a;">{{ newChassis }}</strong>
                    </div>
                  </v-autocomplete>
                </div>
                <div class="readonly-textarea pr-0">
                  <div style="color: #819fb2" class="pb-1 label">
                    Pickup Location
                  </div>
                  <v-textarea
                  class="pickup-location-field"
                    style="font-size: 12px !important"
                    solo
                    label="Pickup location"
                    rows="2"
                    :value="pickupLocation"
                    readonly
                    no-resize
                  ></v-textarea>
                </div>
                <div class="dropdown pb-2">
                  <div style="color: #819fb2" class="pb-1 label">
                    Delivery location
                  </div>
                  <v-autocomplete
                    :loading="loadingLocationPlaces"
                    v-model="address"
                    :items="placesAddLeg"
                    label="Delivery Location"
                    :search-input.sync="searchPlace"
                    item-text="place_name"
                    no-filter
                    clearable
                    hide-no-data
                    @click:clear="clearSearchPlaces"
                    :rules="requiredRules"
                    full-width
                    @change="changeDeliveryLocationState"
                    solo
                    append-icon="ic-chevron-down"
                    class="delivery-location-field"
                  >

                    <template slot="selection" slot-scope="{ item }">
                      <div class="pt-1" style="color: #4a4a4a; font-size: 14px; line-height: 20px; width: 100%;">
                        {{ item.place_name }}
                      </div> 
                    </template>

                    <!-- <template v-slot:item="data" class="v-list-item__title">
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      {{ data.item.place_name }}
                    </div>
                  </div>
                </template> -->
                  </v-autocomplete>
                  <!-- <v-combobox
                  :loading="loadingLocationPlaces"
                  v-model="address"
                  :items="places"
                  label="Delivery Location"
                  :search-input.sync="searchPlace"
                  item-text="place_name"
                  clearable
                  @click:clear="clearSearchPlaces"
                  :rules="requiredRules"
                  full-width
                  solo
                ></v-combobox> -->
                  <!-- @click="clearSearchPlaces" -->
                  <!-- <v-textarea
                          style="font-size: 12px!important; "
                          solo
                          label="Delivery location"
                          rows="3"
                          v-model="address"
                          :rules="requiredRules"
                        ></v-textarea> -->
                </div>
                <div class="driver-notes dropdown pb-2">
                  <div style="color: #819fb2" class="label">
                    Driver Instructions (optional)
                  </div>
                  <v-textarea
                    background-color="white"
                    v-model="driver_instructions"
                    placeholder="Driver Instructions"
                    class="driver-instructions-field"
                    rows="2"
                    outlined
                    hide-details="auto"
                  >
                  </v-textarea>
                </div>
                <div class="checkbox-div" v-if="false">
                  <div class="delivery-order-check">
                    <div class="label custom-label">
                      Driver can see Delivery Order
                    </div>
                    <div>
                      <v-checkbox v-model="driverCanSeeDeliveryOrder" />
                    </div>
                  </div>
                  <div class="delivery-order-check">
                    <div class="label custom-label">POD Required</div>
                    <div>
                      <v-checkbox v-model="pod_required" />
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex-basis: 68%;" class="flex flex-column">
                <div
                  style="flex-basis: 65%; display: flex"
                  class="anl-input-group-right"
                >
                  <div
                    style="flex-basis: 50%; border-right: 1px solid #ebf2f5"
                    class="box"
                  >
                    <h3 style="font-size: 20px !important; font-family: 'Inter-Medium', sans-serif !important;">Pickup</h3>
                    <div class="datetime-field-groups">
                      <div style="color: #819fb2" class="pt-3 label">
                        pickup Scheduled
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="pickup_scheduled_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'pickup_scheduled_date')"
                            @emptied="(e) => handlePickedDate(e, 'pickup_scheduled_date')"
                            ref="pickup_scheduled_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'pickup_scheduled_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="pdt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handlePickupTime"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          placeholder="Select Time"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <!-- custom error text wrapper without the error should always show as we don't want a chappy form -->
                      <div class="custom-error-text"> <!-- set height here in style to have space for showing error  -->
                        <div v-show="showError">
                          <span v-show="hasError('pickup_scheduled_date')">
                            {{ typeof dateErrors["pickup_scheduled_date"] !== 'undefined' ? dateErrors["pickup_scheduled_date"].message : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="datetime-field-groups"
                      v-if="showArrivalAtPickup"
                    >
                      <div style="color: #819fb2" class="pt-2 label d-flex justify-space-between align-center">
                        Arrival at Pickup

                        <button type="button" :disabled="showOutgateFromPickup" icon dark class="btn-close ml-2" @click="removeField('showArrivalAtPickup')">
                          <v-icon size="18px" class="red-color">mdi-close</v-icon>
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="arrival_at_pickup_location_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'arrival_at_pickup_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'arrival_at_pickup_location_date')"
                            ref="arrival_at_pickup_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'arrival_at_pickup_location_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="apdt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handleArrivalPickupTime"
                          placeholder="Select Time"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-if="hasError('arrival_at_pickup_location_date')">
                            {{ typeof dateErrors["arrival_at_pickup_location_date"] !== 'undefined' ? dateErrors["arrival_at_pickup_location_date"].message : '' }}
                          </span>
                        </div>
                      </div>                      
                    </div>
                    <div
                      class="datetime-field-groups"
                      v-if="showOutgateFromPickup"
                    >
                      <div style="color: #819fb2" class="pt-2 label d-flex justify-space-between align-center">
                        Outgate From Pickup

                        <button type="button" icon dark class="btn-close ml-2" @click="removeField('showOutgateFromPickup')">
                          <v-icon size="18px" class="red-color">mdi-close</v-icon>
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="departure_from_pickup_location_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'departure_from_pickup_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'departure_from_pickup_location_date')"
                            ref="departure_from_pickup_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'departure_from_pickup_location_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="ofpdt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handleOutgatePickupTime"
                          placeholder="Select Time"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError" >
                          <span v-show="hasError('departure_from_pickup_location_date')">
                            {{ typeof dateErrors["departure_from_pickup_location_date"] !== 'undefined' ? dateErrors["departure_from_pickup_location_date"].message : '' }}
                          </span>
                        </div>
                      </div>                      
                    </div>
                    <!-- <div
                      class="datetime-field-groups"
                      v-if="showOutgateFromPickup"
                    >
                      <div style="color: #819fb2" class="pt-2 label">
                        Time at pickup
                      </div>
                      <input
                        class="readonly-text-field"
                        :value="getTimeAtPickup"
                        disabled
                      />
                    </div> -->
                    <v-btn
                      v-if="!showOutgateFromPickup"
                      text
                      color="#0171A1"
                      class="button-outline btn-white"
                      style="width: 100%; margin-top: 20px"
                      @click="addPickup"
                    >
                      <span style="font-size: 12px" class="text-uppercase">
                        + {{ addPickupButtonText }}
                      </span>
                    </v-btn>
                  </div>
                  <div
                    style="flex-basis: 50%; border-left: 1px solid #ebf2f5"
                    class="box"
                  >
                    <h3 style="font-size: 20px !important; font-family: 'Inter-Medium', sans-serif !important;">Delivery</h3>
                    <div class="datetime-field-groups">
                      <div style="color: #819fb2" class="pt-3 label">
                        Delivery Scheduled
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="delivery_scheduled_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'delivery_scheduled_date')"
                            @emptied="(e) => handlePickedDate(e, 'delivery_scheduled_date')"
                            ref="delivery_scheduled_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'delivery_scheduled_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="ddt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handleDeliveryTime"
                          placeholder="Select Time"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('delivery_scheduled_date')">
                            {{ typeof dateErrors["delivery_scheduled_date"] !== 'undefined' ? dateErrors["delivery_scheduled_date"].message : '' }}
                          </span>
                        </div>
                      </div>                      
                    </div>
                    <div
                      class="datetime-field-groups"
                      v-if="showArrivalAtDelivery"
                    >
                      <div style="color: #819fb2" class="pt-2 label d-flex justify-space-between align-center">
                        Arrival at Delivery

                        <button type="button" :disabled="showOutgateFromDelivery" icon dark class="btn-close ml-2" @click="removeField('showArrivalAtDelivery')">
                          <v-icon size="18px" class="red-color">mdi-close</v-icon>
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="arrival_at_delivery_location_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'arrival_at_delivery_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'arrival_at_delivery_location_date')"
                            ref="arrival_at_delivery_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'arrival_at_delivery_location_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="addt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handleArrivalDeliveryTime"
                          placeholder="Select Time"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError">
                          <span v-show="hasError('arrival_at_delivery_location_date')">
                            {{ typeof dateErrors["arrival_at_delivery_location_date"] !== 'undefined' ? dateErrors["arrival_at_delivery_location_date"].message : '' }}
                          </span>
                        </div>
                      </div>                      
                    </div>
                    <div
                      class="datetime-field-groups"
                      v-if="showOutgateFromDelivery"
                    >
                      <div style="color: #819fb2" class="pt-2 label d-flex justify-space-between align-center">
                        Outgate From Delivery

                        <button type="button" icon dark class="btn-close ml-2" @click="removeField('showOutgateFromDelivery')">
                          <v-icon size="18px" class="red-color">mdi-close</v-icon>
                        </button>
                      </div>
                      <div style="display: flex">
                        <div class="vc-date-picker__wrapper">
                          <vc-date-picker
                            title-position="left"
                            is-expanded
                            v-model="departure_from_delivery_location_date"
                            mode="date"
                            :popover="{ visibility: 'click' }"
                            :firstDayOfWeek="2"
                            :masks="masks"
                            :select-attribute="attribute"
                            @input="(e) => handlePickedDate(e, 'departure_from_delivery_location_date')"
                            @emptied="(e) => handlePickedDate(e, 'departure_from_delivery_location_date')"
                            ref="departure_from_delivery_location_date">

                            <template #default="{ inputValue, inputEvents }">
                              <input
                                :value="inputValue"
                                v-on="inputEvents"
                                class="leg-date-field"
                                placeholder="Select Date"
                                @keyup="(e) => checkKeyUpVal(e, 'departure_from_delivery_location_date')"
                              />
                              <i class="append-icon ic-calendar"></i>
                            </template>
                          </vc-date-picker>
                        </div>
                        <DxDateBox
                          v-model="dddt"
                          type="time"
                          class="date-field-dxtime"
                          @value-changed="handleDepartureDeliveryTime"
                          placeholder="Select Time"
                          :use-mask-behavior="true"
                          display-format="hh:mm a"
                          :openOnFieldClick="true"
                        />
                      </div>
                      <div class="custom-error-text">
                        <div v-show="showError" >
                          <span v-show="hasError('departure_from_delivery_location_date')">
                            {{ typeof dateErrors["departure_from_delivery_location_date"] !== 'undefined' ? dateErrors["departure_from_delivery_location_date"].message : '' }}
                          </span>
                        </div>
                      </div>                      
                    </div>
                    <!-- <div
                      class="datetime-field-groups"
                      v-if="showOutgateFromDelivery"
                    >
                      <div style="color: #819fb2" class="pt-2 label">
                        Time at Delivery
                      </div>
                      <input
                        class="readonly-text-field"
                        :value="getTimeAtDelivery"
                        disabled
                      />
                    </div> -->
                    <v-btn
                      v-if="!showOutgateFromDelivery"
                      text
                      color="#0171A1"
                      class="button-outline btn-white"
                      style="width: 100%; margin-top: 20px"
                      @click="addDelivery"
                    >
                      <span style="font-size: 12px" class="text-uppercase">
                        + {{ addDeliveryButtonText }}
                      </span>
                    </v-btn>
                  </div>
                </div>
                <div
                  style="flex-basis: 35%; line-height: 15px; width: 100%;"
                  class="align-end bg-gray pt-4 pb-2">                 

                  <!-- <div class="flex pt-3 label" style="color: #819fb2">
                    <div class="flex flex-column" style="min-width: 33.33%">
                      <div class="flex pr-4 pb-3">
                        <span>ETA</span>
                        <span class="px-2 text--secondary">{{ getETA() }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span>Last Free Date</span>
                        <span class="px-2 text--secondary">{{ getLFD() }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span>Per Diem Date</span>
                        <span class="px-2 text--secondary">{{ getPDD() }}</span>
                      </div>
                    </div>
                    <div class="flex flex-column" style="min-width: 33.33%">
                      <div class="flex pr-4 pb-3">
                        <span>Customer</span>
                        <span class="px-2 text--secondary">{{
                          getCustomer()
                        }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span>Delivery To</span>
                        <span class="px-2 text--secondary">{{
                          getDeliveryTo()
                        }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span style="min-width: 34%">Terminal at</span>
                        <span class="text--secondary">{{ getTerminal() }}</span>
                      </div>
                    </div>
                    <div class="flex flex-column" style="min-width: 33.33%">
                      <div class="flex pr-4 pb-3">
                        <span>Cont. Size & Weight</span>
                        <span class="px-2 text--secondary">{{
                          getContSizeandWeight()
                        }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span>Container #</span>
                        <span class="px-2 text--secondary">{{
                          getContainerNo()
                        }}</span>
                      </div>
                      <div class="flex pr-4 pb-3">
                        <span>MBL & Carrier</span>
                        <span class="px-2 text--secondary">{{
                          getMBLandCarrier()
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex pr-4 pb-3" style="max-width: 700px">
                    <span class="text-item-label">Notes</span>
                    <span class="label px-2 text--secondary">{{
                      getNotes()
                    }}</span>
                  </div> -->
                  <div class="details-wrapper d-flex">
                    <div style="width: 35%;" class="details-left-column px-3">
                      <div class="details-content">
                        <div class="details-title">Container #</div>
                        <div class="details-data">{{ getContainerNo() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Cont. Size</div>
                        <div class="details-data">{{ getContSizeandWeight('size') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Cont. Weight</div>
                        <div class="details-data">{{ getContSizeandWeight('weight') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">MBL</div>
                        <div class="details-data">{{ getMBLandCarrier('mbl') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Carrier</div>
                        <div class="details-data">{{ getMBLandCarrier('carrier') }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">ETA</div>
                        <div class="details-data">{{ getETA() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">LFD - Pickup</div>
                        <div class="details-data">{{ getLFD() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">LFD - Return</div>
                        <div class="details-data">{{ getPDD() }}</div>
                      </div>
                    </div>

                    <div style="width: 65%;" class="details-right-column px-3">
                      <div class="details-content">
                        <div class="details-title">Time at pickup</div>
                        <div class="details-data">{{ getTimeAtPickup }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Time at Delivery</div>
                        <div class="details-data">{{ getTimeAtDelivery }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Customer</div>
                        <div class="details-data">{{ getCustomer() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Delivery To</div>
                        <div class="details-data">{{ getDeliveryTo() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Terminal at</div>
                        <div class="details-data">{{ getTerminal() }}</div>
                      </div>
                      <div class="details-content">
                        <div class="details-title">Notes</div>
                        <div class="details-data">{{ getNotes() }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <!-- // actions  -->
        <v-card-actions>
          <v-btn @click="add" class="btn-blue">
            <span>
              <span>
                <!-- <v-progress-circular
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        v-if="isFetching"
                        style="margin-right:3px"
                        >
                        </v-progress-circular> -->
                Ok
              </span>
            </span>
          </v-btn>
          <v-btn
            style="margin-left: 0px !important"
            class="btn-white"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-actions>        
      </v-card>
    </v-dialog>
    <CreatePicker
      :dialogDatepicker="dialogDatepicker"
      :defaultData="pickerDefaultdata"
      @passbackdata="getdata"
      @close="closeDialogpicker"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import _ from "lodash";
import moment from "moment";
import CreatePicker from "../../../Createpicker.vue";
import DxDateBox from 'devextreme-vue/date-box';
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  name: "AddNewLeg",
  props: [
    "showAddLegModal",
    "driverList",
    "chassisList",
    "modedropdown",
    "typeDropdown",
    // "pickupLocation",
    "getObject",
    "bus",
    "lastLeg",
    "dialogData",
    "triggeredData",
    "responseChassis",
    "responseObjectMeta",
    "eta",
    "dispatchData",
  ],
  components: {
    CreatePicker,
    DxDateBox
  },
  data() {
    return {
      callApiForDeliveryLoc: true,
      driver_instructions: "",
      driverCanSeeDeliveryOrder: true,
      driverstatus: "Pending",
      driverListstatus: ["Pending", "Accepted", "Declined"],
      pickerDefaultdata: "",
      currentstatepicker: "",
      dialogDatepicker: false,
      pod_required: true,
      searchDeliveryPlace: "",
      searchPlace: "",
      placesAddLeg: [],
      loadingLocationPlaces: false,
      isClear: false,
      selectedDriver: 0,
      modal_ps_date: false,
      pickup_scheduled_date: "",
      modal_ps_time: false,
      pickup_scheduled_time: null,
      modal_ds_date: false,
      delivery_scheduled_date: "",
      modal_ds_time: false,
      delivery_scheduled_time: null,
      modal_aapld: false,
      arrival_at_pickup_location_date: "",
      modal_aaplt: false,
      arrival_at_pickup_location_time: null,
      modal_dfpld: false,
      departure_from_pickup_location_date: "",
      modal_dfplt: false,
      departure_from_pickup_location_time: null,
      modal_aadld: false,
      arrival_at_delivery_location_date: "",
      modal_aadlt: false,
      arrival_at_delivery_location_time: null,
      modal_dfdld: false,
      departure_from_delivery_location_date: "",
      modal_dfdlt: false,
      departure_from_delivery_location_time: null,
      address: "",
      selectedMode: "",
      selectedType: "",
      requiredRules: [(v) => !!v || v === 0 || "This field is required"],
      errors: {
        pickup_scheduled: "",
        delivery_scheduled: "",
        pickup_outgate: "",
        delivery_outgate: "",
        delivery_arrival: "",
      },
      custom_error_show: false,
      selectedChassis: "",
      selectedChassis2: 0,
      newChassis: "",
      hasNewChassis: 0,
      showArrivalAtPickup: false,
      showOutgateFromPickup: false,
      showArrivalAtDelivery: false,
      showOutgateFromDelivery: false,
      attribute: {
            highlight: {
                style: {
                    backgroundColor: '#0171A1', // blue
                    borderRadius: '4px'
                },
                contentStyle: {
                    color: '#ffffff', // color of the text
                    borderRadius: '4px'
                }
            },
        },
      masks: {
        input: 'MM/DD/YY',
        weekdays: "WWW"
      },
      dateErrors: {},
      showError: false,
      submitting: "",
      justAddShowArrivalPickup: false,
      justAddShowOutgatePickup: false,
      justAddShowArrivalAtDelivery: false,
      justAddShowOutgateDelivery: false,
      pickupLocation: ""
    };
  },
  watch: {
    pickup_scheduled_date(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
      if (c && this.validateDate(c)) {
        if (_.isEmpty(this.delivery_scheduled_date))
          this.delivery_scheduled_date = c;
        if (
          _.isEmpty(this.arrival_at_pickup_location_date) &&
          this.showArrivalAtPickup
        )
          this.arrival_at_pickup_location_date = c;
        if (
          _.isEmpty(this.departure_from_pickup_location_date) &&
          this.showOutgateFromPickup
        )
          this.departure_from_pickup_location_date = c;
      }
      let eta = moment(this.eta)
      let adt = this.arrival_at_pickup_location_date !== "" ? moment(this.arrival_at_pickup_location_date) : ""
      let pdt = moment(c)
      if(this.showArrivalAtPickup) {
        if(adt !== "") {
          if(adt.isBefore(pdt) && pdt.isAfter(eta)) {
            this.arrival_at_pickup_location_date = c
          }
        }
      }
      this.handlePickedDate(c, 'pickup_scheduled_date')
    },
    delivery_scheduled_date(c) {
      if (c && this.validateDate(c)) {
        if (_.isEmpty(this.pickup_scheduled_date))
          this.pickup_scheduled_date = c;
        if (
          _.isEmpty(this.arrival_at_delivery_location_date) &&
          this.showArrivalAtDelivery
        )
          this.arrival_at_delivery_location_date = c;
        if (
          _.isEmpty(this.departure_from_delivery_location_date) &&
          this.showOutgateFromDelivery
        )
          this.departure_from_delivery_location_date = c;
      }
      let eta = moment(this.eta)
      let ddt = this.departure_from_pickup_location_date !== "" ? moment(this.departure_from_pickup_location_date) : ""
      let adt = moment(c)
      if(this.showOutgateFromPickup) {
        if(ddt !== "") {
          if(ddt.isBefore(adt) && adt.isAfter(eta)) {
            this.departure_from_pickup_location_date = c
          }
        }
      }
      this.handlePickedDate(c, 'delivery_scheduled_date')
    },
    arrival_at_pickup_location_date(c) {
      let eta = moment(this.eta)
      let ddt = this.departure_from_pickup_location_date !== "" ? moment(this.departure_from_pickup_location_date) : ""
      let adt = moment(c)
      if(this.showOutgateFromPickup) {
        if(ddt !== "") {
          if(ddt.isBefore(adt) && adt.isAfter(eta)) {
            this.departure_from_pickup_location_date = c
          }
        }
      }
      this.handlePickedDate(c, 'arrival_at_pickup_location_date')
    },
    departure_from_pickup_location_date(c) {
      this.handlePickedDate(c, 'departure_from_pickup_location_date')
    },
    arrival_at_delivery_location_date(c) {
      this.handlePickedDate(c, 'arrival_at_delivery_location_date')
    },
    departure_from_delivery_location_date(c) {
      this.handlePickedDate(c, 'departure_from_delivery_location_date')
    },
    pickup_scheduled_time(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
    },
    delivery_scheduled_time(c) {
      if (c && !this.custom_error_show) this.custom_error_show = true;
    },
    showAddLegModal(c) {
      if (c) {
        this.clearElement();
        //this.$refs.form.reset();
        this.selectedDriver = 0;
        if (this.triggeredData) {
          this.selectedChassis = this.dialogData.chassis;
        } else {
          if (this.dialogData !== undefined) {
            var parse = JSON.parse(this.dialogData);
            if (parse["legs"].length > 0) {
              var lastitem = parse["legs"].pop();
              this.selectedChassis = lastitem.chassis;
            }
          }
        }
        if (this.lastLeg && this.lastLeg.type == "Customer") {
          this.selectedType = "Empty Return";
          this.selectedMode = 1;
        }
        // console.log(this.lastLeg)
      }
    },
    responseChassis: function (val) {
      this.selectedChassis = val.id;
      this.hasNewChassis = 0;
      this.$refs.chassisDrop.blur();
    },
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
    showEditLegModal(c) {
      if (c) {
        //this.etaifEmpty = this.getETA()
        if (typeof this.driverstatus == "undefined") {
          this.driverstatus = "Pending";
        }
      }
    },
    /*
		  If changed then reset to Pending
		 */
    selectedDriver(newval) {
      if (newval) {
        this.driverstatus = "Pending";
      }
    },
    selectedType(newval) {
      if (_.isEmpty(this.address)) {
        this.setInitAddressDropDown(newval);
      }
      if (newval == "Empty Return") {
        this.pod_required = false;
      } else {
        this.pod_required = true;
      }
    },
    dateErrors: {
      handler(nv) {
        console.log('errors >>>', nv)
      },
      deep: true
    },
    submitting: {
      handler() {
        this.showError = true
      },
      deep: true
    },
    pickupLocation(v) {
      console.log('pickup location >>>', v)
      if(typeof this.responseObjectMeta[0]?.deliver_to !== "undefined") {
        this.address = this.responseObjectMeta[0]?.deliver_to 
        this.searchPlace = this.address
      }
    },
  },
  computed: {
    ...mapGetters({
      getYards: "common/getYards",
    }),
    getTimeAtPickup() {
      if (
        this.arrival_at_pickup_location_date &&
        this.departure_from_pickup_location_date &&
        this.validateDate(this.arrival_at_pickup_location_date) &&
        this.validateDate(this.departure_from_pickup_location_date)
      ) {
        let pickup_arrival_ms = 0;
        let pickup_departure_ms = 0;

        let arrivalConvertTime = moment(
          this.arrival_at_pickup_location_time,
          "hh:mm A"
        ).format("HH:mm");
        let departureConvertTime = moment(
          this.departure_from_pickup_location_time,
          "hh:mm A"
        ).format("HH:mm");

        if (
          arrivalConvertTime &&
          moment(arrivalConvertTime, "HH:mm", true).isValid()
        ) {
          pickup_arrival_ms =
            Number(arrivalConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(arrivalConvertTime.split(":")[1]) * 60 * 1000;
        }
        if (
          departureConvertTime &&
          moment(departureConvertTime, "HH:mm", true).isValid()
        ) {
          pickup_departure_ms =
            Number(departureConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(departureConvertTime.split(":")[1]) * 60 * 1000;
        }
        let date_pickup_arrival = new Date(
          this.arrival_at_pickup_location_date
        );
        date_pickup_arrival.setHours(0, 0, 0);
        let date_pickup_departure = new Date(
          this.departure_from_pickup_location_date
        );
        date_pickup_departure.setHours(0, 0, 0);
        pickup_arrival_ms += date_pickup_arrival.getTime();
        pickup_departure_ms += date_pickup_departure.getTime();
        let diff =
          Math.abs(pickup_arrival_ms - pickup_departure_ms) / (1000 * 60);
        return parseInt(diff / 60) + "hrs " + (diff % 60) + "mins ";
      } else {
        return "--";
      }
    },
    getTimeAtDelivery() {
      if (
        this.arrival_at_delivery_location_date &&
        this.departure_from_delivery_location_date &&
        this.validateDate(this.arrival_at_delivery_location_date) &&
        this.validateDate(this.departure_from_delivery_location_date)
      ) {
        let delivery_arrival_ms = 0;
        let delivery_departure_ms = 0;

        let arrivalConvertTime = moment(
          this.arrival_at_delivery_location_time,
          "hh:mm A"
        ).format("HH:mm");
        let departureConvertTime = moment(
          this.departure_from_delivery_location_time,
          "hh:mm A"
        ).format("HH:mm");

        if (
          arrivalConvertTime &&
          moment(arrivalConvertTime, "HH:mm", true).isValid()
        ) {
          delivery_arrival_ms =
            Number(arrivalConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(arrivalConvertTime.split(":")[1]) * 60 * 1000;
        }
        if (
          departureConvertTime &&
          moment(departureConvertTime, "HH:mm", true).isValid()
        ) {
          delivery_departure_ms =
            Number(departureConvertTime.split(":")[0]) * 60 * 60 * 1000 +
            Number(departureConvertTime.split(":")[1]) * 60 * 1000;
        }
        let date_delivery_arrival = new Date(
          this.arrival_at_delivery_location_date
        );
        date_delivery_arrival.setHours(0, 0, 0);
        let date_delivery_departure = new Date(
          this.departure_from_delivery_location_date
        );
        date_delivery_departure.setHours(0, 0, 0);
        delivery_arrival_ms += date_delivery_arrival.getTime();
        delivery_departure_ms += date_delivery_departure.getTime();
        let diff =
          Math.abs(delivery_arrival_ms - delivery_departure_ms) / (1000 * 60);
        return parseInt(diff / 60) + "hrs " + (diff % 60) + "mins ";
      } else {
        return "--";
      }
    },
    getSearchedChassis: {
      get() {
        return this.newChassis !== null
          ? this.newChassis.toString()
          : this.newChassis;
      },
      set(input) {
        let temp = [];
        this.chassisList.map((list) => {
          temp.push(list.name);
        });
        if (temp.includes(input)) {
          this.hasNewChassis = 0;
        } else if (this.newChassis === "" || this.newChassis === null) {
          this.hasNewChassis = 0;
        } else {
          this.hasNewChassis = 1;
        }
        this.newChassis = input;
      },
    },
    addPickupButtonText() {
      if (this.showArrivalAtPickup) return "Add Outgate From Pickup";
      else {
        return "Add Arrival at Pickup";
      }
    },
    addDeliveryButtonText() {
      if (this.showArrivalAtDelivery) {
        return "Add Outgate From Delivery";
      } else {
        return "Add Arrival at Delivery (Arrived)";
      }
    },
    isCompleted() {
      return this.arrival_at_delivery_location_date ? true : false;
    },
    pdt:{
      get(){
        if(this.pickup_scheduled_time !== "" && this.pickup_scheduled_time !== null && this.pickup_scheduled_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.pickup_scheduled_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
    apdt:{
      get() {
        if(this.arrival_at_pickup_location_time !== "" && this.arrival_at_pickup_location_time !== null && this.arrival_at_pickup_location_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.arrival_at_pickup_location_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
    ofpdt: {
      get(){
        if(this.departure_from_pickup_location_time !== "" && this.departure_from_pickup_location_time !== null && this.departure_from_pickup_location_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.departure_from_pickup_location_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
    ddt:{
      get()
      {
        if(this.delivery_scheduled_time !== "" && this.delivery_scheduled_time !== null && this.delivery_scheduled_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.delivery_scheduled_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
    addt: {
      get(){
        if(this.arrival_at_delivery_location_time !== "" && this.arrival_at_delivery_location_time !== null && this.arrival_at_delivery_location_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.arrival_at_delivery_location_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
    dddt: {
      get(){
        if(this.departure_from_delivery_location_time !== "" && this.departure_from_delivery_location_time !== null && this.departure_from_delivery_location_time !== "Invalid date") {
          return moment(moment().format('YYYY-MM-DD')+" "+this.departure_from_delivery_location_time)
        } else {
          // return moment()
          return null
        }
      },
      set(value) {
        if (value) {
          return moment(moment().format('YYYY-MM-DD')+" "+value)
        }
        return null
      }
    },
  },
  mounted() {
    if (this.getYards.length == 0) {
      this.fetchYards();
    }
    let data = {};
    if (this.isClear == false) {
      if (this.getObject !== null && Object.keys(this.getObject).length > 0) {
        Object.values(this.getObject).forEach(function (f) {
          console.log(f.pickup_scheduled_time, 'here');
          data = {
            driver: f.driver,
            chassis: f.selectedChassis,
            address: f.address,
            selectedMode: f.selectedMode,
            selectedType: f.selectedType,
            pickup_scheduled_date: f.pickup_scheduled_date,
            pickup_scheduled_time: f.pickup_scheduled_time,
            delivery_scheduled_date: f.delivery_scheduled_date,
            delivery_scheduled_time: f.delivery_scheduled_time,
          };
        });
        // this.address = data.address;
        // this.address = this.responseObjectMeta[0]?.deliver_to ?? "";
        this.selectedDriver = parseInt(data.driver);
        this.selectedChassis = parseInt(data.chassis);
        this.selectedMode = parseInt(data.selectedMode);
        this.selectedType = data.selectedType;
        this.pickup_scheduled_date = data.pickup_scheduled_date;
        this.pickup_scheduled_time = data.pickup_scheduled_time;
        this.delivery_scheduled_date = data.delivery_scheduled_date;
        this.delivery_scheduled_time = data.delivery_scheduled_time;
      } else {
        this.selectedDriver = 0;
      }
    }
  },
  updated() {
    // console.log(this.pdt);
    this.pickupLocation = this.getTerminal()
  },
  methods: {
    ...mapActions({
      fetchYards: "common/fetchYards",
    }),
    checkKeyUpVal(e, model) {
      if (e.key === 'Enter' || e.keyCode === 13) {
          this.$refs[model].hidePopover(); // close popover on click enter
      }
    },
    removeField(field) {
      if (field === "showArrivalAtPickup") {
        if (!this.showOutgateFromPickup) {
          this[field] = false
          this.arrival_at_pickup_location_date = ""
          this.arrival_at_pickup_location_time = null
          delete this.dateErrors.arrival_at_pickup_location_date
          delete this.dateErrors.arrival_at_pickup_location_time
        }

      } else if (field === "showOutgateFromPickup") {
        this[field] = false
        this.departure_from_pickup_location_date = ""
        this.departure_from_pickup_location_time = null
        delete this.dateErrors.departure_from_pickup_location_date
        delete this.dateErrors.departure_from_pickup_location_time

      } else if (field === "showArrivalAtDelivery") {
        if (!this.showOutgateFromDelivery) {
          this[field] = false
          this.arrival_at_delivery_location_date = ""
          this.arrival_at_delivery_location_time = null
          delete this.dateErrors.arrival_at_delivery_location_date
          delete this.dateErrors.arrival_at_delivery_location_time
        }

      } else if (field === "showOutgateFromDelivery") {
        this[field] = false
        this.departure_from_delivery_location_date = ""
        this.departure_from_delivery_location_time = null
        delete this.dateErrors.departure_from_delivery_location_date
        delete this.dateErrors.departure_from_delivery_location_time
      }
    },
    setInitAddressDropDown(val) {
      if (_.isEmpty(val)) {
        val = this.selectedType;
      }
      if (val == "Empty Return" && this.responseObjectMeta !== undefined) {
        let terminal = this.responseObjectMeta[0]?.terminal;
        if (terminal && terminal.latitude && terminal.longitude) {
          let address =
            (terminal.nickname ? terminal.nickname + " - " : "") +
            terminal.name +
            ` [${terminal.firms_code}], ` +
            terminal.address;
          this.placesAddLeg = [
            {
              terminal: terminal,
              place_name: address,
              geometry: {
                coordinates: [terminal.longitude, terminal.latitude],
              },
            },
          ];
        }
      } else if (val == "Yard") {
        this.placesAddLeg = this.getYards.map((yard) => {
          return {
            yard: yard,
            place_name: yard.name + " - " + yard.address,
            geometry: {
              coordinates: [yard.longitude, yard.latitude],
            },
          };
        });
      } else if (val == "Customer") {
        let shipment = this.responseObjectMeta[0];
        // console.log(shipment)
        if (
          shipment &&
          shipment.deliver_to &&
          shipment.delivery_latitude &&
          shipment.delivery_longitude
        ) {
          this.placesAddLeg = [
            {
              place_name: shipment.deliver_to,
              geometry: {
                coordinates: [
                  shipment.delivery_longitude,
                  shipment.delivery_latitude,
                ],
              },
            },
          ];
        }
      }
    },
    handleDateChange(value, key) {
      if (value) {
        let momentOb = moment(value);
        if (momentOb.isValid()) {
          if (momentOb.year() == "2001") momentOb.set("year", moment().year());
          this[key] = momentOb.format("Y-MM-DD");
        }
      }
    },
    handleTimeChange(value, key) {
      if (value) {
        // let momentOb = moment(value, "HH:mm");
        // if(momentOb.isValid()){
        // 		this[key] = momentOb.format('HH:mm');
        // }

        let momentOb = moment(value, "hh:mm A");
        if (momentOb.isValid()) {
          this[key] = momentOb.format("hh:mm A");
        } else if (value[0] && value[0] > 2) {
          value = "0" + value;
          momentOb = moment(value, "hh:mm A");
          if (momentOb.isValid()) {
            this[key] = momentOb.format("hh:mm A");
          }
        }
      }
    },
    dateRules(value, time = "", isTimeField = false) {
      let datecompare = value;
      time = time !== "" ? moment(time, "hh:mm A").format("HH:mm") : ""; // format to 24 hr
      value = _.isEmpty(value) ? value : time ? value + "T" + time : value;

      return {
        pickupScheduled: () => {
          let msg = "";
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_scheduled"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_scheduled"] = msg;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["pickup_scheduled"] = msg;
            return false;
          } else if (
            _.isEmpty(time) &&
            _.isEmpty(this.delivery_scheduled_time)
          ) {
            this.errors["pickup_scheduled"] =
              "At least one scheduled time is required";
            if (isTimeField) return "err";
            else return true;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "Pickup date should be later than ETA";
            this.errors["pickup_scheduled"] = msg;
          } else {
            this.errors["pickup_scheduled"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        deliveryScheduled: () => {
          let msg = "";
          let pickupDateTime = null;
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          if (this.pickup_scheduled_date) {
            pickupDateTime = this.pickup_scheduled_date;
            if (this.pickup_scheduled_time) {
              pickupDateTime +=
                "T" +
                moment(this.pickup_scheduled_time, "hh:mm A").format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_scheduled"] =
                "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_scheduled"] = msg;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_scheduled"] = msg;
          } else if (
            !_.isEmpty(pickupDateTime) &&
            !moment.utc(value).isAfter(moment.utc(pickupDateTime))
          ) {
            msg = "The Delivery cannot be before the Pickup";
            this.errors["delivery_scheduled"] = msg;
          } else if (_.isEmpty(time) && _.isEmpty(this.pickup_scheduled_time)) {
            this.errors["delivery_scheduled"] =
              "At least one scheduled time is required";
            if (isTimeField) return "err";
            else return true;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "The Delivery should be later than ETA";
            this.errors["delivery_scheduled"] = msg;
          } else {
            this.errors["delivery_scheduled"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        pickupOutgate: () => {
          let msg = "";
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          let pickupArrival = "";
          if (this.arrival_at_pickup_location_date) {
            pickupArrival = this.arrival_at_pickup_location_date;
            if (this.arrival_at_pickup_location_time) {
              pickupArrival +=
                "T" +
                moment(this.arrival_at_pickup_location_time, "hh:mm A").format(
                  "HH:mm"
                );
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_outgate"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_outgate"] = msg;
          } else if (_.isEmpty(pickupArrival)) {
            this.errors["pickup_outgate"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["pickup_outgate"] = msg;
          } else if (moment.utc(value).isBefore(moment.utc(pickupArrival))) {
            msg =
              "The Outgate from Pickup can't be earlier than the Arrival at Pickup";
            this.errors["pickup_outgate"] = msg;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "The Pickup Outgate should be later than ETA";
            this.errors["pickup_outgate"] = msg;
          } else {
            this.errors["pickup_outgate"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        deliveryOutgate: () => {
          let msg = "";
          if (_.isEmpty(time) && value && !value.includes("T")) {
            value += "T23:59";
          }
          let deliveryArrival = "";
          if (this.arrival_at_delivery_location_date) {
            deliveryArrival = this.arrival_at_delivery_location_date;
            if (this.arrival_at_delivery_location_time) {
              deliveryArrival +=
                "T" +
                moment(
                  this.arrival_at_delivery_location_time,
                  "hh:mm A"
                ).format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_outgate"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_outgate"] = msg;
          } else if (_.isEmpty(deliveryArrival)) {
            this.errors["delivery_outgate"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_outgate"] = msg;
          } else if (moment.utc(value).isBefore(moment.utc(deliveryArrival))) {
            msg =
              "The Outgate from Delivery can't be earlier than the Arrival at Delivery";
            this.errors["delivery_outgate"] = msg;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "The Outgate from Delivery should be later than ETA";
            this.errors["delivery_outgate"] = msg;
          } else {
            this.errors["delivery_outgate"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        arrivalDelivery: () => {
          let msg = "";
          let pickupArrival = "";
          let pickupOutgate = "";
          if (this.arrival_at_pickup_location_date) {
            pickupArrival = this.arrival_at_pickup_location_date;
            if (this.arrival_at_pickup_location_time) {
              pickupArrival +=
                "T" +
                moment(this.arrival_at_pickup_location_time, "hh:mm A").format(
                  "HH:mm"
                );
            }
          }
          if (this.departure_from_pickup_location_date) {
            pickupOutgate = this.departure_from_pickup_location_date;
            if (this.departure_from_pickup_location_time) {
              pickupOutgate +=
                "T" +
                moment(
                  this.departure_from_pickup_location_time,
                  "hh:mm A"
                ).format("HH:mm");
            }
          }
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["delivery_arrival"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["delivery_arrival"] = msg;
          } else if (_.isEmpty(pickupOutgate) && _.isEmpty(pickupArrival)) {
            this.errors["delivery_arrival"] = "";
            return true;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["delivery_arrival"] = msg;
          } else if (
            !_.isEmpty(pickupOutgate) &&
            (moment.utc(pickupOutgate).isAfter(moment.utc(value)) ||
              moment.utc(pickupOutgate).isSame(moment.utc(value)))
          ) {
            msg =
              "The Arrival at Delivery should be later than the Outgate from Pickup";
            this.errors["delivery_arrival"] = msg;
          } else if (
            !_.isEmpty(pickupArrival) &&
            (moment.utc(pickupArrival).isAfter(moment.utc(value)) ||
              moment.utc(pickupArrival).isSame(moment.utc(value)))
          ) {
            msg =
              "The Arrival at Delivery should be later than the Arrival at Pickup";
            this.errors["delivery_arrival"] = msg;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "The Arrival at Delivery should be later than ETA";
            this.errors["delivery_arrival"] = msg;
          } else {
            this.errors["delivery_arrival"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
        arrivalPickup: () => {
          let msg = "";
          if (!_.isEmpty(time)) {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              //
            } else {
              msg = this.errors["pickup_arrival"] = "Invalid Time ex. HH:mm";
              return msg;
            }
          }
          if (_.isEmpty(value)) {
            msg = "This field is required";
            this.errors["pickup_arrival"] = msg;
          } else if (!this.validateDate(value)) {
            msg = "Invalid Date ex: YYYY-mm-dd";
            this.errors["pickup_arrival"] = msg;
          } else if (this.eta && moment(this.eta).isAfter(datecompare)) {
            msg = "The Arrival at Pickup should be later than ETA";
            this.errors["pickup_arrival"] = msg;
          } else {
            this.errors["pickup_arrival"] = "";
            return true;
          }
          if (isTimeField) {
            return true;
          }
          return msg;
        },
      };
    },
    validateDaterules(v, c, isTime = false) {
      if (isTime) {
        v = moment(v, "hh:mm A").format("HH:mm");
        if (!_.isEmpty(v) && !moment(v, "HH:mm", true).isValid())
          return "Invalid Time ex. HH:mm";
        return true;
      }
      if (v === "") return "This field is required";
      if (!this.validateDate(v)) {
        return "Invalid Date ex: YYYY-mm-dd";
      } else if (moment(this.eta).isAfter(v)) {
        return "The " + c + " should be later than ETA";
      } else {
        return true;
      }
    },
    validateDate(dateStr) {
      console.log(dateStr)
      // if (typeof dateStr == "undefined") return false;
      // if (dateStr) {
      //   let str = dateStr.split("T");
      //   dateStr = str[0];
      //   const regex = /^\d{4}-\d{2}-\d{2}$/;
      //   if (dateStr.match(regex) === null) {
      //     return false;
      //   }
      //   const date = new Date(dateStr);
      //   const timestamp = date.getTime();
      //   if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
      //     return false;
      //   }
      //   return date.toISOString().startsWith(dateStr);
      // }
      // return false;
    },
    addPickup() {
      if (this.showArrivalAtPickup) {
        if (moment(this.arrival_at_pickup_location_date).isValid())
          this.departure_from_pickup_location_date =
            this.arrival_at_pickup_location_date;
        // if(moment(this.arrival_at_pickup_location_time, "HH:mm").isValid()) this.departure_from_pickup_location_time = this.arrival_at_pickup_location_time;
        this.showOutgateFromPickup = true;
        this.justAddShowOutgatePickup = true;
      } else {
        if (moment(this.pickup_scheduled_date).isValid())
          this.arrival_at_pickup_location_date = this.pickup_scheduled_date;
        // if(moment(this.pickup_scheduled_time, "HH:mm").isValid()) this.arrival_at_pickup_location_time = this.pickup_scheduled_time;
        this.showArrivalAtPickup = true;
        this.justAddShowArrivalPickup = true;
      }
    },
    addDelivery() {
      if (this.showArrivalAtDelivery) {
        if (
          this.arrival_at_delivery_location_date &&
          moment(this.arrival_at_delivery_location_date).isValid()
        )
          this.departure_from_delivery_location_date =
            this.arrival_at_delivery_location_date;
        // if(this.arrival_at_delivery_location_time && moment(this.arrival_at_delivery_location_time, "HH:mm").isValid()) this.departure_from_delivery_location_time = this.arrival_at_delivery_location_time;
        this.showOutgateFromDelivery = true;
        this.justAddShowOutgateDelivery = true;
      } else {
        if (moment(this.delivery_scheduled_date).isValid())
          this.arrival_at_delivery_location_date = this.delivery_scheduled_date;
        // if(moment(this.delivery_scheduled_time, "HH:mm").isValid()) this.arrival_at_delivery_location_time = this.delivery_scheduled_time;
        this.showArrivalAtDelivery = true;
        this.justAddShowArrivalAtDelivery = true;
      }
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    getdata(item) {
      if (this.currentstatepicker == "pickup_scheduled_date") {
        this.pickup_scheduled_date = item;
      }
      if (this.currentstatepicker == "delivery_scheduled_date") {
        this.delivery_scheduled_date = item;
      }
    },
    generalRules(value) {
      return {
        typeRule: () => {
          if (
            ["Empty Return", "Yard"].includes(value) &&
            this.selectedMode == 2
          )
            return value + " is not allowed when mode is Live";
          return true;
        },
      };
    },
    getLastLegDeliveryDateTime() {
      if (_.isEmpty(this.lastLeg)) return null;
      let dateTime = null;
      if (this.lastLeg.arrival_at_delivery_location_date) {
        dateTime = this.lastLeg.arrival_at_delivery_location_date;
        if (this.lastLeg.arrival_at_delivery_location_time) {
          dateTime += "T" + this.lastLeg.arrival_at_delivery_location_time;
        }
      } else if (this.lastLeg.date) {
        dateTime = this.lastLeg.date;
        if (this.lastLeg.time) {
          dateTime += "T" + this.lastLeg.time;
        }
      }
      return dateTime;
    },
    close() {
      this.dateErrors = {}

      if (this.bus != null) {
        this.bus.$emit("closeDialog", true);
      }
      this.clearElement();
      this.$emit("close");
      this.$refs.form.resetValidation()
      this.errors = {
        pickup_scheduled: "",
        delivery_scheduled: "",
        pickup_outgate: "",
        delivery_outgate: "",
        delivery_arrival: "",
      }

      this.justAddShowArrivalPickup = false
      this.justAddShowOutgatePickup = false
      this.justAddShowArrivalAtDelivery = false
      this.justAddShowOutgateDelivery = false
    },
    add() {
      let hasErrors = Object.keys(this.dateErrors).length
      if(hasErrors > 0) {
        this.submitting = Math.random()
      }

      if(this.pickup_scheduled_date === "" ) {
        this.pickup_scheduled_date = null
      } 
      if(this.delivery_scheduled_date === "") {
        this.delivery_scheduled_date = null
      }

      if (!this.placesAddLeg.length > 0) {
        this.$refs.form.validate();
        return;
      }
      const selectedPlace = this.placesAddLeg.find(
        (element) => element.place_name == this.address
      );
      let ob = {
        selectedDriver: this.selectedDriver,
        chassis: this.selectedChassis,
        pickup_scheduled_date: this.pickup_scheduled_date,
        pickup_scheduled_time: this.timeFormatTo24Hr(
          this.pickup_scheduled_time
        ),
        delivery_scheduled_date: this.delivery_scheduled_date,
        delivery_scheduled_time: this.timeFormatTo24Hr(
          this.delivery_scheduled_time
        ),
        driver_instructions: this.driver_instructions,

        schedule_assigned_date_time:
          this.selectedDriver > 0 ? new Date().toJSON() : null,

        schedule_accepted_date_time:
          this.driverstatus == "Accepted" ? new Date().toJSON() : null,
        schedule_completed_date_time: null,
        arrival_at_pickup_location_date: this.arrival_at_pickup_location_date,
        arrival_at_pickup_location_time: this.timeFormatTo24Hr(
          this.arrival_at_pickup_location_time
        ),
        departure_from_pickup_location_date:
          this.departure_from_pickup_location_date,
        departure_from_pickup_location_time: this.timeFormatTo24Hr(
          this.departure_from_pickup_location_time
        ),
        arrival_at_delivery_location_date:
          this.arrival_at_delivery_location_date,
        arrival_at_delivery_location_time: this.timeFormatTo24Hr(
          this.arrival_at_delivery_location_time
        ),
        departure_from_delivery_location_date:
          this.departure_from_delivery_location_date,
        departure_from_delivery_location_time: this.timeFormatTo24Hr(
          this.departure_from_delivery_location_time
        ),
        address: this.address,
        pod_required: this.pod_required,
        selectedMode: this.selectedMode,
        selectedType: this.selectedType,
        driverstatus: this.driverstatus,
        driverCanSeeDeliveryOrder: this.driverCanSeeDeliveryOrder,
      };

      if (this.$refs.form.validate() && hasErrors === 0) {
        ob["addressLongitude"] = selectedPlace.geometry
          ? selectedPlace.geometry.coordinates[0]
          : null;
        ob["addressLatitude"] = selectedPlace.geometry
          ? selectedPlace.geometry.coordinates[1]
          : null;

        let address_model_type = null,
          address_model_id = null;
        if (
          selectedPlace.yard !== undefined &&
          selectedPlace.yard &&
          selectedPlace.yard.id
        ) {
          address_model_type = "App\\Yard";
          address_model_id = selectedPlace.yard.id;
        } else if (
          selectedPlace.terminal !== undefined &&
          selectedPlace.terminal &&
          selectedPlace.terminal.id
        ) {
          address_model_type = "App\\Terminal";
          address_model_id = selectedPlace.terminal.id;
        }

        ob["address_model_type"] = address_model_type;
        ob["address_model_id"] = address_model_id;

        this.$emit("addNewLeg", ob);
        this.$emit("update:dialogData", ob);
        //this.$refs.form.reset();
        //this.clearElement();
      } else {
        return;
        // this.custom_error_show = true;
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearElement() {
      this.$refs["form"].resetValidation();
      this.address = "";
      this.selectedDriver = 0;
      this.selectedChassis = "";
      this.selectedMode = "";
      this.selectedType = "";
      this.pickup_scheduled_date = "";
      this.pickup_scheduled_time = "";
      this.delivery_scheduled_date = "";
      this.delivery_scheduled_time = "";
      this.arrival_at_pickup_location_date = "";
      this.arrival_at_pickup_location_time = "";
      this.departure_from_pickup_location_date = "";
      this.departure_from_pickup_location_time = "";
      this.arrival_at_delivery_location_date = "";
      this.arrival_at_delivery_location_time = "";
      this.departure_from_delivery_location_date = "";
      this.departure_from_delivery_location_time = "";
      this.showArrivalAtPickup = false;
      this.showOutgateFromPickup = false;
      this.showArrivalAtDelivery = false;
      this.showOutgateFromDelivery = false;
      this.custom_error_show = false;
      this.pod_required = true;
      this.driverCanSeeDeliveryOrder = true;
      this.errors = {
        pickup_scheduled: "",
        delivery_scheduled: "",
        pickup_outgate: "",
        delivery_outgate: "",
        delivery_arrival: "",
      };
    },
    handleNewChassis() {
      let data = {
        chassis_number: this.newChassis,
      };
      if (this.newChassis === null || this.newChassis === "") {
        this.dispatchNotificationError("New Chassis should not be blank/null");
      } else {
        this.$emit("saveNewChassis", data);
      }
    },
    getETA() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.eta;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(false).format("YYYY-MM-DD");
          return moment(temp).utc(false).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getLFD() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.last_free_date;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(true).format("YYYY-MM-DD");
          return moment(temp).utc(true).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getPDD() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.per_diem_date;
        if (temp !== null && temp !== "") {
          // return moment(temp).utc(true).format("YYYY-MM-DD");
          return moment(temp).utc(true).format("MM/DD/YY");
        } else {
          return "--";
        }
      }
    },
    getCustomer() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.customer;
        if (temp !== null && temp !== "") {
          return temp?.company_name;
        } else {
          return "--";
        }
      }
    },
    getTerminal() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.terminal;
        if (temp !== null && temp !== "") {
          return temp?.name;
        } else {
          return "--";
        }
      }
    },
    getDeliveryTo() {
      if (this.responseObjectMeta !== undefined) {
        let temp = this.responseObjectMeta[0]?.deliver_to;
        if (temp !== null && temp !== "") {
          return temp;
        } else {
          return "--";
        }
      }
    },
    async getDeliveryLocation(val) {
      if (val.length > 3) {
        this.loadingLocationPlaces = true;
        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then(async (json) => {
            this.loadingLocationPlaces = false;
            this.placesAddLeg = [...json.features];
            // this.places = [...json.features, ...customInput];
            if (this.selectedType == "Empty Return") {
              await axios
                .get(poBaseUrl + "/terminals/search?search_text=" + val)
                .then((res) => res.data)
                .then((terminals) => {
                  terminals = terminals.filter(
                    (f) => f.latitude && f.longitude
                  );
                  terminals = terminals.map((terminal) => {
                    return {
                      terminal: terminal,
                      place_name:
                        (terminal.nickname ? terminal.nickname + " - " : "") +
                        terminal.name +
                        ` [${terminal.firms_code}], ` +
                        terminal.address,
                      geometry: {
                        coordinates: [terminal.longitude, terminal.latitude],
                      },
                    };
                  });
                  this.placesAddLeg = [...terminals, ...this.placesAddLeg];
                })
                .catch((e) => {
                  this.loadingLocationPlaces = false;
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.setInitAddressDropDown(this.selectedType);
      }
    },
    clearSearchPlaces() {
      this.placesAddLeg = [];
      this.setInitAddressDropDown(this.selectedType);
    },

    getContainerNo() {
      if (this.responseObjectMeta !== undefined) {
        let cont = this.responseObjectMeta[0]?.trucker_container;
        let isCSNull = cont === null || cont === "";
        return `${isCSNull ? "--" : cont}`;
      }
    },

    getNotes() {
      if (this.responseObjectMeta !== undefined) {
        let notes = this.responseObjectMeta[0]?.custom_notes;
        let isNNull = notes === null;
        return `${isNNull ? "--" : notes}`;
      }
    },
    timeFormatTo24Hr(time) {
      return time !== "" ? moment(time, ["hh:mm A"]).format("HH:mm") : "";
    },
    getContSizeandWeight(isFor) {
      if (this.responseObjectMeta !== undefined) {
        let cont_size = this.responseObjectMeta[0]?.container_size;
        let cont_weight = this.responseObjectMeta[0]?.container_weight;
        let isCSNull = cont_size === null || cont_size === "";
        let isCWNull = cont_weight === null || cont_weight === "";

        if (isFor === 'size') {
          return isCSNull ? "--" : cont_size?.name
        } else {
          return isCWNull ? "--" : cont_weight
        }
        // return `${isCSNull ? "--" : cont_size?.name}, ${
        //   isCWNull ? "--" : cont_weight
        // }`;
      }
    },
    getMBLandCarrier(isFor) {
      if (this.responseObjectMeta !== undefined) {
        let mbl = this.responseObjectMeta[0]?.mbl_num;
        let carrier = this.responseObjectMeta[0]?.carrier;
        let isCSNull = mbl === null || mbl === "";
        let isCNull = carrier === null || carrier === "";
        
        if (isFor === 'mbl') {
          return isCSNull ? "--" : mbl
        } else {
          return isCNull ? "--" : carrier?.name
        }
        // return `${isCSNull ? "--" : mbl}, ${isCNull ? "--" : carrier?.name}`;
      }
    },
    getStatus(driver) {
      if (typeof driver.dispatch_legs !== "undefined") {
        let ds = driver.dispatch_legs;

        if (ds.length > 0) {
          let fl = ds.filter((leg) => {
            let pdt =
              leg.pickup_scheduled_date_time !== ""
                ? moment(leg.pickup_scheduled_date_time)
                : null;
            let ddt =
              leg.delivery_scheduled_date_time !== ""
                ? moment(leg.delivery_scheduled_date_time)
                : null;
            let date = moment(this.pickup_scheduled_date)
            let ddate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

            let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null)
            let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null)
            let cpsdt = ""
            let cpsddt = ""

            if(isNull) {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T00:00:00"
              );
            } else {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T"+this.pickup_scheduled_time+":00"
              );
            }
            if(ddate !== "") {
              if(disNull) {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T00:00:00"
                );
              } else {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T"+this.delivery_scheduled_time+":00"
                );
              }
            }

            if (!leg.completed) {
              if (pdt !== null) {
                // if(pdt === this.pickup_scheduled_date && leg.driver_status === "Accepted") {
                if (
                  cpsdt.isBetween(pdt, ddt) ||
                  (cpsdt.isAfter(pdt) && cpsdt.isBefore(ddt)) ||
                  (pdt.isBetween(cpsdt, cpsddt))
                ) {
                  return leg;
                }
              }
            }
          });

          if (fl.length > 0) {
            if (this.selectedDriver !== fl[0].driver_id) {
              return "Busy";
            }
          }
        }
      }
    },
    getRef(driver) {
      if (typeof driver.dispatch_legs !== "undefined") {
        let ds = driver.dispatch_legs;

        if (ds.length > 0) {
          let fl = ds.filter((leg) => {
            let pdt =
              leg.pickup_scheduled_date_time !== ""
                ? moment(leg.pickup_scheduled_date_time)
                : null;
            let ddt =
              leg.delivery_scheduled_date_time !== ""
                ? moment(leg.delivery_scheduled_date_time)
                : null;
            let date = moment(this.pickup_scheduled_date)
            let ddate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

            let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null)
            let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null)
            let cpsdt = ""
            let cpsddt = ""

            if(isNull) {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T00:00:00"
              );
            } else {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T"+this.pickup_scheduled_time+":00"
              );
            }
            if(ddate !== "") {
              if(disNull) {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T00:00:00"
                );
              } else {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T"+this.delivery_scheduled_time+":00"
                );
              }
            }

            if (!leg.completed) {
              if (pdt !== null) {
                // if(pdt === this.pickup_scheduled_date && leg.driver_status === "Accepted") {
                if (
                  cpsdt.isBetween(pdt, ddt) ||
                  (cpsdt.isAfter(pdt) && cpsdt.isBefore(ddt)) ||
                  (pdt.isBetween(cpsdt, cpsddt))
                ) {
                  return leg;
                }
              }
            }
          });

          if (fl.length > 0) {
            if (this.selectedDriver !== fl[0].driver_id) {
              return "Ref " + fl[0].shipment.shifl_ref;
            }
          }
        }
      }
    },
    getLinkActive(driver) {
      if (typeof driver.dispatch_legs !== "undefined") {
        let ds = driver.dispatch_legs;

        if (ds.length > 0) {
          let fl = ds.filter((leg) => {
            let pdt =
              leg.pickup_scheduled_date_time !== ""
                ? moment(leg.pickup_scheduled_date_time)
                : null;
            let ddt =
              leg.delivery_scheduled_date_time !== ""
                ? moment(leg.delivery_scheduled_date_time)
                : null;
            let date = moment(this.pickup_scheduled_date)
            let ddate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

            let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null)
            let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null)
            let cpsdt = ""
            let cpsddt = ""

            if(isNull) {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T00:00:00"
              );
            } else {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T"+this.pickup_scheduled_time+":00"
              );
            }
            if(ddate !== "") {
              if(disNull) {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T00:00:00"
                );
              } else {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T"+this.delivery_scheduled_time+":00"
                );
              }
            }

            if (!leg.completed) {
              if (pdt !== null) {
                // if(pdt === this.pickup_scheduled_date && leg.driver_status === "Accepted") {
                console.log("")
                console.log("======== *** ========")
                console.log("pdt:ddt >>>", pdt.format('YYYY-MM-DD hh:mm a'), ddt.format('YYYY-MM-DD hh:mm a'))
                console.log("cpsdt:cpsddt >>>", cpsdt.format('YYYY-MM-DD hh:mm a'), ddate !== "" ? cpsddt.format('YYYY-MM-DD hh:mm a') : "null")
                if (
                  cpsdt.isBetween(pdt, ddt) ||
                  (cpsdt.isAfter(pdt) && cpsdt.isBefore(ddt)) ||
                  (pdt.isBetween(cpsdt, cpsddt))
                ) {
                  return leg;
                }
              }
            }
          });

          if (fl.length > 0) {
            if (this.selectedDriver !== fl[0].driver_id) {
              return 1;
            }
          }
        }
      }
    },
    getShipment(driver) {
      if (typeof driver.dispatch_legs !== "undefined") {
        let ds = driver.dispatch_legs;

        if (ds.length > 0) {
          let fl = ds.filter((leg) => {
            let pdt 
              leg.pickup_scheduled_date_time !== ""
                ? moment(leg.pickup_scheduled_date_time)
                : null;
            let ddt =
              leg.delivery_scheduled_date_time !== ""
                ? moment(leg.delivery_scheduled_date_time)
                : null;
            let date = moment(this.pickup_scheduled_date)
            let ddate = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""

            let isNull = (this.pickup_scheduled_time === "" && this.pickup_scheduled_time === null)
            let disNull = (this.delivery_scheduled_time === "" && this.delivery_scheduled_time === null)
            let cpsdt = ""
            let cpsddt = ""

            if(isNull) {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T00:00:00"
              );
            } else {
              cpsdt = moment(
                date.format('YYYY-MM-DD')+"T"+this.pickup_scheduled_time+":00"
              );
            }
            if(ddate !== "") {
              if(disNull) {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T00:00:00"
                );
              } else {
                cpsddt = moment(
                  ddate.format('YYYY-MM-DD')+"T"+this.delivery_scheduled_time+":00"
                );
              }
            }

            if (!leg.completed) {
              if (pdt !== null) {
                // if(pdt === this.pickup_scheduled_date && leg.driver_status === "Accepted") {
                if (
                  cpsdt.isBetween(pdt, ddt) ||
                  (cpsdt.isAfter(pdt) && cpsdt.isBefore(ddt)) ||
                  (pdt.isBetween(cpsdt, cpsddt))
                ) {
                  return leg;
                }
              }
            }
          });

          if (fl.length > 0) {
            if (this.selectedDriver !== fl[0].driver_id) {
              return fl[0];
            }
          }
        }
      }
    },
    handleShipmentLink(leg) {
      window.location.replace(`/container/edit/${leg.shipment.id}`);
    },
    handlePickedDate(e, model) {
      this.showError = false
      if(this[model] !== null && this[model] !== "") {
        let date = moment(this[model])
        let eta = moment(this.eta)

        if(!date.isValid()) {
          this.dateErrors[model] = {
            message: "Please input a valid date"
          }
        } else {
          if(model === 'pickup_scheduled_date') {
            let pd = moment(this[model])
            let dd = this.delivery_scheduled_date !== "" ? moment(this.delivery_scheduled_date) : ""
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }
            
            if( dd !== "") {
              if(pd.isAfter(dd)) {
                this.dateErrors['delivery_scheduled_date'] = {
                  message: "Delivery should be later than Pickup"
                }
              }
              else {
                delete this.dateErrors.pickup_scheduled_date
              }
            }
            
            if(!_.isEmpty(this.delivery_scheduled_date)) {
              if(_.isEmpty(this.pickup_scheduled_time) && _.isEmpty(this.delivery_scheduled_time)) {
                if(dd !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['pickup_scheduled_time'] = true
                } 
                else {
                  delete this.dateErrors.pickup_scheduled_date
                }
              }
            }

            if(eta.isAfter(pd)) {
              this.dateErrors[model] = {
                message: "Pickup date should be later than ETA"
              }
            } 
            else {
              delete this.dateErrors.pickup_scheduled_date
            }
          } 
          else if(model === 'arrival_at_pickup_location_date') {
            let pd = moment(this.pickup_scheduled_date)
            let apd = moment(this[model])
            let add = this.arrival_at_delivery_location_date !== "" ? moment(this.arrival_at_delivery_location_date) : ""
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if(add !== "") {
              if(apd.isAfter(add)) {
                this.dateErrors['arrival_at_delivery_location_date'] = {
                  message: "Arrival at delivery date should be later than Arrival at pickup date"
                }
              } else {
                delete this.dateErrors.arrival_at_pickup_location_date
              }
            }

            if(!_.isEmpty(this.arrival_at_pickup_location_date)) {
              if(_.isEmpty(this.arrival_at_pickup_location_time) && _.isEmpty(this.arrival_at_delivery_location_time)) {
                if(add !== "") {
                  this.dateErrors[model] = {
                    message: "At least one scheduled time is required"
                  }
                  this.dateErrors['arrival_at_pickup_location_time'] = true
                } else {
                  delete this.dateErrors.arrival_at_pickup_location_date
                }
              }
            }
            
            if(eta.isAfter(apd)) {
              this.dateErrors[model] = {
                message: "Arrival at pickup date should be later than ETA"
              }
            }
            else if(pd.isAfter(apd)) {
              this.dateErrors[model] = {
                message: "Arrival at pickup should be later than Pickup"
              }
            } else {
              delete this.dateErrors.arrival_at_pickup_location_date
            }
          } 
          else if(model === 'departure_from_pickup_location_date') {
            let pd = moment(this.pickup_scheduled_date)
            let apd = moment(this.arrival_at_pickup_location_date)
            let ofpd = moment(this[model])
            let ddd = this.departure_from_delivery_location_date !== "" ? moment(this.departure_from_delivery_location_date) : ""
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }
            
            if(ddd !== "") {
              if(ofpd.isAfter(ddd)) {
                this.dateErrors['departure_from_delivery_location_date'] = {
                  message: "Departure from delivery should be later than Departure from pickup date"
                }
              } else {
                this.dateErrors = {}
              }
            }
            else if(pd.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup date should be later than scheduled Pickup date"
              }
            } 
            else if(_.isEmpty(this.departure_from_pickup_location_time) && _.isEmpty(this.departure_from_delivery_location_time)) {
              if(ddd !== "") {
                this.dateErrors[model] = {
                  message: "At least one scheduled time is required"
                }
                this.dateErrors['departure_from_pickup_location_time'] = true
              } 
            }
            
            if(eta.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup should be later than ETA"
              }
            }
            else if(apd.isAfter(ofpd)) {
              this.dateErrors[model] = {
                message: "Outgate from pickup date should be later than Arrival at pickup date"
              }
            } else {
              this.dateErrors = {}
            }
          }
          else if(model === 'delivery_scheduled_date') {
            let dd = moment(this.delivery_scheduled_date)
            let pd = moment(this.pickup_scheduled_date)
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if(eta.isAfter(dd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              delete this.dateErrors.delivery_scheduled_date
            }

            if(pd.isAfter(dd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than scheduled Pickup date"
              }
            } 
            else if(_.isEmpty(this.pickup_scheduled_time) && _.isEmpty(this.delivery_scheduled_time)) {
              if(pd !== "") {
                this.dateErrors[model] = {
                  message: "At least one scheduled time is required"
                }
                this.dateErrors['delivery_scheduled_time'] = true
              }
            }
            else {
              delete this.dateErrors.delivery_scheduled_date
            }
          }
          else if(model === 'arrival_at_delivery_location_date') {
            let add = moment(this.arrival_at_delivery_location_date)
            let apd = moment(this.arrival_at_pickup_location_date)
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if(eta.isAfter(add)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              this.dateErrors = {}
            }
            
            if(apd.isAfter(add)) {
              this.dateErrors[model] = {
                message: "Arrival at Delivery date should be later than Arrival at Pickup date"
              }
            } 
            else if(_.isEmpty(this.arrival_at_pickup_location_time) && _.isEmpty(this.arrival_at_delivery_location_time)) {
              if(apd !== "") {
                this.dateErrors[model] = {
                  message: "At least one scheduled time is required"
                }
                this.dateErrors['arrival_at_delivery_location_time'] = true
              }
            }
            else {
              this.dateErrors = {}
            }
          }
          else if(model === 'departure_from_delivery_location_date') {
            let dpd = moment(this.departure_from_pickup_location_date)
            let ddd = moment(this.departure_from_delivery_location_date)
            if(typeof this.$refs[model] !== 'undefined') {
              if (typeof this.$refs[model].value_ !== "undefined" && this.$refs[model].value_ !== null) {
                if (this.$refs[model].value_.getFullYear() === 2001) {
                  this.$refs[model].value_.setFullYear(moment().year())
                }
              }
            }

            if(eta.isAfter(ddd)) {
              this.dateErrors[model] = {
                message: "Delivery date should be later than ETA"
              }
            } else {
              this.dateErrors = {}
            } 
            
            if(dpd.isAfter(ddd)) {
              this.dateErrors[model] = {
                message: "Departure from Delivery date should be later than Departure from Pickup date"
              }
            } 
            else if(_.isEmpty(this.departure_from_pickup_location_time) && _.isEmpty(this.departure_from_delivery_location_time)) {
              if(dpd !== "") {
                this.dateErrors[model] = {
                  message: "At least one scheduled time is required"
                }
                this.dateErrors['departure_from_delivery_location_time'] = true
              }
            }
            else {
              this.dateErrors = {}
            }
          }
        }
      } else {
        // this.dateErrors[model] = {
        //   message: "This field is required"
        // }

        if (model === 'pickup_scheduled_date') {
          this.dateErrors[model] = {
            message: "This field is required"
          }
        } else if (model === 'delivery_scheduled_date') {
          this.dateErrors[model] = {
            message: "This field is required"
          }
        } else if (model === 'arrival_at_pickup_location_date') {
          if (!this.justAddShowArrivalPickup) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.arrival_at_pickup_location_date = ""
            this.arrival_at_pickup_location_time = null
            delete this.dateErrors.arrival_at_pickup_location_date
            delete this.dateErrors.arrival_at_pickup_location_time
          }
        } else if (model === 'departure_from_pickup_location_date') {
          if (!this.justAddShowOutgatePickup) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.departure_from_pickup_location_date = ""
            this.departure_from_pickup_location_time = null
            delete this.dateErrors.departure_from_pickup_location_date
            delete this.dateErrors.departure_from_pickup_location_time
          }
        } else if (model === 'arrival_at_delivery_location_date') {
          if (!this.justAddShowArrivalAtDelivery) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.arrival_at_delivery_location_date = ""
            this.arrival_at_delivery_location_time = null
            delete this.dateErrors.arrival_at_delivery_location_date
            delete this.dateErrors.arrival_at_delivery_location_time
          }
        } else if (model === 'departure_from_delivery_location_date') {
          if (!this.justAddShowOutgateDelivery) {
            this.dateErrors[model] = {
              message: "This field is required"
            }
          } else {
            this.departure_from_delivery_location_date = ""
            this.departure_from_delivery_location_time = null
            delete this.dateErrors.departure_from_delivery_location_date
            delete this.dateErrors.departure_from_delivery_location_time
          }
        }
      }
    },
    handlePickupTime(e) {
      this.showError = false
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors["pickup_scheduled_time"] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          // let hasTimeError = this.dateErrors['pickup_scheduled_time']
          // if(hasTimeError) {
            delete this.dateErrors.pickup_scheduled_date
            delete this.dateErrors.pickup_scheduled_time
            delete this.dateErrors.delivery_scheduled_date
            delete this.dateErrors.delivery_scheduled_time
          // }
          this.pickup_scheduled_time = time.format('HH:mm')
          this.handlePickedDate(this.pickup_scheduled_date, 'pickup_scheduled_date')
          this.handlePickedDate(this.delivery_scheduled_date, 'delivery_scheduled_date')
        }
      }
    },
    handleArrivalPickupTime(e) {
      this.showError = false
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors['arrival_at_pickup_location_time'] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          // let hasTimeError = this.dateErrors['arrival_at_pickup_location_time']
          // if(hasTimeError) {
            delete this.dateErrors.arrival_at_pickup_location_date
            delete this.dateErrors.arrival_at_pickup_location_time
            delete this.dateErrors.arrival_at_delivery_location_date
            delete this.dateErrors.arrival_at_delivery_location_time
          // }
          this.arrival_at_pickup_location_time = time.format('HH:mm')
          this.handlePickedDate(this.arrival_at_pickup_location_date, 'arrival_at_pickup_location_date')
          this.handlePickedDate(this.arrival_at_delivery_location_date, 'arrival_at_delivery_location_date')
        }
      }
    },
    handleOutgatePickupTime(e) {
      this.showError = false
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors['departure_from_pickup_location_time'] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          // let hasTimeError = this.dateErrors['departure_from_pickup_location_time']
          // if(hasTimeError) {
            delete this.dateErrors.departure_from_pickup_location_date
            delete this.dateErrors.departure_from_pickup_location_time
            delete this.dateErrors.departure_from_delivery_location_date
            delete this.dateErrors.departure_from_delivery_location_time
          // }
          this.departure_from_pickup_location_time = time.format('HH:mm')
          this.handlePickedDate(this.departure_from_pickup_location_date, 'arrival_at_pickup_location_date')
          this.handlePickedDate(this.departure_from_delivery_location_date, 'arrival_at_delivery_location_date')
        }
      }
    },
    handleDeliveryTime(e) {
      this.showError = false
      console.log('evalue >>>', e.value)
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors['delivery_scheduled_time'] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          let hasTimeError = this.dateErrors['delivery_scheduled_time']
          if(hasTimeError) {
            delete this.dateErrors.pickup_scheduled_date
            delete this.dateErrors.delivery_scheduled_date
            delete this.dateErrors.pickup_scheduled_time
            delete this.dateErrors.delivery_scheduled_time
          }
          this.delivery_scheduled_time = time.format('HH:mm')
          this.handlePickedDate(this.delivery_scheduled_date, 'delivery_scheduled_date')
          this.handlePickedDate(this.pickup_scheduled_date, 'pickup_scheduled_date')
        }
      }
    },
    handleArrivalDeliveryTime(e) {
      this.showError = false
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors['arrival_at_delivery_location_time'] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          let hasTimeError = this.dateErrors['arrival_at_delivery_location_time']
          if(hasTimeError) {
            delete this.dateErrors.arrival_at_pickup_location_date
            delete this.dateErrors.arrival_at_delivery_location_date
            delete this.dateErrors.arrival_at_pickup_location_time
            delete this.dateErrors.arrival_at_delivery_location_time
          }
          this.arrival_at_delivery_location_time = time.format('HH:mm')
          this.handlePickedDate(this.arrival_at_pickup_location_date, 'arrival_at_pickup_location_date')
          this.handlePickedDate(this.arrival_at_delivery_location_date, 'arrival_at_delivery_location_date')
        }
      }
    },
    handleDepartureDeliveryTime(e) {
      this.showError = false
      let time = moment(e.value)
      if(time !== null || time !== "") {
        if(!time.isValid()) {
          this.dateErrors['departure_at_delivery_location_time'] = {
            message: "Invalid Time ex. HH:mm"
          }
        } else {
          let hasTimeError = this.dateErrors['departure_from_delivery_location_time']
          if(hasTimeError) {
            delete this.dateErrors.departure_from_pickup_location_date
            delete this.dateErrors.departure_from_delivery_location_date
            delete this.dateErrors.departure_from_pickup_location_time
            delete this.dateErrors.departure_from_delivery_location_time
          }
          this.departure_from_delivery_location_time = time.format('HH:mm')
          this.handlePickedDate(this.departure_from_pickup_location_date, 'departure_from_pickup_location_date')
          this.handlePickedDate(this.departure_from_delivery_location_date, 'departure_from_delivery_location_date')
        }
      }
    },
    hasError(model) {
      let check = typeof this.dateErrors[model] !== 'undefined'

      return check ? true : false
    }
  },
};
</script>

<style lang="scss">
.v-dialog.leg-modal-wrapper.add {
  .v-card {
    .v-card__title {
      .marked-as-arrived-badge {
        /* padding-left: 15px;
        padding-right: 15px;
        padding-top: 7.5px;
        padding-bottom: 7.5px; */
        padding: 8px 12px !important;
        background: #f0fbff;
        border: 1px solid #ebf2f5;
        box-sizing: border-box;
        border-radius: 99px;
        font-size: 11px;
        color: #0171a1;
        font-style: normal;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter-Medium', sans-serif !important;
      }
    }

    .details-wrapper {
      .details-left-column {
        .details-content {
          .details-title {
            width: 45% !important;
          }

          .details-data {
            width: 55%;
          }
        }
      }

      .details-right-column {
        .details-content {
          .details-title {
            width: 22% !important;
          }

          .details-data {
            width: 78%;
          }
        }
      }

      .details-content {
        display: flex;
        align-items: flex-start;
        margin-bottom: 6px;

        .details-title {
          font-size: 10px;
          color: #819fb2;
          font-family: "Inter-Medium", sans-serif !important;
          text-transform: uppercase;
        }

        .details-data {
          font-size: 11px;
          color: #4a4a4a;
          font-family: "Inter-Regular", sans-serif !important;
        }
      }      
    }
  }
	.v-input {
		.v-input__control {
			.v-input__slot {
				min-height: 42px !important;
				margin-bottom: 4px;
        border: 1px solid #B4CFE0 !important;

        fieldset {
          border: 1px solid #B4CFE0 !important;
          color: #4a4a4a !important;
          background-color: #fff;
        }

				input,
				textarea {
					font-family: 'Inter-Regular', sans-serif !important;
					font-size: 14px !important;
          color: #4a4a4a;

          &::placeholder {
            color: #B4CFE0 !important;
          }
				}

				textarea {					
					border-radius: 4px;          
          min-height: 76px !important;
          line-height: 20px !important;
				}

				.v-input__append-inner {
					.v-input__icon {
						i {
							font-size: 20px !important;
							color: #4a4a4a !important;
						}
					}
				}
			}
		}

    &.driver-instructions-field {
      .v-input__control {
        .v-input__slot {
          fieldset {
            border: none !important;
          }

          textarea {
            margin-top: 0 !important;
            padding-top: 4px !important;
          }
        }
      }
    }

    &.pickup-location-field {
      .v-input__control {
        background-color: transparent !important;

        .v-input__slot {
          .v-text-field__slot {
            margin-right: 0 !important;

            textarea {
              padding-top: 4px !important;
            }
          }
        }
      }
    }

    &.delivery-location-field {
      .v-label {
        color: #B4CFE0 !important;
        font-family: "Inter-Regular", sans-serif;
        font-size: 14px;
        position: unset !important;
        overflow: unset !important;
        white-space: normal !important;
        height: 100% !important;
        padding: 4px 0 !important;
      }

      .v-input__control {
        .v-input__slot {
          .v-select__slot {
            min-height: 76px;
            padding-top: 4px;

            .v-select__selections {
              // height: 100% !important;
              align-self: flex-start;
              flex-wrap: wrap;
              flex-direction: column;
            }

            input {
              align-self: flex-start;
            }
          }
        }

        .v-input__append-inner {
          display: none !important;
        }
      }    
    }
	}

	.anl-input-group {
		.anl-input-group-right {
			.date-field {
				.v-input__slot {
					padding-top: 0 !important;
					border: 1px solid #B4CFE0;

					input {
						padding: unset !important;
					}

					.v-input__append-inner {
						padding-top: 7px;

						i {
							color: #4a4a4a !important;
						}
					}
				}
			}
		}
	}
  
  .date-field-dxtime {
    margin-top: 0;
    height: 41px;
    border: 1px solid #B4CFE0 !important;
    border-radius: 0px 4px 4px 0px !important;
    font-size: 12px !important;

    .dx-dropdowneditor-input-wrapper {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            padding-right: 0px !important;
            font-size: 14px;
            font-family: "Inter-Regular", sans-serif !important;
            caret-color: #4a4a4a;
          }

          .dx-placeholder {
            color: #B4CFE0 !important;
            font-size: 14px;
            font-family: "Inter-Regular", sans-serif !important;
          }
        }
      }

      .dx-texteditor-buttons-container {
        .dx-dropdowneditor-button {
          width: 35px;

          .dx-dropdowneditor-icon {
            width: 40px;
            color: #4a4a4a;

            &::before {
              content: "";
              background-image: url('/icons/clock.svg') !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              background-size: cover !important;
              width: 16px !important;
              height: 16px !important;
              color: #4a4a4a !important;
              padding: 0px;
            }
          }
        }          
      }
    }
  }

  .leg-date-field {
    border-width: 1px !important;
    font-size: 14px !important;
    font-family: "Inter-Regular", sans-serif !important;
    margin-top: 0;
    caret-color: #4a4a4a;
    outline: 0;
  }

  i.append-icon {
    position: relative;
    float: right;
    margin: -30px 10px;
    color: #4a4a4a;
  }

	.label {
		font-size: 10px !important;
		font-weight: unset !important;
		font-family: "Inter-SemiBold", sans-serif !important;
		padding-bottom: 2px !important;
	}

	.custom-error-text {
		height: 12px;
    line-height: 12px;
    margin-top: 6px;
		font-family: 'Inter-Medium', sans-serif;
	}

	.v-text-field__details {
		height: 12px;
    line-height: 12px;
    margin-top: 2px;
		font-family: 'Inter-Medium', sans-serif;
	}

	.button-outline {
		.v-btn__content {
			letter-spacing: 0;
		}
	}

  .datetime-field-groups {
    i.red-color {
      color: #F93131 !important;
    }

    i.not-red {
      color: #819fb2 !important
    }
  }
}
</style>

<style>
.add-option-btn:hover {
  background: rgba(180, 207, 224, 0.3);
  cursor: pointer;
}

.delivery-order-check {
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-order-check .v-input .v-input__control {
  background: #f7f7f7 !important;
}

.checkbox-div {
  display: flex;
  justify-content: space-between;
}

.custom-label {
  color: #819fb2;
  margin-right: 10px;
}
</style>

<style lang="scss">
@import '../../../../assets/scss/vc_calendar.scss';

.date-field-dxtime {
  margin-top: 5px;
  height: 41px;
  border-right: 2px solid #B4CFE0 !important;
  border-top: 2px solid #B4CFE0 !important;
  border-left: solid #B4CFE0 !important;
  border-bottom: 2px solid #B4CFE0 !important;
  border-radius: 0px 4px 4px 0px !important;
  font-size: 12px !important;

  .dx-dropdowneditor-input-wrapper {
    .dx-texteditor-container {
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          padding-right: 0px !important;
        }
      }
    }

    .dx-texteditor-buttons-container {
      .dx-dropdowneditor-button {
        width: 35px;

        .dx-dropdowneditor-icon {
          width: 40px;
          color: #4a4a4a;

          &::before {
            content: "";
            background-image: url('/icons/clock.svg') !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            width: 16px !important;
            height: 16px !important;
            color: #737373 !important;
            padding: 0px;
          }
        }
      }          
    }
  }
}
</style>
