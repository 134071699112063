<template>
    <v-dialog persistent 
        v-model="dialogData" 
        max-width="590px" 
        content-class="add-supplier-dialog add-customer-dialog" 
        v-resize="onResize" 
        :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-form 
                ref="form" 
                id="createCustomer" 
                v-model="valid" 
                action="#" 
                @submit.prevent=""
                >               
                <v-card-text>
                    <v-row>
                      
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-5">
                              <img 
                            src="@/assets/icons/sendReporticon.svg"/>
                        </v-col>    
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-5">
                            <div style="padding-bottom:12px">
                                <h2>Send Report</h2>
                            </div>
                            <p>
                                Are you sure you want to send 
                                the shipment report now to 
                                <strong>{{data.name}}</strong> ({{getemail}})?
                            </p>
                          
                            <v-container class="add-checkbox-recipients px-0 pt-0 pb-0">
                                <v-checkbox
                                    v-model="checkbox"
                                    label="I want to add more email recipient."
                                    hide-details="auto"
                                    :ripple="false">
                                </v-checkbox>
                            </v-container>                            
                          
                        </v-col>     

                        <v-col v-if="checkbox" class="pb-0 pt-0" cols="12" sm="12" md="12">
                            <label class="text-item-label">Recipients email</label>
                            <div class="tags-email-wrapper mb-1">
                                <vue-tags-input
                                    hide-details="auto"
                                    :rules="arrayNotEmptyRules"
                                    :tags="options"
                                    :add-on-blur=true
                                    :class="myclass"
                                    class="DialogInputfield"
                                    :add-on-key="[13, ',']"
                                    :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                                    v-model="warehouseEmailAddress"
                                    placeholder="Enter email address"

                                    @tags-changed="newTags => {
                                        this.myclass = 'noclass';
                                        this.options = newTags
                                        this.tagsInput.touched = true
                                        this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                        if (typeof el!=='udnefined') {
                                            /*if (this.tagsInput.hasError)
                                                el.classList.add('ti-new-tag-input-error')
                                            else
                                                el.classList.remove('ti-new-tag-input-error')*/
                                        }
                                    }"
                                />
                            </div>

                            <div v-if="tagsInput.touched" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div v-if="(options.length > 0) && warehouseEmailAddress!==''" class="v-messages__message">
                                            {{
                                                tagsInput.errorMessage
                                            }}
                                        </div>

                                        <div v-if="options.length == 0 && warehouseEmailAddress!==''" class="v-messages__message">
                                            {{
                                                tagsInput.errorMessage
                                            }}
                                        </div>
                                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                            Please provide at least 1 valid email address.
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <span style="color: #819FB2; font-size: 12px;">
                                Press the "Enter" or "," key in your keyboard to confirm the email address
                            </span>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <div class="d-flex justify-start align-center" style="padding-right:0px;padding-left:0px;">
                        <v-btn style="caret-color:transparent" class="btn-blue" text @click="save">
                            <span>
                                <span style="caret-color:transparent; font-size: 14px !important;">
                                    <v-progress-circular
                                        :size="15"
                                        color="#fff"
                                        :width="2"
                                        indeterminate
                                        v-if="isFetching"
                                        style="margin-right:3px"
                                        >
                                        </v-progress-circular>
                                    Yes, Send now
                                </span>
                            </span>
                        </v-btn>

                        <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                            Cancel
                        </v-btn> 
                    </div>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import VueTagsInput from '@johmun/vue-tags-input';
import globalMethods from '../../../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
import jQuery from 'jquery'
export default {
    name: 'SupplierDialog',
    props: ['dialogData','data'],
    components: {
        //VueTelInputVuetify,
        VueTagsInput
    },
    data: () => ({
        checkbox:false,
        typeError:'', // type of error return from api
        myclass: '',
        isFetching:0,
        customerName:'',
        errorContent: '',
        company_key: '',
        errorSaving: 0,
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "Input is required."
        ],
        companyRules: [
            (v) => v.split(' ').filter(function(n) { return n != '' }).length <= 1 || "Company key should not contain any spaces!"
        ],
        emailRules:[
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 25,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        numberRules: [           
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        tagsValidation: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        tagsValidation3: [
            {
            classes: 'ttt-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        documentProto: document,
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        warehouseEmailAddress:'',
         arrayNotEmptyRules: [           
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        customersEmail: '',
    }),
    computed: {
        ...mapGetters({
            getCustomers: 'customers/getCustomers',
            getCustomersLoading: 'customers/getCustomerLoading',           
        }),
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        getemail:{
            get(){
             if(typeof  this.data.email !='undefined'){
                return this.data.email
             }
             return '--';
        }},
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        } ,
         className(){
            return 'taginpuerrorfound';
        } ,
        optionsFiltered: {
            get() {
                let validEmailAddress = []
                if (this.editedItem.emails !== null && this.editedItem.emails.length > 0) {
                    this.editedItem.emails.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    })
                }
                return validEmailAddress
            },
            set(value) {
                this.options = value
            }
        },
    },
    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            createCustomers:'customers/createCustomers',
        }),
        
         generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
        },
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$refs.form.resetValidation()
            this.tagsInput.touched = false
            this.options = [],
            this.customerName = ''
            this.company_key = ''
            this.warehouseEmailAddress = ''
            this.$emit('close', false)
        },
        async save() {
            // check the movel
            if(this.warehouseEmailAddress !==''){
              this.myclass =  !this.checkvalidEmailblur(this.warehouseEmailAddress)
                              ?  this.className : 'validinputs'
            }

            if (!this.tagsInput.touched)
                this.tagsInput.touched = true

            this.$refs.form.validate()
            this.tagsInput.hasError = (this.options.length > 0) ? false : false

            //this.generateErrorMessage()
            setTimeout(async () => {
                if (this.$refs.form.validate()) {
                    if (!this.tagsInput.hasError) {
                        try {
                            jQuery('.ti-new-tag-input').trigger(
                            jQuery.Event( 'keyup', { keyCode: 13, which: 13 } )
                            )

                            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                            return o.text
                            }) : []

                            //console.log(finalEmailAddress)
                            // use 
                            
                            await this.addCustomer(finalEmailAddress);

                        } catch (e) {
                            console.log(e)
                        }
                    }
                }
            }, 200)
        },

        setToDefault() {
            this.$emit('setToDefault')
        },
        async addCustomer(email){
            this.errorSaving = 0;
            var dis = this; 
            let formData = new FormData(document.getElementById('createCustomer'))

            if(email.length){
                formData.append('email',  email.join());
            }

            formData.append('emailDefault',this.getemail);
            
            var now_utc = new Date();

            formData.append('now', now_utc );
            this.isFetching = 1;
            
            await axios.post(`${APIBaseUrl}/sendReport/`+this.$route.params.id,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                if(typeof error.type !=undefined) this.typeError = error.type
                if(typeof error.error !=undefined){
                    this.errorContent = error.msg
                }
            }).then(response => {
                this.$emit('close', false)
                dis.startloading =0;
                if(response){
                    dis.tagsInput.touched = 0;
                    dis.$refs['form'].resetValidation()
                    dis.options = [];
                    dis.customerName = '';
                    dis.company_key = ''
                    console.log(response)
                    dis.$emit('updatetooltip', response);
                    dis.$emit('update:notifysavedparent',1);
                    dis.close()
                    dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Report has been sent.</span>')
                }else {
                    if(dis.errorContent !=''){
                        dis.errorSaving = 1;
                    }
                }
                this.isFetching = 0;
            })
        },
        checkvalidEmailblur(mail){
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return mail.match(validRegex)
          }
    },

    updated() {

        if(this.dialog == 0){
            this.errorSaving = 0;
        }

        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    },
    watch:{
        dialogData(c){
            if(c){
                 console.log(this.data)
            }
        }
    },
}
</script>

<style lang="scss">
 @import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
 @import '../../../../assets/scss/pages_scss/customers/customerAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.add-checkbox-recipients  {
    .v-input {
        .v-input__control {
            .v-input__slot {
                label {
                    font-size: 14px !important;
                }
            }
        }
    }
}
</style>

<style scoped>
.text-field{
    color: red !important;
    /* add whatever properties you want */
}
</style>
