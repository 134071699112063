<template>
    <v-dialog v-model="dialog" scrollable max-width="58%" content-class="my-custom-dialog-preview" persistent>
		<v-card>
			<v-card-title class="modal-header">
				<span class="headline">Preview Delivery Order Document</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
			</v-card-title>
				
			<v-card-text>
                <div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
                    <v-progress-circular
                        :size="35"
                        color="#0171A1"
                        indeterminate
                        v-if="isFetching">
                    </v-progress-circular>
                </div>

                <div v-if="!isFetching" style="width: 100%; height: 100%;">
                    <div class="forthis" v-for="file in filelist" :key="file.name" style="width: 100%; height: 100%;">
                        <!-- <iframe reload="false" :data="getFileURL(file)+'#view=FitH#toolbar=0&navpanes=0'" frameborder="0" width="100%" height="100%"></iframe> -->
                        <object :data="getFileURL(file)" frameborder="0" width="100%" height="100%"></object>                
                    </div>
                </div>
			</v-card-text>

            <v-card-actions v-if="!isFetching">
                <div class="actions-wrapper d-flex justify-end align-center" style="width: 100%;">
                    <button type="button" class="btn-white" @click="downloadFileClick(items)" :disabled="isDownloadingFile">
                        {{ isDownloadingFile ? "Downloading..." : "Download" }}
                    </button>
                    <button type="button" class="btn-white" style="color: #4a4a4a !important;" @click="close" :disabled="isDownloadingFile">
                        Close
                    </button>
                </div>
            </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import moment from "moment";
import axios from "axios";
// import _ from "lodash"
import globalMethods from '../../../utils/globalMethods';
import iziToast from "izitoast"

const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
	name: "ShowPreviewDoc",
	props: ["items", "dialogData"],
	components: {},
	computed: {
		dialog: {
			get() {
				return this.dialogData;
			},
			set(val) {
				this.$emit("update:dialogData", val)
			}
		},
	},
	watch: {
        dialog(c) {
            if (c) {
                if (this.items.deliveryorderdocument != null) {
                    this.downloadDocument(this.items.deliveryorderdocument.id);
                }
            }
        }
    },
	data() {
		return {
            isFetching: 0,
            filelist: [],
            isDownloadingFile: false
        };
	},
	methods: {
		...globalMethods,
		getFileURL(file) {
			return URL.createObjectURL(file) + "#view=Fit&toolbar=0";
		},
        downloadDocument(documentID) {
			let passdata = documentID;
			this.isFetching = 1;
			let c = [];
			if (passdata !== "") {
				axios({
					url: `${poBaseUrl}/document/download/${passdata}`,
					method: "GET",
					responseType: "blob",
				}).then((response) => {
					this.isFetching = 0;
					window.URL = window.URL || window.webkitURL;
					var json = response.data;
					c[0] = new File([json], "file.pdf", {
						lastModified: new Date().getTime(),
						type: "application/pdf",
					});
					this.filelist = c;
				});
			}
		},
        downloadFileClick(item) {
			let passdata = item.deliveryorderdocument.id;
			this.isDownloadingFile = true;
			let toastData = {
				message: 'Downloading file...',
				timeout: 2000,
				class: 'downloading-file-toast'
			}
			this.notificationCustomAlert(toastData)

			if (passdata !== "") {
				axios({
					url: `${poBaseUrl}/document/download/${passdata}`,
					method: "GET",
					responseType: "blob",
				}).then((response) => {
					this.isDownloadingFile = false;
					window.URL = window.URL || window.webkitURL;
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(
						new Blob([response.data], {
							type: "application/octet-stream",
						})
					);
					document.body.appendChild(link);
					link.setAttribute("download", item.deliveryorderdocument.filename);
					link.click();
					document.body.removeChild(link);

					var toast = document.querySelector('.iziToast.downloading-file-toast');
					iziToast.hide({}, toast);
					this.notificationMessageCustomSuccess("File has been downloaded!")
				});
			}
		},
        close() {
            this.$emit("close")
        }
	},
	mounted() {},
	updated() {}
};
</script>

<style lang="scss">
.v-dialog.my-custom-dialog-preview:not(.v-dialog--fullscreen) {
	max-height: 95%;
    height: 95%;
    left: 0 !important;
    position: absolute!important;
    border-radius: 0!important;
}
</style>