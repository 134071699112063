<template>
    <v-dialog 
        persistent 
        v-model="dialog" 
        max-width="590px" 
        content-class="delete-driver-dialog dialogCreatelink"  
        :retain-focus="false"
        >
        <v-card 
          style="padding-bottom:8px">
           <v-card-title class="text-h6 lighten-2">
            Share DO Form
          </v-card-title>
          <v-card-text 
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >            
           

            <small>Link is reusable and anyone on the internet with the link can use</small>
            <div style="display:flex">
              <v-text-field
                style="width:81%"
                  placeholder=""
                  required
                  solo        
                  v-model="datalink"                     
                  name="vessel_name"
                  hide-details="auto"                
                  >
              </v-text-field>
              <span @click="copylink" class="copylink" style="">Copy Link</span>
            </div>
            <input type="hidden" name="myInput" id="myInput"/>
           
          </v-card-text>
          <v-card-actions 
            style="border-top: 1px solid #E1ECF0;
margin-top: 19px"
            >
            <v-btn 
                @click="closedialog" 
                class="btn-white"
                >
                <span>
                    <span>
                       Close
                    </span>
                </span>
            </v-btn>  
             <span style="color:green" v-if="copied">Copied to clipboard</span>         
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'CreateCustomerLink',
    data(){
      return { 
        copied:0,      
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData','datalink'], 
    computed: { 
        dialog: {
            get () {               
              return this.dialogData
            }       
        },                       
    },
    watch:{
      dialogData(c){
        if(c){
          console.log(this.datalink)
        }
      }
    },   
    methods: {  
        async copylink(){
          this.copied = 1;         
          await navigator.clipboard.writeText(this.datalink);
          setTimeout(() => this.copied = false, 1000);
        },   
        closedialog() {             
          this.$emit("close",1); 
        },             
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}

.dialogCreatelink .delivery_header {
  border-bottom: 1px solid #eee;
}

.dialogCreatelink .v-input__slot {
  box-shadow: none !important;
  background: #FFFFFF;
/* Light Grey */
padding-left:0px !important ;
border: 1px solid #B4CFE0;
border-radius: 4px 0px 0px 4px
}

.dialogCreatelink  input {
  padding: 10px;
}
.copylink {
  border: 1px solid #B4CFE0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 10px;
  padding-left: 11px;
  padding-right: 12px;
  border-left: 0px;
  height: inherit;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0171A1;
}
</style>