<template>
    <v-dialog  persistent v-model="dialogEdit" max-width="590px" content-class="add-supplier-dialog add-driver-dialog" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-form ref="form" id="updatedriver" v-model="valid" action="#" @submit.prevent="">
                <v-card-title style=" caret-color: transparent;">
                    <span class="headline">Edit Driver Information</span>

                    <button icon dark class="btn-close" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <p v-if="errorSaving" style="color:red">{{errorMessage}}</p>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label">First Name</label>
                            <v-text-field
                                placeholder="Enter first name"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="editedItem.name"
                                :rules="[fullnameValidate, ...rules]"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label">Last Name</label>
                            <v-text-field
                                placeholder="Enter last name"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="editedItem.last_name"
                                :rules="[fullnameValidate, ...rules]"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label">Email Address</label>
                             <v-text-field
                                placeholder="Enter Email Address"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="warehouseEmailAddress"
                                :rules="emailRules"
                                name="name"
                                hide-details="auto">
                                </v-text-field>

                        </v-col>



                        <v-col cols="12" sm="12" md="12" class="pb-1">
                            <label class="text-item-label">Phone Number</label>
                            <vue-tel-input-vuetify
                                class="text-fields DialogInputfield"
                                type="number"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :valid-characters-only="true"
                                :rules="numberRules"
                                v-bind="telProps"
                                v-model="editedItem.phone_number" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                      <v-col class="text-right buttonActions" style="padding-right:0px">
                    <v-btn class="btn-blue" text @click="save">
                        <span>
                            <span>
                                 <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >
                                    </v-progress-circular>
                               Update Driver
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                        Cancel
                    </v-btn>
                      </v-col>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
//import VueTagsInput from '@johmun/vue-tags-input';
import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'DriverDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData','edititem','idrow'],
    components: {
        VueTelInputVuetify,
        //VueTagsInput
    },
    data: () => ({
        passwordMsg : 'Minimum of 8 characters at least one numeric digit and a special character.',
        passwordModel: '',
        hasError: false,
        errorSaving:0,
        errorContent:'',
        isFetching:0,
        driverID: '',
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "This Field is required."
        ],
         emailRules:[
            (v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 25,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        numberRules: [
            // (v) => !!v || "Input is required.",
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        warehouseEmailAddress: '',
        errorMessage: ''
    }),
    computed: {
        ...mapGetters({
            //: 'getUser',
            //   getCreateSuppliersLoading: 'suppliers/getCreateSuppliersLoading',
            //   getSupplierSaveAddLoading: 'suppliers/getSupplierSaveAddLoading'
        }),
        dialogEdit: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        }
    },
    methods: {
        ...mapActions({
            // createSuppliers: 'suppliers/createSuppliers',
            // fetchSuppliers: 'suppliers/fetchSuppliers',
            // updateSuppliers: 'suppliers/updateSuppliers'
        }),
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
         fullnameValidate(val){
            if(val !='' && typeof val !='undefined'){
                if (!val.match(/^[a-z\d\-_\s]+$/i))
                  return 'Invalid Name'
            }
            return true;
        },
         passwordRules(inputtxt)
         {
            if(inputtxt==''){
                this.hasError = false;
                return true;
            } else if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(inputtxt) ){
                this.hasError = false;
                return true;
            }else {
                this.hasError = true;
                return true;
            }
        },
        callPopulatefields(){
            if( Object.keys(this.editedItemData).length !== 0 ){
                this.warehouseEmailAddress = this.editedItemData.email;
                this.editedItem.name =  this.editedItemData.name;
                this.editedItem.last_name =  this.editedItemData.last_name;
                this.editedItem.phone_number = this.editedItemData.phone_number;
                this.driverID = this.editedItemData.driver_id;
            }
        },
        close() {
            this.$refs.form.resetValidation()
            this.passwordModel = '';
            this.hasError = false;
            this.$emit('update:dialogData', false)
        },
        async save() {
            setTimeout(async () => {
                if (this.$refs.form.validate() && this.hasError == false) {
                        try {
                             this.updatingDriver();
                        } catch (e) {
                            this.notificationError(e)
                        }
                    }

            }, 200)
        },
        updatingDriver(){
            this.isFetching = 1 ;
            var id = this.idrow;
            var dis = this;
            this.errorSaving = 0;
            this.errorMessage = '';
            axios.put(`${APIBaseUrl}/updateDriver/`+id,
            {
                name: this.editedItem.name,
                last_name: this.editedItem.last_name,
                email:this.warehouseEmailAddress,
                phone_number:this.editedItem.phone_number,
                // password:this.passwordModel
            }
            )
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorSaving = 1;
                if(typeof error.error.email !="undefined"){
                    this.errorMessage = error.error.email[0]
                } else{
                   this.errorMessage = error.msg
                }
            }).then(response => {
                if(response){
                    this.$refs['form'].resetValidation()
                    this.driverName = '';
                    this.editedItem.phone_number = '';
                    this.driverEmailAddress = '';
                    this.hasError = false;
                    this.$emit('update:notifyupdate',1);
                    this.close()
                    dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Driver has been updated.</span>')
                }
                this.isFetching = 0;
            })
        },
        setToDefault() {
            this.$emit('setToDefault')
        }
    },
    watch: {
        dialogEdit(value, oldValue) {
            this.errorSaving = 0;
            if(value || oldValue){
                this.callPopulatefields();
            }
        }
    },
    updated() {
        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
