<template>
    <div class="supplier-container customer-wrapper" v-resize="onResize">
		<!-- DESKTOP -->

        <CustomerDesktopTable
            @deleteCustomer="deleteCustomer"
            :reloadcomponent = "reloadcomponent"
            :items="itemdata"
            :companykey="companykey"
            :companyname="companyname"
            :phone="phone"
            :emails="getemails"
            :drivers="driverobject"
            v-if="renderComponent"
        />


        <!-- <CustomerDialog
            :notifysavedparent.sync ="tellparent"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialog"
            :editedItemData.sync="editedItem"
            @notifysavedparent = 'notifyadd'
        />

        <CustomerEditDialog
            :notifyupdate.sync="notifyupdate"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogEdit"
            :editedItemData.sync="editedItem"
        />

        <CustomerDeleteDialog
            :notifyDeleteitem.sync="isdeleted"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogDelete"
            :editedItemData.sync="editedItem"
        /> -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerDesktopTable from '../components/Tables/Customers/CustomerDetailsDesktopTable.vue'

export default {
    name: "Customers",
	components: {
        CustomerDesktopTable,
	},
    data: () => ({
        add:'New customer has been added',
        edit:'Customer successfully edited',
        msgcontent: '',
        renderComponent: true,
        tellparent:0,
        isdeleted:0,
        isaddedCustomer:0,
        notifyupdate:0,
        dialogEdit: false,
		page: 1,
        pageCount: 0,
        itemsPerPage: 35,
        dialog: false,
        dialogDelete: false,
        reloadcomponent: 0,
        editedIndex: -1,
        editedItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
        defaultItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
		search: '',
		isMobile: false
    }),
    computed: {
        ...mapGetters({
             getCustomers: 'customers/getCustomers',
             getCustomerdata: 'customers/getcustomerdetails',
             getCustomersLoading: 'customers/getCustomerLoading'
        }),
        itemdata(){
            var data = [];
            if(typeof this.getCustomerdata.data !== "undefined") {
                data = this.getCustomerdata.data;
            }
            return data;
        },
        getemails(){
            var data = [];
            if(typeof this.getCustomerdata.getemails !== "undefined") {
                data = this.getCustomerdata.getemails;
            }
            return data;
        },
        companyname(){
            var data = '';
            if(typeof this.getCustomerdata.company_name !== "undefined") {
                data = this.getCustomerdata.company_name;
            }
            return data;
        },
        phone(){
            var data = [];
            if(typeof this.getCustomerdata.company_phone !== "undefined") {
                data = this.getCustomerdata.company_phone;
            }
            return data;
        },
        companykey(){
            var data = [];
            if(typeof this.getCustomerdata.company_key !== "undefined") {
                data = this.getCustomerdata.company_key;
            }
            return data;
        },
        driverobject(){
             var data = [];
            if(typeof this.getCustomerdata.driver !== "undefined") {
                data = this.getCustomerdata.driver;
            }
            return data;
        },
        customers() {
            let array = [];
            var response =  this.getCustomers;
                response.forEach((value) =>{
                    var text = value.emails;
                    const myArray = text.split(",");
                    let m =  {
                                customer_id: value.id,
                                name: value.compnay_name,
                                phone_number: value.phone == null ? '--': value.phone,
                                central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
                                hashId: value.hashId,
                                emails: Object.assign({}, myArray),
                                emailsaddress: value.emails == '' ? '--': value.emails,
                                }
                    array.push(m)
            });
            return array
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogEdit(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.close();
        },
        tellparent(v){
            if(v){
                this.reloadcomponent = 1;
                this.msgcontent = this.add;
                this.isaddedCustomer = true;
                setTimeout(async () => {
                    this.isaddedCustomer = false;
                    this.tellparent = 0;
                }, 10000)
                this.renderCall(v)
           }
        },
        isdeleted(v){
           if(v){
            this.reloadcomponent = 1;
            this.isdeleted = 0;
            this.renderCall(v)
           }
        },
        notifyupdate(v){

            if(v){
                this.reloadcomponent = 1;
                this.msgcontent = this.edit;
                this.isaddedCustomer = true;

                this.isaddedCustomer = false;
                this.notifyupdate = 0;
                this.renderCall(v)
            }
        }
    },
    created() {},
    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            fetchCustomersdetails: 'customers/fetchCustomerDetails'
        }),
        addCustomer() {
            this.dialog = true;
        },

        editCustomer(item) {
            this.editedIndex = this.customers.indexOf(item);
            try{
                 if(item.central_customer_key == '--'){
                    item.central_customer_key = ''
                 }
            }catch(err){
                console.log(err)
            }
            this.editedItem = Object.assign({}, item);
            this.dialogEdit = true;
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        deleteCustomer(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        notifyadd(){
        },
         renderCall(){
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
           });
        },
        deleteSupplierConfirm() {
            this.suppliers.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
		onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        setToDefault() {
            this.editedItem = this.defaultItem
            this.close()
            this.dialog = true
        }
    },
    async mounted() {
        this.$store.dispatch("page/setPage", "customers");
        if (!Object.keys(this.getCustomerdata).length) {
            //
        }
       await this.fetchCustomersdetails(this.$route.params.id)

    }
};
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/customers/customers.scss';
@import '../assets/scss/pages_scss/customers/customer2.scss';
@import '../assets/scss/buttons.scss';
@import '../assets/css/dialog_styles/dialogHeader.css';
@import '../assets/css/dialog_styles/dialogBody.css';
@import '../assets/css/dialog_styles/dialogFooter.css';
</style>
