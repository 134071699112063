<template>
    <div class="supplier-container customer-wrapper" v-resize="onResize">
		<!-- DESKTOP -->

        <CustomerDesktopTable
            :items="customers"
            @showdetails="showdetails"
            @addCustomer="addCustomer"
            @editCustomer="editCustomer"
            @deleteCustomer="deleteCustomer"
            :isMobile="isMobile"
            :reloadcomponent = "reloadcomponent"
            v-if="!isshowdetails"
        />


        <CustomerDialog
            :notifysavedparent.sync ="tellparent"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialog"
            :editedItemData.sync="editedItem"
            @notifysavedparent = 'notifyadd'
            :getCurrentPage="getCurrentPage"
        />

        <CustomerEditDialog
            :notifyupdate.sync="notifyupdate"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogEdit"
            :editedItemData.sync="editedItem"
            :getCurrentPage="getCurrentPage"
        />

        <CustomerDeleteDialog
            :notifyDeleteitem.sync="isdeleted"
            :editedIndexData.sync="editedIndex"
            :dialogData.sync="dialogDelete"
            :editedItemData.sync="editedItem"
            :getCurrentPage="getCurrentPage"
        />

        <CustomerShowdetailsTable
            :items="datashowdetails"
            :drivers="drivers"
            v-if="isshowdetails"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerDialog from '../components/CustomerComponents/CustomerDialog.vue'
import CustomerEditDialog from '../components/CustomerComponents/CustomerEditDialog.vue'
import CustomerDeleteDialog from '../components/CustomerComponents/CustomerDeleteDialog.vue'
import CustomerDesktopTable from '../components/Tables/Customers/CustomerDesktopTable.vue'
import CustomerShowdetailsTable from '../components/Tables/Customers/CustomerDetailsDesktopTable.vue'
//import SupplierMobileTable from '../components/Tables/Supplier/SupplierMobileTable.vue'

export default {
    name: "Customers",
	components: {
		CustomerDialog,
		CustomerEditDialog,
		CustomerDeleteDialog,
        CustomerDesktopTable,
        CustomerShowdetailsTable
       //// SupplierMobileTable
	},
    data: () => ({
        datashowdetails:[],
        isshowdetails:0,
        add:'New customer has been added',
        edit:'Customer successfully edited',
        msgcontent: '',
        renderComponent: true,
        tellparent:0,
        isdeleted:0,
        isaddedCustomer:0,
        notifyupdate:0,
        dialogEdit: false,
		page: 1,
        pageCount: 0,
        itemsPerPage: 35,
        dialog: false,
        dialogDelete: false,
        reloadcomponent: 0,
        editedIndex: -1,
        editedItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
        defaultItem: {
            company_name: "",
            phone: "",
            address: "",
            emails: null,
            central_customer_key: ""
        },
		search: '',
		isMobile: false
    }),
    computed: {
        ...mapGetters({
             getCustomers: 'customers/getCustomers',
             getCustomersLoading: 'customers/getCustomerLoading'
        }),
        drivers(){
            let drivers = [];
            var response =  this.getCustomers;
            Object.entries(response).forEach(([key,value2])=>{
                if(key=='drivers'){
                   drivers = value2
                }
            });
            return drivers;
        },
        customers() {
            let array = [];
            // let drivers = [];
            var response =  this.getCustomers;

            if (typeof response.data !== "undefined" && response.data.length !== 0) {
                array = response.data.map(value => {
                    var text = value.emails;
                    const myArray = text.split(",");

                    return {
                        customer_id: value.id,
                        name: value.company_name,
                        phone_number: value.phone == null ? '--': value.phone,
                        central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
                        hashId: value.hashId,
                        emails: Object.assign({}, myArray),
                        emailsaddress: value.emails == '' ? '--': value.emails,
                        shipments: value.shipments,
                        lastreport: value.lastreport,
                        details: value.details,
                        getManageReportSetting: value.getManageReportSetting,
                        shipments_object: value.shipments_object
                    }
                })
            }

            // Object.entries(response).forEach(([key,value2])=>{
            //    if(key=='data'){
            //         Object.values(value2).forEach(value => {
            //             var text = value.emails;
            //             const myArray = text.split(",");
            //             let m =  {
            //                         customer_id: value.id,
            //                         name: value.compnay_name,
            //                         phone_number: value.phone == null ? '--': value.phone,
            //                         central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
            //                         hashId: value.hashId,
            //                         emails: Object.assign({}, myArray),
            //                         emailsaddress: value.emails == '' ? '--': value.emails,
            //                         shipments: value.shipments,
            //                         lastreport: value.lastreport,
            //                         details: value.details,
            //                         getManageReportSetting: value.getManageReportSetting
            //                     }
            //             array.push(m)
            //         })
            //    }

            //     if(key=='drivers'){
            //         Object.values(value2).forEach(value => {
            //            drivers.push(value)
            //         })
            //     }
            // });

            return array
        },
        getCurrentPage: {
            get() {
                let current_page = 1
                let customers = this.getCustomers

                if (typeof customers.current_page !== 'undefined' && customers.current_page !== null) {
                    current_page = customers.current_page
                }

                return current_page
            },
            set() {
                return {}
            }
        },
        getTotalPage: {
            get() {
                let total = 1
                let customers = this.getCustomers

                if (typeof customers.last_page !== 'undefined' && customers.last_page !== null) {
                    total = customers.last_page
                }

                return total
            }
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogEdit(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.close();
        },
        tellparent(v){
            if(v){
                this.reloadcomponent = 1;
                this.msgcontent = this.add;
                this.isaddedCustomer = true;
                setTimeout(async () => {
                    this.isaddedCustomer = false;
                    this.tellparent = 0;
                }, 10000)
                this.renderCall(v)
           }
        },
        isdeleted(v){
           if(v){
            this.reloadcomponent = 1;
            this.isdeleted = 0;
            this.renderCall(v)
           }
        },
        notifyupdate(v){

            if(v){
                this.reloadcomponent = 1;
                this.msgcontent = this.edit;
                this.isaddedCustomer = true;

                this.isaddedCustomer = false;
                this.notifyupdate = 0;
                this.renderCall(v)
            }
        }
    },
    created() {},
    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            fetchCustomersv2: 'customers/fetchCustomersv2'
        }),
        showdetails(item){
            this.isshowdetails   = 1
            this.datashowdetails = item;
        },
        addCustomer() {
            this.dialog = true;
        },
        editCustomer(item) {
            this.editedIndex = this.customers.indexOf(item);
            try{
                 if(item.central_customer_key == '--'){
                    item.central_customer_key = ''
                 }
            }catch(err){
                console.log(err)
            }
            this.editedItem = Object.assign({}, item);
            this.dialogEdit = true;
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        deleteCustomer(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        notifyadd(){
        },
         renderCall(){
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
           });
        },
        deleteSupplierConfirm() {
            this.suppliers.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
		onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        setToDefault() {
            this.editedItem = this.defaultItem
            this.close()
            this.dialog = true
        }
    },
    async mounted() {
        this.$store.dispatch("page/setPage", "customers");
        await this.fetchCustomersv2(1)
    }
};
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/customers/customers.scss';
@import '../assets/scss/pages_scss/customers/customer2.scss';
@import '../assets/scss/buttons.scss';
@import '../assets/css/dialog_styles/dialogHeader.css';
@import '../assets/css/dialog_styles/dialogBody.css';
@import '../assets/css/dialog_styles/dialogFooter.css';
</style>
