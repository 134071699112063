<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="709px"
    content-class="add-supplier-dialog add-container-dialog DialogEditContainer"
    v-resize="onResize"
    :retain-focus="false"
  >
    <v-card class="add-supplier-card">
      <v-form
        ref="form"
        id="createContainer"
        v-model="valid"
        action="#"
        @submit.prevent=""
      >
        <v-card-title>
          <span class="headline">Edit Container</span>
          <button icon dark class="btn-close" @click="close">
            <v-icon>mdi-close</v-icon>
          </button>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pb-4 pt-2">
              <label class="text-item-label">Container Number</label>
              <v-text-field
                placeholder="E.g ABCDEFG123456789"
                outlined
                required
                class="text-fields DialogInputfield"
                v-model="container_number"
                :rules="container_number_rules"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3" md="3" class="pb-0 pt-0">
              <label class="text-item-label">Size</label>
              <v-autocomplete
                class="containersizemodal"
                :rules="selectRules"
                v-model="container_sizetest"
                :items="itemsDropdownSize"
                item-text="name"
                item-value="id"
                placeholder="Select Size"
                solo
                append-icon="ic-chevron-down"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" sm="3" md="3" class="pb-0 pt-0">
              <label class="text-item-label">Weight</label>
              <v-text-field
                type="number"
                placeholder="Input Weight KG"
                outlined
                required
                class="text-fields DialogInputfield"
                v-model="container_weight"
                :rules="numberRules"
                hide-details="auto"
              >
                <span slot="append" class="px-2 py-3 text--secondary">
                  kg
                </span>
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3" class="pb-0 pt-0">
              <label class="text-item-label">Volume</label>
              <v-text-field
                type="number"
                placeholder="Input Volume CBM"
                outlined
                class="text-fields DialogInputfield"
                v-model="container_volume"
                :rules="numberRules"
                hide-details="auto"
              >
                <span slot="append" class="py-3 text--secondary"> cbm </span>
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3" class="pb-0 pt-0">
              <label class="text-item-label">Qty</label>
              <v-text-field
                type="number"
                placeholder="Input Quantity"
                outlined
                class="text-fields DialogInputfield"
                v-model="container_qty"
                :rules="numberRules"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
              <label class="text-item-label">Delivery To</label>
              <v-autocomplete
                :loading="loadingLocationPlaces"
                v-model="DeliveryTo"
                :items="places"
                :search-input.sync="searchPlace"
                placeholder="Type Delivery Addresss"
                item-text="place_name"
                item-value="place_name"
                no-filter
                :label="DeliveryTo"
                clearable
                hide-no-data
                @click:clear="clearSearchPlaces"
                @change="changeDeliveryLocationState"
                :rules="rules"
                filled
                full-width
                name="custom_notes"
              ></v-autocomplete>
              <!-- <v-textarea
                v-model="DeliveryTo"
                placeholder="Type Delivery Address"
                rows="2"
                :rules="deliveryto"
                name="custom_notes"
                required
              >
              </v-textarea> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="border-top: 2px solid #ebf2f5 !important">
          <v-col
            class="text-left buttonActions"
            style="padding-right: 0px; padding-left: 0px"
          >
            <v-btn
              style="caret-color: transparent"
              class="btn-blue"
              text
              @click="save"
            >
              <span>
                <span style="caret-color: transparent">
                  <v-progress-circular
                    :size="15"
                    color="#fff"
                    :width="2"
                    indeterminate
                    v-if="isFetching"
                    style="margin-right: 3px"
                  >
                  </v-progress-circular>
                  Save Changes
                </span>
              </span>
            </v-btn>
            <v-btn class="btn-white" text @click="close" v-if="!isMobile">
              Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../../utils/globalMethods";
export default {
  name: "EditContainerDialog",
  props: ["dialogData", "editdata", "bus", "ContainerSize", "keytovalue"],
  components: {},
  data() {
    return {
      test: "Bohol",
      container_sizetest: "",
      searchPlace: "Bohol",
      loadingLocationPlaces: false,
      places: [],
      callApiForDeliveryLoc: true,
      containersize: "",
      container_number: "",
      container_size: "",
      container_weight: "",
      container_volume: "",
      container_qty: "",
      DeliveryTo: "",
      typeError: "", // type of error return from api
      isFetching: 0,
      valid: true,
      value: [],
      isMobile: false,
      rules: [(v) => !!v || "Input is required."],
      numberRules: [
        (v) => {
          var reg = new RegExp("^[0-9.0-9]+$");
          if (v && !reg.test(v)) {
            return "Input should be a number";
          }
          return true;
        },
      ],
      container_number_rules: [
        (text) => {
          if (text == "") return "Container # is required.";
          let msg =
            "The first 4 characters are alpha and followed by 7 digits.";
          if (text.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
              /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
              return true;
            }
            return msg;
          }
          return "First 4 characters are alpha and followed by 7 digits.";
        },
      ],
      selectRules: [(v) => !!v || "This field is required"],
      deliveryto: [(v) => !!v || "Delivery To is required"],
    };
  },
  watch: {
    dialog(c) {
      if (c) {
        this.container_number = this.editdata.container_number;
        this.container_weight = this.editdata.container_weight;
        this.container_volume = this.editdata.container_volume;
        this.container_qty = this.editdata.container_qty;
        this.DeliveryTo = this.editdata.delivery_to;
        this.containerid = this.editdata.container_id;
        console.log(this.editdata.container_size);
        let container_sizetest = this.ContainerSize.find(
          (e) => e.name == this.editdata.container_size
        );
        if (typeof container_sizetest === "undefined") {
          if (this.editdata.container_size != null) {
            let getagain = this.ContainerSize.find(
              (e) => e.id == this.editdata.container_size
            );
            this.container_sizetest = getagain.id;
          } else {
            this.container_sizetest = "";
          }
        } else {
          this.container_sizetest = container_sizetest.id;
        }
      }
    },
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
    // searchPlace(val) {
    //   if (val) {
    //     this.getDeliveryLocation(val);
    //   } else {
    //     this.getDeliveryLocation(this.editdata.delivery_to);
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      getCustomers: "customers/getCustomers",
      getCustomersLoading: "customers/getCustomerLoading",
    }),
    itemsDropdownSize: {
      get() {
        return this.ContainerSize;
      },
    },
    dialog: {
      get() {
        return this.dialogData;
      },
      set(value) {
        if (!value) {
          this.$emit("update:dialogData", false);
        } else {
          this.$emit("update:dialogData", true);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCustomers: "customers/fetchCustomers",
      createCustomers: "customers/createCustomers",
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    close() {
      this.$refs.form.resetValidation();
      this.$emit("update:dialogData", false);
    },
    async save() {
      if (!this.places.length > 0) {
        return;
      }

      const selectedPlace = this.places.find(
        (element) => element.place_name === this.DeliveryTo
      );
      if (this.$refs.form.validate()) {
        var sizeid = 0;
        if (typeof this.container_sizetest.id == "undefined") {
          sizeid = this.container_sizetest;
        } else {
          sizeid = this.container_sizetest.id;
        }

        this.$emit("updateContainer", {
          container_id: this.containerid,
          container_number: this.container_number,
          container_size: sizeid,
          container_volume: this.container_volume,
          container_weight: this.container_weight,
          container_qty: this.container_qty,
          delivery_to: this.DeliveryTo,
          deliveryLongitude: selectedPlace.geometry
            ? selectedPlace.geometry.coordinates[0]
            : null,
          deliveryLatitude: selectedPlace.geometry
            ? selectedPlace.geometry.coordinates[1]
            : null,
          error_0: 0,
          checked: this.editdata.checked,
        });
        this.$refs.form.resetValidation();
        this.resetform();
        this.$emit("update:dialogData", false);
      }
    },
    setToDefault() {
      this.$emit("setToDefault");
    },
    resetform() {
      this.container_number = "";
      this.container_size = "";
      this.container_weight = "";
      this.container_volume = "";
      this.container_qty = "";
      this.DeliveryTo = "";
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
  },
  mounted() {
    // alert(this.editdata.container_size)
    //   let container_sizetest =  this.ContainerSize.find((e) =>  e.name == this.editdata.container_size);
    //   if(typeof container_sizetest ==='undefined'){
    //     let getagain =  this.ContainerSize.find((e) =>  e.id == this.editdata.container_size);
    //     this.container_sizetest = (getagain.id)
    //   }else{
    //     this.container_sizetest = (container_sizetest.id)
    //   }
  },
};
</script>
<style lang="scss">
@import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../assets/scss/pages_scss/deliveryorder/deliveryAddDialog.scss";

.v-autocomplete__content.v-menu__content {
  border-radius: 4px !important;
}
</style>

<style scoped>
.text-field {
  color: red !important;
  /* add whatever properties you want */
}

.v-dialog__content .DialogEditContainer .v-textarea .v-input__slot {
  border: 1px solid #b4cfe0 !important;
}

.containersizemodal .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #b4cfe0 !important;
}
.containersizemodal .v-icon {
  font-size: 13px;
}
</style>
