<template>
  <div class="container-create container-edit">
    <DiscardChanges
      :dialog="discard_changes_modal"
      @discardChanges="discardChanges"
    />
    <div style="overflow: visible !important">
      <span class="containerTitle" style="font-size: 12px; scolor: #4a4a4a">
        Containers
      </span>
      <span style="position: relative; top: 3.4px" class="ic-chevron-right">
      </span>
      <span class="referenceName">{{ queryData.shifl_ref }} </span>
    </div>
    <v-toolbar class="toolbaredit" flat>
      <v-toolbar-title style="overflow: visible !important">
        <span class="referrenceTitle">
          Reference {{ queryData.shifl_ref }}
        </span>
        <span v-if="currentState" class="statuses">
          {{ currentState }}
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :class="noerror == 1 ? 'btn-blue submitNow' : 'btn-white saveCreateBtn'"
        depressed
        @click="saveChanges"
        color="primary"
        :disabled="noerrorForm || isSaveChangesLoading"
      >
        <span v-if="isSaveChangesLoading">
          <v-progress-circular
            :size="20"
            color="#0171a1"
            indeterminate>
          </v-progress-circular>
        </span>

        <span v-else>Save Changes</span>
      </v-btn>
      <!-- <v-btn
                class="btn-white saveAndAnother"
                depressed
                @click="clicsubmit('saveAndAnother')"
                color="primary"
                >
                Save & Add Another
            </v-btn> -->
      <v-btn
        style="margin-left: 8px"
        class="btn-white createCancelBtn"
        depressed
        @click="cancel"
        color="primary"
      >
        Cancel
      </v-btn>
    </v-toolbar>
    <v-tabs class="tabstop" v-model="tab" background-color="transparent">
      <v-tabs-slider color="#0171A1"> </v-tabs-slider>
      <v-tab
        v-for="(item, index) in tabsNavigation"
        :key="item"
        @click="tabsnavigationClick(index)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <div class="spinner mt-4" v-if="!stillLoading">
      <v-progress-circular
        :size="40"
        color="#0171a1"
        indeterminate
        v-if="startloading"
      >
      </v-progress-circular>
    </div>
    <div v-if="tabscurrent == 0" class="tab1">
      <v-form
        v-if="this.mode == 'edit'"
        id="sform"
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
        <v-row>
          <v-col class="leftcol" md="8">
            <v-row>
              <v-col class="etacol" cols="6" md="6">
                <label class="text-item-label">Estimated Time Of Arrival</label>
                <div class="vc-date-picker__wrapper">
                  <vc-date-picker
                    title-position="left"
                    is-expanded
                    v-model="date"
                    mode="date"
                    :popover="{ visibility: 'click' }"
                    :firstDayOfWeek="2"
                    :masks="masks"
                    :select-attribute="attribute">

                    <template #default="{ inputValue, inputEvents }">
                        <!-- <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="create-do-eta-dxdate-vc"
                          placeholder="Select ETA"
                        /> -->
                        <v-text-field
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="05/21/2021"
                          append-icon="ic-calendar"
                          name="ETA"
                          :rules="validateDaterules"
                          @click:append="showcustom(date, 'date')"
                        >
                        </v-text-field>
                    </template>
                  </vc-date-picker>
                </div>
                <!-- <v-text-field
                  v-model="date"
                  placeholder="05/21/2021"
                  append-icon="ic-calendar"
                  name="ETA"
                  :rules="validateDaterules"
                  @click:append="showcustom(date, 'date')"
                >
                </v-text-field> -->
              </v-col>
              <v-col class="selectBox" cols="6" sm="6">
                <small>CUSTOMER</small>
                <v-autocomplete
                  v-model="customer"
                  :items="itemsDropdownComputed"
                  :rules="customerRules"
                  item-text="company_name"
                  item-value="id"
                  placeholder="Select Customer"
                  solo
                  @mousedown="dataDown('customer')"
                  @change="getmeselected"
                  append-icon="ic-chevron-down"
                >
                </v-autocomplete>
                <input
                  type="hidden"
                  name="customer_id"
                  :value="customerSelected"
                />
              </v-col>
            </v-row>
            <v-row style="margin-top: 0px">
              <v-col
                class="selectBox"
                cols="12"
                md="12"
                style="padding-top: 3px"
              >
                <label class="text-item-label"> Contacts </label>
                <div class="tags-email-wrapper mb-1">
                  <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRules"
                    :tags="options"
                    :add-on-blur="true"
                    :class="myclass"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="
                      myclass.length > 0 ? tagsValidation : tagsValidation
                    "
                    v-model="warehouseEmailAddress"
                    placeholder="Enter email address"
                    @tags-changed="
                      (newTags) => {
                        this.myclass = 'noclass';
                        this.options = newTags;
                        this.tagsInput.touched = true;
                        this.tagsInput.hasError =
                          this.options.length > 0 ? false : true;
                        let el =
                          this.documentProto.getElementsByClassName(
                            'ti-input'
                          )[0];
                        if (typeof el !== 'udnefined') {
                          /*if (this.tagsInput.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                        }
                      }
                    "
                  />
                </div>
                <div
                  v-if="tagsInput.touched && tagsInput.hasError"
                  class="v-text-field__details"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        v-if="
                          options.length > 0 && warehouseEmailAddress !== ''
                        "
                        class="v-messages__message"
                      >
                        {{ tagsInput.errorMessage }}
                      </div>
                      <div
                        v-if="
                          options.length == 0 && warehouseEmailAddress !== ''
                        "
                        class="v-messages__message"
                      >
                        {{ tagsInput.errorMessage }}
                      </div>
                      <div
                        v-if="
                          options.length == 0 && warehouseEmailAddress == ''
                        "
                        class="v-messages__message"
                      >
                        Please provide at least 1 valid email address.
                      </div>
                    </div>
                  </div>
                </div>
                <span style="color: #819fb2; font-size: 12px">
                  <i style="font-size: 12px; color: #819fb2">
                    Separate multiple email addresses with comma
                  </i>
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" md="6" class="selectBox">
                <label class="text-item-label">Carrier</label>
                <v-autocomplete
                  v-model="CarrierModel"
                  :items="carriers"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Carrier"
                  name="carrier_id"
                  solo
                  append-icon="ic-chevron-down"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <label class="text-item-label">Vessel</label>
                <v-text-field
                  placeholder="Vessel"
                  required
                  v-model="VesselModel"
                  name="vessel_name"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" md="6">
                <small>MBL NUMBER</small>
                <v-text-field
                  placeholder="Enter MBL #"
                  required
                  v-model="mbl"
                  :rules="mblRules"
                  name="mbl_num"
                  hide-details="auto"
                  @keyup="blurevent"
                  class="uppercase-text"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <small>CONTAINER NUMBER</small>
                <v-text-field
                  placeholder="Enter Container Number"
                  required
                  v-model="containerNumber"
                  :rules="containerNumberRules"
                  name="trucker_container"
                  hide-details="auto"
                  @keyup="blurevent"
                  class="uppercase-text"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="rowRemovemargin">
              <v-col class="selectBox" cols="6" md="6">
                <label class="text-item-label"
                  >Container Starting Location</label
                >
                <v-autocomplete
                  v-model="terminalAt"
                  :rules="TerminalAtRules"
                  :items="terminalsDropdown"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Terminal"                  
                  @mousedown="dataDown('TERMINAL AT')"
                  @change="getmeselected"
                  append-icon="ic-chevron-down"
                  :filter="onFilter"
                  attach
                  class="startingDOSclass"
                >
                <template slot="item" slot-scope="{ item }">
                    <template v-if="typeof item == 'object'">                            
                        <div class="listDropdown" style="font-size:12px">
                        {{ item.firms_code }} <span style="display: inline-block;width: 2px; border-right: 1px solid #000; height: 8.6px;  margin-right: 3px;  margin-left: 3px;" class="strtinglocationh"></span> {{ item.name }}
                        </div>
                    </template>
                </template>
                </v-autocomplete>
                <input
                  type="hidden"
                  name="terminal_id"
                  :value="terminalatSelected"
                />
              </v-col>
              <v-col class="selectBox" cols="6" md="6">
                <label class="text-item-label">CONTAINER SIZE</label>
                <v-autocomplete
                  v-model="containerSizeModel"
                  :items="sizeDropdown"
                  :rules="containersizeRules"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Size"
                  solo
                  @mousedown="dataDown('CONTAINER SIZE')"
                  @change="getmeselected"
                  append-icon="ic-chevron-down"
                >
                </v-autocomplete>
                <input
                  type="hidden"
                  name="container_size_id"
                  :value="containersizeSelected"
                />
              </v-col>
            </v-row>
            <v-row class="notesrow">
              <v-col cols="6" md="6" class="goods-delivey-tab">
                <label class="text-item-label">
                  {{
                    items && items.alldata && items.alldata[0].is_export
                      ? "Goods Pickup Address"
                      : "Goods Delivery Address"
                  }}</label
                >
                <!-- <v-text-field
								v-model="deliverTo"
								:rules="delivertoRules"
								placeholder="Deliver To"
								required
								name="deliver_to"
								hide-details="auto"
								@keyup="blurevent"
							>
							</v-text-field> -->
                <v-autocomplete
                  :loading="loadingLocationPlaces"
                  name="deliver_to"
                  dense
                  v-model="deliverTo"
                  :label="deliverTo"
                  :items="places"
                  :search-input.sync="searchPlace"
                  item-text="place_name"
                  no-filter
                  clearable
                  hide-no-data
                  @click:clear="clearSearchPlaces"
                  @change="changeDeliveryLocationState"
                  :rules="delivertoRules"
                  full-width
                  solo
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <label class="text-item-label">WEIGHT</label>
                <v-text-field
                  required
                  placeholder="KG"
                  v-model="weight"
                  :rules="weightRules"
                  name="wieght"
                  hide-details="auto"
                  @keyup="blurevent"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6">
                <label class="text-item-label">COMMODITY</label>
                <v-text-field
                  placeholder="Type Commodity Details"
                  required
                  name="container_commodity"
                  hide-details="auto"
                  v-model="container_commodity"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <!-- <small>CUSTOMER PO#</small> -->
                <label class="text-item-label"
                  >CUSTOMER REFERENCE NUMBER(S)</label
                >
                <div class="tags-email-wrapper mb-1">
                  <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRulesRef"
                    :tags="optionsCustomerRefNo"
                    :add-on-blur="true"
                    :class="myclass"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="
                      myclass.length > 0 ? tagsValidation2 : tagsValidation2
                    "
                    v-model="customerpoNumber"
                    placeholder="Enter refrence #"
                    @tags-changed="
                      (newTags) => {
                        this.myclass = 'noclass';
                        this.optionsCustomerRefNo = newTags;
                        this.tagsInputRef.touched = true;
                        this.tagsInputRef.hasError =
                          this.optionsCustomerRefNo.length > 0 ? false : true;
                        let el =
                          this.documentProto.getElementsByClassName(
                            'ti-input'
                          )[0];
                        if (typeof el !== 'udnefined') {
                          /*if (this.tagsInputRef.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                        }
                      }
                    "
                  />
                </div>
                <div
                  v-if="tagsInputRef.touched && tagsInputRef.hasError"
                  class="v-text-field__details"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        v-if="
                          optionsCustomerRefNo.length > 0 &&
                          warehouseEmailAddress !== ''
                        "
                        class="v-messages__message"
                      >
                        {{ tagsInputRef.errorMessage }}
                      </div>
                      <div
                        v-if="
                          optionsCustomerRefNo.length == 0 &&
                          warehouseEmailAddress !== ''
                        "
                        class="v-messages__message"
                      >
                        {{ tagsInputRef.errorMessage }}
                      </div>
                      <div
                        v-if="
                          optionsCustomerRefNo.length == 0 &&
                          warehouseEmailAddress == ''
                        "
                        class="v-messages__message"
                      >
                        Please provide at least 1 valid refrence number.
                      </div>
                    </div>
                  </div>
                </div>
                <span style="color: #819fb2; font-size: 12px">
                  <i style="font-size: 12px; color: #819fb2">
                    Separate multiple referrence # with comma
                  </i>
                </span>
              </v-col>
            </v-row>
            <v-row class="notesrow" style="margin-top: 11px">
              <v-col class="textareanotes" cols="12" md="12">
                <label class="text-item-label">Notes (Optional)</label>
                <v-textarea
                  v-model="notembl"
                  :rules="noterules"
                  placeholder="Type Note"
                  rows="2"
                  name="custom_notes"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" md="6">
                <label class="text-item-label">last free date</label>
                <div class="vc-date-picker__wrapper">
                  <vc-date-picker
                    title-position="left"
                    is-expanded
                    v-model="last_free_date"
                    mode="date"
                    :popover="{ visibility: 'click' }"
                    :firstDayOfWeek="2"
                    :masks="masks"
                    :select-attribute="attribute">

                    <template #default="{ inputValue, inputEvents }">
                        <!-- <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="create-do-eta-dxdate-vc"
                          placeholder="Select ETA"
                        /> -->
                        <v-text-field
                          :value="inputValue"
                          v-on="inputEvents"
                          v-if="daaerrorLFD"
                          placeholder="Last Free Date"
                          required
                          append-icon="ic-calendar"
                          :rules="lastfreedateRules"
                          name="last_free_date"
                          hide-details="auto"
                          @click:append="showcustom(last_free_date, 'last_free_date')"
                        >
                        </v-text-field>
                    </template>
                  </vc-date-picker>
                </div>
                <!-- <v-text-field
                  v-if="daaerrorLFD"
                  placeholder="Last Free Date"
                  required
                  append-icon="ic-calendar"
                  :rules="lastfreedateRules"
                  name="last_free_date"
                  hide-details="auto"
                  v-model="last_free_date"
                  @click:append="showcustom(last_free_date, 'last_free_date')"
                >
                </v-text-field> -->
              </v-col>
              <v-col cols="6" md="6">
                <label class="text-item-label">Per Diem Date</label>
                <div class="vc-date-picker__wrapper">
                  <vc-date-picker
                    title-position="left"
                    is-expanded
                    v-model="per_diem_date"
                    mode="date"
                    :popover="{ visibility: 'click' }"
                    :firstDayOfWeek="2"
                    :masks="masks"
                    :select-attribute="attribute">

                    <template #default="{ inputValue, inputEvents }">
                        <!-- <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="create-do-eta-dxdate-vc"
                          placeholder="Select ETA"
                        /> -->
                        <v-text-field
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Per Diem Date"
                          required
                          name="per_diem_date"
                          append-icon="ic-calendar"
                          @keyup="blurevent"
                          :rules="PDDRules"
                          hide-details="auto"
                          @click:append="showcustom(per_diem_date, 'per_diem_date')"
                        >
                        </v-text-field>
                    </template>
                  </vc-date-picker>
                </div>
                <!-- <v-text-field
                  placeholder="Per Diem Date"
                  required
                  v-model="per_diem_date"
                  name="per_diem_date"
                  append-icon="ic-calendar"
                  @keyup="blurevent"
                  :rules="PDDRules"
                  hide-details="auto"
                  @click:append="showcustom(per_diem_date, 'per_diem_date')"
                >
                </v-text-field> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <div class="deliveryOrderColumn">
              <div
                style="
                  padding-bottom: 7px;
                  color: #4a4a4a;
                  font-size: 20px;
                  font-weight: 600;
                "
              >
                Delivery Order
                <span
                  v-if="hasDoassociated"
                  style="
                    font-size: 14px;
                    float: right;
                    position: relative;
                    color: #0171a1;
                    cursor: pointer;
                    top: 6px;
                  "
                  @click="deliveryorder(itemsData)"
                >
                  Edit DO
                  <img
                    style="position: relative; top: 3.7px"
                    class="chevronright"
                    src="../../../../assets/icons/Chevron Right.svg"
                  />
                </span>
                <!-- <span
                  v-if="hasDeliveryempty"
                  >
                  <span
                    style="
                      float: right;
                      font-size: 14px !important;
                      color: #0171a1;
                      cursor: pointer;
                    "
                    v-if="uploadTrigger"
                    @click="uploadAgain"
                    class="reupload"
                  >
                    <img
                      class="uploadCreateNew"
                      src="../../../../assets/icons/uploadnew.svg"
                    />
                    Reupload
                  </span>
                </span> -->
              </div>
              <span
                style="color: red; font-size: 12px; margin-left: 5px"
                v-if="errorFile"
              >
                Invalid File Type
              </span>
              <span style="display: none" class="fileuploaderror"> </span>
              <div
                style="display: block"
                class="uploadElement flex w-full h-screen items-left justify-left text-left"
                id="app"
              >
                <v-progress-circular
                  :width="2"
                  :size="15"
                  color="#0171a1"
                  indeterminate
                  v-if="starfetchingdo"
                >
                </v-progress-circular>
                <!-- <div
                  style="width: 100%"
                  :class="hasContent"
                  xclass="cDownloadfiles"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <input
                    style="display: none"
                    type="file"
                    name="fields[]"
                    id="assetsFieldHandle"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="onChange"
                    ref="file"
                    accept="application/pdf"
                  />
                  <label
                    for="assetsFieldHandle"
                    class="block cursor-pointer triggerelement"
                  >
                    <div class="browserInput dragfileElement">
                      <div class="browseFile">Browse Or Drop File</div>
                      <div class="btn-white2 browseButton">
                        <div class="wrappIconbutton">
                          <span class="iconUploadfile"
                            ><i class="ic-upload"></i
                          ></span>
                          <span class="classUpload">Upload</span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div> -->
                <div
                  v-if="!starfetchingdo"
                  class="flex w-full h-screen items-left justify-left text-left"
                  style="width: 100%"
                >
                  <ul
                    v-if="hasDelivery"
                    style="width: 100%; margin-top: 0px !important"
                    class="mt-4 displayItem"
                  >
                    <li
                      style="margin-bottom: 0px"
                      class="text-sm p-1 mb-3 classlistfile"
                    >
                      <div style="display: flex" class="rowItemUploaded">
                        <span
                          class="IconUpload"
                          style="
                            background: #fff;
                            border-radius: 30px;
                            margin-top: 4px;
                          "
                        >
                          <img
                            class="imgIcon"
                            src="../../../../assets/icons/documentFile.svg"
                          />
                        </span>
                        <div style="display: flex" class="mainwrapitem">
                          <div
                            style="
                              width: 65% !important;
                              width: 65% !important;
                              padding-top: 6px !important;
                              font-size: 14px;
                            "
                            class="fileNameList"
                          >
                            {{ do_file }}
                          </div>
                          <div style="margin-top: 5px">
                            <v-progress-circular
                              :size="20"
                              color="#0775A3"
                              indeterminate
                              v-if="!uploadCompleted"
                            >
                            </v-progress-circular>
                          </div>
                          <div
                            style="width: 39% !important"
                            class="fileSize"
                            v-if="uploadCompleted"
                          >
                            <span class="downloadfile" @click="clickDownload">
                              <img
                                style="cursor: pointer"
                                src="../../../../assets/icons/downloadBlue.svg"
                              />
                            </span>
                            <span style="display: none" class="removefiles">
                              <!-- <span
                              class="removefiles"
                              @click="remove(filelist.indexOf(file), objectServerid)
                            "> -->
                              <!-- <i
                             style="position: relative;
                                    top: -4px;
                                    left: -2px;">
                              <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.00026 2.87994C9.63593 2.87994 10.9619 4.24979 10.9619 5.93958C10.9619 7.62937 9.63593 8.99922 8.00026 8.99922C6.36459 8.99922 5.03862 7.62937 5.03862 5.93958C5.03862 4.24979 6.36459 2.87994 8.00026 2.87994ZM8.00026 4.0273C6.97797 4.0273 6.14924 4.88346 6.14924 5.93958C6.14924 6.9957 6.97797 7.85185 8.00026 7.85185C9.02255 7.85185 9.85128 6.9957 9.85128 5.93958C9.85128 4.88346 9.02255 4.0273 8.00026 4.0273ZM8.00026 0.199219C11.4161 0.199219 14.3649 2.60869 15.1831 5.98529C15.2575 6.29264 15.0767 6.60417 14.7792 6.68111C14.4817 6.75805 14.1802 6.57126 14.1057 6.26391C13.4105 3.39498 10.9037 1.34658 8.00026 1.34658C5.09559 1.34658 2.58794 3.39674 1.894 6.26736C1.81969 6.57476 1.51824 6.76172 1.22069 6.68495C0.923145 6.60818 0.742174 6.29675 0.816483 5.98936C1.63322 2.61076 4.58289 0.199219 8.00026 0.199219Z" fill="#0171A1"/>
                              </svg>
                             </i>       -->
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="detailsDelivery">
                        <div>Delivery Order ID #{{ hasshifl_id }}</div>
                        <div>Date Entered: {{ hasdateCreated }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="milestone-create-container">
              <h2>Milestone</h2>
              <div v-for="category in types" :key="category.text" xs6>
                <v-checkbox
                  :color="checkboxColor"
                  :value="category.value"
                  :checked="true"
                  hide-details
                  :on-icon="'ic-checkbox-checked'"
                  :off-icon="'ic-checkbox-empty'"
                  :ripple="false"
                  v-model="category.selected"
                  :label="category.value"
                  @change="blurevent(category.value)"
                >
                  <label
                    v-if="category.value === 'Container Empty'"
                    slot="label"
                  >
                    {{
                      thedata.is_export ? "Container Full" : "Container Empty"
                    }}
                    <span
                      style="font-size: smaller"
                      v-if="thedata.container_empty"
                      >{{ getContainerEmptyDateTime() }}</span
                    >
                  </label>
                </v-checkbox>
              </div>
              <EmptyDialog
                :dialog.sync="emptyDialog"
                :itemData="thedata"
                :isLoading="isSubmitting"
                @closeDialog="closeDialog"
                @updateContainerEmpty="updateContainer"
              />
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
                        <v-col
                            cols="12"
                            md="12">
                            <v-btn
                                :class="noerror == 1 ? 'btn-blue submitNow' : 'btn-white saveCreateBtn' "
                                depressed
                                @click="clicsubmit('save')"
                                color="primary">
                                {{txtUpdate}}
                            </v-btn>
                            <v-btn class="btn-white saveAndAnother"
                            depressed
                            @click="clicsubmit('saveAndAnother')"
                            color="primary">
                            Update & Keep Editing
                            </v-btn>
                            <v-btn
                            class="btn-white createCancelBtn"
                            depressed
                            @click="cancel"
                            color="primary">
                            Cancel
                            </v-btn>
                        </v-col>
                    </v-row> -->
      </v-form>
    </div>
    <div v-if="tabscurrent == 1" class="tab2">
      <Dispatch
        ref="dispatch"
        :theTerminals="theDispatchTerminal"
        :thedrivers="getNoFilterDrivers"
        :thechassis="passDispatchChassis"
        :itemTerminalID="terminalAt"
        :items="dataDispatch"
        msg="dispatchname"
        :getObject="objectMeta"
        :bus="bus"
        :eta="date"
        @leaveDispatch="leaveDispatch"
      />
    </div>
    <div v-if="tabscurrent == 2" class="tab3">
      <Charges :items="chargevalues" />
    </div>
    <!-- <CreatePicker
      :dialogDatepicker="dialogDatepicker"
      :defaultData="pickerDefaultdata"
      @passbackdata="getdata"
      @close="closeDialogpicker"
    /> -->
    <DatePickerDialog 
      :open="dialogDatepicker"
      :multiple="false"
      :label="'Pickup Schedule Date'"
      @confirm="getdata"
      @close="closeDialogpicker"
      :defaultDate="pickerDefaultdata"
    />

    <AcceptedEditFormDialog
      :openDialog.sync="AcceptedreviewDialog"
      :item="theviewdata"    
      @refresh="refreshAccepted" 
      @closeDialog="closeDialogEditDO('AcceptedreviewDialog')"
      :pageClass="'containers-page-do'"
    />
  </div>
</template>
<script>
import DiscardChanges from "../Dialog/DiscardChanges.vue";
import axios from "axios";
import moment from "moment";
import globalMethods from "../../../../utils/globalMethods";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import Dispatch from "../Create/Dispatch.vue";
import Charges from "../Create/Charges.vue";
import EmptyDialog from "../Dialog/EmptyDialog.vue";
import { mapGetters, mapActions } from "vuex";
import jQuery from "jquery";
import VueTagsInput from "@johmun/vue-tags-input";
// import CreatePicker from "../../../Createpicker.vue";
import Vue from "vue";
import DatePickerDialog from '../Dialog/DatePickerDialog.vue';
import AcceptedEditFormDialog from "../../DeliveryOrder/dialog/AcceptedEditFormDialog.vue"

export default {
  name: "create_containers",
  props: [
    "items",
    "mode",
    "customers",
    "terminals",
    "ContainerSize",
    "thedata",
    "files",
    "filesData",
    "thedrivers",
    "currentState",
    "objectMeta",
    "thecontainerchassis",
  ],
  components: {
    Dispatch,
    Charges,
    VueTagsInput,
    DiscardChanges,
    EmptyDialog,
    // CreatePicker,
    DatePickerDialog,
    AcceptedEditFormDialog
  },
  data() {
    return {
      callApiForDeliveryLoc: true,
      searchPlace: "",
      places: [],
      loadingLocationPlaces: false,
      haserrorLFD: 0,
      daaerrorLFD: 1,
      hasDoassociated: 0,
      deliveryDocumentID: 0,
      do_file: "",
      starfetchingdo: 1,
      last_free_date: "",
      per_diem_date: "",
      hasshifl_id: "",
      hasdateCreated: "",
      hasDeliveryempty: 0,
      hasDelivery: 0,
      itemsData: [],
      pickerDefaultdata: "",
      currentstatepicker: "",
      dialogDatepicker: false,
      clearedform: false,
      bus: new Vue(),
      kaniCurrentName: "",
      uploadCompleted: true,
      uploadTrigger: false,
      noerrorForm: true,
      titleni: "",
      txtUpdate: "Update",
      blockstyle: "none",
      isactive: true,
      busLocationFrom: "y",
      isFetching: 0,
      isProcessingRemove: 0,
      hideDownload: true,
      objectServerid: [],
      isDownloaded: false,
      itemsMenuDropdown: [
        {
          title: "Replace File",
        },
        {
          title: "Delete File",
        },
      ],
      hasContent: "hasContentFiles",
      filelist: [],
      errorFile: false,
      shipmentmsg: "Shipment Header Information updated!",
      Truckers: false,
      stillLoading: false,
      showheaderinfo: true,
      queryData: [],
      noerror: true,
      testsubmit: "",
      formFieldlength: 0,
      startloading: 1,
      clickOrigin: "",
      clicksubmit: false,
      submitisclick: false,
      selectedmilestone: [],
      Objectypes: [],
      customer: [],
      mbl: "",
      containerNumber: "",
      container_commodity: "",
      terminalAt: [],
      customerRules: [(v) => !!v || "Customer is required"],
      mblRules: [(v) => !!v || "Mbl# is required"],
      lastfreedateRules: [
        (v) => {
          let checkDate = moment(v)
          if(!checkDate.isValid()) {
            return "Please input a valid date"
          } else {
            if (v != "") {
              return this.validateLFD_PDD(1);
            }
            return true;
          }
        },
      ],
      PDDRules: [
        (v) => {
          let checkDate = moment(v)
          if(!checkDate.isValid()) {
            return "Please input a valid date"
          } else {
            if (v != "") {
              if (this.validateLFD_PDD(2) == true) {
                if (this.haserrorLFD == 1) {
                  this.foundgood = 1;
                }
                return true;
              } else {
                return this.validateLFD_PDD(2);
              }
            }
            return true;
          }
        },
      ],
      containerNumberRules: [
        (text) => {
          if (text == "") return "Container # is required.";
          let msg =
            "The first 4 characters are alpha and followed by 7 digits.";
          if (text.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
              /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
              return true;
            }
            return msg;
          }
          return "First 4 characters are alpha and followed by 7 digits.";
        },
      ],
      notembl: "",
      noterules: [
        (v) =>
          v.length < 200 || "Should not exceeds to 200 allowed characters.",
      ],
      TerminalAtRules: [(v) => !!v || "Terminal At is required"],
      containerSizeModel: [],
      containersizeRules: [(v) => !!v || "Container size is required"],
      deliverTo: "",
      delivertoRules: [(v) => !!v || "Deliver to is required"],
      weight: "",
      weightRules: [(v) => !!v || "Weight is required"],
      customerpoNumber: "",
      customerpoNumberRules: [(v) => !!v || "Customer Ref# is required"],
      containerTypeField: "",
      containerTypeRules: [(v) => !!v || "Container Type is required"],
      truckersField: "",
      truckersRules: [(v) => !!v || "Truckers is required"],
      hiddenmilestone: "",
      hiddenmilestoneRules: [(v) => !!v || "Please select milestone"],
      select: "",
      customerSelected: "",
      containertypeSelected: "",
      terminalatSelected: "",
      containersizeSelected: "",
      currentDropdownState: "",
      trucker_idSelected: "",
      valid: true,
      currentComboState: "",
      tabscurrent: 0,
      tab: null,
      validateDaterules: [
        (v) => {
          if (v === "") return "This field is required";
          if (!this.validateDate(v)) {
            return "Invalid Date ex: YYYY-mm-dd";
          }
          return true;
        },
      ],
      // tabsNavigation: ["Header Info", "Dispatch", "Charges"],
      tabsNavigation: ["Header Info", "Dispatch"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      types: [
        {
          value: "Fully Released",
          selected: "",
        },
        {
          //value: 'Pier Pass Paid',
          value: "Pre-Gate Fees",
          selected: "",
        },
        {
          value: "Discharged",
          selected: "",
        },
        {
          value: "Container Empty",
          selected: "",
        },
        {
          value: "Billed",
          selected: "",
        },
        {
          value: "Cancelled",
          selected: "",
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      values: [
        {
          id: "1",
          name: "Name 1",
        },
        {
          id: "2",
          name: "Name 2",
        },
        {
          id: "3",
          name: "Name 3",
        },
      ],
      menu: false,
      modal: false,
      menu2: false,
      checkboxColor: "#0171A1",
      container_type: "",
      select_terminal_at: "",
      itemsDropdown: [],
      dialog: false,
      search: "",
      searchData: "",
      selectedTasks: [],
      containerType: [],
      containerSize: [],
      TERMINAL_AT: [],
      ex4: [],
      truckers: [],
      newdata: [],
      chargevalues: [],
      dataDispatch: [],
      fetalldetailsdata: [],
      passDispatchDriver: [],
      theDispatchTerminal: [],
      myclass: "",
      options: [],
      optionsCustomerRefNo: [],
      documentProto: document,
      warehouseEmailAddress: "",
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsInputRef: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsValidation: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      arrayNotEmptyRules: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      arrayNotEmptyRulesRef: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      tagsValidation3: [
        {
          classes: "ttt-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      tagsValidation2: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+$/,
          disableAdd: true,
        },
      ],
      CarrierModel: "",
      CarrierRules: [(v) => !!v || "Customer is required"],
      carrierDropdown: [],
      VesselModel: "",
      VesselRules: [(v) => !!v || "Customer is required"],
      discard_changes_modal: false,
      discard_type: null,
      go_to_tab: null,
      allow_navigation: false,
      passDispatchChassis: [],
      emptyDialog: false,
      isSubmitting: false,
      emptyDateTime: {
        date: null,
        time: null,
      },
      emptyModalItem: [],
      foundgood: 0,
      isSaveChangesLoading: false,
      AcceptedreviewDialog: false,
      theviewdata: [],
      attribute: {
        highlight: {
            style: {
              backgroundColor: '#0171A1', // blue
              borderRadius: '4px'
            },
            contentStyle: {
              color: '#ffffff', // color of the text
              borderRadius: '4px'
            }
        },
      },
      masks: {
        input: 'YYYY-MM-DD',
        weekdays: "WWW"
      },
    };
  },
  watch: {
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
    foundgood(v) {
      if (v) {
        this.daaerrorLFD = 0;
        this.$nextTick(() => {
          this.daaerrorLFD = 1;
        });
      }
    },
    terminals(v) {
      this.theDispatchTerminal = v;
    },
    thedrivers(v) {
      this.passDispatchDriver = v;
    },
    thedata(v) {
      this.callload(v);
    },
    filelist(c) {
      if (c.length == 0) {
        this.hasContent = "hasContentFiles";
      } else {
        //this.callUPdocument(c);
        this.hasContent = "cDownloadfiles hidethisdiv";
      }
    },
    thecontainerchassis(v) {
      this.passDispatchChassis = v;
    },
  },
  computed: {
    shipmentData() {
      return this.queryData;
    },
    ...mapGetters({
      getEditviewData: "containers/getEditviewData",
      getCarriers: "containers/getAllCarriers",
      getEditDetails: "containers/getEditDetails",
      getDrivers: "common/getDrivers",
      getNoFilterDrivers: "common/getNoFilterDrivers",
      // get
      //getAllcontainerStatus: 'containers/getAllcontainersStatus',
      // getContainerdetails : 'containers/getContainerdetails',
      // getEditDetails: 'containers/getEditDetails',
    }),
    carriers: {
      get() {
        var data = [];
        if (
          typeof this.getCarriers !== "undefined" &&
          this.getCarriers !== null
        ) {
          if (
            typeof this.getCarriers.data !== "undefined" &&
            this.getCarriers.data.length !== "undefined"
          ) {
            data = this.getCarriers.data;
          }
        }
        return data;
      },
    },
    itemsDropdownComputed: {
      get() {
        return this.customers;
      },
    },
    terminalsDropdown: {
      get() {
        return this.terminals;
      },
    },
    sizeDropdown: {
      get() {
        return this.ContainerSize;
      },
    },
    headers() {
      return [
        {
          text: "REFERENCE",
          align: "left",
          sortable: false,
          value: "REF",
          width: "8%",
          fixed: true,
        },
        {
          text: "CUSTOMER",
          align: "start",
          sortable: false,
          value: "CUSTOMER",
          width: "13%",
          fixed: true,
        },
        {
          text: "CONTAINER #",
          align: "start",
          sortable: false,
          value: "CONTAINER",
          width: "12%",
          fixed: true,
        },
        {
          text: "ETA",
          align: "start",
          sortable: true,
          value: "ETA",
          width: "12%",
          fixed: true,
        },
        {
          text: "TERMINAL AT",
          align: "start",
          sortable: true,
          value: "TERMINAL_AT",
          width: "20%",
          fixed: true,
        },
        {
          text: "DELIVERY TO",
          align: "start",
          sortable: true,
          value: "DELIVERY_TO",
          width: "20%",
          fixed: true,
        },
      ];
    },
  },
  methods: {
    closeDialogEditDO(data) {
      this[data] = false
    },
    refreshAccepted(c){
      if(c){
       console.log(c)
      }
    },
     onFilter(item, queryText){            
        if(item.nickname){
              return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            || item.nickname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) 
            || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
        }
      return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())

    },
    validateLFD_PDD(x) {
      if (moment.utc(this.date).isAfter(moment.utc(this.per_diem_date))) {
        return "Per Diem Date must be later than ETA";
      }
      // LFD dili equal sa PDD
      // PDD laging LATER sa lahat ETA at LFD
      if (moment.utc(this.date).isAfter(moment.utc(this.last_free_date))) {
        return "Last Free Date must be later than ETA";
      }
      if (
        moment.utc(this.last_free_date).isAfter(moment.utc(this.per_diem_date))
      ) {
        if (x == 1) {
          this.haserrorLFD = 1;
        }
        return "Per Diem Date must be later than Last Free Date";
      }
      if (
        moment.utc(this.last_free_date).isSame(moment.utc(this.per_diem_date))
      ) {
        if (x == 1) {
          this.haserrorLFD = 1;
        }
        return "Per Diem Date must be later than Last Free Date";
      }
      return true;
    },
    deliveryorder(items) {
      // this.$router.push(
      //   "/delivery-order/" +
      //     items.alldata[0].delivery_order_id +
      //     "/" +
      //     items.alldata[0].id
      // );

      let shipment_do = items["shipment-associated-do"]

      let data = {
        id: items.alldata[0].delivery_order_id,
        shifl_ref: typeof shipment_do === "object" ? shipment_do.shifl_id : "",
        otherData: items.alldata[0]
      }
      this.AcceptedreviewDialog = true
      this.theviewdata = data
      console.log(items);
    },
    validateDate(dateStr) {
      let str = dateStr.split("T");
      dateStr = str[0];
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateStr.match(regex) === null) {
        return false;
      }
      const date = new Date(dateStr);
      const timestamp = date.getTime();
      if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
      }
      return date.toISOString().startsWith(dateStr);
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    getdata(item) {
      if (this.currentstatepicker == "date") {
        this.date = item;
      }
      if (this.currentstatepicker == "last_free_date") {
        this.last_free_date = item;
      }
      if (this.currentstatepicker == "per_diem_date") {
        this.per_diem_date = item;
      }
      this.dialogDatepicker = false;
    },
    discardChanges(val) {
      this.discard_changes_modal = false;
      if (val) {
        if (this.discard_type == "cancel") {
          this.allow_navigation = true;
          this.$router.push("/containers");
        } else if (this.discard_type == "tab") {
          this.changeTab(this.go_to_tab);
        }
      } else if (this.discard_type == "tab") {
        this.tab = 1;
      }
      this.discard_type = null;
      this.go_to_tab = null;
    },
    clickDownload() {
      this.gotoDownload();
    },
    uploadAgain() {
      jQuery(document).find(".triggerelement").trigger("click");
    },
    ...mapActions({
      fetchEdit: "containers/fetchEdit",
      fetchCarriers: "containers/fetchCarriers",
      fetchDrivers: "common/fetchDrivers",
    }),
    ...globalMethods,
    saveChanges() {
      if (this.tabscurrent == 0) {
        this.clicsubmit("save");
      } else if (this.tabscurrent == 1) {
        this.cleanobject();
        this.allow_navigation = true;
        this.bus.$emit("saveChanges", true);
      }
    },
    async getdeliveryassociated() {
      var dis = this;
      //this.starfetchingdo = 0;
      dis.hasDelivery = 0;

      let shipment_do = this.getEditDetails["shipment-associated-do"]
      
      if (typeof shipment_do !== 'undefined') {
        if (typeof shipment_do === 'object') {
          dis.hasshifl_id = shipment_do.shifl_id;
          dis.hasdateCreated = moment(shipment_do.created)
            .utc(false)
            .format("MMM D, Y");
          dis.do_file = shipment_do.do_file;
          dis.deliveryDocumentID = shipment_do.do_documentid;
          if (shipment_do.hasdocument) {
            dis.hasDelivery = 1;
          }
          dis.hasDoassociated = 1;
        } else {
          dis.hasDeliveryempty = 1;
        }
        dis.starfetchingdo = 0;
      }

      // await axios
      //   .get(`${poBaseUrl}/shipment-associated-do/` + this.$route.params.id)
      //   .then(function (response) {
      //     if (typeof response.data[0] != "undefined") {
      //       dis.hasshifl_id = response.data[0].shifl_id;
      //       dis.hasdateCreated = moment(response.data[0].created)
      //         .utc(false)
      //         .format("MMM D, Y");
      //       dis.do_file = response.data[0].do_file;
      //       dis.deliveryDocumentID = response.data[0].do_documentid;
      //       if (response.data[0].hasdocument) {
      //         dis.hasDelivery = 1;
      //       }
      //       dis.hasDoassociated = 1;
      //     } else {
      //       dis.hasDeliveryempty = 1;
      //     }
      //     dis.starfetchingdo = 0;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    cleanobject() {
      var dis = this;
      axios
        .get(`${poBaseUrl}/cleanobject/` + this.$route.params.id)
        .then(function (response) {
          dis.isClear = true;
          if (!response) {
            console.log(response);
          }
        });
    },
    datetostring(eta) {
      // var mydate = new Date(eta);
      // console.log('eta >>>', eta)
      var mydate = moment(eta).utc(false).format("YYYY-MM-DD");
      return mydate;
    },
    utcDate(eta) {
      // console.log('eta >>>', eta)
      this.date = moment(eta).utc(false).format("YYYY-MM-DD");
    },
    toggleEdit() {
      this.showheaderinfo = false;
      this.$router.push("/container/edit/" + this.$route.params.id);
    },
    callload(v) {
      this.stillLoading = true;
      if (v) {
        // customer
        if (v.customer != null) {
          this.customer = v.customer.id;
          this.customerSelected = v.customer.id;
        }
        this.mbl = v.mbl_num;
        this.containerNumber = v.trucker_container;
        this.CarrierModel = v.carrier_id;
        this.last_free_date = moment(
          moment.utc(v.last_free_date).format("YYYY-MM-DD")
        ).isValid()
          ? moment.utc(v.last_free_date).format("YYYY-MM-DD")
          : "";
        this.per_diem_date = moment(
          moment.utc(v.per_diem_date).format("YYYY-MM-DD")
        ).isValid()
          ? moment.utc(v.per_diem_date).format("YYYY-MM-DD")
          : "";
        //this.last_free_date =  moment(v.last_free_date).utc(false).format("YYYY-MM-DD") ;
        //this.per_diem_date  = moment(v.per_diem_date).utc(false).format("YYYY-MM-DD") ;
        this.VesselModel = v.vessel || "";
        let validEmailAddress = [];
        let emails = JSON.parse(v.contacts);
        // get contacts
        if (emails !== null && Object.keys(emails).length > 0) {
          var obj = emails;
          var result = Object.keys(obj).map((key) => [Number(key), obj[key]]);
          result.forEach((value) => {
            //if(this.emailchecker(value[1])){
            validEmailAddress.push({
              text: value[1],
              tiClasses: ["ti-valid"],
            });
            //}
          });
        }
        this.options = validEmailAddress;
        // terminal
        // if (v.terminal != null) {
        //   this.terminalAt = v.terminal.id;
        //   this.terminalatSelected = v.terminal.id;
        // }
        if (v.terminal_id != null) {
          this.terminalAt = v.terminal_id;
          this.terminalatSelected = v.terminal_id;
        }
        //container
        if (v.container_size_id != null) {
          this.containerSizeModel = v.container_size_id;
          this.containersizeSelected = v.container_size_id;
        }
        this.deliverTo = v.deliver_to;
        if (v.eta != "") {
          //this.date =  moment(v.eta).format('YYYY-MM-DD');
          let de = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
          this.date = moment(moment.utc(v.eta).format("YYYY-MM-DD")).isValid()
            ? moment.utc(v.eta).format("YYYY-MM-DD")
            : de;
        }
        this.weight = v.container_weight;
        // comodity
        if (v.container_commodity != null) {
          this.container_commodity = v.container_commodity;
        }
        // custom_notes
        if (v.custom_notes != null) {
          this.notembl = v.custom_notes;
        }
        // this.customerpoNumber = v.customer_po;
        let validRefrenceNumber = [];
        if (v.customer_po && v.customer_po.length > 0) {
          let valiRef = null;

          // still error when editing / if customer_po is already an object
          // try {
          // 	valiRef = JSON.parse(v.customer_po);
          // }
          // catch (e) {
          // 	console.log(e)
          // }

          // DONT PARSE IF ALREADY AN OBJECT SIMPLY JUST ASSIGN
          valiRef =
            typeof v.customer_po === "object"
              ? v.customer_po
              : JSON.parse(v.customer_po);

          if (valiRef !== null && Object.keys(valiRef).length > 0) {
            var objRef = valiRef;
            var resultRef = Object.keys(objRef).map((key) => [
              Number(key),
              objRef[key],
            ]);
            resultRef.forEach((value) => {
              validRefrenceNumber.push({
                text: value[1].customer_ref_number,
                tiClasses: ["ti-valid"],
              });
            });
          } else {
            validRefrenceNumber = [];
          }
        } else {
          validRefrenceNumber = [];
        }
        this.optionsCustomerRefNo = validRefrenceNumber;
        let fully_released = v.fully_released ? "Fully Released" : "";
        let pier_pass_paid = v.pier_pass_paid ? "Pre-Gate Fees" : "";
        let discharged = v.discharged ? "Discharged" : "";
        let container_empty = v.container_empty ? "Container Empty" : "";
        let billed = v.billed ? "Billed" : "";
        let cancelled = v.cancelled ? "Cancelled" : "";
        let arrayMilestone = [
          {
            value: "Fully Released",
            selected: fully_released,
          },
          {
            value: "Pre-Gate Fees",
            selected: pier_pass_paid,
          },
          {
            value: "Discharged",
            selected: discharged,
          },
          {
            value: "Container Empty",
            selected: container_empty,
          },
          {
            value: "Billed",
            selected: billed,
          },
          {
            value: "Cancelled",
            selected: cancelled,
          },
        ];
        this.dataDispatch = v.dispatch_schedule;
        this.types = arrayMilestone;
        Object.assign(this.queryData, v);
        this.stillLoading = false;
        this.hideDownload = false;
        this.startloading = 0;
        this.noerrorForm = false;
      }
    },
    blurevent(e) {
      if (e === "Container Empty") {
        if (!this.thedata.container_empty) {
          this.emptyDialog = true;
        } else if (this.thedata.container_empty) {
          this.emptyDialog = false;
        }
      }
      this.checkobjects();
    },
    checkobjects() {
      if (
        Object.keys(this.mbl).length !== 0 &&
        Object.keys(this.containerNumber).length !== 0 &&
        // Object.keys(this.containersize).length !== 0 &&
        Object.keys(this.deliverTo).length !== 0 &&
        Object.keys(this.weight).length !== 0 //&&
        //Object.keys(this.customerpoNumber).length !== 0
      ) {
        this.noerror = 1;
        this.noerrorForm = false;
      } else {
        this.noerror = 0;
        this.noerrorForm = true;
      }
    },
    saveandcreate() {
      this.$emit("saveandanother");
    },
    changemilestone() {
      this.selectedmilestone = true;
    },
    clicsubmit(origin) {
      this.submitisclick = 1;
      for (var i = 0; i < this.types.length; i++) {
        if (this.types[i].selected != "") {
          this.selectedmilestone[i] = this.types[i].selected;
        }
      }
      if (this.$refs.form.validate() && this.noerror == 1) {
        this.isSaveChangesLoading = true
        this.startloading = 1;
        let ediID = this.$route.params.id;
        let formData = new FormData(document.getElementById("sform"));
        formData.append("milestone", this.selectedmilestone);
        formData.append("ID", ediID);
        formData.append("mode", "edit");
        formData.append("dateTime", JSON.stringify(this.emptyDateTime));
        let finalEmailAddress =
          this.options.length > 0
            ? this.options.map((o) => {
                return o.text;
              })
            : [];
        formData.append("contacts[]", finalEmailAddress);
        // let finalrefrenceNumber =
        //   this.optionsCustomerRefNo.length > 0
        //     ? this.optionsCustomerRefNo.map((o) => {
        //         return o.text;
        //       })
        //     : [];
        let finalrefrenceNumber =
          this.optionsCustomerRefNo.length > 0
            ? this.optionsCustomerRefNo.map(({ text }) => ({
                customer_ref_number: text,
              }))
            : [];
        formData.append("po_num", JSON.stringify(finalrefrenceNumber));
        if (this.validateMilestones()) {
          axios
            .post(`${poBaseUrl}/containers-update`, formData)
            .then((response) => {
              this.isSaveChangesLoading = false
              return response.data;
            })
            .catch((error) => {
              this.isSaveChangesLoading = false
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            })
            .then((response) => {
              if (response) {
                this.isSaveChangesLoading = false
                this.startloading = 0;
                this.dispatchNotifcation(
                  '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                    this.shipmentmsg +
                    "</span>"
                );
                if (origin == "save") {
                  this.formReset();
                } else {
                  this.callload();
                }
              }
            });
        } else {
          this.isSaveChangesLoading = false
          this.dispatchError(
            "Once the Container has arrived, Fully Released, Pre-Gate Fees and Discharged Milestone can't be unchecked!"
          );
          this.callload();
        }
      }
    },
    validateMilestones() {
      let check = false;
      let dispatch = null;
      try {
        dispatch = JSON.parse(this.dataDispatch);
      } catch (err) {
        dispatch = null;
      }
      if (dispatch) {
        let legs = dispatch.legs;
        if (legs && legs.length > 0) {
          for (let i = 0; i < legs.length; i++) {
            if (legs[i].completed) {
              check = true;
              break;
            }
          }
        }
      }
      if (check)
        return (
          this.selectedmilestone.includes("Fully Released") &&
          this.selectedmilestone.includes("Pre-Gate Fees") &&
          this.selectedmilestone.includes("Discharged")
        );
      return true;
    },
    dispatchError(msg) {
      this.notificationError('<span class="text-message">' + msg + ".</span>");
    },
    cancel() {
      if (this.tabscurrent == 1 && this.$refs.dispatch.isDispatchChanged()) {
        this.discard_changes_modal = true;
        this.discard_type = "cancel";
        this.allow_navigation = false;
      } else {
        this.$router.push("/containers");
      }
    },
    formReset() {
      this.$router.push("/containers");
    },
    getCurrentTab(id) {
      this.currentSelectTab = id;
    },
    sendEmailPushnotification() {
      this.emailsendToVendor(
        '<i class="ic-check-mark"></i> <span class="text-message">PO has been emailed to the vendor.</span>'
      );
      this.dialogEmail = 0;
    },
    isUndefined(arg) {
      return typeof arg !== "undefined" ? 1 : 0;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    gotoCreate() {
      this.$router.push("/containers/create");
    },
    tabsnavigationClick(current) {
      if (this.tabscurrent == current) return;
      if (this.tabscurrent == 1 && this.$refs.dispatch.isDispatchChanged()) {
        this.go_to_tab = current;
        this.discard_changes_modal = true;
        this.discard_type = "tab";
      } else {
        this.changeTab(current);
      }
    },
    changeTab(tab) {
      this.tabscurrent = tab;
    },
    leaveDispatch(ob) {
      if (ob.type == "tab") {
        this.changeTab(ob.tab);
      } else if (ob.type == "cancel") {
        this.$router.push("/containers");
      }
    },
    getmeselected(value) {
      this.checkobjects();
      let finalvalue = value;
      switch (this.currentDropdownState) {
        case "customer":
          this.customerSelected = finalvalue;
          break;
        case "CONTAINER TYPE":
          this.containertypeSelected = finalvalue;
          break;
        case "CONTAINER SIZE":
          this.containersizeSelected = finalvalue;
          break;
        case "TERMINAL AT":
          this.terminalatSelected = finalvalue;
          break;
        case "TRUCKERS":
          this.trucker_idSelected = finalvalue;
          break;
      }
    },
    dataDown(value) {
      this.formFieldlength++;
      this.currentDropdownState = value;
      this.currentSelectTab = value;
    },
    calldata() {
      let terminalvalue = this.formdetails.data.terminal;
      for (const key in terminalvalue) {
        this.TERMINAL_AT.push(terminalvalue[key].name);
      }
    },
    getPrelims: function (data) {
      let response = data;
      let customers = response.data.customers;
      let DataCustomer = [];
      customers.forEach((value) => {
        DataCustomer.push({
          id: value.id,
          name: value.company_name,
        });
      });
      this.itemsDropdown = DataCustomer;
      let terminal_at = response.data.terminal;
      terminal_at.forEach((value) => {
        this.TERMINAL_AT.push({
          id: value.id,
          name: value.name,
        });
      });
      let size = response.data.size;
      size.forEach((value) => {
        this.containerSize.push({
          id: value.id,
          name: value.name,
        });
      });
      let containertype = response.data.type;
      containertype.forEach((value) => {
        this.containerType.push({
          id: value.id,
          name: value.name,
        });
      });
      this.startloading = 0;
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      let table = this.filelist;
      let errorCount = 0;
      this.kaniCurrentName = table[0].name;
      Object.keys(table).forEach(function eachKey(key) {
        if (table[key].type != "application/pdf") {
          errorCount++;
        }
      });
      if (errorCount > 0) {
        this.errorFile = 1;
        this.allFieldsValid = false;
      } else {
        this.errorFile = 0;
        this.allFieldsValid = true;
      }
      this.callUPdocument(this.filelist);
    },
    remove(i) {
      this.uploadTrigger = false;
      this.isProcessingRemove = 1;
      axios
        .delete(`${poBaseUrl}/upload/remove/${this.objectServerid.id}`, {
          data: {
            itemid: this.$route.params.id,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .then((response) => {
          this.isProcessingRemove = 0;
          if (response.error == 0) {
            this.isDownloaded = false;
            this.objectServerid = [];
          }
        });
      this.filelist.splice(i, 1);
      if (this.filelist.length == 0) {
        this.errorFile = 0;
        this.allFieldsValid = true;
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    callUPdocument(data) {
      jQuery(".cDownloadfiles").hide();
      this.uploadCompleted = false;
      this.uploadTrigger = true;
      this.serverObject = [];
      var formData = new FormData();
      var pdfiles = document.getElementById("assetsFieldHandle");
      formData.append("fields[]", pdfiles.files[0]);
      formData.append("data", data);
      formData.append("xmode", "xedit");
      formData.append("xid", this.$route.params.id);
      if (typeof this.objectServerid.id !== "undefined")
        formData.append("theid", this.objectServerid.id);
      if (this.busLocationFrom != "y") {
        // SHOW THE UPLOAD BUTTON
        // HIDE THE DOWNLOAD BUTTON
        this.hideDownload = false;
        this.isDownloaded = false;
        this.isFetching = 1;
      }
      axios
        .post(`${poBaseUrl}/upload_file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .then((response) => {
          this.uploadCompleted = true;
          this.isFetching = 0;
          if (response) {
            this.isDownloaded = true;
          }
          this.serverObject = response.data;
          this.objectServerid = response.data.find((d) => d.id != "");
        });
    },
    gotoDownload() {
      this.uploadCompleted = false;
      let passdata = this.objectServerid.id;
      this.isFetching = 1;
      if (passdata !== "") {
        //let downloadurl  = 'document/download/'+this.deliveryDocumentID;
        // url: `${poBaseUrl}/download/${passdata}`,
        axios({
          url: `${poBaseUrl}/document/download/${this.deliveryDocumentID}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          this.isFetching = 0;
          window.URL = window.URL || window.webkitURL;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
            })
          );
          link.setAttribute("download", "Delivery_order.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.uploadCompleted = true;
        });
      }
    },
    runProcess(runProcess) {
      if (runProcess == "Delete File") {
        this.isProcessingRemove = 1;
        axios
          .delete(`${poBaseUrl}/upload/remove/${this.objectServerid.id}`, {
            data: {
              itemid: this.$route.params.id,
            },
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.isProcessingRemove = 0;
            if (response.error == 0) {
              this.isDownloaded = false;
              this.objectServerid = [];
            }
          });
      } else {
        // TRIGGER CLICK ON REPLACE FILE
        this.busLocationFrom = "zDrop";
        jQuery(document).find(".triggerelement").trigger("click");
      }
    },
    closeDialog(item) {
      this.emptyDialog = false;
      if (!item.container_empty && this.types[3].selected) {
        this.types[3] = {
          value: "Container Empty",
          selected: "",
        };
      }
    },
    updateContainer(item, dateTime) {
      this.emptyDialog = false;
      this.emptyModalItem = item;
      this.emptyDateTime = {
        date: dateTime.date,
        time: dateTime.time,
      };
    },
    openDialog($event) {
      var isChecked = $event.target.checked;
      var isCE = jQuery($event.target).text() === "Container Empty";
      if ($event.target.value === "Container Empty" || isCE) {
        if (!this.thedata.container_empty && (isChecked || isCE)) {
          this.emptyDialog = true;
        } else if (this.thedata.container_empty) {
          this.emptyDialog = false;
        }
      }
    },
    getContainerEmptyDateTime() {
      let d = this.thedata.container_empty_date;
      let t = this.thedata.container_empty_time;
      if (t === null && d === null) {
        return;
      } else if (t === null) {
        return moment(d).format("MMM D, YYYY");
      } else {
        return moment(d + " " + t).format("MMM D, YYYY, hh:mm A");
      }
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;

            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
  },
  created() {
    var dis = this;
    this.bus.$on("closeDialog", (args) => {
      if (args) {
        dis.cleanobject();
      }
    });
  },
  async mounted() {
    await this.fetchDrivers();
    await this.fetchCarriers();
    await this.fetchEdit(this.$route.params.id);
    await this.getdeliveryassociated();
    let ds = this;
    this.itemsData = this.items;
    var itemgetdata = this.items;
    if (itemgetdata.alldata[0].delivery_order_id != null) {
      //this.hasDelivery = 1;
    } else {
      //this.hasDeliveryempty = 1;
    }
    if (typeof this.getEditDetails.datacomponents != "undefined") {
      var obj = this.getEditDetails;
      Object.keys(obj).forEach((key) => {
        if (key == "datacomponents") {
          const files = obj[key],
            keys = Object.keys(files);
          keys.forEach((key) => {
            if (key == "files") {
              files[key].forEach((element) => {
                ds.isDownloaded = true;
                ds.uploadTrigger = true;
                ds.objectServerid.id = element.id;
                ds.kaniCurrentName = element.orginal_name;
                ds.filelist.push({
                  id: element.id,
                  name: element.orginal_name,
                });
              });
            }
          });
        }
      });
    }
    this.$store.dispatch("page/setPage", "containers");
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../assets/scss/buttons.scss";
.containerEditDownload .v-list .v-list-item {
  line-height: 34px !important;
  max-height: 34px;
}

.v-input {
  &.uppercase-text {
    .v-input__control {
      .v-input__slot {
        input {
          text-transform: uppercase !important;
        }
      }
    }
  }
}
</style>
<style>
.browseFile {
  color: #fff !important;
  width: auto;
}

.vue-tags-input {
  max-width: 100% !important;
}

.ti-new-tag-input {
  caret-color: #000 !important;
}

.ti-tags .ti-tag {
  background: #f7f7f7;
  color: #4a4a4a;
}

.uploadElement .hasContentFiles {
  background: #fff !important;
  padding: 6px !important;
  display: block !important;
}

.browserInput {
  display: flex;
  width: 100%;
}

.browseFile {
  width: 50%;
  text-align: center;
  color: #b4cfe0 !important;
  padding-top: 3px;
}

.browseButton {
  width: 50%;
  text-align: right;
  font-size: 12px;
  display: ruby;
}

.wrappIconbutton {
  border: 1px solid #b4cfe0;
  border-radius: 5px;
  width: 50%;
  text-align: center;
  padding: 5px;
}

.classUpload {
  font-size: 12px;
  color: #0171a1;
  position: relative;
  left: 6px;
}

.iconUploadfile {
  color: #0171a1;
}

.rowItemUploaded {
  background: #f7f7f7;
  border-radius: 6px;
  padding: 10px;
  padding-right: 4px;
}

.mainwrapitem {
  border: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px;
}

.uploadCreateNew {
  position: relative;
  top: 3px;
  left: -1px;
  height: 15px;
}

.hidethisdiv {
  display: none !important;
}

.reupload {
  float: right;
  font-size: 14px !important;
  color: rgb(1, 113, 161);
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 0px;
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 1px;
}

.deliveryOrderColumn {
  background-color: #fff;
  padding: 0px;
  width: 93%;
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 15px;
  padding-top: 5px !important;
}

.wrappIconbutton {
  float: right;
}

.classlistfile {
  background: #f7f7f7;
  border-radius: 4px;
  padding-left: 3px;
}

.detailsDelivery {
  font-size: 12px;
  color: #6d858f;
  padding-left: 10px;
  padding-bottom: 12px;
}

.removefiles {
  border: 1px solid #b4cfe0;
  width: 15px;
  display: inline-block;
  padding-right: 25px;
  padding-left: 13px;
  padding-top: 8px;
  border-radius: 3px;
  background: #fff;
  padding-bottom: 5px;
  margin-left: 5px;
}

.downloadfile {
  border: 1px solid #b4cfe0;
  width: 15px;
  display: inline-block;
  padding-right: 25px;
  padding-left: 13px;
  padding-top: 8px;
  border-radius: 3px;
  background: #fff;
  padding-bottom: 5px;
}

.leftcol {
  border-right: 1px solid #d8e7f0 !important;
  padding-right: 18px;
}

.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}

.startingDOSclass .v-menu__content {
  max-height:200px !important;
}
</style>
