<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">New Chassis </span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col style="color:red;" 
                v-if="errorFound" 
                cols="12" 
                sm="12" 
                md="12" 
                class="pb-3 pt-1"
                >
                {{errorMessage}}
              </v-col>  
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Number</label>
                <v-text-field
                  outlined
                  dense
                  placeholder="Enter Chassis Number"
                  v-on:keypress="ISALPHANUMERIC($event)"
                  :rules="inputRules"
                  v-model="newYard.number"
                  name="name"
                  hide-details="auto"
                  style="font-size:14px !important"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-0" md="12">
                <label class="text-item-label">Chassis Type</label>            
                 <v-autocomplete
                 :rules="selectRules"
                  v-model="newYard.chassis_type_id"
                  :items="getChassisType"                
                  item-text='name'
                  class="inputselect"
                  item-value='id'
                  placeholder="Select Chassis Type"
                  solo                 
                  append-icon="ic-chevron-down"
                >
							</v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="12" class="pt-0 pb-2 mt-0 pr-0" md="12"> -->
                  <v-checkbox 
                    class="ml-2"
                    style="margin-top:0px"
                    v-model="trucker_owned"
                    label=""
                  >
                  </v-checkbox>
                  <label class="text-item-label mt-3">Company Owned</label>  
              <!-- </v-col> -->
            </v-row>
          </v-card-text>

          <v-card-actions 
            style="padding-left:4px;
                   padding-left:10px; 
                   margin-top:5px;
                   border-top:2px solid #EBF2F5"
            >
            <!-- <v-col 
                  class="text-left buttonActions" 
                  style="padding-right: 0px"
              >
              <v-btn class="btn-blue" text @click="save">
                <span>
                  <span color="#fff">
                    <v-progress-circular
                      :size="15"
                      color="#fff"
                      :width="2"
                      indeterminate
                      v-if="getCreateChassisLoading"
                      style="margin-right: 3px"
                    >
                    </v-progress-circular>
                    Add
                  </span>
                </span>
              </v-btn>
              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col> -->
            <div class="text-right buttonActions" style="padding:0px">
                <div class="row">
                    <div class="col-md-12 px-5">
                        <div class="row">
                            <div class="col-md-4 ml-1 mr-1">
                                <v-btn class="btn-blue" text @click="save">
                                  <span>
                                    <span color="#fff">
                                      <v-progress-circular
                                        :size="15"
                                        color="#fff"
                                        :width="2"
                                        indeterminate
                                        v-if="getCreateChassisLoading"
                                        style="margin-right: 3px"
                                      >
                                      </v-progress-circular>
                                      Add
                                    </span>
                                  </span>
                                </v-btn>
                            </div>
                            <div class="col-md-4">
                                <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["dialogData", "dialog"],
  data: () => {
    return {
      trucker_owned: 0,
      errorMessage:'',
      errorFound:0,
      check: "",
      errorContent: "heeee",
      errorSaving: 0,
      valid: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => /^[A-Za-z0-9]+$/.test(v) || 'Required with Aphanumeric Characters',
      ],
      selectRules: [
        (v) => !!v || "This field is required",   
      ],
      isFetching: true,
      newYard: {
        name: "",
        address: "",
        trucker_owned: 0
      },
    };
  },
  computed: {
    ...mapGetters({
      getCreateChassisLoading: "chassis/getCreateChassisLoading",
      get_alert_box_text: "chassis/get_alert_box_text",
      getdetails: "chassis/getdetails",
      getChassisType: "chassis/getChassisType",
    }),
  },
  watch: {
    dialog(c){
      if(!c){
        this.$refs.form.resetValidation()
      }else{
        this.errorMessage = '';
      }
    },
  },
  methods: {
    ...mapActions("chassis", ["addNewChassis"]),
    close() {
      this.setDefault();
      this.$emit("close");
    },
    ISALPHANUMERIC(e) {
    let char = String.fromCharCode(e.keyCode); // Get the character
    if(/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
    else e.preventDefault(); // If not match, don't add to input text
   },
    closeForm() {
      if (this.getCreateChassisLoading) {
        this.setDefault();
        this.$emit("close");
      }
    },
    async save() {
      this.errorFound = 0; 
      if (this.$refs.form.validate()) 
      {
        this.newYard.trucker_owned = (this.trucker_owned == true ) ? 1 : 0;
        await this.addNewChassis(this.newYard);
        if(this.getdetails.status){
          this.close();
          this.$emit("syncAction",{created:0,msg: this.get_alert_box_text});
        } else{
          this.errorFound = 1;    
          this.errorMessage = this.getdetails.msg
        }
      }
    },
    setDefault() {
      this.newYard = {
        number: "",
        chassis_type_id: "",
      };
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
.v-select--is-menu-active {
  background: none !important;
  border: 0px !important;
}
</style>