<template>
    <div class="do-archived-tab-page-wrapper pa-6">
        <div class="do-archived-header mb-4">
            <div class="do-archived-header-title">
                <button icon dark class="btn-back-report font-medium" style="font-size: 12px; color: #4a4a4a;"> 
                    Delivery Orders 
                </button>
                <v-icon class="px-1" style="font-size: 14px;">mdi-chevron-right</v-icon>
                <span style="font-size: 12px; color: #6d858f;" class="font-medium">Archived</span>
            </div>
        </div>

        <div class="do-archived-body">
            <div class="do-archived-body-content">
                <div class="dos-table-wrapper-content">
                    <div class="d-flex justify-space-between align-center px-4 py-3" style="border-bottom: 2px solid #EBF2F5 !important;">
                        <div class="do-body-header d-flex justify-start align-center">
                            <button icon dark class="btn-white mr-3" style="height: 35px !important; width: 35px !important;" @click="goback"> 
                                <v-icon style="color: #0171A1; font-size: 16px;">mdi-arrow-left</v-icon>
                            </button>

                            <h2 style="font-family: 'Inter-Medium', sans-serif !important; font-weight: unset; font-size: 20px;">
                                Archived Delivery Orders
                            </h2>
                        </div>

                        <Search style="width:320px !important"
                            placeholder="Search Archived Delivery Orders"
                            className="search custom-search search-component-wrapper"
                            :inputData.sync="search"
                            class="searchDos"
                        />
                    </div>

                    <v-data-table
                        :headers="headers"
                        mobile-breakpoint="400"
                        :items="itemsData"
                        class="driver-table elevation-1 DOSWRAPPER"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        @page-count="pageCount = $event"
                        hide-default-footer
                        fixed-header
                        v-if="hidewhow">

                        <template v-slot:[`item.name`]="{ item }">
                            <div class="mb-0">
                                <span> {{ item.shifl_ref }} </span>
                                <span class="spanContainer">
                                    <svg style="position:relative;top:2px;" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.97506L8.11376 0.918178C8.44185 0.754134 8.80873 0.683478 9.17428 0.713941L16 1.28275L7.38462 5.59044L0 4.97506ZM16 1.89813L7.38464 6.20582V14.8212L14.8945 11.0663C15.572 10.7275 16 10.035 16 9.27745V1.89813ZM6.76923 6.20582L0 5.59044V12.3794C0 13.4138 0.788769 14.2775 1.81893 14.3712L6.76923 14.8212V6.20582ZM14.7693 3.7443L14.1539 4.05199V10.2058L14.7693 9.89814V3.7443ZM12.3077 4.97506L12.9231 4.66737V10.8212L12.3077 11.1289V4.97506ZM11.077 5.59045L10.4616 5.89814V12.052L11.077 11.7443V5.59045ZM9.23074 6.51352L8.61536 6.82121V12.9751L9.23074 12.6674V6.51352ZM3.07695 7.4366H3.69233V12.9751H3.07695V7.4366Z" fill="#6D858F"/>
                                    </svg>
                                    <span style="padding-left:4px; font-size:14px !important;color:#6D858F">{{getcontainercount(item)}}</span>
                                </span>
                                <br>
                                <p class="Mbl_number" style="text-transform: uppercase; font-family: 'Inter-Medium', sans-serif;" v-if="item.mbl_number !== null">
                                    MBL {{item.mbl_number}}
                                </p>
                            </div>
                        </template>

                        <template v-slot:[`item.closed_at`]="{ item }">
                            <span class="rejected" v-if="item.cancelled && item.reviewStatus !=='Rejected'">Cancelled</span>
                            <span class="rejected" v-if="item.reviewStatus == 'Rejected'">Rejected</span>
                            <span class="Accepted" v-if="!item.cancelled && item.reviewStatus =='Accepted'">Closed</span>
                                {{ convertToUserLocalTimezone(item.closed_at || item.created_at) }}
                        </template>

                        <template v-slot:[`item.terminal_name`]="{ item }">
                            <p class="mb-0" style="line-height: 20px;">
                                {{ terminal_name(item) }}
                            </p>
                        </template>

                        <template v-slot:[`item.customerdelivery`]="{ item }">
                            <p class="mb-0"> {{ customer_name(item) }} </p>
                        </template>

                        <template v-slot:[`item.location_to`]="{ item }">
                            <p class="mb-0"> {{ truncateString(location(item), 50) }} </p>
                        </template>
            
                        <template v-slot:[`item.eta`]="{ item }">
                            <p class="mb-0"> {{ eta(item) }} </p> 
                        </template>

                        <template v-slot:[`item.source_of_creation`]="{ item }">
                            <p class="mb-0"> {{ item.source_of_creation }} </p>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="action-button-wrapper-do">	
                                <v-menu offset-y bottom left content-class="trucking-menu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="btn-white" 
                                            style="height: 30px !important; min-width: 35px !important; padding: 4px 8px !important;">
                                            <img src="@/assets/icons/more-blue.svg" alt="" />
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item class="trucking-archived-wrapper">
                                            <v-list-item-title
                                                class="trucking-archived-item"
                                                @click="viewDosDetail(item)">
                                                <span style="padding-right:3px">
                                                    <svg style="position: relative; top: 2px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_9327_10539)">
                                                            <path d="M1.20537 9.66212L0.800803 10.0621L1.20537 9.66212ZM1.20537 6.33788L0.800803 5.93787L1.20537 6.33788ZM14.7946 9.66212L15.1992 10.0621L14.7946 9.66212ZM14.7946 6.33788L15.1992 5.93787L14.7946 6.33788ZM14.3901 9.26209C12.9464 10.8423 10.5898 12.8387 8 12.8387V14C11.0891 14 13.7319 11.6681 15.1992 10.0621L14.3901 9.26209ZM8 12.8387C5.41023 12.8387 3.0536 10.8423 1.60993 9.26209L0.800803 10.0621C2.26806 11.6681 4.91087 14 8 14V12.8387ZM1.60993 6.73791C3.0536 5.1577 5.41023 3.16129 8 3.16129V2C4.91087 2 2.26806 4.33186 0.800803 5.93787L1.60993 6.73791ZM8 3.16129C10.5898 3.16129 12.9464 5.1577 14.3901 6.73791L15.1992 5.93787C13.7319 4.33186 11.0891 2 8 2V3.16129ZM1.60993 9.26209C0.951732 8.54163 0.951732 7.45837 1.60993 6.73791L0.800803 5.93787C-0.266934 7.10658 -0.266934 8.89342 0.800803 10.0621L1.60993 9.26209ZM15.1992 10.0621C16.2669 8.89342 16.2669 7.10658 15.1992 5.93787L14.3901 6.73791C15.0483 7.45837 15.0483 8.54163 14.3901 9.26209L15.1992 10.0621ZM9.67442 8C9.67442 8.96201 8.92473 9.74194 8 9.74194V10.9032C9.54129 10.9032 10.7907 9.60343 10.7907 8H9.67442ZM8 9.74194C7.07528 9.74194 6.32558 8.96201 6.32558 8H5.2093C5.2093 9.60343 6.45874 10.9032 8 10.9032V9.74194ZM6.32558 8C6.32558 7.03799 7.07528 6.25806 8 6.25806V5.09677C6.45874 5.09677 5.2093 6.39659 5.2093 8H6.32558ZM8 6.25806C8.92473 6.25806 9.67442 7.03799 9.67442 8H10.7907C10.7907 6.39659 9.54129 5.09677 8 5.09677V6.25806Z" fill="#6D858F"/>
                                                        </g>
                                                    </svg>
                                                </span> <span class="ml-1">View</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="trucking-archived-wrapper">
                                            <v-list-item-title
                                                class="trucking-archived-item"
                                                @click="editDO(item)">
                                                <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
                                                </svg> <span style="margin-left: 10px;">Edit</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="trucking-archived-wrapper" v-if="(item.cancelled != 1) && item.reviewStatus == 'Accepted'">
                                            <v-list-item-title class="" @click="unactionDriver(item)">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.0242 8.51672L10.0544 8.49251C10.1499 8.40373 10.1585 8.26481 10.0804 8.16693L10.0543 8.13889L8.26187 6.47302C8.16641 6.3843 8.01707 6.3762 7.91177 6.44873L7.8816 6.47291L6.08706 8.13878C5.98194 8.23637 5.98182 8.39469 6.0868 8.49241C6.18223 8.58124 6.33167 8.58941 6.43703 8.51684L6.46722 8.49265L7.80041 7.25544L7.80007 12.1497C7.80007 12.2763 7.90126 12.381 8.03256 12.3975L8.06906 12.3998C8.20525 12.3998 8.31779 12.3057 8.3356 12.1837L8.33806 12.1497L8.3384 7.2511L9.67396 8.49255C9.76947 8.58131 9.91891 8.58937 10.0242 8.51672Z" fill="#6D858F"/>
                                                    <path d="M13.8498 0.799805C14.5954 0.799805 15.1998 1.40422 15.1998 2.1498V3.9498C15.1998 4.53737 14.8244 5.03726 14.3004 5.22276L14.2998 12.0498C14.2998 13.7895 12.8895 15.1998 11.1498 15.1998H4.84981C3.11011 15.1998 1.69981 13.7895 1.69981 12.0498L1.70007 5.22308C1.17559 5.03783 0.799805 4.5377 0.799805 3.9498V2.1498C0.799805 1.40422 1.40422 0.799805 2.14981 0.799805H13.8498ZM13.3998 5.2998H2.59981V12.0498C2.59981 13.2924 3.60717 14.2998 4.84981 14.2998H11.1498C12.3925 14.2998 13.3998 13.2924 13.3998 12.0498V5.2998ZM13.8498 1.6998H2.14981C1.90128 1.6998 1.69981 1.90128 1.69981 2.1498V3.9498C1.69981 4.19833 1.90128 4.3998 2.14981 4.3998H13.8498C14.0983 4.3998 14.2998 4.19833 14.2998 3.9498V2.1498C14.2998 1.90128 14.0983 1.6998 13.8498 1.6998Z" fill="#6D858F"/>
                                                </svg> <span style="margin-left: 10px;">Reopen</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="trucking-archived-wrapper" v-if="(item.cancelled == 1)">
                                            <v-list-item-title class="" @click="uncancelDO(item)" >
                                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.70047 8.19163C4.00677 8.50774 4.50336 8.50774 4.80966 8.19163C5.11593 7.87551 5.11593 7.36292 4.80966 7.0468L3.70047 8.19163ZM1.11781 4.38111L0.563214 3.80869C0.256924 4.12484 0.256924 4.63739 0.563214 4.95354L1.11781 4.38111ZM4.80966 1.71544C5.11593 1.3993 5.11593 0.886741 4.80966 0.570598C4.50336 0.254462 4.00677 0.254462 3.70047 0.570598L4.80966 1.71544ZM1.11781 3.57159C0.684649 3.57159 0.333496 3.93403 0.333496 4.38111C0.333496 4.8282 0.684649 5.19064 1.11781 5.19064V3.57159ZM8.96095 10.0478C8.52777 10.0478 8.17664 10.4102 8.17664 10.8573C8.17664 11.3044 8.52777 11.6668 8.96095 11.6668V10.0478ZM4.80966 7.0468L1.67241 3.80869L0.563214 4.95354L3.70047 8.19163L4.80966 7.0468ZM1.67241 4.95354L4.80966 1.71544L3.70047 0.570598L0.563214 3.80869L1.67241 4.95354ZM1.11781 5.19064H9.74526V3.57159H1.11781V5.19064ZM9.74526 5.19064C11.0448 5.19064 12.0982 6.27791 12.0982 7.61921H13.6668C13.6668 5.38379 11.9111 3.57159 9.74526 3.57159V5.19064ZM12.0982 7.61921C12.0982 8.96051 11.0448 10.0478 9.74526 10.0478V11.6668C11.9111 11.6668 13.6668 9.85463 13.6668 7.61921H12.0982ZM9.74526 10.0478H8.96095V11.6668H9.74526V10.0478Z" fill="#6D858F"/>
                                                </svg> <span style="margin-left: 10px;">Revert Cancellation</span> 
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item class="trucking-archived-wrapper" v-if="(item.reviewStatus == 'Rejected')">
                                            <v-list-item-title class="" @click="openReviewDialog(item)">
                                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.70047 8.19163C4.00677 8.50774 4.50336 8.50774 4.80966 8.19163C5.11593 7.87551 5.11593 7.36292 4.80966 7.0468L3.70047 8.19163ZM1.11781 4.38111L0.563214 3.80869C0.256924 4.12484 0.256924 4.63739 0.563214 4.95354L1.11781 4.38111ZM4.80966 1.71544C5.11593 1.3993 5.11593 0.886741 4.80966 0.570598C4.50336 0.254462 4.00677 0.254462 3.70047 0.570598L4.80966 1.71544ZM1.11781 3.57159C0.684649 3.57159 0.333496 3.93403 0.333496 4.38111C0.333496 4.8282 0.684649 5.19064 1.11781 5.19064V3.57159ZM8.96095 10.0478C8.52777 10.0478 8.17664 10.4102 8.17664 10.8573C8.17664 11.3044 8.52777 11.6668 8.96095 11.6668V10.0478ZM4.80966 7.0468L1.67241 3.80869L0.563214 4.95354L3.70047 8.19163L4.80966 7.0468ZM1.67241 4.95354L4.80966 1.71544L3.70047 0.570598L0.563214 3.80869L1.67241 4.95354ZM1.11781 5.19064H9.74526V3.57159H1.11781V5.19064ZM9.74526 5.19064C11.0448 5.19064 12.0982 6.27791 12.0982 7.61921H13.6668C13.6668 5.38379 11.9111 3.57159 9.74526 3.57159V5.19064ZM12.0982 7.61921C12.0982 8.96051 11.0448 10.0478 9.74526 10.0478V11.6668C11.9111 11.6668 13.6668 9.85463 13.6668 7.61921H12.0982ZM9.74526 10.0478H8.96095V11.6668H9.74526V10.0478Z" fill="#6D858F"/>
                                                </svg> <span style="margin-left: 10px;">Review Again</span> 
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </template>

                        <template v-slot:no-data>
                            <div class="no-data-preloader mt-4" v-if="startfetching">
                                <v-progress-circular
                                    :size="30"
                                    color="#0171a1"
                                    indeterminate>
                                </v-progress-circular>
                            </div>
                            <div v-else>
                                <p class="mb-0 pt-5" style="color: #4a4a4a;">No data</p>
                            </div>
                        </template>
                    </v-data-table>

                    <div>
                        <ModalDetails
                            :items="itemsdetails"
                            :tabtype="2"
                            @datastatus="hideshow"
                            @archivetype="archivetype"
                            @editDO="editDO"
                            :terminals="terminals"
                            :carriers="carriers"
							:customers="customers"
                            :defaultmodal.sync="defaultmodal" />
                    </div>

                    <Pagination
                        v-if="typeof itemsData !== 'undefined' && itemsData.length > 0"
                        :pageData.sync="page"
                        :lengthData.sync="updatedPageCount"
                        :isMobile="isMobile"
                    />

                    <Archivedo
                        :items="theitems"
                        :dialogData.sync="showarchivedo"
                        @close="closearchive"
                        @confirmCancel="confirmArchive"
                        :customer="customer"
                    />

                    <Unarchivedo
                        :items="theitemsunarchive"
                        :dialogData.sync="showunarchivedo"
                        @close="closeunarchive"
                        @confirmCancel="confirmunArchive"
                    />

                    <Uncanceldo
                        :items="uncancelData"
                        :dialogData.sync="showuncanceldo"
                        @close="closeuncancel"
                        @confirmUncancel="confirmUncancel"
                    />

                    <CreateCustomerLink
                        :dialogData="createDialogKey"
                        :datalink="datalink"
                        @close="closeDialog('createDialogKey')"
                    />

                    <AcceptedEditFormDialog
                        :openDialog.sync="AcceptedreviewDialog"
                        :item="theviewdata"
                        @refresh="refreshAccepted"
                        @closeDialog="closeDialog('AcceptedreviewDialog')"
                        :pageClass="'do-table-page'"
                    />

                    <ReviewFormDialog
                        :acceptedmsg.sync="isaccepted"
                        :rejectedmsg.sync="isrejected"
                        :openDialogreview.sync="reviewDialog"
                        :item="theviewdata"
                        :datadialog="fetchincomingData"
                        @closeDialog="closeDialog('reviewDialog')"
                    />

                    <CreateDOFormDialog
                        :openDialogCreate.sync="createFormDialog"
                        @closeDialog="closeDialog('createFormDialog')"
                        :pageClass="'do-table-page'"
                    />

                    <AcceptedNotification
                        :dialogData.sync="isacceptedshow"
                        :item="theviewdata"
                        :closeDialog.sync="iscloseacceptednotification"
                    />

                    <RejectedNotification
                        :dialogData.sync="isrejectedshow"
                        :item="theviewdata"
                        :closeDialog.sync="iscloserejectnotification" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
	mapActions
}
from "vuex";
import Pagination from "../components/Pagination.vue";
import Search from "../components/Search.vue";
import ModalDetails from "../components/Tables/DeliveryOrder/modaldetails";
import Archivedo from "../components/Tables/DeliveryOrder/dialog/Archivedo.vue";
import Uncanceldo from "../components/Tables/DeliveryOrder/dialog/Uncanceldo.vue";
import Unarchivedo from "../components/Tables/DeliveryOrder/dialog/Unarchivedo.vue";
import globalMethods from '../utils/globalMethods'
import axios from "axios";
import moment from "moment";
import ReviewFormDialog from '../components/Tables/DeliveryOrder/dialog/ReviewFormDialog.vue';
import AcceptedEditFormDialog from '../components/Tables/DeliveryOrder/dialog/AcceptedEditFormDialog.vue';
import AcceptedNotification from '../components/Tables/DeliveryOrder/dialog/NotificationAccepted.vue';
import RejectedNotification from '../components/Tables/DeliveryOrder/dialog/Notificationrejected.vue';
import CreateDOFormDialog from '../components/Tables/DeliveryOrder/dialog/CreateDOFormDialog.vue';
import CreateCustomerLink from '../components/Tables/DeliveryOrder/dialog/CreateCustomerLink.vue';
const poBaseUrl = process.env.VUE_APP_PO_URL

export default {
	name: "Connected",
	props: [
        "items",
        "isMobile",
        "itemData",
        "reloadcomponent",
        "pageCurrent",
        "allcount",
        "bus",
        "countTab"
    ],
	components: {
		Pagination,
		Search,
		AcceptedNotification,
		RejectedNotification,
		ModalDetails,
		Archivedo,
		Unarchivedo,
		ReviewFormDialog,
		CreateCustomerLink,
		CreateDOFormDialog,
		AcceptedEditFormDialog,
		Uncanceldo
		// Tab
	},
	data() {
		return {
			terminals: [],
			customers: [],
			carriers: [],
			iscloserejectnotification: false,
			iscloseacceptednotification: false,
			isacceptedshow: false,
			isrejectedshow: false,
			isaccepted: false,
			isrejected: false,
			customer: [],
			tabsmodel: [],
			createFormDialog: false,
			datalink: '',
			createDialogKey: false,
			fetchincomingData: [],
			reviewDialog: false,
			AcceptedreviewDialog: false,
			createDialog: false,
			editDialog: false,
			isAccepting: false,
			hidewhow: 1,
			pageCounter: 1,
			thepagename: '',
			textsearchLabel: '',
			isHidemodal: 0,
			theviewdata: [],
			theitemsunarchive: [],
			theitems: [],
			showarchivedo: 0,
			showunarchivedo: 0,
			itemsdetails: [], // items
			defaultmodal: false,
			tabtype: 0,
			startfetching: 0,
			loading: false,
			dataresult: [],
			page: 1,
			pageCount: 0,
			itemsPerPage: 15,
			updatedPageCount: false,
			search: "",
			lastPage: 1,
			acceptData: [],
			istabchange: 0,
			uncancelData: [],
			showuncanceldo: false,
			timeout: null,
		}
	},
	computed: {
		headers() {
			return [
				{
					text: "DO ID/Containers/MBL",
					align: "start",
					value: "name",
					sortable: false,
					width: "15%",
					fixed: true,
                },
				{
					text: "Closed at",
					align: "start",
					value: "closed_at",
					sortable: false,
					width: "12%",
					fixed: true,
                },
				{
					text: "ETA",
					align: "start",
					value: "eta",
					sortable: false,
					width: "9%",
					fixed: true,
                },
				{
					text: "Customer",
					align: "start",
					value: "customerdelivery",
					sortable: false,
					width: "12%",
					fixed: true,
                },
				{
					text: "Pickup",
					align: "start",
					value: "terminal_name",
					sortable: false,
					width: "15%",
					fixed: true,
                },
				{
					text: "Delivery",
					align: "start",
					value: "location_to",
					sortable: false,
					width: "15%",
					fixed: true,
                },
				{
					text: "",
					align: "center",
					value: "actions",
					sortable: false,
					width: "4%",
					fixed: true,
                },
            ]
		},
		itemsData: {
			get() {
				return this.dataresult
			}
		},
		countData: {
			get() {
				return this.allcount
			}
		},
		drivers: {
			get() {
				return this.items;
			},
			set(value) {
				this.$emit("items", value);
			},
		},
	},
	watch: {
		iscloserejectnotification(c) {
			if(c) {
				this.isrejectedshow = false;
				this.reviewDialog = false;
				// this.showIncoming();
			}
		},
		iscloseacceptednotification(c) {
			if(c) {
				this.isacceptedshow = false;
				// this.showIncoming();
			}
		},
		isrejected(c) {
			if(c) {
				// show the accepted box confirmation
				this.reviewDialog = false;
				this.isrejectedshow = true;
			}
		},
		isaccepted(c) {
			if(c) {
				// show the accepted box confirmation
				this.isacceptedshow = true;
			}
		},
		tabsmodel(c) {
			this.thepagename = ''
			this.pageCounter = 1;
			if(c || !c) {
				this.tabtype = c;
				if(c == 1) {
					this.thepagename = 'opened'
				}
				else if(c == 0) {
					this.thepagename = 'incoming'
				}
				this.callpager();
			}
			this["search"] = ''
		},
		page(nv) {
			this.pageCounter = nv;
			this.callpager()
		},
		search() {
			this.pageCounter = 1;
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.callpager()
			}, 1000)
		},
		pageCount: {
			handler(newValue, oldValue) {
				if(this.search !== "") {
					//console.log(newValue, oldValue);
					if(newValue !== 0) {
						this.updatedPageCount = 1;
					}
					else if(oldValue > this.lastPage) {
						//console.log("2");
						this.updatedPageCount = 1;
					}
					else if(this.lastPage > oldValue) {
						//console.log("3");
						this.updatedPageCount = this.lastPage;
					}
					else {
						//console.log("4");
						this.updatedPageCount = 1;
					}
				}
				else {
					if(newValue > 1) {
						this.updatedPageCount = newValue;
					}
					else this.updatedPageCount = this.lastPage;
				}
			},
			immediate: true,
		},
	},
	created() {},
	methods: {
		goback() {
			this.$router.push("/Dos")
		},
		refreshAccepted(c) {
			if(c) {
				// console.log(c)
			}
		},
		...mapActions({
            
		}),
		truncateString(str, num) {
			if(str.length > num) {
				return str.slice(0, num) + "...";
			}
			else {
				return str;
			}
		},
		getcontainercount(item) {
			if(item.source_of_creation == 'CF') {
				if(item.reviewStatus == 'Pending' || item.reviewStatus == 'Rejected') {
					if(item.containers != null) {
						let cont = JSON.parse(item.containers);
						return cont.length;
					}
					//return 999;
				}
				else if(item.reviewStatus == 'Accepted') {
					return item.shipments.length
				}
			}
			if(item.source_of_creation == 'BO') {
				if(item.reviewStatus == 'Pending' || item.reviewStatus == 'Rejected') {
					if(item.containers != null) {
						let cont = JSON.parse(item.containers);
						return cont.length;
					}
				}
				else {
					return item.shipments.length
				}
			}
			if(item.source_of_creation == 'FE') {
				return item.shipments.length
			}
			if(item.source_of_creation == 'SD') {
				if(item.reviewStatus == 'Pending' || item.reviewStatus == 'Rejected') {
					if(item.containers_group_bookings != null) {
						let cont = JSON.parse(item.containers_group_bookings);
						return cont.length;
					}
				}
				else {
					return item.shipments.length
				}
			}
			return 0;
		},
		terminal_name(item) {
			if(this.tabtype == 0) {
				if(item.source_of_creation == 'SD') {
					if(typeof this.terminals != 'undefined') {
						let findterminal = this.terminals.find((find) => find.firms_code == item.central_firms_code);
						if(findterminal != null) {
							if(findterminal.map_location != '') {
								return findterminal.map_location
							}
						}
					}
					return '--';
				}
				else {
					return item.terminal?.name ?? '--';
				}
			}
			else {
				return item.terminal?.name ?? '--';
			}
		},
		archivetype(type) {
			this.isHidemodal = 1;
			if(type == 'unarchive') {
				this.theitemsunarchive = this.itemsdetails;
				this.showunarchivedo = 1;
			}
			else if(type == 'archive') {
				this.theitems = this.itemsdetails;
				this.showarchivedo = 1;
			}
			else {
				this.actionDoCancel(this.itemsdetails);
			}
			//alert(type)
		},
		confirmunArchive(item) {
			if(item.result) {
				//run archive
				axios.put(`${poBaseUrl}/unarchive-do/` + item.item.id).then((response) => {
					if(response) {
						this.countTab = response.count
						this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' + 'Delivery Order has been successfully Unarchived' + "</span>");
						this.showArchived();
						this.showunarchivedo = 0
						if(this.isHidemodal) {
							this.defaultmodal = false;
						}
					}
				}).catch((error) => {
					console.log(error);
				});
			}
		},
		confirmArchive(item) {
			if(item.result) {
				//run archive
				axios.put(`${poBaseUrl}/archive-do/` + item.item.id).then((response) => {
					if(response) {
						this.countTab = response.count
						this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' + 'Delivery Order has been successfully Archived' + "</span>");
						// this.showOpened();
						this.showarchivedo = 0;
						if(this.isHidemodal) {
							this.defaultmodal = false;
						}
					}
				}).catch((error) => {
					console.log(error);
				});
			}
		},
		closeunarchive() {
			this.showunarchivedo = 0
		},
		closearchive() {
			this.showarchivedo = 0
		},
		customer_name(item) {
			if(this.tabtype == 0) {
				if(item.source_of_creation == 'SD') {
					if(item.reviewStatus === 'Pending') {
						return 'Shifl DO';
					}
					return item.customerdelivery?.company_name ?? '--';
				}
				else if(item.source_of_creation == 'CF') {
					return item.company_name ?? '--';
				}
				else if(item.source_of_creation == null) {
					return '--';
				}
				let f = this.customers.find((find) => find.id == item.customer);
				return f?.company_name ?? '--'
			}
			else {
				return item.customerdelivery?.company_name ?? '--';
			}
		},
		viewDosDetail(item) {
			// console.log(item)
			this.itemsdetails = item;
			//this.theviewdata = item;
			this.defaultmodal = true;
		},
		hideshow(status) {
			if(!status) this.defaultmodal = false;
		},
		clicknow(item) {
			console.log(item)
		},
		createditem(item) {
			// -- old code
			// if(this.tabtype == 2) {
			// 	return item.date_of_changed_status != null ? moment(item.date_of_changed_status).utc(false).format("h:mm A, MMM DD, YYYY") : '---'
			// }
			// return moment(item.created_at).utc(false).format("h:mm A, MMM DD, YYYY")
			// old code --
			// updated code (converting date to user local timezone)
			let date = item.created_at
			let utcTime = ""
			var local_date = ""
			if(this.tabtype == 2) {
				date = item.date_of_changed_status != null ? item.date_of_changed_status : null
			}
			if(date !== null) {
				utcTime = moment(item.created_at).utc(false).format("h:mm A, MMM DD, YYYY")
				local_date = moment.utc(utcTime).local().format('h:mm A, MMM DD, YYYY');
			}
			return local_date
		},
		location(item) {
			if(item.source_of_creation == 'SD') {
				return item.delivery_location || item.description || '--'
			}
			return item.delivery_location || '--';
			//console.log(item.source_of_creation);
		},
		eta(item) {
			/**
			 * ETA should show the date only
			 */
			// return moment(item.eta).utc(false).format("h:mm A, MMM DD, YYYY")
			return moment(item.eta).utc(false).format("MMM DD, YYYY")
		},
		movetoopen(item) {
			let url = 'move-archived-to-opened/' + item.id;
			axios.put(`${poBaseUrl}/` + url).then((response) => {
				if(response) {
					console.log(response);
					this.dispatchNotifcation('<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
						'<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z" fill="#F93131"/>' +
						'</svg>&nbsp;<span class="text-message">' + response.data.result + "</span>");
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		// Move to Open
		movetoincoming(item) {
			let url = 'move-archived-to-incoming-opened/' + item.id;
			axios.put(`${poBaseUrl}/` + url).then((response) => {
				if(response) {
					console.log(response);
					this.dispatchNotifcation('<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
						'<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z" fill="#F93131"/>' +
						'</svg>&nbsp;<span class="text-message">' + response.data.result + "</span>");
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		async callpager() {
			await this.showArchived();
		},
		async showArchived() {
			this.thepagename = 'archived'
			this.textsearchLabel = 'Search Archived DO'
			this.loading = true;
			this.dataresult = [];
			this.startfetching = 1;
			await axios.get(`${poBaseUrl}/index-do/archived`, {
				params: {
					page: this.pageCounter,
					search: this.search,
				},
			}).then((response) => {
				if(response) {
					this.loading = false;
					this.startfetching = 0;
					this.page = this.pageCounter;
					this.lastPage = response.data.result.last_page;
					if(response.data.result.last_page > 1) {
						this.pageCount = response.data.result.last_page;
					}
					this.dataresult = response.data.result.data;
					this.customer = response.data.customer.data;
					this.terminals = response.data.terminal
					this.carriers = response.data.carrier
				}
			}).catch((error) => {
				console.log(error);
			});
		},
		actionDoCancel(item) {
			axios.put(`${poBaseUrl}/cancell-do/` + item.id).then((response) => {
				if(response) {
					this.countTab = response.count
					this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' + 'Delivery Order has been successfully Cancelled ' + "</span>");
				}
				if(this.tabtype == 1) {
					// this.showOpened();
				}
				if(this.isHidemodal) {
					this.defaultmodal = false;
				}
				// this.$emit('reloadpage',0)
			}).catch((error) => {
				console.log(error);
			});
		},
		unactionDriver(item) {
			this.theitemsunarchive = item;
			this.showunarchivedo = 1;
		},
		actionDriver(item) {
			if(item) {
				this.theitems = item;
				this.showarchivedo = 1;
			}
		},
		openReviewDialog(item) {
			//delivery-order/review
			//this.$router.push("/delivery-order/review/"+item.id).catch(()=>{});
			this.theviewdata = item
			this.reviewDialog = true
		},
		openCreateDialog() {
			this.createFormDialog = true
		},
		async openCreatekey() {
			await axios.get(`${poBaseUrl}/customer-link`).then((response) => {
				this.datalink = document.location.origin + '/do-form/' + response.data
			}).catch((error) => {
				console.log(error);
			});
			this.createDialogKey = true
		},
		editDO(item) {
			this.theviewdata = item
			this.AcceptedreviewDialog = true
		},
		closeDialog(data) {
			this[data] = false
		},
		acceptDO(item) {
			console.log(item)
			this.acceptData = item
			this.isAccepting = true
		},
		uncancelDO(item) {
			this.uncancelData = item
			this.showuncanceldo = true
		},
		closeuncancel() {
			this.showuncanceldo = false
		},
		confirmUncancel(item) {
			if(item.result) {
				//run uncancel
				axios.put(`${poBaseUrl}/uncancel-do/` + item.item.id).then((response) => {
					if(response) {
						// this.showOpened();
						this.showArchived();
						this.tabsmodel = 1
						this.countTab = response.count
						this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' + 'Delivery Order has been successfully Uncancelled' + "</span>");
						this.showuncanceldo = false;
						if(this.isHidemodal) {
							this.defaultmodal = false;
						}
					}
				}).catch((error) => {
					console.log(error);
				});
			}
		},
		getCounter(item) {
			let humanize = moment.duration(moment().diff(item.created_at)).humanize()
			return humanize + ' ago'
		},
		getETACounter(item) {
			let now = moment(moment().format('YYYY-MM-DD') + "T00:00:00.000000Z")
			let eta = moment(item.eta).format('YYYY-MM-DD')
			let countDay = moment.duration(moment(item.eta).diff(moment()))
			let isPast = moment(item.eta).isBefore(moment())
			let humanize = moment.duration(moment(item.eta).diff(moment())).humanize()
			// console.log('count day >>>', countDay.days())
			// console.log('eta >>>', eta)
			// console.log('now >>>', now.format('YYYY-MM-DD'))
			if(now.format('YYYY-MM-DD') === eta) {
				return 'Today'
			}
			else {
				if(countDay.days() === 1) {
					if(now.isBefore(eta)) {
						return '1 day to'
					}
					else {
						return '1 day ago'
					}
				}
				else if(countDay.days() === 0) {
					return '1 day ago'
				}
				return humanize + (isPast ? ' ago' : ' to')
			}
		},
    convertToUserLocalTimezone(value, isFor){
			if (isFor === "date") {
				return moment.utc(value).local().format("MMM DD, YYYY")
			} else if (isFor === "time") {
				return moment.utc(value).local().format("h:mm A")
			} else {
				return moment.utc(value).local().format("h:mm A, MMM DD, YYYY")
			}
    },
		...globalMethods,
	},
	async mounted() {
		// this.showIncoming();
        this.showArchived();
		this.$store.dispatch("page/setPage", "Dos");
	},
	updated() {},
};
</script>

<style lang="scss">
.font-medium {
    font-family: "Inter-Medium", sans-serif !important;
}

.do-archived-tab-page-wrapper {
    height: 100%;

    .do-archived-header {
        .do-archived-header-title {
            display: flex;
            align-items: center;
        }
    }

    .search-component-wrapper {
        .v-input {
			margin-right: 0 !important;

            .v-input__control {
                .v-input__slot {
                    width: 320px !important;
                    margin-bottom: 0;

					input {
						font-family: "Inter-Regular", sans-serif !important;
					}
                }
            }
        }
    }

    .do-archived-body {
        height: calc(100% - 40px);
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ebf2f5;

        .dos-table-wrapper-content {
            .v-data-table.DOSWRAPPER {
                box-shadow: none !important;
                border: none !important;

                .v-data-table__wrapper {
                    height: calc(100vh - 275px);
                    overflow-y: auto;

                    table {
                        thead {
                            tr {
                                th {
                                    box-shadow: none !important;
                                    border-bottom: 2px solid #EBF2F5 !important;
                                    height: 40px;
                                    padding: 8px 10px !important;
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    color: #6D858F;

                                    &:first-child {
                                        padding-left: 16px !important;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    border-bottom: 1px solid #EBF2F5 !important;
                                    padding: 10px !important;

                                    &:first-child {
                                        padding-left: 16px !important;
                                    }

                                    .action-button-wrapper-do {
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;

                                        .btn-white.reviewbutton {
                                            height: 30px !important;
                                            font-size: 12px !important;
                                            margin-right: 6px;
                                            padding: 4px 10px !important;
                                        }
                                    }
                                }

                                &.v-data-table__empty-wrapper {
                                    cursor: unset;

                                    &:hover {
                                        background-color: #fff !important;
                                    }

                                    td {
                                        border-bottom: none !important;
                                    }
                                }
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 8px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #d3d3d3;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #d3d3d3;
                    }
                }		

                .btn-black.archived {
                    min-width: 75px !important;
                    padding: 0 !important;
                }

                .Mbl_number {
                    font-size: 14px !important;
                    color: #6D858F;
                    margin-bottom: 0px;
					margin-top: 6px;
                }
                
                .spanContainer {
                    padding-left: 6px;
                    padding-top: 2px;
                    padding-right: 5px;
                    padding-bottom: 1px;
                    border: 2px solid #D8E7F0;
                    border-radius: 3px;
                    margin-left: 3px !important;
                }

                .dosdropdown {
                    width: 100% !important;
                }

                .rejected,
                .Rejected {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 12px;
                    gap: 10px;
                    width: 70px;
                    height: 26px;
                    background: #FFF2F2;
                    border-radius: 99px;
                    font-size: 12px;
                    font-family: 'Inter-Medium', sans-serif;
                    font-weight: 400;
                    color: #F93131
                }
                .Accepted {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 8px;
                    gap: 10px;
                    width: 55px;
                    height: 26px;
                    background: #f7f7f7 !important;
                    border-radius: 99px;
                    font-size: 12px;
                    font-family: 'Inter-Medium', sans-serif;
                    font-weight: 400;
                    color: #4a4a4a;
                }
            }

            .pagination-wrapper {
                border-left: none !important;
                border-right: none !important;
                border-top: 1px solid #EBF2F5 !important;
                border-bottom: 1px solid #EBF2F5 !important;
            }
        }
    }
}

.v-menu__content.trucking-menu {
	min-width: 150px !important;

    .trucking-archived-wrapper {
        min-height: 36px;
        cursor: pointer;
        
        .v-list-item__title {
            font-size: 14px !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
    }
}
</style>