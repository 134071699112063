<template>
<div>
<v-divider color="#EBF2F5 "></v-divider>
  <div class="py-6 px-5" style="background-color:white" >
    <h3 class="integration-primary-color mt-1" style="font-size:20px">Integrations</h3>
    <p class="integration-secondary-color pt-1">Connect Shifl with your online services to streamline your work process. 
        Get <br> started by pressing the ‘Integrate’ button under your relevant service.</p>
		<v-card width="360" height="240" elevation="0" style="border: 1px solid #EBF2F5;">
			<v-card-title class="justify-center">
				<img src="../../../assets/images/quickbooks-integration.svg" alt="">
			</v-card-title>
			<v-card-text class="text-center integration-primary-color body-1 pb-0" style="font-weight:600">Quickbooks</v-card-text>
			<v-card-subtitle class="text-center pt-1">Connect to sync your invoices & bills and manage your accounting entries</v-card-subtitle>
			<v-card-actions class="justify-center">
				<v-btn class="disable-events text-capitalize elevation-0" dark>Coming Soon</v-btn>
			</v-card-actions>
		</v-card>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.integration-primary-color{
	color: #4A4A4A;
}
.integration-secondary-color{
	color: #6D858F;
}
.disable-events {
  pointer-events: none;
  background-color:  #B4CFE0 !important;
  font-weight: 500 !important;
font-size: 14px !important;
}
</style>