<template>
    <div class="text-center">
        <v-dialog persistent v-model="getShowReportDialog" max-width="590px">
            <v-card class="add-supplier-card">
                <v-form id="companyReportForm" ref="form" @submit.prevent>
                    <v-card-title style="caret-color: transparent">
                        <span class="headline">Set Up Report</span>
                        <a autofocus icon dark class="btn-close" @click.prevent="close">
                            <v-icon>mdi-close</v-icon>
                        </a>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text>
                        <v-row>
                            <v-col style="color: red" v-if="errorFound" cols="12" sm="12" md="12" class="pb-3 pt-1">
                                {{ errorMessage }}
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                                <label class="text-item-label">Report Type</label>
                                <v-select
                                    v-model="reportType"
                                    class="text-fields select-items"
                                    :items="reportTypeOptions"
                                    item-text="text"
                                    item-value="value"
                                    placeholder="Select Report Type"
                                    dense
                                    outlined
                                    :rules="selectRules"
                                    hide-details="auto"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                                <label class="text-item-label">Frequency</label>
                                <div>
                                    <v-radio-group class="radio-box-group"  v-model="frequency" row @change="onChangeFrequency">
                                        <v-radio
                                            class="radio-box mr-0"
                                            v-for="freq,i in frequencyOptions"
                                            :key="i"
                                            :label="freq.text"
                                            :value="freq.value"
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                            </v-col>
                            <template v-if="frequency == 'DAILYAT'">
                                <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                                    <label class="text-item-label">Time</label>
                                    <v-text-field
                                        class="text-fields input-time"
                                        outlined
                                        type="text"
                                        hide-details="auto"
                                        v-model="timeText"
                                        placeholder="Set Time"
                                        append-icon="mdi-clock-time-four-outline"
                                        @change="handleTimeChange(timeText)"
                                        :rules="[timeRules(timeText).reportSchedule]"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </template>
                            <template v-else-if="frequency == 'WEEKLYON'">
                                <v-col cols="6" sm="12" class="pt-0 pb-2" md="6">
                                    <label class="text-item-label">Day</label>
                                    <v-select
                                        class="text-fields select-items"
                                        placeholder="Select Day"
                                        :items="dayOptions"
                                        outlined
                                        item-text="text"
                                        item-value="value"
                                        v-model="day"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        dense
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="6" sm="12" class="pt-0 pb-2" md="6">
                                    <label class="text-item-label">Time</label>
                                    <v-text-field
                                        class="text-fields input-time"
                                        outlined
                                        type="text"
                                        hide-details="auto"
                                        v-model="timeText"
                                        placeholder="Set Time"
                                        append-icon="mdi-clock-time-four-outline"
                                        @change="handleTimeChange(timeText)"
                                        :rules="[timeRules(timeText).reportSchedule]"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </template>
                            <template v-else-if="frequency == 'MONTHLY'">
                                <v-col cols="6" sm="12" class="pt-0 pb-2" md="6">
                                    <label class="text-item-label">Day</label>
                                    <v-select
                                        class="text-fields select-items"
                                        placeholder="Select Day"
                                        :items="daysInMonth"
                                        outlined
                                        item-text="text"
                                        item-value="value"
                                        v-model="day"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        dense
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="6" sm="12" class="pt-0 pb-2" md="6">
                                    <label class="text-item-label">Time</label>
                                    <v-text-field
                                        class="text-fields input-time"
                                        outlined
                                        type="text"
                                        hide-details="auto"
                                        v-model="timeText"
                                        placeholder="Set Time"
                                        append-icon="mdi-clock-time-four-outline"
                                        @change="handleTimeChange(timeText)"
                                        :rules="[timeRules(timeText).reportSchedule]"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </template>
                            <template v-else-if="frequency == 'YEARLY'">
                                <v-col cols="4" sm="12" class="pt-0 pb-2" md="4">
                                    <label class="text-item-label">Month</label>
                                    <v-select
                                        class="text-fields select-items"
                                        placeholder="Select Month"
                                        :items="monthOptions"
                                        outlined
                                        item-text="text"
                                        item-value="value"
                                        v-model="month"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        dense
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="12" class="pt-0 pb-2" md="4">
                                    <label class="text-item-label">Day</label>
                                    <v-select
                                        class="text-fields select-items"
                                        placeholder="Select Day"
                                        :items="daysInMonth"
                                        outlined
                                        item-text="text"  
                                        item-value="value"
                                        v-model="day"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        dense
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="12" class="pt-0 pb-2" md="4">
                                    <label class="text-item-label">Time</label>
                                    <v-text-field
                                        class="text-fields input-time"
                                        outlined
                                        type="text"
                                        hide-details="auto"
                                        v-model="timeText"
                                        placeholder="Set Time"
                                        append-icon="mdi-clock-time-four-outline"
                                        @change="handleTimeChange(timeText)"
                                        :rules="[timeRules(timeText).reportSchedule]"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </template>
                            <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                                <label class="text-item-label">Recipients</label>
                                <vue-tags-input
                                    hide-details="auto"
                                    allow-edit-tags
                                    :rules="arrayNotEmptyRules"
                                    :tags="recipientTag"
                                    style="min-width:100%!important"
                                    :add-on-blur=true
                                    :add-on-key="[13, ',']"
                                    :validation="tagsValidation"
                                    v-model="emailAddress"
                                    placeholder="e.g. name@email.com"
                                    @tags-changed="newTags => {
                                    this.recipientTag = newTags
                                    this.tagObject.touched = true
                                    this.tagObject.hasError = (this.recipientTag.length > 0) ? false : true
                                    let el = this.documentProto.getElementsByClassName('ti-input')[0];
                                        if (typeof el !== 'undefined') {
                                            if (this.tagObject.hasError)
                                                el.classList.add('ti-new-tag-input-error')
                                            else
                                                el.classList.remove('ti-new-tag-input-error')               
                                        }
                                    }"
                                />
                                <label class="text-item-label">separte multiple address with comma</label>
                                <div v-if="tagObject.touched" class="v-text-field__details">
                                    <div class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div v-if="(recipientTag.length > 0) && emailAddress !== ''" class="v-messages__message">
                                            {{ tagObject.errorMessage }}
                                            </div>
                                            <div v-if="recipientTag.length == 0 && emailAddress !== ''" class="v-messages__message">
                                                {{ tagObject.errorMessage }}
                                            </div>
                                            <div v-if="recipientTag.length == 0 && emailAddress == ''" class="v-messages__message">
                                            Please provide at least 1 valid email address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions style="padding-left: 4px; padding-left: 11px; margin-top: 5px; border-top: 2px solid #ebf2f5;">
                        <!-- <v-col class="text-left buttonActions" style="padding-right: 0px">
                            <v-btn class="btn-blue mr-2" text @click.prevent="onSaveReport" :disabled="isSaving">
                            <span>
                                <span color="#fff">
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isSaving"
                                    style="margin-right: 5px"
                                >
                                </v-progress-circular>
                                Save
                                </span>
                            </span>
                            </v-btn>
                            <v-btn class="btn-white" text @click.prevent="close"> Cancel </v-btn>
                        </v-col> -->
                        <div class="text-right buttonActions" style="padding:0px">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 ml-2">
                                            <v-btn class="btn-blue mr-2" text @click.prevent="onSaveReport" :disabled="isSaving">
                                                <span>
                                                    <span color="#fff">
                                                    <v-progress-circular
                                                        :size="15"
                                                        color="#fff"
                                                        :width="2"
                                                        indeterminate
                                                        v-if="isSaving"
                                                        style="margin-right: 5px"
                                                    >
                                                    </v-progress-circular>
                                                    Save
                                                    </span>
                                                </span>
                                            </v-btn>
                                        </div>
                                        <div class="col-md-4">
                                            <v-btn class="btn-white" text @click.prevent="close"> Cancel </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from 'axios';
import moment from 'moment';
import VueTagsInput from '@johmun/vue-tags-input';
import globalMethods from '../../../../utils/globalMethods';
import _ from 'lodash';
  
const APIBaseUrl = process.env.VUE_APP_PO_URL;
  
export default {
    props: ["dialogData", "dialog"],

    components: {
        VueTagsInput,
    },
    
    data: () => {
        return {
            errorMessage: "",
            errorFound: 0,
            check: "",
            errorContent: "heeee",
            errorSaving: 0,
            valid: true,
            isSaving: false,
            inputRules: [
                (v) => !!v || "This field is required",
                (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
            ],
            isFetching: true,
            reportTypeOptions:[
                { value: 'shipment_report', text: 'Container/Shipment Report' },
                { value: 'leg_report', text: 'Leg Report' },
                { value: 'do_report', text: 'Delivery Order Report' },
            ],
            selectRules: [
                (v) => !!v || 'Input is required.'
            ],
            frequencyOptions:[
                { value: 'DAILYAT', text: 'Daily' },
                { value: 'WEEKLYON', text: 'Weekly' },
                { value: 'MONTHLY', text: 'Monthly' },
                { value: 'YEARLY', text: 'Yearly' },
            ],
            dayOptions:[
                { value: 1, text: 'Monday' },
                { value: 2, text: 'Tuesday' },
                { value: 3, text: 'Wednesday' },
                { value: 4, text: 'Thursday' },
                { value: 5, text: 'Friday' },
                { value: 6, text: 'Saturday' },
                { value: 7, text: 'Sunday' },
            ],
            monthOptions:[
                { value: '1', text: 'January' },
                { value: '2', text: 'February' },
                { value: '3', text: 'March' },
                { value: '4', text: 'April' },
                { value: '5', text: 'May' },
                { value: '6', text: 'June' },
                { value: '7', text: 'July' },
                { value: '8', text: 'August' },
                { value: '9', text: 'September' },
                { value: '10', text: 'October' },
                { value: '11', text: 'November' },
                { value: '12', text: 'December' },
            ],
    
            reportId: 0,
            frequency: '',
            month: '',
            day: '',
            time: '',
            time2: '',
            active: true,
            truckerId: '',
            companyName: '',
            reportType: '',
            recipientTag: [],
            emailAddress: '',
            timeText:'',
            tagObject: {
                touched: false,
                hasError: false,
                errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
            },
            arrayNotEmptyRules: [
                (v) => !!v || "Email is required",
                () => this.recipientTag.length > 0 || "Make sure to supply at least 1 email."
            ],
            tagsValidation: [
                {
                    classes: 't-new-tag-input-text-error',
                    rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                    disableAdd: true
                }
            ],
            validationErrors: {},
            documentProto: document,
        };
    },
  
    computed: {
        ...mapGetters("reports", ["getShowReportDialog", "getLoadingStatus", "getSelectedReportData", "getIsFormForEdit"]),
        ...mapGetters("profile", ["getCompanyProfile"]),
        
        daysInMonth(){
            let days = [];
            for(let i = 1; i <= 30; i++ ){
                days.push({ text: 'Day '+ i, value: i});
            }
            return days;
        },
  
        optionsFiltered: {
            get() {
                let validEmailAddress = []
                if (this.recipientTag.length > 0) {                    
                    this.recipientTag.map(em => {
                        if (em !== null) {
                            validEmailAddress.push({text: em, tiClasses:["ti-valid"]})
                        }
                    })
                }
                return validEmailAddress
            },
            set() {
            // this.optionsHigh = value
            }            
        },
    },

    watch: {
        getSelectedReportData(nval){
            let recipients = [];
            if(nval && typeof nval.recipients !== 'undefined'){
                const rec = JSON.parse(nval.recipients);
                if(rec && rec.emails){
                    rec.emails.map((em) => {
                        recipients.push({
                            text:em,
                            tiClasses: ["ti-valid"]
                        })
                    });
                }
                this.reportId = nval.id;
                this.frequency = nval.frequency;
                this.truckerId = nval.truckerId;
                this.month = nval.month;
                this.day = nval.day;
                this.time = nval.time;
                this.time2 = nval.time2;
                this.reportType = nval.report_type;
                this.recipientTag = recipients;
                this.timeText = moment(nval.time, "HH:mm:ss").format('hh:mm A');
            }
        },
    },
  
    created(){
        this.getCompanyProfileAsync();
    },
  
    methods: {
        ...mapActions("profile", ["getCompanyProfileAsync"]),
        ...mapMutations("reports", [
            "SET_SELECTED_COMPANY_REPORT",
            "SET_SHOW_COMPANY_REPORT_DIALOG",
            "SET_COMPANY_REPORT_FOR_EDIT",
            "SET_COMPANY_REPORT_RELOAD_INDEX",
        ]),
        ...globalMethods,
    
        onChangeFrequency(val){
            this.frequency = val;
            if(val == 'DAILYAT'){
            // 
            }else if(val == 'WEEKLYON'){
            // 
            }else if(val == 'MONTHLY'){
            // 
            }else if(val == 'YEARLY'){
            // 
            }else{
            // 
            }
        },

        handleTimeChange(value){
            if(value){
                let momentOb = moment(value, "hh:mm A");
                if (momentOb.isValid()) {
                    this.timeText = momentOb.format("hh:mm A");
                    this.time = momentOb.format("HH:mm");
                }else if(value[0] && value[0] > 2){
                    value = '0' + value;
                    momentOb = moment(value, "hh:mm A");
                    if (momentOb.isValid()) {
                        this.timeText = momentOb.format("hh:mm A");
                        this.time = momentOb.format("HH:mm");
                    }
                }
            }
        },
  
        onSaveReport(){
            this.isSaving = true;
            let formdata = new FormData;
            let recipients = [];

            if(this.recipientTag.length > 0){
                this.recipientTag.map(({text}) => {
                    recipients.push(text);
                });
            }
        
            if(this.getCompanyProfile.id){
                if(this.isFormValid()){
                    formdata.append('trucker_id', this.getCompanyProfile.id);
                    formdata.append('frequency', this.frequency);
                    formdata.append('month', this.month);
                    formdata.append('day', this.day);
                    formdata.append('time', this.time);
                    formdata.append('time2', this.time2);
                    formdata.append('report_type', this.reportType);
                    formdata.append('recipients', JSON.stringify({emails:recipients}));

                    if(this.getIsFormForEdit){
                        formdata.append('_method', 'put');
                        axios.post(`${APIBaseUrl}/reports/company/${this.reportId}`, formdata)  
                        .then((res) => {
                            if (res.data.status == 'success') {
                            this.isSaving = false;
                            this.dispatchNotifcation(res.data.message);
                            this.close();
                            this.SET_COMPANY_REPORT_RELOAD_INDEX(true);
                            }else{
                            this.isSaving = false;
                            this.dispatchNotifcationError(res.data.message)
                            }
                        })
                        .catch((err) => {
                            this.isSaving = false;
                            if(err.status == "validation-error"){
                                let message = err.message 
                                if(Object.keys(err.data).length > 0){
                                    let errors = Object.keys(err.data).map((e) => e);
                                    message = err.message +': '+ err.data[errors[0]][0];
                                }
                                this.dispatchNotifcationError(message)
                            }else{
                                this.dispatchNotifcationError(err.message)
                            }
                        });
                    }else{
                        axios.post(`${APIBaseUrl}/reports/company`, formdata)  
                        .then((res) => {
                            if (res.data.status == 'success') {
                                this.dispatchNotifcation(res.data.message)
                                this.isSaving = false;
                                this.close();
                                this.SET_COMPANY_REPORT_RELOAD_INDEX(true);
                            }else{
                                this.isSaving = false;
                                this.dispatchNotifcationError(res.data.message)
                            }
                        })
                        .catch((err) => {
                            this.isSaving = false;
                            if(err.status == "validation-error"){
                                let message = err.message 
                                if(Object.keys(err.data).length > 0){
                                    let errors = Object.keys(err.data).map((e) => e);
                                    message = err.message +': '+ err.data[errors[0]][0];
                                }
                                this.dispatchNotifcationError(message)
                            }else{
                                this.dispatchNotifcationError(err.message)
                            }
                        });
                    }
                }else{
                    this.dispatchNotifcationError('Invalid form, all fields are required.');
                    this.isSaving = false;
                }
            }else{
                this.dispatchNotifcationError('Trucker Id is not defined, please refresh page.');
                this.isSaving = false;
            }
        },
  
        close() {
            this.SET_SHOW_COMPANY_REPORT_DIALOG(false);
            this.SET_COMPANY_REPORT_FOR_EDIT(false);
            this.SET_SELECTED_COMPANY_REPORT({});
            this.setDefault();
        },
    
        clearSearchPlaces() {
            this.places = [];
        },
  
        setDefault() {
            this.reportId = 0,
            this.frequency = '',
            this.month = '',
            this.day = '',
            this.time = '',
            this.time2 = '',
            this.active = true,
            this.truckerId = '',
            this.companyName = '',
            this.reportType = '',
            this.recipientTag = [],
            this.emailAddress = '';
            this.timeText = '';
            this.$refs.form.reset();
            this.tagObject.touched = false;
            this.tagObject.hasError = false;
        },

        setReloadIndex(value){
            this.SET_COMPANY_REPORT_RELOAD_INDEX(value);
        },

        timeRules(time = "") {
            time = time !== '' ? moment(time, "hh:mm A").format("HH:mm") : '' // format to 24 hr
            return {
                reportSchedule: () => {
                    let msg = "";
                    if(!_.isEmpty(time)) {
                        time = moment(time, "HH:mm", true)
                        if(time.isValid()) {
                            return true;
                        }
                        else {
                            msg = this.validationErrors["time"] = "Invalid Time ex. HH:mm"
                            return msg
                        }
                    }
                    return msg;
                }
            }
        },

        isFormValid(){
            if(this.frequency == ''){
                return false;
            }else if(this.time == ''){
                return false;
            }else if(!this.frequency == 'DAILYAT'){
                if(this.day == ''){
                    return false;
                }
            }else if(this.frequency == 'YEARLY'){
                if(this.month == ''){
                    return false;
                }
            }else if(this.recipientTag.length == 0){
                return false;
            }else{
                return true;
            }
        }

    },
    
  };
</script>
<style lang="scss">
  .v-text-field--outlined >>> fieldset {
    border-color: #b4cfe0;
    border-width: 1px;
  }

  .radio-box {
    color: #B4CFE0;
    border: solid 1px;
    padding: 7px 16px 7px;
    border-radius: 4px;
		border-color: #B4CFE0;
    label {
      color:#B4CFE0;
    }
    .v-input--selection-controls__input{
      i{
        color:#B4CFE0;
      }
    }
  }

  .radio-box-group{
    border-top: 0px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    .v-input__control{
      .v-messages{
        min-height: 0px !important;
      }
      .v-input__slot{
        .v-input--radio-group__input{
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  
.ti-tag {
    border-radius: 4px !important;
    font-size: 14px;
    font-family: 'Inter-Medium', sans-serif;
    padding: 3px 10px !important;
    line-height: 1;
    height: 30px;
    background-color: #F7F7F7 !important;
    border: 1px solid #E1ECF0;
    color: #6D858F !important;
    margin: 6px 2px 2px 2px;
    min-width: 75px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.ti-new-tag-input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}

.ti-input {
    border: 1px solid #B4CFE0 !important;
    border-radius: 4px;
    min-height: 40px;
    font-size: 16px;
}

.ti-input .ti-tags span {
    color: #4a4a4a !important;
}

.ti-new-tag-input::placeholder,
.ti-input::placeholder{
    color: #B4CFE0 !important;
}
.ti-new-tag-input {
        caret-color: #000 !important;
    }

.t-new-tag-input-error {
    border: 1px solid #fc8686 !important;
}

.ti-new-tag-input-text-error {
    color: #ff5252 !important;
}

.ti-new-tag-input-wrapper input {
    font-size: 14px !important;
}
    .input-time{
        .v-input__control{
            .v-input__slot{
                .v-input__append-inner{
                    .v-input__icon {
                        i {
                            color: #B4CFE0 !important;
                            position: absolute;
                            top: 50%;
                            bottom: 50%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .radio-box-group{
        .v-input__control{
            .v-input__slot{
                .v-input--radio-group__input{
                    .v-item--active{
                        color:#0171A1;
                        border: 1px solid #0171A1;
                        label{
                            color: #0171A1;
                        }
                        .v-input--selection-controls__input{
                            i::before{
                                color:#0171A1 !important;
                                content: "\F0EC2" !important;
                            }

                        }
                    }
                }
            }
        }
    }
</style>
  