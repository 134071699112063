<template>
    <v-dialog persistent v-model="dialogDelete" max-width="480px" content-class="delete-driver-dialog dialogYard"  :retain-focus="false">
        <v-card style="padding-bottom:8px">
          <v-card-text class="pt-5 pb-0 contentbody" style="caret-color: transparent;padding-bottom:0px !important">
            <img src="../../assets/icons/warming.svg" />
            <h2 style="margin-top:12px">Delete Yard</h2>
            <p>Do you want to delete <strong>{{ deleteYardItemList.name ? deleteYardItemList.name : '-----'}}</strong>  yard ? This action cannot be undone.</p>
          </v-card-text>
          <v-card-actions style="border-top:0px;">
            <v-btn @click="deletenow" class="btn-blue">
                <span>
                    <span>
                        <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="getDeleteYardsLoading"
                                    style="margin-right:3px"
                                    >                       
                                    </v-progress-circular> 
                       Delete 
                    </span>
                </span>
            </v-btn>
            <v-btn 
                style="margin-left:0px !important" 
                class="btn-white" 
                text 
                @click="close"
            >
                Cancel
            </v-btn>           
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    props: [ 'dialogData','dialogDelete','deleteYardItemList'],
  data: () => {
    return {
      errorContent: "",
      errorSaving: 0,
      valid: true,

    };
  },
   computed: {
    ...mapGetters({
      getDeleteYardsLoading: "yards/getDeleteYardsLoading",
      get_alert_box_text: "yards/get_alert_box_text",
    }),
  },
  watch : {
    getDeleteYardsLoading(newValue) {
        if (newValue === false) {
          this.$emit("close")
        }
    }
  },
  methods : {
      ...mapActions('yards', [
          'deleteYardItem'
      ]),
      close () {
          this.$emit("close")
      },
      async deletenow () {
          await this.deleteYardItem(this.deleteYardItemList)
          this.$emit("syncAction",{created:3,msg: this.get_alert_box_text});
      },
  }
};
</script>

<style scoped>
.btn-color  {
    color : "3fff";
}
.delete-driver-dialog .v-card__text{
  padding-bottom: 0px !important;
}
.contentbody p {
  margin-bottom: 6px !important;
}

</style>
