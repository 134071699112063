  <template>
    <div>
        <div 
            class="assignUnassignFilter" 
            style="display: flex;margin-left:4px;"
        >
            <div @click="currenttab(0)" v-bind:class="{
            'active':
              pageCurrent.page == 0
          }"> Connected 
            <strong 
              v-if="isCountTabCount" 
              class="labelnumber">{{countTab.connected}}
              </strong>
            </div>
            <div @click="currenttab(1)" v-bind:class="{
            'active':
               pageCurrent.page == 1
          }"> Not Connected 
            <strong 
              v-if="isCountTabCount" 
              class="labelnumber">{{countTab.notconnected}}
            </strong>
            </div>
            <div @click="currenttab(2)" v-bind:class="{
            'active':
               pageCurrent.page == 2
          }"> Not Active 
            <strong 
              v-if="isCountTabCount" 
              class="labelnumber">{{countTab.notactive}}
              </strong>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "Connected",
  props: ["items","pageCurrent",'countTab'],
  components: {    
  },
  data: () => ({  
    isCountTab:0, 
    pagecur:9,
  }),
  computed: {
    isCountTabCount:{
      get(){ 
         return Object.keys(this.countTab).length       
      }
    }
  },
  created() {},
  methods: {
    currenttab(cur){
        this.pagecur = cur;
        this.$emit("currentPage",cur);
    }
  },
  async mounted() {    
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
<style>
.labelnumber {
  font-size:12px;
  height: 21px;
  width: 21px;
  display: inline-block;
  text-align: center;
  border-radius:80px;
  background:#0171A1;
  color:#fff
}
</style>