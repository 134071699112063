<template>
    <v-dialog persistent 
        v-model="dialogData" 
        max-width="725px" 
        content-class="add-customer-dialog manage-report" 
        v-resize="onResize" 
        :retain-focus="false"
        scrollable>

        <v-card class="add-supplier-card">             
            <v-card-title>
                <span class="headline">Manage Reports Schedule</span>
                
                <div class="toggleSwitch d-flex justify-end align-center">
                    <!-- <ToggleButton 
                        class="test" 
                        :value="toggleNewValue"
                        :width='65'
                        :height="30"
                        @change="getchange($event)"
                        switch-color="{checked: '#16B442',
                                        unchecked: 'linear-gradient(red, yellow)'}"
                        :labels="{checked: 'On', unchecked: 'Off'}"/> -->

                    <v-switch
                        v-model="toggleSwitchValue"
                        inset
                        :ripple="false"
                        hide-details="auto"
                        @change="getchange($event)">
                    </v-switch>

                    <button icon dark class="btn-close ml-2" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </div>
            </v-card-title>          
            <v-card-text class="cardwrapper" style="min-height: 170px;">
                <v-form 
                    ref="form" 
                    id="createCustomer" 
                    v-model="valid" 
                    action="#" 
                    @submit.prevent="">    
                    <!-- <v-row>
                        <v-col                            
                            cols="12"
                            sm="6"
                            md="10"
                            mb-0
                            pb-0
                            >
                            <span class="reportText">Send Automatic Reports</span>
                        </v-col>
                        <v-col class="toggleSwitch"
                                cols="6"
                                md="2"
                            >
                        <ToggleButton 
                            class="test" 
                            :value="toggleNewValue"
                            :width='65'
                            :height="30"
                            @change="getchange($event)"
                            switch-color="{checked: '#16B442',
                                        unchecked: 'linear-gradient(red, yellow)'}"
                            :labels="{checked: 'On', unchecked: 'Off'}"/>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col 
                            class="frequencycolumn  mb-0
                            pb-0"                          
                        
                            sm="4"
                            md="4" 
                            :cols="call_frequency"                           
                            >
                            <label class="lavel manageLabel">Frequency</label>
                            <v-autocomplete
                                v-model="initialfrequencymodel"
                                :items="dropdowndrayage"
                                color="blue"
                                item-text="name"
                                item-value="id"
                                :size="14"
                                class="dropdownstatus"
                                width="334px"
                                hide-details="auto"
                                append-icon="ic-chevron-down"
                                height="40px"
                                :disabled="!toggleSwitchValue">

                                <template slot="item" slot-scope="{ item }">
                                    <template v-if="typeof item == 'object'">
                                        <div 
                                            class="listDropdown selectedLisddown" 
                                            style="width:100%" 
                                            v-if="checkifselected(item)">
                                            <span style="font-size:14px;color:#4A4A4A;
                                            background:#F0FBFF !important">
                                                {{ item.name }}
                                            </span>											
                                        </div>
                                        <div style="font-size:14px;color:#4A4A4A" 
                                        class="listDropdown" v-else>
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>  
                        <v-col  
                            v-if="byday"
                            class="time  mb-0 pb-0"
                            sm="4"
                            :cols="call_class"                             
                            >
                            <label class="lavel manageLabel">Day</label>
                            <v-autocomplete
                                v-model="dayselected"
                                :items="daily"
                                :rules="dayRules"
                                color="blue"
                                item-text="name"
                                item-value="name"
                                placeholder="Pick a day"
                                font-size="14px"
                                class="dropdownstatus"
                                width="334px"
                                hide-details="auto"
                                append-icon="ic-chevron-down"
                                height="40px"
                                :disabled="!toggleSwitchValue">

                                <template slot="item" slot-scope="{ item }">
                                    <template v-if="typeof item == 'object'">
                                        <div 
                                            class="listDropdown" 
                                            style="width:100%" 
                                            v-if="checkifselected(item)">
                                            <span style="font-size:14px;color:#4A4A4A;
                                            background:#F0FBFF !important">
                                                {{ item.name }}
                                            </span>
                                            <img
                                                class="checkdropdown"
                                                style="width:14px;"
                                                src="@/assets/icons/arrow_down.svg"
                                            />
                                        </div>
                                        <div 
                                            style="font-size:14px;color:#4A4A4A;" 
                                            class="listDropdown" v-else>
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col> 

                        <v-col  
                            v-if="monthly"
                            class="time  mb-0 pb-0"
                            sm="4"
                            :cols="call_class"                             
                            >
                            <label class="lavel manageLabel">Day Monthly</label>
                            <v-autocomplete
                            v-model="monthlydayselected"
                            :items="selecteDay"
                            :rules="dayRules"
                            color="blue"
                            item-text="name"
                            item-value="name"
                            placeholder="Select the day"
                            font-size="14px"
                            class="dropdownstatus"
                            width="334px"
                            hide-details="auto"
                            append-icon="ic-chevron-down"
                            height="40px"
                            :disabled="!toggleSwitchValue">
                                <template slot="item" slot-scope="{ item }">
                                    <template v-if="typeof item == 'object'">
                                        <div 
                                            class="listDropdown" 
                                            style="width:100%" 
                                            v-if="checkifselected(item)">
                                            <span style="font-size:14px;color:#4A4A4A;
                                            background:#F0FBFF !important">
                                                {{ item.name }}
                                            </span>
                                            <img
                                                class="checkdropdown"
                                                style="width:14px;"
                                                src="@/assets/icons/arrow_down.svg"
                                            />
                                        </div>
                                        <div 
                                            style="font-size:14px;color:#4A4A4A" 
                                            class="listDropdown" v-else>
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col> 
                        <v-col  
                            class="time  mb-0
                            pb-0"                            
                            :cols="call_frequency"
                            sm="4"
                            md="4"                             
                            >
                            <label class="lavel manageLabel">Time</label>
                            <v-autocomplete
                                v-model="timeselected"
                                :items="countstep"
                                color="blue"
                                :rules="timeRules"
                                item-text="name"
                                item-value="name"
                                placeholder="hh:mm"
                                font-size="14px"
                                class="dropdownstatus timedropdown"
                                width="334px"
                                hide-details="auto"                               
                                height="40px"
                                :disabled="!toggleSwitchValue">

                                <template slot="item" slot-scope="{ item }">
                                    <template v-if="typeof item == 'object'">
                                        <div 
                                            class="listDropdown" 
                                            style="width:100%" 
                                            v-if="checkifselected(item)">
                                            <span style="font-size:14px;color:#4A4A4A;
                                            background:#F0FBFF !important">
                                                {{ item.name }}
                                            </span>
                                            <img
                                                class="checkdropdown"
                                                style="width:14px;"
                                                src="@/assets/icons/arrow_down.svg"
                                            />
                                        </div>
                                        <div 
                                            style="font-size:14px;color:#4A4A4A;" 
                                            class="listDropdown" v-else>
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>                    
                    </v-row>
                    <v-row class="sendcopy manage mt-0">
                        <v-col class="pt-0 pb-0 ">                            
                            <v-checkbox style="color:#4A4A4A;"
                                class="pt-0 pb-0 v-labelsendcopy shrink"
                                v-model="getsendcopy"
                                label="Send me the copy"
                                :disabled="!toggleSwitchValue"
                                hide-details="auto"
                                >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions style="border-top: 2px solid #EBF2F5 !important;">
                <div class="buttonActions d-flex justify-start align-center pr-0" style="width: 100%;">
                    <v-btn style="caret-color:transparent" class="btn-blue" text @click="save">
                        <span>
                            <span style="caret-color:transparent">
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >
                                    </v-progress-circular>
                                Save Settings
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                        Cancel
                    </v-btn> 
                </div>
            </v-card-actions>           
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
import globalMethods from '../../../../utils/globalMethods'
// import { ToggleButton } from 'vue-js-toggle-button'
const APIBaseUrl = process.env.VUE_APP_PO_URL
import jQuery from 'jquery'
export default {
    name: 'SupplierDialog',
    props: ['dialogData','toggleswitch'],
    components: {
        // ToggleButton
    },
    data(){
        return {
            showdialog: 0,
            getsendcopy:0,
            // toggleNewValue:0,
            toggleNewValue:false,
            monthly:false,
            switchFreq: 'md-6',
            byday: false,
            switchClass:"md-6",
            timedropdown:[],
            dayselected: '',
            monthlydayselected: '',
            daily: [{name:'Sunday'},
                    {name:'Monday'},
                    {name:'Tuesday'},
                    {name:'Wednesday'},
                    {name:'Thursday'},
                    {name:'Friday'},
                    {name:'Saturday'}],

            dropdowndrayage: [{id:1,name:'Daily'},
                            {id:2,name:'Weekly'},
                            {id:3,name:'Monthly'}],
            initialfrequencymodel: 1,
            timeselected:'',
            checkedToogle: false,
            checkbox:false,
            typeError:'', // type of error return from api
            myclass: '',
            isFetching:0,
            customerName:'',
            errorContent: '',
            company_key: '',
            errorSaving: 0,
            valid: true,
            telInputOptions: {
                showDialCodeInSelection: true,
                showDialCodeInList: true,
                showFlags: true,
            },
            options: [],
            value: [],
            isMobile: false,
            rules: [
                (v) => !!v || "Input is required."
            ],
            dayRules: [(v) => {                
                          if(v==''){
                            return 'Day is required.'
                          }

                          return true;
            }],
            
            timeRules:[(v) => !!v || "Time is required."],
            companyRules: [
                (v) => v.split(' ').filter(function(n) { return n != '' }).length <= 1 || "Company key should not contain any spaces!"
            ],
            emailRules:[
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            telProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Type phone number",
                label: 'Type phone number',
                autocomplete: "off",
                maxLen: 25,
                preferredCountries: ["US", "CN"],
                enabledCountryCode: true,
                dropdownOptions: {
                    showDialCodeInSelection: true,
                    showFlags: true
                },
                validCharactersOnly: true
            },
            numberRules: [           
                (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
            ],
            tagsValidation: [
                {
                classes: 't-new-tag-input-text-error',
                rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                disableAdd: true
                }
            ],
            tagsValidation3: [
                {
                classes: 'ttt-new-tag-input-text-error',
                rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                disableAdd: true
                }
            ],
            documentProto: document,
            tagsInput: {
                touched: false,
                hasError: false,
                errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
            },
            warehouseEmailAddress:'',
            arrayNotEmptyRules: [           
                () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
            ],
            customersEmail: '',
            toggleSwitchValue: false
        }
    },
    computed: {
        ...mapGetters({
            getCustomers: 'customers/getCustomers',
            getCustomersLoading: 'customers/getCustomerLoading',           
        }),
        call_class(){
            return this.switchClass;
        },
        call_frequency(){
            return this.switchFreq;
        },
        countstep: {
            get(){
            let i      = 0;                 
            let stringarray= [];
                for(i; i<25; i++){
                    let j      = 0;
                    let add    = 0;
                    for(j = 0; j < 4; j++ ){
                        if(j==0){
                            stringarray.push({name: i + ':' + '00' });
                        }else{
                            add+=15;
                            stringarray.push({name: i + ':' + add });
                        }                       
                    }
                    if(i==23){
                        break;
                    }
                }
                return stringarray;
            }
        },
        selecteDay:{            
            get(){
            let monthlyData= [];
            let i = 1;
            for(i;i < 31;i++){
                monthlyData.push({name: 'Day ' + i});
            }
            
            var c = monthlyData;

            if(monthlyData){
                return c;
            }
            return c;
        }
        },
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        } ,
         className(){
            return 'taginpuerrorfound';
        } ,
        optionsFiltered: {
            get() {
                let validEmailAddress = []
                if (this.editedItem.emails !== null && this.editedItem.emails.length > 0) {
                    this.editedItem.emails.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    })
                }
                return validEmailAddress
            },
            set(value) {
                this.options = value
            }
        },
    },
    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            createCustomers:'customers/createCustomers',
        }),       
        getchange(event){
            console.log(event);
            this.toggleNewValue= event.value
            this.checkedToogle = event.value
        },
         generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
        },
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        checkifselected(item) {
			if (item.id == this.initialfrequencymodel) {
				return true;
			}
			return false;
		},
        close() {
            this.$refs.form.resetValidation()
            this.tagsInput.touched = false
            this.options = [],
            this.customerName = ''
            this.company_key = ''
            this.warehouseEmailAddress = ''
            this.$emit('close', false)
            this.toggleSwitchValue = false
        },
        save() {            
            if(this.$refs.form.validate()){
            // var state = (this.toggleNewValue) ? true: false;
            var state = this.toggleSwitchValue
            var  object = {
                state:state,
                copy:this.getsendcopy,
                frequency:this.initialfrequencymodel,
                day:this.initialfrequencymodel == 3 ? this.monthlydayselected : this.dayselected,
                time:this.timeselected,
                type: 'details'   
            }
            this.isFetching = 1;
            axios.post(`${APIBaseUrl}/saveManageReportSettings/`+this.$route.params.id,object)
            .then((response) => {
                if(response){
                    this.$emit('update:tooggle',
                        {
                            state:state,
                            copy:this.getsendcopy,
                            frequency:this.initialfrequencymodel,
                            day:this.initialfrequencymodel == 3 ? this.monthlydayselected:this.dayselected,
                            time:this.timeselected,
                            type: 'details'   
                        }
                    );
                    this.$refs.form.resetValidation();
                    this.$emit('close', false)
                }
                 this.isFetching = 0;
            }); 
           }
        },
        setToDefault() {
            this.$emit('setToDefault')
        },
        async addCustomer(email){
            this.errorSaving = 0;
            var dis = this; 
            let formData = new FormData(document.getElementById('createCustomer'))
            formData.append('name', this.customerName)
            formData.append('central_customer_key', this.company_key)
            formData.append('email',email.join());
            formData.append('pnumber',this.editedItem.phone)
            this.isFetching = 1;
            await axios.post(`${APIBaseUrl}/createCustomer`,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                if(typeof error.type !=undefined) this.typeError = error.type
                if(typeof error.error !=undefined){
                    this.errorContent = error.msg
                }
            }).then(response => {
                dis.startloading =0;
                if(response){
                    dis.tagsInput.touched = 0;
                    dis.$refs['form'].resetValidation()
                    dis.options = [];
                    dis.customerName = '';
                    dis.company_key = ''
                    dis.$emit('update:notifysavedparent',1);
                    dis.close()
                    dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Customer has been added.</span>')
                }else {
                    if(dis.errorContent !=''){
                        dis.errorSaving = 1;
                    }
                }
                this.isFetching = 0;
            })
        },
        checkvalidEmailblur(mail){
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return mail.match(validRegex)
          }
    },

    updated() {
        if(this.dialog == 0){
            this.errorSaving = 0;
        }

        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    },
    watch:{
        initialfrequencymodel(c){                     
            if(c==1){
                this.switchClass= 'md-6';
                this.switchFreq = this.switchClass ;
                this.byday      = false;
                this.monthly    = false;
            }
            if(c==2 || c== 3){
                this.switchClass = 'md-4'
                this.switchFreq  =  this.switchClass
                if(c==2){
                    this.byday   = true;
                    this.monthly = false;
                }else{
                    this.byday   = false;
                    this.monthly = true;
                }
            }
        },
        dialogData(c){
            if(c){
                
                this.showdialog = true;       
                let getdatavalue = (this.toggleswitch.state);
                this.toggleNewValue = getdatavalue;
                this.toggleSwitchValue = getdatavalue;
                this.getsendcopy = this.toggleswitch.copy ? true : false;
                if (typeof this.toggleswitch.frequency === 'string' || 
                this.toggleswitch.frequency instanceof String)
                {
                    if(this.toggleswitch.frequency=='Daily'){
                        this.toggleswitch.frequency = 1;
                    }else if(this.toggleswitch.frequency =='Weekly'){
                        this.toggleswitch.frequency = 2
                    }else{
                        this.toggleswitch.frequency = 3
                    }
                }

                if(this.toggleswitch.state){
                    this.initialfrequencymodel = this.toggleswitch.frequency;                     
                    if(this.toggleswitch.frequency == 2){
                        this.dayselected = this.toggleswitch.day
                    } else if(this.toggleswitch.frequency == 3){
                        this.monthlydayselected = this.toggleswitch.day
                    } 
                    this.timeselected = (this.toggleswitch.time !=null) ? this.toggleswitch.time : '';                             
                }                  
            }
        }
    },
    mounted(){
       
    }
}
</script>

<style lang="scss">
 @import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
 @import '../../../../assets/scss/pages_scss/customers/customerAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.v-dialog {
    &.manage-report {
        .v-card {
            .v-card__text {
                .v-input.v-input--is-disabled {
                    background-color: transparent !important;

                    .v-input__control {
                        .v-input__slot {
                            background-color: #EBF2F5;
                            border: 1px solid #EBF2F5 !important;
                        }
                    }

                    &.v-input--checkbox {
                        .v-input__slot {
                            background-color: transparent !important;
                            border: none !important;

                            .v-input--selection-controls__input {
                                opacity: 0.4;
                            }

                            label {
                                color: #B4CFE0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style>
.v-autocomplete__content .v-list-item--active {
    color: #F0FBFF !important;
    background: #F0FBFF !important;
}
.lavel {
    color:#819FB2;
}
.dropdownstatus {
    font-size:14px !important;
    padding-top:0px;
}
.text-field{
    color: red !important;
    /* add whatever properties you want */
}
.v-right {
    color:grey !important;
    font-size: 14px;
}
.v-left {  
    font-size: 14px  !important;;
}
.toggleSwitch .v-switch-button {
    background-color: #819FB2 !important;
}
.toggleSwitch .v-switch-core {
    background-color: #F0FBFF !important;
    border: 1px solid #EBF2F5;
}

.toggleSwitch .toggled .v-switch-button {
    background-color: #fff !important;
}

.toggleSwitch .toggled .v-switch-core {
    background-color: #16B442 !important;
    border: none !important;
}

.v-dialog.manage-report .v-card .v-card__title .toggleSwitch .v-switch-label {
    font-family: 'Inter-Medium', sans-serif !important;
    font-weight: unset !important;
}

.v-dialog.manage-report .v-card .v-card__title .toggleSwitch .v-switch-label.v-right {
    color: #819FB2 !important;
}

.reportText {
    font-style: normal;font-weight: 600;font-size: 16px;
}

.v-input--is-label-active,.v-select--is-menu-active {
    background: none !important;
}

.frequencycolumn  .v-input .v-input__control .v-input__slot {
  border: 1px solid #B4CFE0 !important;
  padding: 3px !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
}

.frequencycolumn  .v-input .v-input__control .v-input__slot::before {
  width: 0px;
}

.time  .v-input .v-input__control .v-input__slot::before {
  width: 0px;
}
.time  .v-input .v-input__control .v-input__slot {
  border: 1px solid #B4CFE0 !important;
  padding: 3px !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
}

.frequencycolumn  .v-input__append-inner {
    padding-right: 3px;
    padding-top: 3px;
}

.time  .v-input__append-inner {
    padding-right: 3px;
 
 padding-top: 3px;
}

.sendcopy.manage .v-input {
    width: max-content;
}

.sendcopy .v-input__control .v-input__slot {
  min-height: 40px !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.sendcopy .v-input__control .v-input__slot label {
    font-size: 14px !important;
}

.cardwrapper {
    padding-bottom:15px !important;
}
.v-label {
    color:#4A4A4A !important
}
.manageLabel {
    color: #819fb2;
    font-size: 10px;
    text-transform: uppercase;
    font-family: "Inter-SemiBold", sans-serif;
    margin-bottom: 6px;
}
</style>
<style lang="scss">
.timedropdown {
	.v-input__control {
		.v-input__slot {
			.v-select__slot {
                 input {
                    color:#4A4A4A !important;
                 }
				.v-input__append-inner {
					.v-input__icon--append i {
						background: url("../../../../assets/icons/clock.svg")
							no-repeat !important;
						font-size: 26px !important;
						height: 19px;
						width: 19px;
						display: block;
						position: relative;
						top: 1px;
						left: -1px;
                        &::before{
                            font-size:18px;
                            content:'',
                        }
					}
				}
			}
		}
	}
}
.dropdownstatus {
	.v-input__control {
		.v-input__slot {
			.v-select__slot {
                 input {
                    color:#4A4A4A !important;
                 }
				.v-input__append-inner {
					.v-input__icon--append i {
                        &::before{
                            font-size:18px
                        }
					}
				}
			}
		}
	}
}

.toggleSwitch {
    .v-input {
        // v-toggle-switch UI from vue
        &.v-input--switch--inset {
            margin-top: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 2px !important;

            .v-input--selection-controls__input {
                height: 26px;
                width: 70px;
                margin-right: 0;
            }

            .v-input--switch__track {
                width: 65px !important;
                height: 30px !important;
                color: #F0FBFF !important;
                border: 1px solid #EBF2F5 !important;
                opacity: 1 !important;

                &:after {
                    content: "Off";
                    font-size: 14px;
                    position: absolute;
                    right: 12px;
                    top: -2px;
                    color: #819FB2;
                    font-family: 'Inter-Medium', sans-serif !important;
                }
            }

            .v-input--switch__thumb {
                background-color: #819fb2;
                top: calc(50% - 9px);
            }

            &.v-input--is-label-active.v-input--is-dirty {
                .v-input--switch__track {
                    color: #16B442 !important;

                    &:after {
                        content: "On";
                        color: #fff;
                        position: absolute;
                        left: 12px;
                    }
                }

                .v-input--switch__thumb {
                    background-color: #fff !important;
                    transform: translate(36px, 0) !important;
                }
            }
        }
    }
}

</style>

