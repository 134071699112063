<template>
	<div class="containers-wrappers" @click="detectDateField($event)" v-resize="onResize" style="height: 100%;">
		<v-toolbar flat color="transparent" class="toolbar-header-content">
			<v-toolbar-title>Containers</v-toolbar-title>			

			<div class="select-view-tabs-wrapper">
				<v-tabs class="select-view-tabs" v-model="activeTab" @change="setSelectedView">
					<v-tab v-for="(n, i) in tabs" :key="i" >
						<span> {{ n.name }} </span>
					</v-tab>
				</v-tabs>
			</div>

			<v-spacer></v-spacer>

			<v-btn @click="createDo" color="primary" class="btn-blue manage-payment-methods-button">
				<span class="btn-name-manage" style="font-size: 14px;">
					{{ titlePage }}
				</span>
			</v-btn>
		</v-toolbar>
		
		<div v-if="activeTab === 0 || activeTab === '0'">
			<ContainersDesktop
				:items="isChangeitem ? theitemdata : containers"
				:tabitemdata="gettabcounter"
				:newtabitem="newtabitem"
				:contentData="contentDatatable"
				:allcomopents="allComponents"
				:customers="customers"
				:terminals="terminals"
				:magdriver="magdriver"
				:magdropterminal="terminalDropFilter"
				:shipmentsize="size"
				:mgaFiles="mgaFiles"
				:isMobile="isMobile"
				:responseObjectMeta="containerDetails"
				:outsideClick="isClicked"
				@changeitem="changeitem"
				@retrieveContainerDetails="retrieveContainerDetails"
				@reinitializeTable="reinitializeTable"
				v-if="maincontrol"
			/>
		</div>

		<div style="height: calc(100vh - 172px);" v-if="activeTab === 1 || activeTab === '1'">
			<ContainersDesktopCompact
				:items="isChangeitem ? theitemdata : containers"
				:tabitemdata="gettabcounter"
				:newtabitem="newtabitem"
				:contentData="contentDatatable"
				:allcomopents="allComponents"
				:customers="customers"
				:terminals="terminals"
				:magdriver="magdriver"
				:magdropterminal="terminalDropFilter"
				:shipmentsize="size"
				:mgaFiles="mgaFiles"
				:isMobile="isMobile"
				:responseObjectMeta="containerDetails"
				@changeitem="changeitem"
				@retrieveContainerDetails="retrieveContainerDetails"
				@reinitializeTable="reinitializeTable"
				v-if="maincontrol"
			/>
		</div>

		<CreateDOFormDialog
			:openDialogCreate.sync="createFormDialog"
			@closeDialog="closeDialog('createFormDialog')"
			:pageClass="'containers-page-do'"
		/>
	</div>
</template>

<script>
import ContainersDesktop from "../components/Tables/Containers/ContainersDesktopTable.vue";
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../utils/globalMethods";
import ContainersDesktopCompact from '../components/Tables/Containers/CompactView/Tables/ContainersDesktopTable.vue';
import CreateDOFormDialog from '../components/Tables/DeliveryOrder/dialog/CreateDOFormDialog.vue';
import jQuery from 'jquery';
export default {
	name: "Containers",
	components: {
		ContainersDesktop,
		ContainersDesktopCompact,
		CreateDOFormDialog
	},
	data: () => ({
		ALLDATA: [],
		//contentDatatable:[],
		maincontrol: 1,
		renderComponent: 0,
		theitemdata: [],
		isChangeitem: false,
		isMobile: false,
		dialog: false,
		dialogView: false,
		editedIndex: -1,
		editedItem: {
			invoice_no: "",
			invoice_date: "",
			shipment_reference: "",
			due_date: "",
			amuont: "",
		},
		defaultItem: {
			invoice_no: "",
			invoice_date: "",
			shipment_reference: "",
			due_date: "",
			amuont: "",
		},
		tabDataitem: [
			{
				name: "All",
				count: 0,
			},
			{
				name: "Assigned",
				count: 0,
			},
			{
				name: "At Port",
				count: 0,
			},
			{
				name: "At Yard",
				count: 0,
			},
			{
				name: "At the Customer",
				count: 0,
			},
			// Renamed to At the Customer tab
			// {
			// 	name: "Dropped full",
			// 	count: 0
			// },
			// {
			//   name: "Dropped empty",
			//   count: 0,
			// },
			{
				name: "Pending billing",
				count: 0,
			},
			{
				name: "Completed",
				count: 0,
			},
			{
				name: "Cancelled",
				count: 0,
			},
		],
		items: [
			{
				invoice_no: "1234567890",
				invoice_date: "Mar 13, 2021",
				shipment_reference: "#76KS091",
				due_date: "Mar 21, 2021",
				amount: "$5,689.00",
				paid: false,
				date_paid: null,
				status: "All Invoices",
				billing_status: ["All Invoices", "Unpaid"],
			},
			{
				invoice_no: "1234567891",
				invoice_date: "Mar 15, 2021",
				shipment_reference: "#76KS091",
				due_date: "Mar 22, 2021",
				amount: "$6,689.00",
				paid: false,
				date_paid: null,
				status: "All Invoices",
				billing_status: ["All Invoices", "Unpaid"],
			},
			{
				invoice_no: "1234567892",
				invoice_date: "Mar 16, 2021",
				shipment_reference: "#76KS091",
				due_date: "Mar 24, 2021",
				amount: "$7,020.00",
				paid: false,
				date_paid: null,
				status: "All Invoices",
				billing_status: ["All Invoices", "Unpaid"],
			},
			{
				invoice_no: "12345678913",
				invoice_date: "Apr 13, 2021",
				shipment_reference: "#76KS091",
				due_date: "Apr 21, 2021",
				amount: "$8,689.00",
				paid: false,
				date_paid: null,
				status: "Unpaid",
				billing_status: ["All Invoices", "Unpaid"],
			},
			{
				invoice_no: "1234567894",
				invoice_date: "May 13, 2021",
				shipment_reference: "#76KS091",
				due_date: "May 21, 2021",
				amount: "$4,000.00",
				paid: false,
				date_paid: null,
				status: "Unpaid",
				billing_status: ["All Invoices", "Unpaid"],
			},
			{
				invoice_no: "1234567895",
				invoice_date: "Jun 13, 2021",
				shipment_reference: "#76KS091",
				due_date: "Jun 21, 2021",
				amount: "$5,689.00",
				paid: true,
				date_paid: "Jun 6, 2021",
				status: "Paid",
				billing_status: ["All Invoices", "Paid"],
			},
		],
		containerDetails: [],
		// 
		titlePage: "+ Create Delivery Order",
		activeTab: localStorage.getItem('containersView'),
		tabs: [
            {
                value: 'default',
                // name: 'Default View'
				name: 'Comfortable View'
            },
            {
                value: 'compact',
                name: 'Compact View'
            }
        ],
		createFormDialog: false,
		isClicked: ""
	}),
	methods: {
		...mapActions({
			fetallContainersstatus: "containers/allcontainerstatus",
			fetchDrivers: "common/fetchDrivers",
			fetchChassis: "common/fetchChassis",
			fetchTerminals: "common/fetchTerminals",
			fetchCarriers: 'containers/fetchCarriers',
			fetchCustomers: 'customers/fetchCustomers',
		}),
		...globalMethods,
		onResize() {
			if (window.innerWidth < 769) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},

		changeitem(value) {
			this.isChangeitem = true;
			this.theitemdata = value;
		},

		async reinitializeTable() {
			await this.fetallContainersstatus();
		},
		retrieveContainerDetails(item) {
			this.containerDetails[0] = item;
		},
		// 
		createDo() {
			// this.$router.push("/delivery-order").catch(()=>{});
			this.createFormDialog = true
		},
		setSelectedView(i) {			
            this.activeTab = i

			let currentView = i === 0 ? 'default' : 'compact'
            localStorage.setItem('containersView', currentView)
        },
		closeDialog(data) {
			this[data] = false
		},
		detectDateField(elem) {
			let el = jQuery('.vc-popover-content.direction-bottom.vc-container').is(':visible')
			let el2 = jQuery('.vc-date-picker__wrapper').is(':visible')

			if((el || el2) && elem.screenY <= 398 ||
				elem.target._prevClass === 'v-data-table__wrapper' ||
				elem.target._prevClass === 'pagination-wrapper pt-3' ||
				elem.target._prevClass === 'containers-wrappers'
			) {
				this.isClicked = Math.random()
			}
		}
	},
	computed: {
		...mapGetters({
			getAllcontainer: "containers/getAllContainers",
			getPoLoading: "containers/getPoLoading",
			gettabcounter: "containers/gettabcounter",
			getAllcontainerStatus: "containers/getAllcontainersStatus",
		}),
		containers() {
			var data = [];
			if (
				typeof this.getAllcontainer !== "undefined" &&
				this.getAllcontainer !== null
			) {
				if (
					typeof this.getAllcontainer.alldata !== "undefined" &&
					this.getAllcontainer.alldata.length !== "undefined"
				) {
					data = this.getAllcontainer.alldata;
				}
			}
			return data;
		},
		contentDatatable() {
			var s = [];

			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.data !== "undefined" &&
					this.getAllcontainerStatus.data.length !== "undefined"
				) {
					s = Object.values(this.getAllcontainerStatus.data);
				}
			}
			return s;
		},
		mgaFiles() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data = this.getAllcontainerStatus.components.files;
				}
			}
			return data;
		},
		customers() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data = this.getAllcontainerStatus.components.customers;
				}
			}
			return data;
		},
		terminals() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data = this.getAllcontainerStatus.components.terminal;
				}
			}
			return data;
		},
		size() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data = this.getAllcontainerStatus.components.size;
				}
			}
			return data;
		},
		magdriver() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data = this.getAllcontainerStatus.components.drivers;
				}
			}
			//console.log(data)
			return data;
		},
		terminalDropFilter() {
			var data = [];
			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					data =
						this.getAllcontainerStatus.components.loccationfilter;
				}
			}
			return data;
		},
		allComponents() {
			var s = [];

			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.components !==
						"undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined"
				) {
					s = Object.values(this.getAllcontainerStatus.components);
				}
			}
			return s;
		},
		newtabitem() {
			var s = this.tabDataitem;

			if (
				typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null
			) {
				if (
					typeof this.getAllcontainerStatus.data !== "undefined" &&
					this.getAllcontainerStatus.data.length !== "undefined"
				) {
					var data3 = Object.values(this.getAllcontainerStatus.data);

					if (data3) {
						this.tabDataitem.map((element) => {
							element.sub_tab = [];
							if (element.name !== "") {
								let thename = "";
								if (element.name == "All") {
									thename = "all_tab";
								} else if (element.name == "At Port") {
									thename = "Pending,available";
									element.sub_tab = [
										{
											label: "Pending",
											name: "Pending",
											count: 0,
										},
										{
											label: "Available",
											name: "available",
											count: 0,
										},
									];
								} else if (element.name == "Yard full") {
									thename = "in_yard_full";
								} else if (element.name == "At Yard") {
									thename = "at_yard";
								} else if (element.name == "Available") {
									thename = "available";
								} else if (element.name == "Assigned") {
									thename = "assigned";
								} else if (element.name == "Scheduled") {
									thename = "assigned";									
									
									if (this.activeTab === 1) {
										element.sub_tab = [
											{
												label: "All",
												name: "All",
												count: 0,
											},
											{
												label: "Assign",
												name: "Assign",
												count: 0,
											},
											{
												label: "Unassigned",
												name: "Unassigned",
												count: 0,
											},
										];
									} else {
										element.sub_tab = []
									}
								} else if (element.name == "At the Customer") {
									thename = "at_the_customer";
								} else if (element.name == "Completed") {
									thename = "completed";
								} else if (element.name == "Cancelled") {
									thename = "cancelled";
								} else {
									var c = element.name.toLowerCase();
									thename =
										c.charAt(0).toUpperCase() + c.slice(1);
								}
								const result = data3.filter((find) =>
									thename.split(",").includes(find.name)
								);

								element.count = 0;
								Object.values(result).forEach(function (f) {
									if (
										["at_the_customer", "at_yard"].includes(
											f.name
										)
									) {
										element.sub_tab = [
											{
												label: "Full",
												name: "full",
												count: f.full.count,
											},
											{
												label: "Empty",
												name: "empty",
												count: f.empty.count,
											},
										];
									} else
										element.sub_tab.forEach(function (
											s_tab
										) {
											if (
												s_tab.name.toLowerCase() ==
												f.name.toLowerCase()
											) {
												s_tab.count = parseInt(f.count);
											}
										});
									element.count += parseInt(f.count);
								});

								if (element.name == "Assigned") {
									element.name = "Scheduled";
								}
							}
						});
						s = this.tabDataitem;
					}
				}
			}
			return s;
		},
	},
	watch: {
		//'$route': 'reInitialize'
		// async activeTab() {
		// 	await this.fetallContainersstatus();
		// }
	},
	async mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "containers");

		let currentView = localStorage.getItem('containersView')
		this.activeTab = currentView === 'default' ? 0 : 1

		await this.fetallContainersstatus();
		this.fetchDrivers();
		this.fetchChassis();
		this.fetchTerminals();
		this.fetchCarriers();
		this.fetchCustomers()
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/containers/containers.scss";
@import "../assets/scss/buttons.scss";

.containers-wrappers {
	.v-toolbar {
		&.toolbar-header-content {
			.v-toolbar__content {
				padding: 0;

				.v-toolbar__title {
					font-family: 'Inter-SemiBold', sans-serif;
					color: #4a4a4a;
				}
			}
		}
	}

	.select-view-tabs-wrapper {
		margin-left: 16px;
		height: 36px;

		.select-view-tabs {
			.v-tabs-bar {
				height: 36px;
			}

			.v-tabs-slider-wrapper {
				display: none;
			}

			.v-tab {
				font-size: 12px !important;
				text-transform: capitalize!important;
				letter-spacing: 0 !important;
				border: 1px solid #b4cfe0;
				padding: 0 12px;
				font-weight: unset !important;
				
				span {
					color: #4a4a4a;
					font-family: 'Inter-Medium', sans-serif !important;
				}

				&.v-tab--active {
					background-color: #EBF2F5;

					span {
						color: #0171A1;					
					}
				}

				&:nth-child(2) {
					border-right: none !important;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}

				&:last-child {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}
		}
	}
}
</style>
