<template>
    <v-layout row justify-center>
        <v-dialog 
            v-model="open"
            persistent
            max-width="375"
            content-class="eta-date-picker-dialog">

            <v-card class="card_dialog_delete">
                <v-card-text class="pt-5">
                    <div class="flex flex-row justify-md-space-between align-baseline">
                        <span class="c-header">{{ label }}</span>
                        <v-btn class="btn-white btn-main" text @click="reset">
                            Reset to Default
                        </v-btn>
                    </div>
                </v-card-text>                 
                <v-card-text class="flex justify-center pb-0 mb-2">
                    <span class="text-md-body-1 shifl-color">
                        {{ displayDate }}
                    </span>
                </v-card-text>
                
                <div class="flex justify-center vc-date-picker__wrapper-range">
                    <vc-date-picker 
                        title-position="left"
                        v-model="range" 
                        is-range 
                        is-expanded 
                        :select-attribute="attribute"
                        :drag-attribute="attribute"
                        mode="range"
                        :locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WWW', navMonths: 'MMMM' } }" />
                        <!-- :columns="2" -->
                </div>

                <div class="flex px-5 pb-4 pt-1 mw-54">                  
                    <v-btn class="btn-blue w-50 mr-2 btn-main" text @click="confirm">
                        Confirm
                    </v-btn>
                    <v-btn class="btn-white w-50 btn-main" text @click="close">
                        Cancel
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
import moment from 'moment';

export default {
    name: "DatePickerETARange",
    props: ['open', 'multiple', 'label', 'defaultDate'],
    data: () => ({
        isMobile: false,
        date: null,
        range: {
            start: "",
            end: ""
        },
        attribute: {
            highlight: {
                start: {
                    style: {
                        backgroundColor: '#0171A1', // blue
                        borderRadius: '4px 0 0 4px'
                    },
                    contentStyle: {
                        color: '#ffffff', // color of the text
                        borderRadius: '4px'
                    }
                },
                base: {
                    style: {
                        backgroundColor: '#F0FBFF', // light blue
                    }
                },
                end: {
                    style: {
                        backgroundColor: '#0171A1', // blue
                        borderRadius: '0 4px 4px 0'
                    },
                    contentStyle: {
                        color: '#ffffff', // color of the text
                        borderRadius: '4px'
                    }
                },
            },
        },
    }),
    mounted() {},
    updated() {},
    watch: {},
    computed:{
        getEndDate() {
            var endDate = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1, 10);
            return endDate.toISOString().slice(0,10)
        },
        displayDate() {
            let display = ""

            if (this.range !== null && this.range !== '') {
                if (this.range.start !== "" && this.range.end !== "") {
                    display = moment(this.range.start).format("MMM DD, YYYY") + ' - ' + moment(this.range.end).format("MMM DD, YYYY")
                } else {
                    display = "Pick a date from the calendar below"
                }
            } else {
                display = "Pick a date from the calendar below"
            }

            return display
        }
    },
    methods: {
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            if (this.defaultDate !== null) {
                if (this.defaultDate.length === 0) {
                    this.reset()
                    this.$emit('close')
                } else {
                    let currentDate = this.defaultDate.sort((d1, d2) => (moment(d1).format("YYYY-MM-DD") - moment(d2).format("YYYY-MM-DD")))

                    if (this.range !== null && this.range !== '') {
                        let { start, end } = this.range

                        let date = [
                            moment(start).format("YYYY-MM-DD"),
                            moment(end).format("YYYY-MM-DD")
                        ]

                        if (currentDate[0] === date[0] && currentDate[1] === date[1]) {
                            this.$emit('close')
                        } else {
                            this.$emit('close')
                            this.range = {
                                start: currentDate[0],
                                end: currentDate[1]
                            }
                        }
                    } else {
                        this.$emit('close')
                        this.range = {
                            start: currentDate[0],
                            end: currentDate[1]
                        }
                    }
                }
            }
        },
        confirm() {
            if (this.range !== null && this.range !== '') {
                let { start, end } = this.range

                let date = [
                    moment(start).format("YYYY-MM-DD"),
                    moment(end).format("YYYY-MM-DD")
                ]

                this.$emit('confirm', date)
            } else {
                this.$emit('confirm', "")
            }
            
        },
        reset() {
            this.range = {
                start: "",
                end: ""
            }
        },
    },
};
</script>
<style lang="scss">
@import "../../../../assets/scss/vc_calendar.scss";

.eta-date-picker-dialog {
    .shifl-color {
        color: #819FB2 !important;
        font-family: 'Inter-Regular', sans-serif !important;
        letter-spacing: 0 !important;
    }
    .gen-color {
        color: #0171A1 !important;
    }
    button.v-btn.v-date-picker-table__current {
        border-radius: 5px !important;
        font-weight: 600;
        color: #0171A1 !important;
        border: none !important;
    }
    button.v-btn.v-btn--active {
        border-radius: 4px !important;
        font-weight: 400;
        background-color: #0171A1 !important;
        /* border: 1px solid #0171A1 !important; */
        color: white !important;
        font-size: 16px !important;

        &::before {
            opacity: .1;
        }
    }
    th.accent.theme--light {
        background-color: #0171A1 !important;
    }
    th.accent--text.theme--light {
        color: #0171A1 !important;
    }
    .w-50 {
        width: 100%;
    }
    .mw-54 {
        max-width: 54%;
    }
    .fs-16 {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    .bg-white {
        background-color: white;
    }
    td, button.v-btn.v-btn--text {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    td, button.v-btn.v-date-picker-table__current {
        font-size: 16px !important;
        letter-spacing: 0;
    }
    button.theme--light.v-btn.v-btn--flat {
        color: #4a4a4a !important;
    }
    .c-header {
        color: #4A4A4A;
        font-size: 18px;
        font-weight: 600;
    }
    button.theme--light {
        color: #4a4a4a !important;
    }

    .v-picker {
        .v-picker__body {
            width: 350px !important;

            .v-date-picker-table {
                height: 220px !important;
            }
        }
    }

    button.v-btn.v-btn--text.btn-main {
        font-size: 14px !important;
    }
}    
</style>
