<template>
    <v-dialog
        persistent
        v-model="dialog"
        max-width="480px"
        content-class="delete-driver-dialog dialogYard"
        :retain-focus="false"
        >
        <v-card
          style="padding-bottom:8px">
          <v-card-text
            class="pt-5 pb-0 contentbody"
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >
            <svg width="44" height="44" fill="#6D858F" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 331.708 331.708" xml:space="preserve">
              <g>
                <path d="M222.685,284.287H47.419V109.02h102.502l47.421-47.419H5c-2.761,0-5,2.239-5,5v260.105c0,2.761,2.239,5,5,5h260.104
                  c2.761,0,5-2.239,5-5v-192.34l-47.419,47.418V284.287z"/>
                <path d="M330.244,1.467c-1.149-1.149-2.783-1.671-4.387-1.391L194.44,22.786c-1.84,0.318-3.35,1.635-3.915,3.415
                  c-0.564,1.78-0.09,3.727,1.23,5.047l30.971,30.971l-79.517,79.516c-1.953,1.953-1.953,5.119,0,7.071l39.688,39.689
                  c0.938,0.938,2.21,1.464,3.536,1.464c1.326,0,2.598-0.527,3.536-1.464l79.517-79.516l30.97,30.971
                  c1.32,1.32,3.268,1.794,5.047,1.23c1.78-0.564,3.097-2.074,3.414-3.915L331.634,5.854C331.912,4.253,331.393,2.617,330.244,1.467z"
                  />
              </g>
            </svg>

            <h2
              class="delivery_header"
              style="">Revert Cancellation</h2>

            <p
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                Are you sure to uncancel DO #{{items.shifl_ref}}?
            </p>

          </v-card-text>
          <v-card-actions
            style="border-top:0px;"
            >
            <v-btn
                @click="continueDialog"
                class="btn-blue"
                >
                <span>
                    <span>
                       Uncancel
                    </span>
                </span>
            </v-btn>
             <v-btn
                @click="clickback"
                class="btn-white"
                >
                <span>
                    <span>
                       Cancel
                    </span>
                </span>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'uncanceldo',
    data(){
      return {
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData','movetype','items'],
    computed: {
        dialog: {
            get () {
              return this.dialogData
            } ,
            set(value) {
              if (!value) {
                this.$emit("update:dialogData", false);
              } else {
                this.$emit("update:dialogData", true);
              }
            }
        },
    },
    methods: {
        continueDialog() {
          console.log(this.items)
           this.$emit("confirmUncancel",{item: this.items, result: 1});
        },
        clickback(){
          this.$emit("close",1);
        }
    },
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>
