<template>
  <div class="containers-wrappers" v-resize="onResize">
    <ContainersDesktop
      :items="isChangeitem ? theitemdata : containers"
      :tabitemdata="gettabcounter"
      :newtabitem="newtabitem"
      :contentData="contentDatatable"
      :allcomopents="allComponents"
      :customers="customers"
      :terminals="terminals"
      :magdriver="magdriver"
      :magdropterminal="terminalDropFilter"
      :shipmentsize="size"
      :mgaFiles="mgaFiles"
      :isMobile="isMobile"
      :responseObjectMeta="containerDetails"
      @changeitem="changeitem"
      @retrieveContainerDetails="retrieveContainerDetails"
      @reinitializeTable="reinitializeTable"
      v-if="maincontrol"
    />
  </div>
</template>

<script>
import ContainersDesktop from "../components/Tables/Containers/CustomerVisibilityContainersDesktopTable.vue";
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../utils/globalMethods";
const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  name: "customerVisibility",
  components: {
    ContainersDesktop,
  },
  data: () => ({
    ALLDATA: [],
    //contentDatatable:[],
    maincontrol: 1,
    renderComponent: 0,
    theitemdata: [],
    isChangeitem: false,
    isMobile: false,
    dialog: false,
    dialogView: false,
    editedIndex: -1,
    editedItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
    defaultItem: {
      invoice_no: "",
      invoice_date: "",
      shipment_reference: "",
      due_date: "",
      amuont: "",
    },
    tabDataitem: [
      {
        name: "All",
        count: 0,
      },
      {
        name: "Assigned",
        count: 0,
      },
      {
        name: "At Port",
        count: 0,
      },
      {
        name: "At Yard",
        count: 0,
      },
      {
        name: "At the Customer",
        count: 0,
      },
      // Renamed to At the Customer tab
      // {
      // 	name: "Dropped full",
      // 	count: 0
      // },
      // {
      //   name: "Dropped empty",
      //   count: 0,
      // },
      {
        name: "Pending billing",
        count: 0,
      },
      {
        name: "Completed",
        count: 0,
      },
      {
        name: "Cancelled",
        count: 0,
      },
    ],
    items: [
      {
        invoice_no: "1234567890",
        invoice_date: "Mar 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 21, 2021",
        amount: "$5,689.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567891",
        invoice_date: "Mar 15, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 22, 2021",
        amount: "$6,689.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567892",
        invoice_date: "Mar 16, 2021",
        shipment_reference: "#76KS091",
        due_date: "Mar 24, 2021",
        amount: "$7,020.00",
        paid: false,
        date_paid: null,
        status: "All Invoices",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "12345678913",
        invoice_date: "Apr 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Apr 21, 2021",
        amount: "$8,689.00",
        paid: false,
        date_paid: null,
        status: "Unpaid",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567894",
        invoice_date: "May 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "May 21, 2021",
        amount: "$4,000.00",
        paid: false,
        date_paid: null,
        status: "Unpaid",
        billing_status: ["All Invoices", "Unpaid"],
      },
      {
        invoice_no: "1234567895",
        invoice_date: "Jun 13, 2021",
        shipment_reference: "#76KS091",
        due_date: "Jun 21, 2021",
        amount: "$5,689.00",
        paid: true,
        date_paid: "Jun 6, 2021",
        status: "Paid",
        billing_status: ["All Invoices", "Paid"],
      },
    ],
    containerDetails: [],
  }),
  methods: {
    ...mapActions({
      fetallContainersstatus: "containers/allcontainerstatus",
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    changeitem(value) {
      this.isChangeitem = true;
      this.theitemdata = value;
    },

    async reinitializeTable() {
      // await this.fetallContainersstatus();
    },
    retrieveContainerDetails(item) {
      this.containerDetails[0] = item;
    },
  },
  computed: {
    ...mapGetters({
      getAllcontainer: "containers/getAllContainers",
      getPoLoading: "containers/getPoLoading",
      gettabcounter: "containers/gettabcounter",
      getAllcontainerStatus: "containers/getAllcontainersStatus",
    }),
    containers() {
      var data = [];
      if (
        typeof this.getAllcontainer !== "undefined" &&
        this.getAllcontainer !== null
      ) {
        if (
          typeof this.getAllcontainer.alldata !== "undefined" &&
          this.getAllcontainer.alldata.length !== "undefined"
        ) {
          data = this.getAllcontainer.alldata;
        }
      }
      return data;
    },
    contentDatatable() {
      var s = [];

      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.data !== "undefined" &&
          this.getAllcontainerStatus.data.length !== "undefined"
        ) {
          s = Object.values(this.getAllcontainerStatus.data);
        }
      }
      return s;
    },
    mgaFiles() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.files;
        }
      }
      return data;
    },
    customers() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.customers;
        }
      }
      return data;
    },
    terminals() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.terminal;
        }
      }
      return data;
    },
    size() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.size;
        }
      }
      return data;
    },
    magdriver() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.drivers;
        }
      }
      //console.log(data)
      return data;
    },
    terminalDropFilter() {
      var data = [];
      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          data = this.getAllcontainerStatus.components.loccationfilter;
        }
      }
      return data;
    },
    allComponents() {
      var s = [];

      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.components !== "undefined" &&
          this.getAllcontainerStatus.components.length !== "undefined"
        ) {
          s = Object.values(this.getAllcontainerStatus.components);
        }
      }
      return s;
    },
    newtabitem() {
      var s = this.tabDataitem;

      if (
        typeof this.getAllcontainerStatus !== "undefined" &&
        this.getAllcontainerStatus !== null
      ) {
        if (
          typeof this.getAllcontainerStatus.data !== "undefined" &&
          this.getAllcontainerStatus.data.length !== "undefined"
        ) {
          var data3 = Object.values(this.getAllcontainerStatus.data);

          if (data3) {
            this.tabDataitem.map((element) => {
              element.sub_tab = []
              if (element.name !== "") {
                let thename = "";
                if (element.name == "All") {
                  thename = "all_tab";
                }
                else if (element.name == "At Port") {
                  thename = "Pending,available";
                  element.sub_tab = [{
                                        label : "Pending",
                                        name : "Pending",
                                        count: 0
                                      },{
                                          label : "Available",
                                          name : "available",
                                          count: 0
                                      }]
                }
                else if (element.name == "Yard full") {
                  thename = "in_yard_full";
                }
                else if (element.name == "At Yard") {
                  thename = "at_yard";
                }
                else if (element.name == "Available") {
                  thename = "available";
                } else if (element.name == "Assigned") {
                  thename = "assigned";
                } else if (element.name == "Scheduled") {
                  thename = "assigned";
                }
                else if (element.name == "At the Customer") {
                  thename = "at_the_customer";
                } else if (element.name == "Completed") {
                  thename = "completed";
                } else if (element.name == "Cancelled") {
                  thename = "cancelled";
                } else {
                  var c = element.name.toLowerCase();
                  thename = c.charAt(0).toUpperCase() + c.slice(1);
                }
                const result = data3.filter((find) => thename.split(",").includes(find.name));

                element.count = 0;
                Object.values(result).forEach(function (f) {
                    if(["at_the_customer",'at_yard'].includes(f.name)){
                        element.sub_tab = [{
                            label : "Full",
                            name : "full",
                            count: f.full.count
                          },{
                              label : "Empty",
                              name : "empty",
                              count: f.empty.count
                          }]
                    }else
                      element.sub_tab.forEach(function (s_tab){
                          if(s_tab.name.toLowerCase() == f.name.toLowerCase()){
                              s_tab.count = parseInt(f.count);
                          }
                      })
                    element.count += parseInt(f.count);
                });

                if (element.name == "Assigned") {
                    element.name = "Scheduled";
                }
              }
            });
            s = this.tabDataitem;
          }
        }
      }
      return s;
    },
  },
  watch: {
    //'$route': 'reInitialize'
  },
  async mounted() {
    //set current page
    this.$store.dispatch("page/setPage", "containers");
    await this.fetallContainersstatus(`${poBaseUrl}/customer-visibility/${this.$route.params.customer_id}/allcontainer/status`);
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/containers/containers.scss";
@import "../assets/scss/buttons.scss";
</style>
