<template>
  <div class="supplier-content-wrapper driver-content-wrapper">
    <div     
      class="mainpage">
    <div class="driverHeader">
      <h2 @click="testCall">Drivers</h2>
      <v-btn
        color="primary"
        class="btn-blue add-supplier"
        @click.stop="addDriver"
      >
        + Add Driver
      </v-btn>
    </div>

      <ConnectedTable 
        v-if="thecurrent.page==0"
        :items="drivers"
        :pageCurrent="thecurrent"
        :drivers="drivers"
        :itemData="itemData"
        :reloadcomponent="reloadcomponent"
        @currentPage="getcurrentpage"
        :mainSync.sync="main"
        :isMobile="isMobile"
        :startfetching="fetchingstart"
        :allcount="pageCount"
        :page.sync="page"
        @reloadpage="callexeApi"
        :bus="bus"
        :countTab="counTab"
      />

      <Notconnected 
        v-if="thecurrent.page==1"
        :pageCurrent="thecurrent"
        :items="drivers"
        :drivers="drivers"
        :itemData="itemData"
        :reloadcomponent="reloadcomponent"
        @currentPage="getcurrentpage"
        :isMobile="isMobile"
        :startfetching="fetchingstart"
        :allcount="pageCount"
        :page.sync="page"
        @reloadpage="callexeApi"
        :bus="bus"
        :countTab="counTab"
        @sendEmail="sendEmail"
      />

      <Notactive 
        v-if="thecurrent.page==2" 
        :pageCurrent="thecurrent"
        :items="drivers"
        :drivers="drivers"
        :itemData="itemData"
        :reloadcomponent="reloadcomponent"
        @currentPage="getcurrentpage"
        :isMobile="isMobile"
        :startfetching="fetchingstart"
        :allcount="pageCount"
        :page.sync="page"
         @reloadpage="callexeApi"
         :bus="bus"
         :countTab="counTab"
          @sendEmail="sendEmail"
      />
      </div>     
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConnectedTable from "./pages/Connected.vue";
import Notconnected from "./pages/Notconnected.vue";
import Notactive from "./pages/Notactive.vue";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL
export default {
  name: "DriversDesktopTable",
  props: ["items",
          "isMobile",
          "itemData", 
          "reloadcomponent",
          "bus",
          "defaultPage"
          ],
  components: {   
    ConnectedTable,
    Notconnected,
    Notactive
  },
  data: () => ({
    counTab:[],
    page:1,
    startload:0,
    lastPage:0,
    updatedPageCount:0,
    pageCount:0,
    fetchingstart:0,
    main:1,
    newDatafetch: [],
    thecurrent:{page:0}, // page:0 == tab1, page:1 == tab2, page:2 == tab3
    startfetching: false,    
    DriverData: [],
    search: "",
  }),
  computed: {
    ...mapGetters({
      getDrivers: "drivers/getDrivers",
      getDriverLoading: "drivers/getDriverLoading",
      getBackState: "drivers/getBackState",
    }),
    drivers: {
      get() {
        return  this.newDatafetch ;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {   
    page(nv) {
      if(nv){
        this.callexeApi(this.thecurrent.page);// to work next and prev
      }
    },
  },
  created() {
   
    this.bus.$on('reload', (data) => {
      if(data)
      this.callexeApi(1);          
	})
  
  },
  methods: {
    ...mapActions({
      fetchDrivers: "drivers/fetchDrivers",
      passGobackData: "drivers/passGobackData",
    }),
    async getcurrentpage(current){
      await this.passGobackData(current)
      this.callexeApi(current);
      this.thecurrent = { page:-1 }    
      this.$nextTick(() => {
        this.thecurrent = {page:current}
      });     
    },
    testCall(){
       this.bus.$emit('call',{
                        cur:1,          
                     });
    },
    async sendEmail(id){       
      await axios
        .post(`${poBaseUrl}/sendEmailDriver`,{driverID:id})
        .then(function (response) {         
            if (response) {
                console.log(response)
            }
        }).catch(error => {
            console.log(error)
        })
    }, 
    addDriver() {
      this.$emit("addDriver");
    },
    toUpperCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    deleteSupplier(item) {
      this.$emit("editPassdata", item.driver_id);
      this.$emit("deleteSupplier", item);
    },
    async callexeApi (cur){
        
        this.startload = 1;
        let url = '';        
        if(this.thecurrent.page !=cur){
          this.page = 1
        }
        switch(cur)
        {
          case 0:
            url = 'getConnectedDriver?page='+this.page;
            break;
          case 1:
            url = 'getnotConnectedDriver?page='+this.page;
            break;
          case 2:
            url = 'getNotActiveDriver?page='+this.page;
            break;
          default:
            url = 'getConnectedDriver?page='+this.page;
            break;
        }

        let dis = this;
        dis.newDatafetch= [];
        dis.fetchingstart = 1;
        
        await axios
        .get(`${poBaseUrl}/`+ url)
        .then(function (response) {         
            if (response) {  
                dis.pageCurrent = dis.page;
                dis.lastPage = response.data.drivers.last_page;            
                dis.newDatafetch = response.data.drivers.data               
                dis.pageCount = response.data.drivers.last_page;
                dis.counTab =  JSON.parse(response.data.count)      
                dis.startload = 0;      
            }
             dis.fetchingstart = 0;
        }).catch(error => {
            console.log(error)
        })
    },
    getalldrivers() {
      let array = [];
      var response = this.getDrivers;
      response.forEach((v) => {
        let m = {
          driver_id: v.id,
          name: v.name,
          last_name: v.last_name,
          phone_number: v.phone_number == null ? "" : v.phone_number,
          emails: v.email,
        };
        array.push(m);
      });

      return array;      
    },
  },
  async mounted() {   
    this.thecurrent = {page:this.getBackState}  
    this.callexeApi(this.getBackState);   
    //set current page
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
<style>
.classsort {
	background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
	margin-left: 4px;
	width: 21px;
	height: 17px;
	display: block;
	position: relative;
	top: 3px;
	left: 1px;
	float: right;
}

.assignUnassignFilter{
  display: flex;
  border: 1px solid #B4CFE0;
  border-radius: 5px;
  margin: 0;
  margin-left: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0px;
  caret-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.assignUnassignFilter div.active {
  background-color: #EBF2F5 !important;
  color: #0171A1;
}

.assignUnassignFilter div:first-child {
  padding: 8px 10px 8px 9px !important;
  border-radius: 5px 0 0 5px !important;
}

.assignUnassignFilter div:hover {
  cursor: pointer;
  background-color: #EBF2F5 !important;
}

.assignUnassignFilter div {
  padding: 8px 10px 8px 12px !important;
  border-right: 1px solid #B4CFE0;
  font-size: 12px;
  color: #4A4A4A;
  -moz-user-select: none;
  -ms-user-select: none;
}

.trucking_DropDown .v-list-item__title{
  width: 100% !important;
  color: #4A4A4A !important
}
</style>