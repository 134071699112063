<template>
    <v-dialog persistent 
        v-model="dialog" 
        max-width="590px" 
        content-class="add-supplier-dialog add-driver-dialog" 
        v-resize="onResize" 
        :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-form 
                id="createdriver" 
                ref="form" 
                v-model="valid"
                action="#" 
                @submit.prevent=""
            >
                <v-card-title style=" caret-color: transparent;"> 
                    Choose Driver Account Holder
                    <button icon dark class="btn-close" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <v-card-text>
                    <v-row>                      
                        <v-col cols="12" sm="12" md="12" class="pb-3 pt-0 mt-5">
                            <label class="text-item-label">Choose Driver Account Holder</label>
                             <v-autocomplete
                                v-model="accountDriverHolder"                              
                                :items="dropdownDriverHolder"
                                class="driverAccountHolder"
                                item-text= 'name'
                                solo
                                item-value='id'
                                placeholder="Select Driver Account Holder"                              
                                append-icon="ic-chevron-down"
                                return-object
                            >
                </v-autocomplete>   
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                     <v-col class="text-right buttonActions" style="padding-right:0px;display:flex;">
                        <v-btn class="btn-blue" text @click="save">
                            <span v-if="editedIndexData === -1">
                                <span>
                                     <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >
                                    </v-progress-circular>
                                    Connect Now
                                </span>
                            </span>
                            <span v-else>
                                Connect Now
                            </span>
                        </v-btn>
                        <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                            Cancel
                        </v-btn>
                     </v-col>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import globalMethods from '../../../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'DriverDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData','driverID','bus'],
    components: {
       // VueTelInputVuetify
    },
    data: () => ({
        accountDriverHolder:'',
        passwordMsg : 'Minimum of 8 characters,at least one numeric digit and a special character.',
        passwordModel: '',
        hasError: false,
        passwordRequired: false,
        driverName: '',
        last_name: '',
        isFetching:0,
        errorMessage:'',
        errorSaving: 0,
        errorContent: '',
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "Input is required.",
            v => !v || /^([\w]{3,})+\s+([\w\s]{3,})+$/i.test(v) || 'Invalid Full Name'
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 25,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        emailRules:[
            (v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        numberRules: [
            (v) => !!v || "Input is required.",
            (v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        driverEmailAddress: ''
    }),
    computed: {
        ...mapGetters({
             getHolderDriver: 'drivers/getDriverAccountHolder'
        }),
        dropdownDriverHolder:{
            get () {
                return this.getHolderDriver
            },
        },
        formTitle () {
            return this.editedIndexData === -1 ? 'Add Driver' : 'Edit Driver'
        },
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        }
    },
    methods: {
        ...mapActions({
            fetchDriverHolder: 'drivers/fetchDriversAccountHolder'
        }),
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        fullnameValidate(val){
            if(val !=''){
               if (!val.match(/^[a-z\d\-_\s]+$/i))
                  return 'Invalid Name'
            }else
              return 'This field is required'
            return true;
        },   
        close() {            
            this.$emit('update:dialogData', false)
        },
        async save() {          
            var dis = this;
            let formData = new FormData(document.getElementById('createdriver'))
                formData.append('driverAccountHolder', this.accountDriverHolder.id) 

            this.isFetching = 1;
            this.errorMessage = '';
            let userID = dis.accountDriverHolder.id
            axios.put(`${APIBaseUrl}/connectDriver/`+ dis.driverID+'/'+userID,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                console.log(error)
            }).then(response => {
                if(response){
                    dis.startloading = 0;
                    dis.errorSaving = 1;
                    dis.errorContent = this.errorMessage
                    dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Driver has been connected.</span>')
                    this.$emit('update:dialogData', false)
                    this.$emit('update:fetchCompleted',1)                    
                     this.bus.$emit('reload',{
                        cur:1,          
                     });
                    dis.isFetching = 0;
                }
            })
        },
        setToDefault() {
            this.$emit('setToDefault')
        }
        },      
    watch: {
    },
    async mounted(){
        await this.fetchDriverHolder();
    }

}
</script>

<style lang="scss">

@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/drivers/driverAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.driverAccountHolder {
    font-size: 14px;
    color:#4A4A4A;
}
.driverAccountHolder .v-input__control .v-input__slot {
    border:1px solid #ddd !important;
    box-shadow: none !important;
}

.driverAccountHolder .v-card__text .v-input .v-input__control {
    background-color: #ffffff !important
}
.v-input--is-label-active,.v-select--is-menu-active {
    background: none !important;
}
</style>
