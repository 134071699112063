<template>
    <v-dialog 
        persistent 
        v-model="dialog" 
        max-width="480px" 
        content-class="delete-driver-dialog dialogYard"  
        :retain-focus="false"
        >
        <v-card 
          style="padding-bottom:8px">
          <v-card-text 
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >            
            <svg                
                width="44" 
                height="44" 
                viewBox="0 0 44 44" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M22.0004 43.6004C33.9297 43.6004 43.6004 33.9297 43.6004 22.0004C43.6004 10.071 33.9297 0.400391 22.0004 0.400391C10.071 0.400391 0.400391 10.071 0.400391 22.0004C0.400391 33.9297 10.071 43.6004 22.0004 43.6004ZM22.0004 40.3609C11.8602 40.3609 3.63989 32.1406 3.63989 22.0004C3.63989 11.8602 11.8602 3.63989 22.0004 3.63989C32.1406 3.63989 40.3609 11.8602 40.3609 22.0004C40.3609 32.1406 32.1406 40.3609 22.0004 40.3609ZM21.9925 25.242C22.8125 25.2425 23.4906 24.6336 23.5984 23.8431L23.6133 23.6233L23.6211 11.7417C23.6217 10.8471 22.897 10.1215 22.0024 10.1209C21.1824 10.1203 20.5043 10.7293 20.3965 11.5198L20.3816 11.7396L20.3738 23.6212C20.3732 24.5157 21.098 25.2414 21.9925 25.242ZM22.0014 32.796C23.1925 32.796 24.1581 31.8304 24.1581 30.6392C24.1581 29.448 23.1925 28.4824 22.0014 28.4824C20.8102 28.4824 19.8446 29.448 19.8446 30.6392C19.8446 31.8304 20.8102 32.796 22.0014 32.796Z" fill="#D68A1A"/>
            </svg>

            <h2 
              class="delivery_header"
              style="">Rejected Delivery Order</h2>

            <p 
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                   DO #{{item.shifl_ref}} has been rejected.
            </p>
            
          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="continueDialog" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Close
                    </span>
                </span>
            </v-btn>      
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Shownotification',
    data(){
      return {
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData','closeDialog','item'], 
    computed: { 
        dialog: {
            get () {               
              return this.dialogData
            } ,
            set(value) {
                if (!value) {
                    this.$emit("update:dialogData",false);
                } else {
                    this.$emit("update:dialogData",true);
                }
            },       
        },                       
    },
    methods: {      
        continueDialog() {             
          this.$emit("update:closeDialog",true); 
        },              
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>