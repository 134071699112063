<template>
<v-layout
	row
	justify-center
>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="480"
	>
		<v-card class="card_dialog_delete pt-3 pb-3">
			<v-card-title class="text-h5">

			</v-card-title>
			<v-card-text>
				<h2>Are You Sure?</h2>
			</v-card-text>
			<v-card-text> Do you want to mark this shipment as {{ isChecked ? '' : 'Not' }} {{ milestoneTexts[field] }}
			</v-card-text>
			<v-card-actions class="v-card-action-delete-purchase-order">
				<v-btn
					class="btn-blue"
					text
					@click="mark"
					:disabled="isLoading"
				> {{  "Mark As " + (isChecked ? '' : 'Not ') + milestoneTexts[field] }}
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="close"
				> Cancel </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</v-layout>
</template>
<script>
export default {
	name: "MilestoneConfirmationDialog",
	props: ['dialog', 'isLoading', 'itemData', 'field', 'isChecked'],
	mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "settings/users");
	},
	data: () => ({
		isMobile: false,
		valid: true,
		milestoneTexts: {
			discharged : 'Discharged',
			fully_released : 'Fully Released',
			pier_pass_paid : 'Pier Gate Fees Paid'
		},
	}),
	methods: {
		onResize() {
			if(window.innerWidth < 769) {
				this.isMobile = true
			}
			else {
				this.isMobile = false
			}
		},
		mark() {
      this.itemData[this.field] = this.isChecked
			this.$emit('updateMilestone', this.itemData)
		},
		close() {
			this.$emit('closeMilestoneDialog', this.itemData)
		}
	},
};
</script>
