<template>
  <div class="container-create">
    <v-toolbar class="toolbHeader" flat>
      <v-toolbar-title style="overflow: visible !important">
        <span class="NewContainerShipmentTitle"> New Delivery Order </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :class="noerror == 1 ? 'btn-blue submitNow' : 'btn-white saveCreateBtn'"
        depressed
        @click="clicsubmit()"
        color="primary"
        :disabled="noerrorForm"
      >
        Save
      </v-btn>
      <v-btn
        style="margin-left: 8px"
        class="btn-white createCancelBtn"
        depressed
        @click="cancel"
        color="primary"
      >
        Cancel
      </v-btn>
    </v-toolbar>
    <v-progress-circular
      :size="15"
      color="#fff"
      :width="2"
      indeterminate
      v-if="startloading"
      style="margin-right: 3px"
    >
    </v-progress-circular>
    <v-form
      class="sFormClass"
      style="padding: 0px !important; border-top: 1px solid #ebf2f5"
      id="sform"
      ref="form"
      v-model="valid"
      @submit.prevent="submit"
    >
      <v-row class="clRow">
        <v-col
          class="leftcol"
          md="4"
          style="
            width: 59% !important;
            min-width: 29% !important;
            max-width: 30.4% !important;
            padding: 20px !important;
          "
        >
          <h4>Delivery Order Information</h4>
          <v-row>
            <v-col class="etacol pb-0 mt-2" cols="6" md="6">
              <small>ETA</small>
              <!-- <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                placeholder="05/21/2021"
                                append-icon="ic-calendar"
                                readonly
                                name="ETA"
                                v-bind="attrs"
                                v-on="on">
                            </v-text-field>
                        </template>
                    <v-date-picker
                        v-model="date"
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                            >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog> -->
              <v-text-field
                v-model="date"
                placeholder="05/21/2021"
                append-icon="ic-calendar"
                :rules="validateDaterules"
                name="ETA"
                @click:append="showcustom(date, 'date')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="pb-0 mt-2">
              <small>MBL #</small>
              <v-text-field
                placeholder="Enter MBL #"
                required
                v-model="mbl"
                :rules="mblRules"
                name="mbl_num"
                hide-details="auto"
                @keyup="blurevent"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="selectBox pt-0" cols="12" sm="12">
              <small>CUSTOMER</small>
              <v-autocomplete
                v-model="customerdropdown"
                :items="itemsDropdownComputed"
                :rules="customerRules"
                item-text="company_name"
                item-value="id"
                placeholder="Select Customer"
                solo
                @mousedown="dataDown('customer')"
                @keyup="blurevent"
                append-icon="ic-chevron-down"
                return-object
                @change="onSelectCustomer(customerdropdown)"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="customerdropdown !== ''">
            <v-col class="selectBox pt-0" cols="12" sm="12">
              <small>CONSIGNEE</small>
              <v-autocomplete
									ref="consigneeRef"
									class="select-consignee"
									v-model="selectedConsignee"
									:items="consigneeLists"
									item-text="name"
									item-value="id"
									:placeholder="getAllConsigneesLoading ? 'Fetching consignees...' : 'Select Consignee'"
									solo
									append-icon="ic-chevron-down"
									:search-input.sync="searchedConsignee"
                  :menu-props="{ contentClass: 'consignee-menu-lists'}"
                  :disabled="getCreateConsigneeLoading || getAllConsigneesLoading">

									<div slot="no-data">
                    <p class="px-4 py-3 mb-0" style="font-size: 14px;" v-if="!hasNewConsignee || consigneeLists.length === 0">
                      No available data
                    </p>
                  </div>

                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                        <p class="consignee-name mb-0" style="font-size: 14px; color: #4a4a4a;">{{item.name}}</p>
                    </v-list-item-content>
                </template>

                <div class="v-list-item v-list-item__title add-option-btn px-3 py-1" 
                  style="min-height: 35px; border-top: 1px solid #EBF2F5 !important;"
										slot="append-item" @click="handleNewConsignee" v-if="hasNewConsignee && searchedConsignee !== ''">
                    <span style="font-size: 14px; color: #0171A1;">
                      {{ getCreateConsigneeLoading ? 'Adding...' : '+ Add New'}}
                      <span v-if="searchedConsignee" style="color: #4a4a4a;">- {{ searchedConsignee }}</span>
                    </span>
									</div>
								</v-autocomplete>
            </v-col>
          </v-row>
          <v-row style="margin-top: 0px">
            <v-col class="selectBox" cols="12" md="12" style="padding-top: 3px">
              <label class="text-item-label"> Contacts </label>

              <div class="tags-email-wrapper mb-1">
                <vue-tags-input
                  hide-details="auto"
                  :rules="arrayNotEmptyRules"
                  :tags="options"
                  :add-on-blur="true"
                  :class="myclass"
                  class="DialogInputfield"
                  :add-on-key="[13, ',']"
                  :validation="
                    myclass.length > 0 ? tagsValidation : tagsValidation
                  "
                  v-model="warehouseEmailAddress"
                  placeholder="Enter email address"
                  @tags-changed="
                    (newTags) => {
                      this.myclass = 'noclass';
                      this.options = newTags;
                      this.tagsInput.touched = true;
                      this.tagsInput.hasError =
                        this.options.length > 0 ? false : true;
                      let el =
                        this.documentProto.getElementsByClassName(
                          'ti-input'
                        )[0];
                      if (typeof el !== 'udnefined') {
                        /*if (this.tagsInput.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                      }
                    }
                  "
                />
              </div>

              <div
                v-if="tagsInput.touched && tagsInput.hasError"
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div
                      v-if="options.length > 0 && warehouseEmailAddress !== ''"
                      class="v-messages__message"
                    >
                      {{ tagsInput.errorMessage }}
                    </div>

                    <div
                      v-if="options.length == 0 && warehouseEmailAddress !== ''"
                      class="v-messages__message"
                    >
                      {{ tagsInput.errorMessage }}
                    </div>
                    <div
                      v-if="options.length == 0 && warehouseEmailAddress == ''"
                      class="v-messages__message"
                    >
                      Please provide at least 1 valid email address.
                    </div>
                  </div>
                </div>
              </div>
              <span style="color: #819fb2; font-size: 12px">
                <i style="font-size: 12px; color: #819fb2">
                  Separate multiple email addresses with comma
                </i>
              </span>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="6" md="6" class="selectBox">
              <small>Carrier</small>
              <v-autocomplete
                v-model="CarrierModel"
                :items="carrierDropdown"
                item-text="name"
                item-value="id"
                placeholder="Select Carrier"
                name="carrier_id"
                solo
                @mousedown="dataDown('CARRIER')"
                @keyup="blurevent"
                append-icon="ic-chevron-down"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="6">
              <small>Vessel</small>
              <v-text-field
                placeholder="Vessel"
                required
                v-model="VesselModel"
                name="vessel_name"
                hide-details="auto"
                @keyup="blurevent"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row style="margin-top: 0px">
            <v-col class="selectBox" cols="12" md="12" style="padding-top: 3px">
              <label class="text-item-label"> Customer Reference # </label>

              <div class="tags-email-wrapper mb-1">
                <vue-tags-input
                  hide-details="auto"
                  :rules="arrayNotEmptyRulesRefference"
                  :tags="optionsReferrence"
                  :add-on-blur="true"
                  :class="myclass2"
                  class="DialogInputfield"
                  :add-on-key="[13, ',']"
                  :validation="
                    myclass.length > 0 ? tagsValidation2 : tagsValidation2
                  "
                  v-model="customerReferrenceNumber"
                  placeholder="Enter reference #"
                  @tags-changed="
                    (newTags) => {
                      this.myclass2 = 'noclass';
                      this.optionsReferrence = newTags;
                      this.tagsInput2.touched = true;
                      this.tagsInput2.hasError =
                        this.optionsReferrence.length > 0 ? false : true;
                      let el =
                        this.documentProto.getElementsByClassName(
                          'ti-input'
                        )[0];
                    }
                  "
                />
              </div>
              <div
                v-if="tagsInput2.touched && tagsInput2.hasError"
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div
                      v-if="
                        optionsReferrence.length > 0 &&
                        customerReferrenceNumber !== ''
                      "
                      class="v-messages__message"
                    >
                      {{ tagsInput2.errorMessage }}
                    </div>

                    <div
                      v-if="
                        optionsReferrence.length == 0 &&
                        customerReferrenceNumber !== ''
                      "
                      class="v-messages__message"
                    >
                      {{ tagsInput2.errorMessage }}
                    </div>
                    <div
                      v-if="
                        optionsReferrence.length == 0 &&
                        warehouseEmailAddress == ''
                      "
                      class="v-messages__message"
                    >
                      Please provide at least 1 valid refrence number.
                    </div>
                  </div>
                </div>
              </div>
              <span style="color: #819fb2; font-size: 12px">
                <i style="font-size: 12px; color: #819fb2">
                  Separate multiple referrence # with comma
                </i>
              </span>
            </v-col>
          </v-row>
          <v-row class="rowRemovemargin">
            <v-col class="selectBox" cols="12" md="12">
              <small>Container Starting Location</small>
              <v-autocomplete
                v-model="terminalAt"
                :rules="TerminalAtRules"
                :items="terminalsDropdown"
                item-text="name"
                item-value="id"
                placeholder="Select Terminal"                
                @mousedown="dataDown('TERMINAL AT')"
                @keyup="blurevent"
                append-icon="ic-chevron-down"
                return-object
                :filter="onFilter"
                attach
                class="startingDOSclass"
              >
              <template slot="item" slot-scope="{ item }">
                        <template v-if="typeof item == 'object'">                            
                            <div class="listDropdown" style="font-size:12px">
                            {{ item.firms_code }} <span class="strtinglocationh"></span> {{ item.name }}
                            </div>
                        </template>
                    </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="rowRemovemargin">
            <v-col class="selectBox pt-0" cols="12" md="12">
              <small>Container Commodity</small>
              <v-text-field
                placeholder="Enter Container Commodity"
                required
                v-model="container_commodity"
                name="mbl_num"
                hide-details="auto"
                @keyup="blurevent"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="notesrow" style="margin-top: 14px">
            <v-col class="textareanotes" cols="12" md="12">
              <small>Notes (Optional)</small>
              <v-textarea
                v-model="notembl"
                :rules="noterules"
                placeholder="Type Note"
                rows="2"
                name="custom_notes"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row class="notesrow mt-0"> </v-row>
        </v-col>
        <v-col class="uploadDocuwrapper">
          <div class="deliveryOrderColumn">
            <div
              style="
                padding-bottom: 7px;
                color: #4a4a4a;
                font-size: 16px;
                font-weight: 600;
              "
            >
              Document
              <!-- <span 
                            style="float:right;font-size:14px !important;color:#0171A1;cursor:pointer"                         
                            v-if="uploadTrigger"
                            @click="uploadAgain"
                            class="reupload"
                        >
                            <img class="uploadCreateNew" src="../../../assets/icons/uploadnew.svg">
                            Reupload 
                        </span>   -->
            </div>

            <small
              style="color: red; font-size: 12px; margin-left: 5px"
              v-if="errorFile"
            >
              Invalid File Type
            </small>
            <span style="display: none" class="fileuploaderror"> </span>
            <div
              style="display: block"
              class="uploadElement flex w-full h-screen items-left justify-left text-left"
              id="app"
            >
              <div
                style="width: 100%; background: #fff !important"
                :class="hasContent"
                xclass="cDownloadfiles"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  style="display: none"
                  type="file"
                  name="fields[]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept="application/pdf"
                />

                <label
                  for="assetsFieldHandle"
                  class="block cursor-pointer triggerelement"
                >
                  <div class="browserInput2 dragfileElement">
                    <div class="browseFileDelivery">Browse Or Drop File</div>
                    <div class="btn-white2 browseButton2">
                      <div class="wrappIconbutton2">
                        <span class="iconUploadfile"
                          ><i class="ic-upload"></i
                        ></span>
                        <span class="classUpload">Upload</span>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div
                class="flex w-full h-screen items-left justify-left text-left"
                style="width: 100%"
              >
                <ul
                  style="width: 100%"
                  class="mt-4 displayItem"
                  v-if="this.filelist.length"
                  v-cloak
                >
                  <li
                    class="text-sm p-1 mb-3"
                    v-for="(file, index) in filelist"
                    :key="index"
                  >
                    <div
                      style="display: flex; align-items: center"
                      class="rowItemUploaded"
                    >
                      <span
                        class="IconUpload"
                        style="background: #fff; border-radius: 30px"
                      >
                        <img
                          class="imgIcon"
                          src="../../../assets/icons/documentFile.svg"
                        />
                      </span>
                      <div
                        style="display: flex; align-items: center"
                        class="mainwrapitem"
                      >
                        <div class="fileNameList" style="top: 0px !important">
                          {{ file.name }}
                        </div>
                        <!-- <div 
                                                class="fileSize">[ {{ (file.size / 1024).toFixed(2) }} kb <span>.</span> pdf ] 
                                                <button 
                                                    class="ml-2" 
                                                    type="button" 
                                                    @click="remove(filelist.indexOf(file) , objectServerid)" 
                                                    title="Remove file"
                                                    >
                                                    <i 
                                                        style="color:#212121" 
                                                        class="ic-delete"
                                                        >
                                                    </i>
                                                </button>
                                            </div> -->
                        <div style="margin-top: 5px">
                          <v-progress-circular
                            :size="20"
                            color="#0775A3"
                            indeterminate
                            v-if="!uploadCompleted"
                          >
                          </v-progress-circular>
                        </div>
                        <div
                          class="flex justify-end fileSize"
                          v-if="uploadCompleted"
                        >
                          <button class="replace-btn" @click="handleReplace">
                            Replace
                          </button>
                          <a
                            class="ml-2 document-btn"
                            target="_blank"
                            :href="getFileURL(file)"
                          >
                            <img
                              style="
                                cursor: pointer;
                                margin-right: 12px;
                                margin-top: 9px;
                              "
                              src="../../../assets/icons/view-blue.svg"
                            />
                          </a>
                          <button
                            class="ml-2 document-btn"
                            type="button"
                            @click="
                              remove(filelist.indexOf(file), objectServerid)
                            "
                            title="Remove file"
                          >
                            <i style="color: red" class="ic-delete"> </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <small style="color: #f93131 !important" v-if="moreFilesError"
                    >Only 1 file can be uploaded</small
                  >
                </ul>
              </div>
            </div>
          </div>
          <div
            class="milestone-create-container333"
            style="
              padding-left: 7px;
              position: relative;
              width: 101%;
              padding-top: 18px;
            "
          >
            <div class="mainWrapperListContainers">
              <div v-if="containerList.length > 0">
                <!-- <ul class="topheader" width="100%" style="padding-left:19px">                               
                                <li style="width:24%" >Container #</li>
                                <li>Size</li>
                                <li>Weight</li>
                                <li>Volume</li>
                                <li>Qty</li>                                 
                            </ul> -->
                <ListRendering
                  v-if="refresh"
                  @saveUpdated="saveUpdated"
                  :containerList="containerList"
                  :ContainerSize="ContainerSize"
                  :disableEditContainers="disableEditContainers"
                  @tellthelengthobject="checklengthdata"
                  @showEditnow="emitedit"
                  :iscreate="iscreate"
                  :istriggerededit="istriggerededit"
                />
              </div>
              <div v-else>
                <p
                  style="
                    text-align: center;
                    font-size: 16px;
                    padding-top: 13px;
                    color: #6d858f;
                  "
                >
                  {{ containerEmptytext }}
                </p>
                <div
                  class="addContainer"
                  style="text-align: center; border: 0px !important"
                >
                  <span
                    style="color: #0171a1; cursor: pointer"
                    @click="addContainer"
                  >
                    + Add Container
                  </span>
                </div>
              </div>
              <div v-if="containerList.length > 0" class="addContainer">
                <span
                  style="
                    color: #0171a1;
                    cursor: pointer;
                    position: relative;
                    top: -7px;
                  "
                  @click="addContainer"
                  >+ Add Container</span
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <ContainerDialog
      @saveContainer="saveContainer"
      :dialogData.sync="dialog"
      :ContainerSize="ContainerSize"
      :lastIndexData="lastIndexData"
    />

    <ContainerDialogEdit
      @updateContainer="updateContainer"
      :dialogData.sync="showedit"
      :ContainerSize="ContainerSize"
      :editdata="keysvalue"
    />

    <CreatePicker
      :dialogDatepicker="dialogDatepicker"
      :defaultData="pickerDefaultdata"
      @passbackdata="getdata"
      @close="closeDialogpicker"
    />
    <Confirm
      @confirmDialog="continueDialog"
      :dialogData.sync="dialogConfirm"
      :isSaving.sync="isSavingConfirm"
      :deliveryID="DO_ID"
      :containersnumber="containers_number"
      :countshipment="countshipment"
      :shipmentIDS="shipmentIDS"
      :confirmmessage="confirm_message"
      :location="location"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import globalMethods from "../../../utils/globalMethods";
import VueTagsInput from "@johmun/vue-tags-input";
import ContainerDialog from "./dialog/DialogContainer.vue";
import ContainerDialogEdit from "./dialog/EditDialogContainer.vue";
import ListRendering from "./listRender.vue";
import CreatePicker from "../../Createpicker.vue";
import Confirm from "./confirm.vue";
import jQuery from "jquery";
import Vue from "vue";

//import _ from "lodash";
export default {
  name: "create_containers",
  props: ["items", "customers", "terminals", "ContainerSize", "carriers"],
  components: {
    VueTagsInput,
    ContainerDialog,
    ListRendering,
    CreatePicker,
    Confirm,
    ContainerDialogEdit,
  },
  data() {
    return {
      location: "create",
      istriggerededit: 3,
      iscreate: 1,
      confirm_message: [],
      movedo: "createdo",
      isMovedo: 0,
      eventBus: new Vue(),
      keytovalue: 0,
      keysvalue: [],
      editdata: [],
      showedit: 0,
      isErrorInlinEdit: 0,
      countshipment: 0,
      shipmentIDS: [],
      containers_number: [],
      DO_ID: "",
      isSavingConfirm: false,
      dialogConfirm: false,
      disableEditContainers: false,
      refresh: 0,
      callna: "",
      solidestyle: 0,
      errorstate: 0,
      notpermited: false,
      calleditableELe: false,
      pickerDefaultdata: "",
      currentstatepicker: "",
      dialogDatepicker: false,
      modaletd: false,
      container_commodity: "",
      customerdropdown: "",
      allDeliveryData: [],
      currentindexshow: 99999,
      containerEmptytext:
        "Container is empty. Add container before you save changes",
      roottablecontainer: "blueteam",
      containerList2: [],
      containerList: [],
      HBL_NUMBER: "",
      uploadCompleted: true,
      uploadTrigger: false,
      noerrorForm: true,
      contactsModel: "",
      CarrierModel: "",
      VesselModel: "",
      responseDatatempt: "",
      serverObject: [],
      objectServerid: "",
      hasContent: "hasContentFiles2",
      filelist: [],
      errorFile: false,
      comodity: "",
      resetform: false,
      shipmentmsg: "The shipment was created!",
      Truckers: false,
      noerror: false,
      testsubmit: "",
      formFieldlength: 0,
      startloading: 0,
      clickOrigin: "",
      clicksubmit: false,
      submitisclick: false,
      selectedmilestone: [],
      customer: [],
      mbl: "",
      containerNumber: "",
      terminalAt: [],
      CarrierRules: [(v) => !!v || "Customer is required"],
      VesselRules: [(v) => !!v || "Customer is required"],
      customerRules: [(v) => !!v || "Customer is required"],
      mblRules: [(v) => !!v || "Mbl# is required"],
      fieldrequired: [(v) => !!v || "This field is required"],
      containerNumberRules: [(v) => !!v || "Container # is required"],
      notembl: "",
      noterules: [
        (v) =>
          v.length < 200 || "Should not exceeds to 200 allowed characters.",
      ],
      TerminalAtRules: [(v) => !!v || "Terminal At is required"],
      containerSizeModel: [],
      containersize: "",
      containersizeRules: [(v) => !!v || "Container size is required"],
      deliverTo: "",
      delivertoRules: [(v) => !!v || "Deliver to is required"],
      weight: "",
      weightRules: [(v) => !!v || "Weight is required"],
      customerpoNumber: "",
      customerpoNumberRules: [(v) => !!v || "Customer PO# is required"],
      containerTypeField: "",
      containerTypeRules: [(v) => !!v || "Container Type is required"],
      truckersField: "",
      truckersRules: [(v) => !!v || "Truckers is required"],
      hiddenmilestone: "",
      hiddenmilestoneRules: [(v) => !!v || "Please select milestone"],
      select: "",
      customerSelected: "",
      containertypeSelected: "",
      terminalatSelected: "",
      containersizeSelected: "",
      currentDropdownState: "",
      trucker_idSelected: "",
      valid: true,
      currentComboState: "",
      tabscurrent: 0,
      tab: null,
      tabsNavigation: ["Header Info"],
      date_etd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      checkboxColor: "#0171A1",
      container_type: "",
      select_terminal_at: "",
      itemsDropdown: [],
      dialog: false,
      dialog2: false,
      search: "",
      searchData: "",
      selectedTasks: [],
      containerType: [],
      containerSize: [],
      TERMINAL_AT: [],
      ex4: [],
      truckers: [],
      newdata: [],
      myclass: "",
      myclass2: "",
      options: [],
      optionsReferrence: [],
      documentProto: document,
      warehouseEmailAddress: "",
      customerReferrenceNumber: "",
      moreFilesError: false,
      submit: false,
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsInput2: {
        touched: false,
        hasError: false,
        errorMessage:
          'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.',
      },
      tagsValidation: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      tagsValidation2: [
        {
          classes: "t-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+$/,
          disableAdd: true,
        },
      ],
      arrayNotEmptyRules: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      arrayNotEmptyRulesRefference: [
        //(v) => !!v || "Email is required",
        () =>
          this.optionsFiltered.length > 0 ||
          "Make sure to supply at least 1 email.",
      ],
      tagsValidation3: [
        {
          classes: "ttt-new-tag-input-text-error",
          rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
          disableAdd: true,
        },
      ],
      validateDaterules: [
        (v) => {
          if (v === "") return "This field is required";
          if (!this.validateDate(v)) {
            return "Invalid Date ex: YYYY-mm-dd";
          }
          return true;
        },
      ],
      lastIndexData: null,
      consigneeLists: [],
      selectedConsignee: null,
      newConsignee: "",
			hasNewConsignee: 0,
    };
  },
  watch: {
    keysvalue(c) {
      console.log(c);
    },
    customers(c) {
      if (c == "") {
        console.log("customer is null");
      }
    },
    items: function (newVal) {
      this.getPrelims(newVal);
    },
    filelist(c) {
      // if(c.length > 0){
      //     /**
      //      * Temporarily remove to not upload files directly without delivery order ID
      //      */
      //     //    this.callUPdocument(c);

      //     console.log(c)
      // }

      if (c.length == 0) this.hasContent = "hasContentFiles2";
      else this.hasContent = "cDownloadfiles hidethisdiv";
    },
  },
  computed: {
    ...mapGetters({
      getCreateloading: "containers/getPoLoading",
      formdetails: "containers/getcontainerFormDetails",
      getPoLoading: "containers/getPoLoading",
      getAllConsignees: 'containers/getAllConsignees',
      getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
      getCreateConsigneeLoading: 'containers/getCreateConsigneeLoading'
    }),
    itemsDropdownComputed: {
      get() {
        return this.customers;
      },
    },
    terminalsDropdown: {
      get() {
        return this.terminals;
      },
    },
    carrierDropdown: {
      get() {
        return this.carriers;
      },
    },
    sizeDropdown: {
      get() {
        return this.ContainerSize;
      },
    },
    searchedConsignee: {
			get() {
				return this.newConsignee !== null && this.newConsignee !== '' ? this.newConsignee.toString() : this.newConsignee;
			},
			set(input) {
				let temp = [];
				this.consigneeLists.map((list) => {
					temp.push(list.name);
				});
				if(temp.includes(input)) {
					this.hasNewConsignee = 0;
				}
				else if(this.newConsignee === "" || this.newConsignee === null) {
					this.hasNewConsignee = 0;
				}
				else {
					this.hasNewConsignee = 1;
				}
				this.newConsignee = input;
			},
		},
  },

  methods: {
     onFilter(item, queryText){            
        if(item.nickname){
              return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            || item.nickname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) 
            || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
        }
      return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())

    },
    continueDialog(item) {
      if (item.isConfirmed) {
        // this.dialogConfirm = false;
        this.isSavingConfirm = true;
        if (item.isConfirmed) {
          //this.saveForm();
          this.$router.push({ path: "/containers" }).then(() => {
            this.$router.go();
          });
        }
      }
    },
    classCheck(cate) {
      if (cate.error == 1) {
        return "triggerclasserror";
      }
      return "";
    },

    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    },
    calleditable() {
      return this.calleditableELe;
    },
    edit() {
      return (this.calleditableELe = true);
    },
    validateDate(dateStr) {
      let str = dateStr.split("T");
      dateStr = str[0];
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateStr.match(regex) === null) {
        return false;
      }
      const date = new Date(dateStr);
      const timestamp = date.getTime();
      if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
      }
      return date.toISOString().startsWith(dateStr);
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    closeDialogpicker() {
      this.dialogDatepicker = false;
    },
    getdata(item) {
      if (this.currentstatepicker == "date") {
        this.date = item;
      }
    },
    clickDownload() {
      this.gotoDownload();
    },
    uploadAgain() {
      jQuery(document).find(".triggerelement").trigger("click");
    },
    timetopass() {
      let finalEmailAddress =
        this.options.length > 0
          ? this.options.map((o) => {
              return o.text;
            })
          : [];
      return finalEmailAddress;
    },
    timetopassreference() {
      // let finalEmailAddress = (this.optionsReferrence.length > 0) ? this.optionsReferrence.map(o => {
      //         return o.text
      //     }) : []
      //     return finalEmailAddress;
      let finalrefrenceNumber =
        this.optionsReferrence.length > 0
          ? this.optionsReferrence.map(({ text }) => ({
              customer_ref_number: text,
            }))
          : [];
      return JSON.stringify(finalrefrenceNumber);
    },
    ...mapActions({
      fetchFormdetails: "containers/fetchallformdetails",
      fetchConsignees: 'containers/fetchConsignees',
      createConsignee: 'containers/createConsignee'
    }),
    ...globalMethods,
    blurevent() {
      this.checkobjects();
    },
    currentshow(index) {
      if (index == this.currentindexshow) return 1;
    },
    // magamit if click sa save sa taas
    checkobjects() {
      if (
        Object.keys(this.mbl).length !== 0 &&
        Object.keys(this.customerdropdown).length !== 0 &&
        Object.keys(this.CarrierModel).length !== 0 &&
        Object.keys(this.VesselModel).length !== 0 &&
        Object.keys(this.terminalAt).length !== 0 &&
        Object.keys(this.containerList).length !== 0 &&
        this.isErrorInlinEdit != 1
      ) {
        this.noerror = 1;
        this.noerrorForm = false;
      } else {
        this.noerror = 0;
        this.noerrorForm = true;
      }
    },
    deleteClear(i) {
      this.containerList.splice(i, 1);
      this.checkobjects();
    },
    clicsubmit() {
      if (this.$refs.form.validate()) {
        this.startloading = 1;
        let formData = new FormData(document.getElementById("sform"));
        formData.append("flagCreatedFE", 1);
        formData.append("xmbl", this.mbl);
        formData.append("xcustomer", this.customerdropdown.id);
        formData.append("xcontacts[]", this.timetopass());
        formData.append("xcarrier", this.CarrierModel.id);
        formData.append("xcarrier_name", this.CarrierModel.name);
        formData.append("xvessel", this.VesselModel);
        formData.append("xreferrence[]", this.timetopassreference());
        formData.append("xlocation_at", this.terminalAt.id);
        formData.append("xlocation_at_name", this.terminalAt.name);
        formData.append("xnote_mbl", this.notembl);
        formData.append("xcontainerList", JSON.stringify(this.containerList));
        formData.append("container_commodity", this.container_commodity);
        formData.append("consignee_id", this.selectedConsignee);
        this.filelist.map((file, key) => {
          formData.append(`files[${key}]`, file);
        });
        axios
          .post(`${poBaseUrl}/orderdelivery-save`, formData)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.startloading = 0;
            if (response) {
              this.DO_ID = response.DO_ID;
              this.shipmentIDS = response.shipment_id;
              this.containers_number = response.containers_number;
              this.countshipment = response.count_shipment;
              this.confirm_message = response.confirm_message;

              // var c = ( this.containerList.length > 1) ?
              //          ' Containers':
              //          ' Container';

              // var finaltext = 'DO ' + response.DO_ID +' Created and' + c;
              //this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">'+ finaltext +' Added!</span>')

              if (origin == "save") {
                this.formReset();
              } else {
                this.$refs["form"].resetValidation();

                this.dialogConfirm = true;
                //this.$router.push('/delivery-order/'+response.DO_ID)
                // show modal confirmation
                // then if hide redirect to index
                // if close redirect to index
                /*
                                    Delivery Order ID - 6789009
                
                                    4 Shipments created with the following details:

                                    Containers
                                    345345454,34534543,34534543,34543543
                                    
                                    IDs
                                    324324324,343243,2343243,32432
                                */
                //this.$router.push('/containers')
              }
            }
          });
      }
    },
    saveUpdated(item) {
      this.isErrorInlinEdit = 0;
      if (item.updated) {
        this.containerList = item.data;
      }

      this.containerList.map((v) => {
        const propertyValues = Object.values(v);
        const result = propertyValues.filter((word, index) => {
          if (index > 7) {
            return word;
          }
        });

        this.isErrorInlinEdit = result.reduce(
          (partialSum, a) => partialSum + a,
          0
        );
      });

      this.checkobjects();
    },
    checklengthdata(item) {
      if (item.value === 0) {
        this.containerList = [];
      }
      this.checkobjects();
    },
    emitedit(data) {
      this.showedit = data.show;
      this.keysvalue = data.data[data.index];
      this.keytovalue = data.index;
    },
    updateContainer(item) {
      this.containerList[this.keytovalue] = item;
      console.log(this.containerList);
      var sizesize = item.container_size;
      var getsize = this.ContainerSize.find((e) => {
        if (e.id == sizesize) {
          return e;
        }
      });

      item.container_size = getsize.id;

      //this.containerList.push(item);
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });

      this.checkobjects();
    },
    saveContainer(item) {
      var sizesize = item.container_size;
      var dt = item.delivery_to;
      var getsize = this.ContainerSize.find((e) => {
        if (e.id == sizesize) {
          return e;
        }
      });

      item.container_size = getsize.id;
      this.lastIndexData = dt;

      this.containerList.push(item);
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });
      //console.log(this.containerList)
      this.checkobjects();
    },
    cancel() {
      if (this.isMovedo) {
        this.$router.push(
          "/delivery-order/" +
            this.$route.params.id +
            "/" +
            this.$route.params.sid
        );
      } else {
        this.$router.push("/containers");
      }
    },
    formReset() {
      this.$router.push("/containers");
    },
    addContainer() {
      this.dialog = true;
    },
    dataDown(value) {
      this.formFieldlength++;
      this.currentDropdownState = value;
      this.currentSelectTab = value;
    },
    checkindex(index) {
      this.currentindexshow = index;
      jQuery(".tablecontainer").css({ background: "none" });
      jQuery(".blueteam_" + index).css({ background: "#F0FBFF" });
    },
    getPrelims: function (data) {
      let response = data;

      let customers = response.data.customers;

      let DataCustomer = [];
      customers.forEach((value) => {
        DataCustomer.push({ id: value.id, name: value.company_name });
      });

      this.itemsDropdown = DataCustomer;

      let terminal_at = response.data.terminal;
      terminal_at.forEach((value) => {
        this.TERMINAL_AT.push({ id: value.id, name: value.name });
      });

      let size = response.data.size;
      size.forEach((value) => {
        this.containerSize.push({ id: value.id, name: value.name });
      });

      let containertype = response.data.type;
      containertype.forEach((value) => {
        this.containerType.push({ id: value.id, name: value.name });
      });

      let truckersID = response.data.trucker;
      truckersID.forEach((value) => {
        this.truckers.push({ id: value.id, name: value.name });
      });
      this.startloading = 0;
    },

    onChange() {
      this.filelist = [...this.$refs.file.files];
      let table = this.filelist;
      let errorCount = 0;
      let keys = [];

      Object.keys(table).forEach(function eachKey(key) {
        if (table[key].type != "application/pdf") {
          keys.push(key);
          errorCount++;
        }
      });

      if (errorCount > 0) {
        this.errorFile = 1;
        this.allFieldsValid = false;
      } else {
        this.errorFile = 0;
        this.allFieldsValid = true;
      }

      keys.forEach((e) => {
        this.filelist.splice(e, keys.length + 1);
      });

      if (this.filelist.length > 1) {
        this.filelist.splice(1, this.filelist.length);
        this.moreFilesError = true;
      } else {
        this.moreFilesError = false;
      }
    },
    remove(i, e) {
      this.uploadTrigger = false;
      try {
        axios
          .delete(`${poBaseUrl}/upload/remove/` + e.id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.responseDatatempt = response;
          });

        this.filelist.splice(i, 1);
        if (this.filelist.length == 0) {
          this.errorFile = 0;
          this.allFieldsValid = true;
        }
      } catch (err) {
        document.querySelector(".fileuploaderror").innerHTML = err.message;
        setTimeout(() => {
          document.querySelector(".fileuploaderror").style.display = "none";
        }, 5000);
      }
    },
    gotoDownload() {
      let passdata = this.objectServerid.id;
      this.isFetching = 1;
      if (passdata !== "") {
        axios({
          url: `${poBaseUrl}/newshipment/download/${passdata}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          this.isFetching = 0;
          window.URL = window.URL || window.webkitURL;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" })
          );
          link.setAttribute("download", "Delivery_order.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    // async fetchdo(){
    //     var that = this;
    //     var validEmailAddress = [],ref = [],containers = [];
    //     await axios
    //         .get(`${poBaseUrl}/getdeliveryorderbyid/` + this.$route.params.id)
    //         .then(function (response) {
    //             if (response.data) {

    //                 that.mbl = response.data.data.mbl_number;
    //                 that.customerdropdown =  parseInt(response.data.data.customer);

    //                 var s = JSON.parse(response.data.data.contacts);
    //                 s.map(wea => {
    //                     if (wea!==null) {
    //                         validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
    //                     }
    //                 })

    //                 that.options = validEmailAddress
    //                 that.CarrierModel = response.data.data.carrier_id;

    //                 that.orderdeliveryID =  response.data.data.shifl_ref
    //                 that.VesselModel =  response.data.data.vessel
    //                 var refer = response.data.data.customer_ref_number
    //                 refer.map(wea => {
    //                     if (wea!==null) {
    //                         ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
    //                     }
    //                 })
    //                 that.optionsReferrence = ref
    //                 that.terminalAt =  (response.data.data.location_from)
    //                 that.container_commodity = response.data.data.container_commodity;
    //                 that.notembl = response.data.data.description;
    //                 var cont = JSON.parse(response.data.data.containers);
    //                 var getAllshipment = (response.data.shipment);

    //                 if(getAllshipment.length){
    //                     _.forEach(getAllshipment,function(value){
    //                         that.allshipmentedits.push(value);
    //                     });
    //                 }
    //                 console.log(that.allshipmentedits);
    //                 cont.map(wea => {
    //                     if (wea!==null) {
    //                         containers.push({
    //                             container_number: wea.Container,
    //                             container_size:wea.Sizes,
    //                             container_volume: wea.VolumeNumberofCBM,
    //                             container_weight: wea.WeightNumberofKG,
    //                             container_qty: wea.CartonCount,
    //                             delivery_to :  response.data.data.deliver_to ,
    //                             error_0:0,error_1:0,error_2:0,error_3:0,error_4:0
    //                         })
    //                     }
    //                 })

    //                 that.containerList = containers;
    //                 that.refresh = 0;

    //                 that.$nextTick(() => {
    //                     that.refresh = 1;
    //                 });
    //             }
    //         }).catch(error => {
    //             console.log(error)
    //         })
    // },
    async callUPdocument(data) {
      if (data) jQuery(".cDownloadfiles").hide();
      this.uploadCompleted = false;
      this.uploadTrigger = true;
      this.serverObject = [];
      var formData = new FormData();

      try {
        var pdfiles = document.getElementById("assetsFieldHandle");
        formData.append("fields[]", pdfiles.files[0]);
        formData.append("data[]", this.filelist);
        formData.append("isCreateShipment", "Yes");

        await axios
          .post(`${poBaseUrl}/upload_file`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .then((response) => {
            this.uploadCompleted = true;
            this.serverObject = response.data;
            this.objectServerid = response.data.find((d) => d.id != "");
          });
      } catch (err) {
        document.querySelector(".fileuploaderror").innerHTML = err.message;
        setTimeout(() => {
          document.querySelector(".fileuploaderror").style.display = "none";
        }, 5000);
      }
    },
    handleReplace() {
      jQuery(document).find(".triggerelement").trigger("click");
    },
    getFileURL(file) {
      return URL.createObjectURL(file);
    },
    // 
    async onSelectCustomer(selected) {
      if (selected !== null && selected !== '') {
        this.consigneeLists = []
        await this.fetchConsigneeAction(selected.id)
      }
    },
    async handleNewConsignee() {
			if(this.newConsignee === null || this.newConsignee === "") {
				this.notificationCustom("Please enter consignee name");
			} else {        
        let data = {
          name: this.newConsignee,
          customer_id: this.customerdropdown.id,
        };
				await this.createConsignee(data).then(async (res) => {
          if (res) {
            let data = res.data
            this.notificationMessage(res.message)
            this.selectedConsignee = data.id
            this.consigneeLists = []
            await this.fetchConsigneeAction(data.customer_id)
          }
        }) 
			}
		},
    async fetchConsigneeAction(id) {
        await this.fetchConsignees(id)
        if (typeof this.getAllConsignees !== 'undefined' && this.getAllConsignees !== null) {
            this.consigneeLists = this.getAllConsignees.results
        }
        this.$refs.consigneeRef.blur();
    }
  },
  async mounted() {
    if (typeof this.$route.params.moveid != "undefined") {
      //let id = this.$route.params.id
      //
      // query to server
      this.isMovedo = 1;
      //this.fetchdo(this.$route.params.id);
    }
    this.$store.dispatch("page/setPage", "delivery-order");
  },
  created() {
    this.eventBus.$on("changeIt", (data) => {
      this.showedit = data.show;
      this.keysvalue = data.data[data.index];
      this.keytovalue = data.index;
    });
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";
//  @import '../../../assets/scss/pages_scss/deliveryorder/listcontainer.scss';
@import "../../../assets/scss/buttons.scss";

.v-input {
    &.select-consignee.v-input--is-disabled {
        .v-input__control {
            .v-input__slot {
                background-color: #EBF2F5;
                border-color: #EBF2F5 !important;
            }
        }
    }
}

.v-menu__content {
  &.consignee-menu-lists {
    .v-list {
      padding-top: 2px;
      padding-bottom: 2px;
      .v-list-item {
        min-height: 42px;
        // border-bottom: 1px solid #EBF2F5;
      }
    }
  }
}
</style>
<style>
/* New style */
.topheader {
  padding-left: 19px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 4px;
}
.topheader li {
  color: #6d858f !important;
  display: inline-block;
  width: 19%;
  word-break: break-all;
  cursor: pointer;
  font-size: 14px;
}
.addContainer {
  border-bottom: 1px solid #eee;
  border-top: 0px solid #eee;
  font-size: 14px;
  margin-top: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}
.leftcol {
  border-right: 1px solid #eee;
}
.data-element .item {
  border-bottom: 0px solid #eee;
  padding: 6px;
}
.headerContainer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;

  color: #4a4a4a;
}
.tablecontainer {
  padding: 0 5px;
  border-bottom: 1px solid #ebf2f5;
  border-top: 1px solid #ebf2f5;
  font-size: 14px;
}
.tablecontainer:hover {
  background: rgb(240, 251, 255) none repeat scroll 0% 0% !important;
}
.tablecontainer li {
  display: inline-block;
  width: 19%;
  word-break: break-all;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 3px;
  height: 37px;
  color: #4a4a4a !important;
  padding-top: 6px;
  padding-bottom: 16px;
  margin-top: 3px;
}

.uploadDocuwrapper {
  margin-top: 7px !important;
  max-width: 68.7% !important;
}

.containers-wrappers {
  padding-top: 8px !important;
}

.container-create {
  padding-top: 0px !important;
}

.container-create .toolbHeader .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}

.toolbHeader {
  margin-bottom: 4px;
}

.container-create .toolbHeader {
  background: none !important;
}

.NewContainerShipmentTitle {
  font-size: 24px;
  font-weight: 600;
}

.deliveryOrderColumn {
  background-color: #fff;
  padding: 0px;
  width: 100%;
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 15px;
}

.vue-tags-input {
  max-width: 100% !important;
}

.ti-new-tag-input {
  caret-color: #000 !important;
}

.ti-tags .ti-tag {
  background: #f7f7f7;
  color: #4a4a4a;
}

div.ti-input {
  border-radius: 4px;
  border: 1px solid #b4cfe0 !important;
}

.uploadElement .hasContentFiles2 {
  background: #fff !important;
  padding: 6px !important;
  display: block !important;
  height: 106px !important;
  border: 2px dashed #b4cfe0 !important;
  border-radius: 5px;
}

.browserInput2 {
  width: 100%;
}

.browseFileDelivery {
  width: 100% !important;
  text-align: center;
  color: #b4cfe0 !important;
  padding-top: 12px;
  font-size: 14px !important;
}

.browseButton2 {
  width: 79px;
  font-size: 12px;
  margin: 0 auto;
  margin-top: 6px;
}

.wrappIconbutton2 {
  border: 1px solid #b4cfe0;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 5px;
  margin-top: 21px;
}
.classUpload {
  font-size: 12px;
  color: #0171a1;
  position: relative;
  left: 6px;
}

/* .tablecontainer li:hover {
    background: #fff;
} */

.ic-chevron-down {
  font-size: 16px !important;
}

.iconUploadfile {
  color: #0171a1;
}

.rowItemUploaded {
  background: #f7f7f7 !important;
  border-radius: 6px;
  padding: 10px;
  height: 64px;
}

.mainwrapitem {
  border: 0px !important;
}

.uploadCreateNew {
  position: relative;
  top: 3px;
  left: -1px;
  height: 15px;
}

.hidethisdiv {
  display: none !important;
}

.reupload {
  float: right;
  font-size: 14px !important;
  color: rgb(1, 113, 161);
  cursor: pointer;
  position: relative;
  top: 5px;
  left: 0px;
  margin-top: 3px;
  margin-left: 0px;
  margin-right: 1px;
}
.ic-calendar::before {
  font-size: 16px !important;
}

.lastchild:hover {
  background: none !important;
}

.document-btn {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #b4cfe0;
  border-radius: 4px;
}

.replace-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0171a1;
}

.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}

.startingDOSclass .v-menu__content {
  max-height:200px !important;
}
</style>
