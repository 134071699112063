import { render, staticRenderFns } from "./EditLeg.vue?vue&type=template&id=10cd8700&"
import script from "./EditLeg.vue?vue&type=script&lang=js&"
export * from "./EditLeg.vue?vue&type=script&lang=js&"
import style0 from "./EditLeg.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./EditLeg.vue?vue&type=style&index=1&lang=css&"
import style2 from "./EditLeg.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VDialog,VForm,VIcon,VTextarea})
