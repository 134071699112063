var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"persistent":"","max-width":"725px","content-class":"add-customer-dialog manage-report","retain-focus":false,"scrollable":""},model:{value:(_vm.dialogData),callback:function ($$v) {_vm.dialogData=$$v},expression:"dialogData"}},[_c('v-card',{staticClass:"add-supplier-card"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Manage Reports Schedule")]),_c('div',{staticClass:"toggleSwitch d-flex justify-end align-center"},[_c('v-switch',{attrs:{"inset":"","ripple":false,"hide-details":"auto"},on:{"change":function($event){return _vm.getchange($event)}},model:{value:(_vm.toggleSwitchValue),callback:function ($$v) {_vm.toggleSwitchValue=$$v},expression:"toggleSwitchValue"}}),_c('button',{staticClass:"btn-close ml-2",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',{staticClass:"cardwrapper",staticStyle:{"min-height":"170px"}},[_c('v-form',{ref:"form",attrs:{"id":"createCustomer","action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"frequencycolumn  mb-0\n                        pb-0",attrs:{"sm":"4","md":"4","cols":_vm.call_frequency}},[_c('label',{staticClass:"lavel manageLabel"},[_vm._v("Frequency")]),_c('v-autocomplete',{staticClass:"dropdownstatus",attrs:{"items":_vm.dropdowndrayage,"color":"blue","item-text":"name","item-value":"id","size":14,"width":"334px","hide-details":"auto","append-icon":"ic-chevron-down","height":"40px","disabled":!_vm.toggleSwitchValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(typeof item == 'object')?[(_vm.checkifselected(item))?_c('div',{staticClass:"listDropdown selectedLisddown",staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#4A4A4A","background":"#F0FBFF !important"}},[_vm._v(" "+_vm._s(item.name)+" ")])]):_c('div',{staticClass:"listDropdown",staticStyle:{"font-size":"14px","color":"#4A4A4A"}},[_vm._v(" "+_vm._s(item.name)+" ")])]:_vm._e()]}}]),model:{value:(_vm.initialfrequencymodel),callback:function ($$v) {_vm.initialfrequencymodel=$$v},expression:"initialfrequencymodel"}})],1),(_vm.byday)?_c('v-col',{staticClass:"time  mb-0 pb-0",attrs:{"sm":"4","cols":_vm.call_class}},[_c('label',{staticClass:"lavel manageLabel"},[_vm._v("Day")]),_c('v-autocomplete',{staticClass:"dropdownstatus",attrs:{"items":_vm.daily,"rules":_vm.dayRules,"color":"blue","item-text":"name","item-value":"name","placeholder":"Pick a day","font-size":"14px","width":"334px","hide-details":"auto","append-icon":"ic-chevron-down","height":"40px","disabled":!_vm.toggleSwitchValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(typeof item == 'object')?[(_vm.checkifselected(item))?_c('div',{staticClass:"listDropdown",staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#4A4A4A","background":"#F0FBFF !important"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('img',{staticClass:"checkdropdown",staticStyle:{"width":"14px"},attrs:{"src":require("@/assets/icons/arrow_down.svg")}})]):_c('div',{staticClass:"listDropdown",staticStyle:{"font-size":"14px","color":"#4A4A4A"}},[_vm._v(" "+_vm._s(item.name)+" ")])]:_vm._e()]}}],null,false,2752681068),model:{value:(_vm.dayselected),callback:function ($$v) {_vm.dayselected=$$v},expression:"dayselected"}})],1):_vm._e(),(_vm.monthly)?_c('v-col',{staticClass:"time  mb-0 pb-0",attrs:{"sm":"4","cols":_vm.call_class}},[_c('label',{staticClass:"lavel manageLabel"},[_vm._v("Day Monthly")]),_c('v-autocomplete',{staticClass:"dropdownstatus",attrs:{"items":_vm.selecteDay,"rules":_vm.dayRules,"color":"blue","item-text":"name","item-value":"name","placeholder":"Select the day","font-size":"14px","width":"334px","hide-details":"auto","append-icon":"ic-chevron-down","height":"40px","disabled":!_vm.toggleSwitchValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(typeof item == 'object')?[(_vm.checkifselected(item))?_c('div',{staticClass:"listDropdown",staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#4A4A4A","background":"#F0FBFF !important"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('img',{staticClass:"checkdropdown",staticStyle:{"width":"14px"},attrs:{"src":require("@/assets/icons/arrow_down.svg")}})]):_c('div',{staticClass:"listDropdown",staticStyle:{"font-size":"14px","color":"#4A4A4A"}},[_vm._v(" "+_vm._s(item.name)+" ")])]:_vm._e()]}}],null,false,2752681068),model:{value:(_vm.monthlydayselected),callback:function ($$v) {_vm.monthlydayselected=$$v},expression:"monthlydayselected"}})],1):_vm._e(),_c('v-col',{staticClass:"time  mb-0\n                        pb-0",attrs:{"cols":_vm.call_frequency,"sm":"4","md":"4"}},[_c('label',{staticClass:"lavel manageLabel"},[_vm._v("Time")]),_c('v-autocomplete',{staticClass:"dropdownstatus timedropdown",attrs:{"items":_vm.countstep,"color":"blue","rules":_vm.timeRules,"item-text":"name","item-value":"name","placeholder":"hh:mm","font-size":"14px","width":"334px","hide-details":"auto","height":"40px","disabled":!_vm.toggleSwitchValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(typeof item == 'object')?[(_vm.checkifselected(item))?_c('div',{staticClass:"listDropdown",staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#4A4A4A","background":"#F0FBFF !important"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('img',{staticClass:"checkdropdown",staticStyle:{"width":"14px"},attrs:{"src":require("@/assets/icons/arrow_down.svg")}})]):_c('div',{staticClass:"listDropdown",staticStyle:{"font-size":"14px","color":"#4A4A4A"}},[_vm._v(" "+_vm._s(item.name)+" ")])]:_vm._e()]}}]),model:{value:(_vm.timeselected),callback:function ($$v) {_vm.timeselected=$$v},expression:"timeselected"}})],1)],1),_c('v-row',{staticClass:"sendcopy manage mt-0"},[_c('v-col',{staticClass:"pt-0 pb-0 "},[_c('v-checkbox',{staticClass:"pt-0 pb-0 v-labelsendcopy shrink",staticStyle:{"color":"#4A4A4A"},attrs:{"label":"Send me the copy","disabled":!_vm.toggleSwitchValue,"hide-details":"auto"},model:{value:(_vm.getsendcopy),callback:function ($$v) {_vm.getsendcopy=$$v},expression:"getsendcopy"}})],1)],1)],1)],1),_c('v-card-actions',{staticStyle:{"border-top":"2px solid #EBF2F5 !important"}},[_c('div',{staticClass:"buttonActions d-flex justify-start align-center pr-0",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"btn-blue",staticStyle:{"caret-color":"transparent"},attrs:{"text":""},on:{"click":_vm.save}},[_c('span',[_c('span',{staticStyle:{"caret-color":"transparent"}},[(_vm.isFetching)?_c('v-progress-circular',{staticStyle:{"margin-right":"3px"},attrs:{"size":15,"color":"#fff","width":2,"indeterminate":""}}):_vm._e(),_vm._v(" Save Settings ")],1)])]),(!_vm.isMobile)?_c('v-btn',{staticClass:"btn-white",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }