<template>
    <v-dialog 
        persistent
        v-model="dialog"
        max-width="480px" 
        content-class="required-dialog dialogYard"  
        :retain-focus="false"
        >
        <v-card
          style="padding-bottom:8px">
          <v-card-text
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >   
            
            <svg 
                v-if="movetype==3 || movetype==5" 
                width="44" 
                height="44" 
                viewBox="0 0 44 44" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M22.0004 43.6004C33.9297 43.6004 43.6004 33.9297 43.6004 22.0004C43.6004 10.071 33.9297 0.400391 22.0004 0.400391C10.071 0.400391 0.400391 10.071 0.400391 22.0004C0.400391 33.9297 10.071 43.6004 22.0004 43.6004ZM22.0004 40.3609C11.8602 40.3609 3.63989 32.1406 3.63989 22.0004C3.63989 11.8602 11.8602 3.63989 22.0004 3.63989C32.1406 3.63989 40.3609 11.8602 40.3609 22.0004C40.3609 32.1406 32.1406 40.3609 22.0004 40.3609ZM21.9925 25.242C22.8125 25.2425 23.4906 24.6336 23.5984 23.8431L23.6133 23.6233L23.6211 11.7417C23.6217 10.8471 22.897 10.1215 22.0024 10.1209C21.1824 10.1203 20.5043 10.7293 20.3965 11.5198L20.3816 11.7396L20.3738 23.6212C20.3732 24.5157 21.098 25.2414 21.9925 25.242ZM22.0014 32.796C23.1925 32.796 24.1581 31.8304 24.1581 30.6392C24.1581 29.448 23.1925 28.4824 22.0014 28.4824C20.8102 28.4824 19.8446 29.448 19.8446 30.6392C19.8446 31.8304 20.8102 32.796 22.0014 32.796Z" fill="#D68A1A"/>
            </svg>        
            <svg 
                v-else 
                width="44" 
                height="44" 
                viewBox="0 0 44 44" 
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M21.9999 0.400391C33.9292 0.400391 43.5999 10.071 43.5999 22.0004C43.5999 33.9297 33.9292 43.6004 21.9999 43.6004C10.0706 43.6004 0.399902 33.9297 0.399902 22.0004C0.399902 10.071 10.0706 0.400391 21.9999 0.400391ZM21.9999 3.63989C11.8597 3.63989 3.6394 11.8602 3.6394 22.0004C3.6394 32.1406 11.8597 40.3609 21.9999 40.3609C32.1401 40.3609 40.3604 32.1406 40.3604 22.0004C40.3604 11.8602 32.1401 3.63989 21.9999 3.63989ZM21.992 18.7588C22.812 18.7583 23.4901 19.3672 23.5979 20.1577L23.6128 20.3775L23.6206 32.2591C23.6212 33.1537 22.8965 33.8793 22.0019 33.8799C21.1819 33.8804 20.5038 33.2715 20.396 32.481L20.3811 32.2612L20.3733 20.3796C20.3728 19.4851 21.0975 18.7594 21.992 18.7588ZM22.0009 11.2048C23.192 11.2048 24.1576 12.1704 24.1576 13.3616C24.1576 14.5527 23.192 15.5184 22.0009 15.5184C20.8097 15.5184 19.8441 14.5527 19.8441 13.3616C19.8441 12.1704 20.8097 11.2048 22.0009 11.2048Z" fill="#0171A1"/>
            </svg>

            <h2 
                v-if="movetype==3"
                class="required_header"
                style="">Cannot Move All Container</h2>

            <h2 
                v-else-if="movetype==4"
                class="required_header"
                style="">Moving not allowed. Save the changes.</h2>

            <h2 
                v-else
                class="required_header"
                style="">Cannot Move All Container</h2>

            <p
                v-if="movetype==3"
                style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                     You are not allowed to move all containers to a different DO.
                     Should have at least one unselected container.
            </p>
            <p
                v-else-if="movetype==5"
                style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                     You are not allowed to move containers.
                     Check the required fields in the container information.
            </p>
            <p 
                v-else
                style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                Save first the changes before moving DO to another.
            </p>
            
          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="continueDialog" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Understood 
                    </span>
                </span>
            </v-btn>                  
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Notallowed',
    data(){
      return {
            colorstrong:'#55a1c1',
            colorcontainers:'#4A4A4A',
            errotype:0,
        }
    },
    props: ['dialogData','movetype'], 
    computed: { 
        dialog: {
            get () {   
                //alert(this.movetype)          
                return this.dialogData
            },
            set(value) {
                if (!value) {
                    this.$emit("update:dialogData",0);
                } else {
                    this.$emit("update:dialogData",1);
                }
            },        
        },                       
    },
    watch:{  

    },
    methods: {    
        getmessage(){
          return 'You are not allowed to move all containers to a different DO.'+
                 'Check Container Information required fields.';
        },  
        continueDialog() {             
          this.$emit("close",1); 
        }        
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.required-dialog {
    .required_header {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 17px 0 9px;
    }

    p, li {
        color: #4a4a4a;
        font-family: "Inter-Regular", sans-serif;
    }
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>