<template>
      <div>
        <v-dialog
            v-model="openDialogCom_review"
            fullscreen
            persistent
            scrollable
            no-click-animation
            content-class="dialogClassDos create-do popupdialogDos">

            <v-card>
               <!-- <v-toolbar
                    dark
                    color="white"
                    class="px-4"
                    style="padding-left:5px !important;"
                >
                    <v-toolbar-title class="reviewHeader-text" style="color: black;margin-left:0px;">Review Delivery Order #{{ item.shifl_ref }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            dark
                            @click="closedialog()"
                            >
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar> -->

                <v-card-title>
					<span class="headline">Review Delivery Order #{{ item.shifl_ref }}</span>
					<button icon dark class="btn-close" @click="closedialog">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>

                <v-card-text class="pa-0">
                    <div class="container-create pb-0">
                        <!-- <div v-if="startfetching" class="no-data-preloader mt-4"
                        style="margin: 0 auto;
                            margin-top: 0px;width: 100%;position: absolute;
                            top: 126px;text-align: center;"
                        >
                        <v-progress-circular :size="40" color="#0171a1" indeterminate>
                        </v-progress-circular>
                        </div> -->

                        <div class="loading-wrapper-do" v-if="startfetching">
                            <v-progress-circular
                                :size="35"
                                color="#0171A1"
                                indeterminate
                                v-if="startfetching">
                            </v-progress-circular>
                        </div>

                        <v-progress-circular
                            :size="15"
                            color="#fff"
                            :width="2"
                            indeterminate
                            v-if="startloading"
                            style="margin-right:3px">
                        </v-progress-circular>

                        <v-form
                            class="sFormClass"
                            style="padding:0px !important; width:100%"
                            id="sform"
                            ref="form"
                            v-model="valid"
                            @submit.prevent="submit">

                            <div class="d-flex">
                                <div class="leftcol" style="
									width: 790px !important;
									min-width: 650px !important;
									max-width: 790px !important;
									padding: 16px 20px !important;
									border-right: 1px solid #D8E7F0 !important;
                                ">
                                    <!-- <v-row>
                                        <v-col
                                        class="etacol pb-0 mt-0 pt-0"
                                        >
                                        <div style="font-style: normal;
                                                    font-weight: 600;
                                                    font-size: 16px;
                                                    margin-bottom:5px;
                                                    margin-top:1px;
                                                    color: #4A4A4A;">
                                                    General
                                        </div>
                                        </v-col>
                                    </v-row> -->

                                    <!-- <div class="row">
                                        <div class="col-md-12 pb-6">
                                            <label class="text-item-label">Mode</label>
                                            <div class="row">
                                                <div class="col-md-2 pr-0">
                                                    <div class="radio-box pl-5">
                                                    <input type="radio" v-model="themode" :checked="getcheckvalue('ocean')" value="ocean" name="radio" class="radio-wrap"/>
                                                    <div class="control__indicator"></div>
                                                    <span class="ml-7  radio-text">
                                                        <label class="ml-2">Ocean</label>
                                                        <span class="radio-icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80651 3.75H3.26319C2.98704 3.75 2.76319 3.97386 2.76319 4.25V5.45587C2.76319 5.73201 2.98704 5.95587 3.26319 5.95587H8.80651C9.08265 5.95587 9.30651 5.73201 9.30651 5.45587V4.25C9.30651 3.97386 9.08265 3.75 8.80651 3.75ZM10.7214 7.92646C10.7214 7.65031 10.4976 7.42646 10.2214 7.42646H1.80902C1.53287 7.42646 1.30902 7.65031 1.30902 7.92646V9.13233C1.30902 9.40847 1.53287 9.63233 1.80902 9.63233H10.2214C10.4976 9.63233 10.7214 9.40847 10.7214 9.13233V7.92646ZM14.6855 8.36547L12.5771 11.0675H0.500262C0.173128 11.0675 -0.0658836 11.3764 0.0162948 11.6931L1.10178 15.8756C1.159 16.0961 1.35798 16.25 1.58575 16.25H17.024C17.2349 16.25 17.4231 16.1177 17.4945 15.9192L19.9703 9.03467C20.0874 8.70887 19.846 8.36547 19.4998 8.36547H14.6855ZM4.21725 13.3089C4.21725 13.715 3.89175 14.0442 3.49021 14.0442C3.08868 14.0442 2.76318 13.715 2.76318 13.3089C2.76318 12.9028 3.08868 12.5736 3.49021 12.5736C3.89175 12.5736 4.21725 12.9028 4.21725 13.3089ZM7.85264 14.0442C8.25417 14.0442 8.57968 13.715 8.57968 13.3089C8.57968 12.9028 8.25417 12.5736 7.85264 12.5736C7.45111 12.5736 7.12561 12.9028 7.12561 13.3089C7.12561 13.715 7.45111 14.0442 7.85264 14.0442ZM12.9417 13.3089C12.9417 13.715 12.6162 14.0442 12.2147 14.0442C11.8132 14.0442 11.4877 13.715 11.4877 13.3089C11.4877 12.9028 11.8132 12.5736 12.2147 12.5736C12.6162 12.5736 12.9417 12.9028 12.9417 13.3089Z" fill="#6D858F"/>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 ml-12">
                                                <div class="radio-box pl-6">
                                                    <input type="radio" v-model="themode" :checked="getcheckvalue('air')" value="air" name="radio" class="radio-wrap"/>
                                                    <div class="control__indicator"></div>
                                                    <span class="ml-9  radio-text">
                                                        <label class="ml-2">Air</label>
                                                        <span class="radio-icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.0265 7.23006L12.5327 7.58131L8.76828 1.54844C8.65978 1.38176 8.51326 1.24471 8.34142 1.14914C8.16957 1.05358 7.97758 1.00238 7.78208 1H6.57939C6.39282 1.00521 6.20999 1.05484 6.04537 1.14497C5.88076 1.2351 5.73887 1.36326 5.63094 1.5193C5.52301 1.67533 5.452 1.85497 5.42353 2.04399C5.39506 2.23301 5.40991 2.42623 5.4669 2.60835L7.38519 8.1051L3.96955 8.45019C1.81073 6.22561 0.818508 7.03903 1.02898 7.77234C1.84681 10.57 1.84681 9.43615 1.02898 12.2338C0.812495 12.9795 1.81073 13.7744 3.96354 11.5683L7.37918 11.9134L5.4669 17.3978C5.41116 17.5791 5.39708 17.7711 5.42576 17.9589C5.45445 18.1466 5.52512 18.3251 5.63226 18.4802C5.73939 18.6353 5.88009 18.763 6.04335 18.8532C6.20662 18.9433 6.38801 18.9936 6.57337 19H7.77606C7.97157 18.9976 8.16356 18.9464 8.33541 18.8509C8.50725 18.7553 8.65377 18.6182 8.76227 18.4516L12.5327 12.468L16.0265 12.7761C16.4033 12.8125 16.7834 12.768 17.1424 12.6452C17.5014 12.5224 17.8314 12.3241 18.1114 12.0631C18.3913 11.802 18.6149 11.484 18.7679 11.1292C18.921 10.7745 19 10.3909 19 10.0031C19 9.61524 18.921 9.23167 18.7679 8.87693C18.6149 8.5222 18.3913 8.20413 18.1114 7.94308C17.8314 7.68204 17.5014 7.48378 17.1424 7.36099C16.7834 7.23821 16.4033 7.19361 16.0265 7.23006Z" fill="#6D858F"/>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>   -->

                                    <v-row>
                                        <v-col
                                            class="etacol pb-0 mt-0"
                                            cols="6"
                                            md="6">
                                            <label class="text-item-label" for="">
                                            ETA
                                            </label>

                                            <!-- <DxDateBox
                                                placeholder="Select ETA"
                                                class="create-do-eta-dxdate mt-1"
                                                v-model="date"
                                                name="ETA"
                                                type="date"
                                                :openOnFieldClick="true"
                                                @value-changed="handleETAValueChange"
                                                :use-mask-behavior="true"
                                                display-format="yyyy-MM-dd"/>

                                                <p class="mt-1 mb-0" v-if="isShowETAError"
                                                style="color: #ff5252; font-size: 12px; font-family: 'Inter-Regular', sans-serif;">
                                                    ETA is required
                                                </p> -->

                                            <div class="vc-date-picker__wrapper">
                                                <div class="vc-datepicker-with-clear">
                                                    <vc-date-picker
                                                        title-position="left"
                                                        is-expanded
                                                        v-model="date"
                                                        mode="date"
                                                        :popover="{ visibility: 'focus' }"
                                                        :firstDayOfWeek="2"
                                                        :masks="masks"
                                                        :select-attribute="attribute"
                                                        @input="(e) => checkErrorShowing(e, 'eta')"
                                                        ref="eta_ref_do"
                                                        :is-required="isDateRequired">

                                                        <template #default="{ inputValue, inputEvents }">
                                                            <input
                                                                :value="inputValue"
                                                                v-on="inputEvents"
                                                                class="create-do-eta-dxdate-vc"
                                                                placeholder="Select ETA"
                                                                @keyup="(e) => checkKeyUpVal(e)"
                                                            />
                                                        </template>
                                                    </vc-date-picker>

                                                    <button type="button" class="vc-date-clear-button" @click="clearDate" 
                                                        v-if="date !== null">
                                                        <v-icon size="18px" color="#F93131">mdi-close</v-icon>
                                                    </button>
                                                </div>

                                                <p class="p-form-errors" v-if="isShowETAError">
                                                    ETA is required
                                                </p>
                                            </div>

                                            <!-- <v-text-field
                                                v-model="date"
                                                placeholder="05/21/2021"
                                                append-icon="ic-calendar"
                                                :rules="checkvalidtime"
                                                name="ETA"
                                                @click:append="showcustom(date,'date')"
                                                @input="blurevent()"
                                                hide-details="auto"
                                                >
                                            </v-text-field> -->
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            md="6"
                                            class="pb-0 mt-0"
                                            >
                                            <label class="text-item-label" for="">
                                            MBL #
                                            </label>
                                            <v-text-field
                                                placeholder="Enter MBL #"
                                                required
                                                v-model="mbl"
                                                name="mbl_num"
                                                hide-details="auto"
                                                @keyup="blurevent()"
                                                @input="(e) => checkErrorShowing(e, 'mbl')"
                                                >
                                            </v-text-field>

                                            <p class="p-form-errors" v-if="isShowMBLError">
                                                MBL is required
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            md="6"
                                            class="selectBox"

                                            >
                                            <label class="text-item-label" for="">
                                                Carrier
                                            </label>
                                            <v-autocomplete
                                                v-model="CarrierModel"
                                                :items="carrierDropdown"
                                                item-text='name'
                                                item-value='id'
                                                placeholder="Select Carrier"
                                                name="carrier_id"
                                                solo
                                                @mousedown="dataDown('CARRIER')"
                                                @keyup="blurevent()"
                                                append-icon="ic-chevron-down"
                                                style="margin-top:4px;"
                                                hide-details="auto"
                                                attach
                                                >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            md="6"
                                            >
                                            <label class="text-item-label" for="">
                                                Vessel
                                            </label>
                                            <v-text-field
                                                placeholder="Vessel"
                                                required
                                                v-model="VesselModel"

                                                name="vessel_name"
                                                hide-details="auto"
                                                @keyup="blurevent()"
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="selectBox pt-0"
                                            cols="12"
                                            sm="12"
                                            >
                                            <label class="text-item-label" for="">
                                            CUSTOMER NAME
                                            </label>
                                            <v-autocomplete
                                                v-if="iscustomer"
                                                v-model="customerdropdown"
                                                :items="itemsDropdownComputed"
                                                item-text= 'company_name'
                                                item-value='id'
                                                :placeholder="select_company"
                                                solo
                                                append-icon="ic-chevron-down"
                                                class="customerDropdown mt-1"
                                                :search-input.sync="getSearchCompany"
                                                hide-details="auto"
                                                attach
                                                @input="(e) => checkErrorShowing(e, 'customer_name')"
                                                @change="onSelectCustomer(customerdropdown)"
                                                >
                                                    <!--
                                                        <template slot="item" slot-scope="{ item }">
                                                            <template v-if="typeof item == 'object'">
                                                                <div @click="createcustomer(item.company_name)"
                                                                    class="listDropdown"
                                                                    style="width:100%"
                                                                    v-if="item.id===999999">
                                                                    <span style="font-size:15px;">
                                                                        <span style="color:#b3c7d3">{{ item.company_name }} </span>
                                                                        <span class="createClass">Create Customer</span>
                                                                    </span>
                                                                </div>
                                                                <div class="listDropdown" v-else>
                                                                {{ item.company_name }}
                                                                </div>
                                                            </template>
                                                        </template>
                                                    -->
                                                <div slot="no-data"></div>
                                                <div style="font-size:14px;"
                                                    v-if="hasNewChassis"
                                                    class="v-list-item v-list-item__title add-option-btn"
                                                    slot="append-item"
                                                    @click="createcustomer()"
                                                    >
                                                    Add New
                                                    </div>
                                            </v-autocomplete>

                                            <p class="p-form-errors" v-if="isShowCustomerError">
                                                Customer is required
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-4">
										<v-col class="selectBox pt-0" cols="12" sm="12">
                                            <label class="text-item-label" for="">
                                                CONSIGNEE
                                            </label>

                                            <v-autocomplete
                                                ref="consigneeRef"
                                                class="select-consignee"
                                                v-model="selectedConsignee"
                                                :items="consigneeLists"
                                                item-text="name"
                                                item-value="id"
                                                :placeholder="getAllConsigneesLoading ? 'Fetching consignees...' : 'Select Consignee'"
                                                solo
                                                append-icon="ic-chevron-down"
                                                :search-input.sync="searchedConsignee"
                                                :menu-props="{ contentClass: 'consignee-menu-lists'}"
                                                :disabled="getCreateConsigneeLoading || getAllConsigneesLoading"
                                                attach
                                                hide-details="auto">

                                                <div slot="no-data">
                                                    <p class="px-4 py-3 mb-0" style="font-size: 14px;" v-if="!hasNewConsignee || consigneeLists.length === 0">
                                                        No available data
                                                    </p>
                                                </div>

                                                <template slot="item" slot-scope="{ item }">
                                                    <v-list-item-content>
                                                        <p class="consignee-name mb-0" style="font-size: 12px; color: #4a4a4a;">{{item.name}}</p>
                                                    </v-list-item-content>
                                                </template>

                                                <div class="v-list-item v-list-item__title add-option-btn px-3 py-1" 
                                                    style="min-height: 35px; border-top: 1px solid #EBF2F5 !important;"
                                                    slot="append-item" @click="handleNewConsignee" 
                                                    v-if="hasNewConsignee && searchedConsignee !== '' && (customerdropdown !== null && customerdropdown !== '')">
                                                    <span style="font-size: 14px; color: #0171A1;">
                                                        {{ getCreateConsigneeLoading ? 'Adding...' : '+ Add New'}}
                                                    <span v-if="searchedConsignee" style="color: #4a4a4a;">- {{ searchedConsignee }}</span>
                                                    </span>
                                                </div>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="selectBox pt-1"
                                            cols="12"
                                            md="12"
                                            >
                                        <label
                                            class="text-item-label">
                                            Customer Contacts
                                        </label>

                                        <div
                                            class="tags-email-wrapper">
                                            <vue-tags-input
                                                hide-details="auto"
                                                :rules="arrayNotEmptyRules"
                                                :tags="options"
                                                :add-on-blur=true
                                                :class="myclass"
                                                class="DialogInputfield"
                                                :add-on-key="[13, ',']"
                                                :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                                                v-model="warehouseEmailAddress"
                                                placeholder="Enter email address"

                                                @tags-changed="newTags => {
                                                    this.myclass = 'noclass';
                                                    this.options = newTags
                                                    this.tagsInput.touched = true
                                                    this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                                    let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                                    if (typeof el!=='udnefined') {
                                                        /*if (this.tagsInput.hasError)
                                                            el.classList.add('ti-new-tag-input-error')
                                                        else
                                                            el.classList.remove('ti-new-tag-input-error')*/
                                                    }
                                                }"
                                                />
                                        </div>

                                        <div
                                            v-if="tagsInput.touched"
                                            class="v-text-field__details">
                                            <div
                                                class="v-messages theme--light error--text"
                                                role="alert">
                                                <div
                                                    class="v-messages__wrapper">
                                                    <div
                                                        v-if="(options.length > 0) && warehouseEmailAddress!==''"
                                                        class="v-messages__message">
                                                        {{
                                                            tagsInput.errorMessage
                                                        }}
                                                    </div>

                                                    <div
                                                        v-if="options.length == 0 && warehouseEmailAddress!==''"
                                                        class="v-messages__message">
                                                        {{
                                                            tagsInput.errorMessage
                                                        }}
                                                    </div>
                                                    <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                        Please provide at least 1 valid email address.
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <span
                                            style="color: #819FB2; font-size: 12px;">
                                            <i style="font-size:12px;color:#819FB2">
                                                Separate multiple email addresses with comma
                                            </i>
                                        </span>
                                    </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="selectBox pt-1"
                                            cols="12"
                                            md="12"
                                            >
                                        <label
                                            class="text-item-label">
                                            Customer Reference #
                                        </label>

                                        <div
                                            class="tags-email-wrapper">
                                            <vue-tags-input
                                                hide-details="auto"
                                                :rules="arrayNotEmptyRulesRefference"
                                                :tags="optionsReferrence"
                                                :add-on-blur=true
                                                :class="myclass2"
                                                class="DialogInputfield"
                                                :add-on-key="[13, ',']"
                                                :validation="myclass.length > 0 ? tagsValidation2 : tagsValidation2"
                                                v-model="customerReferrenceNumber"
                                                placeholder="Enter reference #"
                                                @tags-changed="newTags => {
                                                    this.myclass2 = 'noclass';
                                                    this.optionsReferrence = newTags
                                                    this.tagsInput2.touched = true
                                                    this.tagsInput2.hasError = (this.optionsReferrence.length > 0) ? false : true
                                                    let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                                }"
                                                />
                                        </div>

                                        <div
                                            v-if="tagsInput2.touched"
                                            class="v-text-field__details">
                                            <div
                                                class="v-messages theme--light error--text"
                                                role="alert">
                                                <div
                                                    class="v-messages__wrapper">
                                                    <div
                                                        v-if="(optionsReferrence.length > 0) && customerReferrenceNumber!==''"
                                                        class="v-messages__message">
                                                        {{
                                                            tagsInput2.errorMessage
                                                        }}
                                                    </div>

                                                    <div
                                                        v-if="optionsReferrence.length == 0 && customerReferrenceNumber!==''"
                                                        class="v-messages__message">
                                                        {{
                                                            tagsInput2.errorMessage
                                                        }}
                                                    </div>
                                                    <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                        Please provide at least 1 valid email address.
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <span
                                            style="color: #819FB2; font-size: 12px;">
                                            <i style="font-size:12px;color:#819FB2">
                                                Separate multiple referrence # with comma
                                            </i>
                                        </span>
                                    </v-col>
                                    </v-row>

                                    <v-row class="row-locations rowRemovemargin mt-0">
                                        <div class="col-xs-6 col-md-6 pb-0 box">
                                            <label class="text-item-label" for="">
                                            Container Starting Location
                                            </label>
                                            <!-- <v-autocomplete
                                                v-model="pickup_location"
                                                item-text= 'name'
                                                item-value='id'
                                                placeholder=""
                                                solo
                                                append-icon="ic-chevron-down"
                                                style="height:41px;"
                                                >
                                            </v-autocomplete>                -->
                                            <!-- <v-autocomplete
                                                v-model="terminalAt"
                                                :items="theterminals"
                                                placeholder="Terminal"
                                                item-text="name"
                                                item-value="id"
                                                no-filter
                                                clearable
                                                hide-no-data
                                                :rules="rules"
                                                filled
                                                full-width
                                                class="Goodsdeliverylocation"
                                                name="custom_notes"
                                            ></v-autocomplete> -->
                                            <v-autocomplete
                                                v-model="terminalAt"
                                                :items="theterminals"
                                                placeholder="Terminal"
                                                item-text="name"
                                                item-value="id"
                                                class="Goodsdeliverylocation container-starting-location mt-0"
                                                name="custom_notes"
                                                return-object
                                                hide-details="auto"
                                                :filter="onFilter"
                                                attach>
                                                <!-- :rules="TerminalAtRules" -->

                                                <template slot="selection" slot-scope="{ item }">
                                                    <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                                                        {{ item.firms_code }} | {{ item.name }} | {{ item.map_location }}
                                                    </div>
                                                </template>
                                                
                                                <template slot="item" slot-scope="{ item }">
                                                    <template v-if="typeof item == 'object'">                            
                                                        <div class="listDropdown" style="font-size:12px">
                                                            {{ item.firms_code }} <span class="strtinglocationh"></span> 
                                                            {{ item.name }} <span class="strtinglocationh"></span>
                                                            {{ item.map_location }}
                                                        </div>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </div>
                                        <div class="col-xs-6 col-md-6 pb-0 box">
                                            <label class="text-item-label">Goods Delivery Location</label>
                                            <!-- <textarea
                                                disabled
                                                rows="5"
                                                class="textarea-field"
                                                placeholder="Goods Delivery Location"
                                                style="height: 102px; width: 100%;"
                                                v-model="gooodsdeliverylocation"
                                            >
                                            </textarea> -->
                                            <v-autocomplete
                                                :loading="loadingLocationPlaces"
                                                v-model="gooodsdeliverylocation"
                                                :items="places"
                                                :search-input.sync="searchPlace"
                                                :placeholder="getDeliveryLocationPlaceHolder()"
                                                item-text="place_name"
                                                no-filter
                                                clearable
                                                hide-no-data
                                                @click:clear="clearSearchPlaces"
                                                filled
                                                full-width
                                                class="Goodsdeliverylocation mt-0"
                                                name="custom_notes"
                                                hide-details="auto"
                                                attach
                                                @input="(e) => checkErrorShowing(e, 'delivery_location')">
                                                
                                                <template slot="selection" slot-scope="{ item }">
                                                    <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                                                        {{ item.place_name }}
                                                    </div>
                                                </template>    
                                            </v-autocomplete>

                                            <p class="p-form-errors" v-if="isShowDeliveryError">
                                                Input is required
                                            </p>
                                        </div>
                                    </v-row>

                                    <v-row class="mt-5">
                                        <v-col
                                            class="selectBox"
                                            cols="12"
                                            md="12"
                                            >
                                            <!-- <label class="text-item-label" for="">
                                            Commodity Description
                                            </label>
                                            <v-text-field
                                                placeholder="Enter Container Commodity"
                                                required
                                                v-model="container_commodity"
                                                name="mbl_num"
                                                hide-details="auto"
                                                @keyup="blurevent"
                                                >
                                            </v-text-field> -->

                                            <label class="text-item-label" for="">
                                                Description of Articles, Special Marks & Exceptions
                                            </label>
                                            <v-textarea
                                                placeholder="Enter Description Of Articles, Special Marks & Exceptions"
                                                required
                                                v-model="container_commodity"
                                                name="mbl_num"
                                                hide-details="auto"
                                                @keyup="blurevent"
                                                rows="3"
												class="textarea-commodity"
                                                >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row class="notesrow mt-0">
                                        <v-col
                                            class="textareanotes pb-0 mt-0"
                                            cols="12"
                                            md="12"
                                            >
                                            <label class="text-item-label" for="">
                                            Notes
                                            </label>
                                            <v-textarea
                                                v-model="notembl"
                                                :rules="noterules"
                                                placeholder="Type Note..."
                                                rows="3"
                                                name="custom_notes"
                                                @input="blurevent"
                                                class="textarea-commodity"
                                                >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row class="notesrow mt-0">
                                    </v-row>

                                    <v-row class="pt-0 pb-2">
                                        <v-col cols="12" md="12">
                                            <v-form ref="containerForm">
                                                <!-- <h4 style="padding-left:6px;">Containers</h4> -->
                                                <div class="milestone-create-container333">
                                                    <div class="mainWrapperListContainers mt-0">
                                                        <div style="color: red; height: 20px;">
                                                            <ul class="errorList" >
                                                                <li>{{errorMessagetext['container']}}</li>
                                                                <!-- <li>{{errorMessagetext['Volume']}}</li>
                                                                <li>{{errorMessagetext['Weight']}}</li>
                                                                <li>{{errorMessagetext['Quantity']}}</li>
                                                                <li>{{errorMessagetext['Sizes']}}</li> -->
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-12 px-0">
                                                            <v-data-table
                                                                v-model="selected"
                                                                class="add-containers-table review-do containerListingTable"
                                                                :headers="headers"
                                                                :items="containerList"
                                                                :page.sync="page"
                                                                :items-per-page="5"
                                                                hide-default-footer
                                                                fixed-header
                                                                dense
                                                                disable-pagination>

                                                                <template v-slot:body="{ items }">
                                                                    <tbody>
                                                                        <tr class="listing_table" v-for="(item, index) in items" :key="index">
                                                                            <!-- <td class="text-start"
                                                                                style="padding-left: 13px !important;
                                                                                    border-left: 1px solid #EBF2F5;
                                                                                    border-bottom: 1px solid #EBF2F5"
                                                                                >
                                                                            {{item.container_number}}
                                                                            </td>
                                                                            <td class="text-end cl"
                                                                                >
                                                                                {{ item.container_size }}
                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                {{ item.container_weight }}
                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                {{ item.container_volume }}
                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                {{ item.container_qty }}
                                                                            </td> -->
                                                                            <td class="text-start"
                                                                                style="border-left: 1px solid #EBF2F5;
                                                                                    border-bottom: 1px solid #EBF2F5">

                                                                                <!-- {{item.container_number}} -->
                                                                                <v-text-field
                                                                                    type="text"
                                                                                    placeholder="Input Container #"
                                                                                    class="text-fields"
                                                                                    @keyup="validateContainerNumber(item.container_number)"
                                                                                    full-width
                                                                                    v-model="item.container_number"
                                                                                />

                                                                            </td>
                                                                            <td class="text-end cl"
                                                                                >
                                                                                <!-- {{ item.container_size }}  -->
                                                                                <v-select
                                                                                    v-model="item.container_size"
                                                                                    :items="itemsDropdownSize"
                                                                                    placeholder="Select Size"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    class="text-fields fieldClass sizeClass"
                                                                                    append-icon="ic-chevron-down"
                                                                                ></v-select>
                                                                                    <!-- @input="validatesize(item.container_size)"      -->

                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_weight }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_weight"
                                                                                    suffix="KG"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_weight,'Weight')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">KG</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_weight)" v-model="item.container_weight"><SPAN>kg</SPAN>
                                                                                -->
                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_volume }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_volume"
                                                                                    suffix="CBM"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_volume,'Volume')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">CBM</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_volume)" v-model="item.container_volume"><span>cbm</span> -->

                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_qty }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_qty"
                                                                                    suffix="UNIT"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_qty,'Quantity')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">UNIT</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_qty)" v-model="item.container_qty"> -->
                                                                            </td>
                                                                            <td class="text-center" style="border-right:0px !important;border-bottom: none !important;">
                                                                                <span @click="removeContainer(index)" style="color: red; cursor: pointer;">
                                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1527 0.846318C11.3932 1.08682 11.3932 1.47674 11.1527 1.71724L6.87069 5.99928L11.1527 10.2813C11.3932 10.5218 11.3932 10.9117 11.1527 11.1522C10.9122 11.3927 10.5223 11.3927 10.2818 11.1522L5.99977 6.8702L1.71773 11.1522C1.47723 11.3927 1.0873 11.3927 0.846806 11.1522C0.606308 10.9117 0.606308 10.5218 0.846807 10.2813L5.12884 5.99928L0.846807 1.71724C0.606308 1.47674 0.606308 1.08682 0.846807 0.846318C1.08731 0.605819 1.47723 0.605819 1.71773 0.846318L5.99977 5.12835L10.2818 0.846318C10.5223 0.605819 10.9122 0.605819 11.1527 0.846318Z" fill="#F93131"/>
                                                                                </svg>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody v-if="isAdding" >
                                                                        <tr class="addingnewrow" >
                                                                            <td class="text-end" style="border-right: 1px solid #EBF2F5 !important;">
                                                                                <v-text-field
                                                                                    type="text"
                                                                                    placeholder="Input Container #"
                                                                                    class="text-fields"
                                                                                    v-model="containers.container_number"
                                                                                    :rules="[age]"
                                                                                    full-width
                                                                                />
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <!-- <v-autocomplete
                                                                                    style="margin-top:10px"
                                                                                    v-model="containers.container_size"
                                                                                    :items="itemsDropdownSize"
                                                                                    placeholder="Select Size"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    class="text-fields fieldClass sizeClass"
                                                                                    no-filter
                                                                                    clearable
                                                                                    hide-no-data
                                                                                    :rules="[sizecheck(containers.container_size)]"
                                                                                ></v-autocomplete> -->
                                                                                <v-select
                                                                                    v-model="containers.container_size"
                                                                                    :items="itemsDropdownSize"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    placeholder="Select Size"
                                                                                    class="text-fields fieldClass sizeClass"
                                                                                    append-icon="ic-chevron-down"
                                                                                ></v-select>
                                                                                    <!-- :rules="[sizecheck(containers.container_size)]" -->
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_weight"
                                                                                    suffix="KG"
                                                                                >
                                                                                    <!-- :rules="[rulesCheck(containers.container_weight,'Weight')]" -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">KG</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_volume"
                                                                                    suffix="CBM"
                                                                                >
                                                                                    <!-- :rules="[rulesCheck(containers.container_volume,'Volume')]" -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">CBM</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields quantifyFields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_qty"
                                                                                    suffix="UNIT"
                                                                                >
                                                                                    <!-- :rules="[rulesCheck(containers.container_qty,'Quantity')]" -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">UNIT</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td style="border-bottom: 0px !important; border-left: none !important;" class="text-end" >
                                                                                <span @click="cancelAdding()"
                                                                                style="color: grey;
                                                                                    cursor: pointer;
                                                                                    position: relative;
                                                                                    top: 0px;">
                                                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.846389C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12891 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846878 0.846389C1.08738 0.605891 1.4773 0.605891 1.7178 0.846389L5.99984 5.12843L10.2819 0.846389C10.5224 0.605891 10.9123 0.605891 11.1528 0.846389Z" fill="#B4CFE0"/>
                                                                                        </svg>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-data-table>
                                                            <!-- <div style="cursor:pointer;width:182px !important" @click="addCOntainer" class="addcontainer">
                                                                <svg style="position:relative;top:2px;cursor:pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.6998 3.50078C7.6998 3.11418 7.3864 2.80078 6.9998 2.80078C6.61321 2.80078 6.29981 3.11418 6.29981 3.50078L6.2998 6.30078H3.4998C3.11321 6.30078 2.7998 6.61418 2.7998 7.00078C2.7998 7.38738 3.11321 7.70078 3.4998 7.70078H6.2998V10.5008C6.2998 10.8874 6.61321 11.2008 6.9998 11.2008C7.3864 11.2008 7.6998 10.8874 7.6998 10.5008V7.70078H10.4998C10.8864 7.70078 11.1998 7.38738 11.1998 7.00078C11.1998 6.61418 10.8864 6.30078 10.4998 6.30078H7.6998V3.50078Z" fill="#0171A1"/>
                                                                </svg>Add Container
                                                            </div> -->
                                                            <div class="d-flex" style="width: 100%;">
                                                                <div style="cursor:pointer;width:27% !important" @click="addCOntainer" class="addcontainer">
                                                                    + Add Container
                                                                </div>
                                                                <div style="width: 20%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 8%;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div class="uploadDocuwrapper colright mt-0"
									style="
										width: calc(100% - 790px) !important;
										max-width: calc(100% - 790px) !important;
										padding: 16px 0 0 !important;
									">
                                    <div class="deliveryOrderColumn px-4">
                                        <!-- <div class="mt-3"
                                        style="
                                            padding-bottom: 7px;
                                            color: #4a4a4a;
                                            font-size: 16px;
                                            font-weight: 600;
                                        "
                                        >Document</div> -->
                                        <small
                                            style="color: red; font-size: 12px; margin-left: 5px"
                                            v-if="errorFile"
                                        >
                                            Invalid File Type
                                        </small>
                                        <span style="display: none" class="fileuploaderror"> </span>
                                        <div
                                            style="display: block"
                                            class="uploadElement flex w-full h-screen items-left justify-left text-left mt-0"
                                            id="app"
                                            >
                                            <div
                                                style="width: 100%; background: #fff !important"
                                                :class="hasContent"
                                                xclass="cDownloadfiles"
                                                @dragover="dragover"
                                                @dragleave="dragleave"
                                                @drop="drop"
                                            >
                                                <input
                                                style="display: none"
                                                type="file"
                                                name="files[]"
                                                id="assetsFieldHandle_review"
                                                class="w-px h-px opacity-0 overflow-hidden absolute"
                                                @change="onChangereview"
                                                ref="file_review"
                                                accept="application/pdf"
                                                />

                                                <label
                                                for="assetsFieldHandle_review"
                                                class="block cursor-pointer triggerelement2"
                                                >
                                                <div class="browserInput2 dragfileElement">
                                                    <div class="browseFileDelivery">Browse Or Drop File</div>
                                                    <div class="btn-white2 browseButton2">
                                                    <div class="wrappIconbutton2 mt-3">
                                                        <span class="iconUploadfile"
                                                        ><i class="ic-upload"></i
                                                        ></span>
                                                        <span class="classUpload">Upload</span>
                                                    </div>
                                                    </div>
                                                </div>
                                                </label>
                                            </div>

                                            <div
                                                class="flex w-full h-screen items-left justify-left text-left"
                                                style="width: 100%"
                                            >
                                            <!-- <span @click="downloadDocument(69)">Download</span>                               -->
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
                                        <v-progress-circular
                                            :size="35"
                                            color="#0171A1"
                                            indeterminate
                                            v-if="isFetching">
                                        </v-progress-circular>
                                    </div>

                                    <div v-if="!isFetching" :style="filelist_review.length > 0 ? 'height: calc(100% - 40px);' : ''">
                                        <div v-if="this.filelist_review.length > 0" style="height: 100%;">
                                            <div class="forthis" v-for="file in filelist_review" :key="file.name" style="height: 100%;">
                                                <div class="flex align-center justify-end mb-3 ml-0">
                                                    <!-- <div>
                                                        <label for="" class="reviewSubHeader-text document-align d-flex align-center">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6532 6.56315H16.9276C17.2438 6.56315 17.5 6.80836 17.5 7.10971V17.4486C17.5 18.387 16.7038 19.1673 15.721 19.1673H4.3C3.3178 19.1673 2.5 18.387 2.5 17.4486V2.53268C2.5 1.59424 3.3178 0.833984 4.3 0.833984H10.9276C11.2438 0.833984 11.5 1.07862 11.5 1.38055V5.462C11.5 6.07044 12.016 6.56315 12.6532 6.56315ZM6.7 15.1569H13.3C13.6312 15.1569 13.9 14.9008 13.9 14.584C13.9 14.2672 13.6312 14.0111 13.3 14.0111H6.7C6.3688 14.0111 6.1 14.2672 6.1 14.584C6.1 14.9008 6.3688 15.1569 6.7 15.1569ZM6.7 12.8652H13.3C13.6312 12.8652 13.9 12.6091 13.9 12.2923C13.9 11.9755 13.6312 11.7194 13.3 11.7194H6.7C6.3688 11.7194 6.1 11.9755 6.1 12.2923C6.1 12.6091 6.3688 12.8652 6.7 12.8652ZM6.7 10.5736H13.3C13.6312 10.5736 13.9 10.3169 13.9 10.0007C13.9 9.6844 13.6312 9.42773 13.3 9.42773H6.7C6.3688 9.42773 6.1 9.6844 6.1 10.0007C6.1 10.3169 6.3688 10.5736 6.7 10.5736ZM16.117 5.41732H13.2724C12.9562 5.41732 12.7 5.17211 12.7 4.87076V2.15456C12.7 1.93284 12.8392 1.73289 13.054 1.64925C13.267 1.56446 13.5142 1.61143 13.6774 1.76727L16.522 4.48347C16.6858 4.63987 16.735 4.87591 16.6462 5.0793C16.558 5.2844 16.3492 5.41732 16.117 5.41732Z" fill="#6D858F"/>
                                                            </svg>
                                                            <span class="ml-2" style="color: #4a4a4a;">{{ file.name }}</span>
                                                        </label>
                                                    </div> -->
                                                    <div class="d-flex align-center pr-4">
                                                        <button type="button" class="btn-white replace-btn" @click="handleReplace">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.29882 3.63602C5.5221 3.91096 5.46577 4.30383 5.173 4.51352C4.0217 5.33812 3.33333 6.61527 3.33333 8.00078C3.33333 10.1904 5.04317 12.005 7.27733 12.3312L6.80474 11.887C6.54439 11.6425 6.54439 11.2461 6.80474 11.0015C7.06509 10.757 7.4872 10.757 7.74755 11.0015L9.41421 12.5668C9.67456 12.8113 9.67456 13.2077 9.41421 13.4522L7.74755 15.0174C7.4872 15.2619 7.06509 15.2619 6.80474 15.0174C6.54439 14.7729 6.54439 14.3765 6.80474 14.132L7.36685 13.6046C4.35077 13.3075 2 10.912 2 8.00078C2 6.22047 2.88588 4.57685 4.36444 3.51786C4.65721 3.30817 5.07554 3.36107 5.29882 3.63602ZM6.58579 2.54938L8.25245 0.984158C8.5128 0.739656 8.93491 0.739656 9.19526 0.984158C9.43559 1.20985 9.45407 1.56499 9.25072 1.8106L9.19526 1.86958L8.63315 2.397C11.6492 2.69406 14 5.08961 14 8.00078C14 9.70363 13.1899 11.2835 11.8189 12.347C11.5351 12.5672 11.1149 12.5296 10.8804 12.263C10.6459 11.9964 10.6859 11.6018 10.9698 11.3816C12.0373 10.5535 12.6667 9.3261 12.6667 8.00078C12.6667 5.81116 10.9568 3.99659 8.72267 3.6704L9.19526 4.11459C9.45561 4.35909 9.45561 4.75551 9.19526 5.00001C8.95494 5.22571 8.57679 5.24307 8.31526 5.0521L8.25245 5.00001L6.58579 3.4348C6.34546 3.2091 6.32698 2.85397 6.53033 2.60836L6.58579 2.54938L8.25245 0.984158L6.58579 2.54938Z" fill="#0171A1"/>
                                                            </svg>
                                                            <span class="ml-1">Replace</span>
                                                        </button>
                                                        <button type="button" class="btn-white replace-btn ml-2" @click="removeFileUpload">
															<svg style="position: relative;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1528 2.84639C13.3933 3.08689 13.3933 3.47681 13.1528 3.71731L8.87076 7.99935L13.1528 12.2814C13.3933 12.5219 13.3933 12.9118 13.1528 13.1523C12.9123 13.3928 12.5224 13.3928 12.2819 13.1523L7.99984 8.87027L3.7178 13.1523C3.4773 13.3928 3.08738 13.3928 2.84688 13.1523C2.60638 12.9118 2.60638 12.5219 2.84688 12.2814L7.12891 7.99935L2.84688 3.71731C2.60638 3.47681 2.60638 3.08689 2.84688 2.84639C3.08738 2.60589 3.4773 2.60589 3.7178 2.84639L7.99984 7.12843L12.2819 2.84639C12.5224 2.60589 12.9123 2.60589 13.1528 2.84639Z" fill="#F93131"/>
                                                            </svg>
															<span class="ml-1" style="color: #f93131;">Remove</span>
														</button>
                                                    </div>
                                                </div>
                                                <div class="flex" style="height: 100%;">
                                                    <object  style="overflow: hidden ! important;"
                                                    :data="getFileURL(file)" frameborder="0" width="100%" height="100%"></object>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-form>

                        <template>
                            <div class="text-center">
                                <v-snackbar
                                v-model="snackbar"
                                :timeout="timeout"
                                >
                                <div v-if="snackbarcontent">
                                Selected containers has been moved to another DO
                                <span
                                    style="cursor:pointer;"
                                    @click="viewdo">
                                    View DO
                                    <img
                                        src="../../../../assets/icons/arrow-right-delivery.svg">
                                    </span>
                                </div>
                                <div v-else>
                                    Delivery Order has been successfully saved.
                                </div>
                                </v-snackbar>
                            </div>
                        </template>
                    </div>
                </v-card-text>

                <v-card-actions class="mt-0" style="border-top: 1px solid #EBF2F5;padding-top: 16px;">
                    <div class="flex flex-row">
                        <button
                            type="button"
                            style="height:41px;font-size:14px;"
                            class="btn-blue"
                            @click="acceptDO"
                            :disabled="isSubmittingForm">
                            <svg style="position: relative;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.15063 10.8212L3.42101 7.95511C3.00445 7.51772 2.32907 7.51772 1.91252 7.95511C1.49596 8.39249 1.49596 9.10164 1.91252 9.53902L5.46806 13.2723C5.91158 13.738 6.6402 13.7033 7.04174 13.1974L14.1528 4.23742C14.53 3.76223 14.4688 3.056 14.0163 2.66001C13.5637 2.26402 12.8911 2.32822 12.514 2.80341L6.15063 10.8212Z" fill="white"/>
                            </svg>
                            <span class="ml-1" v-if="item.reviewStatus !== 'Rejected'"> 
                                {{ isSubmittingForm ? "Confirming..." : "Confirm" }} 
                            </span>
                            <span class="ml-1" v-else> 
                                {{ isSubmittingForm ? "Accepting..." : "Accept" }} 
                            </span>
                        </button>
                        <button
                            type="button"
                            class="btn-white cancel-btn ml-0"
                            style="color:red !important;height:41px;font-size:14px;"
                            @click="rejectDO(item.reviewStatus)"
                            :disabled="isSubmittingForm">
                            <svg style="position: relative;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1528 2.84639C13.3933 3.08689 13.3933 3.47681 13.1528 3.71731L8.87076 7.99935L13.1528 12.2814C13.3933 12.5219 13.3933 12.9118 13.1528 13.1523C12.9123 13.3928 12.5224 13.3928 12.2819 13.1523L7.99984 8.87027L3.7178 13.1523C3.4773 13.3928 3.08738 13.3928 2.84688 13.1523C2.60638 12.9118 2.60638 12.5219 2.84688 12.2814L7.12891 7.99935L2.84688 3.71731C2.60638 3.47681 2.60638 3.08689 2.84688 2.84639C3.08738 2.60589 3.4773 2.60589 3.7178 2.84639L7.99984 7.12843L12.2819 2.84639C12.5224 2.60589 12.9123 2.60589 13.1528 2.84639Z" fill="#F93131"/>
                            </svg>
                            <span class="ml-1">Reject</span>
                        </button>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ContainerDialog
            @saveContainer="saveContainer"
            :dialogData.sync="dialog"
            :ContainerSize="ContainerSize"
            :lastIndexData="lastIndexData"
        />

        <EditContainers
            @updateContainer="updateContainer"
            :dialogData.sync="showedit"
            :ContainerSize="ContainerSize"
            :editdata="keysvalue"
        />

        <CreatePicker
            :dialogDatepicker="dialogDatepicker"
            :defaultData ="pickerDefaultdata"
            @passbackdata="getdata"
            @close="closeDialogpicker()"
        />

        <!-- <Confirm
            @cancelDialog="cancelDialog"
            @confirmDialog="continueDialog"
            :dialogData.sync="dialogConfirm"
            :isSaving.sync="isSavingConfirm"
            :location="location"
        /> -->

        <Movedialognotallowed
            :dialogData.sync="notallowed"
            :movetype="movetype"
            @close="closenotallowed()"
        />
        <DialogCancel
            :dialogData.sync="showcancel"
            @close="closecancel()"
            @confirmCancel="confirmCancel()"
        />
        <ShowNotificationMoveDO
            :dialogData.sync="shownotification"
            @hideNotification="usernotification()"
        />

        <SnackbarContainersave
            :dialogData.sync="showcontaineradded"
        />

        <!-- <Showdialogupdatebox
            :dialogData.sync="showconfirmafterupdate"
        /> -->

        <CreatedContainersDialog
            @confirmDialog="continueDialogUpdateBox"
            :dialogData.sync="dialogConfirm"
            :isSaving.sync="isSavingConfirm"
            :deliveryID="DO_ID"
            :containersnumber="containers_number"
            :countshipment="countshipment"
            :shipmentIDS="shipmentIDS"
            :confirmmessage="confirm_message"
            :location="location"
        />

        <showrejectmodal
            :dialogData.sync="showreject"
            :item="itemreject"
            @submitreject="submitreject"
            @closeDialog="showrejectClose"
        />

        <CustomerDialogform
            :aftersavecustomer.sync ="aftersavecustomer"
            :aftersave.sync="aftersave"
            :dialogData.sync="dialogCustomerdialog"
            :datainitial="initialcustomer_name"
            :item="item"


        />
    <DatePickerDialog
            :open="isDatePickerOpen"
            :multiple="false"
            :label="pickerLabel"
            @confirm="confirmDatePicker"
            @close="isDatePickerOpen = false"
        />

        <Containerrequired
            :dialogData.sync="dialogrequired"
            @close ="()=>{ dialogrequired = false }"
        />
        <Containervalidate
            :dialogData.sync="dialogcontainervalidate"
            @close ="() => { dialogcontainervalidate = false }"
        />
      </div>
</template>
<script>
import axios from "axios";
import {mapGetters,mapActions } from 'vuex'
const poBaseUrl = process.env.VUE_APP_PO_URL
const baseUrl = process.env.VUE_APP_BASE_URL;
import globalMethods from '../../../../utils/globalMethods'
import VueTagsInput from '@johmun/vue-tags-input';
import ContainerDialog from './DialogContainer.vue'
import EditContainers from './EditDialogContainer.vue'
import Containerrequired from './Onecontainerrequired.vue'
import Containervalidate from './Containervalidate.vue'
//import ListRendering from '../listRender.vue'
//import ListRendering2 from '../newlistRender.vue'
//import DatePickerDialog from '../../components/Tables/Containers/Dialog/DatePickerDialog.vue';
import CreatePicker from "../../../Createpicker.vue";
import Movedialognotallowed from "./NotallowedDialog.vue";
import DialogCancel from "./CancelDialog.vue";
import ShowNotificationMoveDO from "./ShowNotificationMoveDO.vue";
import SnackbarContainersave from "../snackbar/containersave.vue";
import CreatedContainersDialog from "./CreatedContainer.vue";
import CustomerDialogform from './CustomerDialogform.vue';
import showrejectmodal from "./ShowrejectModal.vue";
import DatePickerDialog from '../../../../components/Tables/Containers/Dialog/DatePickerDialog.vue';
import moment from "moment";
//import Confirm from './confirm.vue'
import _ from "lodash";
import jQuery from 'jquery'

// import DxDateBox from 'devextreme-vue/date-box';

export default {
    name: "Dos",
    props: ['item','openDialogreview','acceptedmsg','rejectedmsg'],
    components: {
        VueTagsInput,
        ContainerDialog,
        Containerrequired,
        Containervalidate,
        //ListRendering2,
        //ListRendering,
        //Confirm,
        DatePickerDialog,
        EditContainers,
        CreatePicker,
        Movedialognotallowed,
        DialogCancel,
        ShowNotificationMoveDO,
        SnackbarContainersave,
        CreatedContainersDialog,
        showrejectmodal,
        CustomerDialogform,
        // DxDateBox
    },
    data(){
        return {
        isDateRequired: false,
        attribute: {
            highlight: {
                style: {
                    backgroundColor: '#0171A1', // blue
                    borderRadius: '4px'
                },
                contentStyle: {
                    color: '#ffffff', // color of the text
                    borderRadius: '4px'
                }
            },
        },
        masks: {
            weekdays: "WWW"
        },
        isSubmittingForm: false,
        isFetching: 0,
        hasfileni:'',
        terminal:'',
        searchPlace: "",
        places: [],
        delivery_location:'',
        loadingLocationPlaces: false,
        calceladding: false,
        haserrorContainers: false,
        dialogrequired:false,
        dialogcontainervalidate: false,
        tempcustomer:[],
        initialcompany:'',
        hasNewChassis: 0,
        newChassis: "",
        hassblickvalidate:0,
        errorMessagetext:{
            container:'',
            Weight:'',
            Volume:'',
            Quantity:'',
            Sizes:'',
        },
        min: 21,
        max: 65,
        filenameobj:'',
        hasfile:0,
        docuID:0,
        nextt:1,
        therurlvalue:'',
        selected:[],
        page:1,
        isAdding: false,
        isDatePickerOpen: false,
        fields:{
            containers:[{
                id:0,
                container_number: 'ewrewrew',
                container_size: 4,
                container_weight: 34,
                container_volume:65,
                container_qty:23,
                lfd: '2023-09-11'
            },{
                id:1,
                container_number: 'ewrewrew',
                container_size: 4,
                container_weight: 34,
                container_volume:65,
                container_qty:23,
                lfd: '2023-09-11'
            }]
        }
         ,
        containers:{
            container_number: "",
            container_size: "",
            container_weight: "",
            container_volume: "",
            container_qty: "",
            checkbox:0,
            },
        ContainerSize:[],
        startfetching:0,
        aftersave:0,
        aftersavecustomer:[],
        iscustomer:1,
        initialcustomer_name:'',
        dialogCustomerdialog:false,
        pickup_location:'',
        customer_name_tag:'',
        itemreject:[],
        showreject:false,
        themode: '',
        typeoflocation:'',
        gooodsdeliverylocation:'',
        delivery_mode:'',
        terminals:[],
        customers:[],
        carriers:[],
        hide:0,
        confirm_message:[],
        countshipment:0,
        containers_number:[],
        shipmentIDS:[],
        DO_ID:'',
        showconfirmafterupdate:0,
        showcontaineradded:0,
        snackbarcontent: false,
        timeout:2000,
        thelinkreturn: 0,
        thdid:'',
        sid:'',
        snackbar:false,
        triggerloc:'',
        istriggerdfrommodal:0,
        shownotification:0,
        showcancel:0,
        getlistasstring:[],
        haschangesform:0,
        doInitialvalue:null,
        dofinalvalue:null,
        initcontainerListdata:null,
        finalcontainerListdata:null,
        getunchecked: [],
        defaultlistdata:[],
        movetype:0,
        notallowed:0, // if use added container, must be saved first
        updated:0,
        location:'edit',
        movetoexisting:0,
        istriggerededit:0,
        existingdeliveryobject:[],
        selectedExistingDeliveryID:0,
        selectedshifl_ref: 0,
        iscreate:0,
        ismove:0,
        allshipmentedits:[],
        pickerDefaultdata:'',
        dialogDatepicker:false,
        keytovalue: 0,
        keysvalue: [],
        editdata: [],
        showedit: 0,
        disableEditContainers: 1, // chana advice disable containers edit for now
        orderdeliveryID:'',
        refresh:0,
        callna: '',
        solidestyle:0,
        errorstate: 0,
        notpermited:false,
        calleditableELe: false,
        modaletd:false,
        container_commodity:'',
        customerdropdown: 0,
        allDeliveryData: [],
        currentindexshow:99999,
        containerEmptytext: 'Container is empty. Add container before you save changes',
        roottablecontainer:'blueteam',
        containerList2:[],
        containerList:[],
         HBL_NUMBER: '',
        uploadCompleted: true,
        uploadTrigger:false,
        noerrorForm:true,
        contactsModel:'',
        CarrierModel:'',
        VesselModel:'',
        responseDatatempt:'',
        serverObject:[],
        objectServerid:'',
        hasContent:'hasContentFiles2',
        filelist_review:[],
        errorFile: false,
        comodity: '',
        resetform : false,
        shipmentmsg: 'The shipment was created!',
        Truckers:false,
        noerror: false,
        testsubmit:'',
        formFieldlength:0,
        startloading: 0,
        clickOrigin:'',
        clicksubmit: false,
        submitisclick:false,
        selectedmilestone:[],
        customer: [],
        mbl: '',
        containerNumber : '',
        terminalAt :'',
        CarrierRules:[
            v => !!v || 'Customer is required',
        ],
        VesselRules:[
            v => !!v || 'Customer is required',
        ],
        customerRules: [
            v => !!v || 'Customer is required',
        ],
        mblRules: [
            v => !!v || 'Mbl# is required',
        ],
        fieldrequired: [
            v => !!v || 'This field is required',
        ],
        containerNumberRules: [
            v => !!v || 'Container # is required',
        ],
        notembl: '',
        pickerLabel: "Pick ETA Date",
        dateValidation: [
            (date) => {
                if(date === "") {
                    return "Required"
                } else {
                    if(/^\d{4}-\d{2}-\d{2}$/.test(date)) {
                        return true
                    }

                    return "The date should be in YYYY-MM-DD format"
                }
            }
        ],
        // container_number_rules: [
        //     (text) => {
        //         if (text == "") "Container # is required.";

        //         let msg = "Invalid";
        //         if (text.length == 11) {
        //             if (
        //             /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
        //             /^[0-9]*$/.test(text.substring(4, text.length))
        //             ) {
        //                 return true;
        //             }
        //                 return msg;
        //         }
        //         return "4 chars then next 7 digits";
        //     },
        // ],
        noterules:[
            (v) => {
                if (v == null){
                    return true;
                }else if(v !='') {
                    if(v.length > 200)
                    return  'Should not exceed the 200 allowed characters'
                }
                return true;
            }
        ],
        TerminalAtRules: [
            v => !!v || 'Terminal At is required',
        ],
        containerSizeModel:[],
        containersize: '',
        containersizeRules: [
            v => !!v || 'Container size is required',
        ],
        deliverTo: '',
        delivertoRules:[
            v => !!v || 'Deliver to is required',
        ],
        weight: '',
        weightRules:[
            v => !!v || 'Weight is required',
        ],
        customerpoNumber:'',
        customerpoNumberRules:[
            v => !!v || 'Customer PO# is required',
        ],
        containerTypeField:'',
        containerTypeRules:[
            v => !!v || 'Container Type is required',
        ],
        truckersField:'',
        truckersRules:[
            v => !!v || 'Truckers is required',
        ],
        hiddenmilestone:'',
        hiddenmilestoneRules:[
            v => !!v || 'Please select milestone',
        ],
        select: '',
        customerSelected:'',
        containertypeSelected:'',
        terminalatSelected:'',
        containersizeSelected:'',
        currentDropdownState:'',
        trucker_idSelected: '',
        valid:true,
        currentComboState:'',
        tabscurrent:0,
        tab: null,
        tabsNavigation: [
          'Header Info',
        ],
        date_etd:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        checkboxColor: '#0171A1',
        container_type: '',
        select_terminal_at:'',
        itemsDropdown: [],
        dialog: false,
        dialogConfirm: false,
        isSavingConfirm: false,
		search: "",
        searchData: '',
        selectedTasks: [],
        containerType: [],
        containerSize: [],
        TERMINAL_AT: [],
        ex4: [],
        truckers: [],
        newdata:[],
        myclass: '',
        myclass2: '',
        options: [],
        optionsReferrence: [],
        documents: [],
        document: null,
        documentProto: document,
        warehouseEmailAddress:'',
        customerReferrenceNumber: '',
        moreFilesError: false,
        submit: false,
        isLoading: false,
         tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
          tagsInput2: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.'
        },
        tagsValidation: [
        {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
        }
        ],
         tagsValidation2: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+$/),
            disableAdd: true
            }
        ],
        arrayNotEmptyRules: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        arrayNotEmptyRulesRefference: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        tagsValidation3: [
            {
            classes: 'ttt-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        rules: [(v) => !!v || "Required fields"],
        checkvalidtime: [
          (v) =>
            {
              if(v==='') return "This field is required"
              if(!this.validateDate(v)){
                return "Invalid Date ex: YYYY-mm-dd"
              } return true
            }
         ],
         lastIndexData: null,
         whereitcome:'',
        isShowETAError: false,
        isShowMBLError: false,
        isShowCustomerError: false,
        isShowDeliveryError: false,
        consigneeLists: [],
        selectedConsignee: null,
        newConsignee: "",
        hasNewConsignee: 0,
    }},
    watch: {
        searchPlace(val) {
            if (val) {
                this.getDeliveryLocation(val);
            }
        },
        filenameobj(c){
            if(c){
                // comment calling of method download document
                // this.downloadDocument(this.docuID)
            }
        },
        aftersave(item){
            if(item){
                this.customerdropdown = item;
                this.dialogCustomerdialog = false;
                this.whereitcome = 'aftersave';
            }
        },
        dialogCustomerdialog(c){
            if(c || !c){
                this.iscustomer = 0;
                this.$nextTick(() => {
                    this.customers = this.whereitcome == 'aftersave' ? this.aftersavecustomer : this.tempcustomer
                    this.iscustomer = 1;
                });
            }
        },
        openDialogreview(c){
            if(c){
                this.filelist_review = [];
                this.callpopulatedialog();

            }else{
                this.filelist_review = [];
            }
        },
        snackbar(c){
            if(!c){
               location.reload();
            }
        },
        updated(c){
            if(c){
                this.notallowed = 1;
                this.updated = 1;
                if(c==2){
                    this.notallowed = 1;
                    this.updated = 1;
                }else if(c==3){
                    this.movetype = 3
                }else if(c==4){
                    this.movetype = 4
                }else  if(c==5){
                    this.movetype = 5
                }
            }
        },
        optionsReferrence:{
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
         options: {
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
        solidestyle(c){
            alert(c)
        },
        customers(c){
            if(c == ''){
                console.log('customer is null')
            }
        },
        items: function(newVal){
          this.getPrelims(newVal)
        },
        filelist_review(c){
            this.hasfileni =  '';
            if(c.length === 0) {
                if(!this.documents.length > 0) {
                    this.hasContent = 'hasContentFiles2'
                }
            }
            else {
                //this.callUPdocument(c)
               this.hasContent = "cDownloadfiles hidethisdiv";
            }
        },
        ismove(){
            //console.log(this.containerList)
           // var getobject = _.filter(this.containerList,function(o){return o.checked;});
            //console.log(getobject);
            //this.containerList = getobject
            this.istriggerededit = 1;
        },
        // filelist_review(c){
        //    if(c.length > 0){
        //        this.callUPdocument(c);
        //    }
        // }
        hasContent(nv) {
            console.log(nv)
        }
	},
    computed: {
        theterminals:{
            get(){
              return  this.terminals;
            }
        },
        // gethasfile() {
        //     if(this.therurlvalue !==''){
        //         let file = this.therurlvalue
        //         return URL.createObjectURL(file);
        //     }
        //     return ''
        // },
        headers(){ return [
      {
        text: "Container #",
        align: "start",
        value: "container_number",
        sortable: false,
        width: "27%",
        fixed: true,
      },
     {
        text: 'Size',
        align: "end",
        value: "container_size",
        sortable: false,
        width: "20%",
        fixed: true,
      },
      {
        text: "Weight",
        align: "end",
        value: "container_weight",
        sortable: false,
        width: "15%",
        fixed: true,
      },
      {
        text: "Volume",
        align: "end",
        value: "container_volume",
        sortable: false,
        width: "15%",
        fixed: true,
      },
       {
        text: "Quantity",
        align: "end",
        value: "container_qty",
        sortable: false,
        width: "15%",
        fixed: true,
      },
    //    {
    //     text: "LFD",
    //     align: "start",
    //     value: "lfd",
    //     sortable: false,
    //     width: "10%",
    //     fixed: true,
    //   },
       {
        text: "",
        align: "end",
        value: "actions",
        sortable: false,
        width: "8%",
        fixed: true,
        class:'alex'
        },
    ]},
        openDialogCom_review:{
          get(){
            return this.openDialogreview;
          },
          set(val) {
            this.$emit("update:openDialogreview", val)
          }
        },
        ...mapGetters({
            getCreateloading: 'containers/getPoLoading',
            formdetails: 'containers/getcontainerFormDetails',
            getPoLoading: 'containers/getPoLoading',
            getAllConsignees: 'containers/getAllConsignees',
            getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
            getCreateConsigneeLoading: 'containers/getCreateConsigneeLoading'
        }),
        itemsDropdownSize:{
            get () {
                // console.log(this.ContainerSize)
                return this.ContainerSize
            },
        },
        getSearchCompany: {
			get() {
				return this.newChassis !== null ? this.newChassis.toString() : this.newChassis;
			},
			set(input) {
				let temp = [];

				this.itemsDropdownComputed.map((list) => {
					temp.push(list.company_name);
				});
				if(temp.includes(input)) {
					this.hasNewChassis = 0;
				}
				else if(this.newChassis === "" || this.newChassis === null) {
					this.hasNewChassis = 0;
				}
				else {
					this.hasNewChassis = 1;
				}
				this.newChassis = input;
			},
		},
        select_company(){
            return this.initialcompany;
        },
        itemsDropdownComputed:{
            get(){
                return this.customers
            }
        },
        terminalsDropdown:{
            get(){
                return this.terminals
            }
        },
        carrierDropdown:{
            get(){
                return this.carriers
            }
        },
        sizeDropdown:{
            get(){
                return this.ContainerSize
            }
        },
        imgPath () {
        // webpack will replace the given path to import() with the actual "production" image path
            return import('../../../../assets/icons/arrow-right-delivery.svg')
        },
        searchedConsignee: {
			get() {
				return this.newConsignee !== null && this.newConsignee !== '' ? this.newConsignee.toString() : this.newConsignee;
			},
			set(input) {
				let temp = [];
				this.consigneeLists.map((list) => {
					temp.push(list.name);
				});
				if(temp.includes(input)) {
					this.hasNewConsignee = 0;
				}
				else if(this.newConsignee === "" || this.newConsignee === null) {
					this.hasNewConsignee = 0;
				}
				else {
					this.hasNewConsignee = 1;
				}
				this.newConsignee = input;
			},
		},
    },

    methods: {
        clearDate() {
            this.date = null
            this.isDateRequired = false
        },
        checkKeyUpVal(e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                this.$refs.eta_ref_do.hidePopover(); // close popover on click enter
            }
        },
        async onSelectCustomer(selected) {
            if (selected !== null && selected !== '') {
                this.consigneeLists = []
                await this.fetchConsigneeAction(selected)
            }
        },
        async handleNewConsignee() {
            if(this.newConsignee === null || this.newConsignee === "") {
                this.notificationCustom("Please enter consignee name");
            } else {        
                let data = {
                    name: this.newConsignee,
                    customer_id: this.customerdropdown.id,
                };
                await this.createConsignee(data).then(async (res) => {
                    if (res) {
                        let data = res.data
                        this.notificationMessageCustomSuccess(res.message)
                        this.selectedConsignee = data.id
                        this.consigneeLists = []
                        await this.fetchConsigneeAction(data.customer_id)
                    }
                }) 
            }
        },
        async fetchConsigneeAction(id) {
            await this.fetchConsignees(id)
            if (typeof this.getAllConsignees !== 'undefined' && this.getAllConsignees !== null) {
                this.consigneeLists = this.getAllConsignees.results
            }
            this.$refs.consigneeRef.blur();
        },
        checkFormErrors() {
            // check required fields and show error after submitting
            let hasErrors = false

            // ETA
            if (this.date === null || this.date === "") {
                this.isShowETAError = true
            } else {
                this.isShowETAError = false
            }
            // MBL
            if (this.mbl === null || this.mbl === "") {
                this.isShowMBLError = true
            } else {
                this.isShowMBLError = false
            }
            // CUSTOMER NAME
            if (this.customerdropdown === null || this.customerdropdown === "") {
                this.isShowCustomerError = true
            } else {
                this.isShowCustomerError = false
            }
            // GOODS DELIVERY LOCATION
            if (this.gooodsdeliverylocation === null || this.gooodsdeliverylocation === "") {
                this.isShowDeliveryError = true
            } else {
                this.isShowDeliveryError = false
            }

            if (!this.isShowDeliveryError && !this.isShowMBLError && !this.isShowCustomerError && !this.isShowETAError) {
                hasErrors = false
            } else {
                hasErrors = true
            }

            return hasErrors
        },
        checkErrorShowing(e, field) {
            if (e !== "") {
                if (field === 'mbl') {
                    this.isShowMBLError = false
                } else if (field === 'customer_name') {
                    this.isShowCustomerError = false
                } else if (field === 'delivery_location') {
                    this.isShowDeliveryError = false
                } else if (field === 'eta') {
                    this.isShowETAError = false
                    this.isDateRequired = true

                    if (this.date !== null && this.date !== "") {
                        // this.$refs.eta_ref_do.value_.setFullYear(moment().year())
                        if (this.$refs.eta_ref_do.value_.getFullYear() === 2001) {
                            this.$refs.eta_ref_do.value_.setFullYear(moment().year())
                        }
                    }
                }
            }
        },
        onFilter(item, queryText){
             if(item.nickname){
                 return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.nickname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            }
        return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())

        },
        async getDeliveryLocation(val) {
        if (val.length > 4) {
            this.loadingLocationPlaces = true;

            await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/`+val+`.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`)
                .then((response) => response.json())
                .then((json) => {
                    this.loadingLocationPlaces = false
                    this.places = [...json.features]
                })
                .catch((e) => {
                    this.loadingLocationPlaces = false;
                    console.log(e);
                });
        }
    },
    clearSearchPlaces() {
        this.places = [];
    },

    sizecheck(thechecker){
        let value = (thechecker==='')? false:true;
        if(this.hassblickvalidate){
            if(value==false){
                this.errorMessagetext['Sizes'] = 'Size is required.';
            } else{
                value = true;
                this.errorMessagetext['Sizes'] = '';
            }
        }
        if(value){
            this.errorMessagetext['Sizes'] = '';
        }
        return value;
    },
    rulesCheck(thechecker,name){
        let msg = '';
        let value = false;
        if(isNaN(thechecker)){
            msg = name + ' must be numeric';
        }else{
            msg = '';
            value  = true;
        }

        if(thechecker==''){
            msg = name + ' must be numeric';
            value = false;
        }

        if(this.hassblickvalidate){
            if(name=='Weight'){
                this.errorMessagetext['Weight'] = msg;
            }else if(name=='Volume'){
                this.errorMessagetext['Volume'] = msg;
            }else if(name=='Quantity'){
                this.errorMessagetext['Quantity'] = msg;
            }
        }
        return value;
    },
        age(text){
            let value = false;

        let msg = "First 4 characters are alpha and followed by 7 digits.";
        if (text.length == 11) {
            if (
            /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
            /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
                msg = '';
                value = true;
            }
        }
        if(this.hassblickvalidate){
            this.errorMessagetext['container'] = msg;
        }
        return value;
        },
        // getfileurl(){
        //     return process.env.VUE_APP_TRUCKING_ROOT_URL + 'storage/' + this.item.id + '/' + this.docuID;
        // },
        // getFileURL(file) {
        //     return URL.createObjectURL(file);
        // },
         getFileURL(file){
            if(this.hasfileni !=''){
                return (this.hasfileni);
            }else{
                this.hasfileni = URL.createObjectURL(file) + "#toolbar=0";
                return URL.createObjectURL(file) + "#toolbar=0";
           }
        },
        confirmDatePicker(date) {
            if(this.pickerLabel === "Pick ETA Date") {
                this.fields.eta = date
            } else {
                this.containers.lfd = date
            }
            this.isDatePickerOpen = false
        },
         handleAddContainer(){
            this.hassblickvalidate = 1;
            //this.errorMessagetext  = [];
            if(this.$refs.containerForm.validate()) {
                 this.hassblickvalidate = 0;
                this.containerList.push(this.containers)
                this.isAdding = false
                this.containers = {
                    container_number: "",
                    container_size: "",
                    container_weight: "",
                    container_volume: "",
                    container_qty: "",
                    lfd: ""
                }
            }

         },
         addform(){
            //if(this.$refs.containerForm.validate()) {
                    this.fields.containers.push(this.containers)

                    this.isAdding = false
                    this.containers = {
                        container_number: "",
                        container_size: "",
                        container_weight: "",
                        container_volume: "",
                        container_qty: "",
                        lfd: ""
                        // delivery_to: "",
                        // deliveryLongitude: "",
                        // deliveryLatitude: "",
                    }
               // }
         },
         removeme(i) {
            this.fields.containers.splice(i, 1)
        },
        checkvalue(item,name){
            console.log(item,name);
            let haserror = 0;
            // if (item =='' || isNaN(item)) {
            //     this.haserrorContainers = true;
            //     haserror = 1;
            //     if(name=='Weight'){
            //         this.errorMessagetext['Weight'] = 'Weight must a numeric value';
            //     }else if(name=='Volume'){
            //         this.errorMessagetext['Volume'] = 'Volume must a numeric value';
            //     }else if(name=='Quantity'){
            //         this.errorMessagetext['Quantity'] = 'Quantity must a numeric value';
            //     }
            // }else{
            //     this.haserrorContainers = false;
            //     if(name=='Weight'){
            //         this.errorMessagetext['Weight'] = '';
            //     }else if(name=='Volume'){
            //         this.errorMessagetext['Volume'] = '';
            //     }else if(name=='Quantity'){
            //         this.errorMessagetext['Quantity'] = '';
            //     }
            // }

            return haserror;
        },
        validatesize(text){
            let value = 0;
            if(typeof text =='undefined'){
                value = 1;
            }else if(text !=''){
                if(text=='NaN'){
                    value = 1;
                }else if(isNaN(text)){
                    value = 1;
                }
            }else if(text==''){
                value = 1;
            }

            if(value==1){
                this.haserrorContainers = true
                this.errorMessagetext['Sizes'] = 'Size is required.';
            } else{
                this.haserrorContainers = false
                this.errorMessagetext['Sizes'] = '';
            }
            return value;
        },
        validateContainerNumber(text){
            let haserror = 0;  // no error
            // let msg = "Container #,First 4 characters are alpha and followed by 7 digits.";
            let msg = "Container Number's first 4 characters are alpha and followed by 7 digits.";
            if(text !='' && typeof text !='undefined'){

                if(text.length == 11) {
                    if (
                    /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
                    /^[0-9]*$/.test(text.substring(4, text.length))
                    ) {
                        msg = '';
                        this.haserrorContainers = false
                        this.errorMessagetext['container'] = '';
                    }  else{
                        this.haserrorContainers = true;
                        haserror  = 1;
                        this.errorMessagetext['container'] = msg;
                    }
                }else{
                    this.haserrorContainers = true;
                    haserror  = 1;
                    this.errorMessagetext['container'] = msg;
                }
            }else if(text ===''){
                haserror  = 1;
                this.errorMessagetext['container'] = msg;
            }
            return haserror;
        },
        cancelAdding(){
            this.calceladding= true;
            this.isAdding = false;
            this.errorMessagetext= {
                container:'',
                Weight:'',
                Volume:'',
                Quantity:'',
                Sizes:'',
            }

            this.containers = {
                            container_number: "",
                            container_size: "",
                            container_weight: "",
                            container_volume: "",
                            container_qty: "",
                        }
            this.hassblickvalidate = 0;
        },
        addCOntainer(){
            if(this.isAdding){
                this.containerList.push(this.containers)
                this.isAdding = false
                this.containers = {
                    container_number: "",
                    container_size: "",
                    container_weight: "",
                    container_volume: "",
                    container_qty: "",
                    checkbox:0,
                }
            }
            this.isAdding = true;

        },
        removeContainer(i){
             if(this.containerList.length > 1){
                this.containerList.splice(i, 1)
                this.haserrorContainers = false
                this.errorMessagetext = {
                    container:''
                }
             }else{
                this.dialogrequired = true;
             }
          },
          createcustomer(){
            this.customerdropdown = [];
            this.dialogCustomerdialog  = true;
            this.iscustomer = 0;
            this.$nextTick(() => {
                this.customers == this.tempcustomer;
                this.iscustomer = 0;
            });
          },
          submitreject(){
              //this.$emit("update:openDialog",false);
              this.$emit('update:rejectedmsg',true);
          },
            gotoDownload2() {
                this.uploadCompleted = false;
                let passdata = this.objectServerid.id;
                this.isFetching = 1;
                if (passdata !== "") {
                    //let downloadurl  = 'document/download/'+this.deliveryDocumentID;
                    // url: `${poBaseUrl}/download/${passdata}`,
                    axios({
                    url: `${poBaseUrl}/document/download/${this.deliveryDocumentID}`,
                    method: "GET",
                    responseType: "blob",
                    }).then((response) => {
                    this.isFetching = 0;
                    window.URL = window.URL || window.webkitURL;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(
                        new Blob([response.data], {
                        type: "application/octet-stream",
                        })
                    );
                    link.setAttribute("download", "Delivery_order.pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.uploadCompleted = true;
                    });
                }
            },
          showrejectClose(){
            this.showreject = false;

          },

          getcheckvalue(item){
            if( this.delivery_mode =='ocean' && item =='ocean'){
                return 1;
            }

            if( this.delivery_mode =='air' && item =='air'){
                return 1;
            }

            return 0;
          },
          checklastcontainer(){
            let hassfounderror = false;
            let c=0;
            if(this.isAdding){
                this.containerList.push(this.containers)
                this.isAdding = false
            }
            // let valem = '';
            Object.values(this.containerList).forEach((val) => {
                // if(typeof val.container_size =='undefined'){
                //     valem = '';
                // }else{
                //     valem = val.container_size
                // }
              c +=this.validateContainerNumber(val.container_number)
                // this.validatesize(valem) +
                //   this.checkvalue(val.container_weight,'Weight') +
                //   this.checkvalue(val.container_volume,'Volume') +
                //   this.checkvalue(val.container_qty,'Quantity')
            })

            if(c > 0){
                hassfounderror = true;
            }
            return hassfounderror;
        },
        async submitnow(){
            if(this.$refs.form.validate() ) {
                if(this.containerList.length === 0 ){
                   this.dialogrequired = true;
                } else {
                    this.isSubmittingForm = true
                    let formData = new FormData();
                    let eta = this.date !== null ? moment(this.date).format("YYYY-MM-DD") : ""

                    formData.append("ETA", eta);
                    formData.append('flagCreatedFE',2); // review
                    formData.append("xmbl", this.mbl);
                    formData.append('xnote_mbl', this.notembl)
                    formData.append("xcontacts[]",this.timetopass());
                    formData.append("xcarrier", this.CarrierModel);
                    formData.append("xvessel", this.VesselModel);
                    formData.append("xreferrence[]", JSON.stringify(this.timetopassreference()));

                    // formData.append("xcarrier_name", this.fields.carrier_name);
                    // formData.append("xreferrence[]", JSON.stringify(this.timetopassreference));
                    formData.append("xcontainerList", JSON.stringify(this.containerList));
                    //formData.append('xlocation_at_name', this.fields.pickup_location)

                    formData.append('xcustomer', this.customerdropdown)
                    formData.append('container_commodity', this.container_commodity)
                    formData.append('xlocation_at',this.terminalAt)
                    formData.append('location', 'CF')
                    formData.append('xdelivery_location', this.gooodsdeliverylocation)

                    // this.filelist_review.map((file, key) => {
                    //     formData.append(`files[${key}]`, file);
                    // });

                    await axios
                    .post(`${process.env.VUE_APP_PO_URL}/accepting/`+this.item.id, formData)
                    .then((response) => {
                      if(response){
                        this.isSubmittingForm = false
                        console.log(response)
                        this.DO_ID = response.data.DO_ID;
                        this.shipmentIDS = response.data.shipment_id;
                        this.containers_number = response.data.containers_number;
                        this.countshipment = response.data.count_shipment;
                        this.confirm_message = response.data.confirm_message;
                        this.dialogConfirm = true;
                      }
                    })
                    .catch((error) => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                        this.isSubmittingForm = false
                    })
                }
            }          },
          async acceptDO() {
            // haserrorContainers = true ; naay error
            // checkcontainer = true ; naay error
            if(this.checklastcontainer() || this.haserrorContainers){
               this.dialogcontainervalidate = true;
            } else if (this.checkFormErrors()) {
                this.notificationCustom("Please fill the required fields.")
            }else{
                this.submitnow();
            }
           // console.log(this.containerList);
        },

        rejectDO(status){
            if (status !== 'Rejected') {
                this.itemreject = this.item;
                this.showreject = true;
            } else {
                this.openDialogCom_review = false
                this.clearFields()
            }          
        },
        clearFields() {
            this.$refs.form.resetValidation()
            this.date = ""
            this.mbl = ""
            this.CarrierModel = ""
            this.VesselModel = ""
            this.customerdropdown = ""
            this.optionsReferrence = [];
            this.options = [];
            this.containerList = [];
            this.warehouseEmailAddress = '';
            this.containers = {
                container_number: "",
                container_size: "",
                container_weight: "",
                container_volume: "",
                container_qty: "",
                lfd: ""
            }
            this.terminalAt = ""
            this.gooodsdeliverylocation = ""
            this.container_commodity = ""
            this.notembl = ""
            this.hasfileni = ""
            this.isShowETAError = false
            this.isShowMBLError = false
            this.isShowCustomerError = false
            this.isShowDeliveryError = false
            this.containerList = []
            this.haserrorContainers = false;
            this.errorMessagetext = {
                container:'',
                Weight:'',
                Volume:'',
                Quantity:'',
                Sizes:'',
            }
            this.selectedConsignee = null
            this.isDateRequired = false
        },
        closedialog(){
          this.$emit('closeDialog');
          this.clearFields()
          this.initialcompany = ""
        },
        viewdo(){
             window.location.href = '/delivery-order/'+ this.thelinkreturn + '/' + this.sid;
        },
        usernotification(){
            this.istriggerdfrommodal = 1;
            this.shownotification = 0;
        },
        classCheck(cate){
            if(cate.error == 1){
                return 'triggerclasserror';
            }
            return '';
        },
        isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); },
        calleditable(){
            return this.calleditableELe;
        },
        edit(){
           return this.calleditableELe = true;
        },
        clickDownload(){
            this.gotoDownload();
        },
        uploadAgain(){
            jQuery(document).find('.triggerelement2').trigger('click');
        },
        timetopass(){
            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                    return o.text
                }) : []
                return finalEmailAddress;
        },
         timetopassreference(){
            // let finalEmailAddress = (this.optionsReferrence.length > 0) ? this.optionsReferrence.map(o => {
            //         return o.text
            //     }) : []
            //     return finalEmailAddress;
            let finalrefrenceNumber = this.optionsReferrence.length > 0 ?  this.optionsReferrence.map(({text}) => ({customer_ref_number:text})) :[]
            return finalrefrenceNumber;
        },
        ...mapActions({
			fetchFormdetails: 'containers/fetchallformdetails',
            fetchConsignees: 'containers/fetchConsignees',
            createConsignee: 'containers/createConsignee'
        }),
        ...globalMethods,
        blurevent(){
           this.haschages()
           this.checkobjects()
        },
        currentshow(index){
            if(index ==  this.currentindexshow)
            return 1;
        },
        checkeverything(){
            let getcustomerref  = JSON.stringify(this.timetopassreference());
            let contactlist     = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;
            let changes;

            if(this.doInitialvalue !=this.dofinalvalue){
                changes = 1
            }else{
                changes = 0
            }

            if(this.triggerloc=='cancel'){
                return changes;
            }else{
                this.haschangesform = changes
            }

            this.containerlistchanges();
        },
        contacts(response,validEmailAddress){
            if(  response.data.result[0].contacts !=='null' && response.data.result[0].contacts !=null){
                var s = JSON.parse(response.data.result[0].contacts);
                    s.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    })
                    return validEmailAddress
            }
        },
        company(response){
            var that = this;
            // if(response.data.result[0].company_name !=null && response.data.result[0].company_name !=''){
            //     // const newFirstElement = {'company_name':response.data.result[0].company_name,id:999999}
            //     // that.initialcustomer_name = response.data.result[0].company_name;
            //     // that.customers = [newFirstElement].concat(that.customers)
            //     that.initialcompany = response.data.result[0].company_name
            // }

            if (typeof response.data.result[0].company_name !== "undefined" && 
                response.data.result[0].company_name !== null ) {
                that.initialcompany = response.data.result[0].company_name
            } else {
                if (response.data.result[0].source_of_creation === "SD") {
                    that.initialcompany = response.data.result[0].customer_name
                }
            }
        },
        // magamit if click sa save sa taas
        checkobjects(){
            if((this.mbl !== null && this.mbl.length > 0)
                && (this.customerdropdown) !=''
                && this.CarrierModel !=''
                && this.VesselModel !=''
                && this.terminalAt !=''
                //&& this.options.length > 0
                && this.optionsReferrence.length > 0)
            {
                this.noerror = 1
                this.noerrorForm = false;
            }else{
                this.noerror = 0
                this.noerrorForm = true;
            }
        },
        async callpopulatedialog(){
            this.startfetching = 1;
            this.containerList = [];
            var that = this;
            var validEmailAddress = [],ref = [];
            this.isLoading = true

            let url = "get-incoming-do-by-id"

            if (this.item.reviewStatus !== "Pending") {
                url = "get-do-edit-accepted"
            }

            await axios.get(`${poBaseUrl}/${url}/` + this.item.id
            // .get(`${poBaseUrl}/get-do-edit-accepted/` + this.item.id
            )
            .then(function (response) {
                if (response.data) {
                    let getID = 0;
                    if(response.data.deliveryorder_docu.length > 0){
                        getID = response.data.deliveryorder_docu.find(obj => {
                        return obj;
                        });
                        that.hasfile = 0
                        that.docuID = getID.id;
                        that.filenameobj = getID.filename
                        // call download document method here instead
                        that.downloadDocument(getID.id, getID)
                    }

                    let etaDate = response.data.result[0].eta

                    that.carriers = response.data.carrier
                    that.ContainerSize= response.data.sizes
                    //console.log(response.data.carrier);
                    that.terminals = response.data.terminal;
                    // that.date = moment(response.data.result[0].eta).utc(false).format("YYYY-MM-DD");
                    that.date = etaDate !== "" && etaDate !== null ? moment(etaDate).utc(false).format("YYYY-MM-DD") : null;
                    //// alert(response.data.result.eta)
                    that.mbl = response.data.result[0].mbl_number;
                    that.pickup_location = response.data.result[0].location_from;

                    that.customers=response.data.customer
                    that.tempcustomer=response.data.customer
                    that.company(response);

                    // if (response.data.result[0].source_of_creation === "SD") {
                    //     that.customerdropdown = response.data.result[0].customer_name
                    // } else {
                    //     if(response.data.result[0].customer !=null){
                    //         that.customerdropdown = response.data.result[0].customer
                    //         that.selectedConsignee = response.data.result[0].consignee_id
                    //         that.fetchConsigneeAction(that.customerdropdown)
                    //     }
                    // }

                    if(response.data.result[0].customer !=null){
                     that.customerdropdown = response.data.result[0].customer
                     that.selectedConsignee = response.data.result[0].consignee_id
                    that.fetchConsigneeAction(that.customerdropdown)
                    }

                    // Assign to the terminalAt
                    //let letteriminal = 0;
                    if(response.data.result[0].source_of_creation == 'SD') {
                        if(typeof that.terminals != 'undefined') {
                            let findterminal = that.terminals.find((find) => find.firms_code == response.data.result[0].central_firms_code);
                            if(findterminal != null) {
                                if(findterminal.map_location != '') {
                                   that.terminalAt = findterminal.id
                                }
                            }
                        }
                    } else {
                        that.terminalAt = response.data.result[0].terminal_id ?? null
                    }

                    that.typeoflocation = response.data.result[0].source_of_creation;

                    if(response.data.result[0].delivery_location !==''){
                    that.gooodsdeliverylocation = response.data.result[0].delivery_location;
                    if(that.gooodsdeliverylocation !==null)
                    that.getDeliveryLocation(that.gooodsdeliverylocation);
                    }


                    if(response.data.result[0].mode!==''){
                        that.delivery_mode = response.data.result[0].mode
                    }

                    that.options = that.contacts(response,validEmailAddress);
                    that.CarrierModel = response.data.result[0].carrier_id;

                    that.VesselModel =  response.data.result[0].vessel

                    var refer = response.data.result[0].customer_ref_number
                    if(refer !==null){
                        if( refer.length > 0 ){
                            (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                    if (wea!==null) {
                                        ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                    }
                            })
                        }
                        that.optionsReferrence = ref
                    }

                    if(response.data.result[0].container_commodity != null) {
                        that.container_commodity = response.data.result[0].container_commodity;
                    }

                    if(response.data.result[0].description != null) {
                        that.notembl = response.data.result[0].description;
                    }

                    var getcontainersfromdelivery = response.data.result[0].containers;

                    if(response.data.result[0].source_of_creation == 'CF'  ||
                       response.data.result[0].source_of_creation == 'BO'){
                       that.pcontain(getcontainersfromdelivery,'ac',response);
                    }else if(response.data.result[0].source_of_creation == 'SD'){
                       let sdcontainers = response.data.result[0].containers_group_bookings;
                       that.pcontain(sdcontainers,'bc',response);
                    }
                    that.refresh = 0;
                    that.isLoading = false

                    that.$nextTick(() => {
                        that.refresh = 1;
                    });
                    that.startfetching = 0;
                    }
            }).catch(error => {
                console.log(error)
                that.startfetching = 0;
            })
        },
        getdata(item){
            if(this.currentstatepicker == 'date'){
                this.date = item;
            }
        },
        haschages(){
            let getcustomerref = JSON.stringify(this.timetopassreference());
            let contactlist = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;

            if(this.doInitialvalue !=this.dofinalvalue){
               this.haschangesform = 1
            }else{
               this.haschangesform = 0
            }

        },
        pcontain(containers,loc,response){
            let that = this;
            if(containers !=null){
            if(containers.length > 0){
                let containersListdraft  = JSON.parse(containers)
                containersListdraft.map((wea,i) => {
                    if (wea!==null) {
                        that.containerList.push({
                            id:i,
                            container_id :( loc == 'bc' ) ? '' : ( response.data.result[0].source_of_creation == 'BO' ? wea.container_id : '' ) ,
                            container_number: (loc == 'bc') ?  wea.container_num : wea.Container,
                            container_size: (loc =='bc') ? wea.size : parseInt(wea.Sizes),
                            container_volume: (loc == 'bc') ? wea.cbm : wea.VolumeNumberofCBM,
                            container_weight: (loc == 'bc') ? wea.kg : wea.WeightNumberofKG,
                            container_qty: (loc =='bc') ? wea.cartons : wea.CartonCount,
                            delivery_to :  '', // response.data.data.deliver_to ,
                            //lfd: response.data.result[0].source_of_creation == 'BO' ? '' : wea.lfd,
                            checked:0,
                            error_0:0
                        })
                    }
                })
            }}
        },
       async createdo(item){
        var that = this;

            if(item.state=='createdo'){
                this.cleancontainerlist(that.containerList);
                this.ismove = 1; // copy ug magbuhat ug bag-ong delivery
            }else if(item.state=='moveDo'){
                this.movetoexisting = 1;
                this.selectedExistingDeliveryID = item.selected; // Do id
                this.selectedshifl_ref = item.shifl_ref; // Do id
                // Fetch the containers of the selected DO
                // SelectedExistingDeliveryID
                // kuhaon para e merge ang duha selected ug moving do containers


                   // lihug dili e delete kay magamit ni puhon
                    //gamit e merge ang selected DO with the current DO to be moved
                    await axios.get(`${poBaseUrl}/gethipmentbydeliveryorderid/`+item.selected)
                    .then(function (response) {
                        if (response.data) {
                            var getobject = _.filter(that.containerList,function(o){return o.checked;});
                            that.containerList = getobject
                            that.existingdeliveryobject = response.data.data
                            response.data.data.map(wea => {
                                if (wea!==null) {
                                    // push to get the existing containers
                                that.pushobject(wea);
                                }
                            })
                            }
                    }).catch(error => {
                        console.log(error)
                    })


                //var validEmailAddress = [],ref = [];
                this.isLoading = true
                await axios
                .get(`${poBaseUrl}/getdeliveryorderbyid/` + item.selected
                )
                .then(function (response) {
                    if (response.data) {
                        that.date = moment(response.data.data.eta).utc(false).format("YYYY-MM-DD");
                        that.mbl = response.data.data.mbl_number;
                        that.customerdropdown =  parseInt(response.data.data.customer);

                        var s = JSON.parse(response.data.data.contacts);
                        s.map(wea => {
                            if (wea!==null) {
                               // validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                            }
                        })

                       // that.options = validEmailAddress
                        that.CarrierModel = response.data.data.carrier_id;

                        //that.orderdeliveryID =  response.data.data.shifl_ref
                        that.VesselModel =  response.data.data.vessel

                        var refer = response.data.data.customer_ref_number

                        if( refer.length > 0 ){
                            (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {
                                    //ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                            })
                        }

                        //that.optionsReferrence = ref
                        that.terminalAt = response.data.data.terminal_id

                        if(response.data.data.container_commodity != null) {
                            that.container_commodity = response.data.data.container_commodity;
                        }

                        if(response.data.data.description != null) {
                            that.notembl = response.data.data.description;
                        }

                        //     /// GET THE CONTAINER FROM DELIVERY_ORDER_SHIPMENT
                        //     var getcontainersfromdelivery = response.data.delivery_containers;
                        //     var getAllshipment = (response.data.shipment);

                        //     if(getAllshipment.length){
                        //         _.forEach(getAllshipment,function(value){
                        //             that.allshipmentedits.push(value);
                        //         });
                        //     }
                        // // var test;
                        //     getcontainersfromdelivery.map(wea => {
                        //         if (wea!==null) {
                        //             that.pushobject(wea);
                        //         }
                        //     })

                        if(response.data.data.documents.length > 0) {
                            that.hasContent = 'cDownloadfiles hidethisdiv'
                            that.documents = response.data.data.documents
                        }else {
                            that.hasContent = 'cDownloadfiles'
                            that.documents = [];
                        }

                        //that.containerList = containers;
                        that.refresh = 0;
                        that.isLoading = false

                        that.$nextTick(() => {
                            that.refresh = 1;
                        });
                    }
                }).catch(error => {
                    console.log(error)
                })

                //this.cleancontainerlist(that.containerList);
                this.ismove = 2;
            }
        },
        closeDialogpicker(){
            this.dialogDatepicker = false;
        },
        deleteClear(i){
            this.containerList.splice(i, 1);
            this.checkobjects()
        },
        cleancontainerlist(clList){
            this.containerList = _.filter(clList,function(o){return o.checked;});
        },
        pushobject(wea){
             var that = this;
             that.containerList.push({
                container_id : wea.shipment_id,
                container_number: wea.container_number,
                container_size:wea.size,
                container_volume: wea.volume,
                container_weight: wea.weight,
                container_qty: wea.qty,
                delivery_to :  wea.address, // response.data.data.deliver_to ,
                checked:0,
                error_0:0
                //error_1:0,error_2:0,error_3:0,error_4:0
            })

            that.refresh = 0;
            that.$nextTick(() => {
                that.refresh = 1;
            });
        },
        saveForm(){
            //var redirectid = 0;
            this.startloading = 1;
            this.sid = this.$route.params.sid;
            this.thdid = this.$route.params.id;

            let formData = new FormData(document.getElementById('sform'))
            formData.append('xmbl',this.mbl);

            if(typeof this.CarrierModel !=='object'){
                var carriene = this.carriers.find((d) => d.id == this.CarrierModel);
                this.CarrierModel = carriene;
            }

            // if(typeof this.terminalAt !=='object'){
            //     var termi = this.terminals.find((d) => d.name == this.terminalAt);
            //     this.terminalAt = termi;
            // }

            formData.append('xcustomer',(typeof this.customerdropdown=='object') ? this.customerdropdown.id : this.customerdropdown);
            formData.append('xcontacts[]',this.timetopass());
            formData.append('xcarrier', (typeof this.CarrierModel=='object') ? this.CarrierModel.id : this.CarrierModel);
            formData.append('xcarrier_name',(this.CarrierModel.name));
            formData.append('xvessel',this.VesselModel);

            formData.append('do_move_mode',this.ismove);
            if(this.ismove==2){
                //redirectid = this.selectedExistingDeliveryID
                formData.append('existing_do_id',this.selectedExistingDeliveryID); // selected delivery order
                formData.append('xedit_id_of_moved_do',this.$route.params.id); // id of the moved do
            } else {
                //redirectid = this.$route.params.id
            }
            // do_move_mode
            // 1 is move or copy
            // 2 is move to existing
            // 0 is default
            var getun = _.filter(this.containerList,function(o){return!o.checked;});
            formData.append('xexclude_container', JSON.stringify(getun));
            formData.append('xreferrence[]', JSON.stringify(this.timetopassreference()));
            formData.append('xlocation_at', (typeof this.terminalAt=='object') ? this.terminalAt.id : this.terminalAt); // Change into ID
            // formData.append('xlocation_at_name',this.terminalAt.name);
            formData.append('xnote_mbl',this.notembl);
            formData.append('xcontainerList', JSON.stringify(this.containerList));
            formData.append('container_commodity',(this.container_commodity));
            formData.append('xedit',this.$route.params.id);
            formData.append("consignee_id", this.selectedConsignee);

            axios.post(`${poBaseUrl}/orderdelivery-update`,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.startloading = 0;
                this.showcontaineradded = 0;
                if(response){
                    this.DO_ID            = response.DO_ID;
                    this.shipmentIDS      = response.shipment_id
                    this.containers_number= response.containers_number
                    this.countshipment    = response.count_shipment
                    this.confirm_message  = response.confirm_message

                    this.dialogConfirm = true;
                    this.thelinkreturn = response.DO_ID;
                    this.snackbarcontent = true;
                    if(this.ismove==1){
                       // this.snackbar = true;
                     }else if(this.ismove==2){
                        //this.snackbar = true;
                        this.isSavingConfirm = true;
                    }else{
                        //this.snackbar = true;
                        this.snackbarcontent = false;
                        //setTimeout(function () {
                            //window.location.href = '/delivery-order/'+ this.thdid + '/' + this.sid;
                        //}.bind(this), 2000)
                    }
                }
            })
        },
        clicsubmit(){
            if( this.$refs.form.validate() ){
                //this.dialogConfirm = true;
                this.saveForm();
            }
        },
        goback(){
            location.reload(true);
        },
        cancelDialog(item){
            if(item.isCancel)
            this.dialogConfirm = false;
        },
        continueDialog(item){
            if(item.isConfirmed){
               // this.dialogConfirm = false;
               this.dialogConfirm = false;
               //location.reload();
               /*if(item.isConfirmed){
                    this.saveForm();
                }*/
            }
        },
        continueDialogUpdateBox(){
            location.reload();
        },
        saveUpdated(item){
            if(item.updated)  {
               this.containerList = item.data;
            }

            if(item.hasError){
                this.noerror = 0
                this.noerrorForm = true;
            } else {
                this.noerror = 1
                this.noerrorForm = false;
            }
        },
        validateDate(dateStr){
            let str = dateStr.split('T');
            dateStr = str[0];
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            if (dateStr.match(regex) === null) {
            return false;
            }
            const date = new Date(dateStr);
            const timestamp = date.getTime();
            if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
            }
            return date.toISOString().startsWith(dateStr);
        },
        showcustom(state,m){
            this.pickerDefaultdata = state;
            this.currentstatepicker = m;
            this.dialogDatepicker = true;
        },
        checklengthdata(item){
            console.log(item)
            if(item.value===0)
            {
                 this.containerList = [];
            }
            this.checkobjects()
        },
        emitedit(data){
            this.showedit = data.show;
            this.keysvalue = data.data[data.index]
            this.keytovalue = data.index
        },
         updateContainer(item){
            console.log(item)
            //this.containerList[this.keytovalue] =  item
            var sizesize  = (item.container_size)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            if(typeof getsize !='undefined') {
                item.container_size = String(getsize.id)
            }
            this.containerList[this.keytovalue] =  item

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {
                data = {
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            "delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }
                dis.getlistasstring.push(data);
            })


            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });

            this.checkobjects()
            this.checkeverything();
        },
        saveContainer(item){
            var sizesize  = (item.container_size)
            var dt  = (item.delivery_to)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            item.container_size = (getsize.id)
            this.lastIndexData = dt
            this.containerList.push(item);

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {
                data = {
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            //"delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }
                dis.getlistasstring.push(data);
            })

            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });
           // this.updated = 1
            this.checkobjects()
            this.checkeverything();
            this.showcontaineradded = 1;
           // this.addContainerNotification('<span style="color:#16B442 !important" class="text-message">Container was successfully added</span>')
        },
        confirmCancel(){
            if(this.triggerloc !=''){
                this.redirect();
            }else{
                location.reload()
            }
        },
        closecancel(){
            this.showcancel = 0
        },
        cancel() {
            if(this.ismove){
                this.showcancel = 1
            }else{
                this.triggerloc='cancel'
                if(this.checkeverything()){
                    this.showcancel = 1
                }else{
                    this.redirect();
                }
            }
        },
        formReset(){
             this.$router.push('/containers')
        },
        closenotallowed(c){
            if(c){
                this.notallowed = 0;
            }
        },
        redirect(){
            if(this.$route.params.sid){
                this.$router.push('/container/edit/'+this.$route.params.sid)
            }else{
                this.$router.push('/containers')
            }
        },
        containerlistchanges(){
            this.finalcontainerListdata = this.getlistasstring;
            var einit = (this.initcontainerListdata);
            var finalval = JSON.stringify(this.finalcontainerListdata);
            let loctype;

            if(einit === finalval ){
                loctype = 0;
            }else{
                loctype = 1;
            }

            if(this.triggerloc=='cancel'){
              return loctype;
            }else{
              this.haschangesform = loctype;
            }
        },
        addContainer(){
            var s = this.containerList;
            console.log(s);
            this.dialog = true;
        },
        dataDown(value){
            this.formFieldlength++;
            this.currentDropdownState = value
            this.currentSelectTab = value
        },
        checkindex(index){
            this.currentindexshow = index
            jQuery('.tablecontainer').css({'background':'none'})
            jQuery(".blueteam_"+index).css({'background':'#F0FBFF'});
        },
        getPrelims: function(data) {
          let response = data;
          let customers = (response.data.customers);

          let DataCustomer=[];
              customers.forEach((value) => {
              DataCustomer.push({id:value.id ,name:value.company_name});
          });

          this.itemsDropdown = DataCustomer;

          let terminal_at = (response.data.terminal);
              terminal_at.forEach((value) => {
              this.TERMINAL_AT.push({id:value.id,name:value.name});
          });

          let size = (response.data.size);
              size.forEach((value) => {
              this.containerSize.push({id:value.id,name:value.name});
          });

          let containertype = (response.data.type);
              containertype.forEach((value) => {
              this.containerType.push({id:value.id,name:value.name});
          });

          let truckersID = (response.data.trucker);
              truckersID.forEach((value) => {
              this.truckers.push({id:value.id,name:value.name});
          });
          this.startloading = 0;
        },
        onChangereview() {
            // this.filelist_review = [...this.$refs.file_review.files];
            // let table = this.filelist_review;
            // let errorCount = 0;
            // let keys = [];

            // Object.keys(table)
            // .forEach(function eachKey(key){
            //     if(table[key].type !='application/pdf')
            //     {
            //         keys.push(key)
            //         errorCount++;
            //     }
            // });

            // if(errorCount> 0) {
            //     this.errorFile =1
            //     this.allFieldsValid = false;
            // }  else{
            //     this.errorFile =0
            //     this.allFieldsValid = true;
            // }

            // keys.forEach(e => {
            //     this.filelist_review.splice(e, keys.length+1)
            // });

            // if(this.filelist_review.length > 1) {
            //     this.filelist_review.splice(1, this.filelist_review.length)
            //     this.moreFilesError = true
            // }
            // else {
            //     this.moreFilesError = false
            // }

            let files = this.$refs.file_review.files;

            if (!files.length) {
                return false;
            }

            if (files[0].type !== "application/pdf") {
                this.notificationCustom("Invalid File Type")
            } else {
                this.filelist_review = files
            }
        },
        remove(i,e) {
        this.uploadTrigger = false;
        try {
            axios.delete(`${poBaseUrl}/document/remove/` + e.id)
            .then((response) => {
                if(response.status === 200) {
                    this.hasContent = 'hasContentFiles2'
                    this.documents = []
                }

                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.responseDatatempt = response;
            })

            this.filelist_review.splice(i, 1);
            if( this.filelist_review.length == 0){
                this.errorFile = 0
                this.allFieldsValid = true;
            }
        } catch (err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
    },
    gotoDownload(){
        let passdata = this.objectServerid.id
        this.isFetching = 1;
        if(passdata !==''){
            axios({
                url: `${poBaseUrl}/newshipment/download/${passdata}`,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                this.isFetching = 0;
                window.URL = window.URL || window.webkitURL;
                const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data],{type: "application/octet-stream"}));
                    link.setAttribute('download', 'Delivery_order.pdf');
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link);
            })
        }
    },
    downloadDocument(documentID, data){
        let passdata = documentID
        let that = this;
        this.isFetching = 1;
        let c = [];

        let is_generated = data.is_generated === 1 ? true : false

        if(passdata !==''){
            if (!is_generated) {
                axios({
                    url: `${poBaseUrl}/document/download/${passdata}`,
                    method: 'GET',
                    responseType: 'blob'
                })
                .then((response) => {
                    this.isFetching = 0;
                    window.URL = window.URL || window.webkitURL;
                    //const link = document.createElement('a');
                    var json =(response.data);
                    // console.log(response)
                    //link.href = window.URL.createObjectURL(new Blob([json],{type: "application/octet-stream"}));
                    //var link2=window.URL.createObjectURL(new Blob([json],{type: "application/octet-stream"}));

                    c[0] = new File([json], that.filenameobj , { lastModified: new Date().getTime(), type: 'application/pdf' })
                    console.log(c)
                    this.filelist_review = [];
                    this.filelist_review = c;
                }).catch(error => {
                    console.log(error);
                    this.isFetching = 0;
                })
            } else {
                this.callGeneratedDocument(data)
            }
        }
    },
    callGeneratedDocument(data) {
        let that = this;
        this.isFetching = 1;
        let c = [];

        let payload = {
            path: data.filepath,
            original_name: data.original_name
        }

        axios.post(`${baseUrl}/v2/shipment/do-document`, payload, {
            responseType: 'blob'
        })
        .then((response) => {
            this.isFetching = 0;
            window.URL = window.URL || window.webkitURL;
            var json = (response.data);
            // console.log(response)

            c[0] = new File([json], that.filenameobj , { lastModified: new Date().getTime(), type: 'application/pdf' })
            console.log(c)
            this.filelist_review = [];
            this.filelist_review = c;
        }).catch(error => {
            console.log(error);
            this.isFetching = 0;
        })
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file_review.files = event.dataTransfer.files;
      this.onChangereview();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    async callUPdocument(data){
        if(data)
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        var formData = new FormData();
        let idparam  = (this.selectedExistingDeliveryID !=0) ? this.selectedExistingDeliveryID : this.$route.params.id;
        try {
            formData.append(`deliveryOrderId`, idparam)
            data.map((file, key) => {
                formData.append(`files[${key}]`, file)
            })
            await axios.post(`${poBaseUrl}/document/upload_file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    this.filelist_review = []
                    this.documents = response.data.data
                }
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
      },
      handleReplace() {
        this.$refs.file_review.click()
        //this.document = documentID
        // jQuery(document).find('.uploadElement').hide();
        // jQuery(document).find(".triggerelement2").trigger("click");
      },
      onReplace() {
        let file = document.getElementById('replaceFieldHandle');
        this.filelist_review = file.files
        let table = this.filelist_review;
        let errorCount = 0;

        Object.keys(table)
        .forEach(function eachKey(key){
            if(table[key].type !='application/pdf')
            {
                errorCount++;
            }
        });

        if(errorCount> 0) {
            this.errorFile =1
            this.allFieldsValid = false;
            this.filelist_review = []
        } else{
            this.errorFile =0
            this.allFieldsValid = true;
            this.replaceDocument()
        }
      },
      replaceDocument() {
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        this.documents = []

        var formData = new FormData();
        try {
            formData.append(`files[0]`, this.filelist_review[0])

            axios.post(`${poBaseUrl}/document/replace_file/${this.document}`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                this.filelist_review = []
                this.documents = response.data.data
                this.uploadCompleted = true
                this.uploadTrigger = false
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
      },
      getDeliveryLocationPlaceHolder(){
        if(!this.item.delivery_location && this.item.source_of_creation == 'SD' && this.item.description){
          return this.item.description
        }
        return 'Type Delivery Addresss'
      },
      handleETAValueChange(e) {
        if (e.value === null) {
            this.isShowETAError = true
        } else {
            this.isShowETAError = false
            this.date = this.date !== '' ? moment(this.date).format("YYYY-MM-DD") : ""
        }
        },
        removeFileUpload() {
            this.filelist_review = [];
            this.hasfileni = "";
        }
    },
    updated(){
        if(this.ismove==1){
            if(this.istriggerdfrommodal==0){
                this.shownotification = 1;
            }
        }
    },
    async mounted(){
        //alert('mounted')
        //await this.getOrderbyId()
        //this.checkobjects()

        this.initcontainerListdata = JSON.stringify(this.containerList);
        // let getcustomerref = JSON.stringify(this.timetopassreference());
        //let contactlist = JSON.stringify(this.timetopass())
        //this.doInitialvalue = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;
        // this.$store.dispatch("page/setPage", "delivery-order");
    }
};
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/containers/containersTable.scss';
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/buttons.scss';

.dialogClassDos.create-do {
	.v-card {
        .v-card__title {
            border-bottom: 2px solid #ebf2f5 !important;
        }

		.v-card__text {
            .deliveryOrderColumn {
                width: 100% !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
                padding-top: 0 !important;
            }

            .text-item-label {
                font-family: 'Inter-SemiBold', sans-serif !important;
                font-size: 10px !important;
                text-transform: uppercase !important;
            }

            .v-input {
                .v-input__control {
                    .v-input__slot {
                    padding: 4px 12px !important;

                    &::after {
                        background-color: #4a4a4a !important;
                        border-color: #4a4a4a !important;
                    }
                    }
                }

                &.icon-append-custom {
                    .v-input__control {
                    .v-input__slot {
                        .v-select__slot {
                        .v-input__append-inner {
                            margin-top: 4px !important;

                            i {
                            color: #4a4a4a !important;
                            }
                        }
                        }
                    }
                    }
                }

                &.textarea-commodity {
                    .v-input__control {
                    .v-input__slot {
                        padding-top: 8px !important;
                        padding-right: 4px !important; // to set padding outside the textarea

                        textarea {
                        font-size: 14px !important;
                        line-height: 20px;
                        padding-right: 12px;
                        font-family: 'Inter-Regular', sans-serif;
                        }
                    }
                    }
                }

                &.create-do-eta {
                    .v-input__control {
                    .v-input__slot {
                        .v-input__append-inner {
                        padding-left: 0;

                        .v-input__icon {
                            .ic-calendar {
                            padding-top: 6px;
                            }
                        }
                        }
                    }
                    }
                }

                &.select-consignee.v-input--is-disabled {
                    .v-input__control {
                        .v-input__slot {
                            background-color: #EBF2F5;
                            border-color: #EBF2F5 !important;
                            border-radius: 4px;
                        }
                    }
                }

                input {
                    font-family: 'Inter-Regular', sans-serif !important;
                    color: #4a4a4a !important;
                }
            }

            .row-locations {
                .box {
                    display: flex;
                    flex-direction: column;

                    .text-item-label {
                        margin-bottom: 4px;
                    }

                    .v-input {
                        &.Goodsdeliverylocation {
                            .v-input__control {
                                height: 100%;

                                .v-input__slot{
                                    height: 100%;
                                    padding: 8px 12px !important;

                                    .v-select__slot {
                                        min-height: 60px !important;

                                        .v-select__selections {
                                            align-self: flex-start;
                                            flex-wrap: wrap;
                                        }

                                        input {
                                            align-self: flex-start;
                                            padding: 0;
                                        }

                                        .v-label {
                                            color: #4a4a4a!important;
                                            font-family: "Inter-Regular", sans-serif;
                                            font-size: 14px;
                                            position: unset !important;
                                            overflow: unset !important;
                                            white-space: normal !important;
                                            height: 100% !important;
                                            padding: 0;
                                        }

                                        .v-input__append-inner{
                                            display: block !important;

                                            .v-input__icon {
                                                height: 20px;

                                                i {
                                                    transition: none !important;
                                                    transform: none !important;

                                                    &.mdi-menu-down {
                                                        width: 26px !important;

                                                        &::before {
                                                            content: '' !important;
                                                            background-image: url('/icons/search-address.svg') !important;
                                                            background-position: center !important;
                                                            background-repeat: no-repeat !important;
                                                            background-size: cover !important;
                                                            width: 16px !important;
                                                            height: 18px !important;
                                                            opacity: .9;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.container-starting-location {
                                .v-menu__content {
                                    .v-list {
                                        padding: 0 !important;

                                        .v-list-item {
                                            border-bottom: 1px solid #EBF2F5;
                                            padding: 6px 16px;

                                            .listDropdown {
                                                color: #4a4a4a !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // dxdatebox ETA
            .create-do-eta-dxdate {
                height: 42px;
                border: 1px solid #B4CFE0;
                border-radius: 4px;

                input {
                    font-family: 'Inter-Regular', sans-serif;
                    caret-color: #4a4a4a;
                    color: #4a4a4a;
                    padding-left: 12px;
                }

                .dx-placeholder {
                    color: #B4CFE0 !important;

                    &::before {
                        padding-left: 12px;
                    }
                }

                .dx-texteditor-buttons-container {
                    .dx-dropdowneditor-button {
                        width: 45px;

                        .dx-dropdowneditor-icon {
                            width: 45px;
                            color: #4a4a4a;

                            &::before {
                            content: "";
                            background-image: url('/icons/calendar-black.svg') !important;
                            background-position: center !important;
                            background-repeat: no-repeat !important;
                            background-size: cover !important;
                            width: 18px !important;
                            height: 18px !important;
                            }
                        }
                    }
                }
            }

            .add-containers-table.containerListingTable.review-do {
                table {
                    thead {
                        tr {
                            th {
                                padding: 0 8px;
                                box-shadow: none !important;
                                font-size: 12px !important;
                                height: 40px;

                                &:first-child {
                                    padding-left: 16px;
                                }

                                span {
                                    font-size: 12px !important;
                                }
                            }
                        }
                    }

                    tbody {
                        .v-input {
                            .v-input__control {
                                .v-input__slot {
                                    padding: 0 !important;

                                    .v-select__slot {
                                        padding: 2px 0 2px 8px !important;

                                        .v-select__selection--comma {
                                            font-size: 14px !important;
                                        }
                                    }

                                    .v-input__append-inner {
                                        padding-top: 14px;
                                    }

                                    .v-text-field__suffix {
                                        color: #819fb2;
                                        line-height: unset;
                                        padding-right: 8px;
                                    }
                                }
                            }

                            &.text-input-end {
                                input {
                                    text-align: right !important;
                                }
                            }

                            &.container-checkbox-custom {
                                .v-input__control {
                                    .v-input__slot {
                                        padding: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

			/* Scrollbar */
			&::-webkit-scrollbar {
				width: 12px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background-color: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background-color: #e2e2e2;
				border-radius: 6px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background-color: #e2e2e2;
			}
		}
	}
}

.loading-wrapper-do {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: #4A4A4A40;
}

.p-form-errors {
    color: #ff5252;
    font-size: 12px;
    font-family: 'Inter-Regular', sans-serif;
    margin-top: 4px;
    margin-bottom: 0 !important;
}
</style>
<style>
    .errorList {
        font-size: 12px;
        padding-left:0px !important;
        margin:0px;
    }
    .fieldClass {
        margin-top: 5px;
    }

    .popupdialogDos .fieldClass .v-input__control .v-input__slot {
        background: none !important;
    }
    .containerListingTable .v-data-table__wrapper > table > tbody > tr > td{
        padding:0px !important;
    }
    .add-btn {
        cursor: pointer;
        background: #0171A1;
        color: white;
        padding: 2px 8px 6px 8px;
        border-radius: 50%;
        position: relative;
        top:-12px
    }
    .add-containers-table div.v-data-table__wrapper table tr .v-input__control
    .v-input__slot .v-text-field__slot input{
        width: 55px !important;
    }

    .add-containers-table div.v-data-table__wrapper table tr .v-input__control
    .v-input__slot .v-select__slot input{
        width: 43px !important;
    }

    /* .add-containers-table div.v-data-table__wrapper table tr .v-input__control
    .v-text-field__details{
        display:block !important
    }   */

    .add-containers-table > div.v-data-table__wrapper > table > thead > tr > th[role="columnheader"] {
        font-style: normal;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #6D858F !important;
        height: 38px;
        border-bottom: 1px solid #EBF2F5 !important;
        background: #F7F7F7;
        text-transform: uppercase;
    }

    .add-containers-table > div.v-data-table__wrapper > table > tbody > tr > td {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        border: 0px solid #EBF2F5;
    }
    .add-containers-table > div.v-data-table__wrapper > table > tbody > tr > td p{
       padding-top: 9px;
    }
    .add-containers-table > div.v-data-table__wrapper > table > tbody > tr > td:last-child{
        background:#fff !important;
        text-align: center !important;
    }
    .containerListingTable .text-fields {
        margin-top: 0px !important;
        position: relative;
        top: 0px;
    }

    .containerListingTable .v-input__control .v-input__slot  {
        padding:0px;
        border-radius: 0px;
        border-top:0px;
        border-left: 0px solid #B4CFE0;
        border-right: 0px solid #B4CFE0;
        margin-bottom: 0px !important;
        border-bottom:0px !important;
    }

    .containerListingTable .quantifyFields .v-input__control .v-input__slot  {
        padding:0px;
        border-radius: 0px;
        border-top:0px;
        border-left: 0px solid #B4CFE0;
        border-right: 0px solid #B4CFE0;
    }

    .containerListingTable .v-input__control .v-input__slot .v-text-field__slot input {
        padding-left:14px;
    }
     .containerListingTable .v-input__control .v-input__slot .v-select__slot{
		padding-bottom: 3px;
		padding-top: 5px;
		padding-left: 4px;
	}
    .containerListingTable .listing_table td {
        border-right: 1px solid #EBF2F5  !important;
        padding-right: 6px;
        padding-bottom: 10px;
        color: #4A4A4A;
    }
    .containerListingTable .sizeClass .v-input__control .v-input__slot .v-input__append-inner {
        padding-top:4px !important;
    }

    .containerListingTable .v-input__control .v-input__slot .v-input__append-inner {
        padding-top:15px;
        padding-right:7px;
    }
    .containerListingTable tr.listing_table td.cl{
        border-bottom: 1px solid #EBF2F5 !important;
        padding-right: 0px !important;
        height: 39px;
    }

    .containerListingTable tr.addingnewrow td{
        border-bottom: 1px solid #EBF2F5 !important;
        padding-right: 6px !important;
       border-left: 1px solid rgb(235, 242, 245) !important;
        border-bottom: 1px solid rgb(235, 242, 245) !important;
        padding-bottom: 0px !important;
    }

    .containerListingTable  tr td::hover{
        background: none !important
    }
    .containerListingTable tbody   tr:hover {
        background-color: transparent !important;
    }

    .containerListingTable tr::last-child td {
        border-bottom:0px !important
    }

    .addcontainer {
        font-size: 14px;
        padding-top: 10px;
        border: 1px solid #EBF2F5;
        width: 175px;
        padding-bottom: 10px;
        padding-left: 14px;
        color: #0171A1;
        font-weight: 600;
    }
    .popupdialogDos .v-input__slot {
      box-shadow: none !important;
    }

    .popupdialogDos .v-select--is-menu-active{
      background:none !important;
    }

    .popupdialogDos .v-text-field.v-text-field--solo .v-input__control {
      min-height: 41px !important;
    }

    .popupdialogDos .v-input__slot::before{
      border:0px !important;
    }

    .popupdialogDos .v-input__slot{
        border:1px solid #B4CFE0;
        padding:3.5px 16px;
        border-radius: 4px;
    }

   .popupdialogDos input[type='text']{
    font-size:14px;
   }
   .popupdialogDos .v-text-field {
      padding-top:0px;
   }

   .popupdialogDos small {
      font-size:10px;
   }
   /* New style */
    .topheader {
        padding-left: 19px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 4px
    }
    .topheader li {
        color:#6D858F !important;
        display: inline-block;
        width: 19%;
        word-break: break-all;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600px;
    }
    .addContainer {
        border-bottom: 1px solid #eee;
        border-top: 0px solid #eee;
        font-size: 14px;
        margin-top: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
    }
    .leftcol {
        border-right: 1px solid #eee;
    }
    .data-element .item {
        border-bottom: 0px solid #eee;
        padding: 6px
    }
    .headerContainer {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;

        color: #4A4A4A;
    }
    .tablecontainer {
        padding:0 5px;
        border-bottom: 1px solid #EBF2F5;
        border-top: 1px solid #EBF2F5;
        font-size: 14px;
    }
    .tablecontainer:hover{
        background: rgb(240, 251, 255) none repeat scroll 0% 0% !important
    }
    .tablecontainer li {
        display: inline-block;
        width: 19%;
        word-break: break-all;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 3px;
    }

    .uploadDocuwrapper  {
        margin-top: 7px !important;
    }

    .containers-wrappers {
        padding-top: 8px !important;
    }

    .container-create {
        padding-top: 0px !important;
        padding-bottom: 12px !important;
    }

    .container-create .toolbHeader .v-toolbar__content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .toolbHeader {
        margin-bottom: 4px;
    }

    .container-create .toolbHeader{
        background: none !important;
    }

    .NewContainerShipmentTitle {
        font-size: 24px;
        font-weight: 600;
    }

.colright {
    width: 67.7% !important;
    padding-left: 11px !important;
    padding-top: 3px !important;
    padding-right: 16px !important;
}
    .deliveryOrderColumn {
        background-color: #fff;
        padding: 0px;
        width:  97.6%;
        margin-left: 8px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .vue-tags-input {
        max-width: 100% !important;
    }

    .ti-new-tag-input {
        caret-color: #000 !important;
    }

    .ti-tags .ti-tag {
        background:#F7F7F7;
        color:#4A4A4A
    }

    div.ti-input {
        border-radius: 4px;
        border: 1px solid #B4CFE0 !important;
        width: 100% !important;
    }

    .uploadElement .hasContentFiles2 {
        background: #fff !important;
        padding:6px !important;
        display: block !important;
        height: 106px !important;
        border:2px dashed #B4CFE0 !important;
        border-radius: 5px;
    }

    .browserInput2 {
     width: 100%;
}

.browseFileDelivery {
    width: 100% !important;
    text-align: center;
    color:#B4CFE0 !important;
    padding-top: 12px;
    font-size: 14px !important;
}

.browseButton2 {
    width: 79px;
    font-size: 12px;
    margin:0 auto;
    margin-top: 6px;
}

.wrappIconbutton2 {
    border: 1px solid #B4CFE0;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-top:21px
}
.classUpload {
     font-size: 12px;
    color: #0171A1;
    position: relative;
    left: 6px;
}

/* .tablecontainer li:hover {
    background: #fff;
} */



.iconUploadfile {
    color:#0171A1;
}

.rowItemUploaded {
    background: #F7F7F7 !important;
    border-radius: 6px;
    padding: 10px;
    height: 64px;
}

.mainwrapitem {
    border: 0px !important;
}

.uploadCreateNew {
    position: relative;
    top: 3px;
    left: -1px;
    height: 15px
}

.hidethisdiv {
    display: none !important;
}

.reupload {
    float: right;
    font-size: 14px !important;
    color: rgb(1, 113, 161);
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 0px;
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 1px;
}
 .ic-calendar::before{
    font-size:16px !important;
}

.lastchild:hover {
    background: none !important;
}

 .ic-chevron-down::before {
    font-size: 16px !important
}
.listDropdown {
  font-size: 12px !important;
}
.document-btn {
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
}

.replace-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0171A1;
}

.createClass {
    padding-left:12px;padding-right:12px;border-radius:4px;color:#fff;font-size:12px;padding-top:4px;padding-bottom:4px;background:#5fbcd3
}

.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}
</style>
