<template>
<v-layout
	row
	justify-center
>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="480"
	>
		<v-card class="card_dialog_delete">
			<v-card-title class="text-h5">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="45"
					height="45"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 17.6502C5.77491 17.6502 2.34979 14.2251 2.34979 10C2.34979 5.7749 5.77491 2.34979 10 2.34979C14.2251 2.34979 17.6502 5.7749 17.6502 10C17.6502 14.2251 14.2251 17.6502 10 17.6502ZM9.99672 11.3507C10.3384 11.3509 10.6209 11.0972 10.6658 10.7678L10.6721 10.6762L10.6753 5.72554C10.6755 5.35281 10.3736 5.05045 10.0008 5.0502C9.65917 5.04998 9.37664 5.30369 9.33173 5.63308L9.32551 5.72466L9.32227 10.6753C9.32203 11.0481 9.62399 11.3504 9.99672 11.3507ZM10.0004 14.4982C10.4967 14.4982 10.8991 14.0958 10.8991 13.5995C10.8991 13.1032 10.4967 12.7008 10.0004 12.7008C9.50409 12.7008 9.10175 13.1032 9.10175 13.5995C9.10175 14.0958 9.50409 14.4982 10.0004 14.4982Z"
						fill="#D68A1A"
					/>
				</svg>
			</v-card-title>
			<v-card-text class="pt-4 pb-3">
				<h2>Discard Changes</h2>
			</v-card-text>
			<v-card-text class="pb-5"> You have unsaved changes for this Container. Do you want to discard the changes? </v-card-text>
			<v-card-actions class="pt-0 pb-3">
				<v-btn
					class="btn-blue mr-0"
					text
          @click="close(true)"
				> Discard Changes </v-btn>
				<v-btn
					class="btn-white"
					text
					@click="close(false)"
				> Continue Editing </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</v-layout>
</template>
<script>
export default {
	name: "DiscardChanges",
	props: ['dialog'],
	mounted() {},
	data: () => ({}),
	methods: {
    close(val){
      this.$emit("discardChanges",val)
    }
  },
};
</script>
