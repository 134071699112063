<template>
  <div class="containers-wrappers" v-resize="onResize">
    <CreateContainer
      :items="getdata"
      :customers="customers"
      :terminals="terminals"
      :carriers="carriers"
      :ContainerSize="ContainerSize"
      @saveandanother="saveandAnother"
      :isMobile="isMobile"
      v-if="renderComponent"
    />
  </div>
</template>
<script>
import CreateContainer from "../components/Tables/Containers/Create/Create.vue";
import { mapGetters, mapActions } from "vuex";
import globalMethods from "../utils/globalMethods";
export default {
  name: "createContainers",
  components: {
    CreateContainer,
  },
  data: () => ({
    renderComponent: true,
    dataFormfield: [],
    isMobile: false,
  }),
  computed: {
    ...mapGetters({
      formdetails: "containers/getcontainerFormDetails",
      getcarriers: "containers/getAllCarriers",
      getPoLoading: "containers/getPoLoading",
    }),
    getdata() {
      return this.formdetails;
    },
    customers() {
      var data = [];
      if (
        typeof this.formdetails !== "undefined" &&
        this.formdetails !== null
      ) {
        if (
          typeof this.formdetails.data !== "undefined" &&
          this.formdetails.data.length !== "undefined"
        ) {
          data = this.formdetails.data.customers;
        }
      }
      return data;
    },
    terminals() {
      var data = [];
      if (
        typeof this.formdetails !== "undefined" &&
        this.formdetails !== null
      ) {
        if (
          typeof this.formdetails.data !== "undefined" &&
          this.formdetails.data.length !== "undefined"
        ) {
          data = this.formdetails.data.terminal;
        }
      }
      return data;
    },
    carriers() {
      var data = [];
      if (
        typeof this.getcarriers !== "undefined" &&
        this.getcarriers !== null
      ) {
        if (
          typeof this.getcarriers.data !== "undefined" &&
          this.getcarriers.data.length !== "undefined"
        ) {
          data = this.getcarriers.data;
        }
      }

      return data;
    },
    ContainerSize() {
      var data = [];
      if (
        typeof this.formdetails !== "undefined" &&
        this.formdetails !== null
      ) {
        if (
          typeof this.formdetails.data !== "undefined" &&
          this.formdetails.data.length !== "undefined"
        ) {
          data = this.formdetails.data.size;
        }
      }
      return data;
    },
  },
  methods: {
    ...mapActions({
      fetchFormdetails: "containers/fetchallformdetails",
      fetchCarriers: "containers/fetchCarriers",
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    saveandAnother() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
  async mounted() {
    //set current pages
    await this.fetchFormdetails();
    await this.fetchCarriers();
    this.$store.dispatch("page/setPage", "delivery-order");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/containers/containers.scss";
@import "../assets/scss/buttons.scss";
</style>
