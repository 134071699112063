<template>
<div class="containers-inner-wrapper visibility conTabledisplay">

    <!-- ///////// -->
    <div class="spinner mt-4" v-if="getTabLoading">
      <v-progress-circular
        :size="40"
        color="#0171a1"
        indeterminate
        v-if="getPoLoading"
      >
      </v-progress-circular>
    </div>
    <v-data-table
      :headers="headers"
      :items="loadingData ? [] : newData"
      :custom-sort="customSort"
      class="containers-table elevation-1 conTabledisplay"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      @item-selected="itemSelected"
      v-model="selectedTasks"
      v-if="refresh"
      calculate-widths
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Containers</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-tabs
          v-if="retabs"
          class="purchase-order2-tabs filterstabs"
          v-model="activeTab"
        >
          <v-tab
            :disabled="isDisabled"
            v-for="(n, i) in newtabitem"
            :key="i"
            @mouseup="getCurrentTab(n.name, i, n)"
          >
             <span v-if="n.name=='Assigned'">
              Scheduled
             </span>
             <span v-else>{{ n.name }}</span>
            <span
            class="text-tab-counter"
            v-if="n.count"
            >
              {{ n.count }}
            </span>
          </v-tab>
        </v-tabs>
        <v-toolbar height="auto" class="searchAndFilter">


					<div
						v-if="currentSelectTab == 'All'"
						style="padding-top:10px"
					>
						<div style="padding-left: 10px; display: flex">


							<div style="margin-right: 20px; display: flex">
									<div style="color: #6D858F; margin-right: 5px">Show</div>

									<v-menu
										offset-y
									>
										<template v-slot:activator="{ attrs, on }">

											<div style="cursor: pointer" v-bind="attrs" v-on="on">
												<span style="margin-right: 2px"> {{ selectedActiveInactiveFilter.label }} </span>
												<svg
													width="10"
													height="6"
													viewBox="0 0 10 6"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
														fill="#0B6085"
													/>
												</svg>
											</div>
										</template>
										<v-list>
											<v-list-item
												@click="selectedActiveInactiveFilter = item"
												v-for="item in activeInactiveFilter"
												:key="item.value"
												link
											>
												<v-list-item-title v-text="item.label" ></v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
							</div>

							<div style="margin-right: 15px; display: flex">
								<div style="color: #6D858F; margin-right: 5px; cursor: pointer" @click="handlePicker">
									<span> ETA </span>
									<span style=""> {{ selectedEtaRange.length > 0 ? (selectedEtaRange[0].replaceAll('-','/') + '-' + selectedEtaRange[1].replaceAll('-','/')) : "--"}} </span>
									<svg
										width="10"
										height="6"
										viewBox="0 0 10 6"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
											fill="#0B6085"
										/>
									</svg>
								</div>
							</div>
							<!-- <div style="margin-right: 15px; display: flex">
									<div style="color: #6D858F; margin-right: 5px">ETA</div>
									<v-menu
										offset-y
									>
										<template v-slot:activator="{ attrs, on }">
											<div style="cursor: pointer" v-bind="attrs" v-on="on">
												<span> {{ selectedActiveInactiveFilter.label }} </span>
												<svg
													width="10"
													height="6"
													viewBox="0 0 10 6"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M5 4.47655L8.97867 0.188526C9.21703 -0.0683692 9.61175 -0.061258 9.84203 0.200905C10.0565 0.445037 10.0524 0.825416 9.83085 1.06423L5.42609 5.81148C5.19286 6.06284 4.80714 6.06284 4.57391 5.81148L0.169146 1.06423C-0.0524367 0.825415 -0.0564694 0.445037 0.15797 0.200905C0.388249 -0.0612581 0.782966 -0.0683693 1.02133 0.188526L5 4.47655Z"
														fill="#0B6085"
													/>
												</svg>
											</div>
										</template>
										<v-list>
											<v-list-item
												@click="selectedActiveInactiveFilter = item"
												v-for="item in activeInactiveFilter"
												:key="item.value"
												link
											>
												<v-list-item-title v-text="item.label" ></v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
							</div> -->




						</div>
					</div>


          <div
            v-if="currentSelectTab == 'Scheduled'"
            class="assignUnassignFilter"
            style="display: flex"
          >
            <div
              v-for="(item, index) in filterAssign"
              :key="index"
              :class="checkIfClassActive(item)"
              @click="setSetFilterActive(item)"
            >
              {{ item }}
            </div>
          </div>
          <div class="assignUnassignFilter" style="display: flex">
            <div
              v-for="(item, index) in sub_tab"
              :key="index"
              :class="checkIfClassActive(item.name)"
              :style="
                'background:' +
                (item.name == selected_sub_tab.name ? '#EBF2F5' : '#ffffff')
              "
              @click="setSubTab(item)"
            >
              {{ item.label }}
              <span
                class="text-tab-counter"
                style="
                  padding: 2px 5px 4px 5px;
                  gap: 10px;
                  width: 20px;
                  font-size: 11px;
                  height: 20px;
                  background: #0171a1;
                  border-radius: 12px;
                "
                :style="
                  'background:' +
                  (item.name == selected_sub_tab.name ? '#0171A1' : '#EBF2F5') +
                  ';color:' +
                  (item.name == selected_sub_tab.name ? '#ffffff' : '#4A4A4A')
                "
                v-if="item.count"
              >
                {{ item.count }}
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div
            style="position: relative"
            v-if="currentSelectTab == 'Scheduled' && cardDropdownFilter == true"
          >
            <div class="text-center">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="80"
                offset-y
                nudge-left="212"
                nudge-top="-6"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="btn-white" v-bind="attrs" v-on="on" ref="fi">
                    <img src="@/assets/icons/filter.svg" /> Filters
                  </v-btn>
                </template>
                <v-card class="cardDropdown" position-x="233">
                  <v-list>
                    <!-- <div
                      v-if="drivernameLabel.length > 0"
                      style="padding: 15px"
                    >
                      <p class="showResultdriver">
                        Show you result by this driver
                      </p>
                      <div style="position: relative; width: 273px">
                        <span
                          v-for="(item, index) in drivernameLabel"
                          :key="index"
                          style="display: inline-block; position: relative"
                          class="Suncastle"
                        >
                          <span class="driverNameTtitle">
                            {{ item.name }}
                          </span>
                          <span
                            @click="disSelected(index)"
                            class="closeXbutton"
                          >
                            <img src="@/assets/icons/xicon.svg" />
                          </span>
                        </span>
                      </div>
                    </div>                    -->
                    <v-list-item
                      v-if="magdriver.length > 0"
                      >
                      <v-list-item-content
                      >
                        <label
                          class="driverLabel"
                          for="">Drivers
                        </label>

                        <v-autocomplete
                            v-model="driverSelectFilter"
                            :items="driverdropdown"
                            color="blue"
                            item-text="name"
                            @change="changemenow"
                            placeholder="Select Driver"
                            item-value="id"
                            multiple
                            size="14px">
                            <template v-slot:selection="data">
                                <span
                                    v-if="driverSelectFilter.length < 2"
                                >
                                     {{ `${driverSelectFilter.length} Driver selected` }}
                                </span>
                                <div v-else-if="data.index === 0">
                                    {{ `${driverSelectFilter.length} Drivers selected` }}
                                </div>
                            </template>
                            <template
                              slot="item"
                              slot-scope="{ item }">
                              <div
                                class="listDropdown"
                                >
                                <v-checkbox
                                  :size="12"
                                  v-model="checkall"
                                  :value="item.id"
                                  :label="item.name"
                                  class="my-checkbox2"
                                >
                                </v-checkbox>
                              </div>
                            </template>
                        </v-autocomplete>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <label class="driverLabel" for=""
                          >Pickup Location
                        </label>
                        <v-autocomplete
                          v-model="locationfromfilter"
                          :items="getlocation"
                          color="blue"
                          @change="changelocation"
                          item-text="name"
                          placeholder="Select Location"
                          item-value="id"
                          multiple
                          size="14px"
                        >
                         <template v-slot:selection="data">
                            <span
                                v-if="locationfromfilter.length < 2"
                            >
                              {{ `${locationfromfilter.length} Location selected` }}
                            </span>
                            <span v-else-if="data.index === 0">
                              {{ `${locationfromfilter.length} Locations selected` }}
                            </span>
                        </template>
                        <template
                          slot="item"
                          slot-scope="{ item }">
                          <div
                            class="listDropdown"
                            >
                              <v-checkbox
                                v-model="checkallLocation"
                                :value="item.id"
                                :label="item.name"
                                class="my-checkbox2"
                              >
                              </v-checkbox>
                          </div>
                        </template>
                        </v-autocomplete>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-actions class="buttonCardActions">
                    <v-btn
                      style="height: 30px !important"
                      class="btn-blue filterButtonApply"
                      color="primary"
                      text
                      :disabled="isDisabledfilter"
                      @click="applyFilter"
                      ref="refapply"
                    >
                      <v-progress-circular
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        v-if="startloading"
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Apply
                    </v-btn>
                    <v-btn
                      style="height: 30px !important"
                      class="btn-white filterButtonCancel"
                      text
                      @click="ClearFilter"
                    >
                      Clear
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </div>

          <div
            class="initialDropdown"
            v-else-if="
              currentSelectTab == 'Scheduled' && cardDropdownFilter == false
            "
          >
            <!-- <v-autocomplete
              v-model="inititaldriverSelectFilter"
              :items="driverdropdown"
              color="blue"
              item-text="name"
              @change="changenowDropdown"
              placeholder="All Driver"
              item-value="id"
              size="14px"
              append-icon="ic-chevron-down"
            >
            </v-autocomplete> -->
          </div>
          <Search
            placeholder="Search Containers"
            className="search"
            :inputData.sync="search"
          />
        </v-toolbar>
        <v-toolbar
          style="display: none; border-top: 1px solid #eee; height: 48px"
          v-if="currentSelectTab == 'Scheduled'"
          class="filterShowtag"
        >
          <v-toolbar-title> Active Filter by Driver </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0 && currentSelectTab == 'All'">
          <tr  v-for="item in items" :key="item.id">
            <td class="referrence">
              <div class="classTop" v-html="Ref(item)"></div>
            </td>
            <td style="padding-top: 20px">
              <div>{{ item.mbl_num || "--" }}</div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <td style="padding-top: 20px">
              <div>
                {{ item.customer != null ? item.customer.company_name : "--" }}
              </div>
            </td>
            <td >
              <div class="containers_etas" style="padding-top: 10px">
                {{ getContainerSize(item) }}
              </div>
            </td>
            <td style="padding-top: 19px">
              {{ getDateFormat(item.eta) }}
            </td>
            <td style="padding-top: 19px">
              {{
                getDateFormat(
                  getLastArrivedLeg(item)
                    ? item.per_diem_date
                    : item.last_free_date
                )
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length === 0 && currentSelectTab === 'Scheduled'">
        <tr>
            <td
              style="align: center"
              colspan="10"
            >
              <div v-if="shouldLoad" style="text-align: center">
                <v-progress-circular :size="40" color="#0171a1" indeterminate>
                </v-progress-circular>
              </div>
              <div
                v-if="!getPoLoading && !shouldLoad"
                style="text-align: center"
              >
                <div v-if="currentSelectTab == 'Scheduled'">
                  <div v-if="filterstarted">Fetching data.....</div>
                  <div
                    v-else
                    class="wrapno-matching"
                    style="padding-top: 70px; display: inline-block"
                  >
                    <img src="@/assets/icons/truckicon.svg" />
                    <p class="nomatching">No matching result</p>
                    <p>We could not Find any Shipments under this filters</p>
                    <p>
                      <button
                        class="btn-white"
                        style="padding-top: 6px !important"
                        @click="triggersearch"
                      >
                        Try Different Search
                      </button>
                    </p>
                  </div>
                </div>
                <div v-else>No Data</div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 && currentSelectTab === 'Scheduled'">
          <tr  v-for="item in items" :key="item.id">
            <td class="referrence">
              <div class="classTop" v-html="Ref(item)"></div>
            </td>
            <td style="padding-top: 20px">
              <div>{{ item.mbl_num || "--" }}</div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <!-- <td
						class="container_customer"
						>
						<p
							style="padding-top:0px"
							class="classTop"
							v-html="TruckerConCustomer(item)">
						</p>
					</td> -->
            <td >
              <div class="containers_etas" style="padding-top: 10px">
                {{ getContainerSize(item) }}
              </div>
            </td>
            <td style="padding-top: 18px">
              <div>{{getDateUTCTRUE(item.last_free_date)}}</div>
              <div>{{getDateUTCTRUE(item.per_diem_date)}}</div>
            </td>
            <td class="schedule" style="padding-left: 0px; padding-right: 0px">
              <div
                class="classTop f"
                v-html="
                  get_schedule(
                    typeFilter || !typeFilter
                      ? item.new_dispatch_schedule
                      : item.dispatch_schedule,
                    typeFilter
                  )
                "
						></div>
					</td>
					<td
						class="pickupon"
						style="padding-left: 0px; padding-right: 0px"
					>
						<div
							class="pickup_on classTop"
							v-html="
                  pickupOn(
                    item.new_dispatch_schedule,
                    item
                  )
                "
						></div>
					</td>
					<td
						class="deliveryon"
						style="padding-left: 0px; padding-right: 0px"
					>
						<div
							class="pickup_on classTop"
							v-html="
                  deliveryon(
                    typeFilter || !typeFilter
                      ? item.new_dispatch_schedule
                      : item.dispatch_schedule
                  )
                "
						></div>
					</td>
					<td
						class="statuson"
						style="padding-left: 0px; padding-right: 0px"
					>
						<div
							class="AssignTo classTop"
							v-html="
                  getAssignedTo(
                    typeFilter || !typeFilter
                      ? item.new_dispatch_schedule
                      : item.dispatch_schedule
                  )
                "
              ></div>
            </td>
            <td>
              {{ sugomo(item.new_dispatch_schedule) }}
            </td>
            <td
              class="more_btn_outer statuson"
              style="padding-left: 0px; padding-right: 0px"
            >
              <p
                :class="
                  isEmpty(leg.driver)
                    ? `${
                        i ===
                        getNewDispatchScheduleLegs(
                          typeFilter || !typeFilter
                            ? item.new_dispatch_schedule
                            : item.dispatch_schedule
                        ).length -
                          1
                          ? ''
                          : 'commonP'
                      } DRIVER_unASSIGN unassignna hasNoDriver`
                    : `${
                        i ===
                        getNewDispatchScheduleLegs(
                          typeFilter || !typeFilter
                            ? item.new_dispatch_schedule
                            : item.dispatch_schedule
                        ).length -
                          1
                          ? ''
                          : 'commonP'
                      } DRIVER_ASSIGN Assign hasDriver`
                "
							v-for="(leg, i) in getNewDispatchScheduleLegs(
                  typeFilter || !typeFilter
                    ? item.new_dispatch_schedule
                    : item.dispatch_schedule
                )"
                :key="leg.id"
              >
                <!-- <v-menu
                  class="more_btn"
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  nudge-left="82"
                  nudge-top="-30"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <img src="../../../assets/icons/more_dot.svg" alt="" />
                    </v-btn>
                  </template>
                  <v-list style="padding: 0">
                    <v-list-item
                      class="hover-bg"
                      @click="markAsArrived(item, leg)"
                    >
                      <v-list-item-title>Mark As Arrived</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="hover-bg" @click="editLeg(item, leg)">
                      <v-list-item-title>Edit Leg</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </p>
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="
            items.length > 0 &&
            (currentSelectTab == 'Yard full' || currentSelectTab == 'At Yard')
          "
        >
          <tr v-for="item in items" :key="item.id" :class="getBg(item)">
            <td  class="referrenceN">
              <div class="classTop">
                {{
                  item.trucker_container != null
                    ? capitalize(item.trucker_container)
                    : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td >
              <div>{{ item.mbl_num || "--" }}</div>
            </td>
            <td >
              {{ item.customer != null ? item.customer.company_name : "" }}
            </td>
            <td >
              <div class="containers_etas">{{ getContainerSize(item) }}</div>
            </td>
            <td >
              <div class="containers_etas" :class="getTextColor(item)">
                <div>
                  {{ getCarrier(item) }}
                </div>
                <div class="text--disabled">
                  {{ getPerDiemDate(item) }}
                </div>
              </div>
            </td>
            <td  v-html="getTimeArrived(item)"></td>
            <td  v-html="getScheduledDate(item)"></td>
            <td
              v-if="
                ['Yard full', 'At Yard'].includes(currentSelectTab) ||
                ['Yard full', 'At Yard'].includes(selected_sub_tab.label)
              "
              class="more_btn_outer statuson"
              style="padding-left: 0px; padding-right: 0px"
            >
              <p class="more_btn_sm">

              </p>
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="
            items.length > 0 &&
            (currentSelectTab == 'At the Customer' ||
              currentSelectTab == 'Dropped empty')
          ">
				<tr
					v-for="item in items"
					:key="item.id"
					:class="getBgAtheCustomer(item)"
				>
					<td

						class="referrenceN"
					>
						<div class="classTop">
							{{ item.trucker_container != null
                    ? capitalize(item.trucker_container)
                    : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td >
              <div>{{ item.mbl_num || "--" }}</div>
            </td>
            <td >
              {{ item.customer != null ? item.customer.company_name : "" }}
            </td>
            <td >
              <div class="containers_etas">{{ getContainerSize(item) }}</div>
            </td>
            <td >
              <div class="containers_etas" :class="getTextColor(item)">
                <div>
                  {{ getCarrier(item) }}
                </div>
                <div :class="getPerDiemDateClass(item)">
                  {{ getPerDiemDate(item) }}
                </div>
              </div>
            </td>
            <td >
              <div>
                <div>
                  {{ getAddress(item) }}
                </div>
                <div class="text--disabled">
                  {{ getATCTimeArrived(item) }}, {{ getDateArrived(item) }}
                </div>
              </div>
            </td>
            <td

              v-if="currentSelectTab === 'At the Customer'"
            >
              <span>{{ getMode(item) }}</span>
            </td>
            <td

              v-if="currentSelectTab === 'Dropped empty'"
            >
              <span>{{ getType(item) }}</span>
            </td>
            <td
              class="text-center"
              v-if="currentSelectTab === 'At the Customer'"
            >
              <input
                :id="item.id"
                class="empty-checkbox my-checkbox"
                type="checkbox"
                :checked="item.container_empty"
                @click="(e) => { e.preventDefault() }"
              />
            </td>
            <td >
              <div>
                <div>
                  {{ getScheduleTime(item) }}
                </div>
                <div class="text--disabled">
                  {{ getScheduleDate(item) }}
                </div>
              </div>
            </td>
            <td
              v-if="
                ['At the Customer'].includes(currentSelectTab) ||
                ['At the Customer'].includes(selected_sub_tab.label)
              "
              class="more_btn_outer statuson"
              style="padding-left: 0px; padding-right: 0px"
            >
              <p class="more_btn_sm">

              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0 && currentSelectTab !== 'Scheduled'">
          <tr v-for="item in items" :key="item.id" :class="getBg(item)">
            <td  class="referrenceN">
              <div class="classTop">
                {{
                  item.trucker_container != null
                    ? capitalize(item.trucker_container)
                    : ""
                }}
              </div>
              <div>
                {{ item.shifl_ref != null ? item.shifl_ref : "" }}
              </div>
            </td>
            <td >
              <div>{{ item.mbl_num || "--" }}</div>
              <div>{{ getCarrier(item) }}</div>
            </td>
            <td >
              {{ item.customer != null ? item.customer.company_name : "" }}
            </td>
            <td >
              <div class="containers_etas">{{ getContainerSize(item) }}</div>
            </td>
            <td

              v-if="
                [
                  'Pending',
                  'Available',
                  'Yard full',
                  'At Yard',
                  'Dropped full',
                  'Pending billing',
                  'Completed',
                  'Cancelled',
                ].includes(currentSelectTab) ||
                [
                  'Pending',
                  'Available',
                  'Yard full',
                  'At Yard',
                  'Dropped full',
                  'Pending billing',
                  'Completed',
                  'Cancelled',
                ].includes(selected_sub_tab.label)
              "
            >
              <div class="containers_etas" :class="getTextColor(item)">
                {{ getDateFormat(item.eta) }}
              </div>
            </td>
            <td

              v-if="
                ['Available', 'Pending'].includes(currentSelectTab) ||
                ['Available', 'Pending'].includes(selected_sub_tab.label)
              "
            >
              <div class="containers_etas" :class="getTextColor(item)">
                {{ getDateFormat(item.last_free_date) }}
              </div>
            </td>
            <!-- // dropeed empty  -->
            <td

              v-if="
                ['Dropped empty'].includes(currentSelectTab) ||
                ['Dropped empty'].includes(selected_sub_tab.label)
              "
            >
              <div>{{ item.carrier != null ? item.carrier.name : "--" }}</div>
              <div class="text-dark-gray">
                {{ getDateFormat(item.per_diem_date) }}
              </div>
            </td>
            <td

              v-if="
                ['Dropped empty'].includes(currentSelectTab) ||
                ['Dropped empty'].includes(selected_sub_tab.label)
              "
              v-html="getTimeArrived(item)"
            ></td>
            <td

              v-if="
                ['Dropped empty'].includes(currentSelectTab) ||
                ['Dropped empty'].includes(selected_sub_tab.label)
              "
            >
              {{ getType(item) }}
            </td>
            <!--  // -->
            <td

              v-if="
                [
                  'Pending',
                  'Available',
                  'Yard full',
                  'At Yard',
                  'Pending billing',
                  'Completed',
                  'Cancelled',
                  'Dropped full',
                ].includes(currentSelectTab) ||
                [
                  'Pending',
                  'Available',
                  'Yard full',
                  'At Yard',
                  'Pending billing',
                  'Completed',
                  'Cancelled',
                  'Dropped full',
                ].includes(selected_sub_tab.label)
              "
            >
              {{ item.terminal_id != null ? getTerminalName(item.terminal_id) : "--" }}
            </td>
            <td

              v-if="
                ['Available', 'Dropped empty'].includes(currentSelectTab) ||
                ['Available', 'Dropped empty'].includes(selected_sub_tab.label)
              "
              v-html="getScheduledDate(item)"
            ></td>
            <!-- milestones -->
            <td
              class="text-center"
              v-if="
                ['Pending'].includes(currentSelectTab) ||
                ['Pending'].includes(selected_sub_tab.label)
              "
            >
              <input
                :id="item.id + '_discharged'"
                class="empty-checkbox my-checkbox"
                type="checkbox"
                :checked="item.discharged"
                @click="(e) => { e.preventDefault() }"
              />
            </td>
            <td
              class="text-center"
              v-if="
                ['Pending'].includes(currentSelectTab) ||
                ['Pending'].includes(selected_sub_tab.label)
              "
            >
              <input
                :id="item.id + '_pier_pass_paid'"
                class="empty-checkbox my-checkbox"
                type="checkbox"
                :checked="item.pier_pass_paid"
                @click="(e) => { e.preventDefault() }"
              />
            </td>
            <td
              class="text-center"
              v-if="
                ['Pending'].includes(currentSelectTab) ||
                ['Pending'].includes(selected_sub_tab.label)
              "
            >
              <input
                :id="item.id + '_fully_released'"
                class="empty-checkbox my-checkbox"
                type="checkbox"
                :checked="item.fully_released"
                @click="(e) => { e.preventDefault() }"
              />
            </td>
            <td
              v-if="
                ['Pending', 'Available'].includes(currentSelectTab) ||
                ['Pending', 'Available'].includes(selected_sub_tab.label)
              "
              class="more_btn_outer statuson"
              style="padding-left: 0px; padding-right: 0px"
            >
              <p class="more_btn_sm">

              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              style="align: center"
              :colspan="
                ['Scheduled', 'At the Customer', 'At Port'].includes(
                  currentSelectTab
                )
                  ? 9
                  : 7
              "
            >
              <div v-if="shouldLoad" style="text-align: center">
                <v-progress-circular :size="40" color="#0171a1" indeterminate>
                </v-progress-circular>
              </div>
              <div
                v-if="!getPoLoading && !shouldLoad"
                style="text-align: center"
              >
                <div v-if="currentSelectTab == 'Scheduled'">
                  <div v-if="filterstarted">Fetching data...</div>
                  <div
                    v-else
                    class="wrapno-matching"
                    style="padding-top: 70px; display: inline-block"
                  >
                    <img src="@/assets/icons/truckicon.svg" />
                    <p class="nomatching">No matching result</p>
                    <p>We could not Find any Shipments under this filters</p>
                    <p>
                      <button
                        class="btn-white"
                        style="padding-top: 6px !important"
                        @click="triggersearch"
                      >
                        Try Different Search
                      </button>
                    </p>
                  </div>
                </div>
                <div v-else>No Data</div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:[`no-data`]>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
    <!-- <Pagination
		v-if="typeof containersData !== 'undefined' && containersData.length > 0"
		:pageData.sync="page"
		:lengthData.sync="pageCount"
		:isMobile="isMobile"
	/> -->
    <Pagination
      v-if="typeof newData !== 'undefined' && newData.length > 0"
      :pageData.sync="page"
      :lengthData.sync="updatedPageCount"
      :isMobile="isMobile"
    />
    <EmptyDialog
      :dialog.sync="dialog"
      :itemData="dialogData"
      :isLoading="isSubmitting"
      @closeDialog="closeDialog"
      @updateContainerEmpty="updateContainer"
    />
    <MilestoneConfirmationDialog
      :dialog.sync="milestoneDialog"
      :itemData="milestoneDialogData"
      :isLoading="milestoneLoading"
      :field="selectedMilestoneField"
      :isChecked="isSelectedMilestoneFieldChecked"
      @closeMilestoneDialog="closeMilestoneDialog"
      @updateMilestone="updateMilestone"
    />
	<DatePickerDialog
		:open="isDatePickerOpen"
		:multiple="true"
		:label="pickerLabel"
		@confirm="confirmDatePicker"
		@close="isDatePickerOpen = false"
	/>
  </div>
</template>

<script>
import {
	mapGetters,
	mapActions
}
from "vuex";
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;
import Search from "../../Search.vue";
import Pagination from "../../Pagination.vue";
import globalMethods from "../../../utils/globalMethods";
import moment from "moment";
import jQuery from "jquery";
import tabs from "./Config/TabWiseColumn.js";
import EmptyDialog from "./Dialog/EmptyDialog.vue";
import MilestoneConfirmationDialog from "./Dialog/MilestoneConfirmationDialog.vue";
import DatePickerDialog from './Dialog/DatePickerDialog.vue';
const el = ".v-data-table__wrapper table";
export default {
	name: "CustomerVisibilityContainersDesktopTable",
	props: [
    "items",
    "isMobile",
    "tabitemdata",
    "newtabitem",
    "contentData",
    "allcomopents",
    "customers",
    "terminals",
    "magdriver",
    "shipmentsize",
    //"magdropterminal",
    "mgaFiles",
    "responseObjectMeta",
  ],
  components: {
    Search,
    Pagination,
    EmptyDialog,
    MilestoneConfirmationDialog,
		DatePickerDialog
  },
  mounted() {
    this.$store.dispatch("page/setPage", "containers");
		console.log(this.currentSelectTab)
		this.selectedTabData("All", "Pending");

    // this.fetchDrivers();
    // this.fetchChassis();
    // this.fetchTerminals();
  },
  data: () => ({
    checkallLocation:[],
    checkall: [],
    allDriverList:[],
    allLocationList: [],
    checkbox1: true,
    uncheckbox1: false,
    magdriverFilter: [],
    magdropterminal: [],
    startloading: 0,
    detectchange: 0,
    foundsearch: false,
    whatclick: true,
    assigntrigger: 0,
    refresh: 1,
    metaobject: [],
    filterstarted: false,
    filterdataRes: [],
    triggerDisselected: 0,
    triggerapply: 1,
    hasAssignedDriver: false,
    driverfinalPush: [],
    newByDriverFilterAssginged: [],
    currentDriverFilterID: 0,
    cardDropdownFilter: false,
    typeFilter: 0,
    drivernameLabel: [],
    locationDropdowndetected: 0,
    locationfromfilter: [],
    driverSelectFilter: [],
    inititaldriverSelectFilter: [],
    sub_tab: [],
    selected_sub_tab: {},
    fav: true,
    menu: false,
    message: false,
    hints: true,
    showhideDropdown: false,
    hastwotypes: "hastwotypes",
    numberAssignType: "data-numberassigntype",
    filterTabitem: "Scheduled",
    callTrigger: "",
    titlePage: "+ Create Delivery Order",
    naayDriverwala: "hasDriver",
    walayDriver: "hasNoDriver",
    refcontainercl: "",
    updatedni: 0,
    retabs: 1,
    currentStateClick: "",
    borderzero: {
      border: "'border','0px !important'",
    },
    hasDriverLastElement: "hasDriverLastElement",
    loopUnassigned: 0,
    loopAssign: 0,
    lenthDispatch: 0,
    assignna: 0,
    alert: 1,
    filterAssign: ["All", "Assign", "Unassigned"],
    classSelected: "All",
    isDisabled: true,
    isDisabledfilter: true,
    currentView: [],
    itemsData: [],
    dialog: false,
    dialogData: [],
    isSubmitting: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 15,
    search: "",
    tabs: [
      "Pending",
      "Available",
      "In Yard full ",
      "Assigned",
      "Dropped full",
      "Dropped empty",
      "Pending billing",
      "Completed",
      "Cancelled",
    ],
    activeTab: 0,
    isInputExpanded: false,
    searchData: "",
    currentSelectTab: "All",
    selectedTasks: [],
    filters: [
      {
        title: "Pending",
      },
			{
				title: "Available",
      },
			{
				title: "Yard full",
      },
			{
				title: "Scheduled",
      },
			{
				title: "At the Customer",
      },
      //Renamed into "At the Customer" tab
      // {
      // 	title: 'Dropped full'
      // },
			{
				title: "Dropped empty",
      },
			{
				title: "Pending billing",
      },
			{
				title: "Completed",
      },
			{
				title: "Cancelled",
      },
    ],
    tabdata: [],
    tabClicked: false,
    aIsfirst: 0,
    bIsfirst: 0,
    milestoneDialog: false,
    milestoneDialogData: null,
    milestoneLoading: false,
    selectedMilestoneField: null,
    isSelectedMilestoneFieldChecked: false,
    typeDropdown: [
      {
        label: "Customer",
        value: "Customer",
      },
      {
        label: "Yard",
        value: "Yard",
      },
      {
        label: "To Port",
        value: "Empty Return",
      },
    ],
    modedropdown: [
      {
        id: 1,
        name: "Drop",
      },
			{
				id: 2,
				name: "Live",
      },
    ],
    responseChassis: null,
    showEditLegModal: false,
    editingLeg: null,
    editingShipment: null,
    showAddLegModal: false,
    lastLeg: null,
    newContainersData: [],
    oldContainersData: [],
    shouldLoad: false,
    loadingData: false,
    updatedPageCount: false,
    lastPage: 1,
    oldselectedTab: "",
		selectedActiveInactiveFilter: { label: 'All', value: 'all'},
		activeInactiveFilter: [{ label: 'All', value: 'all'}, { label: 'Only Actives', value: 'active'}, {label: 'Only Inactives', value: 'inactive'}],
		selectedCustomerFilter: {id: 0, company_name: 'Select'},
	isDatePickerOpen: false,
	selectedEtaRange: [],
	pickerLabel: ''
  }),
  computed: {
    ...mapGetters({
      getPoLoading: "containers/getPoLoading",
      gettabcounter: "containers/gettabcounter",
      getAllcontainer: "containers/getAllContainers",
      getAllcontainerStatus: "containers/getAllcontainersStatus",
      getTabLoading: "containers/getTabLoading",
      getCurrentSelectTab: "containers/getCurrentSelectedTab",
      getScheduledFilterState: "containers/getScheduledFilterState",
      // getDrivers: "common/getDrivers",
      // getChassis: "common/getChassis",
      getTerminals: "common/getTerminals",
    }),
    getEditingShipmentEta: {
      get() {
        if (this.editingShipment && this.editingShipment.eta) {
          return moment(this.editingShipment.eta)
            .utc(false)
            .format("YYYY-MM-DD");
        }
        return null;
      },
    },
    getlocation: {
      get() {
        var f = this.magdropterminal;
        return f;
      },
    },
    driverdropdown: {
			get() {
				var f = this.magdriverFilter;
				return f;
			},
		},
    object() {
      return Object.values(this.items);
    },
    headers() {
      if (this.currentSelectTab == "All") {
        return tabs.all_tab;
      } else if (this.currentSelectTab == "At Port") {
        if (this.selected_sub_tab.label == "Pending") {
          return tabs.pending_tab;
        } else {
          return tabs.available_tab;
        }
      }
      if (this.currentSelectTab == "Pending") {
        return tabs.pending_tab;
      } else if (this.currentSelectTab == "Available") {
        return tabs.available_tab;
      } else if (this.currentSelectTab == "Scheduled") {
        return tabs.scheduled_tab;
      } else if (this.currentSelectTab == "At Yard") {
        if (this.selected_sub_tab.name == "Pending") console.log(this.sub_tab);
        return tabs.in_yard_full;
      } else if (this.currentSelectTab == "At the Customer") {
        return tabs.at_the_customer_tab;
      } else if (this.currentSelectTab == "Dropped empty") {
        return tabs.dropped_empty_tab;
      }
      return tabs.default;
    },
    // edit leg
    editingLegPickupLocation() {
      if (this.editingLeg != null && this.editingShipment != null) {
        let legIndex = this.currentEditingLegIndex;
        try {
          if (legIndex == 0)
            return this.getTerminals.find(
              (t) => t.id == this.editingShipment.terminal_id
            ).name;
        } catch (err) {
          return "";
        }
        if (this.editingShipment.dispatch_schedule != "") {
          let dispatch_schedule = null;
          try {
            dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
          } catch (err) {
            console.log(err);
          }
          if (dispatch_schedule) {
            let legs = dispatch_schedule.legs || {};
            if (legs.length > 0) {
              let address = legs[0].address;
              for (let i = 1; i < legs.length; i++) {
                if (legs[i].id == this.editingLeg.id) {
                  return address;
                }
                address = legs[i].address;
              }
            }
          }
        }
      }
      return "";
    },
    currentEditingLegIndex() {
      if (this.editingLeg != null && this.editingShipment != null) {
        if (this.editingShipment.dispatch_schedule != "") {
          let dispatch_schedule = null;
          try {
            dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
          } catch (err) {
            console.log(err);
          }
          if (dispatch_schedule) {
            let legs = dispatch_schedule.legs || {};
            if (legs.length > 0) {
              for (let i = 0; i < legs.length; i++) {
                if (legs[i].id == this.editingLeg.id) {
                  return i;
                }
              }
            }
          }
        }
      }
      return 0;
    },
    getPickupLocation() {
      if (this.editingShipment != null) {
        try {
          if (this.editingShipment.dispatch_schedule) {
            let dispatch_schedule = JSON.parse(
              this.editingShipment.dispatch_schedule
            );
            if (
              dispatch_schedule &&
              typeof dispatch_schedule.legs != "undefined" &&
              dispatch_schedule.legs.length > 0
            )
              return dispatch_schedule.legs[dispatch_schedule.legs.length - 1]
                .address;
          }
          if (this.editingShipment.terminal_id)
            return this.getTerminals.find(
              (t) => t.id == this.editingShipment.terminal_id
            ).name;
        } catch (err) {
          console.log(err);
        }
      }
      return "";
    },
    newData: {
      get() {
        return this.newContainersData;
      },
    },
  },
  watch: {
    checkall(c){
      this.allDriverList = (c);
    },
    locationfromfilter: {
      deep: true,
      handler (newValue) {
        var newstring = [];
        newValue.forEach((v)=>{
          newstring.push({location:this.magdropterminal.find((e)=> e.id == v).name});
        })
        this.allLocationList = JSON.stringify(newstring);
      }
    },
    classSelected(c) {
      if (c != "") this.whatclick = this.whatclick ? false : 0;
      this.refresh = 0;
      this.$nextTick(() => {
        this.refresh = 1;
      });
    },
    items: function (value) {
      this.itemsData = value;
    },
    itemsData: function (v) {
      this.itemsData = v;
    },
    getPoLoading: function (value) {
      if (value) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    updatedni(c) {
      if (c) {
        this.retabs = 0;
        this.$nextTick(() => {
          var text = this.getCurrentSelectTab;
          if (Object.keys(text).length !== 0) {
            this.activeTab = parseInt(text.activeTab);
            this.currentSelectTab = text.id;
            this.retabs = 1;
          } else {
            this.retabs = 1;
          }
        });
      }
    },
    activeTab(nv) {
      //console.log(nv);
      if(nv && !nv){
        //console.log(nv);
      }
      if (this.oldselectedTab !== this.currentSelectTab) {
        this.isDisabled = true;
      }
    },
    pageCount: {
      handler(newValue, oldValue) {
        if (this.search !== "") {
          //console.log(newValue, oldValue);
          if (newValue !== 0) {
            this.updatedPageCount = 1;
          } else if (oldValue > this.lastPage) {
            //console.log("2");
            this.updatedPageCount = 1;
          } else if (this.lastPage > oldValue) {
            //console.log("3");
            this.updatedPageCount = this.lastPage;
          } else {
            //console.log("4");
            this.updatedPageCount = 1;
          }
        } else {
          if (newValue > 1) {
            this.updatedPageCount = newValue;
          } else this.updatedPageCount = this.lastPage;
        }
      },
      immediate: true,
    },
    shouldLoad: {
      handler(newValue) {
        this.loadingData = newValue;
      },
      immediate: true,
    },
    selected_sub_tab(nv) {
      this.selectedTabData(this.currentSelectTab, nv, 1);
    },
    currentSelectTab(nv, oldValue) {
      this.oldselectedTab = oldValue;
      this.pageCount = 1;
      if (this.newContainersData.length < 1) {
        if (this.oldContainersData.length < 1) {
          this.selectedTabData(nv, this.selected_sub_tab, 1);
        } else {
          this.selectedTabData(nv, this.selected_sub_tab);
        }
      } else this.selectedTabData(nv, this.selected_sub_tab, 1);
    },
    page(nv) {
      this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, nv);
    },
    search() {
      this.pageCount = 1;
      this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1);
    },
    contentData() {
      if(this.currentSelectTab=='All')
      this.selectedTabData("All", "Pending");
    },
		selectedCustomerFilter(){
			if(this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
		},
		selectedActiveInactiveFilter(){
			if(this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
		}
  },
  methods: {
	confirmDatePicker(date) {
		this.selectedEtaRange = date || []
		if(this.currentSelectTab == "All") this.selectedTabData("All", this.selected_sub_tab, 1);
		this.isDatePickerOpen = false
	},
    getTerminalName(id){
				if(id){
						let terminal = this.getTerminals.find(t => t.id == id)
						if(terminal) return terminal.name
				}
				return ""
		},
    async filtertabs(subType) {
      this.newContainersData = [];
      this.filterstarted = 1;
      this.shouldLoad = true;
      this.currentSelectTab = "Scheduled";
      this.selectedTabData(this.currentSelectTab, subType, 1);
      this.filterstarted = 0;
    },
    ...mapActions({
      storeEditData: "containers/passData",
      storeCurrentTabSelected: "containers/currentTabContainer",
      setFilter: "containers/setScheduleFilterState",
      setContainerEmptyStatus: "containers/setContainerEmptyStatus",
      // fetchDrivers: "common/fetchDrivers",
      fetchChassis: "common/fetchChassis",
      fetchTerminals: "common/fetchTerminals",
    }),
    setSubTab(tab) {
      this.selected_sub_tab = tab;
    },
    sugomo(items) {
      if (typeof items != "undefined") {
        let ds = JSON.parse(items);
        if (typeof ds.legs[0].driver_status != "undefined")
          return ds.legs[0].driver_status;
      }
      return "Pending";
    },
    addFirstLeg(item) {
      this.editingShipment = item;
      this.showAddLegModal = true;
      try {
        let ds = JSON.parse(this.editingShipment.dispatch_schedule);
        if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
          this.lastLeg = ds.legs[ds.legs.length - 1];
        else this.lastLeg = null;
      } catch (err) {
        console.log(err);
        this.lastLeg = null;
      }
      this.$emit("retrieveContainerDetails", item);
    },
    makegenerate() {
      return new Date().valueOf();
    },
    addNewLeg(leg) {
      let dispatch_schedule = null,
        id = null,
        legs = [],
        ds = null;
      if (this.editingShipment != null) {
        try {
          ds = JSON.parse(this.editingShipment.dispatch_schedule);
          if (typeof ds.id != "undefined" && ds.id) id = ds.id;
          if (typeof ds.legs != "undefined" && ds.legs && ds.legs.length > 0)
            legs = ds.legs;
        } catch (err) {
          console.log(err);
          id = this.makegenerate();
        }
        dispatch_schedule = {
          id: id,
          location_from: this.editingShipment.terminal_id,
          last_free_date: this.editingShipment.last_free_date,
          per_diem_date: this.editingShipment.per_diem_date,
          legs: legs,
        };
      }
      let ob = {
        id: this.makegenerate(),
        schedule_id: dispatch_schedule.id,
        type: leg.selectedType,
        address: leg.address,
        date: leg.delivery_scheduled_date,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis,
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: false,
        from_date: leg.pickup_scheduled_date,
        to_time: leg.pickup_scheduled_time,
        arrival_at_pickup_location_date: "",
        arrival_at_pickup_location_time: "",
        departure_from_pickup_location_date: "",
        departure_from_pickup_location_time: "",
        arrival_at_delivery_location_date: "",
        arrival_at_delivery_location_time: "",
        departure_from_delivery_location_date: "",
        departure_from_delivery_location_time: "",
      };
      dispatch_schedule.legs.push(ob);
      axios
        .post(`${poBaseUrl}/dispatch-save/${this.editingShipment.id}`, {
          data: JSON.stringify(dispatch_schedule),
        })
        .then((res) => {
          if (res.status == 200) {
            this.dispatchNotifcation(
              "Container has been successfully updated!"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.editingLeg = null;
          this.editingShipment = null;
          this.showAddLegModal = false;

					if(this.currentSelectTab == 'Scheduled'){
							this.setSetFilterActive(this.currentStateClick)
					}else{
							this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
					}
        });
    },
    isAnyLegAvailable(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        }
        catch (err) {
          console.log(err);
        }
        if(dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          try {
            len = legs.length;
          }
          catch (err) {
            console.log(err);
          }
          if(len > 0){
            return legs.find(leg => !leg.completed)
          }
        }
      }
      return false;
    },
    isAnyUnarrivedLegAvailable(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        } catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          try {
            len = legs.length;
          } catch (err) {
            console.log(err);
          }
          if (len > 0) {
            return legs.find((leg) => !leg.completed);
          }
        }
      }
      return false;
    },
    editFirstLeg(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        }
        catch (err) {
          console.log(err);
        }
        if(dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          let leg = null;
          try {
            len = legs.length;
            if(len > 0){
              leg = legs.find(leg => !leg.completed)
            }
          }
          catch (err) {
            console.log(err);
          }
          if(len > 0 && leg) {
            this.editLeg(item, leg);
          }
        }
      }
    },
    editFirstUnarrivedLeg(item) {
      if (item.dispatch_schedule != "" && item.dispatch_schedule != null) {
        let dispatch_schedule = null;
        try {
          dispatch_schedule = JSON.parse(item.dispatch_schedule);
        } catch (err) {
          console.log(err);
        }
        if (dispatch_schedule) {
          let legs = dispatch_schedule.legs || [];
          let len = 0;
          let leg = null;
          try {
            len = legs.length;
            if (len > 0) {
              leg = legs.find((leg) => !leg.completed);
            }
          } catch (err) {
            console.log(err);
          }
          if (len > 0 && leg) {
            this.editLeg(item, leg);
          }
        }
      }
    },
    saveEditedLeg(leg) {
      let ob = {
        id: leg.id,
        schedule_id: this.editingLeg.schedule_id,
        driver_status: leg.driverstatus,
        type: leg.selectedType,
        address: leg.address,
        date: leg.delivery_scheduled_date,
        time: leg.delivery_scheduled_time,
        driver: leg.selectedDriver ? leg.selectedDriver : "",
        chassis: leg.chassis ? leg.chassis : "",
        mode: leg.selectedMode == 1 ? "Dropped" : "Live",
        completed: leg.arrival_at_delivery_location_date ? true : false,
        from_date: leg.pickup_scheduled_date,
        to_time: leg.pickup_scheduled_time,
        arrival_at_pickup_location_date:
          leg.arrival_at_pickup_location_date || "",
        arrival_at_pickup_location_time:
          leg.arrival_at_pickup_location_time || "",
        departure_from_pickup_location_date:
          leg.departure_from_pickup_location_date || "",
        departure_from_pickup_location_time:
          leg.departure_from_pickup_location_time || "",
        arrival_at_delivery_location_date:
          leg.arrival_at_delivery_location_date || "",
        arrival_at_delivery_location_time:
          leg.arrival_at_delivery_location_time || "",
        departure_from_delivery_location_date:
          leg.departure_from_delivery_location_date || "",
        departure_from_delivery_location_time:
          leg.departure_from_delivery_location_time || "",
      };
      axios
        .put(`${poBaseUrl}/legs/${leg.id}`, {
          leg_data: ob,
          shipment_id: this.editingShipment.id,
        })
        .then((res) => {
          if (res.status == 200) {
            this.dispatchNotifcation(
              "Container has been successfully updated!"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.editingLeg = null;
          this.editingShipment = null;
          this.showEditLegModal = false;
					if(this.currentSelectTab == 'Scheduled'){
							this.setSetFilterActive(this.currentStateClick)
					}else{
							this.selectedTabData(this.currentSelectTab, this.selected_sub_tab, 1)
					}
        });
    },
    saveNewChassis(data) {
      axios
        .post(`${poBaseUrl}/chassis-save`, data)
        .then((response) => {
          this.dispatchNotifcation(
            '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
              response.data.message +
              "</span>"
          );
          this.responseChassis = response.data.chassis;
          this.fetchChassis();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markAsArrived(item, leg) {
      this.editLeg(item, leg);
    },
    editLeg(item, leg) {
      //console.log(leg)
      this.$emit("retrieveContainerDetails", item);
      this.editingLeg = leg;
      this.editingShipment = item;
      this.showEditLegModal = true;
    },
    isEmpty(val) {
      return val == null || val == 0 || val == "undefined";
    },
    getNewDispatchScheduleLegs(item) {
      if (item != null) {
        item = this.getparse(item);
        if (item) {
          if (item.legs != "undefined") {
            return item.legs;
          }
        }
      }
      return [];
    },
    closeMilestoneDialog(item) {
      jQuery("#" + item.id + "_" + this.selectedMilestoneField).prop(
        "checked",
        !jQuery("#" + item.id + "_" + this.selectedMilestoneField).is(
          ":checked"
        )
      );
      this.milestoneDialogData = null;
      this.selectedMilestoneField = null;
      this.milestoneDialog = false;
    },
    updateMilestone(item) {
      axios
        .post(`${poBaseUrl}/milestones-update/${item.id}`, {
          id: item.id,
          value: this.isSelectedMilestoneFieldChecked,
          field: this.selectedMilestoneField,
        })
        .then((res) => {
          if (res.status == 200) {
            this.milestoneDialogData = null;
            this.selectedMilestoneField = null;
            this.milestoneDialog = false;
            if (item.discharged && item.fully_released && item.pier_pass_paid) {
              this.$emit("reinitializeTable");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openMilestoneDialog(e, item, field) {
      let isChecked = e.target.checked;
      this.milestoneDialogData = item;
      this.selectedMilestoneField = field;
      this.isSelectedMilestoneFieldChecked = isChecked;
      this.milestoneDialog = true;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async methodtest(metho) {
      var dis = this;
      var formData = new FormData();
      console.log(metho)
      formData.append("xid",this.allDriverList);
      formData.append("xname",this.allLocationList);
      await axios
        .post(`${poBaseUrl}/legsfilter`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          //console.error("There was an error!", error);
          alert("Server Error" + error);
        })
        .then((response) => {
          this.startloading = 0;
          //dis.filterdataRes = response;
          dis.newContainersData = response.data;
        });
    },
    triggersearch() {
      this.$refs.fi.$el.click();
    },
    disabledni() {
      this.isDisabledfilter = false;
    },
    changenowDropdown(ee) {
      this.triggerapply = 1;
      this.driverSelectFilter = ee;
      this.currentDriverFilterID = ee;
      this.typeFilter = 3;
      this.cardDropdownFilter = true;
      var drivername = this.magdriver.find((e) => {
        return e.id == ee;
      });
      var Checkdrivername = this.drivernameLabel.find((e) => {
        return e.id == drivername.id;
      });
      if (typeof Checkdrivername != "undefined") {
        if (Checkdrivername == "") {
          this.drivernameLabel.push(drivername);
        }
      } else {
        this.drivernameLabel.push(drivername);
      }
      this.driverfinalPush = this.drivernameLabel;
      this.typeFilter = 3;
    },
    async applyFilter() {
      this.startloading = 1;
      this.startFilter();
      // location alone
      //if(this.locationDropdowndetected == 1 && this.drivernameLabel.length == 0){
      if (this.detectchange == 1 && this.drivernameLabel.length == 0) {
        this.typeFilter = 4;
        this.$nextTick(() => {
          this.locationDropdowndetected = 4;
        });
        await this.methodtest(allid);
        this.isDisabledfilter = true;
        this.triggerapply = 1;
        this.triggerDisselected = 1;
        this.detectchange = 0;
      } else {
        // locaton and driver if
        var drivername = this.magdriver.find((e) => {
          return e.id == this.driverSelectFilter;
        });
        if (typeof drivername != "undefined") {
          var Checkdrivername = this.drivernameLabel.find((e) => {
            return e.id == drivername.id;
          });
          if (typeof Checkdrivername != "undefined") {
            if (Checkdrivername == "") {
              this.drivernameLabel.push(drivername);
            }
          } else {
            this.drivernameLabel.push(drivername);
          }
          this.typeFilter++;
          if (this.typeFilter < 3) {
            this.typeFilter = 3;
          }
          if (this.typeFilter == 2) {
            this.typeFilter++;
          }
          this.driverfinalPush = this.drivernameLabel;
          this.menu = true;
          this.driverSelectFilter = [];
        }
        var allid = [];
        Object.values(this.driverfinalPush).forEach(function (ff) {
          allid.push(ff.id);
        });
        await this.methodtest(allid);
        this.isDisabledfilter = true;
        this.triggerapply = 1;
        this.triggerDisselected = 1;
      }
      this.startFilter();
      //this.startloading = 0;
    },
    ClearFilter() {
      this.typeFilter = 0; // changed from 1
      this.menu = true;
      this.drivernameLabel = [];
      this.newByDriverFilterAssginged = [];
      this.driverSelectFilter = [];
      this.locationfromfilter = [];
      // added clear data
      this.locationDropdowndetected = 0;
      this.detectchange = 0;
      //this.foundsearch = false;
      this.isDisabledfilter = true;

      // new clear
      this.checkallLocation = []; // checkboxes of the location
      this.checkall = []; // checkboxes of the drivers
    },
    //button filter
    changemenow() {
      this.driverSelectFilter = this.checkall;
      this.isDisabledfilter = false;
      //this.triggerapply = 0;
      //this.triggerDisselected = 0;
    },
    changelocation() {
      console.log(this.locationfromfilter)
      this.locationfromfilter = this.checkallLocation;
      this.isDisabledfilter = false;
      //this.triggerapply = 0;
      //this.locationDropdowndetected = 0;
      this.detectchange = 1;
    },
    showhideClick() {
      this.showhideDropdown = this.showhideDropdown == true ? false : true;
    },
    getType(item) {
      let last_arrived_leg = this.getLastArrivedLeg(item);
      return last_arrived_leg.type ? last_arrived_leg.type : "__";
    },
    startFilter() {
      this.filterstarted = this.filterstarted ? false : true;
    },
    getLastArrivedLeg(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      if (legs.length > 0) {
        for (let i = legs.length - 1; i >= 0; i--) {
          if (legs[i].completed) {
            return legs[i];
          }
        }
      }
      return null;
    },
    getFirstUnArrivedLeg(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      if (legs.length > 0) {
        for (let i = 0; i < legs.length; i++) {
          if (!legs[i].completed) {
            return legs[i];
          }
        }
      }
      return null;
    },
    getTimeArrived(item) {
      let last_arrived_leg = this.getLastArrivedLeg(item);
      let time_html = "";
      let date_html = "";
      if (last_arrived_leg) {
        time_html =
          "<div> " +
          this.checkTotime(last_arrived_leg.arrival_at_delivery_location_time) +
          "  </div>";
        date_html =
          "<div class='text-dark-gray'>  " +
          this.getDateFormat(
            last_arrived_leg.arrival_at_delivery_location_date
          ) +
          " </div>";
      }
      return time_html + date_html;
    },
    getScheduledDate(item) {
      let dispatch_schedule = null;
      try {
        dispatch_schedule = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        dispatch_schedule = null;
      }
      let legs = dispatch_schedule ? dispatch_schedule.legs || [] : [];
      let not_scheduled = "<p class='text-dark-gray'> Not Scheduled </p>";
      if (
        ["Available"].includes(this.currentSelectTab) ||
        ["Available"].includes(this.selected_sub_tab.label)
      )
        return legs.length > 0 && legs[0].from_date
          ? this.getDateFormat(legs[0].from_date)
          : not_scheduled;
      else if (
        ["Dropped empty", "Yard full", "At Yard"].includes(
          this.currentSelectTab
        ) ||
        ["Dropped empty", "Yard full", "At Yard"].includes(
          this.selected_sub_tab.label
        )
      ) {
        let first_unarrived_leg = this.getFirstUnArrivedLeg(item);
        if (!(first_unarrived_leg && first_unarrived_leg.from_date)) {
          return not_scheduled;
        }
        if (
          ["Yard full", "At Yard"].includes(this.currentSelectTab) ||
          ["Yard full", "At Yard"].includes(this.selected_sub_tab.label)
        ) {
          let date_div =
            "<div class='text-dark-gray'>" +
            this.getDateFormat(first_unarrived_leg.from_date) +
            "</div>";
          let time_div =
            "<div>" + this.checkTotime(first_unarrived_leg.to_time) + "</div>";
          return time_div + date_div;
        }
        return this.getDateFormat(first_unarrived_leg.from_date);
      }
    },
    getTextColor(item) {
      return ["Available", "Pending"].includes(this.currentSelectTab) ||
        ["Available", "Pending"].includes(this.selected_sub_tab.label)
        ? this.compareWithToday(item.last_free_date, ">")
          ? "text-red"
          : this.compareWithToday(item.last_free_date, "==")
          ? "text-yellow"
          : ""
        : "";
    },
    getBg(item) {
      return ["Available", "Pending"].includes(this.currentSelectTab) ||
        ["Available", "Pending"].includes(this.selected_sub_tab.label)
        ? this.compareWithToday(item.last_free_date, ">")
          ? "bg-redish"
          : this.compareWithToday(item.last_free_date, "==")
          ? "bg-yellowish"
          : ""
        : "";
    },
    getBgAtheCustomer(item) {
      return ["At the Customer", "Dropped empty"].includes(
        this.currentSelectTab
      ) ||
        ["At the Customer", "Dropped empty"].includes(
          this.selected_sub_tab.label
        )
        ? this.compareWithToday(item.per_diem_date, ">")
          ? "bg-redish"
          : this.compareWithToday(item.per_diem_date, "==")
          ? "bg-yellowish"
          : ""
        : "";
    },
    disSelected(s) {
      this.triggerapply = 1;
      this.triggerDisselected = 1;
      this.driverfinalPush.splice(s, 1);
      this.disabledni();
      this.applyFilter();
      this.isDisabledfilter = true;
    },
    compareWithToday(date, operator) {
      if (date) {
        date = new Date(date);
        let now = new Date();
        date.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        if (operator == ">") return now > date;
        else if (operator == "==") return date.getTime() == now.getTime();
      }
      return false;
    },
    callitemcustomer(name) {
      if (name.customer == null) {
        return "--";
      } else {
        return name.customer.company_name;
      }
    },
    ...globalMethods,
    itemSelected() {
      //console.log(this.selectedTasks);
    },
    getDateUTCTRUE(date) {
      if (date) {
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(true).format("MMM DD, YYYY");
        } else {
          return "--";
        }
      }
      return "--";
    },
    getDateFormat(date) {
      if (date) {
        //return moment(date).utc(false).format('MMM DD, YYYY');
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(false).format("MMM DD, YYYY");
        } else {
          return "--";
        }
      }
      return "--";
    },
    getCarrier(item) {
      let carrier = item.carrier;
      return carrier !== null
        ? typeof carrier !== "undefined"
          ? carrier.name
          : "--"
        : "--";
    },
    getPerDiemDate(item) {
      if (item.per_diem_date !== undefined && item.per_diem_date !== "") {
        if (this.checkifValid(item.per_diem_date, "DD MMM YYYY")) {
          return moment(item.per_diem_date).utc(true).format("MMM DD, YYYY");
        } else {
          return "--";
        }
      }
      //return old code item.per_diem_date ? moment(item.per_diem_date).utc(false).format('MMM DD, YYYY') : '__'
    },
    getPerDiemDateClass(item) {
      if (item.per_diem_date !== undefined && item.per_diem_date !== "") {
        return this.compareWithToday(item.per_diem_date, ">")
          ? "text-red"
          : this.compareWithToday(item.per_diem_date, "==")
          ? "text-yellow"
          : "text--disabled";
      } else {
        return "text--disabled";
      }
    },
    getMode(item) {
      let scheds = null;
      let mode = "N/A";
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      if (scheds !== null) {
        scheds.legs.map((value) => {
          if (value.completed) {
            mode = value.mode === "Live" ? "Live" : "Drop";
          }
        });
      }
      return mode;
    },
    getATCTimeArrived(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var adlt =
        scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_time;
      if (adlt !== undefined && adlt !== "") {
        var bd = moment().format("YYYY-MM-DD");
        var checkdate = moment(bd + " " + adlt)
          .utc(true)
          .isValid();
        return checkdate ? moment(bd + " " + adlt).format("hh:mm A") : "--";
      } else {
        return "--";
      }
    },
    getAddress(item) {
      if (item.dispatch_schedule !== undefined) {
        let ds = JSON.parse(item.dispatch_schedule);
        let legs = ds.legs;
        if (legs.length > 0) {
          if (
            legs[legs.length - 1].address !== null &&
            legs[legs.length - 1].completed
          ) {
            return legs[legs.length - 1].address ?? "--";
          } else {
            return "--";
          }
        } else return "--";
      } else return "--";
    },
    getDateArrived(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var adld =
        scheds.legs[scheds.legs.length - 1]?.arrival_at_delivery_location_date;
      //return  adld !== undefined && adld !== "" ? moment(adld).utc(false).format('DD MMM YYYY') : '__'
      if (adld !== undefined && adld !== "") {
        if (this.checkifValid(adld, "DD MMM YYYY")) {
          return moment(adld).utc(true).format("DD MMM YYYY");
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    getScheduleTime(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var t = scheds.legs[scheds.legs.length - 1]?.to_time;
      var is_arrived = scheds.legs[scheds.legs.length - 1]?.completed;
      var bd = moment().format("YYYY-MM-DD");
      // Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
      //
      if (this.checkifValid(bd + " " + t, "hh:mm A") && !is_arrived) {
        return moment(bd + " " + t).format("hh:mm A");
      } else {
        return "--";
      }
    },
    getScheduleDate(item) {
      let scheds = null;
      try {
        scheds = JSON.parse(item.dispatch_schedule);
      } catch (err) {
        scheds = null;
      }
      var d = scheds.legs[scheds.legs.length - 1]?.from_date;
      var is_arrived = scheds.legs[scheds.legs.length - 1]?.completed;
      // Return blank if the last leg is arrived, there should be another schedule leg that has not yet arrived
      if (this.checkifValid(d, "DD MMM YYYY") && !is_arrived) {
        return moment(d).utc(true).format("DD MMM YYYY");
      } else {
        return "--";
      }
    },
    getContainerSize(item) {
      return item.container_size?.name ?? "--";
    },
    updateContainer(item, dateTime) {
      let payload = {
        item: item,
        dateTime: dateTime,
      };
      this.isSubmitting = true;
      this.setContainerEmptyStatus(payload);
      this.$emit("reinitializeTable");
      this.dispatchNotifcation("Container has been successfully updated!");
      this.isSubmitting = false;
      this.dialog = false;
    },
    getpickup(date) {
      if (typeof date != "undefined") {
        //return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
        if (this.checkifValid(date, "MMM DD, YYYY")) {
          return moment(date).utc(true).format("MMM DD, YYYY");
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    Ref(item) {
      if (item.shifl_ref != null) {
        var dispatchitem = this.typeFilter
          ? item.new_dispatch_schedule
          : item.dispatch_schedule;
        var getContainer =
          item.trucker_container != null
            ? this.capitalize(item.trucker_container)
            : "";
        return (
          '<span class="referennceColumn ' +
          this.checkIFOneRowdispatch(dispatchitem) +
          '">' +
          '<span id="container">' +
          getContainer +
          '</span><br><span style="color:#6D858F;display: inline-block;padding-top: 12px;">' +
          item.shifl_ref +
          "</span></span>"
        );
      } else {
        return "";
      }
    },
    /**
     *  Trucker Container Name
     *  Mixed Container name and Customer name
     */
    TruckerConCustomer(item) {
      var l;
      var dispatchitem = this.typeFilter
        ? item.new_dispatch_schedule
        : item.dispatch_schedule;
      if (dispatchitem != "") {
        var parsejson = this.getparse(dispatchitem);
        l = parsejson.legs.length;
      } else {
        l = 0;
      }
      let whichAssignTo = this.checkIFOneRowdispatch(dispatchitem);
      let pilaynaassign = this.HomanyTtypes(dispatchitem);
      const toCustomerCase = (ct) => {
        return ct
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      //let contai 			= item.trucker_container ;
      //let concatContainer = contai != null ? '<span>' + contai.toUpperCase() + '</span><br>' : '';
      let concatCustomer =
        item.customer != null
          ? '<span style="color:#6D858F">' +
            toCustomerCase(item.customer.company_name) +
            "</span>"
          : "";
      this.refcontainercl = "";
      return (
        '<p data-numberAssignType="' +
        pilaynaassign +
        '" data-id="' +
        l +
        '" id="' +
        whichAssignTo +
        '" class="' +
        whichAssignTo +
        '">' +
        concatCustomer +
        "</p>"
      );
    },
    fromdate(date) {
      if (typeof date != "undefined") {
        //return '<span class="greyColor">, '+ moment(date).utc(false).format('MMM DD, YYYY')+'</span>';
        return moment(date).utc(true).format("MMM DD, YYYY");
      } else {
        return "--";
      }
    },
    checkifValid(string, format) {
      return moment(moment(string).format(format), format, true).isValid();
    },
    getCurrentTab(id, i, tab) {
      this.sub_tab = tab.sub_tab;
      if (tab.sub_tab.length > 0) this.selected_sub_tab = tab.sub_tab[0];
      if (!this.getTabLoading) {
        if (["Cancelled", "Completed"].includes(id)) {
          this.selected_sub_tab = {};
        }
        this.currentSelectTab = id;
        this.tabClicked = true;
        this.storeCurrentTabSelected({
          id: id,
          activeTab: i,
        });
      }
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        const i = String(index[0]).toLowerCase();
        var al = "";
        var bl = "";
        if (i !== "undefined") {
          if (["Pickup On"].includes(index[0])) {
            let ads = JSON.parse(a.dispatch_schedule);
            let bds = JSON.parse(b.dispatch_schedule);
            if (ads !== null) {
              if (ads.legs.length > 0) {
                ads.legs.map((leg, i) => {
                  if (!this.aIsfirst) {
                    if (!leg.completed) {
                      let a_aapld =
                        ads?.legs[i].arrival_at_pickup_location_date;
                      let a_aaplt =
                        ads?.legs[i].arrival_at_pickup_location_time;
                      let date =
                        a_aapld !== undefined && a_aapld !== ""
                          ? a_aapld
                          : ads.legs[i].from_date;
                      let time =
                        a_aaplt !== undefined && a_aaplt !== ""
                          ? a_aaplt
                          : "00:00:00";
                      al = date + " " + time;
                      this.aIsfirst = 1;
                    }
                  }
                });
              }
            }
            if (bds !== null) {
              if (bds.legs.length > 0) {
                bds.legs.map((leg, i) => {
                  if (!this.bIsfirst) {
                    if (!leg.completed) {
                      let b_aapld =
                        bds?.legs[i].arrival_at_pickup_location_date;
                      let b_aaplt =
                        bds?.legs[i].arrival_at_pickup_location_time;
                      let date =
                        b_aapld !== undefined && b_aapld !== ""
                          ? b_aapld
                          : bds.legs[i].from_date;
                      let time =
                        b_aaplt !== undefined && b_aaplt !== ""
                          ? b_aaplt
                          : "00:00:00";
                      bl = date + " " + time;
                      this.bIsfirst = 1;
                    }
                  }
                });
              }
            }
            this.aIsfirst = 0;
            this.bIsfirst = 0;
            if (isDesc[0]) {
              return new Date(bl) - new Date(al);
            } else {
              return new Date(al) - new Date(bl);
            }
          }
          if (
            ["ETA", "last_free_date", "lfd_pdd", "per_diem_date"].includes(
              index[0]
            )
          ) {
            let j, k;
            j = k = i;
            if (["lfd_pdd"].includes(index[0])) {
              j = this.getLastArrivedLeg(a)
                ? "per_diem_date"
                : "last_free_date";
              k = this.getLastArrivedLeg(b)
                ? "per_diem_date"
                : "last_free_date";
            }
            if (isDesc[0]) {
              return new Date(b[k]) - new Date(a[j]);
            } else {
              return new Date(a[j]) - new Date(b[k]);
            }
          }
        }
      });
      return items;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    showAlert(item) {
      localStorage.setItem("queryData", this.currentSelectTab);
      this.storeEditData({
        items: item,
        objectMeta: this.metaobject,
        files: this.mgaFiles,
        drivers: this.magdriver,
        terminals: this.terminals,
        customers: this.customers,
        shipmentsize: this.shipmentsize,
        currentState: this.currentSelectTab,
      });
      this.$router.push("/container/edit/" + item.id).catch(()=>{});
    },
    openDialog($event, item) {
      var isChecked = $event.target.checked;
      if (!item.container_empty && isChecked) {
        this.dialogData = item;
        this.dialog = true;
      } else if (item.container_empty && !isChecked) {
        this.dialogData = item;
        this.dialog = true;
      }
    },
    closeDialog(item) {
      this.dialog = false;
      var div = jQuery(document);
      var isChecked = div.find("#" + item.id).is(":checked");
      if (!item.container_empty && isChecked) {
        jQuery("#" + item.id).prop("checked", false);
      } else if (item.container_empty && !isChecked) {
        jQuery("#" + item.id).prop("checked", true);
      }
    },
		handlePicker() {
			this.isDatePickerOpen = true
			this.pickerLabel = 'Pick ETA Range'
		},
    createDo() {
      this.$router.push("/delivery-order").catch(()=>{});
    },
    gotoCreate() {
      this.$router.push("/container/add/new").catch(()=>{});
    },
    clickFilter(nameele) {
      this.classSelected = nameele;
    },
    checkIfClassActive(name) {
      if (name === this.classSelected) {
        return "active";
      }
    },
    /* SET ACTIVE */
    setSetFilterActive(item) {
      if (item) {
        this.filtertabs(item);
        // if click assign,all,unassigned
        this.menu = false;
        this.driverSelectFilter = [];
        this.locationfromfilter = [];
        // if click assign,all,unassigned
        this.classSelected = item;
        this.currentStateClick = item;
        let __ = this;
        var div = jQuery(document);
        if (this.lenthDispatch > 1) {
          __.driverfinalPush = [];
          __.drivernameLabel = [];
          if (item == "Assign") {
            __.typeFilter = 1;
            let namePrefix = "hasDriver";
            //div.find(".v-data-table__wrapper .hasNoDriver").hide();
            //div.find(".v-data-table__wrapper .hasDriver").show();
            var leng = div.find(
              ".v-data-table__wrapper" +
                " table tr.hastwotypes td.schedule p.hasDriver"
            ).length;
            __.calJqueryEach("schedule", namePrefix, leng),
              __.calJqueryEach("pickupon", namePrefix, leng),
              __.calJqueryEach("deliveryon", namePrefix, leng),
              __.calJqueryEach("statuson", namePrefix, leng);
          } else if (item == "All") {
            this.typeFilter = 0;
            //div.find(".v-data-table__wrapper .hasNoDriver").show();
            //div.find(".v-data-table__wrapper .hasDriver").show();
            __.callremove(__.hasDriverLastElement, "schedule"),
              __.callremove(__.hasDriverLastElement, "pickupon"),
              __.callremove(__.hasDriverLastElement, "deliveryon"),
              __.callremove(__.hasDriverLastElement, "statuson");
          } else {
            this.typeFilter = 2;
            //div.find(".v-data-table__wrapper .hasNoDriver").show();
            //div.find(".v-data-table__wrapper .hasDriver").hide();
          }
          this.setFilter(item);
        }
      }
    },
    /**
     *  Add class to access the last element of the group
     *  @param1 = name class of ta target element (schedule,pickupon,deliveryon,statuson)
     *  @param2 = hassDriver or hasnoDriver
     *  @param3 = check the length
     */
    calJqueryEach(cl, pr, l) {
      if (l) {
        jQuery(document)
          .find(
            ".v-data-table__wrapper" +
              " table tr.hastwotypes td." +
              cl +
              " p." +
              pr
          )
          .each(function (e) {
            if (e + 2 == l + 1) {
              jQuery(this).addClass("hasDriverLastElement");
            }
          });
      }
    },
    /**
     *   Remove if all
     *   @param1 = element , the element to remove the class
     *   @param2 = el object element
     */
    callremove(element, el) {
      var c;
      var _dc = jQuery(document);
      switch (el) {
        case "schedule":
          c = _dc.find(".v-data-table__wrapper" + " table td.schedule p");
          break;
        case "deliveryon":
          c = _dc.find(".v-data-table__wrapper" + " table td.deliveryon p");
          break;
        case "pickupon":
          c = _dc.find(".v-data-table__wrapper" + " table td.pickupon p");
          break;
        case "statuson":
          c = _dc.find(".v-data-table__wrapper" + " table td.statuson p");
          break;
      }
      c.removeClass(element);
    },
    /* GET SCHEDULE */
    get_schedule(item) {
      if (item !== null) {
        var parsejson = this.getparse(item),
          kani = this,
          c = "",
          s = "";
          if(typeof parsejson.legs !=='undefined'){
            if (parsejson.legs.length > 0)
            {
              Object.values(parsejson.legs).forEach(function (f) {
                c = kani.findDriverClass(f.driver);
                let sched = typeof f.id !='undefined' ? f.id : '--' ;
                s +=
                  '<p data-id="' +
                  c +
                  '" id="' +
                  c +
                  '" class="' +
                  c +
                  ' Assign commonP"><span>' +
                  sched +
                  '</span><br><span class="totype" >' +
                  (f.type == "Empty Return" ? "" : "To ") +
                  kani.nameTransform(
                    f.type
                      ? kani.typeDropdown.find((e) => e.value == f.type).label
                      : f.type
                  ) +
                  "</span><br>" +
                  '<span class="totype" >' +
                  kani.nameTransform(
                    f.mode ? (f.mode == "Dropped" ? "Drop" : f.mode) : ""
                  ) +
                  "</span></p>";
              });
              return s;
            }
          }
      }
    },
    /* GET TYPE */
    get_type(item) {
      var parsejson = this.getparse(item);
      if (parsejson.legs.length > 0) {
        return parsejson.legs[0].type;
      }
    },
    /* ASSIGN TO */
    getAssignedTo(item) {
      var des = this.getAllcontainerStatus.components.drivers,
        kani = this,
        hasDriver = "";
      if (item !== null) {
        var ti = this.getparse(item);
        if(typeof ti.legs !='undefined'){
          if (ti.legs.length > 0) {
            var s = "";
            Object.values(ti.legs).forEach(function (f) {
              var r = des.filter((find) => find.id == f.driver);
              if (r.length > 0) {
                hasDriver = "hasDriver ";
                Object.values(r).forEach(function (ff) {
                  s +=
                    '<p  class="' +
                    hasDriver +
                    ' DRIVER_ASSIGN Assign commonP" ><span>' +
                    kani.nameTransform(ff.name) +
                    "<span></p>";
                });
              } else {
                hasDriver = "hasNoDriver ";
                s +=
                  '<p  class="' +
                  hasDriver +
                  ' DRIVER_unASSIGN unassignna commonP" ><span>Unassigned<span></p>';
              }
            });
            return s;
          }//end length
        }
      }
    },
    /**
     *  Delivery On
     *  Scheduled Delivery Date = leg.date
     */
    deliveryon(item) {
      if (item !== null) {
        var akoy = this,parsejson = this.getparse(item);

        if(typeof parsejson.legs !='undefined')
        {
          if (parsejson.legs.length > 0)
          {
            var s = "",
              commonP = "commonP ";
            Object.values(parsejson.legs).forEach(function (f) {
              let date = "",
                time = "";
              if (typeof f.date != "undefined" && f.date != "") {
                date = f.date;
                time = "";
                if (typeof f.time != "undefined" && f.time != "") {
                  time = f.time;
                }
              }
              var hasDriver = akoy.findDriverClass(f.driver);
              var theDate = "";
              if (akoy.checkTotime(date) == "--" && akoy.fromdate(time) == "--") {
                theDate = "--";
              } else {
                if (akoy.checkTotime(time) == "--") {
                  theDate =
                    '<span class="greyColor">' + akoy.fromdate(date) + "</span>";
                } else {
                  theDate =
                    akoy.checkTotime(time) +
                    ', <span class="greyColor">' +
                    akoy.fromdate(date) +
                    "</span>";
                }
              }
              var xlenthtaddress =
                f.address.length > 50
                  ? f.address.substring(0, 50) + "..."
                  : f.address;
              s +=
                '<p  class="' +
                commonP +
                "Assign " +
                hasDriver +
                '"><span>' +
                xlenthtaddress +
                '<span class="greyColor"><br><span>' +
                theDate +
                "</p>";
            });
            return s;
          }
        }
      }
    },
    /**
     *   Pickup On
     *   @param1 = item or the dispatch_schedule
     *   @param2 = terminal name for the leg one pickup address
     *   Scheduled Pickup Date = leg.from_date
     */
    pickupOn(item,mainItem) {

			if(item !== null) {
				var akoy = this,parsejson = this.getparse(item);
				akoy.lenthDispatch = 0;

				var counterLeg = 0;

        if(typeof parsejson.legs !='undefined')
        {
          var l = parsejson.legs.length
          if(parsejson.legs.length > 0) {
            var ii = "",lastLeg = {address: ""},address;
            var terminalName = '';

            // get the terminal name
            if(mainItem.terminal !==null){
              Object.keys(mainItem)
              .forEach(function(key) {
                if(key=='terminal'){
                  terminalName = (mainItem['terminal'].name);
                }
              });
            }

            /** Get the legs */
            Object.values(parsejson.legs).forEach(function(f) {
              var datePick = {};
              if(typeof f.arrival_at_pickup_location_date != "undefined") {
                if(f.arrival_at_pickup_location_date != "" && f.arrival_at_pickup_location_date != null) {
                  datePick.date = f.arrival_at_pickup_location_date;
                  datePick.time = f.arrival_at_pickup_location_time;
                }
                else {
                  datePick.date = f.from_date;
                  datePick.time = f.to_time;
                }
              }
              else {
                datePick.date = f.from_date;
                datePick.time = f.to_time;
              }
              // address = (counterLeg == 0) ? { address: terminalName,time: f.to_time,date: f.from_date} :
              // 		{ address: lastLeg.address,time: lastLeg.time,date: lastLeg.date};
              if(typeof terminalName === "undefined" || terminalName === null || terminalName === "") {
                terminalName = "--";
              }
              // if naa nakita ang location ug ang x_element
              // x_element mao ni ang current element sa array
              if(akoy.foundsearch == true && typeof f.x_element != "undefined") {
                address = {
                  address: f.x_element.address,
                  time: f.time,
                  date: f.date
                };
              }
              else if(typeof f.x_element != 'undefined') {
                // gikan ni sa dili filter, normal fetch
                // if naa nakita x element dri padulong
                address = {
                  address: f.x_element.address,
                  time: f.time,
                  date: f.date
                };
              }
              else {
                address = counterLeg == 0 ? {
                  address: terminalName,
                  time: datePick.time,
                  date: datePick.date,
                } : {
                  address: + lastLeg.address ,
                  time: lastLeg.time,
                  date: lastLeg.date,
                };
              }
              var hasDriver = akoy.findDriverClass(f.driver);
              var datedate = "";
              if(akoy.convertDateformat(datePick.time, "isDate") == "--" && akoy.getpickup(datePick.date) == "--") {
                datedate = "--";
              }
              else {
                if(akoy.convertDateformat(datePick.time, "isDate") == "--") {
                  datedate = '<span class="greyColor">' + akoy.getpickup(datePick.date) + "</span>";
                }
                else {
                  if(akoy.getpickup(datePick.date) == "--") {
                    datedate = akoy.convertDateformat(datePick.time, "isDate");
                  }
                  else {
                    datedate = akoy.convertDateformat(datePick.time, "isDate") + '<span class="greyColor">, ' + akoy.getpickup(datePick.date) + "</span>";
                  }
                }
              }
              var lenthtaddress = address.address.length > 60 ? address.address.substring(0, 50) + "..." : address.address;
              ii += '<p vlength="' + l + '" id="' + hasDriver + '"  class="commonP Assign ' + hasDriver + '"><span>' + lenthtaddress + '<span class="greyColor"><br><span>' + datedate + "</p>";
              lastLeg = {
                address: f.address,
                time: datePick.time,
                date: datePick.date,
              };
              counterLeg++;
            });
            return ii;
          }
        }
			}
		},
    /* PARSE THE ITEM */
    getparse(item) {
      if (item != "") {
        try {
          return JSON.parse(item);
        } catch (err) {
          // Uncaught SyntaxError: JSON.parse: unexpected character at
          // line 1 column 2 of the JSON data
          return {};
        }
      }
    },
    /* TRANSFORM STRING  */
    nameTransform(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    /**
     *   Convert date format
     *   @param1 = date
     *   @param2 = date filter string
     */
    convertDateformat(data, isDate) {
      if (typeof data != "undefined") {
        var thedate = "--";
        if (isDate == "isDate") {
          thedate = this.timeConvert(data);
        }
        return thedate;
      } else {
        return "--";
      }
    },
    // check to time
    checkTotime(data) {
      if (typeof data != "undefined") {
        return this.timeConvert(data);
      } else {
        return "--";
      }
    },
    // time convert
    timeConvert(dateString) {
      const d = dateString;
      const validHHMMstring = (str) =>
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);
      if (validHHMMstring(d)) {
        const timeString12hr = new Date(
          "1970-01-01T" + d + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        });
        return timeString12hr.replace(/\s/g, "");
      } else {
        return "--";
      }
    },
    // find driver
    findDriverClass(f) {
      var des = this.magdriver,
        c = "",
        r = des.filter((find) => find.id == f);
      if (r.length > 0) {
        c = "hasDriver ";
        this.loopUnassigned = 1;
      } else {
        c = "hasNoDriver ";
        this.loopAssign = 1;
      }
      this.lenthDispatch = this.loopUnassigned + this.loopAssign; // count number of assigned and unassigned
      return c;
    },
    /**
     *	Check if row has one dispatch
     */
    checkIFOneRowdispatch(item) {
      if (item != "") {
        var naadrivertanan = 0;
        var walaydrivertanan = 0;
        var ds = this;
        var des = this.magdriver,
          parsejson = this.getparse(item),
          returnme = "";
        if (parsejson.legs.length == 1) {
          Object.values(parsejson.legs).forEach(function (f) {
            const r = des.filter((find) => find.id == f.driver);
            if (r.length > 0) {
              returnme = ds.naayDriverwala;
            } else {
              returnme = ds.walayDriver;
            }
          });
        } else {
          Object.values(parsejson.legs).forEach(function (f) {
            const r = des.filter((find) => find.id == f.driver);
            if (r.length > 0) {
              naadrivertanan++;
            } else {
              walaydrivertanan++;
            }
          });
          // multiple dispatch pero walay driver and tanan
          if (naadrivertanan == 0 && walaydrivertanan != 0) {
            returnme = ds.walayDriver;
          }
          // multiple dispatch pero naay driver tanan walay unassigned
          if (naadrivertanan != 0 && walaydrivertanan == 0) {
            returnme = ds.naayDriverwala;
          }
        }
        return returnme;
      } else {
        return "Noclass";
      }
    },
    // Pila na assign driver sa multiple dispatch
    HomanyTtypes(item) {
      if (item != "") {
        var des = this.magdriver,
          parsejson = this.getparse(item),
          unassign = 0,
          assign = 0;
        if (parsejson.legs.length > 1) {
          Object.values(parsejson.legs).forEach(function (f) {
            const r = des.filter((find) => find.id == f.driver);
            if (r.length > 0) {
              unassign = 1;
            } else {
              assign = 1;
            }
          });
        }
        return parseInt(unassign) + parseInt(assign);
      } else {
        return "Noclass";
      }
    },
    capitalize(item) {
      return item.toUpperCase();
    },
    selectedTabData(tab, subTab = this.selected_sub_tab, page) {
      //if (!["not"].includes(tab)) {
      this.shouldLoad = true;
      if (tab == "All") {
        this.getSelectedTabData("all", page);
      } else if (tab == "At Port") {
        if (subTab.label == "Pending") {
          this.getSelectedTabData("at-port-pending", page);
        } else {
          this.getSelectedTabData("at-port-available", page);
        }
      } else if (tab == "At Yard") {
        if (subTab.name == "full") {
          this.getSelectedTabData("at-yard-full", page);
        } else {
          this.getSelectedTabData("at-yard-empty", page);
        }
      } else if (tab == "At the Customer") {
        if (subTab.name == "full") {
          this.getSelectedTabData("at-customer-full", page);
        } else {
          this.getSelectedTabData("at-customer-empty", page);
        }
      } else if (tab == "Pending billing") {
        this.getSelectedTabData("pending-billing", page);
      } else if (tab == "Completed") {
        this.getSelectedTabData("completed", page);
      } else if (tab == "Cancelled") {
        this.getSelectedTabData("cancelled", page);
      } else if (tab == "Scheduled") {
        if (subTab == "Assign") {
          this.getSelectedTabData("assigned-schedule", page);
        } else if (subTab == "Unassigned") {
          this.getSelectedTabData("unassigned-schedule", page);
        } else {
          this.getSelectedTabData("all-scheduled", page);
        }
      }
      //} else {
      this.isDisabled = false;
      //}
    },
    async getSelectedTabData(tab, page) {
      this.oldContainersData = this.newData;
      if (this.search !== null && this.search !== "") {
        await axios
          .get(`${poBaseUrl}/customer-visibility/${this.$route.params.customer_id}/shipment-tabs/${tab}`, {
			params: {
				page: page,
				search: this.search,
				customer_id: this.selectedCustomerFilter.id,
				status: this.selectedActiveInactiveFilter.value,
				etaStart : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '',
				etaEnd : this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : ''
			},
          })
          .then((response) => {
            this.page = 1;
            this.lastPage = response.data.results.last_page;
            if (response.data.results.last_page > 1) {
              this.pageCount = response.data.results.last_page;
            }
            //console.log(response.data.results.data);
            this.newContainersData = response.data.results.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {

        if(typeof page =='undefined'){
          page = 1;
        }

        await axios.get(`${poBaseUrl}/customer-visibility/${this.$route.params.customer_id}/shipment-tabs/${tab}?page=${page}&customer_id=${this.selectedCustomerFilter.id}&status=${this.selectedActiveInactiveFilter.value}&etaStart=${(this.selectedEtaRange.length > 0 ? this.selectedEtaRange[0] : '')}&etaEnd=${(this.selectedEtaRange.length > 0 ? this.selectedEtaRange[1] : '')}`).then((response) => {
			if(typeof response.data.components !== "undefined") {
				this.magdropterminal = response.data.components.loccationfilter;
			}

            if (typeof response.data.driversDropdown !== "undefined") {
              this.magdriverFilter = response.data.driversDropdown;
            }

            this.page = page;
            this.lastPage = response.data.results.last_page;
            if (this.pageCount !== response.data.results.last_page) {
              this.pageCount = response.data.results.last_page;
            }
            this.newContainersData = response.data.results.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.shouldLoad = false;
      this.isDisabled = false;
    },
  },
  updated() {
    if (this.sub_tab.length == 0) {
      let current_tab_item = this.newtabitem.find(
        (f) => f.name == this.currentSelectTab
      );
      if (
        current_tab_item &&
        current_tab_item.sub_tab &&
        current_tab_item.sub_tab.length > 0
      ) {
        this.sub_tab = current_tab_item.sub_tab;

        if (typeof this.selected_sub_tab.name == "undefined") {
          this.selected_sub_tab = current_tab_item.sub_tab[0];
        }
      }
    }
    /** Alex Timbal Following modifies table tr tab with new attributes */
    if (this.callTrigger) {
      this.setSetFilterActive(this.callTrigger);
    }
    var _ = jQuery;
    var ds = this;
    var __ = `${el}`;
    if (this.currentSelectTab == this.filterTabitem) {
      _(__ + " td div.classTop p:even").css({
        height: "74px",
        "border-bottom": "1px solid #eee",
      }),
        _(__ + " td.schedule p").css({
          "margin-left": "17px",
        }),
        _(__ + " td.pickupon p").css({
          "padding-left": "16px",
        }),
        _(__ + " td.deliveryon p").css({
          "padding-left": "16px",
        }),
        _(__ + " td.statuson p").css({
          "padding-left": "16px",
        }),
        _(__ + " td.schedule").css("padding-left", "0px !important"),
        _(".v-data-table__wrapper table td div.classTop p:last-child").css({
          "border-bottom": "0px solid #eee",
        }),
        {},
        _(document)
          .find(
            ".v-data-table__wrapper table tr td.container_customer .classTop"
          )
          .each(function () {
            let nextClass = _(this).children().first(),
              id = nextClass.attr("id"),
              count = nextClass.attr("data-id"),
              numbertype = nextClass.attr(ds.numberAssignType);
            if (typeof count != "undefined") {
              if (count > 0) {
                _(this).closest("tr").addClass(id),
                  _(this).closest("tr").attr("data-count", count),
                  _(this)
                    .closest("tr")
                    .attr("data-numberassigntype", numbertype);
                if (numbertype > 1) {
                  _(this).closest("tr").addClass(ds.hastwotypes);
                }
              }
            }
          }),
        {},
        _(document)
          .find(".v-data-table__wrapper table tr td .classTop p:last-child")
          .each(function () {
            _(this).css({
              "margin-bottom": "0px",
            });
            _(this).addClass("AddlastElementChild");
          });
    }
    this.updatedni = 1;
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../assets/scss/pages_scss/containers/containers.scss";
@import "../../../assets/scss/buttons.scss";

.containers-inner-wrapper .v-data-table.containers-table .v-data-table__wrapper table thead tr th span {
    font-size: 14px !important;
}

.DRIVER_ASSIGN,
.DRIVER_unASSIGN {
  padding: 0;
}

.DRIVER_ASSIGN {
    color: #0171a1;
}

.DRIVER_unASSIGN {
    color: #6d858f;
    font-size: 14px;
}

.v-data-table__wrapper table .tr td.schedule p {
    margin-left: 17px;
}
.greyColor,
.totype {
  color: #6d858f;
}

.commonP {
  border-bottom: 1px solid #eeeeee;
  margin-top: 0 !important;
  padding-bottom: 2px !important;
}

td.statuson div p span {
    position: relative;
    // top:calc(27% + 0%);
}

.hasDriverLastElement {
  border: 0 !important;
}

.containers-inner-wrapper.visibility .referrence div.classTop #container {
    color: #0171a1;
    font-weight: 600;
    position: relative;
    top: 12px;
}

.containers-inner-wrapper.visibility .referrenceN div.classTop {
    color: #0171a1;
    font-weight: 600;
    position: relative;
    top: 4px;
}

.v-data-table__wrapper table td p {
  padding-bottom: 0 !important;
}
.bg-redish {
    background: #fff2f2;
}
.bg-yellowish {
    background: #fff9f0;
}
.text-red {
    color: #f93131;
}
.text-yellow {
    color: #d68a1a;
}
.text-dark-gray {
    color: #6d858f;
}

.buttonfilter {
    border-radius: 4px;
    width: 80px;
    height: 40px;
    border: 1px solid #0171a1;
}
.imgtop {
    position: relative;
    top: 2px;
}

.showdropdown {
  position: absolute !important;
  height: 123px;
  background: #fff;
  left: px;
  right: 0;
  margin-top: 2px;
  border-radius: 4px;
  box-shadow: -1px 2px 2px 0 #eee;
  padding: 16px;
  border: 1px solid #eee;
}

.showResultdriver {
    color: #819fb2;
    font-weight: 600;
    font-size: 10px;
}

.driverLabel {
    font-size: 10px;
    line-height: 14px;
    color: #819fb2;
}

.filterButtonApply {
    height: 30px !important;
    width: 49px !important;
    background: #0171a1 !important;
    font-size: 10px !important;
}

.filterButtonCancel {
    height: 30px !important;
    width: 49px !important;
    font-size: 10px !important;
}

.cardDropdown .v-list-item {
    width: 303px !important;
}

.Suncastle {
    display: block;
    border: 1px solid #eee;
    text-align: center;
    padding: 1px;
    border-radius: 15px;
    padding-bottom: 3px;

    display: inline-block;
    position: relative;
    padding-right: 27px;
    padding-left: 9px;
    padding-top: 1px;
    margin-right: 4px;
    margin-bottom: 5px;
}

.closeXbutton {
    position: absolute;
    font-size: 11px;
    right: 10px;
    top: 8.1px;
}

.driverNameTtitle {
    font-size: 12px !important;
}

.cardDropdown .v-input__slot {
    border: 1px solid #eee;
    border-radius: 4px;
}

.cardDropdown .v-select__slot {
    padding-left: 8px;
    padding-right: 6px;
    font-size: 12px;
}

.cardDropdown .v-select__slot .v-icon {
    position: relative;
    top: 3px;
}

.cardDropdown .v-select--is-menu-active {
    background: transparent !important;
}

.v-select--is-menu-active {
    background: transparent !important;
}

.v-input__control > .v-input__slot::before {
    border-color: transparent !important;
}

.initialDropdown {
    margin-top: 8px;
}

.initialDropdown .v-input__slot {
    border: 1px solid #b4cfe0;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 3px;
}

.initialDropdown .v-input__append-inner {
    position: relative;
    top: 3px;
    left: -3px;
}

.initialDropdown .v-input,
.initialDropdown .v-input__control,
.initialDropdown .v-input__slot {
    height: 48px;
}

.filterShowtag {
    background: #fff !important;
    height: 48px !important;
}

.filterShowtag .v-toolbar__content {
    height: 48px !important;
    font-size: 12px;
    background: #fff !important;
}

.wrapno-matching {
    padding-top: 70px;
    display: inline-block;
    padding-bottom: 66px;
}

.nomatching {
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
}

.pickup_on {
    margin-bottom: 15px !important;
}
.buttonCardActions {
    padding-left: 18px;
    padding-bottom: 18px;
    padding-top: 18px;
}
.aside {
    color: #ccc;
}
.my-checkbox {
    position: relative !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 5px;
    box-sizing: content-box;
    overflow: hidden;
}
.my-checkbox:before {
    content: "";
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    transition: 0.2s border-color ease;
}
.my-checkbox:checked:before {
    border-color: #0171a1;
    transition: 0.5s border-color ease;
}
.my-checkbox:disabled:before {
    border-color: #ccc;
    background-color: #ccc;
}
.my-checkbox:after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    background-color: #0171a1;
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    transform: translate(-50%, -50%) scale(0);
}
.my-checkbox[type="checkbox"]:before {
    border-radius: 5px;
}
.my-checkbox[type="checkbox"]:after {
  width: 9.6px;
  height: 16px;
  border-radius: 0;
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
  background-color: transparent;
  box-shadow: 4px 4px 0 0 #0171a1;
}
.my-checkbox[type="checkbox"]:checked:after {
    -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
    animation: toggleOnCheckbox 0.2s ease forwards;
}
.my-checkbox[type="checkbox"].filled:before {
    border-radius: 4px;
    transition: 0.2s border-color ease, 0.2s background-color ease;
}
.my-checkbox[type="checkbox"].filled:checked:not(:disabled):before {
    background-color: #0171a1;
}
.my-checkbox[type="checkbox"].filled:not(:disabled):after {
  box-shadow: 4px 4px 0 0 white;
}
.my-checkbox2  label{
  font-size: 12px !important;
}
.my-checkbox2 {
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.my-checkbox2 .v-messages {
  height: 0px !important;
  line-height: 0px !important;
  max-height: 0px !important;
  min-height: 0px;
}
.my-checkbox2 .v-input__slot {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
@-webkit-keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}
@keyframes toggleOnCheckbox {
    0% {
        opacity: 0;
        transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
        transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
}

/* (1366x768) WXGA Display */
// @media  screen and (max-width: 1366px) {
//     .conTabledisplay table tbody tr td {
//       height: 169px !important;
//     }
// }
</style>
