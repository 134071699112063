<template>
    <div 
        class="container-create">  
        <div v-if="ismove"  @click="goback"
            style="font-size:14px;
                   cursor:pointer;
                   color:#0171A1">
                   <svg 
                    width="6" 
                    height="10" 
                    viewBox="0 0 6 10" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M1.52344 5L5.81147 8.97867C6.06837 9.21703 6.06126 9.61175 5.79909 9.84203C5.55496 10.0565 5.17458 10.0524 4.93577 9.83085L0.188523 5.42609C-0.062841 5.19286 -0.062841 4.80714 0.188523 4.57391L4.93577 0.169146C5.17458 -0.0524368 5.55496 -0.0564694 5.79909 0.15797C6.06126 0.388249 6.06837 0.782967 5.81147 1.02133L1.52344 5Z" fill="#0171A1"/>
                   </svg>       
                   <span style="margin-left:7px">Go Back</span>
        </div>   
        
        <v-toolbar 
            class="toolbHeader" 
            flat>
            <v-toolbar-title 
                style="overflow:visible !important">
                <span
                    class="NewContainerShipmentTitle"                   
                    > 
                    <span v-if="ismove==0">Delivery Order - {{orderdeliveryID}}</span>
                    <span v-else-if="ismove==1">New Delivery Order</span>
                    <span v-else>New Delivery Order - {{selectedshifl_ref}}</span>
                </span>
                 <span
                    style="display:block;font-size:14px;color:#4A4A4A" 
                    v-if="ismove==1">Containers moved from DO #{{orderdeliveryID}}
                </span> 
                <span 
                    style="display:block;font-size:14px;color:#4A4A4A" 
                    v-if="ismove==2">Containers moved from DO #{{orderdeliveryID}}
                </span> 
            </v-toolbar-title>
            <v-spacer></v-spacer>
                <v-btn 
                    :class="noerror == 1 ? 'btn-blue submitNow' : 'btn-white saveCreateBtn' "
                    depressed
                    @click="clicsubmit()"
                    color="primary"
                    :disabled="noerrorForm"
                    >
                    Save Changes
                </v-btn>              
                <v-btn 
                    style="margin-left:8px"
                    class="btn-white createCancelBtn"
                    depressed
                    @click="cancel"
                    color="primary">
                    Cancel
                </v-btn>
        </v-toolbar>        
         <v-progress-circular
        :size="15"
        color="#fff"
        :width="2"
        indeterminate
        v-if="startloading"
        style="margin-right:3px"
        >                       
        </v-progress-circular>  
        <v-form 
            class="sFormClass" 
            style="padding:0px !important; border-top:1px solid #EBF2F5" 
            id="sform" 
            ref="form" 
            v-model="valid" 
            @submit.prevent="submit"
            >
        <v-row 
            class="clRow">            
            <v-col 
                class="leftcol" 
                md="4"
                style="width: 59% !important;
                       min-width: 29% !important;
                       max-width: 30.4% !important;
                       padding:20px !important"
                >
            <h4>Container Information</h4>   
            <v-row>                
            <v-col  
                class="etacol pb-0 mt-2" 
                cols="6" 
                md="6"              
                >
                <small>ETA</small>               
                <v-text-field
                    v-model="date"
                    placeholder="05/21/2021"
                    append-icon="ic-calendar"   
                    :rules="validateDaterules"                 
                    name="ETA"
                    @click:append="showcustom(date,'date')" 
                     @input="blurevent" 
                    >
                </v-text-field>
            </v-col>  
             <v-col 
                cols="6" 
                md="6"
                class="pb-0 mt-2"
                >
                <small>MBL #</small>
                <v-text-field
                    placeholder="Enter MBL #"
                    required
                    v-model="mbl"
                    :rules="mblRules"
                    name="mbl_num"
                    hide-details="auto"
                    @input="blurevent"                  
                    >
                </v-text-field>
            </v-col>               
        </v-row>       
        <v-row>
             <v-col 
                class="selectBox pt-0" 
                cols="12" 
                sm="12"
                >
                <small>CUSTOMER</small>
                <v-autocomplete
                    v-model="customerdropdown"
                    :items="itemsDropdownComputed"
                    :rules="customerRules"
                    item-text= 'company_name'
                    item-value='id'
                    placeholder="Select Customer"
                    solo
                    @mousedown="dataDown('customer')"
                    @input="blurevent"
                    append-icon="ic-chevron-down"
                    return-object
                    @change="onSelectCustomer(customerdropdown)"
                    >
                </v-autocomplete>               
            </v-col>
        </v-row>

        <v-row class="mt-0" v-if="customerdropdown !== ''">
            <v-col class="selectBox pt-0" cols="12" sm="12">
                <small>CONSIGNEE</small>
                <v-autocomplete
                    ref="consigneeRef"
                    class="select-consignee"
                    v-model="selectedConsignee"
                    :items="consigneeLists"
                    item-text="name"
                    item-value="id"
                    :placeholder="getAllConsigneesLoading ? 'Fetching consignees...' : 'Select Consignee'"
                    solo
                    append-icon="ic-chevron-down"
                    :search-input.sync="searchedConsignee"
                    :menu-props="{ contentClass: 'consignee-menu-lists'}"
                    :disabled="getCreateConsigneeLoading || getAllConsigneesLoading">

                    <div slot="no-data">
                        <p class="px-4 py-3 mb-0" style="font-size: 14px;" v-if="!hasNewConsignee || consigneeLists.length === 0">
                            No available data
                        </p>
                    </div>

                    <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                            <p class="consignee-name mb-0" style="font-size: 14px; color: #4a4a4a;">{{item.name}}</p>
                        </v-list-item-content>
                    </template>

                    <div class="v-list-item v-list-item__title add-option-btn px-3 py-1" 
                        style="min-height: 35px; border-top: 1px solid #EBF2F5 !important;"
                        slot="append-item" @click="handleNewConsignee" v-if="hasNewConsignee && searchedConsignee !== ''">
                        <span style="font-size: 14px; color: #0171A1;">
                            {{ getCreateConsigneeLoading ? 'Adding...' : '+ Add New'}}
                            <span v-if="searchedConsignee" style="color: #4a4a4a;">- {{ searchedConsignee }}</span>
                        </span>
                    </div>
                </v-autocomplete>
            </v-col>
        </v-row>

        <v-row 
            style="margin-top: 0px;">
            <v-col 
                class="selectBox" 
                cols="12" 
                md="12"
                style="padding-top:3px"
                >
            <label 
                class="text-item-label">
                Contacts
            </label>
            
            <div 
                class="tags-email-wrapper mb-1">
                <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRules"                                  
                    :tags="options"
                    :add-on-blur=true
                    :class="myclass"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                    v-model="warehouseEmailAddress"
                    placeholder="Enter email address"
                    
                    @tags-changed="newTags => {
                        this.myclass = 'noclass';
                        this.options = newTags
                        this.tagsInput.touched = true
                        this.tagsInput.hasError = (this.options.length > 0) ? false : true
                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                        if (typeof el!=='udnefined') {
                            /*if (this.tagsInput.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                        }
                    }"
                    />
            </div>

            <div 
                v-if="tagsInput.touched" 
                class="v-text-field__details">
                <div 
                    class="v-messages theme--light error--text" 
                    role="alert">
                    <div 
                        class="v-messages__wrapper">
                        <div 
                            v-if="(options.length > 0) && warehouseEmailAddress!==''" 
                            class="v-messages__message">
                            {{
                                tagsInput.errorMessage
                            }}
                        </div>

                        <div 
                            v-if="options.length == 0 && warehouseEmailAddress!==''" 
                            class="v-messages__message">
                            {{
                                tagsInput.errorMessage
                            }}
                        </div>
                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                            Please provide at least 1 valid email address.
                        </div> -->
                    </div>
                </div>
            </div>
            <span 
                style="color: #819FB2; font-size: 12px;">
                <i style="font-size:12px;color:#819FB2">
                    Separate multiple email addresses with comma
                </i>   
            </span>
        </v-col>
        </v-row>
      
        <v-row 
            class="mt-0"
            >
        <v-col 
            cols="6" 
            md="6"
            class="selectBox"
            >
            <small>Carrier</small>
             <v-autocomplete
                v-model="CarrierModel"               
                :items="carrierDropdown"
                item-text='name'
                item-value='id'
                placeholder="Select Carrier"
                name="carrier_id"
                solo
                @mousedown="dataDown('CARRIER')"
                @keyup="blurevent"
                append-icon="ic-chevron-down"
                 return-object
                >
            </v-autocomplete>          
        </v-col>
        <v-col 
            cols="6" 
            md="6"
            >
            <small>Vessel</small>
            <v-text-field
                placeholder="Vessel"
                required
                v-model="VesselModel"
               
                name="vessel_name"
                hide-details="auto"
                @keyup="blurevent"
                >
            </v-text-field>
        </v-col>
        </v-row>
         <v-row 
            style="margin-top: 0px;">
            <v-col 
                class="selectBox" 
                cols="12" 
                md="12"
                style="padding-top:3px"
                >
            <label 
                class="text-item-label">
                Customer Reference #
            </label>
            
            <div 
                class="tags-email-wrapper mb-1">
                <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRulesRefference"                                  
                    :tags="optionsReferrence"
                    :add-on-blur=true
                    :class="myclass2"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="myclass.length > 0 ? tagsValidation2 : tagsValidation2"
                    v-model="customerReferrenceNumber"
                    placeholder="Enter reference #"                                     
                    @tags-changed="newTags => {
                        this.myclass2 = 'noclass';
                        this.optionsReferrence = newTags
                        this.tagsInput2.touched = true
                        this.tagsInput2.hasError = (this.optionsReferrence.length > 0) ? false : true
                        let el = this.documentProto.getElementsByClassName('ti-input')[0]                        
                    }"
                    />
            </div>

            <div 
                v-if="tagsInput2.touched" 
                class="v-text-field__details">
                <div 
                    class="v-messages theme--light error--text" 
                    role="alert">
                    <div 
                        class="v-messages__wrapper">
                        <div 
                            v-if="(optionsReferrence.length > 0) && customerReferrenceNumber!==''" 
                            class="v-messages__message">
                            {{
                                tagsInput2.errorMessage
                            }}
                        </div>

                        <div 
                            v-if="optionsReferrence.length == 0 && customerReferrenceNumber!==''" 
                            class="v-messages__message">
                            {{
                                tagsInput2.errorMessage
                            }}
                        </div>
                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                            Please provide at least 1 valid email address.
                        </div> -->
                    </div>
                </div>
            </div>
            <span 
                style="color: #819FB2; font-size: 12px;">
                <i style="font-size:12px;color:#819FB2">
                    Separate multiple referrence # with comma
                </i>   
            </span>
        </v-col>
        </v-row>       
        <v-row 
            class="rowRemovemargin">
            <v-col 
                class="selectBox" 
                cols="12" 
                md="12"
                >
                <small>Container Starting Location</small>
                <!-- <v-autocomplete
                    v-model="terminalAt"
                    :rules="TerminalAtRules"
                    :items="terminalsDropdown"
                    item-text= 'name'
                    item-value='id'
                    placeholder="Select Terminal"                   
                    @mousedown="dataDown('TERMINAL AT')"
                    @keyup="blurevent"
                    append-icon="ic-chevron-down"
                    return-object
                    :filter="onFilter"
                    class="startingDOSclass"
                    > -->
                    <v-autocomplete
                        v-model="terminalAt"
                        :rules="TerminalAtRules"
                        :items="terminalsDropdown"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Terminal"                
                        @mousedown="dataDown('TERMINAL AT')"
                        @keyup="blurevent"
                        append-icon="ic-chevron-down"
                        return-object
                        :filter="onFilter"
                        attach
                        class="startingDOSclass"
                    >
                    <template slot="item" slot-scope="{ item }">
                        <template v-if="typeof item == 'object'">                            
                            <div class="listDropdown" style="font-size:12px">
                            {{ item.firms_code }} <span style="font-size:12px" class="strtinglocationh"></span> {{ item.name }}
                            </div>
                        </template>
                    </template>
                </v-autocomplete>               
            </v-col>           
        </v-row>       
         <v-row 
            class="rowRemovemargin">
            <v-col 
                class="selectBox pt-0" 
                cols="12" 
                md="12"
                >
                <small>Container Commodity</small>
                <v-text-field
                    placeholder="Enter Container Commodity"
                    required
                    v-model="container_commodity"                   
                    name="mbl_num"
                    hide-details="auto"
                    @keyup="blurevent"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row 
            class="notesrow"
            style="margin-top:14px"
            >
            <v-col 
                class="textareanotes" 
                cols="12" 
                md="12"
                >
                <small>Notes</small>
                <v-textarea
                    v-model="notembl"
                    :rules="noterules"
                    placeholder="Type Note"
                    rows="2"
                    name="custom_notes"
                     @input="blurevent" 
                    >
                </v-textarea>
            </v-col>      
        </v-row>
        <v-row  
            class="notesrow mt-0">            
        </v-row>
        </v-col>
            <v-col 
               
               
                class="uploadDocuwrapper colright rightcol"
                >
                <div
                    class="deliveryOrderColumn"
                    >
                    <div 
                        style="padding-bottom:7px; color:#4A4A4A;font-size:16px; font-weight:600"
                        >
                        Document 
                        <!-- <span 
                            style="float:right;font-size:14px !important;color:#0171A1;cursor:pointer"                         
                            v-if="uploadTrigger"
                            @click="uploadAgain"
                            class="reupload"
                            > 
                            
                        <img class="uploadCreateNew" src="../../../assets/icons/uploadnew.svg">
                        Reupload 
                        </span>   -->
                    </div> 
                                    
                <span 
                    style="color:red;font-size:12px;margin-left:5px" 
                    v-if="errorFile">
                    Invalid File Type
                </span>   
                <span 
                    style="display:none;" 
                    class="fileuploaderror"
                    >
                </span>                         
                <div 
                    style="display:block" 
                    class="uploadElement flex w-full h-screen items-left justify-left text-left" 
                    id="app"
                >
                    
                    <div 
                        style="width:100%;background:#fff !important;"  
                        :class="hasContent"  
                        xclass="cDownloadfiles" 
                        @dragover="dragover" 
                        @dragleave="dragleave" 
                        @drop="drop"
                       
                        >
                        <input 
                            style="display:none" 
                            type="file" 
                            name="replaces[]" 
                            id="replaceFieldHandle" 
                            class="w-px h-px opacity-0 overflow-hidden absolute" 
                            @change="onReplace" 
                            ref="replaceFile" 
                            accept="application/pdf" 
                        >
                        <input 
                            style="display:none" 
                            type="file" 
                            name="fields[]" 
                            id="assetsFieldHandle" 
                            class="w-px h-px opacity-0 overflow-hidden absolute" 
                            @change="onChange" 
                            ref="file" 
                            accept="application/pdf"
                            :disabled="isLoading"
                        >

                            <label 
                                for="assetsFieldHandle" 
                                class="block cursor-pointer triggerelement"
                                >
                                <div 
                                    class="browserInput2 dragfileElement">
                                    <div 
                                        class="browseFileDelivery"
                                        >
                                        Browse Or Drop File
                                    </div>  
                                    <div class="btn-white2 browseButton2">
                                        <div class="wrappIconbutton2">
                                            <span class="iconUploadfile"><i class="ic-upload"></i></span>
                                            <span class="classUpload">Upload</span>
                                        </div>   
                                    </div>                                   
                                </div>
                            </label>

                    </div>
                    <div 
                        class="flex w-full h-screen items-left justify-left text-left" 
                        style="width:100%"
                        >

                        <!-- Show if still uploading the file -->
                        <ul 
                            style="width:100%"
                            class="mt-4 displayItem" 
                            v-if="this.filelist.length" 
                            v-cloak
                            >
                            <li 
                                class="text-sm p-1 mb-3" 
                                v-for="(file,index) in filelist "   
                                :key="index"
                                >
                                <div 
                                    style="display:flex; align-items: center;" 
                                    class="rowItemUploaded"
                                    >
                                    <span 
                                        class="IconUpload" 
                                        style="background:#fff;border-radius:30px"
                                        > 
                                    <img 
                                        class="imgIcon" src="../../../assets/icons/documentFile.svg" 
                                        >
                                    </span>
                                    <div 
                                        style="display:flex; align-items: center;" 
                                        class="mainwrapitem"
                                        > 
                                        <div class="fileNameList" style="top: 0px !important"> {{file.name}}</div> 
                                        <!-- <div 
                                            class="fileSize">[ {{ (file.size / 1024).toFixed(2) }} kb <span>.</span> pdf ] 
                                            <button 
                                                class="ml-2" 
                                                type="button" 
                                                @click="remove(filelist.indexOf(file) , objectServerid)" 
                                                title="Remove file"
                                                >
                                                <i 
                                                    style="color:#212121" 
                                                    class="ic-delete"
                                                    >
                                                </i>
                                            </button>
                                        </div> -->
                                        <div style="margin-top:5px">
                                            <v-progress-circular
                                                :size="20"
                                                color="#0775A3"
                                                indeterminate
                                                v-if="!uploadCompleted"
                                                >
                                            </v-progress-circular>
                                        </div>
                                        <div 
                                            class="fileSize flex justify-end"
                                            v-if="uploadCompleted"
                                        > 
                                            <button
                                                class="replace-btn"
                                                @click="handleReplace"
                                            >
                                                Replace
                                            </button>
                                            <button 
                                                class="ml-2 document-btn"
                                                @click="clickDownload">
                                                <img style="cursor:pointer" src="../../../assets/icons/view-blue.svg">
                                            </button>
                                            <button 
                                                class="ml-2 document-btn" 
                                                type="button" 
                                                @click="remove(filelist.indexOf(file) , objectServerid)" 
                                                title="Remove file"
                                                >
                                                <i 
                                                    style="color:red" 
                                                    class="ic-delete"
                                                    >
                                                </i>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </li>
                            <small style="color: #F93131 !important" v-if="moreFilesError">Only 1 file can be uploaded</small>
                        </ul> 
                        <!-- Show if has uploaded document during the creation of DO -->
                        <ul 
                            style="width:100%"
                            class="mt-4 displayItem" 
                            v-if="this.documents.length > 0" 
                            v-cloak
                            >
                            <li 
                                class="text-sm p-1 mb-3" 
                                v-for="(file,index) in documents "   
                                :key="index"
                                >
                                <div 
                                    style="display:flex; align-items: center;" 
                                    class="rowItemUploaded"
                                    >
                                    <span 
                                        class="IconUpload" 
                                        style="background:#fff;border-radius:30px"
                                        > 
                                    <img 
                                        class="imgIcon" src="../../../assets/icons/documentFile.svg" 
                                        >
                                    </span>
                                    <div 
                                        style="display:flex; align-items: center;" 
                                        class="mainwrapitem"
                                        > 
                                        <div class="fileNameList" style="top: 0px !important"> {{file.filename}}</div>
                                        <div style="margin-top:5px">
                                            <v-progress-circular
                                                :size="20"
                                                color="#0775A3"
                                                indeterminate
                                                v-if="!uploadCompleted"
                                                >
                                            </v-progress-circular>
                                        </div>
                                        <div 
                                            class="fileSize flex"
                                            v-if="uploadCompleted"
                                        > 
                                            <button
                                                class="replace-btn"
                                                @click.prevent="handleReplace(file.id)"
                                            >
                                                Replace
                                            </button>
                                            <button 
                                                class="ml-2 document-btn"
                                                @click.prevent="downloadDocument(file.id)">
                                                <img style="cursor:pointer" src="../../../assets/icons/view-blue.svg">
                                            </button>
                                            <button 
                                                class="ml-2 document-btn" 
                                                type="button" 
                                                @click.prevent="remove(index, file)" 
                                                title="Remove file"
                                                >
                                                <i 
                                                    style="color:red" 
                                                    class="ic-delete"
                                                    >
                                                </i>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </li>
                        </ul>    
                    </div>
                </div>
                </div>
                <div 
                    class="milestone-create-container333" 
                    style="padding-left: 7px;
                           position: relative;
                           width: 100%;
                           padding-top:0px;"
                    >                   
                    <div 
                        class="mainWrapperListContainers">                   
                        <div 
                            v-if="containerList.length > 0"> 

                            <ListRendering   
                                v-if="refresh"   
                                :ContainerSize="ContainerSize"
                                @saveUpdated="saveUpdated"                               
                                :containerList="containerList"
                                :disableEditContainers ="disableEditContainers"
                                @tellthelengthobject="checklengthdata"
                                @showEditnow="emitedit"
                                :noerrorForm="noerrorForm"
                                @movecontainer="createdo"
                                :iscreate="iscreate"
                                :ismovetoexisting="movetoexisting"
                                :istriggerededit="istriggerededit"
                                :updated.sync="updated"
                                :haschanges.sync=" haschangesform"
                                />

                        </div>
                        <div>
                            <p 
                                v-if="containerList.length==0" style="text-align:center;
                                      font-size: 16px;
                                      padding-top:13px;
                                      color: #6D858F;">{{containerEmptytext}}
                            </p>
                           <div 
                                class="addContainer" 
                                style="text-align:left;
                               margin-top:0px;
                                       padding-left:4px !important;
                                       border:0px !important;"
                            >
                            <span 
                                style="color:#0171A1;
                                cursor:pointer;" 
                                @click="addContainer">
                                + Add Container
                            </span>
                            </div> 
                        </div>
                        <div  
                            v-if="containerList.length > 0 " 
                            class="addContainer"
                            style="margin-top:0px !important;
                                   padding-bottom:0px !important;">
                            <span 
                                style="color:#0171A1;
                                       cursor:pointer;
                                       position: relative;
                                       top: -7px;
                                       display:none;" 
                                @click="addContainer">+ Add Container</span>
                        </div>                              
                    </div>                               
                </div>
            </v-col>
        </v-row>       
        </v-form>
         
        <template>
            <div class="text-center ma-2">               
                <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                >
                <div v-if="snackbarcontent">
                   Selected containers has been moved to another DO
                   <span 
                      style="cursor:pointer;" 
                      @click="viewdo">
                      View DO
                      <img 
                        src="../../../assets/icons/arrow-right-delivery.svg">
                    </span>
                </div> 
                <div v-else>
                    Delivery Order has been successfully saved.
                </div>   
                </v-snackbar>
            </div>
        </template>
        <ContainerDialog    
            @saveContainer="saveContainer"        
            :dialogData.sync="dialog" 
            :ContainerSize="ContainerSize"
            :lastIndexData="lastIndexData"
        />

         <EditContainers    
            @updateContainer="updateContainer"        
            :dialogData.sync="showedit" 
            :ContainerSize="ContainerSize"
            :editdata="keysvalue"                              
        />

        <CreatePicker                       
            :dialogDatepicker="dialogDatepicker" 
            :defaultData ="pickerDefaultdata"   
            @passbackdata="getdata"
            @close="closeDialogpicker"                       
        />

        <!-- <Confirm    
             @cancelDialog="cancelDialog"  
             @confirmDialog="continueDialog"          
            :dialogData.sync="dialogConfirm" 
            :isSaving.sync="isSavingConfirm"
            :location="location"            
        /> -->

        <Movedialognotallowed
            :dialogData.sync="notallowed" 
            :movetype="movetype"
            @close="closenotallowed"      
        />
        <DialogCancel
            :dialogData.sync="showcancel"          
            @close="closecancel"  
            @confirmCancel="confirmCancel"    
        />
        <ShowNotificationMoveDO
            :dialogData.sync="shownotification" 
            @hideNotification="usernotification"    
        />

        <SnackbarContainersave   
            :dialogData.sync="showcontaineradded"          
        /> 

        <Showdialogupdatebox
            :dialogData.sync="showconfirmafterupdate"          
        /> 

        <Showdialogupdatebox 
             @confirmDialog="continueDialogUpdateBox"          
            :dialogData.sync="dialogConfirm" 
            :isSaving.sync="isSavingConfirm" 
            :deliveryID="DO_ID"
            :containersnumber="containers_number"
            :countshipment="countshipment"
            :shipmentIDS="shipmentIDS"        
            :confirmmessage="confirm_message" 
            :location="location"   
        />
    </div>
</template>
<script>
import axios from "axios";
import {mapGetters,mapActions } from 'vuex'
const poBaseUrl = process.env.VUE_APP_PO_URL
import globalMethods from '../../../utils/globalMethods'
import VueTagsInput from '@johmun/vue-tags-input';
import ContainerDialog from './dialog/DialogContainer.vue'
import EditContainers from './dialog/EditDialogContainer.vue'
import ListRendering from './listRender.vue'
import CreatePicker from "../../Createpicker.vue";
import Movedialognotallowed from "./dialog/NotallowedDialog.vue";
import DialogCancel from "./dialog/CancelDialog.vue";
import ShowNotificationMoveDO from "./dialog/ShowNotificationMoveDO.vue";
import SnackbarContainersave from "./snackbar/containersave.vue";
import Showdialogupdatebox from "./dialog/Showdialogupdatebox.vue";
import moment from "moment";
//import Confirm from './confirm.vue'
import _ from "lodash";
import jQuery from 'jquery'
export default {
    name: "edit_containers",
    props: ['items','customers','terminals','ContainerSize','carriers'],
    components: {
        VueTagsInput,
        ContainerDialog,
        ListRendering,
        //Confirm,
        EditContainers,
        CreatePicker,
        Movedialognotallowed,
        DialogCancel,
        ShowNotificationMoveDO,
        SnackbarContainersave,
        Showdialogupdatebox
    },
    data(){
        return {
        confirm_message:[],
        countshipment:0,    
        containers_number:[],    
        shipmentIDS:[],      
        DO_ID:'',    
        showconfirmafterupdate:0,
        showcontaineradded:0,    
        snackbarcontent: false,    
        timeout:2000,    
        thelinkreturn: 0,    
        thdid:'',
        sid:'',
        snackbar:false,    
        triggerloc:'',    
        istriggerdfrommodal:0,
        shownotification:0,    
        showcancel:0,    
        getlistasstring:[],   
        haschangesform:0,
        doInitialvalue:null,
        dofinalvalue:null,
        initcontainerListdata:null,
        finalcontainerListdata:null,
        getunchecked: [],
        defaultlistdata:[],
        movetype:0,    
        notallowed:0, // if use added container, must be saved first
        updated:0,
        location:'edit',  
        movetoexisting:0,
        istriggerededit:0,
        existingdeliveryobject:[],
        selectedExistingDeliveryID:0,
        selectedshifl_ref: 0,
        iscreate:0,    
        ismove:0,
        allshipmentedits:[],
        pickerDefaultdata:'',    
        dialogDatepicker:false,     
        keytovalue: 0,
        keysvalue: [],
        editdata: [],
        showedit: 0,       
        disableEditContainers: 1, // chana advice disable containers edit for now    
        orderdeliveryID:'',
        refresh:0,
        callna: '',
        solidestyle:0,
        errorstate: 0,
        notpermited:false,
        calleditableELe: false,
        modaletd:false,
        container_commodity:'',
        customerdropdown: '',
        allDeliveryData: [],
        currentindexshow:99999,
        containerEmptytext: 'Container is empty. Add container before you save changes',
        roottablecontainer:'blueteam',
        containerList2:[],
        containerList:[],
         HBL_NUMBER: '',
        uploadCompleted: true,
        uploadTrigger:false,
        noerrorForm:true,
        contactsModel:'',
        CarrierModel:'',
        VesselModel:'',
        responseDatatempt:'',
        serverObject:[],
        objectServerid:'',
        hasContent:'hasContentFiles2',
        filelist:[],
        errorFile: false,
        comodity: '',
        resetform : false,
        shipmentmsg: 'The shipment was created!',
        Truckers:false,
        noerror: false,
        testsubmit:'',
        formFieldlength:0,
        startloading: 0,
        clickOrigin:'',
        clicksubmit: false,
        submitisclick:false,
        selectedmilestone:[],
        customer: [],
        mbl: '',
        containerNumber : '',
        terminalAt :'',
        CarrierRules:[
            v => !!v || 'Customer is required',        
        ],
        VesselRules:[
            v => !!v || 'Customer is required',    
        ],
        customerRules: [
            v => !!v || 'Customer is required',
        ],
        mblRules: [
            v => !!v || 'Mbl# is required',
        ],
        fieldrequired: [
            v => !!v || 'This field is required',
        ],
        containerNumberRules: [
            v => !!v || 'Container # is required',      
        ],
        notembl: '',
        noterules:[
            (v) => {                
                if (v == null){
                    return true;
                }else if(v !='') {
                    if(v.length > 200)
                    return  'Should not exceed the 200 allowed characters'
                }  
                return true;           
            }
        ],
        TerminalAtRules: [
            v => !!v || 'Terminal At is required',
        ],
        containerSizeModel:[],
        containersize: '',
        containersizeRules: [
            v => !!v || 'Container size is required',
        ],
        deliverTo: '',
        delivertoRules:[
            v => !!v || 'Deliver to is required', 
        ],
        weight: '',
        weightRules:[
            v => !!v || 'Weight is required',
        ],
        customerpoNumber:'',
        customerpoNumberRules:[
            v => !!v || 'Customer PO# is required',           
        ],
        containerTypeField:'',
        containerTypeRules:[
            v => !!v || 'Container Type is required',
        ],
        truckersField:'',
        truckersRules:[
            v => !!v || 'Truckers is required',
        ],
        hiddenmilestone:'',
        hiddenmilestoneRules:[
            v => !!v || 'Please select milestone',
        ],
        select: '',
        customerSelected:'',
        containertypeSelected:'',
        terminalatSelected:'',
        containersizeSelected:'',
        currentDropdownState:'',
        trucker_idSelected: '',
        valid:true,
        currentComboState:'',
        tabscurrent:0,
        tab: null,
        tabsNavigation: [
          'Header Info',
        ],
        date_etd:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        checkboxColor: '#0171A1',
        container_type: '',
        select_terminal_at:'',
        itemsDropdown: [],
        dialog: false,
        dialogConfirm: false,
        isSavingConfirm: false,
		search: "",
        searchData: '',
        selectedTasks: [],
        containerType: [],
        containerSize: [],
        TERMINAL_AT: [],
        ex4: [],
        truckers: [],
        newdata:[],
        myclass: '',
        myclass2: '',
        options: [],
        optionsReferrence: [],
        documents: [],
        document: null,
        documentProto: document,
        warehouseEmailAddress:'',
        customerReferrenceNumber: '',
        moreFilesError: false,
        submit: false,
        isLoading: false,
         tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
          tagsInput2: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.'
        },
        tagsValidation: [
        {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
        }
        ],
         tagsValidation2: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+$/),
            disableAdd: true
            }
        ],
        arrayNotEmptyRules: [           
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        arrayNotEmptyRulesRefference: [           
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        tagsValidation3: [
            {
            classes: 'ttt-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        validateDaterules: [
          (v) => 
            {
              if(v==='') return "This field is required"
              if(!this.validateDate(v)){
                return "Invalid Date ex: YYYY-mm-dd"
              } return true
            }          
         ],
         lastIndexData: null,
        consigneeLists: [],
        selectedConsignee: null,
        newConsignee: "",
        hasNewConsignee: 0,
    }},
    watch: {
        snackbar(c){
            if(!c){
               location.reload(); 
            }
        },
        updated(c){
            if(c){                
                this.notallowed = 1;
                this.updated = 1;
                if(c==2){
                    this.notallowed = 1;
                    this.updated = 1;
                }else if(c==3){
                    this.movetype = 3
                }else if(c==4){                   
                    this.movetype = 4 
                }else  if(c==5){                    
                    this.movetype = 5 
                }
            }
        },
        optionsReferrence:{
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
         options: {
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
        solidestyle(c){
            alert(c)
        },
        customers(c){
            if(c == ''){
                console.log('customer is null')
            }
        },     
        items: function(newVal){
          this.getPrelims(newVal)
        },
        filelist(c){
            if(c.length === 0) {
                if(!this.documents.length > 0) {
                    this.hasContent = 'hasContentFiles2'
                }
            }
            else { 
                this.callUPdocument(c)
            }
        },
        ismove(){
            //console.log(this.containerList)
           // var getobject = _.filter(this.containerList,function(o){return o.checked;});
            //console.log(getobject);
            //this.containerList = getobject
            this.istriggerededit = 1;
        },
        // filelist(c){             
        //    if(c.length > 0){              
        //        this.callUPdocument(c);
        //    }         
        // }
        hasContent(nv) {
            console.log(nv)
        }
	},
    computed: {
        ...mapGetters({  
            getCreateloading: 'containers/getPoLoading',
            formdetails: 'containers/getcontainerFormDetails',
            getPoLoading: 'containers/getPoLoading',
            getAllConsignees: 'containers/getAllConsignees',
            getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
            getCreateConsigneeLoading: 'containers/getCreateConsigneeLoading'
        }),  
        itemsDropdownSize:{
            get () {
                return this.ContainerSize
            },
        },       
        itemsDropdownComputed:{
            get(){
                return this.customers
            }
        },
        terminalsDropdown:{
            get(){
                return this.terminals
            }
        },
        carrierDropdown:{
            get(){                
                return this.carriers
            }
        },
        sizeDropdown:{
            get(){
                return this.ContainerSize
            }
        },
        imgPath () {
        // webpack will replace the given path to import() with the actual "production" image path
            return import('../../../assets/icons/arrow-right-delivery.svg')
        },
        searchedConsignee: {
			get() {
				return this.newConsignee !== null && this.newConsignee !== '' ? this.newConsignee.toString() : this.newConsignee;
			},
			set(input) {
				let temp = [];
				this.consigneeLists.map((list) => {
					temp.push(list.name);
				});
				if(temp.includes(input)) {
					this.hasNewConsignee = 0;
				}
				else if(this.newConsignee === "" || this.newConsignee === null) {
					this.hasNewConsignee = 0;
				}
				else {
					this.hasNewConsignee = 1;
				}
				this.newConsignee = input;
			},
		},     
    },

    methods: {  
        onFilter(item, queryText){            
            if(item.nickname){
                 return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.nickname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) 
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            }
        return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())

        },
        viewdo(){
             window.location.href = '/delivery-order/'+ this.thelinkreturn + '/' + this.sid;
        },
        usernotification(){
            this.istriggerdfrommodal = 1;
            this.shownotification = 0;
        },   
        classCheck(cate){           
            if(cate.error == 1){                
                return 'triggerclasserror';
            }            
            return '';
        },
        isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); },       
        calleditable(){
            return this.calleditableELe;
        },
        edit(){           
           return this.calleditableELe = true;
        },
        clickDownload(){
            this.gotoDownload();
        },
        uploadAgain(){
            jQuery(document).find('.triggerelement').trigger('click');
        },
        timetopass(){
            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                    return o.text
                }) : [] 
                return finalEmailAddress;
        },
         timetopassreference(){
            // let finalEmailAddress = (this.optionsReferrence.length > 0) ? this.optionsReferrence.map(o => {
            //         return o.text
            //     }) : [] 
            //     return finalEmailAddress;
            let finalrefrenceNumber = this.optionsReferrence.length > 0 ?  this.optionsReferrence.map(({text}) => ({customer_ref_number:text})) :[]
            return finalrefrenceNumber;
        },
        ...mapActions({
			fetchFormdetails: 'containers/fetchallformdetails',
            fetchConsignees: 'containers/fetchConsignees',
            createConsignee: 'containers/createConsignee'
        }),
        ...globalMethods,
        blurevent(){  
           this.haschages()  
           this.checkobjects()         
        },
        currentshow(index){
            if(index ==  this.currentindexshow)
            return 1;
        },
        checkeverything(){
            let getcustomerref  = JSON.stringify(this.timetopassreference());
            let contactlist     = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist; 
            let changes;

            if(this.doInitialvalue !=this.dofinalvalue){
                changes = 1
            }else{
                changes = 0
            }

            if(this.triggerloc=='cancel'){               
                return changes;              
            }else{
                this.haschangesform = changes
            }
            
            this.containerlistchanges();
        },
        // magamit if click sa save sa taas
        checkobjects(){
            
            if(this.mbl.length > 0 
                && (this.customerdropdown) !='' 
                && this.CarrierModel !=''
                && this.VesselModel !='' 
                && this.terminalAt !=''
                //&& this.options.length > 0 
                && this.optionsReferrence.length > 0)
            {
                this.noerror = 1
                this.noerrorForm = false;
            }else{
                this.noerror = 0
                this.noerrorForm = true;
            }
        },     
        async getOrderbyId(){
            var that = this;
            var validEmailAddress = [],ref = [];  
            this.isLoading = true         
            await axios
            .get(`${poBaseUrl}/getdeliveryorderbyid/` + this.$route.params.id
            )
            .then(function (response) {         
                if (response.data) {
                    that.date = moment(response.data.data.eta).utc(false).format("YYYY-MM-DD"); 
                    that.mbl = response.data.data.mbl_number; 
                    that.customerdropdown =  parseInt(response.data.data.customer);   
                  
                    var s = JSON.parse(response.data.data.contacts);   
                    s.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    }) 

                    that.options = validEmailAddress                     
                    that.CarrierModel = response.data.data.carrier_id;
                   
                    that.orderdeliveryID =  response.data.data.shifl_ref 
                    that.VesselModel =  response.data.data.vessel 
                    that.selectedConsignee = response.data.data.consignee_id

                    var refer = response.data.data.customer_ref_number 

                    if( refer.length > 0 ){  
                        (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {                               
                                    ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                        }) 
                    }

                    that.optionsReferrence = ref 
                    that.terminalAt = response.data.data.terminal_id

                    if(response.data.data.container_commodity != null) {
                        that.container_commodity = response.data.data.container_commodity;
                    }
                    
                    if(response.data.data.description != null) {
                        that.notembl = response.data.data.description;
                    }                    

                    /// GET THE CONTAINER FROM DELIVERY_ORDER_SHIPMENT 
                    var getcontainersfromdelivery = response.data.delivery_containers;                    
                    var getAllshipment = (response.data.shipment); 

                    if(getAllshipment.length){
                        _.forEach(getAllshipment,function(value){
                            that.allshipmentedits.push(value);
                        });
                    }
                   // var test;
                    getcontainersfromdelivery.map(wea => {                  
                        if (wea!==null) {
                            that.pushobject(wea);
                        }
                    }) 

                    if(response.data.data.documents.length > 0) {
                        that.hasContent = 'cDownloadfiles hidethisdiv'
                        that.documents = response.data.data.documents
                    }
                    
                    //that.containerList = containers;
                    that.refresh = 0;
                    that.isLoading = false
                  
                    that.$nextTick(() => {
                        that.refresh = 1;                                              
                    }); 
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getdata(item){
            if(this.currentstatepicker == 'date'){
                this.date = item;
            }           
        },
        haschages(){ 
            let getcustomerref = JSON.stringify(this.timetopassreference());
            let contactlist = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist; 
            
            if(this.doInitialvalue !=this.dofinalvalue){              
               this.haschangesform = 1
            }else{               
               this.haschangesform = 0
            } 
           
        },
       async createdo(item){
        var that = this;    
         
            if(item.state=='createdo'){
                this.cleancontainerlist(that.containerList);
                this.ismove = 1; // copy ug magbuhat ug bag-ong delivery 
            }else if(item.state=='moveDo'){
                this.movetoexisting = 1;
                this.selectedExistingDeliveryID = item.selected; // Do id
                this.selectedshifl_ref = item.shifl_ref; // Do id             
                // Fetch the containers of the selected DO
                // SelectedExistingDeliveryID
                // kuhaon para e merge ang duha selected ug moving do containers

                                  
                   // lihug dili e delete kay magamit ni puhon
                    //gamit e merge ang selected DO with the current DO to be moved
                    await axios.get(`${poBaseUrl}/gethipmentbydeliveryorderid/`+item.selected)
                    .then(function (response) {    
                        if (response.data) {        
                            var getobject = _.filter(that.containerList,function(o){return o.checked;});
                            that.containerList = getobject     
                            that.existingdeliveryobject = response.data.data 
                            response.data.data.map(wea => {                  
                                if (wea!==null) {
                                    // push to get the existing containers
                                that.pushobject(wea);
                                }
                            }) 
                            }
                    }).catch(error => {
                        console.log(error)
                    })
                

                var validEmailAddress = [],ref = [];  
                this.isLoading = true         
                await axios
                .get(`${poBaseUrl}/getdeliveryorderbyid/` + item.selected
                )
                .then(function (response) {         
                    if (response.data) {
                        that.date = moment(response.data.data.eta).utc(false).format("YYYY-MM-DD");
                        that.mbl = response.data.data.mbl_number; 
                        that.customerdropdown =  parseInt(response.data.data.customer);   
                    
                        var s = JSON.parse(response.data.data.contacts);   
                        s.map(wea => {
                            if (wea!==null) {
                                validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                            }
                        }) 

                        that.options = validEmailAddress                     
                        that.CarrierModel = response.data.data.carrier_id;
                    
                        //that.orderdeliveryID =  response.data.data.shifl_ref 
                        that.VesselModel =  response.data.data.vessel 

                        var refer = response.data.data.customer_ref_number 

                        if( refer.length > 0 ){  
                            (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {                               
                                    ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                            }) 
                        }

                        that.optionsReferrence = ref 
                        that.terminalAt = response.data.data.terminal_id

                        if(response.data.data.container_commodity != null) {
                            that.container_commodity = response.data.data.container_commodity;
                        }
                        
                        if(response.data.data.description != null) {
                            that.notembl = response.data.data.description;
                        }                    

                        //     /// GET THE CONTAINER FROM DELIVERY_ORDER_SHIPMENT 
                        //     var getcontainersfromdelivery = response.data.delivery_containers;                    
                        //     var getAllshipment = (response.data.shipment); 

                        //     if(getAllshipment.length){
                        //         _.forEach(getAllshipment,function(value){
                        //             that.allshipmentedits.push(value);
                        //         });
                        //     }
                        // // var test;
                        //     getcontainersfromdelivery.map(wea => {                  
                        //         if (wea!==null) {
                        //             that.pushobject(wea);
                        //         }
                        //     }) 

                        if(response.data.data.documents.length > 0) {
                            that.hasContent = 'cDownloadfiles hidethisdiv'
                            that.documents = response.data.data.documents
                        }else {
                            that.hasContent = 'cDownloadfiles'
                            that.documents = [];
                        }
                        
                        //that.containerList = containers;
                        that.refresh = 0;
                        that.isLoading = false
                    
                        that.$nextTick(() => {
                            that.refresh = 1;                                              
                        }); 
                    }
                }).catch(error => {
                    console.log(error)
                })

                //this.cleancontainerlist(that.containerList);
                this.ismove = 2;
            }          
        },
        closeDialogpicker(){
            this.dialogDatepicker = false;
        },
        deleteClear(i){
            this.containerList.splice(i, 1);
            this.checkobjects()   
        }, 
        cleancontainerlist(clList){           
            this.containerList = _.filter(clList,function(o){return o.checked;});
        },
        pushobject(wea){
             var that = this;
             that.containerList.push({
                container_id : wea.shipment_id,
                container_number: wea.container_number,
                container_size:wea.size,
                container_volume: wea.volume,
                container_weight: wea.weight,
                container_qty: wea.qty,
                delivery_to :  wea.address, // response.data.data.deliver_to ,
                checked:0,
                error_0:0
                //error_1:0,error_2:0,error_3:0,error_4:0
            })
            console.log(this.containerList)
            that.refresh = 0;                  
            that.$nextTick(() => {
                that.refresh = 1;                                              
            });
        },
        saveForm(){
            //var redirectid = 0;
            this.startloading = 1;  
            this.sid = this.$route.params.sid;
            this.thdid = this.$route.params.id;
            
            let formData = new FormData(document.getElementById('sform'))
            formData.append('xmbl',this.mbl); 

            if(typeof this.CarrierModel !=='object'){                      
                var carriene = this.carriers.find((d) => d.id == this.CarrierModel);
                this.CarrierModel = carriene;
            }

            // if(typeof this.terminalAt !=='object'){                      
            //     var termi = this.terminals.find((d) => d.name == this.terminalAt);
            //     this.terminalAt = termi;
            // }
                                
            formData.append('xcustomer',(typeof this.customerdropdown=='object') ? this.customerdropdown.id : this.customerdropdown);  
            formData.append('xcontacts[]',this.timetopass());
            formData.append('xcarrier', (typeof this.CarrierModel=='object') ? this.CarrierModel.id : this.CarrierModel);    
            formData.append('xcarrier_name',(this.CarrierModel.name)); 
            formData.append('xvessel',this.VesselModel);  

            formData.append('do_move_mode',this.ismove);
            if(this.ismove==2){
                //redirectid = this.selectedExistingDeliveryID
                formData.append('existing_do_id',this.selectedExistingDeliveryID); // selected delivery order
                formData.append('xedit_id_of_moved_do',this.$route.params.id); // id of the moved do
            } else {
                //redirectid = this.$route.params.id
            }  
            // do_move_mode
            // 1 is move or copy
            // 2 is move to existing 
            // 0 is default      
            var getun = _.filter(this.containerList,function(o){return!o.checked;});          
            formData.append('xexclude_container', JSON.stringify(getun)); 
            formData.append('xreferrence[]', JSON.stringify(this.timetopassreference()));
            formData.append('xlocation_at', (typeof this.terminalAt=='object') ? this.terminalAt.id : this.terminalAt); // Change into ID
            // formData.append('xlocation_at_name',this.terminalAt.name); 
            formData.append('xnote_mbl',this.notembl);     
            formData.append('xcontainerList', JSON.stringify(this.containerList));     
            formData.append('container_commodity',(this.container_commodity));
            formData.append('xedit',this.$route.params.id);
            formData.append("consignee_id", this.selectedConsignee);
            axios.post(`${poBaseUrl}/orderdelivery-update`,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {                                           
                this.startloading = 0;
                this.showcontaineradded = 0;                        
                if(response){
                    this.DO_ID            = response.DO_ID;
                    this.shipmentIDS      = response.shipment_id
                    this.containers_number= response.containers_number
                    this.countshipment    = response.count_shipment
                    this.confirm_message  = response.confirm_message

                    this.dialogConfirm = true;
                    this.thelinkreturn = response.DO_ID;  
                    this.snackbarcontent = true;                                                                 
                    if(this.ismove==1){  
                       // this.snackbar = true;                      
                     }else if(this.ismove==2){
                        //this.snackbar = true;
                        this.isSavingConfirm = true; 
                    }else{
                        //this.snackbar = true;
                        this.snackbarcontent = false;
                        //setTimeout(function () {                            
                            //window.location.href = '/delivery-order/'+ this.thdid + '/' + this.sid;
                        //}.bind(this), 2000)                      
                    }
                }
            })
        },
        clicsubmit(){     
            if( this.$refs.form.validate() ){               
                //this.dialogConfirm = true;  
                this.saveForm();              
            }
        },
        goback(){
            location.reload(true);
        },
        cancelDialog(item){           
            if(item.isCancel)
            this.dialogConfirm = false;
        },
        continueDialog(item){  
            if(item.isConfirmed){
               // this.dialogConfirm = false;
               this.dialogConfirm = false;
               //location.reload();
               /*if(item.isConfirmed){
                    this.saveForm();                    
                }*/
            }
        },
        continueDialogUpdateBox(){
            this.$router.push('/containers') 
        },
        saveUpdated(item){            
            if(item.updated)  {        
               this.containerList = item.data;                           
            } 
            
            if(item.hasError){
                this.noerror = 0
                this.noerrorForm = true;
            } else {
                this.noerror = 1
                this.noerrorForm = false;
            }
        },
        validateDate(dateStr){ 
            let str = dateStr.split('T');
            dateStr = str[0];
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            if (dateStr.match(regex) === null) {
            return false;
            }
            const date = new Date(dateStr);
            const timestamp = date.getTime();
            if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
            }
            return date.toISOString().startsWith(dateStr);     
        },
        showcustom(state,m){
            this.pickerDefaultdata = state;
            this.currentstatepicker = m;
            this.dialogDatepicker = true;
        },
        checklengthdata(item){
            console.log(item)
            if(item.value===0)
            {
                 this.containerList = []; 
            }
            this.checkobjects()  
        },
        emitedit(data){
            this.showedit = data.show;            
            this.keysvalue = data.data[data.index] 
            this.keytovalue = data.index
        },
         updateContainer(item){
            console.log(item)
            //this.containerList[this.keytovalue] =  item            
            var sizesize  = (item.container_size)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            if(typeof getsize !='undefined') {          
                item.container_size = String(getsize.id)  
            }  
            this.containerList[this.keytovalue] =  item     

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {             
                data = { 
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            "delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }    
                dis.getlistasstring.push(data);
            })   
 
             
            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });      
          
            this.checkobjects()            
            this.checkeverything();
        },
        saveContainer(item){
            var sizesize  = (item.container_size)
            var dt  = (item.delivery_to)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            item.container_size = (getsize.id)
            this.lastIndexData = dt
            this.containerList.push(item);  

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {             
                data = { 
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            "delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }    
                dis.getlistasstring.push(data);
            })   
           
            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });      
           // this.updated = 1
            this.checkobjects()            
            this.checkeverything();
            this.showcontaineradded = 1;
           // this.addContainerNotification('<span style="color:#16B442 !important" class="text-message">Container was successfully added</span>') 
        },
        confirmCancel(){
            if(this.triggerloc !=''){
                this.redirect();
            }else{
                location.reload()
            }            
        },
        closecancel(){
            this.showcancel = 0
        },
        cancel() {           
            if(this.ismove){
                this.showcancel = 1
            }else{
                this.triggerloc='cancel'              
                if(this.checkeverything()){
                    this.showcancel = 1
                }else{
                    this.redirect();
                }           
            }           
        },
        formReset(){
             this.$router.push('/containers')
        }, 
        closenotallowed(c){  
            if(c){
                this.notallowed = 0;
            } 
        },
        redirect(){
            if(this.$route.params.sid){
                this.$router.push('/container/edit/'+this.$route.params.sid)
            }else{
                this.$router.push('/containers')
            }
        },
        containerlistchanges(){
            this.finalcontainerListdata = this.getlistasstring;
            var einit = (this.initcontainerListdata);
            var finalval = JSON.stringify(this.finalcontainerListdata);
            let loctype;

            if(einit === finalval ){                                    
                loctype = 0;
            }else{
                loctype = 1;              
            }

            if(this.triggerloc=='cancel'){
              return loctype;
            }else{
              this.haschangesform = loctype; 
            }
        },
        addContainer(){
            var s = this.containerList;
            console.log(s);
            this.dialog = true;
        },        
        dataDown(value){
            this.formFieldlength++;
            this.currentDropdownState = value
            this.currentSelectTab = value
        },
        checkindex(index){
            this.currentindexshow = index
            jQuery('.tablecontainer').css({'background':'none'})
            jQuery(".blueteam_"+index).css({'background':'#F0FBFF'});
        },      
        getPrelims: function(data) {
          let response = data;
          let customers = (response.data.customers);
       
          let DataCustomer=[];
              customers.forEach((value) => {
              DataCustomer.push({id:value.id ,name:value.company_name});
          });
        
          this.itemsDropdown = DataCustomer;
          
          let terminal_at = (response.data.terminal);
              terminal_at.forEach((value) => {
              this.TERMINAL_AT.push({id:value.id,name:value.name});
          });

          let size = (response.data.size);
              size.forEach((value) => {
              this.containerSize.push({id:value.id,name:value.name});
          });

          let containertype = (response.data.type);
              containertype.forEach((value) => {
              this.containerType.push({id:value.id,name:value.name});
          });

          let truckersID = (response.data.trucker);
              truckersID.forEach((value) => {
              this.truckers.push({id:value.id,name:value.name});
          });
          this.startloading = 0;
        },
        onChange() {
            this.filelist = [...this.$refs.file.files];            
            let table = this.filelist;
            let errorCount = 0;
            let keys = [];

            Object.keys(table)
            .forEach(function eachKey(key){           
                if(table[key].type !='application/pdf')
                {   
                    keys.push(key)
                    errorCount++;
                }
            });     

            if(errorCount> 0) {
                this.errorFile =1
                this.allFieldsValid = false;
            }  else{
                this.errorFile =0
                this.allFieldsValid = true;
            }            

            keys.forEach(e => {
                this.filelist.splice(e, keys.length+1)
            });

            if(this.filelist.length > 1) {
                this.filelist.splice(1, this.filelist.length)
                this.moreFilesError = true
            }
            else {
                this.moreFilesError = false
            }   
        },
        remove(i,e) {
        this.uploadTrigger = false;
        try { 
            axios.delete(`${poBaseUrl}/document/remove/` + e.id)      
            .then((response) => {
                if(response.status === 200) {
                    this.hasContent = 'hasContentFiles2'
                    this.documents = []
                }

                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {    
                this.responseDatatempt = response; 
            })

            this.filelist.splice(i, 1);
            if( this.filelist.length == 0){
                this.errorFile = 0
                this.allFieldsValid = true;
            }
        } catch (err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;  
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000) 
        }        
    },
    gotoDownload(){       
        let passdata = this.objectServerid.id  
        this.isFetching = 1; 
        if(passdata !==''){
            axios({
                url: `${poBaseUrl}/newshipment/download/${passdata}`,
                method: 'GET',
                responseType: 'blob'           
            })
            .then((response) => {
                this.isFetching = 0; 
                window.URL = window.URL || window.webkitURL;               
                const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data],{type: "application/octet-stream"}));                
                    link.setAttribute('download', 'Delivery_order.pdf');               
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link); 
            })  
        }
    },
    downloadDocument(documentID){       
        let passdata = documentID 
        this.isFetching = 1; 
        if(passdata !==''){
            axios({
                url: `${poBaseUrl}/document/download/${passdata}`,
                method: 'GET',
                responseType: 'blob'           
            })
            .then((response) => {
                this.isFetching = 0; 
                window.URL = window.URL || window.webkitURL;               
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data],{type: "application/octet-stream"}));                
                document.body.appendChild(link);
                // link.setAttribute('target', '_blank');               
                link.setAttribute('download', 'Delivery_order.pdf');               
                link.click();
                document.body.removeChild(link); 
            })  
        }
    },
    dragover(event) {
      event.preventDefault();    
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {    
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();    
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    async callUPdocument(data){
        if(data)
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        var formData = new FormData();   
        let idparam  = (this.selectedExistingDeliveryID !=0) ? this.selectedExistingDeliveryID : this.$route.params.id;
        try {
            formData.append(`deliveryOrderId`, idparam)
            data.map((file, key) => {
                formData.append(`files[${key}]`, file)
            })
            await axios.post(`${poBaseUrl}/document/upload_file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    this.filelist = []
                    this.documents = response.data.data
                }
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {  
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')                       
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;  
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)          
        }        
      },
      handleReplace(documentID) {
        this.document = documentID
        jQuery(document).find('#replaceFieldHandle').trigger('click');
      },
      onReplace() {
        let file = document.getElementById('replaceFieldHandle');
        this.filelist = file.files
        let table = this.filelist;
        let errorCount = 0;

        Object.keys(table)
        .forEach(function eachKey(key){           
            if(table[key].type !='application/pdf')
            {   
                errorCount++;
            }
        });     

        if(errorCount> 0) {
            this.errorFile =1
            this.allFieldsValid = false;
            this.filelist = []
        } else{
            this.errorFile =0
            this.allFieldsValid = true;
            this.replaceDocument()
        }
      },
      replaceDocument() {
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        this.documents = []

        var formData = new FormData();   
        try {
            formData.append(`files[0]`, this.filelist[0])

            axios.post(`${poBaseUrl}/document/replace_file/${this.document}`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                this.filelist = []
                this.documents = response.data.data
                this.uploadCompleted = true
                this.uploadTrigger = false
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {  
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')                       
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;  
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)          
        }
      },
    //   
        async onSelectCustomer(selected) {
            if (selected !== null && selected !== '') {
                this.consigneeLists = []
                await this.fetchConsigneeAction(selected.id)
            }
        },
        async handleNewConsignee() {
            if(this.newConsignee === null || this.newConsignee === "") {
                this.notificationCustom("Please enter consignee name");
            } else {
                let data = {
                    name: this.newConsignee,
                    customer_id: this.customerdropdown,
                };
                await this.createConsignee(data).then(async (res) => {
                    if (res) {
                        let data = res.data
                        this.notificationMessage(res.message)
                        this.selectedConsignee = data.id
                        // this.consigneeLists = []
                        await this.fetchConsigneeAction(data.customer_id)
                    }
                }) 
            }
        },
        async fetchConsigneeAction(id) {
            await this.fetchConsignees(id)
            if (typeof this.getAllConsignees !== 'undefined' && this.getAllConsignees !== null) {
                this.consigneeLists = this.getAllConsignees.results
            }
            this.$refs.consigneeRef.blur();
        }
    },  
    updated(){
        if(this.ismove==1){
            if(this.istriggerdfrommodal==0){
                this.shownotification = 1;
            }
        }
    },
    async mounted(){ 
        await this.getOrderbyId()
        this.fetchConsigneeAction(this.customerdropdown)
        this.checkobjects()  
        this.initcontainerListdata = JSON.stringify(this.containerList);
        let getcustomerref = JSON.stringify(this.timetopassreference());
        let contactlist = JSON.stringify(this.timetopass())
        this.doInitialvalue = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;
        this.$store.dispatch("page/setPage", "delivery-order");
    }
};
</script>
<style lang="scss">
@import '../../../assets/scss/pages_scss/containers/containersTable.scss';
@import '../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../assets/scss/buttons.scss';

.v-input {
    &.select-consignee.v-input--is-disabled {
        .v-input__control {
            .v-input__slot {
                background-color: #EBF2F5;
                border-color: #EBF2F5 !important;
            }
        }
    }
}

.v-menu__content {
    &.consignee-menu-lists {
        .v-list {
            padding-top: 2px;
            padding-bottom: 2px;
            .v-list-item {
                min-height: 42px;
                // border-bottom: 1px solid #EBF2F5;
            }
        }
    }
}
</style>
<style>
   /* New style */
    .topheader {
        padding-left: 19px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 4px
    }
    .topheader li {
        color:#6D858F !important;
        display: inline-block;
        width: 19%; 
        word-break: break-all;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600px;
    }
    .addContainer {
        border-bottom: 1px solid #eee;
        border-top: 0px solid #eee;
        font-size: 14px;
        margin-top: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
    }
    .leftcol {
        border-right: 1px solid #eee;
    }
    .data-element .item {
        border-bottom: 0px solid #eee;
        padding: 6px
    }
    .headerContainer {      
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;      
        align-items: center;
      
        color: #4A4A4A;
    }
    .tablecontainer {
        padding:0 5px;
        border-bottom: 1px solid #EBF2F5;
        border-top: 1px solid #EBF2F5;
        font-size: 14px;
    }
    .tablecontainer:hover{
        background: rgb(240, 251, 255) none repeat scroll 0% 0% !important
    }
    .tablecontainer li { 
        display: inline-block;
        width: 19%; 
        word-break: break-all;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 3px;
    }

    .uploadDocuwrapper  {
        margin-top: 7px !important;
    }

    .containers-wrappers {
        padding-top: 8px !important;
    }

    .container-create {
        padding-top: 0px !important;
    }

    .container-create .toolbHeader .v-toolbar__content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .toolbHeader {
        margin-bottom: 4px;
    }

    .container-create .toolbHeader{
        background: none !important;
    }

    .NewContainerShipmentTitle {
        font-size: 24px;
        font-weight: 600;
    }

.colright {
    width: 67.7% !important;
    padding-left: 11px !important;
    padding-top: 13px !important;
    padding-right: 16px !important; 
}

.colright {
    max-width: 90% !important;
}
    .deliveryOrderColumn {
        background-color: #fff;
        padding: 0px;
        width:  97.6%;
        margin-left: 8px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .vue-tags-input {
        max-width: 100% !important;
    }

    .ti-new-tag-input {
        caret-color: #000 !important;
    }

    .ti-tags .ti-tag {
        background:#F7F7F7;
        color:#4A4A4A
    }

    div.ti-input {
        border-radius: 4px;
        border: 1px solid #B4CFE0 !important;
    }

    .uploadElement .hasContentFiles2 {
        background: #fff !important;
        padding:6px !important;
        display: block !important;
        height: 106px !important;
        border:2px dashed #B4CFE0 !important;
        border-radius: 5px;
    }

    .browserInput2 {
     width: 100%;
}

.browseFileDelivery {
    width: 100% !important;
    text-align: center;
    color:#B4CFE0 !important;
    padding-top: 12px;
    font-size: 14px !important;
}

.browseButton2 {
    width: 79px;  
    font-size: 12px;
    margin:0 auto;
    margin-top: 6px;   
}

.wrappIconbutton2 {
    border: 1px solid #B4CFE0;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-top:21px
}
.classUpload {
     font-size: 12px;
    color: #0171A1;
    position: relative;
    left: 6px;
}

/* .tablecontainer li:hover {
    background: #fff;
} */



.iconUploadfile {
    color:#0171A1;
}

.rowItemUploaded {  
    background: #F7F7F7 !important;
    border-radius: 6px;
    padding: 10px;
    height: 64px;
}

.mainwrapitem {
    border: 0px !important;
}

.uploadCreateNew {
    position: relative;
    top: 3px;
    left: -1px;
    height: 15px
}

.hidethisdiv {
    display: none !important;
}

.reupload {
    float: right;
    font-size: 14px !important;
    color: rgb(1, 113, 161);
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 0px;
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 1px;
}
 .ic-calendar::before{
    font-size:16px !important;
}

.lastchild:hover {
    background: none !important;
}

 .ic-chevron-down::before {
    font-size: 16px !important
}

.document-btn {
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
}

.replace-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0171A1;
}
.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}

.startingDOSclass .v-menu__content {
  max-height:200px !important;
}
</style>