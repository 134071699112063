<template>
  <div
    d-flex
    class="supplier-content-wrapper driver-content-wrapper yard-container-wrapper"
  >
    <div class="driverHeader">
      <h2>Company Reports</h2>
      <v-btn
        @click="SET_SHOW_COMPANY_REPORT_DIALOG(true)"
        color="primary"
        class="btn-blue"
      >
        <img src="@/assets/icons/plus-button-icon.svg" />&nbsp;Set Up Report
      </v-btn>
    </div>
    <v-card class="company-report-container" outlined tile elevation="0" style="min-height: 600px;">
      <div v-if="typeof companyReports.data !== 'undefined' && companyReports.data.length > 0 && isPageLoading == false">
        <div v-for="report, index in companyReports.data" :key="index">
          <div class="row-v1">
            <div class="row-left">
              <p class="sub-heading mb-3">{{ reportHeaders[report.report_type] }}</p>
              <div v-if="report.active" class="d-inline-flex mt-1">
                <i style="margin-top: 1px; color:#819fb2" class="ic-clock"></i>
                <div class="ml-2">
                  <p v-if="report.frequency == 'DAILYAT'" >
                    <span class="font-weight-bold">Daily</span> at {{ formattedTime(report.time) }}
                  </p>
                  <p v-else-if="report.frequency == 'WEEKLYON'" >
                    <span class="font-weight-bold">Weekly</span> Every {{ dayOptions[report.day] }} at {{ formattedTime(report.time) }}
                  </p>
                  <p v-else-if="report.frequency == 'MONTHLY'" >
                    <span class="font-weight-bold">Monthly</span> Every {{ formattedDayInMonth(report.day) }} at {{ formattedTime(report.time) }}
                  </p>
                  <p v-else-if="report.frequency == 'YEARLY'" >
                    <span class="font-weight-bold">Yearly</span> Every {{ monthOptions[report.month] }} {{ report.day }} at {{ formattedTime(report.time) }}
                  </p>
                </div>
              </div>
              <div v-if="report.active"  class="d-flex" style="margin-top: -10px">
                <i style="vertical-align:middle; line-height:inherit; color:#819fb2" class="ic-users"></i>
                <div class="ml-2 d-flex"  v-if="report.recipients">
                  <div v-for="email, i2 in decodedEmails(report.recipients)" :key="i2" class="email-tabs mr-2">
                    <p class="email-txt">{{ email }}</p>
                  </div>
                </div>
              </div>
              <p class="subRow-three mb-0">
                Last Updated at {{ formattedDatetime(report.updated_at) }}
              </p>
            </div>
            <div class="d-flex flex-row align-top">
              <div style="margin-right: 16px; margin-top: 5px;">
                <ToggleButton
                  class="report-toggle" 
                  :value="companyReports.data[index].active ? true : false"
                  sync
                  :width='68'
                  :height="30"
                  @change="onUpdateStatus({...report,index:index})"
                  :switch-color="{ checked:'#fff', unchecked:'#819FB2'}"
                  :labels="{checked: 'On', unchecked: 'Off'}"
                  :color="{checked: '#16B442', unchecked: '#F0FBFF', disabled: '#CCCCCC'}"
                />
              </div>
              <div style="height: 40px">
                <v-divider class="vertical-divider" vertical color="#B4CFE0"></v-divider>
              </div>
              <v-btn @click="onSendReport(report)" :disabled="selectedReport.id == report.id && selectedReport.isSendingReport" style="margin: 0px 8px 0px 16px; height: 40px !important; border: 1px solid #B4CFE0;" class="btn-white createCancelBtn" depressed color="primary">
                Send Now &nbsp;
                <v-progress-circular v-if="selectedReport.id == report.id && selectedReport.isSendingReport" :size="15" color="#0171A1" :width="2" indeterminate style="margin-right:3px"></v-progress-circular>
                <img v-else src="@/assets/icons/send.svg" alt=""/>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <button class="burger-button" v-bind="attrs" v-on="on" style=" border: 1px solid #B4CFE0; height: 40px; width: 48px; border-radius: 4px; ">
                    <img class="burger-button-icon" src="@/assets/icons/more-blue.svg" alt="" />
                  </button>
                </template>
                <v-list>
                  <v-list-item @click="onToggleEditOpen(report)">
                    <v-list-item-title class="dropdown-tabs">
                      <i class="ic-edit mr-1"></i>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onDownloadClick(report)">
                    <v-list-item-title class="dropdown-tabs">
                      <i class="ic-download mr-1"></i>
                      Download</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item  @click="onToggleDeleteOpen(report)">
                    <v-list-item-title class="delete-drop-down">
                      <i class="ic-delete mr-1"></i>
                      Delete Report</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div>
            <v-divider class="mt-5 horizontal-divider" color="#EBF2F5 "></v-divider>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-center align-center">
        <div v-if="isPageLoading" class="d-flex justify-center align-center flex-column" style="margin-top: 80px;">
          <v-progress-circular
            v-if="isPageLoading"
            :size="40"
            color="#0171a1"
            indeterminate>
          </v-progress-circular>
        </div>
        <div v-else>
          <div class="d-flex justify-center align-center flex-column mt-10">
            <i class="ic-reports customize-prop"></i>
            <p
              style="color: #4A4A4A; font-style: normal; font-weight: 600; font-size: 20px; line-height: 30px; margin-top: 15px;">
              Company Reports
            </p>
            <p style="color:#6D858F; font-style: normal;font-weight: 400;font-size: 14px;color: #6d858f;width: 55%;">
              Let's set up a new Company report.
              You can adjust when to send, who are the recipients and download it right away from this page.
            </p>

            <button
              @click="SET_SHOW_COMPANY_REPORT_DIALOG(true)"
              style="color:#0171A1; height: 40px; width: 127px; border: 2px solid #b4cfe0; border-radius: 4px; font-size: 14px; line-height: 20px;">
              <span class="setup-btn-label">Setup Report</span>
            </button>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="toggleDeleteDialogOpen" max-width="500px">
      <v-card style="padding-bottom:8px">
        <v-card-text class="pt-5 pb-0 contentbody" style="caret-color: transparent;padding-bottom:0px !important">
          <img src="../../../assets/icons/warning-alert-icon.svg" />
          <h2 style="margin-top:12px">Delete Report</h2>
          <p>Are you sure you want to delete this report?</p>
        </v-card-text>
        <v-card-actions style="border-top:0px;">
          <v-btn class="btn-blue mr-4" @click="onDeleteReport" :disabled="isDeleting">
            <span>
              <span>
                <v-progress-circular v-if="isDeleting" :size="15" color="#fff" :width="2" indeterminate style="margin-right:3px"></v-progress-circular> 
                 Delete 
              </span>
            </span>
          </v-btn>
          <v-btn @click="onToggleDeleteClose" style="margin-left:0px !important" class="btn-white" text>Cancel</v-btn>           
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios';
import moment from 'moment';
  import globalMethods from '../../../utils/globalMethods';
import { ToggleButton } from 'vue-js-toggle-button'

const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  components: {
    ToggleButton,
  },

  props: ["isMobile"],

  data: () => {
    return {
      isPageLoading: true,
      companyReports:[],

      reportHeaders: {
        do_report : 'Delivery Order Report',
        leg_report : 'Leg Report',
        shipment_report : 'Container/Shipment Report',
      },

      dayOptions:{
        1 : 'Monday',
        2 : 'Tuesday',
        3 : 'Wednesday',
        4 : 'Thursday',
        5 : 'Friday',
        6 : 'Saturday',
        7 : 'Sunday',
      },

      monthOptions: {
        1 : 'January',
        2 : 'February',
        3 : 'March',
        4 : 'April',
        5 : 'May',
        6 : 'June',
        7 : 'July',
        8 : 'August',
        9 : 'September',
        10 : 'October',
        11 : 'November',
        12 : 'December',
      },

      toggleDeleteDialogOpen: false,
      isDeleting: false,
      isSendingReport: [],
      selectedReport: {},
      pageCount: 0,
      page: 1,
    };
  },

  computed: {
    ...mapGetters("reports", ["getLoadingStatus", "getShowStatic","getSetReloadIndex"]),
  },

  watch:{
    getSetReloadIndex(nval){
      if(nval){
        this.getCompanyReports();
      }
    }
  },

  created(){
    this.getCompanyReports();
  },

  methods: {
    ...mapMutations("reports", [
        "SET_SHOW_COMPANY_REPORT_DIALOG",
        "SET_SELECTED_COMPANY_REPORT",
        "SET_COMPANY_REPORT_FOR_EDIT",
        "SET_COMPANY_REPORT_RELOAD_INDEX",
      ]),
      ...globalMethods,

    async getCompanyReports(){
      this.isPageLoading = true;
      await axios.get(`${APIBaseUrl}/reports/company`)  
        .then((res) => {
          if(res.data){
            this.companyReports = res.data;
          }
          this.isPageLoading = false;
        })
        .catch((err) => {
          this.isPageLoading = false;
          console.log(err);
        });
      this.SET_COMPANY_REPORT_RELOAD_INDEX(false);
    },

    formattedTime(time){
      return moment(time,'HH:mm:ss').format('hh:mm A');
    },
    
    formattedDate(date){
      return moment(date,'YYYY-MM-DD').format('MMM DD, YYYY');
    },

    formattedDatetime(date){
      return moment(date,'YYYY-MM-DD HH:mm:ss').format('hh:mm A  MMM DD, YYYY');
    },

    formattedDayInMonth(day){
      let date = '1990-01-'+day;
      return moment(date,'YYYY-MM-D').format('Do');
    },

    decodedEmails(val){
      if(val){
        let recipients = JSON.parse(val);
        if(recipients.emails && recipients.emails.length > 0){
          return recipients.emails;
        }
      }
      return [];
    },

    onToggleDeleteOpen(report){
      this.toggleDeleteDialogOpen = true;
      this.selectedReport = report;
    },

    onToggleDeleteClose(){
      this.toggleDeleteDialogOpen = false;
      this.selectedReport = {};
      this.isDeleting = false;
    },

    onToggleEditOpen(report){
      this.SET_SHOW_COMPANY_REPORT_DIALOG(true);
      this.SET_COMPANY_REPORT_FOR_EDIT(true);
      this.SET_SELECTED_COMPANY_REPORT(report);
    },

    onToggleEditClose(){
      this.SET_SHOW_COMPANY_REPORT_DIALOG(false);
      this.SET_COMPANY_REPORT_FOR_EDIT(false);
      this.SET_SELECTED_COMPANY_REPORT({});
    },

    async onDeleteReport(){
      this.isDeleting = true;
      if(this.selectedReport.id){
        await axios.delete(`${APIBaseUrl}/reports/company/${this.selectedReport.id}`)
        .then((res) => {
          if (res.data.status == 'success') {
            this.isDeleting = false;
            this.getCompanyReports();
            this.dispatchNotifcation(res.data.message)
            this.onToggleDeleteClose();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isDeleting = false;
          this.selectedReport = {};
          this.dispatchNotifcationError(err.message)
        });
      }
    },

    async onSendReport(report){
      this.selectedReport = report;
      this.selectedReport.isSendingReport = true;
      if(report.id){
        await axios.get(`${APIBaseUrl}/reports/company/send-now/${report.id}`)
        .then((res) => {
          if (res.data.status == 'success') {
            this.dispatchNotifcation(res.data.message)
            this.selectedReport = {};
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSendingReport.data[report.id] = false;
          this.dispatchNotifcationError(err.message);
          this.selectedReport = {};
        });
      }
    },

    async onUpdateStatus(report){
      let value = !report.active;
      this.companyReports.data[report.index].active = value;
      if(report.id){
        await axios.post(`${APIBaseUrl}/reports/company/update-status/${report.id}`,{
          _method: 'patch',
          is_active: value
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.dispatchNotifcation(res.data.message);
            this.getCompanyReports();
          }else{
            this.getCompanyReports();
          }
        })
        .catch((err) => {
          console.log(err);
          this.dispatchNotifcationError(err.message)
        });
      }
    },

    async onDownloadClick(report){
      if(report.id){

        let filename = 'file';
				let theDate = new Date().valueOf();
        if(report.report_type == 'do_report'){
          filename = 'delivery_order_report_'+ theDate;
        }else if(report.report_type == 'shipment_report'){
          filename = 'shipment_report_'+ theDate;
        }else if(report.report_type == 'leg_report'){
          filename = 'leg_report_'+ theDate;
        }

        axios({
          url: `${APIBaseUrl}/reports/company/download/${report.id}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          if(response.data){
            window.URL = window.URL || window.webkitURL;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data], {
              type: "application/octet-stream",
            }));
            link.setAttribute("download", filename + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
         
        }).catch((err) => {
          console.log(err);
          this.dispatchNotifcationError(err.message)
        });
      }
    }

  },
};
</script>
<style scoped>
.sub-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.notifi-div {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #ebfaef;
  /* box-shadow: 0px 0px 1px rgba(180, 207, 224, 0.56),
    0px 4px 12px rgba(180, 207, 224, 0.4); */
  border-radius: 4px;
  align-items: center;
  height: 50px;
}
.notifi-div-txt {
  margin: 10px 20px;
  color: #16b442;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.customize-prop {
  font-size: 50px;
  color: #1a7ca8;
}
.row-v1 {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0px 20px;
}

.row-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.email-tabs {
  background-color: #f7f7f7;
  color: #4a4a4a;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid #EBF2F5;
  border-radius: 4px;
}
.email-txt {
  margin: 3px 10px;
}
.subRow-three {
  color: #819fb2;
  font-size: 12px;
  margin-top: 10px;
}
.delete-drop-down {
  color: #f93131;
}
.dropdown-tabs {
  color: #4a4a4a;
}

.burger-button{
  display: grid;
  align-items: center;
}

.burger-button-icon{
  margin: 0 auto;
}
.vue-js-switch >>> .v-right{
  color: #819FB2;
}
.toggled >>> .v-switch-core{
  border: 1px solid #16B442 !important;
}
.vue-js-switch >>> .v-switch-core{
  border: 1px solid #EBF2F5;
  position: initial !important;
}
.vertical-divider{
  border-color: #B4CFE0 !important;
}
.horizontal-divider{
  margin-top: 16px !important;
  border-color: #EBF2F5 !important;
}

.v-left{
  font-size: 14px !important;
  font-weight: 500 !important;
}

.company-report-container{
  border: 1px solid #EBF2F5 !important;
  border-radius: 4px 0px 0px 4px !important;
}
.report-toggle >>> .v-switch-label{
    right: 14px !important;
    line-height: 30px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.toggled >>> .v-switch-label{
    left: 14px !important;
}

.setup-btn-label{
  font-weight: 600 !important;
}
</style>
