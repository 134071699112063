<template>
    <v-dialog persistent v-model="dialogDelete" max-width="480px" content-class="delete-driver-dialog" v-resize="onResize" :retain-focus="false">
        <v-card>
          <v-card-text class="pt-5 pb-0" style="caret-color: transparent;">
            <img src="../../assets/icons/warming.svg" />
            <h2>Delete Driver</h2>
            <p>Do you want to delete <strong>{{editedItemData.name}}</strong> from the driver list? It will delete all their data and they will no longer have access to Shifl platform.</p>
            <span style="color:red" v-if="errorFound">{{errorMessage}}.</span>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="deletenow" class="btn-blue">
                <span>
                    <span>
                        <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >                       
                                    </v-progress-circular> 
                       Delete
                    </span>
                </span>
            </v-btn>
            <v-btn style="margin-left:0px !important" class="btn-white" text @click="close" v-if="!isMobile">
                Cancel
            </v-btn>           
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
export const bus = new Vue();
export default {
    name: 'DriverDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData','idrow'],
    components: {
        //VueTelInputVuetify,
        //VueTagsInput
    },
    data: () => ({
        errorMessage:'',
        errorFound:0,
        drivername: '',
        isFetching:0,
        valid: true,
        startloading:0,
        options: [],
        value: [],
        isMobile: false,
        drivermessage: 'Driver has been deleted.',
        rules: [
            (v) => !!v || "Input is required."
        ],       
        numberRules: [
            (v) => !!v || "Input is required.",
            (v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],  
    }),
    computed: {
        ...mapGetters({
            // getUser: 'getUser',
             getCreateSuppliersLoading: 'suppliers/getCreateSuppliersLoading',
             getSupplierSaveAddLoading: 'suppliers/getSupplierSaveAddLoading'
        }),       
        dialogDelete: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        }, 
    },
    methods: {
        getDriverName(){          
            var dis = this;
            Object.entries(this.editedItemData).forEach(entry => {
                const [key, value] = entry;
                if(key=='name')
                dis.drivername = value 
            });           
        },
        ...mapActions({
            createSuppliers: 'suppliers/createSuppliers',
            fetchSuppliers: 'suppliers/fetchSuppliers',
            updateSuppliers: 'suppliers/updateSuppliers'
        }),
        ...globalMethods,       
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        deletenow(){
            let id = this.idrow;  
            this.startloading =1;    
            this.isFetching = 1;   
            this.errorMessage = '';
            this.errorFound = 0;

             axios.delete(`${APIBaseUrl}/deleteDriver/` + id)            
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message; 
                this.errorFound = 1;               
            }).then(response => {                      
                this.startloading =0;
                
                if(typeof response !='undefined'){
                    if(response.response == 'Successful'){
                        // hide the dialog                    
                        this.$emit('update:dialogData', false)
                        this.$emit('update:notifyDeleteitem',id);
                        bus.$emit('changeIt', 'changed header');
                        this.dispatchNotifcation('<i class="ic-delete"></i>&nbsp;<span class="text-message">'+this.drivermessage+'</span>')    
                    }
                }
                this.isFetching = 0;   
            })
          
        },
        close() {           
            this.$emit('update:dialogData', false)
        },       
        setToDefault() {
            this.$emit('setToDefault')
        }
    },
    watch: { 
        dialogDelete(c){
            if(c){    
                this.errorMessage = '';
                this.errorFound = 0;           
                this.getDriverName();         
            }
        }
    },
    updated() {       
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>