  <template>
  <div>
    <v-data-table
        :headers="headers"
        mobile-breakpoint="400"
        :items="drivers"
        class="suppliers-table driver-table elevation-1"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
        fixed-header
    >
        <template v-slot:[`header.name`]="{ header }">
			{{ header.text }}<i class="classsort"></i>
			</template>
        <template v-slot:top>
        <v-toolbar flat>
            <Tab
                :pageCurrent="pageCurrent"
                @currentPage="currenttab"
                :countTab="countTab"
            />
          <v-spacer></v-spacer>
          <Search
            placeholder="Search Drivers"
            className="search custom-search"
            :inputData.sync="search"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">{{ item.name !== "" ? item.name + " " + item.last_name : "--" }}</p>
      </template>

      <template v-slot:[`item.emails`]="{ item }">
        <p class="mb-0">{{ item.emails !== "" ? item.emails : "--" }}</p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">


      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn style="width:80px"
                class="connect btn-white-driver"
                height="36"
                elevation="0"
                @click="inviteDriver(item)"
                v-bind="(item.driver_invites && item.driver_invites.length > 0) || (item.driver_connects && item.driver_connects.length > 0) ? attrs : null"
                v-on="(item.driver_invites && item.driver_invites.length > 0) || (item.driver_connects && item.driver_connects.length > 0) ? on : null"
            >
              <span v-if="currnetDriverInvitationLoadingId != item.id">
                {{ (item.driver_invites && item.driver_invites.length > 0) || (item.driver_connects && item.driver_connects.length > 0) ? 'Reinvite' : 'Invite' }}
              </span>
              <v-progress-circular
                v-else
                indeterminate
                :size="19"
                :width="2"
                color="primary">
              </v-progress-circular>
            </v-btn>
          </template>
          <div style="font-size: 10px" v-if="(item.driver_invites && item.driver_invites.length > 0) || (item.driver_connects && item.driver_connects.length > 0)"> Last sent at : {{ getLastInviteSentAt(item) }} </div>
        </v-tooltip>
        <v-menu offset-y nudge-width="100" nudge-left="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="truckerMoreBtn">
              <img src="@/assets/icons/more-blue.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="trucking_DropDown">
               <v-list-item-title
                class="trucking_DropDownEdit"
                @click="editDriver(item, item.id)"
                >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
                </svg>
                Edit
              </v-list-item-title>
              <v-list-item-title
                class="trucking_DropDownEdit"
                @click="viewDriver(item, item.id)"
                >
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00044 2.88073C9.63611 2.88073 10.9621 4.25058 10.9621 5.94037C10.9621 7.63016 9.63611 9.00001 8.00044 9.00001C6.36478 9.00001 5.03881 7.63016 5.03881 5.94037C5.03881 4.25058 6.36478 2.88073 8.00044 2.88073ZM8.00044 4.0281C6.97815 4.0281 6.14942 4.88425 6.14942 5.94037C6.14942 6.99649 6.97815 7.85265 8.00044 7.85265C9.02273 7.85265 9.85147 6.99649 9.85147 5.94037C9.85147 4.88425 9.02273 4.0281 8.00044 4.0281ZM8.00044 0.200012C11.4163 0.200012 14.3651 2.60948 15.1832 5.98608C15.2577 6.29344 15.0769 6.60497 14.7794 6.6819C14.4819 6.75884 14.1803 6.57205 14.1059 6.2647C13.4107 3.39577 10.9039 1.34738 8.00044 1.34738C5.09577 1.34738 2.58813 3.39754 1.89419 6.26816C1.81988 6.57555 1.51843 6.76251 1.22088 6.68574C0.923328 6.60897 0.742357 6.29755 0.816666 5.99015C1.6334 2.61156 4.58307 0.200012 8.00044 0.200012Z" fill="#6D858F"/>
                </svg>
                View
              </v-list-item-title>
              <v-list-item-title v-if="item.status==1" @click="actionDriver(item,'deactivate')">
                  <span  >
                    <svg style="position:relative;top:2px" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27128 2.40739C6.6841 2.40739 7.82942 3.56813 7.82942 4.99998C7.82942 6.43183 6.6841 7.59257 5.27128 7.59257C3.85846 7.59257 2.71314 6.43183 2.71314 4.99998C2.71314 3.56813 3.85846 2.40739 5.27128 2.40739ZM6.80616 4.99998C6.80616 4.14087 6.11897 3.44442 5.27128 3.44442C4.42359 3.44442 3.73639 4.14087 3.73639 4.99998C3.73639 5.85909 4.42359 6.55553 5.27128 6.55553C6.11897 6.55553 6.80616 5.85909 6.80616 4.99998Z" fill="#F93131"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7286 0.333313C13.2717 0.333313 15.3333 2.42265 15.3333 4.99998C15.3333 7.57731 13.2717 9.66665 10.7286 9.66665H5.27128C2.7282 9.66665 0.666626 7.57731 0.666626 4.99998C0.666626 2.42265 2.7282 0.333313 5.27128 0.333313H10.7286ZM14.31 4.99998C14.31 2.99539 12.7066 1.37035 10.7286 1.37035H5.27128C3.29333 1.37035 1.68988 2.99539 1.68988 4.99998C1.68988 7.00457 3.29333 8.62961 5.27128 8.62961H10.7286C12.7066 8.62961 14.31 7.00457 14.31 4.99998Z" fill="#F93131"/>
                    </svg>
                  </span>
                  Deactivate
              </v-list-item-title>

            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="startfetching">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
          >
          </v-progress-circular>
        </div>
        <div v-else>
            No data
        </div>
      </template>
    </v-data-table>

    <Pagination
      v-if="drivers.length"
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    />

     <Dialog
      :dialogData.sync="dialogHolder"
      :driverID="driverID"
      :bus="bus"
    />
    </div>
</template>
<script>
import { mapActions} from "vuex";
import Pagination from "../../../Pagination.vue";
import Search from "../../../Search.vue";
import Dialog from "../dialog/driverHolder.vue";
import Tab from "./tab.vue";
import axios from 'axios';
import globalMethods from '../../../../utils/globalMethods'
const poBaseUrl = process.env.VUE_APP_PO_URL

export default {
  name: "Connected",
  props: ["items",
          "isMobile",
          "itemData",
          "reloadcomponent",
          "pageCurrent",
          "startfetching",
          'bus',
          'countTab'],
  components: {
      Pagination,
      Search,
      Tab,
      Dialog
  },
  data: () => ({
    dialogHolder:false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 15,
    driverID:0,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: true,
        width: "17%",
        fixed: true,
      },
      {
        text: "Email",
        align: "start",
        value: "email",
        sortable: false,
        width: "17%",
        fixed: true,
      },
      {
        text: "",
        align: "start",
        value: "actions",
        sortable: false,
        width: "7%",
        fixed: true,
      },
    ],
    DriverData: [],
    search: "",
    currnetDriverInvitationLoadingId: null,
  }),
  computed: {
    drivers: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      passData: "drivers/passData",
      passLocFrom: "drivers/passLocFrom",
      detailNotification: "drivers/detailNotification",
    }),
    ...globalMethods,
    sendEmail(item,id){
      this.$emit('sendEmail',id);
    },
    connectDriver(id){
      console.log(id);
      // this.dialogHolder = true;
      // this.driverID = id;
    },
    currenttab(cur){
      this.$emit("currentPage",cur);
    },
    async viewDriver(item, id) {
        if(item){
          await this.passData(item)
          await this.detailNotification(item)
          await this.passLocFrom('notconnected');
          this.$router.push("/drivers-details/" + id+"/02").catch(()=>{});
        }
    },
    editDriver(item, id) {
        this.bus.$emit('changeIt',{
          id:id,
          updatelisting:item,
          editPassdata:id,
          editDriver:item
        });
    },
    async inviteDriver(item){
        this.currnetDriverInvitationLoadingId = item.id
        await axios.post(`${poBaseUrl}/dispatcher-driver/${item.id}/invite`)
              .then(res => {
                  console.log(res.data.message)
                  this.currnetDriverInvitationLoadingId = null
                  this.dispatchNotifcation(
                    '<span class="text-message">' + res.data.message + "</span>"
                  );
                  this.$emit('reloadpage',1)
              }).catch(err => {
                  console.log(err)
                  this.currnetDriverInvitationLoadingId = null
              })
    },
    actionDriver(item,type){
        let url = '';
        console.log(item)
        if(type=='activate'){
          url = 'activateDriver';
        }else if(type =='deactivate') {
          url = 'deactivateDriver';
        }else{
          url = 'disconnectDriver';
        }
        axios
        .put(`${poBaseUrl}/`+url+'/'+item.id)
        .then((response) => {
            if(response){
                this.countTab = response.count
                this.dispatchNotifcation(
                    '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                    'Driver has been successfully ' + type + 'd' +
                    "</span>"
                );
            }
            this.$emit('reloadpage',1)
        })
        .catch((error) => {
            console.log(error);
        });
    },
    getLastInviteSentAt(item){
        if(item.driver_connects && item.driver_connects.length > 0){
            return new Date(item.driver_connects[item.driver_connects.length - 1].updated_at).toLocaleString();
        }
        if(item.driver_invites && item.driver_invites.length > 0){
          return new Date(item.driver_invites[item.driver_invites.length - 1].created_at).toLocaleString();
        }
        return ''
    }
  },
  async mounted() {
    //set current page
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},

};
</script>
<style>
    .connect { margin-right: 5px;}
    .connect .v-btn__content {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        color:#0171A1;

    }
    .btn-white-driver {
        background-color: #ffffff !important;
        color: #0171A1 !important;
        border: 1px solid #B4CFE0 !important;
        padding: 10px 16px !important;
        font-size: 14px;
        height: 36px !important;
        text-transform: capitalize;
        letter-spacing: 0;
        box-shadow: none !important;
        border-radius: 4px;
        font-family: "Inter-Regular", sans-serif;
    }
</style>
