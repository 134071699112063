<template>
  <div class="ssupplier-container yard-container common-container-el">
    <ReportNewDesktopTable />
    <CompanyReportForm />
  </div>
</template>

<script>
import CompanyReportForm from "../components/Tables/Reports/Dialog/CompanyReportForm.vue";
import ReportNewDesktopTable from "../components/Tables/Reports/ReportsNewDesktopTable.vue";
export default {
  components: {
    CompanyReportForm,
    ReportNewDesktopTable,
  },
  props: ["isMobile"],
  data: () => {
    return {
      switch1: true,
      switch2: false,
      boxtype: "",
      itemsPerPage: 9,
      alertbox: false,
      pageCount: 0,
      page: 1,
    };
  },
  computed: {},
  methods: {},
  async mounted() {
    this.$store.dispatch("page/setPage", "reports");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/yard/yard.scss";
@import "../assets/scss/pages_scss/yard/yard_css.scss";
@import "../assets/scss/buttons.scss";
@import "../assets/css/dialog_styles/dialogHeader.css";
@import "../assets/css/dialog_styles/dialogBody.css";
@import "../assets/css/dialog_styles/dialogFooter.css";
.v-select--is-menu-active {
  background: #fff !important;
}
</style>
