<template>
  <div class="containers-wrappers">
     <company-profile-desktop-table    
     ref="comprofile"
     :isDiscard="isDiscard"
     :lastidchoosen.sync="lastidChoosen"
     @checkifhaschanges="checkifhaschanges"
     :lasttabchanges="lasttabchanges"
     />
    <DiscardChanges
      :dialog="discard_changes_modal"
      @discardChanges="discardChanges"
    />
  </div>
</template>

<script>
import CompanyProfileDesktopTable from "../components/Tables/Containers/CompanyProfileDesktopTable.vue";
import DiscardChanges from "../components/SettingsComponents/Dialog/DiscardChanges.vue";
import { mapActions } from "vuex";

export default {
  components: {
    CompanyProfileDesktopTable,
    DiscardChanges
  },
  watch:{   
  },
  data() {
    return {
      lasttabchanges:-1,
      nadiscardna:0,
      lastidChoosen:0,
      isDiscard:0,
      fromtabs:0,
      hasChanges: 0,
      discard_changes_modal: false,
    };
  },

  beforeRouteLeave(to, from, next) {    
   if( typeof this.$refs.comprofile.$refs.noti !='undefined'){
     if(this.$refs.comprofile.$refs.noti.getstatus()){  
        if(this.nadiscardna===1){
           next(true);
        }  else{
          this.discard_changes_modal = true;
          this.next = next;
        }        
      }else{
        next(true);  
      }
   }else{
      next(true);    
    }
  },
  computed: {},
  methods: {
    ...mapActions("profile", ["getCompanyProfileAsync"]),
    discardChanges(val){
      if(this.fromtabs){          
         if(val){           
          this.isDiscard = 1;
          this.nadiscardna = 1;
          this.fromtabs = 0;
          this.lasttabchanges = this.lastidChoosen
         }
         this.discard_changes_modal = false;
      }else{
        this.discard_changes_modal = false;
        this.next(val);
        this.next = null;
      }
    },  
    checkifhaschanges(next){  
      if(next.status){ 
         this.lastidChoosen = next.pageid;
         this.fromtabs = 1;
         this.discard_changes_modal = true;
      }
    }
  },  
  created() {
    this.getCompanyProfileAsync();
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "settings/company-profile");
  },
};
</script>

<style></style>
