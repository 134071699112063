<template>
  <div>
    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="drivers"
      class="suppliers-table driver-table elevation-1"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      fixed-header
    >
      <template v-slot:[`header.name`]="{ header }">
        {{ header.text }}<i class="classsort"></i>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <Tab
            :pageCurrent="pageCurrent"
            @currentPage="currenttab"
            :countTab="countTab"
          />
          <v-spacer></v-spacer>
          <Search
            placeholder="Search Drivers"
            className="search custom-search"
            :inputData.sync="search"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">{{ concatlastname(item) }}</p>
      </template>

      <template v-slot:[`item.phone_number`]="{ item }">
        <p class="mb-0">
          {{ item.phone_number !== "" ? item.phone_number : "--" }}
        </p>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <p class="mb-0">
          {{ item.email !== "" ? item.email : "--" }}
        </p>
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        <p class="mb-0">
          {{ lastseendate(item.driver.updated_at) }}
        </p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y nudge-width="100" nudge-left="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="truckerMoreBtn">
              <img src="@/assets/icons/more-blue.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="trucking_DropDown">
              <v-list-item-title
                class="trucking_DropDownEdit"
                @click="editDriver(item, item.id)"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
                    fill="#6D858F"
                  />
                </svg>
                Edit
              </v-list-item-title>
              <v-list-item-title class="" @click="viewDriver(item, item.id)">
                <svg
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00044 2.88073C9.63611 2.88073 10.9621 4.25058 10.9621 5.94037C10.9621 7.63016 9.63611 9.00001 8.00044 9.00001C6.36478 9.00001 5.03881 7.63016 5.03881 5.94037C5.03881 4.25058 6.36478 2.88073 8.00044 2.88073ZM8.00044 4.0281C6.97815 4.0281 6.14942 4.88425 6.14942 5.94037C6.14942 6.99649 6.97815 7.85265 8.00044 7.85265C9.02273 7.85265 9.85147 6.99649 9.85147 5.94037C9.85147 4.88425 9.02273 4.0281 8.00044 4.0281ZM8.00044 0.200012C11.4163 0.200012 14.3651 2.60948 15.1832 5.98608C15.2577 6.29344 15.0769 6.60497 14.7794 6.6819C14.4819 6.75884 14.1803 6.57205 14.1059 6.2647C13.4107 3.39577 10.9039 1.34738 8.00044 1.34738C5.09577 1.34738 2.58813 3.39754 1.89419 6.26816C1.81988 6.57555 1.51843 6.76251 1.22088 6.68574C0.923328 6.60897 0.742357 6.29755 0.816666 5.99015C1.6334 2.61156 4.58307 0.200012 8.00044 0.200012Z"
                    fill="#6D858F"
                  />
                </svg>
                View
              </v-list-item-title>
              <v-list-item-title
                @click="actionDriver(item, 'disconnect')"
                class=""
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.6502C4.77491 16.6502 1.34979 13.2251 1.34979 9C1.34979 4.7749 4.77491 1.34979 9 1.34979C13.2251 1.34979 16.6502 4.7749 16.6502 9C16.6502 13.2251 13.2251 16.6502 9 16.6502ZM8.99672 10.3507C9.3384 10.3509 9.62093 10.0972 9.66584 9.76778L9.67206 9.67621L9.6753 4.72554C9.67554 4.35281 9.37358 4.05045 9.00084 4.0502C8.65917 4.04998 8.37663 4.30369 8.33173 4.63308L8.32551 4.72466L8.32227 9.67532C8.32202 10.0481 8.62399 10.3504 8.99672 10.3507ZM9.0004 13.4982C9.49672 13.4982 9.89906 13.0958 9.89906 12.5995C9.89906 12.1032 9.49672 11.7008 9.0004 11.7008C8.50409 11.7008 8.10175 12.1032 8.10175 12.5995C8.10175 13.0958 8.50409 13.4982 9.0004 13.4982Z"
                    fill="#F93131"
                  />
                </svg>
                Disconnect
              </v-list-item-title>
              <v-list-item-title
                v-if="item.status == 1"
                @click="actionDriver(item, 'deactivate')"
              >
                <span>
                  <svg
                    style="position: relative; top: 2px"
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.27128 2.40739C6.6841 2.40739 7.82942 3.56813 7.82942 4.99998C7.82942 6.43183 6.6841 7.59257 5.27128 7.59257C3.85846 7.59257 2.71314 6.43183 2.71314 4.99998C2.71314 3.56813 3.85846 2.40739 5.27128 2.40739ZM6.80616 4.99998C6.80616 4.14087 6.11897 3.44442 5.27128 3.44442C4.42359 3.44442 3.73639 4.14087 3.73639 4.99998C3.73639 5.85909 4.42359 6.55553 5.27128 6.55553C6.11897 6.55553 6.80616 5.85909 6.80616 4.99998Z"
                      fill="#F93131"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.7286 0.333313C13.2717 0.333313 15.3333 2.42265 15.3333 4.99998C15.3333 7.57731 13.2717 9.66665 10.7286 9.66665H5.27128C2.7282 9.66665 0.666626 7.57731 0.666626 4.99998C0.666626 2.42265 2.7282 0.333313 5.27128 0.333313H10.7286ZM14.31 4.99998C14.31 2.99539 12.7066 1.37035 10.7286 1.37035H5.27128C3.29333 1.37035 1.68988 2.99539 1.68988 4.99998C1.68988 7.00457 3.29333 8.62961 5.27128 8.62961H10.7286C12.7066 8.62961 14.31 7.00457 14.31 4.99998Z"
                      fill="#F93131"
                    />
                  </svg>
                </span>
                Deactivate
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="startfetching">
          <v-progress-circular :size="40" color="#0171a1" indeterminate>
          </v-progress-circular>
        </div>
        <div v-else>No data</div>
      </template>
    </v-data-table>

    <!-- <Pagination
      v-if="drivers.length"
      :pageData.sync="page"
      :lengthData.sync="pageCount"
      :isMobile="isMobile"
    /> -->
    <Pagination
      v-if="typeof drivers !== 'undefined' && drivers.length > 0"
      :pageData.sync="page"
      :lengthData.sync="countData"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Pagination from "../../../Pagination.vue";
import Search from "../../../Search.vue";
import globalMethods from "../../../../utils/globalMethods";
import Tab from "./tab.vue";
import axios from "axios";
import moment from "moment";
const poBaseUrl = process.env.VUE_APP_PO_URL;
export default {
  name: "Connected",
  props: [
    "items",
    "isMobile",
    "itemData",
    "reloadcomponent",
    "pageCurrent",
    "startfetching",
    "allcount",
    "bus",
    "countTab",
  ],
  components: {
    Pagination,
    Search,
    Tab,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          sortable: true,
          width: "8%",
          fixed: true,
        },
        {
          text: "Phone Number",
          align: "start",
          value: "phone_number",
          sortable: false,
          width: "6%",
          fixed: true,
        },
        {
          text: "Email",
          align: "start",
          value: "email",
          sortable: false,
          width: "10%",
          fixed: true,
        },
        {
          text: "Status",
          align: "start",
          value: "updated_at",
          sortable: false,
          width: "10%",
          fixed: true,
        },
        {
          text: "",
          align: "start",
          value: "actions",
          sortable: false,
          width: "4%",
          fixed: true,
        },
      ],
      search: "",
    };
  },
  computed: {
    countData: {
      get() {
        return this.allcount;
      },
    },
    drivers: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {
    page(nv) {
      this.$emit("update:page", nv);
    },
  },
  created() {},
  methods: {
    ...mapActions({
      passData: "drivers/passData",
      passLocFrom: "drivers/passLocFrom",
      detailNotification: "drivers/detailNotification",
    }),
    concatlastname(item) {
      return (
        (item.name !== "" && item.name !== null ? item.name : " ") +
        (item.last_name != "" && item.last_name != null ? item.last_name : "")
      );
    },
    actionDriver(item, type) {
      let url = "";
      console.log(item);
      if (type == "activate") {
        url = "activateDriver";
      } else if (type == "deactivate") {
        url = "deactivateDriver";
      } else {
        url = "disconnectDriver";
      }
      axios
        .put(`${poBaseUrl}/` + url + "/" + item.id)
        .then((response) => {
          if (response) {
            this.countTab = response.count;
            this.dispatchNotifcation(
              '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                "Driver has been successfully " +
                type +
                "d" +
                "</span>"
            );
          }
          this.$emit("reloadpage", 0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lastseendate(v) {
      if (v) {
        return (
          "Last seen " +
          moment(v).utc(true).format("MMM Do YYYY") +
          " at " +
          moment(v).utc(true).format("h:mm:ss a")
        );
      }
    },
    clickEmails(items) {
      console.log(items);
      this.$router.push("/drivers-details/" + 2).catch(() => {});
    },
    ...globalMethods,
    currenttab(cur) {
      this.$emit("currentPage", cur);
    },
    async viewDriver(item, id) {
      if (item) {
        await this.passData(item);
        await this.detailNotification(item);
        await this.passLocFrom("connected");
        this.$router.push("/drivers-details/" + id + "/01").catch(() => {});
      }
    },
    editDriver(item, id) {
      this.bus.$emit("changeIt", {
        id: id,
        updatelisting: item,
        editPassdata: id,
        editDriver: item,
      });
    },
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
