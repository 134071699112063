<template>
    <v-dialog 
        persistent 
        v-model="dialog" 
        max-width="550px" 
        content-class="delete-driver-dialog dialogYard"  
        :retain-focus="false"
        >
        <v-card 
          style="padding-bottom:8px">
          <v-card-text 
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >
            <img 
              src="@/assets/icons/Circle Check Outline.svg"
            />
            <h2 
              class="delivery_header"
              style="">DO ID {{deliveryID}} Was Successfully Updated</h2>
          
            <p 
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
              The following containers are created:
            </p>
            <ul 
              style="list-style:disc">
              <li 
                v-for="(item, index) in confirmmessage" 
                :key="index">
                {{item}}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="continueDialog" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Close 
                    </span>
                </span>
            </v-btn>                  
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Showdialogupdatebox',
    data(){
      return {
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A'
      }
    },
    props: ['dialogData',
            'isSaving',
            'deliveryID',
            'shipmentIDS',
            'containersnumber',
            'countshipment',
            'confirmmessage',
            'location'], 
    computed: { 
        dialog: {
            get () {      
              return this.dialogData
            }        
        },                       
    },
    methods: {       
        continueDialog() {             
          this.$emit("confirmDialog",{
            isConfirmed: true                       
          }); 
        }        
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>