<template>
    <div class="
             ssupplier-container 
             chassis-container
             common-container-el"
    >   

    <ChassisDesktopTable
      v-if="renderComponent"
      :isMobile="isMobile"
      @addChassisDialog="addChassisDialog"
      @editChassis="editChassisItem($event)"
      @showDeleteDialog="showDeleteDialog($event)"
    />

    <ChassisDialog 
      @setToDefault="setToDefault" 
      @syncAction="updateddialog" 
      :dialog="dialog" 
      @close="close" 
    />

    <ChassisEditDialog
      @setToDefault="setToDefault"
      :dialogEdit="dialogEdit"
      :editChassisItemList="editChassisItemList"     
      @syncAction="updateddialog"
      @close="close" 
    />

    <ChassisDeleteDialog
      :deleteYardItemList="deleteYardItemList"
      :dialogDelete="dialogDelete"
      @syncAction="updateddialog" 
      @setToDefault="setToDefault"
      @close="close"
    />
  </div>
</template>

<script>
import ChassisDesktopTable from "../components/Tables/Chassis/ChassisDesktopTable.vue";
import ChassisDialog from "../components/ChassisComponents/ChassisDialog.vue";
import ChassisEditDialog from "../components/ChassisComponents/ChassisEditDialog.vue";
import ChassisDeleteDialog from "../components/ChassisComponents/ChassisDeleteDialog.vue";
import globalMethods from '../utils/globalMethods'
export default {
  name: "Chassis",
  components: {
    ChassisDesktopTable,
    ChassisDialog,
    ChassisEditDialog,
    ChassisDeleteDialog,
  },
  data: () => ({
    renderadddialog: false,
    renderComponent: true,
    isMobile: false,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    isdeleted: 0,
    editChassisItemList: {
      number: "",
      chassis_type_id: "",
      type: "",

    },
    deleteYardItemList: "",
  }),
  methods: {
    ...globalMethods,     
    updateddialog(c){ 
      let text = (c.created) ? c.msg : c.msg; 
      if(c.created==3){       
        this.showDeleteNotificationBox('<i  class="ic-delete"></i>&nbsp;'+
                                       '<span style="margin-left:4px;" class="text-message">'+text+'!</span>')                         
      }else{    
        this.dispatchNotifcation('<i style="font-size:11px" class="ic-check-mark"></i>&nbsp;'+
                                 '<span  style="margin-left:4px;" class="text-message">'+text+'!</span>')                         
      }
    },   
    onClickChild(value) {
      console.log(value); // someValue
      this.dai;
    },
    setToDefault() {
      this.close();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogEdit = false;
    },
    editChassisItem(item) {
      this.dialogEdit = true;
      this.editChassisItemList = Object.assign({}, item);
    },
    addChassisDialog() {
      this.dialog = true;
    },
    showDeleteDialog(item) {
      this.deleteYardItemList = item;
      this.dialogDelete = true;
    },
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "chassis");
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages_scss/chassis/chassis.scss";
@import "../assets/scss/pages_scss/chassis/chassis_css.scss";
@import "../assets/scss/buttons.scss";
@import "../assets/css/dialog_styles/dialogHeader.css";
@import "../assets/css/dialog_styles/dialogBody.css";
@import "../assets/css/dialog_styles/dialogFooter.css";
</style>
