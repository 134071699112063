var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"assignUnassignFilter",staticStyle:{"display":"flex","margin-left":"4px"}},[_c('div',{class:{
        'active':
          _vm.pageCurrent.page == 0
      },on:{"click":function($event){return _vm.currenttab(0)}}},[_vm._v(" Connected "),(_vm.isCountTabCount)?_c('strong',{staticClass:"labelnumber"},[_vm._v(_vm._s(_vm.countTab.connected)+" ")]):_vm._e()]),_c('div',{class:{
        'active':
           _vm.pageCurrent.page == 1
      },on:{"click":function($event){return _vm.currenttab(1)}}},[_vm._v(" Not Connected "),(_vm.isCountTabCount)?_c('strong',{staticClass:"labelnumber"},[_vm._v(_vm._s(_vm.countTab.notconnected)+" ")]):_vm._e()]),_c('div',{class:{
        'active':
           _vm.pageCurrent.page == 2
      },on:{"click":function($event){return _vm.currenttab(2)}}},[_vm._v(" Not Active "),(_vm.isCountTabCount)?_c('strong',{staticClass:"labelnumber"},[_vm._v(_vm._s(_vm.countTab.notactive)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }