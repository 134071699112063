<template>
    <v-dialog
        persistent
        v-model="dialog"
        max-width="480px"
        content-class="delete-driver-dialog dialogYard"
        :retain-focus="false"
        >
        <v-card
          style="padding-bottom:8px">
          <v-card-text
            class="pt-5 pb-0 contentbody"
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.5499 0.400391C41.7867 0.400391 43.5999 2.21364 43.5999 4.45039V9.85039C43.5999 11.6131 42.4738 13.1128 40.9018 13.6693L40.8999 34.1504C40.8999 39.3695 36.669 43.6004 31.4499 43.6004H12.5499C7.33081 43.6004 3.0999 39.3695 3.0999 34.1504L3.10069 13.6702C1.52727 13.1145 0.399902 11.6141 0.399902 9.85039V4.45039C0.399902 2.21364 2.21315 0.400391 4.4499 0.400391H39.5499ZM38.1999 13.9004H5.7999V34.1504C5.7999 37.8783 8.82198 40.9004 12.5499 40.9004H31.4499C35.1778 40.9004 38.1999 37.8783 38.1999 34.1504V13.9004ZM17.9499 19.3004H26.0499C26.7955 19.3004 27.3999 19.9048 27.3999 20.6504C27.3999 21.3131 26.9223 21.8643 26.2926 21.9786L26.0499 22.0004H17.9499C17.2043 22.0004 16.5999 21.396 16.5999 20.6504C16.5999 19.9876 17.0775 19.4364 17.7072 19.3221L17.9499 19.3004H26.0499H17.9499ZM39.5499 3.10039H4.4499C3.70432 3.10039 3.0999 3.70481 3.0999 4.45039V9.85039C3.0999 10.596 3.70432 11.2004 4.4499 11.2004H39.5499C40.2955 11.2004 40.8999 10.596 40.8999 9.85039V4.45039C40.8999 3.70481 40.2955 3.10039 39.5499 3.10039Z" fill="#0171A1"/>
            </svg>

            <h2
              class="delivery_header"
              style="">Reopen DO</h2>

            <p
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                Are you sure to Reopen DO #{{items.shifl_ref}}?
            </p>

          </v-card-text>
          <v-card-actions
            style="border-top:0px;"
            >
            <v-btn
                @click="continueDialog"
                class="btn-blue"
                >
                <span>
                    <span>
                       Reopen
                    </span>
                </span>
            </v-btn>
             <v-btn
                @click="clickback"
                class="btn-white"
                >
                <span>
                    <span>
                       Cancel
                    </span>
                </span>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'archivedo',
    data(){
      return {
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
      }
    },
    props: ['dialogData','movetype','items'],
    computed: {
        dialog: {
            get () {
              return this.dialogData
            } ,
            set(value) {
              if (!value) {
                this.$emit("update:dialogData", false);
              } else {
                this.$emit("update:dialogData", true);
              }
            }
        },
    },
    methods: {
        continueDialog() {
          console.log(this.items)
           this.$emit("confirmCancel",{item: this.items, result: 1});
        },
        clickback(){
          this.$emit("close",1);
        }
    },
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}
</style>
