<template>
  <div class="containers-inner-wrapper profile pa-2">
    <edit-company :dialogEdit="dialogEdit" @close="close" />
    <add-user :dialog="addUserDialog" @close="close" />
    <delete-user
      :dialogDelete="setDeleteUserDialog"
      :deleteUserItemList="setUserItemList"
      @close="close"
    />
    <edit-user
      :dialogEdit="editUserDailog"
      :editUserItemList="setUserItemList"
      @close="close"
    />

    <template>
      <v-toolbar height="50">
        <v-tabs 
          class="purchase-order2-tabs filterstabs"
          v-model="testmodel"
          v-if="reload"
        >
          <v-tab v-for="(n, i) in tabs" :key="i" @click="getCurrentTab(i)">
            {{ n }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <div v-if="compomenentReload">
      <div
        v-if="checkwhatpage()==0"
        class="grey lighten-5"
      >
        <v-divider color="#EBF2F5 "></v-divider>
        <v-card class="py-5" elevation="0" tile>
          <div class="px-5 mb-4">
            <div class="d-flex justify-space-between">
              <div>
                <h2 style="font-size: 20px" class="mb-7 mt-3">
                  Company Information
                </h2>
              </div>
              <div>
                <div>
                  <input
                    @click="showEditCompanyProfileDialog()"
                    type="button"
                    style="
                      border: 1px solid #b4cfe0 !important;
                      border-radius: 5px;
                      color: #0171a1;
                      padding: 6px 14px;
                    "
                    value="Edit Profile"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex">
                <div
                  style="width: 8%; height: 100px"
                  class="d-flex align-center pr-6 mt-3 mr-3"
                >
                  <!-- src="http://localhost:8000/api/storage/bBk5vaaWoqlm0h8D6lWcc6vgogZfsms9AxhfZ2u8.png" -->

                  <v-img
                    :src="getCompanyProfile.imageUrlProfile"
                    style="border: 1px solid #d8e7f0; border-radius: 4px"
                    alt="image-log"
                    height="100%"
                    width="100%"
                  />

                  <!-- <v-img
                    style="border: 1px solid #d8e7f0; border-radius: 4px"
                    src="../../../assets/images/companyprofilelogo.svg"
                    height="100%"
                    width="100%"
                    alt="image"
                  /> -->
                </div>
                <div style="width: 10%">
                  <p class="headingTxt">Company Name</p>
                  <p class="headingTxt">Company Key</p>
                  <p class="headingTxt">Email Address</p>
                  <p class="headingTxt">Super Admin</p>
                </div>
                <div style="width: 27%">
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.name || "-----" }}
                  </p>
                  <div style="display: flex">
                    <p class="headingTxtValue">
                      {{ getCompanyProfile.central_customer_key || "-----" }}
                    </p>
                    <button
                      v-if="getCompanyProfile.central_customer_key"
                      style="margin-top: -12px; margin-left: 10px"
                      title="copy"
                      @click="
                        copyToClipBoard(getCompanyProfile.central_customer_key)
                      "
                    >
                      <img
                        class="custom-clipboard"
                        src="@/assets/icons/clipBoard.svg"
                        alt=""
                      />
                    </button>
                  </div>
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.general_email || "-----" }}
                  </p>
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.email || "-----" }}
                  </p>
                </div>
                <div style="width: 8%">
                  <p class="headingTxt">Dot Number</p>
                  <p class="headingTxt">MC Number</p>
                  <p class="headingTxt">Phone Number</p>
                  <p class="headingTxt">Address</p>
                </div>
                <div>
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.dot_number || "-----" }}
                  </p>
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.mc_number || "-----" }}
                  </p>
                  <p class="headingTxtValue">
                    {{ getCompanyProfile.phone_number || "-----" }}
                  </p>
                  <div class="headingTxtValue">
                    <div class="parentDivAddress">
                      {{
                        getCompanyProfile.street_address +
                          " " +
                          getCompanyProfile.city +
                          " " +
                          " " +
                          getCompanyProfile.zip_code || "----"
                      }}
                      <span
                        v-if="getCompanyProfile.street_address"
                        @mouseover="SET_TEXT_ADRESS_HOVER(true)"
                        @mouseleave="SET_TEXT_ADRESS_HOVER(false)"
                        style="color: #1c7da9"
                        >... more</span
                      >
                      <div v-if="getTextAddressHover" class="longAddress">
                        <p>
                          {{ getCompanyProfile.street_address }}
                          {{ getCompanyProfile.city }}
                          {{ getCompanyProfile.state }}
                          {{ getCompanyProfile.zip_code }}
                          {{ getCompanyProfile.country_code }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-divider class="mt-5" color="#EBF2F5 "></v-divider>
            <div>
              <div style="margin: 20px" class="d-flex justify-space-between">
                <div>
                  <input
                    type="button"
                    :class="{ 'active-user-grp': usersActiveTable }"
                    class="user-btn left-btn"
                    value="Users"
                    @click="showUsersTable"
                  />
                  <input
                    :class="{ 'active-user-grp': !usersActiveTable }"
                    type="button"
                    class="user-btn right-btn"
                    value="Users Groups"
                  />
                </div>
                <div class="d-flex justify-content-space-around">
                  <div>
                    <Search placeholder="Search Group" />
                  </div>
                  <v-btn
                    @click="showAddUserDialog"
                    style="margin-left: 20px"
                    color="primary"
                    class="btn-blue manage-payment-methods-button"
                  >
                    <span class="btn-name-manage">Add User</span>
                  </v-btn>
                </div>
              </div>
              <v-divider class="mt-10" color="#EBF2F5 "></v-divider>

              <v-data-table
                v-if="usersActiveTable"
                :headers="getHeaders"
                :items="getUserWithRoleAssigned"
                hide-default-footer
                disable-sort
                fixed-header
              >
                <template
                  v-slot:[`item.name`]="{ item }"
                  style="position: fixed"
                >
                  <div class="d-flex pt-2">
                    <p class="table-txt">{{ item.name }}</p>
                    <div
                      style="position: relative"
                      v-if="JSON.parse(getUser).email == item.email"
                    >
                      <i
                        @mouseover="SET_USER_MORE_INFO_HOVER(true)"
                        @mouseleave="SET_USER_MORE_INFO_HOVER(false)"
                        style="
                          font-size: 16px;
                          color: #0084bd;
                          position: relative;
                        "
                        class="ic-info mt-4 ml-4"
                      ></i>
                      <div class="d-flex" v-if="getUserMoreInfoHover">
                        <div
                          style="
                            position: absolute;
                            top: 4px;
                            left: 30px;
                            width: 0;
                            height: 0;
                            border-top: 6px solid transparent;
                            border-right: 12px solid #4a4a4a;
                            border-bottom-left-radius: 8px;
                            border-bottom: 6px solid transparent;
                          "
                        />
                        <div class="txt-show d-flex justify-center">
                          <p class="mt-1 white--text">Current User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template
                  v-slot:[`item.userRole`]="{ item }"
                  style="position: fixed"
                >
                  <div>
                    <p>
                      {{
                        item.roles.length > 0
                          ? item.roles.length > 1
                            ? item.roles[0].name + " , " + item.roles[1].name
                            : item.roles[0].name
                          : "---"
                      }}
                    </p>
                  </div>
                </template>
                <template
                  v-slot:[`item.created_at`]="{ item }"
                  style="position: fixed"
                >
                  <div class="d-flexs justify-center">
                    <p>
                      {{
                        new Date(item.created_at).toLocaleString("en-us", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </p>
                  </div>
                </template>
                <template
                  v-slot:[`item.updated_at`]="{ item }"
                  style="position: fixed"
                >
                  <div class="d-flexs justify-center">
                    <p>
                      {{
                        new Date(item.updated_at).toLocaleString("en-us", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </p>
                  </div>
                </template>
                <!-- <template
                  v-slot:[`item.actions`]="{ item }"
                  style="position: fixed"
                >
                  <div class="d-flexs justify-center">
                    <v-btn
                      small
                      width="55"
                      style="margin-right: 10px; min-height: 30px !important"
                      @click="showEditUserDailog(item)"
                      class="truckerMoreBtn"
                    >
                      <img
                        class=""
                        src="../../../assets/icons/yards_edit.svg"
                        alt=""
                      />
                    </v-btn>
                    <v-btn
                      @click="showDeleteUserDialog(item)"
                      small
                      class="truckerMoreBtn"
                    >
                      <img src="../../../assets/icons/Delete.svg" alt="" />
                    </v-btn>
                  </div>
                </template> -->
              </v-data-table>
              <v-data-table
                v-else
                :headers="getHeadersUserGroup"
                :items="itemsUserGroup"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.actions`] style="position: fixed">
                  <div class="d-flexs justify-center">
                    <v-btn
                      small
                      width="55"
                      style="margin-right: 10px; min-height: 30px !important"
                      class="truckerMoreBtn"
                    >
                      <img
                        class=""
                        src="../../../assets/icons/visibility.svg"
                        alt=""
                      />
                    </v-btn>
                    <v-btn small class="truckerMoreBtn">
                      <img src="../../../assets/icons/yards_edit.svg" alt="" />
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
          <v-divider color="#EBF2F5"></v-divider>
        </v-card>
      </div>

      <div v-if="checkwhatpage() == 2">
        <Integrations />
      </div>
      <div v-if="checkwhatpage() == 1">
        <Notifications :checksaving.sync="checksaving" ref="noti" />
      </div>
      </div>
    </template>
    
  </div>
 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Search from "../../Search.vue";
import EditCompany from "./Dialog/CompanyProfile/EditCompany.vue";
import AddUser from "./Dialog/CompanyProfile/AddUser.vue";
import DeleteUser from "./Dialog/CompanyProfile/DeleteUser.vue";
import EditUser from "./Dialog/CompanyProfile/EditUser.vue";
import Integrations from "./Integrations.vue";
import Notifications from "./Notifications.vue";

export default {
  components: {
    Search,
    EditCompany,
    AddUser,
    DeleteUser,
    EditUser,
    Integrations,
    Notifications,
  },
  props: ['isDiscard','lastidchoosen','lasttabchanges'],
  watch:{ 
    // add watcher here for discard then reload the tabs to display the last tab
    lasttabchanges(c){
      this.reload = 0;
        this.$nextTick(() => { 
            this.reload = 1;
            this.thepage = c;
        }); 
    },    
  },
  data() {
    return {
      compomenentReload:1,
      isApplied:0,
      discardChanges:0,
      thepage:0,
      reload:1,
      checksaving:0,
      setUserItemList: {},
      setDeleteUserDialog: false,
      editUserDailog: false,
      addUserDialog: false,
      showEditUserDialog: false,
      dialogEdit: false,
      activeTab: 0,
      currentSelectTab: 0,
      usersActiveTable: true,
      activeGroupClass: false,
      image: "",
      showStatic: true,
      // image: require("@/assets/icons/clipBoard.svg"),
      tabs: ["Company Profile", "Notifications", "Integrations"],
      itemsUserGroup: [
        {
          userGroup: "Frozen Doe",
          noOfUsers: "2",
          users: "Asim",
          permission: "All Permission",
        },
        {
          user: "Alexa Jackson",
          noOfUsers: "5",
          userGroup: "Manager",
          users: "Asim",
          permission: "Manager",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("profile", [
      "getCompanyProfileImage",
      "getShowStatic",
      "getCompanyProfile",
      "getTextAddressHover",
      "getHeaders",
      "getHeadersUserGroup",
      "getUserMoreInfoHover",
      "getUserWithRoleAssigned",
    ]),
    ...mapGetters(["getUser"]),
     testmodel:{    
     get() {
         return this.thepage;       
      },
      set(value) {
        if(value) return [];
      },
    },
  },
  mounted(){
    //alert(this.$store.state.page.currentSettingsTab +'teeee')
  },
  methods: {
    ...mapMutations("profile", [
      "SET_TEXT_ADRESS_HOVER",
      "SET_USER_MORE_INFO_HOVER",
    ]),
    ...mapActions("profile", ["getCompanyUsersFromApi"]),
    checkwhatpage(){      
      let hachange;      
      if(this.$store.state.page.thecurrentmenuitem){
        if(typeof this.$refs.noti !='undefined'){
          hachange = this.$refs.noti.getstatus(); 
          if(hachange && this.$store.state.page.currentSettingsTab !== 1 && this.isDiscard == 0){
            this.$emit('checkifhaschanges',{pageid:1,status:1});
            this.thepage = 1
          } else{
            this.thepage = this.$store.state.page.currentSettingsTab
          }  
        }else{
           this.thepage = this.$store.state.page.currentSettingsTab
        }       
      }       
      return this.thepage;
    },
    getCurrentTab(id) {
      this.currentSelectTab = id;
      let hachange = 0;
      if(typeof this.$refs.noti !='undefined'){
        hachange = this.$refs.noti.getstatus();      
      }
     
      this.$store.dispatch("page/setCurrentSettingsTab", id);
      this.$store.dispatch("page/setAccessMenuitem",0); // reset if the tab is clicked
      this.$store.state.page.thecurrentmenuitem
      let pathData =
        typeof this.$router.history.current.query.tab !== "undefined"
          ? this.$router.history.current.query.tab
          : null;

      if (id == 0 && pathData !== null && pathData !== "company-profile") {
        this.$router.push("?tab=company-profile");
      }
      // if (id == 1 && pathData !== null && pathData !== "notifications") {
      // 	this.$router.push("?tab=notifications");
      // }
      if (id == 1 && pathData !== null && pathData !== "notifications") {
        this.$router.push("?tab=notifications");
      }
      if (id == 2 && pathData !== null && pathData !== "integrations") {
        this.$router.push("?tab=integrations");
      }
      
      // IF HAS CHANGES AND IS DISCARDED
      if(hachange==1 && this.isDiscard == 0){ 
        this.reload = 0;
        this.$nextTick(() => { 
            this.reload = 1;
            this.thepage = 1;           
            this.$emit('checkifhaschanges',{pageid: this.currentSelectTab,status:1});          
        });   
        return 1;     
      }else{
         if(this.isDiscard){
            if(this.isApplied){
              this.thepage = id            
            }else{              
              this.isApplied = 1;
              this.thepage = this.lastidchoosen;
              this.compomenentReload = 0;
              this.$nextTick(() => { 
                  this.reload = 1;
                  this.compomenentReload = 1;
                  this.thepage =  this.currentSelectTab; 
              }); 
            }           
         }else{
            this.thepage = id; 
         }
      }
      this.$emit("tabs",id)
      //  this.thepage = id; 
    },
    showUserGroupTable() {
      if (this.usersActiveTable) {
        this.usersActiveTable = false;
      }
    },
    showUsersTable() {
      if (!this.usersActiveTable) {
        this.getCompanyUsersFromApi();
        this.usersActiveTable = true;
      }
    },
    showEditCompanyProfileDialog() {
      this.dialogEdit = true;
    },
    showAddUserDialog() {
      this.addUserDialog = true;
    },
    showDeleteUserDialog(item) {
      this.setUserItemList = item;
      this.setDeleteUserDialog = true;
    },
    showEditUserDailog(item) {
      this.setUserItemList = Object.assign({}, item);
      this.editUserDailog = true;
    },
    close() {
      this.dialogEdit = false;
      this.addUserDialog = false;
      this.setDeleteUserDialog = false;
      this.editUserDailog = false;
    },
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          console.log(text);
        },
        () => {
          alert("error while copying to clipboard");
        }
      );
    },
    check() {
      const tt = JSON.parse(this.getUser);
      console.log(this.getUser);

      console.log(tt.id);
    },
  },

  created() {
    this.getCompanyUsersFromApi();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/containers/containersTable.scss";
@import "../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../assets/scss/pages_scss/containers/containers.scss";
@import "../../../assets/scss/buttons.scss";
.parentDivAddress {
  position: relative;
}
.longAddress {
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  background-color: #354349;
  color: #fff;
  font-weight: 600;
  z-index: 4;
}
.custom-clipboard {
  color: #0171a1;
}
.containers-inner-wrapper.profile span {
  font-size: 14px !important;
}
.v-tab {
  font-size: 14px !important;
  padding: 14px 12px;
  text-transform: capitalize !important;
  letter-spacing: 0;
  font-family: Inter-SemiBold, sans-serif;
  color: #4a4a4a !important;
  min-width: 10px;
  letter-spacing: 0px !important;
}
.v-tab:hover {
  background-color: transparent !important;
}
.v-tab--active {
  color: #0171a1 !important;
}
.headingTxt {
  color: #819fb2;
  // font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.headingTxtValue {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
}
.user-btn {
  padding: 8px;
  color: #0171a1;
}
.left-btn {
  border: 1px solid #b4cfe0;
  border-left-width: 1px;
  border-radius: 4px 0px 0px 4px;
}
.right-btn {
  border: 1px solid #b4cfe0;
  border-left-width: 1px;
  border-radius: 0px 4px 4px 0px;
}
.active-user-grp {
  background-color: #ebf2f5;
}
.search {
  width: 350px;
  margin-left: 200px;
}
.table-txt {
  font-weight: 400;
  size: 14px;
  line-height: 20px;
}
.txt-show {
  position: absolute;
  background: #4a4a4a;
  top: -5px;
  left: 40px;
  padding: 24px, 24px, 16px, 24px;
  height: 30px;
  width: 100px;
  border-radius: 4px;
}
</style>
