<template>
    <div
        class="container-create">
        <v-toolbar
            class="toolbHeader"
            flat>
            <v-toolbar-title
                style="overflow:visible !important">
                <span
                    class="NewContainerShipmentTitle"
                    >
                    New Container Shipment
                </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
                <v-btn
                    :class="noerror == 1 ? 'btn-blue submitNow' : 'btn-white saveCreateBtn' "
                    depressed
                    @click="clicsubmit('save')"
                    color="primary"
                    :disabled="noerrorForm"
                    >
                    Save Changes
                </v-btn>
                <!-- <v-btn
                    class="btn-white saveAndAnother"
                    depressed
                    @click="clicsubmit('saveAndAnother')"
                    color="primary"
                    >
                    Save & Add Another
                </v-btn> -->
                <v-btn
                    style="margin-left:8px"
                    class="btn-white createCancelBtn"
                    depressed
                    @click="cancel"
                    color="primary">
                    Cancel
                </v-btn>
        </v-toolbar>
        <v-tabs
            class="tabstop"
            v-model="tab"
            background-color="transparent"
            >
            <v-tab
                v-for="(item,index) in tabsNavigation"
                :key="item"
                @click="tabsnavigationClick(index)"
                >
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-form
            class="sFormClass"
            style="padding:0px !important; border-top:1px solid #EBF2F5"
            id="sform"
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            >
        <v-row
            class="clRow">
            <v-col
                class="leftcol"
                md="8"
                >
            <v-row>
            <v-col
                class="etacol"
                cols="6"
                md="6"
                >
                <small>ETA</small>
                <!-- <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                placeholder="05/21/2021"
                                append-icon="ic-calendar"
                                readonly
                                name="ETA"
                                v-bind="attrs"
                                v-on="on">
                            </v-text-field>
                        </template>
                    <v-date-picker
                        v-model="date"
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                            >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog> -->
                <v-text-field
                    v-model="date"
                    placeholder="05/21/2021"
                    append-icon="ic-calendar"                    
                    name="ETA"
                    :rules="validateDaterules"
                    @click:append="showcustom(date,'date')" 
                >
                </v-text-field>
            </v-col>
            <v-col
                class="selectBox"
                cols="6"
                sm="6"
                >
                <small>CUSTOMER</small>
                <v-autocomplete
                    v-model="customer"
                    :items="itemsDropdownComputed"
                    :rules="customerRules"
                    item-text= 'company_name'
                    item-value='id'
                    placeholder="Select Customer"
                    solo
                    @mousedown="dataDown('customer')"
                    @change="getmeselected"
                    append-icon="ic-chevron-down"
                    >
                </v-autocomplete>
                <input
                    type="hidden"
                    name="customer_id"
                    :value="customerSelected"
                    >
            </v-col>
        </v-row>
        <v-row
            style="margin-top: 0px;">
            <v-col
                class="selectBox"
                cols="12"
                md="12"
                style="padding-top:3px"
                >
            <label
                class="text-item-label">
                Contacts
            </label>

            <div
                class="tags-email-wrapper mb-1">
                <vue-tags-input
                    hide-details="auto"
                    :rules="arrayNotEmptyRules"
                    :tags="options"
                    :add-on-blur=true
                    :class="myclass"
                    class="DialogInputfield"
                    :add-on-key="[13, ',']"
                    :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                    v-model="warehouseEmailAddress"
                    placeholder="Enter email address"

                    @tags-changed="newTags => {
                        this.myclass = 'noclass';
                        this.options = newTags
                        this.tagsInput.touched = true
                        this.tagsInput.hasError = (this.options.length > 0) ? false : true
                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                        if (typeof el!=='udnefined') {
                            /*if (this.tagsInput.hasError)
                                el.classList.add('ti-new-tag-input-error')
                            else
                                el.classList.remove('ti-new-tag-input-error')*/
                        }
                    }"
                    />
            </div>

            <div
                v-if="tagsInput.touched"
                class="v-text-field__details">
                <div
                    class="v-messages theme--light error--text"
                    role="alert">
                    <div
                        class="v-messages__wrapper">
                        <div
                            v-if="(options.length > 0) && warehouseEmailAddress!==''"
                            class="v-messages__message">
                            {{
                                tagsInput.errorMessage
                            }}
                        </div>

                        <div
                            v-if="options.length == 0 && warehouseEmailAddress!==''"
                            class="v-messages__message">
                            {{
                                tagsInput.errorMessage
                            }}
                        </div>
                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                            Please provide at least 1 valid email address.
                        </div> -->
                    </div>
                </div>
            </div>
            <span
                style="color: #819FB2; font-size: 12px;">
                <i style="font-size:12px;color:#819FB2">
                    Separate multiple email addresses with comma
                </i>
            </span>
        </v-col>
        </v-row>
        <v-row
            class="notesrow"
            style="margin-top:14px"
            >
            <v-col
                class="textareanotes"
                cols="12"
                md="12"
                >
                <small>Notes (Optional)</small>
                <v-textarea
                    v-model="notembl"
                    :rules="noterules"
                    placeholder="Type Note"
                    rows="2"
                    name="custom_notes"
                    >
                </v-textarea>
            </v-col>
        </v-row>
        <v-row
            class="mt-0"
            >
        <v-col
            cols="6"
            md="6"
            class="selectBox"
            >
            <small>Carrier</small>
             <v-autocomplete
                v-model="CarrierModel"

                :items="carrierDropdown"
                item-text='name'
                item-value='id'
                placeholder="Select Carrier"
                name="carrier_id"
                solo
                @mousedown="dataDown('CARRIER')"
                @change="getmeselected"
                append-icon="ic-chevron-down"
                >
            </v-autocomplete>
        </v-col>
        <v-col
            cols="6"
            md="6"
            >
            <small>Vessel</small>
            <v-text-field
                placeholder="Vessel"
                required
                v-model="VesselModel"

                name="vessel_name"
                hide-details="auto"
                @keyup="blurevent"
                >
            </v-text-field>
        </v-col>
        </v-row>
        <v-row
            class="mt-0"
            >
            <v-col
                cols="6"
                md="6"
                >
                <small>MBL #</small>
                <v-text-field
                    placeholder="Enter MBL #"
                    required
                    v-model="mbl"
                    :rules="mblRules"
                    name="mbl_num"
                    hide-details="auto"
                    @keyup="blurevent"
                    >
                </v-text-field>
            </v-col>
            <v-col
                cols="6"
                md="6"
                >
                <small>CONTAINER #</small>
                <v-text-field
                    placeholder="Enter Container Number"
                    required
                    v-model="containerNumber"
                    :rules="containerNumberRules"
                    name="trucker_container"
                    hide-details="auto"
                    @keyup="blurevent"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            class="rowRemovemargin">
            <v-col
                class="selectBox"
                cols="6"
                md="6"
                >
                <small>TERMINAL AT</small>
                <v-autocomplete
                    v-model="terminalAt"
                    :rules="TerminalAtRules"
                    :items="terminalsDropdown"
                    item-text= 'name'
                    item-value='id'
                    placeholder="Select Terminal"
                    solo
                    @mousedown="dataDown('TERMINAL AT')"
                    @change="getmeselected"
                    append-icon="ic-chevron-down"
                    >
                </v-autocomplete>
                <input
                    type="hidden"
                    name="terminal_id"
                    :value="terminalatSelected"
                    >
            </v-col>
            <v-col
                class="selectBox"
                cols="6"
                md="6"
                >
                <small>CONTAINER SIZE</small>
                <v-autocomplete
                    v-model="containerSizeModel"
                    :items="sizeDropdown"
                    :rules="containersizeRules"
                    item-text= 'name'
                    item-value='id'
                    placeholder="Select Size"
                    solo
                    @mousedown="dataDown('CONTAINER SIZE')"
                    @change="getmeselected"
                    append-icon="ic-chevron-down"
                    >
                </v-autocomplete>
                <input
                    type="hidden"
                    name="container_size_id"
                    :value="containersizeSelected"
                    >
            </v-col>
        </v-row>
        <v-row
            class="notesrow"
            >
            <v-col
                cols="6"
                md="6"
                >
                <small>DELIVER TO</small>
                <v-text-field
                    v-model="deliverTo"
                    :rules="delivertoRules"
                    placeholder="Deliver To"
                    required
                    name="deliver_to"
                    hide-details="auto"
                    @keyup="blurevent"
                    >
                </v-text-field>
            </v-col>
            <v-col
                cols="6"
                md="6"
                >
                <small>WEIGHT</small>
                <v-text-field
                    required
                    placeholder="KG"
                    v-model="weight"
                    :rules="weightRules"
                    name="wieght"
                    hide-details="auto"
                    @keyup="blurevent"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="6"
                md="6"
                >
                <small>COMMODITY</small>
                <v-text-field
                    placeholder="Type Commodity Details"
                    required
                    name="container_commodity"
                    hide-details="auto"
                    v-model="comodity"
                    >
                </v-text-field>
            </v-col>
            <v-col
                cols="6"
                md="6"
                >
                <!-- <small>CUSTOMER PO#</small> -->
                <small> Customer Ref#</small>
                <v-text-field
                    placeholder="Enter PO #"
                    required
                    v-model="customerpoNumber"
                    
                    name="po_num"
                    @keyup="blurevent"
                    hide-details="auto"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            class="notesrow mt-0">
        </v-row>
        </v-col>
            <v-col
                md="4"
                class="uploadDocuwrapper"
                >
                <div
                    class="deliveryOrderColumn"
                    >
                    <div
                        style="padding-bottom:7px; color:#4A4A4A;font-size:20px; font-weight:600"
                        >
                        Delivery Order
                        <span
                            style="float:right;font-size:14px !important;color:#0171A1;cursor:pointer"
                            v-if="uploadTrigger"
                            @click="uploadAgain"
                            class="reupload"
                            >
                        <img class="uploadCreateNew" src="../../../../assets/icons/uploadnew.svg">
                        Reupload
                        </span>
                    </div>

                <span
                    style="color:red;font-size:12px;margin-left:5px"
                    v-if="errorFile">
                    Invalid File Type
                </span>
                <span
                    style="display:none;"
                    class="fileuploaderror"
                    >
                </span>
                <div
                    style="display:block"
                    class="uploadElement flex w-full h-screen items-left justify-left text-left"
                    id="app"
                    >

                    <div
                        style="width:100%"
                        :class="hasContent"
                        xclass="cDownloadfiles"
                        @dragover="dragover"
                        @dragleave="dragleave"
                        @drop="drop"

                        >
                        <input
                            style="display:none"
                            type="file"
                            name="fields[]"
                            id="assetsFieldHandle"
                            class="w-px h-px opacity-0 overflow-hidden absolute"
                            @change="onChange"
                            ref="file"
                            accept="application/pdf"
                            >

                            <label
                                for="assetsFieldHandle"
                                class="block cursor-pointer triggerelement"
                                >
                                <div
                                    class="browserInput dragfileElement">
                                    <div
                                        class="browseFile"
                                        >
                                        Browse Or Drop File
                                    </div>
                                    <div class="btn-white2 browseButton">
                                        <div class="wrappIconbutton">
                                            <span class="iconUploadfile"><i class="ic-upload"></i></span>
                                            <span class="classUpload">Upload</span>
                                        </div>
                                    </div>
                                </div>
                            </label>

                    </div>
                    <div
                        class="flex w-full h-screen items-left justify-left text-left"
                        style="width:100%"
                        >
                        <ul
                            style="width:100%"
                            class="mt-4 displayItem"
                            v-if="this.filelist.length"
                            v-cloak
                            >
                            <li
                                class="text-sm p-1 mb-3"
                                v-for="(file,index) in filelist "
                                :key="index"
                                >
                                <div
                                    style="display:flex"
                                    class="rowItemUploaded"
                                    >
                                    <span
                                        class="IconUpload"
                                        style="background:#fff;border-radius:30px"
                                        >
                                    <img
                                        class="imgIcon" src="../../../../assets/icons/documentFile.svg"
                                        >
                                    </span>
                                    <div
                                        style="display:flex"
                                        class="mainwrapitem"
                                        >
                                        <div class="fileNameList"> {{file.name}}</div>
                                        <!-- <div
                                            class="fileSize">[ {{ (file.size / 1024).toFixed(2) }} kb <span>.</span> pdf ]
                                            <button
                                                class="ml-2"
                                                type="button"
                                                @click="remove(filelist.indexOf(file) , objectServerid)"
                                                title="Remove file"
                                                >
                                                <i
                                                    style="color:#212121"
                                                    class="ic-delete"
                                                    >
                                                </i>
                                            </button>
                                        </div> -->
                                        <div style="margin-top:5px">
                                            <v-progress-circular
                                                :size="20"
                                                color="#0775A3"
                                                indeterminate
                                                v-if="!uploadCompleted"
                                                >
                                            </v-progress-circular>
                                        </div>
                                        <div
                                            class="fileSize"
                                            v-if="uploadCompleted"
                                            >
                                            <span
                                                @click="clickDownload">
                                                <img style="cursor:pointer" src="../../../../assets/icons/downloadBlue.svg">
                                            </span>
                                            <button
                                                class="ml-2"
                                                type="button"
                                                @click="remove(filelist.indexOf(file) , objectServerid)"
                                                title="Remove file"
                                                >
                                                <i
                                                    style="color:red"
                                                    class="ic-delete"
                                                    >
                                                </i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
                <div
                    class="milestone-create-container">
                    <h2>Milestone</h2>
                    <div
                        v-for="(category,index) in types"
                        :key="types[index].text"
                        xs6
                        >
                        <v-checkbox
                            :color="checkboxColor"
                            :value="category.value"
                            hide-details
                            :on-icon="'ic-checkbox-checked'"
                            :off-icon="'ic-checkbox-empty'"
                            :ripple="false"
                            v-model="selectedmilestone"
                            :label="category.text"
                            @change="blurevent"
                            >
                        </v-checkbox>
                    </div>
                </div>
            </v-col>
        </v-row>
        </v-form>
         <CreatePicker                       
        :dialogDatepicker="dialogDatepicker" 
        :defaultData ="pickerDefaultdata"   
        @passbackdata="getdata"
        @close="closeDialogpicker"                       
      />
    </div>
</template>
<script>
import axios from "axios";
import {mapGetters,mapActions } from 'vuex'
const poBaseUrl = process.env.VUE_APP_PO_URL
import globalMethods from '../../../../utils/globalMethods'
import VueTagsInput from '@johmun/vue-tags-input';
import jQuery from 'jquery'
export default {
    name: "create_containers",
    props: ['items','customers','terminals','ContainerSize','carriers'],
    components: {
        VueTagsInput
    },
    data: () => ({
        pickerDefaultdata:'',
        currentstatepicker:'',
        dialogDatepicker:false,
        uploadCompleted: true,
        uploadTrigger:false,
        noerrorForm:true,
        contactsModel:'',
        CarrierModel:'',
        VesselModel:'',
        responseDatatempt:'',
        serverObject:[],
        objectServerid:'',
        hasContent:'hasContentFiles',
        filelist:[],
        errorFile: false,
        comodity: '',
        resetform : false,
        shipmentmsg: 'The shipment was created!',
        Truckers:false,
        noerror: false,
        testsubmit:'',
        formFieldlength:0,
        startloading: 1,
        clickOrigin:'',
        clicksubmit: false,
        submitisclick:false,
        selectedmilestone:[],
        customer: [],
        mbl: '',
        containerNumber : '',
        terminalAt : [],
        CarrierRules:[
            v => !!v || 'Customer is required',
        ],
        VesselRules:[
            v => !!v || 'Customer is required',
        ],
        customerRules: [
            v => !!v || 'Customer is required',
        ],
        mblRules: [
            v => !!v || 'Mbl# is required',
        ],
        containerNumberRules: [(text)=>{      
            if(text=='') return 'Container is required.';          
            let msg   = 'The first 4 characters are alpha and the rest are digits.';
            if(text.length > 3 && text.length <= 10 ){
                if(/^[a-zA-Z]*$/.test(text.substring(0,4)) 
                && /^[0-9]*$/.test(text.substring(4,text.length))){
                    return true
                }
                return msg                      
            }
            return 'More than 3 or equal to 10 characters long.'   
        }],
        notembl: '',
        noterules:[
            v => v.length < 200 || 'Should not exceeds to 200 allowed characters.',
        ],
        TerminalAtRules: [
            v => !!v || 'Terminal At is required',
        ],
        containerSizeModel:[],
        containersize: '',
        containersizeRules: [
            v => !!v || 'Container size is required',
        ],
        deliverTo: '',
        delivertoRules:[
            v => !!v || 'Deliver to is required',
        ],
        weight: '',
        weightRules:[
            v => !!v || 'Weight is required',
        ],
        customerpoNumber:'',
        customerpoNumberRules:[
            v => !!v || 'Customer PO# is required',
        ],
        containerTypeField:'',
        containerTypeRules:[
            v => !!v || 'Container Type is required',
        ],
        truckersField:'',
        truckersRules:[
            v => !!v || 'Truckers is required',
        ],
        hiddenmilestone:'',
        hiddenmilestoneRules:[
            v => !!v || 'Please select milestone',
        ],
        select: '',
        customerSelected:'',
        containertypeSelected:'',
        terminalatSelected:'',
        containersizeSelected:'',
        currentDropdownState:'',
        trucker_idSelected: '',
        valid:true,
        currentComboState:'',
        tabscurrent:0,
        tab: null,
        tabsNavigation: [
          'Header Info',
        ],
        //Pier Pass Paid change to Pre-Gate Fees
        types: [
            { text: 'Fully Released', value: 'Fully Released' , selected : false },
            { text: 'Pre-Gate Fees', value: 'Pre-Gate Fees' , selected : false  },
            { text: 'Discharged', value: 'Discharged' , selected : false },
            { text: 'Container Empty', value: 'Container Empty' , selected : false  },
            { text: 'Billed', value: 'Billed' , selected : false },
            { text: 'Cancelled', value: 'Cancelled' , selected : false },
      ],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        values: [
                {id:'1',name:'Name 1'},
                {id:'2', name:'Name 2'},
                {id:'3', name:'Name 3'},
        ],
        menu: false,
        modal: false,
        menu2: false,
        checkboxColor: '#0171A1',
        container_type: '',
        select_terminal_at:'',
        itemsDropdown: [],
        dialog: false,
		search: "",
        searchData: '',
        selectedTasks: [],
        containerType: [],
        containerSize: [],
        TERMINAL_AT: [],
        ex4: [],
        truckers: [],
        newdata:[],
        myclass: '',
        options: [],
        documentProto: document,
        warehouseEmailAddress:'',
         tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        tagsValidation: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        arrayNotEmptyRules: [
            //(v) => !!v || "Email is required",
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        tagsValidation3: [
            {
            classes: 'ttt-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
         validateDaterules: [
          (v) => 
            {
              if(v==='') return "This field is required"
              if(!this.validateDate(v)){
                return "Invalid Date ex: YYYY-mm-dd"
              } return true
            }          
          ],
    }),
    watch: {
        items: function(newVal){
          this.getPrelims(newVal)
        },
        filelist(c){
           if(c.length > 0){
               this.callUPdocument(c);
           }

           if(c.length == 0)
               this.hasContent = 'hasContentFiles'
           else
               this.hasContent = 'cDownloadfiles hidethisdiv'
        }
	},
    computed: {
        ...mapGetters({
            getCreateloading: 'containers/getPoLoading',
            formdetails: 'containers/getcontainerFormDetails',
            getPoLoading: 'containers/getPoLoading',
        }),
        itemsDropdownComputed:{
            get(){
                return this.customers
            }
        },
        terminalsDropdown:{
            get(){
                return this.terminals
            }
        },
        carrierDropdown:{
            get(){
                return this.carriers
            }
        },
        sizeDropdown:{
            get(){
                return this.ContainerSize
            }
        },
        headers(){
          return   [
            {
                text: "REFERENCE",
                align: "left",
                sortable: false,
                value: "REF",
                width: "8%",
                fixed: true
            },
            {
                text: "CUSTOMER",
                align: "start",
                sortable: false,
                value: "CUSTOMER",
                width: "13%",
                fixed: true
            },
            {
                text: "CONTAINER #",
                align: "start",
                sortable: false,
                value: "CONTAINER",
                width: "12%",
                fixed: true
            },
            {
                text: "ETA",
                align: "start",
                sortable: true,
                value: "ETA",
                width: "12%",
                fixed: true
            },
            {
                text: "TERMINAL AT",
                align: "start",
                sortable: true,
                value: "TERMINAL_AT",
                width: "20%",
                fixed: true
            },
            {
                text: "DELIVERY TO",
                align: "start",
                sortable: true,
                value: "DELIVERY_TO",
                width: "20%",
                fixed: true
            },
        ]
        },
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        billingsData: {
            get() {
                return this.items
            },
            set(value) {
                this.$emit('update:items', value)
            }
        },

        porchaseItems(){
          return this.tabOne()
        },
        // reserved for the api
        callPOapi() {
            let data = this.billingsData.filter((billing) =>
                billing.status === this.tabs[this.activeTab]
            )
            return data
        },
    },

    methods: {
        clickDownload(){
            this.gotoDownload();
        },
        uploadAgain(){
            jQuery(document).find('.triggerelement').trigger('click');
        },
        timetopass(){
            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                    return o.text
                }) : []
                return finalEmailAddress;
        },
        ...mapActions({
			fetchFormdetails: 'containers/fetchallformdetails'
        }),
        ...globalMethods,
        blurevent(){
           this.checkobjects()
        },
        checkobjects(){
            if(Object.keys(this.mbl).length !== 0  &&
            Object.keys(this.containerNumber).length !== 0 &&
            Object.keys(this.containerNumber).length !== 0 &&
            Object.keys(this.containerNumber).length !== 0 &&
            Object.keys(this.containerNumber).length !== 0 &&
            Object.keys(this.containerNumber).length !== 0 &&
            Object.keys(this.deliverTo).length !== 0 &&
            Object.keys(this.weight).length !== 0 &&
            Object.keys(this.customerpoNumber).length !== 0)
            {
                this.noerror = 1
                this.noerrorForm = false;
            }else{
                this.noerror = 0
                this.noerrorForm = true;
            }
        },

        saveandcreate(){
            this.$emit('saveandanother')
        },
        changemilestone(){
            this.selectedmilestone = true;
        },
        clicsubmit(origin){

            if(this.selectedmilestone.length == 0){
               this.submitisclick = true;
            }

            //if( this.$refs.form.validate() && this.selectedmilestone.length > 0){
            if( this.$refs.form.validate() ){
                this.startloading = 1;
                let formData = new FormData(document.getElementById('sform'))
                    formData.append('milestone',this.selectedmilestone);
                    formData.append('contacts[]',this.timetopass());
                    axios.post(`${poBaseUrl}/containers-save`,formData)
                    .then((response) => {
                        return response.data
                    }).catch(error => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                    }).then(response => {
                        this.startloading =0;
                        if(response){
                           this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">'+this.shipmentmsg+'</span>')
                            if(origin=='save'){
                                this.formReset();
                            }else{
                                this.filelist = [];
                                this.$refs['form'].resetValidation()
                                this.mbl = '';
                                this.customer = [];
                                this.containerNumber = ''
                                this.containerSizeModel = []
                                this.deliverTo = '';
                                this.weight= '';
                                this.customerpoNumber = '';
                                this.terminalAt = [];
                                this.comodity = '';
                            }
                        }
                    })
            }
        },
        cancel() {
             this.$router.push('/containers')
        },
        formReset(){
             this.$router.push('/containers')
        },
        showcustom(state,m){
            this.pickerDefaultdata = state;
            this.currentstatepicker= m;
            this.dialogDatepicker  = true;
        },
        closeDialogpicker(){
            this.dialogDatepicker = false;
        },
        getdata(item){
            if(this.currentstatepicker == 'date'){
               this.date = item;
            }          
        },
        getCurrentTab(id) {
            this.currentSelectTab = id;
        },
         sendEmailPushnotification(){
            this.emailsendToVendor('<i class="ic-check-mark"></i> <span class="text-message">PO has been emailed to the vendor.</span>')
            this.dialogEmail = 0
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem);
            } else {
                this.desserts.push(this.editedItem);
            }
            this.close();
        },
        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        gotoCreate(){
            this.$router.push('/containers/create')
        },
        tabsnavigationClick(current){
            if(current==1){
                this.$router.push('/containers/dispatch')
            }
            this.tabscurrent = current;
        },
        getmeselected(value){
            this.checkobjects()
            let finalvalue =  value;
            switch(this.currentDropdownState){
                case "customer":
                    this.customerSelected = finalvalue;
                    break;
                case "CONTAINER SIZE":
                    this.containersizeSelected = finalvalue;
                    break;
                case "TERMINAL AT":
                    this.terminalatSelected = finalvalue;
                    break;
                case "TRUCKERS":
                    this.trucker_idSelected = finalvalue;
                    break;
                // case "CARRIER":
                //     this.trucker_idSelected = finalvalue;
                //     break;
            }
        },
        dataDown(value){
            this.formFieldlength++;
            this.currentDropdownState = value
            this.currentSelectTab = value
        },
       calldata(){
            let terminalvalue = this.formdetails.data.terminal;
            for (const key in terminalvalue) {
                this.TERMINAL_AT.push(terminalvalue[key].name);
            }
        },
        getPrelims: function(data) {
          let response = data;

          let customers = (response.data.customers);

          let DataCustomer=[];
              customers.forEach((value) => {
              DataCustomer.push({id:value.id ,name:value.company_name});
          });

          this.itemsDropdown = DataCustomer;

          let terminal_at = (response.data.terminal);
              terminal_at.forEach((value) => {
              this.TERMINAL_AT.push({id:value.id,name:value.name});
          });

          let size = (response.data.size);
              size.forEach((value) => {
              this.containerSize.push({id:value.id,name:value.name});
          });

          let containertype = (response.data.type);
              containertype.forEach((value) => {
              this.containerType.push({id:value.id,name:value.name});
          });

          let truckersID = (response.data.trucker);
              truckersID.forEach((value) => {
              this.truckers.push({id:value.id,name:value.name});
          });
          this.startloading = 0;
        },

         onChange() {
            this.filelist = [...this.$refs.file.files];
            let table = this.filelist;
            let errorCount = 0;

            Object.keys(table)
            .forEach(function eachKey(key){
                if(table[key].type !='application/pdf')
                {
                errorCount++;
                }
            });

            if(errorCount> 0) {
                this.errorFile =1
                this.allFieldsValid = false;
            }  else{
                this.errorFile =0
                this.allFieldsValid = true;
            }
        },
    remove(i,e) {
        this.uploadTrigger = false;
        try {
            axios.delete(`${poBaseUrl}/upload/remove/` + e.id)
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.responseDatatempt = response;
            })

            this.filelist.splice(i, 1);
            if( this.filelist.length == 0){
                this.errorFile = 0
                this.allFieldsValid = true;
            }
        } catch (err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
    },
    gotoDownload(){
        let passdata = this.objectServerid.id
        this.isFetching = 1;
        if(passdata !==''){
            axios({
                url: `${poBaseUrl}/newshipment/download/${passdata}`,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                this.isFetching = 0;
                window.URL = window.URL || window.webkitURL;
                const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data],{type: "application/octet-stream"}));
                    link.setAttribute('download', 'Delivery_order.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
            })
        }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    async callUPdocument(data){
        if(data)
        jQuery('.cDownloadfiles').hide();
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        var formData = new FormData();

        try {
            var pdfiles  = document.getElementById('assetsFieldHandle');
            formData.append("fields[]", pdfiles.files[0]);
            formData.append("data[]", this.filelist);
            formData.append("isCreateShipment",'Yes');

            await axios.post(`${poBaseUrl}/upload_file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
      }
    },
    async mounted(){
        this.$store.dispatch("page/setPage", "containers");
    }
};
</script>
<style lang="scss">
     @import '../../../../assets/scss/pages_scss/containers/containersTable.scss';
     @import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
     @import '../../../../assets/scss/buttons.scss';
</style>
<style>
    .uploadDocuwrapper  {
        margin-top: 7px;
    }

    .containers-wrappers {
        padding-top: 8px !important;
    }

    .container-create {
        padding-top: 0px !important;
    }

    .container-create .toolbHeader .v-toolbar__content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .toolbHeader {
        margin-bottom: 4px;
    }

    .container-create .toolbHeader{
        background: none !important;
    }

    .NewContainerShipmentTitle {
        font-size: 24px;
        font-weight: 600;
    }

    .deliveryOrderColumn {
        background-color: #fff;
        padding: 0px;
        width: 93%;
        margin-left: 15px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .vue-tags-input {
        max-width: 100% !important;
    }

    .ti-new-tag-input {
        caret-color: #000 !important;
    }

    .ti-tags .ti-tag {
        background:#F7F7F7;
        color:#4A4A4A
    }

    div.ti-input {
        border-radius: 4px;
        border: 1px solid #B4CFE0 !important;
    }

    .uploadElement .hasContentFiles {
        background: #fff !important;
        padding:6px !important;
        display: block !important;

    }

    .browserInput {
    display: flex;
    width: 100%;
}

.browseFile {
    width: 50%;
    text-align: center;
    color:#B4CFE0 !important;
    padding-top: 3px;
}

.browseButton {
   width: 50%;
  text-align: right;
  font-size: 12px;
  display: ruby;

}

.wrappIconbutton {
    border: 1px solid #B4CFE0;
    border-radius: 5px;
    width: 50%;
    text-align: center;
    padding: 5px;
}
.classUpload {
     font-size: 12px;
    color: #0171A1;
    position: relative;
    left: 6px;
}

.iconUploadfile {
    color:#0171A1;
}

.rowItemUploaded {
    background: #F7F7F7;
    border-radius: 6px;
    padding: 10px;
}

.mainwrapitem {
    border: 0px !important;
}

.uploadCreateNew {
    position: relative;
    top: 3px;
    left: -1px;
    height: 15px
}

.hidethisdiv {
    display: none !important;
}

.reupload {
    float: right;
    font-size: 14px !important;
    color: rgb(1, 113, 161);
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 0px;
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 1px;
}

.wrappIconbutton {
    float: right;
}

.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}
</style>
