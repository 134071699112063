var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplier-content-wrapper \n             driver-content-wrapper \n             chassis-container-wrapper",attrs:{"d-flex":""}},[_c('div',{staticClass:"driverHeader"},[_c('h2',[_vm._v(_vm._s(_vm.titlePage))]),(_vm.getTruckerId)?_c('v-btn',{staticClass:"btn-blue add-supplier",attrs:{"color":"primary addyardbutton"},on:{"click":function($event){return _vm.addChassisDialog()}}},[_c('img',{attrs:{"src":require("@/assets/icons/plus-button-icon.svg")}}),_vm._v("  "+_vm._s(_vm.chassisName)+" ")]):_vm._e()],1),_c('v-data-table',{staticClass:"yard-table \n           suppliers-table \n           ddriver-table \n           elevation-1",attrs:{"headers":_vm.headers,"mobile-breakpoint":"400","items":_vm.getChassis,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.number !== "" ? item.number : "--"))])]}},{key:"item.trucker_owned",fn:function(ref){
var item = ref.item;
return [(item.trucker_owned==1)?_c('p',{staticClass:"mb-0"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 24 24","fill":"green","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticStyle:{"fill":"#157ca8"},attrs:{"d":"M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM15.5416 8.66664L10.625 13.5833L8.45836 11.4166C8.13618 11.0945 7.61382 11.0945 7.29164 11.4166C6.96945 11.7388 6.96945 12.2612 7.29164 12.5834L10.0416 15.3334C10.3638 15.6555 10.8862 15.6555 11.2084 15.3334L16.7084 9.83336C17.0305 9.51118 17.0305 8.98882 16.7084 8.66664C16.3862 8.34445 15.8638 8.34445 15.5416 8.66664Z","fill":"#0171A1"}})])]):_c('p',{staticClass:"mb-0"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 24 24","fill":"grey","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticStyle:{"fill":"#b5b3b3"},attrs:{"d":"M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM15.5416 8.66664L10.625 13.5833L8.45836 11.4166C8.13618 11.0945 7.61382 11.0945 7.29164 11.4166C6.96945 11.7388 6.96945 12.2612 7.29164 12.5834L10.0416 15.3334C10.3638 15.6555 10.8862 15.6555 11.2084 15.3334L16.7084 9.83336C17.0305 9.51118 17.0305 8.98882 16.7084 8.66664C16.3862 8.34445 15.8638 8.34445 15.5416 8.66664Z","fill":"#819FB2"}})])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.type !== "" ? item.type : "--"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flexs justify-center"},[_c('v-btn',{staticClass:"truckerMoreBtn",staticStyle:{"margin-right":"10px","min-height":"30px !important"},attrs:{"small":"","width":"55"},on:{"click":function($event){return _vm.editChassis(item)}}},[_c('img',{attrs:{"src":require("../../../assets/icons/yards_edit.svg"),"alt":""}}),_c('span',{staticClass:"EditButton"},[_vm._v("Edit")])]),_c('v-menu',{attrs:{"offset-y":"","nudge-left":"97","nudge-top":"-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"truckerMoreBtn",attrs:{"small":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../../../assets/icons/more_dot.svg"),"alt":""}})])]}}],null,true)},[_c('v-list',{staticClass:"trucking_DropDown",staticStyle:{"overflow":"hidden"}},[_c('v-btn',{staticStyle:{"background-color":"#fff","height":"45px"},on:{"click":function($event){return _vm.deleteChassis(item)}}},[_c('v-list-item',{staticStyle:{"padding-left":"0px","width":"96px","padding-right":"15px","text-transform":"none"}},[_c('img',{attrs:{"src":require("../../../assets/icons/Delete.svg"),"alt":""}}),_c('v-list-item-title',{staticClass:"trucking_DropDownDelete"},[_vm._v(" "+_vm._s(_vm.deleteText)+" ")])],1)],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [(_vm.getChassisLoading)?_c('div',{staticClass:"no-data-preloader mt-4"},[(_vm.getChassisLoading)?_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)}),_c('Pagination',{attrs:{"pageData":_vm.page,"lengthData":_vm.pageCount,"isMobile":_vm.isMobile},on:{"update:pageData":function($event){_vm.page=$event},"update:page-data":function($event){_vm.page=$event},"update:lengthData":function($event){_vm.pageCount=$event},"update:length-data":function($event){_vm.pageCount=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }