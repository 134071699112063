<template>
  <div class="containers-wrappers" v-resize="onResize">
    <DiscardChanges
      :dialog="discard_changes_modal"
      @discardChanges="discardChanges"
    />
    <ViewContainer
      ref="index"
      :items="getdata"
      :customers="triggerPliplop ? customersOnIndex : customers"
      :terminals="triggerPliplop ? terminalOnIndex : terminals"
      :ContainerSize="triggerPliplop ? ContainerSizeOnIndex : ContainerSize"
      :thedata="triggerPliplop ? DataOnindex : thedata"
      :files="triggerPliplop ? fileOnIndex : files"
      :filesData="triggerPliplop ? fileOnIndex : files"
      :objectMeta="triggerPliplop ? objectMetaOnIndex : objectMeta"
      :thedrivers="triggerPliplop ? DriverOnindex : theDrivers"
      :thecontainerchassis="
        triggerPliplop ? ChassisOnindex : thecontainerchassis
      "
      :currentState="triggerPliplop ? currentStateOnindex : currentStateReload"
      mode="edit"
      @saveandanother="saveandAnother"
      :isMobile="isMobile"
      v-if="renderComponent"
    />
  </div>
</template>
<script>
import DiscardChanges from "../components/Tables/Containers/Dialog/DiscardChanges.vue";
import ViewContainer from "../components/Tables/Containers/View/index.vue";
import { mapGetters, mapActions } from "vuex";
import globalMethods from "../utils/globalMethods";
export default {
  name: "viewContainers",
  props: ["fromtable"],
  components: {
    ViewContainer,
    DiscardChanges,
  },
  data: () => ({
    triggerPliplop: 0,
    renderComponent: true,
    dataFormfield: [],
    isMobile: false,
    discard_changes_modal: false,
    next: null,
    hasParamsUpdated: false
  }),
  beforeRouteLeave(to, from, next) {
    if (
      this.$refs.index.$refs.dispatch &&
      this.$refs.index.$refs.dispatch.isDispatchChanged() &&
      !this.$refs.index.allow_navigation
    ) {
      this.discard_changes_modal = true;
      this.next = next;
    } else {
      next(true);
    }
  },
  watch: {
    hasParamsUpdated(nv) {
      if(nv) {
        this.fetchEdit(this.$route.params.id)
      }
    }
  },
  computed: {
    ...mapGetters({
      getPoLoading: "containers/getPoLoading",
      getEditDetails: "containers/getEditDetails", // get
      getEditviewData:
        "containers/getEditviewData" /* view data fron table passdata  */,
    }),
    getdata() {
      return this.getEditDetails;
    },
    customers() {
      var customers = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          customers = this.getEditDetails.datacomponents.customers;
        }
      }
      return customers;
    },
    terminalOnIndex() {
      var terminals = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.terminals)) {
          terminals = this.getEditviewData.terminals;
        }
      }
      return terminals;
    },
    terminals() {
      var terminal = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          terminal = this.getEditDetails.datacomponents.terminal;
        }
      }
      return terminal;
    },
    customersOnIndex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.customers)) {
          data = this.getEditviewData.customers;
        }
      }
      return data;
    },
    currentStateOnindex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.currentState)) {
          data = this.getEditviewData.currentState;
        }
      }
      return data;
    },
    currentStateReload() {
      var data = "";
      // fix when reload the status changed, store the previously open tab and
      // retrieve when not empty
      var titleni = localStorage.getItem("queryData");
      if (titleni != null) {
        if (titleni != "") {
          return titleni;
        }
      }
      const toTitleCase = (phrase) => {
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.status)) {
          data = this.getEditDetails.status;
        }
      }
      let result = toTitleCase(data);
      return result;
    },
    DriverOnindex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.noFilterDrivers)) {
          data = this.getEditviewData.noFilterDrivers;
        }
      }
      return data;
    },
    ChassisOnindex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          data = this.getEditDetails.datacomponents.chassis;
        }
      }
      return data;
    },
    theDrivers() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          data = this.getEditDetails.datacomponents.drivers;
        }
      }
      return data;
    },
    ContainerSizeOnIndex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.shipmentsize)) {
          data = this.getEditviewData.shipmentsize;
        }
      }
      return data;
    },
    ContainerSize() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          data = this.getEditDetails.datacomponents.size;
        }
      }
      return data;
    },
    objectMeta() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.object_meta)) {
          data = this.getEditDetails.object_meta;
        }
      }
      return data;
    },
    objectMetaOnIndex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.objectMeta)) {
          data = this.getEditviewData.objectMeta;
        }
        //console.log(data);
      }
      return data;
    },
    fileOnIndex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.files)) {
          data = this.getEditviewData.files;
          data = data.find((v) => v.shipment_id == this.$route.params.id);
        }
      }
      return data;
    },
    files() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          data = this.getEditDetails.datacomponents.files[0];
        }
      }
      return data;
    },
    DataOnindex() {
      var data = [];
      if (this.isUndefinedNull(this.getEditviewData)) {
        if (this.isUndefined(this.getEditviewData.items)) {
          data = this.getEditviewData.items;
        }
      }
      return data;
    },
    thedata() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.alldata)) {
          data = this.getEditDetails.alldata[0];
        }
      }
      return data;
    },
    thecontainerchassis() {
      var data = [];
      if (this.isUndefinedNull(this.getEditDetails)) {
        if (this.isUndefined(this.getEditDetails.datacomponents)) {
          data = this.getEditDetails.datacomponents.chassis;
        }
      }
      return data;
    },
  },
  methods: {
    ...mapActions({
      fetchEdit: "containers/fetchEdit",
    }),
    ...globalMethods,
    discardChanges(val) {
      this.discard_changes_modal = false;
      this.next(val);
      this.next = null;
    },
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    saveandAnother() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    isUndefinedNull(object) {
      if (typeof object !== "undefined" && object !== null) {
        return true;
      }
      return false;
    },
    isUndefined(object) {
      if (typeof object !== "undefined" && object.length !== "undefined") {
        return true;
      }
      return false;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      (toParams, previousParams) => {
        if(toParams, previousParams) {
          this.hasParamsUpdated = true
        }
      }
    )
  },
  async mounted() {
    if (!Object.keys(this.getEditviewData).length) {
      await this.fetchEdit(this.$route.params.id);
      var c = this.getEditDetails;
      // promt error msg if user is trying to edit shipment that belong to other user
      if (typeof c.ERROR_CODE != "undefined") {
        this.dispatchNotifcationError("Shipment not found.");
        this.$router.push("/containers");
      }
    } else {
      this.triggerPliplop = 1; // when click each row
    }
    this.$store.dispatch("page/setPage", "containers");
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages_scss/containers/containers.scss";
@import "../assets/scss/buttons.scss";
</style>
