<template>
    <v-dialog 
        persistent
        v-model="dialog"
        max-width="480px" 
        content-class="delete-driver-dialog dialogYard"  
        :retain-focus="false"
        >
        <v-card
          style="padding-bottom:8px">
          <v-card-text
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >   

            <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3 5.45455C20.3 3.64707 18.8225 2.18182 17 2.18182C15.1775 2.18182 13.7 3.64707 13.7 5.45455H11.5C11.5 2.44208 13.9624 0 17 0C20.0376 0 22.5 2.44208 22.5 5.45455H32.4C33.0075 5.45455 33.5 5.94296 33.5 6.54545C33.5 7.14795 33.0075 7.63636 32.4 7.63636H31.1812L28.3299 32.1365C28.0737 34.3384 26.1938 36 23.9589 36H10.0411C7.8062 36 5.9263 34.3384 5.67013 32.1365L2.8166 7.63636H1.6C1.05999 7.63636 0.610862 7.25045 0.517723 6.74155L0.5 6.54545C0.5 5.94296 0.992487 5.45455 1.6 5.45455H20.3ZM28.9636 7.63636H5.0342L7.85562 31.8865C7.98371 32.9874 8.92366 33.8182 10.0411 33.8182H23.9589C25.0763 33.8182 26.0163 32.9874 26.1444 31.8865L28.9636 7.63636ZM13.7 13.0909C14.24 13.0909 14.6891 13.4286 14.7823 13.8739L14.8 14.0455V27.4091C14.8 27.9363 14.3075 28.3636 13.7 28.3636C13.16 28.3636 12.7109 28.026 12.6177 27.5807L12.6 27.4091V14.0455C12.6 13.5183 13.0925 13.0909 13.7 13.0909ZM20.3 13.0909C20.84 13.0909 21.2891 13.4286 21.3823 13.8739L21.4 14.0455V27.4091C21.4 27.9363 20.9075 28.3636 20.3 28.3636C19.76 28.3636 19.3109 28.026 19.2177 27.5807L19.2 27.4091V14.0455C19.2 13.5183 19.6925 13.0909 20.3 13.0909Z" fill="#F93131"/>
            </svg>        

            <h2 

                class="delivery_header"
                style="">Delete Container</h2>           
            <p                 
                style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                Are you sure you want to delete this container? This action can not be undone.
            </p>

          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="deleteconfim" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Delete 
                    </span>
                </span>
            </v-btn>     
            <v-btn 
                @click="closedialog" 
                class="btn-white"
                >
                <span>
                    <span>
                       Cancel 
                    </span>
                </span>
            </v-btn>              
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'Showmodaldelete',
    data(){
      return {
            colorstrong:'#55a1c1',
            colorcontainers:'#4A4A4A',
            errotype:0,
        }
    },
    props: ['dialogData','movetype'], 
    computed: { 
        dialog: {
            get () {   
                //alert(this.movetype)          
                return this.dialogData
            },
            set(value) {
                if (!value) {
                    this.$emit("update:dialogData",0);
                } else {
                    this.$emit("update:dialogData",1);
                }
            },        
        },                       
    },
    watch:{  
    },
    methods: {    
        getmessage(){
          return 'You are not allowed to move all containers to a different DO.'+
                 'Check Container Information required fields.';
        },  
        deleteconfim() {             
          this.$emit("deleteconfim",1); 
        }  
        ,
        closedialog() {             
          this.$emit("close",1); 
        }      
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}
.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}
.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}
.delivery_header {
    margin-top: 17px !important;
    margin-bottom: 9px
}
</style>