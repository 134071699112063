<template>
  <div d-flex 
        class="supplier-content-wrapper 
               driver-content-wrapper 
               yard-container-wrapper"
    >
    <div class="driverHeader">
      <h2>Yards</h2>
      <v-btn
        v-if="getTruckerId"
        color="primary addyardbutton"
        class="btn-blue add-supplier"
        @click="addYardDialog()"
      >
        <img src="@/assets/icons/plus-button-icon.svg">&nbsp; New Yard
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="getYards"
      class="yard-table 
             suppliers-table 
             ddriver-table 
             elevation-1"
      :page.sync="page"
      hide-default-footer
       @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
     
    >
      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">{{ item.name !== "" ? item.name : "--" }}</p>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        <p class="mb-0">{{ item.address !== "" ? item.address : "--" }}</p>
      </template>
      <template v-slot:[`item.actions`]="{ item }" style="position: fixed">
        <div class="d-flexs justify-center">
          <v-btn small width="55"
            style="margin-right: 10px; min-height: 30px !important;"
            @click="editYard(item)"
            class="truckerMoreBtn"
          >
            <img class="" src="../../../assets/icons/yards_edit.svg" alt="" />
            <span
              style="
                text-transform: capitalize;
                color: #0171a1;
                font-size: 12px;
                font-family: Roboto;
                font-weight: 500, Medium;
                line-height: 18px;
                margin-left:2px;
              "
              >Edit</span
            >
          </v-btn>
          <v-menu offset-y nudge-left="97"  nudge-top="-5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" class="truckerMoreBtn">
                <img src="../../../assets/icons/more_dot.svg" alt="" />
              </v-btn>
            </template>
            <v-list class="trucking_DropDown" style="overflow: hidden">
              <v-btn
                @click="deleteYard(item)"
                style="background-color: #fff; height: 45px"
              >
                <v-list-item 
                    style="padding-left: 0px;
                           width: 96px;
                           padding-right:15px;
                           text-transform:none;"
                  >
                  <img src="../../../assets/icons/Delete.svg" alt="" />
                  <v-list-item-title
                    class="trucking_DropDownDelete"
                    style=""
                  >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-btn>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="getYardLoading">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
            v-if="getYardLoading"
          >
          </v-progress-circular>
        </div>
      </template>
    </v-data-table>

    <Pagination 
      :pageData.sync="page" 
      :lengthData.sync="pageCount" 
      :isMobile="isMobile" 
      />
   
    <!-- <v-alert
      :value="getalert_box"
      height="59px"
      width="auto"
      max-width="26%"     
      style="margin: 8px auto"
      transition="scale-transition"
      :class="get_alert_type"
      class="yard_alert_delete"     
    >
      <p style="">
         <span 
             style="color:white"
             >
             <i 
              :class="checkstatus(get_alert_type)"
             >
             </i>
             &nbsp;
             {{ get_alert_box_text + "." }}
          </span>
      </p>     
      
    </v-alert> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Pagination from '../../Pagination.vue'
import globalMethods from '../../../utils/globalMethods'
export default {
  components: {
    Pagination,
  },
  props: ["isMobile"],
  data: () => {
    return {
      boxtype:'',
      itemsPerPage:9,
      alertbox: false,
      pageCount: 0,
      page: 1,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "20%",
          fixed: true,
        },
        {
          text: "Address",
          align: "start",
          value: "address",
          sortable: false,
          width: "40%",
          fixed: true,
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
          width: "20%",
          fixed: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getYards: "yards/getYards",
      getYardLoading: "yards/getYardLoading",
      getalert_box: "yards/getalert_box",
      get_alert_box_text: "yards/get_alert_box_text",
      get_alert_type: "yards/get_alert_type",
      getTruckerId: "yards/getTruckerId",
    }),
  },

  methods: {
    ...globalMethods, 
    ...mapActions("yards", ["getYardsFromApi", "changeAlertBoxStatus"]),
    editYard(item) {
      this.$emit("editYard", item);
    },
    addNeww() {
      this.getStatusOfBox;
      // this.changeAlertBoxStatus(!this.getalert_box);
    },
    deleteYard(item) {
      this.$emit("showDeleteDialog", item);
    },
    addYardDialog() {
      this.$emit("addYardDialog");
    },
  },
  mounted() {
    this.getYardsFromApi();
  },
  watch: {
    getalert_box() {
      setTimeout(() => {
        this.changeAlertBoxStatus(false);
      }, 2000);
    },
  },
};
</script>

<style scoped>
.truckerMoreBtnn {
  background-color: brown;
}
.delete {
  background: #4A4A4A !important;
}

.pagination-wrapper {
  height: 48px !important;
}

.pagination-wrapper .v-pagination {
  position: relative !important;
  top: -6px !important;
}

.trucking_DropDownDelete {
  color: #f93131;
  font-size: 12px !important;                      
  font-weight: Regular;
  font-weight: 500;
  margin-left:0px;
  text-align:left;
  padding-left:6px;
}
</style>
