<template>
<div>
    <v-divider color="#EBF2F5 "></v-divider>
    <div class="py-6 pb-16 px-5" style="background-color:white" >
        <h3 class="integration-primary-color mt-1" style="font-size:20px">Notifications</h3>
        <div  v-if="!isContentLoading">
            <v-card class="py-3 mt-3" elevation="0" style="border: 1px solid #EBF2F5;border-radius:5px">
            <v-card-title class="py-0 my-0">
                <span class="setting-notification-primary-color body-1 mb-2" style="font-weight:600">Driver Accepted Load</span>
                    <v-spacer></v-spacer>
                <label :style="!dispatchAcceptedToggle ? 'border: 1px solid #EBF2F5;' :'border: 1px solid #16B442'" class="custom-swich toggle" :class="dispatchAcceptedToggle ? 'toggle-true':'' ">
                    <input type="checkbox" v-model="dispatchAcceptedToggle">
                    <span class="slider"></span>
                    <span class="labels" data-on="On" data-off="Off"></span>
                </label>
            </v-card-title>
            <v-card-subtitle class="body-2 py-0 pt-2 pb-2" style="color: #6D858F;">Email notification every time a driver accepts a load</v-card-subtitle>
            
            <div class="notification-custom-list-dropdown" v-if="dispatchAcceptedToggle" >
                <v-card-text class="py-0 setting-notification-grey-color pb-0" style="font-weight:600;font-size:10px;text-transform: uppercase;">Recipients</v-card-text>
                <v-row no-gutters class="mx-3">
                    <v-col cols="12">    
                        <vue-tags-input
                            hide-details="auto"
                            allow-edit-tags
                            :disabled="!dispatchAcceptedToggle"
                            :rules="arrayNotEmptyRules"
                            :tags="dispatchLoadAcceptedTag"
                            :style="dispatchAcceptedToggle ? '':'background-color:#EBF2F5'"
                            style="min-width:100%!important"
                            :add-on-blur=true
                            :add-on-key="[13, ',']"
                            :validation="tagsValidation"
                            v-model="dispatchLoadAcceptedEmail"
                            placeholder="e.g. name@email.com"
                            @tags-changed="newTags => {
                                this.dispatchLoadAcceptedTag = newTags
                                this.tagsInput.touched = true
                                this.tagsInput.hasError = (this.dispatchLoadAcceptedTag.length > 0) ? false : true
                                let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                if (typeof el!=='udnefined') {
                                    if (this.tagsInput.hasError)
                                        el.classList.add('ti-new-tag-input-error')
                                    else
                                        el.classList.remove('ti-new-tag-input-error')   
                                    }
                            }"
                        />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <p class="caption mb-0 setting-notification-grey-color">Separate multiple email addresses with comma</p>
                        <div v-if="dispatchLoadAcceptedEmail !==''" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="tagsInput.touched" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <!-- <div v-if="(dispatchLoadAcceptedTag.length > 0) && dispatchLoadAcceptedEmail!==''" class="v-messages__message">
                                        {{ tagsInput.errorMessage }}
                                    </div>

                                    <div v-if="dispatchLoadAcceptedTag.length == 0 && dispatchLoadAcceptedEmail!==''" class="v-messages__message">
                                        {{ tagsInput.errorMessage }}
                                    </div> -->
                                    <div v-if="dispatchLoadAcceptedTag.length == 0 && dispatchLoadAcceptedEmail==''" class="v-messages__message">
                                        Please provide at least 1 valid email address.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>    
        </v-card>

        <v-card class="py-3 mt-3" elevation="0" style="border: 1px solid #EBF2F5;border-radius:5px;">
            <v-card-title class="py-0 my-0">
                <span class="setting-notification-primary-color body-1 mb-2" style="font-weight:600">Driver Rejected Load</span>
                <v-spacer></v-spacer>
                <label :style="!dispatchRejectedToggle ? 'border: 1px solid #EBF2F5;' :'border: 1px solid #16B442'" class="custom-swich toggle" :class="dispatchRejectedToggle ? 'toggle-true':'' ">
                    <input type="checkbox" v-model="dispatchRejectedToggle">
                    <span class="slider"></span>
                    <span class="labels" data-on="On" data-off="Off"></span>
                </label>
            </v-card-title>
            <v-card-subtitle class=" body-2 py-0 pt-2 pb-3" style="color: #6D858F;">Email notification every time a driver rejects a load</v-card-subtitle>
            <v-row v-if="dispatchRejectedToggle" no-gutters class="mx-3">
                <v-col cols="12">    
                    <vue-tags-input
                        hide-details="auto"
                        allow-edit-tags
                        :disabled="!dispatchRejectedToggle"
                        :rules="arrayNotEmptyRules"
                        :tags="dispatchLoadRejectedTag"
                        :style="dispatchRejectedToggle ? '':'background-color:#EBF2F5'"
                        style="min-width:100%!important"
                        :add-on-blur=true
                        :add-on-key="[13, ',']"
                        :validation="tagsValidation"
                        v-model="dispatchLoadRejectedEmail"
                        placeholder="e.g. name@email.com"
                        @tags-changed="newTags => {
                            this.dispatchLoadRejectedTag = newTags
                            this.tagsInput.touched = true
                            this.tagsInput.hasError = (this.dispatchLoadRejectedTag.length > 0) ? false : true
                            let el = this.documentProto.getElementsByClassName('ti-input')[0]
                            if (typeof el!=='udnefined') {
                                if (this.tagsInput.hasError)
                                    el.classList.add('ti-new-tag-input-error')
                                else
                                    el.classList.remove('ti-new-tag-input-error')                                                    
                            }
                        }"
                    />
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                    <p class="caption mb-0 setting-notification-grey-color">Separate multiple email addresses with comma</p>
                    <div v-if="dispatchLoadRejectedEmail !==''" class="v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                    Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tagsInput.touched" class="v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                                <!-- <div v-if="(dispatchLoadRejectedTag.length > 0) && dispatchLoadRejectedEmail!==''" class="v-messages__message">
                                    {{ tagsInput.errorMessage }}
                                </div>
                                <div v-if="dispatchLoadRejectedTag.length == 0 && dispatchLoadRejectedEmail!==''" class="v-messages__message">
                                    {{ tagsInput.errorMessage }}
                                </div> -->
                                <div v-if="dispatchLoadRejectedTag.length == 0 && dispatchLoadRejectedEmail==''" class="v-messages__message">
                                    Please provide at least 1 valid email address.
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="py-3 mt-3" elevation="0" style="border: 1px solid #EBF2F5;border-radius:5px;">
            <v-card-title class="py-0 my-0">
                <span class="setting-notification-primary-color body-1 mb-2" style="font-weight:600">Low Customer Activity</span>
                <v-spacer></v-spacer>
                <label :style="!notifications.low_customer_activity.enabled ? 'border: 1px solid #EBF2F5;' :'border: 1px solid #16B442'" class="custom-swich toggle" :class="notifications.low_customer_activity.enabled ? 'toggle-true':'' ">
                    <input type="checkbox" v-model="notifications.low_customer_activity.enabled">
                    <span class="slider"></span>
                    <span class="labels" data-on="On" data-off="Off"></span>
                </label>
            </v-card-title>
            <v-card-subtitle class="body-2 py-0 pt-2 pb-2" style="color: #6D858F;">Email Notification when a customer hasn't booked with you in a while</v-card-subtitle>
            
            <div v-if="notifications.low_customer_activity.enabled">
                <v-card-text class="py-0 setting-notification-grey-color" style="font-weight:600;font-size:10px;text-transform: uppercase;">Sent after'</v-card-text>
                <v-row no-gutters class="mx-3 notification-custom-list-dropdown">
                    <v-col cols="4">
                        <v-select
                            ref="Vueselect"
                            :items="lowCustomerActivity"
                            class="select-activity text-fields shrink"
                            item-text="activity"
                            item-value="activity"
                            :disabled="!notifications.low_customer_activity.enabled"
                            :style=" notifications.low_customer_activity.enabled ? '':'background-color:'"
                            outlined
                            placeholder="Customer Actvity"
                            chips
                            attach
                            append-icon="mdi-chevron-down"
                            :background-color="notifications.low_customer_activity.enabled ? 'white': '#EBF2F5'"
                            v-model="selectedActivity"
                            hide-details="auto"
                            :menu-props="{ contentClass: '', bottom: true, offsetY: true, maxHeight: 500,maxWidth:500}"
                            multiple
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip style="color: #4A4A4A;font-weight:500" v-if="index == 0 || index == 1"  class="name">
                                    {{ item }} month
                                </v-chip>
                                <span style="color: #4A4A4A;" v-if="index == 2"  class="name ml-2"> +{{ selectedActivity.length - 2 }}</span>           
                            </template>

                            <template v-slot:prepend-item>
                                <v-card elevation="0" class="prepend-btn-inventory-reports-filter-close">
                                    <v-card-title style="font-size:16px;font-weight:600;padding:6px 16px;" class="pr-1 black--text">
                                        Activity
                                        <v-spacer></v-spacer>
                                        <v-btn @click="closeDropdown" icon>
                                            <v-icon color="#0171A1">mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>                                        
                                
                                    <v-divider style="border:1px solid #F5F9FC!important;"></v-divider>
                                    <div class="d-flex align-center px-1">
                                        <v-btn  text style="padding:8px 12px !important;border:none!important;" class="btn-white text-capitalize">Select All</v-btn>
                                        <span style="color: #4a4a4a;">-</span>
                                        <v-btn text style="padding:8px 12px !important;border:none!important;" class="btn-white text-capitalize">Deselect All</v-btn>
                                    </div>
                                    <v-divider style="border:1px solid #F5F9FC!important;"></v-divider>
                                </v-card>
                            </template>

                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item class="d-flex justify-start" style="border:none;min-height:45px;height:45px;" v-on="on" v-bind="attrs" #default="{ active }">
                                    <div><v-checkbox :input-value="active"></v-checkbox></div>
                                    <div>          
                                        <p class="name mb-1 font-regular" style="color: #4A4A4A;"> No activity for {{ item }} month</p>           
                                    </div>
                                </v-list-item>
                            </template>

                            <!-- <template v-slot:append-item>
                                <v-divider></v-divider>
                                <v-card class="d-flex px-4 py-3 append-btn-inventory-reports-add-clear" flat
                                    style="border-top-right-radius: 0; border-top-left-radius: 0; border-top:2px solid #F5F9FC;">
                                    <v-btn class="btn-blue mr-2">
                                        Apply
                                    </v-btn>
                                    <v-btn class="btn-white">
                                        Close
                                    </v-btn>
                                </v-card>                                
                            </template> -->
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="mt-1">
                        <vue-tags-input
                            hide-details="auto"
                            allow-edit-tags
                            :disabled="!notifications.low_customer_activity.enabled"
                            :rules="arrayNotEmptyRules"
                            :tags="optionsLow"
                            :style=" notifications.low_customer_activity.enabled ? '':'background-color:#EBF2F5'"
                            style="min-width:100%!important"
                            :add-on-blur=true
                            :add-on-key="[13, ',']"
                            :validation="tagsValidation"
                            v-model="lowEmailAddress"
                            placeholder="e.g. name@email.com"
                            @tags-changed="newTags => {
                                this.optionsLow = newTags
                                this.tagsInputLow.touched = true
                                this.tagsInputLow.hasError = (this.optionsLow.length > 0) ? false : true
                                let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                if (typeof el!=='udnefined') {
                                    if (this.tagsInputLow.hasError)
                                        el.classList.add('ti-new-tag-input-error')
                                    else
                                        el.classList.remove('ti-new-tag-input-error')   
                                    
                                }
                            }"
                        />
                    </v-col>
                    
                    <v-col cols="12" class="my-0 py-0">
                        <p class="caption mb-0 setting-notification-grey-color">Separate multiple email addresses with comma</p>
                        <div v-if="lowEmailAddress !==''" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="tagsInputLow.touched" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <!-- <div v-if="(optionsLow.length > 0) && lowEmailAddress!==''" class="v-messages__message">
                                        {{
                                            tagsInputLow.errorMessage
                                        }}
                                    </div>

                                    <div v-if="optionsLow.length == 0 && lowEmailAddress!==''" class="v-messages__message">
                                        {{
                                            tagsInputLow.errorMessage
                                        }}
                                    </div> -->
                                    <div v-if="optionsLow.length == 0 && lowEmailAddress==''" class="v-messages__message">
                                        Please provide at least 1 valid email address.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
            </v-row>     
            </div>    
        </v-card>

        <v-card class="py-3 mt-3" elevation="0" style="border: 1px solid #EBF2F5;border-radius:5px">
            <v-card-title class="py-0 my-0">
                <span class="setting-notification-primary-color body-1 mb-2" style="font-weight:600">Incoming Delivery Order</span>
                    <v-spacer></v-spacer>
                <label :style="!incomingDONotificationToggle ? 'border: 1px solid #EBF2F5;' :'border: 1px solid #16B442'" class="custom-swich toggle" :class="incomingDONotificationToggle ? 'toggle-true':'' ">
                    <input type="checkbox" v-model="incomingDONotificationToggle">
                    <span class="slider"></span>
                    <span class="labels" data-on="On" data-off="Off"></span>
                </label>
            </v-card-title>
            <v-card-subtitle class="body-2 py-0 pt-2 pb-2" style="color: #6D858F;">Email notification every time a driver accepts a load</v-card-subtitle>
            
            <div class="notification-custom-list-dropdown" v-if="incomingDONotificationToggle" >
                <v-card-text class="py-0 setting-notification-grey-color pb-0" style="font-weight:600;font-size:10px;text-transform: uppercase;">Emails</v-card-text>
                <v-row no-gutters class="mx-3">
                    <v-col cols="12">    
                        <vue-tags-input
                            hide-details="auto"
                            allow-edit-tags
                            :disabled="!incomingDONotificationToggle"
                            :rules="arrayNotEmptyRules"
                            :tags="incomingDONotificationTag"
                            :style="incomingDONotificationToggle ? '':'background-color:#EBF2F5'"
                            style="min-width:100%!important"
                            :add-on-blur=true
                            :add-on-key="[13, ',']"
                            :validation="tagsValidation"
                            v-model="incomingDONotificationEmail"
                            placeholder="e.g. name@email.com"
                            @tags-changed="newTags => {
                                this.incomingDONotificationTag = newTags
                                this.tagsInputIncoming.touched = true
                                this.tagsInputIncoming.hasError = (this.incomingDONotificationTag.length > 0) ? false : true
                                let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                if (typeof el!=='undefined') {
                                    if (this.tagsInputIncoming.hasError)
                                        el.classList.add('ti-new-tag-input-error')
                                    else
                                        el.classList.remove('ti-new-tag-input-error')   
                                    }
                            }"
                        />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <p class="caption mb-0 setting-notification-grey-color">Separate multiple email addresses with comma</p>
                        <div v-if="incomingDONotificationEmail !==''" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="tagsInputIncoming.touched" class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <!-- <div v-if="(incomingDONotificationTag.length > 0) && incomingDONotificationEmail !==''" class="v-messages__message">
                                        {{ tagsInputIncoming.errorMessage }}
                                    </div> -->

                                    <!-- <div v-if="incomingDONotificationTag.length == 0 && incomingDONotificationEmail !==''" class="v-messages__message">
                                        {{ tagsInputIncoming.errorMessage }}
                                    </div> -->
                                    <div v-if="incomingDONotificationTag.length == 0 && incomingDONotificationEmail ==''" class="v-messages__message">
                                        Please provide at least 1 valid email address.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>    
        </v-card>
        
            <div class="mt-8 px-1">
                <v-btn :disabled="isSaving" @click.prevent="saveChange" class="btn-blue" text>
                    <span style="color:#fff"> {{ isSaving ? "Saving Changes..." : "Save Changes" }} </span>
                </v-btn>
            </div>
        </div>
        
        <div v-else class="content-loading">
            <v-progress-circular :size="30" color="#0171a1" indeterminate>
            </v-progress-circular>
        </div>
        
        <!-- <v-card class="py-3 mt-3" elevation="0" style="border: 1px solid #EBF2F5;border-radius:5px">
            <v-card-title class="py-0 my-0">
                <span class="setting-notification-primary-color body-1 mb-2" style="font-weight:600">Incoming Delivery Order</span>
                    <v-spacer></v-spacer>
                <label :style="!incomingDO ? 'border: 1px solid #EBF2F5;' :'border: 1px solid #16B442'" class="custom-swich toggle" :class="incomingDO ? 'toggle-true':'' ">
                    <input :disabled="true" type="checkbox" v-model="incomingDO">
                    <span class="slider"></span>
                    <span class="labels" data-on="On" data-off="Off"></span>
                </label>
            </v-card-title>
            <v-card-subtitle class="body-2 py-0 pt-2 pb-2" style="color: #6D858F;">Email notification every time you received a Delivery Order</v-card-subtitle>
            
            <div class="notification-custom-list-dropdown" v-if="incomingDO" >
                <v-card-text class="py-0 setting-notification-grey-color pb-0" style="font-weight:600;font-size:10px;text-transform: uppercase;">Recipients</v-card-text>
                <v-row no-gutters class="mx-3">
                    <v-col cols="12">    
                        <vue-tags-input
                                            hide-details="auto"
                                            allow-edit-tags
                                            :disabled="!incomingDO"
                                            :rules="arrayNotEmptyRules"
                                            :tags="DOoptionsh"
                                            :style=" incomingDO ? '':'background-color:#EBF2F5'"
                                            style="min-width:100%!important"
                                            :add-on-blur=true
                                            :add-on-key="[13, ',']"
                                            :validation="tagsValidation"
                                            v-model="deliveryOrderNoticationAddress"
                                            placeholder="e.g. name@email.com"
                                            @tags-changed="newTags => {
                                                this.DOoptionsh = newTags
                                                this.tagsInput.touched = true
                                                this.tagsInput.hasError = (this.DOoptionsh.length > 0) ? false : true
                                                let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                                if (typeof el!=='udnefined') {
                                                    if (this.tagsInput.hasError)
                                                        el.classList.add('ti-new-tag-input-error')
                                                    else
                                                        el.classList.remove('ti-new-tag-input-error')   
                                                    
                                                }
                                            }"
                                        />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <p class="caption mb-0 setting-notification-grey-color">Separate multiple email addresses with comma</p>
                        <div v-if="tagsInput.touched" class="v-text-field__details">
                                        <div class="v-messages theme--light error--text" role="alert">
                                            <div class="v-messages__wrapper">
                                                <div v-if="(DOoptionsh.length > 0) && deliveryOrderNoticationAddress!==''" class="v-messages__message">
                                                    {{
                                                        tagsInput.errorMessage
                                                    }}
                                                </div>

                                                <div v-if="DOoptionsh.length == 0 && deliveryOrderNoticationAddress!==''" class="v-messages__message">
                                                    {{
                                                        tagsInput.errorMessage
                                                    }}
                                                </div>
                                                <div v-if="DOoptionsh.length == 0 && deliveryOrderNoticationAddress==''" class="v-messages__message">
                                                    Please provide at least 1 valid email address.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                    </v-col>
                </v-row>
                
            </div>    
        </v-card> -->
    </div>
</div>
</template>

<script>
import jQuery from 'jquery'
import VueTagsInput from '@johmun/vue-tags-input';
import axios from 'axios';
import globalMethods from '../../../utils/globalMethods';
import { mapActions, mapGetters } from "vuex";

const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
    components:{
        VueTagsInput
    },
    props: ['checksaving'],
    data(){
        return {
            isContentLoading: true,
            isSaving: false,
            stringdata:'',           
            savechanges:1,
            // driver_accepted_load: '',
            // driver_rejected_load:'',
            low_customer_activity:'',
            incoming_delivery_order:'',
            selectedActivity:[],
            lowCustomerActivity:['1','2','3'],
            // selectedLast:[],
            // driveAcceptToggle:false,
            // incomingDO:true,
            // driveRejectToggle:true,
            // customerActivityToggle:false,
            // myclass: '',
            tagsInput: {
                touched: false,
                hasError: false,
                errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
            },
            optionsHigh: [],
            DOoptionsh: [],
            optionsRejected:[],
            optionRejectedtxt:'',
            arrayNotEmptyRules: [
                (v) => !!v || "Email is required",
                () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email." 
            ],
            tagsValidation: [
                {
                    classes: 't-new-tag-input-text-error',
                    rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                    disableAdd: true
                }
            ],
            warehouseEmailAddress: '',
            deliveryOrderNoticationAddress: '',
            documentProto: document,

            // low
            lowEmailAddress:'',
            optionsLow:[],
            tagsInputLow: {
                touched: false,
                hasError: false,
                errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
            },
            tagsInputIncoming: {
                touched: false,
                hasError: false,
                errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
            },
            notifications: {
                low_customer_activity: {
                    enabled: false,
                    sent_after: {
                        one_month: false,
                        two_month: false,
                        three_month: false
                    },
                    recipients: []
                },
                dispatch_load_accepted: {
                    enabled: false,
                    recipients:[],
                },
                dispatch_load_rejected: {
                    enabled: false,
                    recipients:[],
                },
            },
            dispatchAcceptedToggle: false,
            dispatchRejectedToggle: false,
            dispatchLoadAcceptedTag: [],
            dispatchLoadRejectedTag: [],
            dispatchLoadAcceptedEmail: '',
            dispatchLoadRejectedEmail: '',

            incomingDONotification: {
                enabled: false,
                emails: []
            },

            incomingDONotificationToggle: false,
            incomingDONotificationTag: [],
            incomingDONotificationEmail: '',
        }
    },
    methods:{
        ...mapActions("profile", ["getCompanyProfileAsync"]),
        ...globalMethods,
        closeDropdown() {
           this.$refs.Vueselect.blur();
        //    this.selectedActivity = []
        },
        callalldata(){
            let icomingdonot = JSON.stringify(this.incoming_delivery_order);
           
            if(typeof this.notifications !=='undefined'){ 
                if(JSON.stringify(this.notifications).length + icomingdonot.length !== this.stringdata.length){
                    // this.savechanges = 1;
                }  else{
                    this.savechanges = 0;
                }      
                this.$emit('update:checksaving',0);   
            }else{
                this.savechanges = 0;
            }
        },
        getstatus(){
            // return this.savechanges;
            return 0;
        },
        saveChange() {
            this.isSaving = true;
            let formdata = new FormData()

            let notifs = {
                low_customer_activity: this.notifications.low_customer_activity
            }

            let dispatchAcceptedEmails = [];
            if(this.dispatchLoadAcceptedTag.length > 0){
                this.dispatchLoadAcceptedTag.map(({text}) => {
                    dispatchAcceptedEmails.push(text);
                });
            }else{
                this.dispatchAcceptedToggle = false;
            }

            let dispatchRejectedEmails = [];
            if(this.dispatchLoadRejectedTag.length > 0){
                this.dispatchLoadRejectedTag.map(({text}) => {
                    dispatchRejectedEmails.push(text);
                });
            }else{
                this.dispatchRejectedToggle = false;
            }
            
            notifs = {
                ...notifs,
                dispatch_load_accepted: {
                    enabled: this.dispatchAcceptedToggle,
                    recipients:dispatchAcceptedEmails,
                },
                dispatch_load_rejected: {
                    enabled: this.dispatchRejectedToggle,
                    recipients:dispatchRejectedEmails,
                },
            }

            let incomingDONotifsEmails = [];
            if(this.incomingDONotificationTag.length > 0){
                this.incomingDONotificationTag.map(({text}) => {
                    incomingDONotifsEmails.push(text);
                });
            }else{
                this.incomingDONotificationToggle = false;
            }

            let incomingDONotifs = {
                enabled: this.incomingDONotificationToggle,
                emails: incomingDONotifsEmails,
            }

            formdata.append('notifications', JSON.stringify(notifs))
            formdata.append('incoming_do_notification_recipients', JSON.stringify(incomingDONotifs))

            axios.post(`${APIBaseUrl}/trucker-notification/`+this.getCompanyProfile.id, formdata)
            .then((res) => {
                if (res.status === 200) {
                    this.isContentLoading = true;
                    this.getCompanyProfileAsync();
                    this.dispatchNotifcation(res.data.message);
                }
                this.isSaving = false;
                this.savechanges = 0;
            })
            .catch((err) => {
                this.dispatchNotifcationError(err.message)
                this.savechanges = 1;
                this.isSaving = false;
            });
        },

        loadNotifications(){
            this.isContentLoading = true;
            if(this.getCompanyProfile.notifications !== null) {
                this.notifications = JSON.parse(this.getCompanyProfile.notifications)
                let selectedActivity = [];
                Object.keys(this.notifications.low_customer_activity.sent_after).forEach(key => {
                    if(this.notifications.low_customer_activity.sent_after[key]) {
                        if(key === "one_month") {
                            selectedActivity.push("1")
                        } else if(key === "two_month") {
                            selectedActivity.push("2")
                        } else if(key === "three_month") {
                            selectedActivity.push("3")
                        }
                    }
                });
                this.selectedActivity = selectedActivity;
                let optionsLow = [];
                this.notifications.low_customer_activity.recipients.map((email) => {
                    optionsLow.push({
                        text: email,
                        tiClasses: ["ti-valid"]
                    })
                })
                this.optionsLow = optionsLow;

                if(this.notifications.dispatch_load_accepted && this.notifications.dispatch_load_accepted.recipients.length > 0){
                    let dispatchLoadAcceptedTag = [];
                    this.notifications.dispatch_load_accepted.recipients.map((em) => {
                        dispatchLoadAcceptedTag.push({
                            text:em,
                            tiClasses: ["ti-valid"]
                        });
                    });
                    this.dispatchLoadAcceptedTag = dispatchLoadAcceptedTag;
                    this.dispatchAcceptedToggle = this.notifications.dispatch_load_accepted.enabled;
                }

                if(this.notifications.dispatch_load_rejected && this.notifications.dispatch_load_rejected.recipients.length > 0){
                    let dispatchLoadRejectedTag = [];
                    this.notifications.dispatch_load_rejected.recipients.map((em) => {
                        dispatchLoadRejectedTag.push({
                            text:em,
                            tiClasses: ["ti-valid"]
                        });
                    });
                    this.dispatchLoadRejectedTag = dispatchLoadRejectedTag;
                    this.dispatchRejectedToggle = this.notifications.dispatch_load_rejected.enabled;
                }

                if(this.getCompanyProfile.incoming_do_notification_recipients !== null) {
                    this.incomingDONotification = JSON.parse(this.getCompanyProfile.incoming_do_notification_recipients);
                    if(this.incomingDONotification && this.incomingDONotification.emails.length > 0){
                        let incomingDONotificationTag = [];
                        this.incomingDONotification.emails.map((em) => {
                            incomingDONotificationTag.push({
                                text:em,
                                tiClasses: ["ti-valid"]
                            })
                        });
                        this.incomingDONotificationTag = incomingDONotificationTag;
                        this.incomingDONotificationToggle = this.incomingDONotification.enabled;
                    }
                }
                if(this.notifications){
                    this.isContentLoading = false;
                }
            }
        },
    },
    computed:{
        ...mapGetters("profile", [
            "getCompanyProfile",
        ]),
        optionsFiltered: {
            get() {
                let validEmailAddress = []
                if (this.warehouse.email_address.length > 0) {                    
                    this.warehouse.email_address.map(wea => {
                        if (wea !== null) {
                            validEmailAddress.push({text: wea, tiClasses:["ti-valid"]})
                        }
                    })
                }
                return validEmailAddress
            },
            set(value) {
                this.optionsHigh = value
            }            
        },
    },
    watch: { 
        dispatchLoadAcceptedTag(c){
            if(c.length > 0) 
            {
                let temp = []
                c.map((obj) => {
                    temp.push(obj.text)

                    let uniq = [...new Set(temp)]
                    this.notifications.dispatch_load_rejected.recipients = uniq
                })          
            }  else {
                this.notifications.dispatch_load_rejected.recipients = []
            } 
            this.callalldata();
        },       
        dispatchLoadRejectedTag(c){            
            if(c.length > 0) {
                let temp = []
                c.map((obj) => {
                    temp.push(obj.text)
                    let uniq = [...new Set(temp)]
                    this.notifications.dispatch_load_rejected.recipients = uniq
                })
            }  else {
                this.notifications.dispatch_load_rejected.recipients = []
            } 
            this.callalldata();            
        },
        DOoptionsh(c){          
           let temp = []
           if(c.length > 0) {               
                c.map((obj) => {
                    temp.push({emails:obj.text})
                })
            }  else {
               temp = []
            } 
            this.incoming_delivery_order = temp         
            this.callalldata();
        },
        tagsInput(value) {
            if (value.hasError) {
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            } else {
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            }
        },
        tagsInputLow(value) {
            if (value.hasError) {
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            } else {
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            }
        },

        dialog(value, oldValue) {
            this.tagsInput.hasError = false
            this.tagsInput.touched = false
            jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            if (typeof el!=='undefined') {
                let el = document.getElementsByClassName('ti-input')[0]
                el.classList.remove('ti-new-tag-input-error')
            }           
            
            if (!value) {
                this.optionsHigh = []
                this.warehouseEmailAddress = ''              
            } else if (value && !oldValue) {
                if (this.editedIndex==-1) {
                    this.optionsHigh = []
                } else {
                    let validEmailAddress = []
                    if (this.warehouse.email_address.length > 0) {                        
                        this.warehouse.email_address.map(wea => {
                            if (wea!==null) {
                                validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                            }
                        })
                    }
                    this.optionsHigh = validEmailAddress    
                }                
            }
        },
        selectedActivity(nv) {
            this.notifications.low_customer_activity.sent_after = {
                one_month: nv.includes('1') ? true : false,
                two_month: nv.includes('2') ? true : false,
                three_month: nv.includes('3') ? true : false,
            }
        },
        getCompanyProfile(nv) {
            if(nv){
                this.loadNotifications();
            }
        },
        optionsLow(nv) {
            if(nv.length > 0) {
                let temp = []
                nv.map((obj) => {
                    temp.push(obj.text)

                    let uniq = [...new Set(temp)]
                    this.notifications.low_customer_activity.recipients = uniq
                })
                //this.savechanges = 1;
            } else this.notifications.low_customer_activity.recipients = []
           this.callalldata();
        },
    },

    created(){
        this.getCompanyProfileAsync();
    },
}
</script>

<style lang="scss">
.setting-notification-primary-color{
	color: #4A4A4A;
}
.setting-notification-secondary-color{
	color: #6D858F;
}
.setting-notification-grey-color{
    color: #819FB2;
}
.select-activity.v-input {
    .v-input__control {
        // border: 1px solid  #B4CFE0;
        border-radius: 4px;
        // background-color: white;
        .v-input__slot {
            min-height: 44px;
            .v-select__slot {
                .v-select__selections {
                    min-height: 48px;

                    .v-chip {
                        background: #f7f7f7;
                        background-color: #f7f7f7;
                        height: 24px;
                        border-radius: 4px;
                        border: 1px solid #EBF2F5;
                        padding: 0 8px;
                        font-size: 12px;
                        margin: 1px 4px 4px 1px;

                        .v-chip__content {
                        color: #4a4a4a;

                        .name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 275px;
                        }

                        .btn-icon-wrapper {
                            height: auto;
                            width: auto;
                            min-width: unset;
                            box-shadow: none;
                            padding: 0;
                            padding-left: 6px;
                            display: flex;
                            i {
                                font-size: 18px;
                                color: #819FB2;
                            }
                        }
                    }
                }   

                    .mdi-close-circle::before {
                        content: "\F0156";
                        color: #6D858F;
                        font-size: 16px;
                    }
                     .v-input__append-inner {
                         display: none;
                     }
                }
            }
        }
    }
}


.custom-swich.toggle {
  --width: 68px;
  --height: 30px;
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
//   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
}

.custom-swich.toggle input {
  display: none;
}

.custom-swich.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #F0FBFF;
  transition: all 0.4s ease-in-out;
}

.custom-swich.toggle .slider::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 3px;
  width: 23px;
  height: 23px;
  border-radius: calc(var(--height) / 2);
  background-color: #819FB2;
//   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.custom-swich.toggle input:checked+.slider {
  background-color: #16B442;
}

.custom-swich.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.custom-swich.toggle .labels {
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 8px;
  font-weight: 500;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.custom-swich.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 12px;
  color: #819FB2;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.custom-swich.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 12px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.custom-swich.toggle input:checked~.labels::after {
  opacity: 0;
}

.custom-swich.toggle input:checked~.labels::before {
  opacity: 1;
}
.toggle-true.custom-swich.toggle .slider::before {
  background-color: white;
}
.notification-custom-list-dropdown {
    .v-input {
        margin-bottom: 4px;

        i {
            &.mdi-checkbox-blank-outline {
                width: 26px !important;
                // height: 26px !important;
                padding: 3px !important;
    
                &::before {
                    content: '' !important;
                    background-image: url('/checkbox-empty-icon-1.svg') !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-size: cover !important;
                    width: 16px !important;
                    height: 16px !important;
                }
            }
    
            &.mdi-checkbox-marked {
                width: 26px !important;
                // height: 26px !important;
                padding: 3px !important;
                &::before {
                    content: '' !important;
                    background-image: url('/checkbox-filled-icon-1.svg') !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-size: cover !important;
                    width: 16px !important;
                    height: 16px !important;
                }
            }
        }

        &.v-input--is-disabled {
            i {        
                &.mdi-checkbox-marked {        
                    &::before {
                        content: '' !important;
                        background-image: url('/checkbox-checked-disabled.svg') !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        width: 16px !important;
                        height: 16px !important;
                    }
                }
            }
        }
    }
}
.ti-tag {
    border-radius: 4px !important;
    font-size: 14px;
    font-family: 'Inter-Medium', sans-serif;
    padding: 3px 10px !important;
    line-height: 1;
    height: 30px;
    background-color: #F7F7F7 !important;
    border: 1px solid #E1ECF0;
    color: #6D858F !important;
    margin: 6px 2px 2px 2px;
    min-width: 75px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.ti-new-tag-input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}

.ti-input {
    border: 1px solid #B4CFE0 !important;
    border-radius: 4px;
    min-height: 45px;
    font-size: 14px;
}

.ti-input .ti-tags span {
    color: #4a4a4a !important;
}

.ti-new-tag-input::placeholder,
.ti-input::placeholder{
    color: #B4CFE0 !important;
}
.ti-new-tag-input {
        caret-color: #000 !important;
    }

.ti-new-tag-input-error {
    border: 1px solid #fc8686 !important;
}

.t-new-tag-input-text-error {
    color: #ff5252 !important;
}

.ti-new-tag-input-wrapper input {
    font-size: 14px !important;
}
.btn-save {
    background-color: #0171A1;
    color: white;
    padding: 10px;
    border-radius: 3px;
}
.btn-save:hover {
    background-color: #0885bb;
    color: white;
    padding: 10px;
    border-radius: 3px;
}
.content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
</style>