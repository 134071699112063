<template>
     <div>
        <v-dialog
            v-model="openDialogCom"
            fullscreen
            persistent
            scrollable
            no-click-animation
            :content-class="`dialogClassDos create-do popupdialogDos ${pageClass}`">

            <v-card>
               <!-- <v-toolbar dark color="white" class="px-4" style="padding-left:5px !important;">
                    <v-toolbar-title
                        v-if="ismoving_delivery"
                        class="reviewHeader-text"
                        style="color: black;margin-left:0px;"
                        >
                        <span v-if="movetoexisting">Update Delivery Order </span>
                        <span v-else>New Delivery Order </span>
                        <span style="font-size:12px;">
                            Containers moved from #{{ item.shifl_ref }}
                        </span>
                    </v-toolbar-title>
                    <v-toolbar-title
                        v-else
                        class="reviewHeader-text"
                        style="color: black;margin-left:0px;"
                        >
                        Edit Delivery Order #{{ item.shifl_ref }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            dark
                            @click="closedialog()"
                            >
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar> -->

                <v-card-title>
                    <span class="headline" v-if="ismoving_delivery">
                        <span class="headline" v-if="movetoexisting">Update Delivery Order #{{ selectedshifl_ref }}</span>
                        <span class="headline" v-else>New Delivery Order </span>
                        <span style="font-size:12px; height: 26px; font-family: 'Inter-SemiBold', sans-serif !important; font-weight: unset;" 
                            class="ml-3 d-flex justify-start align-start">
                            Container(s) moved from <span class="ml-1" 
                                style="color: #0171A1; font-family: 'Inter-SemiBold', sans-serif !important; font-weight: unset;"> 
                                DO #{{ item.shifl_ref }}
                            </span>
                        </span>
                    </span>

                    <span class="headline" v-else>
                        Edit Delivery Order #{{ item.shifl_ref }}
                    </span>
                    <button icon dark class="btn-close" @click="closedialog">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <v-card-text class="pa-0">
                    <div class="container-create pb-0">
                        <div class="loading-wrapper-do" v-if="startfetching">
                            <v-progress-circular
                                :size="35"
                                color="#0171A1"
                                indeterminate
                                v-if="startfetching">
                                </v-progress-circular>
                        </div>

                        <v-form class="sFormClass"
                            style="padding:0px !important; width:100%"
                            id="sform"
                            ref="form"
                            v-model="valid"
                            @submit.prevent="submit">

                            <div class="d-flex">
                                <div class="leftcol" style="
									width: 790px !important;
									min-width: 650px !important;
									max-width: 790px !important;
									padding: 16px 20px !important;
									border-right: 1px solid #D8E7F0 !important;
								">
                                    <!-- <v-row>
                                        <v-col class="etacol pb-0 mt-0 pt-0" >
                                        </v-col>
                                    </v-row> -->

                                    <v-row>
                                        <v-col
                                            class="etacol pb-0 mt-0"
                                            cols="6"
                                            md="6"
                                            >
                                            <label class="text-item-label" for="">
                                                ETA
                                            </label>


                                            <div class="vc-date-picker__wrapper">
                                                <div class="vc-datepicker-with-clear">
                                                    <vc-date-picker
                                                        title-position="left"
                                                        is-expanded
                                                        v-model="date"
                                                        mode="date"
                                                        :popover="{ visibility: 'focus' }"
                                                        :firstDayOfWeek="2"
                                                        :masks="masks"
                                                        :select-attribute="attribute"
                                                        @input="(e) => checkErrorShowing(e, 'eta')"
                                                        ref="eta_ref_do"
                                                        :is-required="isDateRequired">

                                                        <template #default="{ inputValue, inputEvents }">
                                                            <input
                                                                :value="inputValue"
                                                                v-on="inputEvents"
                                                                class="create-do-eta-dxdate-vc"
                                                                placeholder="Select ETA"
                                                                @keyup="(e) => checkKeyUpVal(e)"
                                                            />
                                                        </template>
                                                    </vc-date-picker>

                                                    <button type="button" class="vc-date-clear-button" @click="clearDate" 
                                                    v-if="date !== null">
                                                        <v-icon size="18px" color="#F93131">mdi-close</v-icon>
                                                    </button>
                                                </div>

                                                <p class="p-form-errors" v-if="isShowETAError">
                                                    ETA is required
                                                </p>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            md="6"
                                            class="pb-0 mt-0"
                                            >
                                            <label class="text-item-label" for="">
                                            MBL #
                                            </label>
                                            <v-text-field
                                                placeholder="Enter MBL Number"
                                                required
                                                v-model="mbl"
                                                name="mbl_num"
                                                hide-details="auto"
                                                @keyup="blurevent()"
                                                autocomplete="off"
                                                @input="(e) => checkErrorShowing(e, 'mbl')"
                                                >
                                            </v-text-field>
                                            <!-- :rules="mblRules" -->
                                            <p class="p-form-errors" v-if="isShowMBLError">
                                                MBL is required
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            md="6"
                                            class="selectBox"

                                            >
                                            <label class="text-item-label" for="">
                                                Carrier
                                            </label>
                                            <v-autocomplete
                                                v-model="CarrierModel"
                                                :items="carrierDropdown"
                                                item-text='name'
                                                item-value='id'
                                                placeholder="Select Carrier"
                                                name="carrier_id"
                                                solo
                                                @mousedown="dataDown('CARRIER')"
                                                @keyup="blurevent()"
                                                append-icon="ic-chevron-down"
                                                style="margin-top:4px;"
                                                hide-details="auto"
                                                class="icon-append-custom"
                                                attach
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            md="6">
                                            <label class="text-item-label" for="">
                                                Vessel
                                            </label>
                                            <v-text-field
                                                placeholder="Vessel"
                                                required
                                                v-model="VesselModel"
                                                name="vessel_name"
                                                hide-details="auto"
                                                @keyup="blurevent()"
                                                autocomplete="off"
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="selectBox pt-0"
                                            cols="12"
                                            sm="12"
                                            >
                                            <label class="text-item-label" for="">
                                                CUSTOMER NAME
                                            </label>
                                            <v-autocomplete
                                                v-model="customerdropdown"
                                                :items="itemsDropdownComputed"
                                                item-text= 'company_name'
                                                item-value='id'
                                                :placeholder="select_company()"
                                                solo
                                                append-icon="ic-chevron-down"
                                                class="customerDropdown mt-1 icon-append-custom"
                                                :search-input.sync="getSearchCompany"
                                                hide-details="auto"
                                                attach
                                                @input="(e) => checkErrorShowing(e, 'customer_name')"
                                                @change="onSelectCustomer(customerdropdown)"
                                                >
                                                <div slot="no-data" style="font-size: 14px;" class="px-4 py-2">
                                                    No data
                                                </div>
                                                <!-- :rules="customerRules" -->
                                                <!-- <div style="font-size:14px;"
                                                    v-if="hasNewChassis"
                                                    class="v-list-item v-list-item__title add-option-btn"
                                                    slot="append-item"
                                                    @click="createcustomer()"
                                                    >
                                                    <span style="color: #0171A1;">Add New</span>
                                                </div> -->
                                            </v-autocomplete>
                                            <p class="p-form-errors" v-if="isShowCustomerError">
                                                Customer is required
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-4">
										<v-col class="selectBox pt-0" cols="12" sm="12">
                                            <label class="text-item-label" for="">
                                                CONSIGNEE
                                            </label>

                                            <v-autocomplete
                                                ref="consigneeRef"
                                                class="select-consignee"
                                                v-model="selectedConsignee"
                                                :items="consigneeLists"
                                                item-text="name"
                                                item-value="id"
                                                :placeholder="getAllConsigneesLoading ? 'Fetching consignees...' : 'Select Consignee'"
                                                solo
                                                append-icon="ic-chevron-down"
                                                :search-input.sync="searchedConsignee"
                                                :menu-props="{ contentClass: 'consignee-menu-lists'}"
                                                :disabled="getCreateConsigneeLoading || getAllConsigneesLoading"
                                                attach
                                                hide-details="auto">

                                                <div slot="no-data">
                                                    <p class="px-4 py-3 mb-0" style="font-size: 14px;" v-if="!hasNewConsignee || consigneeLists.length === 0">
                                                        No available data
                                                    </p>
                                                </div>

                                                <template slot="item" slot-scope="{ item }">
                                                    <v-list-item-content>
                                                        <p class="consignee-name mb-0" style="font-size: 12px; color: #4a4a4a;">{{item.name}}</p>
                                                    </v-list-item-content>
                                                </template>

                                                <div class="v-list-item v-list-item__title add-option-btn px-3 py-1" 
                                                    style="min-height: 35px; border-top: 1px solid #EBF2F5 !important;"
                                                    slot="append-item" @click="handleNewConsignee" 
                                                    v-if="hasNewConsignee && searchedConsignee !== '' && (customerdropdown !== null && customerdropdown !== '')">
                                                    <span style="font-size: 14px; color: #0171A1;">
                                                        {{ getCreateConsigneeLoading ? 'Adding...' : '+ Add New'}}
                                                    <span v-if="searchedConsignee" style="color: #4a4a4a;">- {{ searchedConsignee }}</span>
                                                    </span>
                                                </div>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="selectBox pt-2"
                                            cols="12"
                                            md="12"
                                            >
                                        <label
                                            class="text-item-label">
                                            Customer Contacts
                                        </label>

                                        <div
                                            class="tags-email-wrapper mb-1">
                                            <vue-tags-input
                                                hide-details="auto"
                                                :tags="options"
                                                :add-on-blur=true
                                                :class="myclass"
                                                class="DialogInputfield"
                                                :add-on-key="[13, ',']"
                                                :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                                                v-model="warehouseEmailAddress"
                                                placeholder="Enter Email Address"

                                                @tags-changed="newTags => {
                                                    this.myclass = 'noclass';
                                                    this.options = newTags
                                                    this.tagsInput.touched = true
                                                    this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                                    let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                                    if (typeof el!=='udnefined') {
                                                        /*if (this.tagsInput.hasError)
                                                            el.classList.add('ti-new-tag-input-error')
                                                        else
                                                            el.classList.remove('ti-new-tag-input-error')*/
                                                    }
                                                }"
                                                />
                                                <!-- :rules="arrayNotEmptyRules"       -->
                                        </div>

                                        <span
                                            style="color: #819FB2; font-size: 12px;">
                                            <i style="font-size:12px;color:#819FB2">
                                                Separate multiple email addresses with comma
                                            </i>
                                        </span>

                                        <div style="height: 12px; line-height: 12px;">
                                            <div
                                                v-if="tagsInput.touched"
                                                class="v-text-field__details">
                                                <div
                                                    class="v-messages theme--light error--text"
                                                    role="alert">
                                                    <div
                                                        class="v-messages__wrapper">
                                                        <div
                                                            v-if="(options.length > 0) && warehouseEmailAddress!==''"
                                                            class="v-messages__message">
                                                            {{
                                                                tagsInput.errorMessage
                                                            }}
                                                        </div>

                                                        <div
                                                            v-if="options.length == 0 && warehouseEmailAddress!==''"
                                                            class="v-messages__message">
                                                            {{
                                                                tagsInput.errorMessage
                                                            }}
                                                        </div>
                                                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                            Please provide at least 1 valid email address.
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    </v-row>

                                    <v-row
                                        style="margin-top: 5px;">
                                        <v-col
                                            class="selectBox"
                                            cols="12"
                                            md="12"
                                            style="padding-top:3px"
                                            >
                                        <label
                                            class="text-item-label">
                                            Customer Reference #
                                        </label>

                                        <div
                                            class="tags-email-wrapper mb-1">
                                            <vue-tags-input
                                                hide-details="auto"
                                                :tags="optionsReferrence"
                                                :add-on-blur=true
                                                :class="myclass2"
                                                class="DialogInputfield"
                                                :add-on-key="[13, ',']"
                                                :validation="myclass.length > 0 ? tagsValidation2 : tagsValidation2"
                                                v-model="customerReferrenceNumber"
                                                placeholder="Enter Reference Number"
                                                @tags-changed="newTags => {
                                                    this.myclass2 = 'noclass';
                                                    this.optionsReferrence = newTags
                                                    this.tagsInput2.touched = true
                                                    this.tagsInput2.hasError = (this.optionsReferrence.length > 0) ? false : true
                                                    let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                                }"
                                                />
                                        </div>
                                        <!-- :rules="arrayNotEmptyRulesRefference"       -->
                                        <span
                                            style="color: #819FB2; font-size: 12px;">
                                            <i style="font-size:12px;color:#819FB2">
                                                Separate multiple referrence # with comma
                                            </i>
                                        </span>

                                        <div style="height: 12px; line-height: 12px;">
                                            <div
                                                v-if="tagsInput2.touched"
                                                class="v-text-field__details">
                                                <div
                                                    class="v-messages theme--light error--text"
                                                    role="alert">
                                                    <div
                                                        class="v-messages__wrapper">
                                                        <div
                                                            v-if="(optionsReferrence.length > 0) && customerReferrenceNumber!==''"
                                                            class="v-messages__message">
                                                            {{
                                                                tagsInput2.errorMessage
                                                            }}
                                                        </div>

                                                        <div
                                                            v-if="optionsReferrence.length == 0 && customerReferrenceNumber!==''"
                                                            class="v-messages__message">
                                                            {{
                                                                tagsInput2.errorMessage
                                                            }}
                                                        </div>
                                                        <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                            Please provide at least 1 valid email address.
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    </v-row>

                                    <v-row class="row-locations rowRemovemargin mt-0">
                                        <div class="col-xs-6 col-md-6 pb-0 box">
                                            <label class="text-item-label" for="">
                                            Container Starting Location
                                            </label>
                                            <v-autocomplete
                                                v-model="terminalAt"
                                                :items="terminalsDropdown"
                                                placeholder="Terminal"
                                                item-text="name"
                                                item-value="id"
                                                clearable
                                                hide-no-data
                                                filled
                                                full-width
                                                class="Goodsdeliverylocation container-starting-location"
                                                name="custom_notes"
                                                return-object
                                                hide-details="auto"
                                                :filter="onFilter"
                                                attach>

                                                <template slot="selection" slot-scope="{ item }">
                                                    <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                                                        {{ item.firms_code }} | {{ item.name }} | {{ item.map_location }}
                                                    </div>
                                                </template>
                                                
                                                <template slot="item" slot-scope="{ item }">
                                                    <template v-if="typeof item == 'object'">                            
                                                        <div class="listDropdown" style="font-size:12px">
                                                            {{ item.firms_code }} <span class="strtinglocationh"></span> 
                                                            {{ item.name }} <span class="strtinglocationh"></span>
                                                            {{ item.map_location }}
                                                        </div>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </div>
                                        <div class="col-xs-6 col-md-6 pb-0 box">
                                        <label class="text-item-label">Goods Delivery Location</label>
                                            <v-autocomplete
                                                :loading="loadingLocationPlaces"
                                                v-model="delivery_location"
                                                :items="places"
                                                :search-input.sync="searchPlace"
                                                placeholder="Type Delivery Addresss"
                                                item-text="place_name"
                                                no-filter
                                                clearable
                                                hide-no-data
                                                @click:clear="clearSearchPlaces"
                                                :label="delivery_location"
                                                filled
                                                full-width
                                                class="Goodsdeliverylocation"
                                                name="custom_notes"
                                                hide-details="auto"
                                                attach
                                                @input="(e) => checkErrorShowing(e, 'delivery_location')">
                                                
                                                <template slot="selection" slot-scope="{ item }">
                                                    <div style="color: #4a4a4a; font-size: 14px; line-height: 20px;">
                                                        {{ item.place_name }}
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                                <!-- :rules="rules" -->
                                            <p class="p-form-errors" v-if="isShowDeliveryError">
                                                Input is required
                                            </p>
                                        </div>
                                    </v-row>

                                    <v-row class="">
                                        <v-col
                                            class="selectBox pt-0 mt-4"
                                            cols="12"
                                            md="12"
                                            >

                                            <label class="text-item-label" for="">
                                            Description of Articles, Special Marks & Exceptions
                                            </label>
                                            <v-textarea
                                                placeholder="Enter Description Of Articles, Special Marks & Exceptions"
                                                required
                                                v-model="container_commodity"
                                                name="container_commodity"
                                                hide-details="auto"
                                                @keyup="blurevent"
                                                rows="3"
												class="textarea-commodity"
                                                >
                                            </v-textarea>

                                            <!-- <label class="text-item-label" for="">
                                            Commodity Description
                                            </label>
                                            <v-text-field
                                                placeholder="Enter Container Commodity"
                                                required
                                                v-model="container_commodity"
                                                name="mbl_num"
                                                hide-details="auto"
                                                @keyup="blurevent"
                                                >
                                            </v-text-field> -->
                                        </v-col>
                                    </v-row>

                                    <v-row class="notesrow mt-0">
                                        <v-col
                                            class="textareanotes pb-0 mt-0"
                                            cols="12"
                                            md="12">
                                            <label class="text-item-label" for="">
                                            Notes <span class="text-capitalize">(Optional)</span>
                                            </label>
                                            <v-textarea
                                                v-model="notembl"
                                                :rules="noterules"
                                                placeholder="Type Note..."
                                                rows="3"
                                                name="custom_notes"
                                                class="textarea-commodity"
                                                hide-details="auto">
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row class="notesrow mt-0 mb-3"></v-row>

                                    <v-row class="pt-0 pb-2">
                                        <v-col cols="12" md="12">
                                            <v-form ref="containerForm">
                                                <div class="holderheadercontainer" style="height: 40px;" :class="ismoving_delivery ? 'is_moving' : ''">
                                                    <!-- <h4 style="padding-left:6px; font-size: 16px; font-family: 'Inter-Medium', sans-serif;">Containers</h4> -->
                                                    <div class="d-flex justify-end align-center" v-if="showselect && !ismoving_delivery">
                                                        <span
                                                            class="CLEAR_selection"
                                                            v-if="hascheck"
                                                            @click="clearselection"
                                                            >Clear Selection</span>
                                                        <div
                                                            v-if="hascheck"
                                                            class="move_other_do"
                                                            >
                                                        <div
                                                            @click="moveotherdo"
                                                            class="moveotherdo"
                                                            > Move to other DO
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="color: red;">
                                                        <ul class="errorList" >
                                                            <li>{{errorMessagetext['container']}}</li>
                                                            <!-- <li>{{errorMessagetext['Volume']}}</li>
                                                            <li>{{errorMessagetext['Weight']}}</li>
                                                            <li>{{errorMessagetext['Quantity']}}</li>
                                                            <li>{{errorMessagetext['Sizes']}}</li>                                 -->
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="milestone-create-container333">
                                                    <div class="mainWrapperListContainers mt-0">
                                                        <div class="col-md-12 px-0">
                                                            <v-data-table
                                                                v-model="selected"
                                                                class="add-containers-table containerListingTable edit-do"
                                                                :class="movetoexisting ? 'update-do' : ''"
                                                                :headers="headers"
                                                                :items="containerList"
                                                                :page.sync="page"
                                                                :items-per-page="5"
                                                                hide-default-footer
                                                                fixed-header
                                                                dense
                                                                :show-select='showselect'
                                                                v-if="reload"
                                                                disable-pagination>
                                                                <template v-slot:header.data-table-select="">
                                                                    <v-checkbox
                                                                        value="0"
                                                                        style="position: relative;top: 0;"
                                                                        @change="getcheckall"
                                                                        class="text-fields pt-0 container-checkbox-custom"
                                                                        v-model="checkboxall"
                                                                        hide-details="auto"
                                                                        :ripple="false">
                                                                    </v-checkbox>
                                                                </template>

                                                                <template v-slot:body="{ items}">
                                                                    <tbody>
                                                                        <tr class="listing_table" v-for="(item, index) in items" :key="index">
                                                                            <td v-if="showselect" width="4%"
                                                                                style="
                                                                                    border-bottom: 1px solid #EBF2F5 !important;
                                                                                    border-left: 1px solid #EBF2F5 !important;">
                                                                                <v-checkbox
                                                                                    :value="item.checkbox"
                                                                                    @change="checkselected()"
                                                                                    class="text-fields checkboxrow pt-0"
                                                                                    v-model="item.checkbox"
                                                                                    hide-details="auto"
                                                                                    :ripple="false">
                                                                                </v-checkbox>
                                                                            </td>
                                                                            <td class="text-start" style="border-bottom: 1px solid #EBF2F5;">
                                                                                <!-- {{item.container_number}} -->
                                                                                <v-text-field
                                                                                    type="text"
                                                                                    placeholder="Input Container #"
                                                                                    class="text-fields"
                                                                                    @keyup="validateContainerNumber(item.container_number)"
                                                                                    full-width
                                                                                    v-model="item.container_number"
                                                                                    hide-details="auto"
                                                                                />

                                                                            </td>
                                                                            <td class="text-end cl"
                                                                                >
                                                                                <!-- {{ item.container_size }}  -->
                                                                                <v-select
                                                                                    v-model="item.container_size"
                                                                                    :items="itemsDropdownSize"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    class="text-fields fieldClass sizeClass"
                                                                                    placeholder="Select Size"
                                                                                    append-icon="ic-chevron-down"
                                                                                    hide-details="auto"
                                                                                ></v-select>
                                                                                <!-- @input="validatesize(item.container_size)"       -->

                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_weight }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_weight"
                                                                                    suffix="KG"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_weight,'Weight')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">KG</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_weight)" v-model="item.container_weight"><SPAN>kg</SPAN>
                                                                                -->
                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_volume }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_volume"
                                                                                    suffix="CBM"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_volume,'Volume')" -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">CBM</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_volume)" v-model="item.container_volume"><span>cbm</span> -->

                                                                            </td>
                                                                            <td class="text-end cl">
                                                                                <!-- {{ item.container_qty }} -->
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="item.container_qty"
                                                                                    suffix="UNIT"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(item.container_qty,'Quantity')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">UNIT</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                                <!-- <input class="cinput_containers" type="text" @blur="checkvalue(item.container_qty)" v-model="item.container_qty"> -->
                                                                            </td>
                                                                            <td class="text-center" style="border-right:0px !important; border-bottom: 0 !important;">
                                                                                <span @click="removeContainer(index)" style="color: red; cursor: pointer;">
                                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1527 0.846318C11.3932 1.08682 11.3932 1.47674 11.1527 1.71724L6.87069 5.99928L11.1527 10.2813C11.3932 10.5218 11.3932 10.9117 11.1527 11.1522C10.9122 11.3927 10.5223 11.3927 10.2818 11.1522L5.99977 6.8702L1.71773 11.1522C1.47723 11.3927 1.0873 11.3927 0.846806 11.1522C0.606308 10.9117 0.606308 10.5218 0.846807 10.2813L5.12884 5.99928L0.846807 1.71724C0.606308 1.47674 0.606308 1.08682 0.846807 0.846318C1.08731 0.605819 1.47723 0.605819 1.71773 0.846318L5.99977 5.12835L10.2818 0.846318C10.5223 0.605819 10.9122 0.605819 11.1527 0.846318Z" fill="#F93131"/>
                                                                                </svg>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody v-if="isAdding" >
                                                                        <tr class="addingnewrow">
                                                                            <td v-if="showselect" width="4%" style="
                                                                                    border-bottom: 1px solid #EBF2F5 !important;
                                                                                    border-right: 1px solid #EBF2F5 !important;">
                                                                                <v-checkbox
                                                                                    :value="checkboxitem"
                                                                                    class="text-fields checkboxrow pt-0"
                                                                                    v-model="checkboxcontainers"
                                                                                    hide-details="auto"
                                                                                    :ripple="false">
                                                                                </v-checkbox>
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="text"
                                                                                    placeholder="Input Container #"
                                                                                    class="text-fields"
                                                                                    v-model="containers.container_number"
                                                                                    @keyup="validateContainerNumber(containers.container_number)"
                                                                                    full-width
                                                                                    hide-details="auto"
                                                                                />
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-select
                                                                                    v-model="containers.container_size"
                                                                                    :items="itemsDropdownSize"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    class="text-fields fieldClass sizeClass"
                                                                                    placeholder="Select Size"
                                                                                    append-icon="ic-chevron-down"
                                                                                    hide-details="auto"
                                                                                ></v-select>
                                                                                  <!-- @change="validatesize(containers.container_size)"   -->
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_weight"
                                                                                    suffix="KG"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(containers.container_weight,'Weight')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">KG</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_volume"
                                                                                    suffix="CBM"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(containers.container_volume,'Volume')"  -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">CBM</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td class="text-end" style="
                                                                                border-right: 1px solid #EBF2F5 !important;
                                                                                border-left: none !important;">
                                                                                <v-text-field
                                                                                    type="number"
                                                                                    placeholder="0"
                                                                                    class="text-fields quantifyFields text-input-end"
                                                                                    full-width
                                                                                    v-model="containers.container_qty"
                                                                                    suffix="UNIT"
                                                                                    hide-details="auto"
                                                                                >
                                                                                    <!-- @keyup="checkvalue(containers.container_qty,'Quantity')" -->
                                                                                    <!-- <template slot="append">
                                                                                        <span style="font-size: 14px;color: #819FB2;">UNIT</span>
                                                                                    </template> -->
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td style="border-bottom: 0px !important; border-left: none !important;" class="text-end" >
                                                                                <span @click="cancelAdding"
                                                                                    style="color: grey;
                                                                                        cursor: pointer;
                                                                                        position: relative;
                                                                                        top: 0px;">
                                                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1528 0.846389C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12891 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846878 0.846389C1.08738 0.605891 1.4773 0.605891 1.7178 0.846389L5.99984 5.12843L10.2819 0.846389C10.5224 0.605891 10.9123 0.605891 11.1528 0.846389Z" fill="#B4CFE0"/>
                                                                                    </svg>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-data-table>
                                                            <div class="d-flex" style="width: 100%;">
                                                                <div style="cursor:pointer;width:25% !important" @click="addCOntainer" class="addcontainer">
                                                                    + Add Container
                                                                </div>
                                                                <div style="width: 20%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 15%;"></div>
                                                                <div style="width: 8%;"></div>
                                                            </div>
                                                            <!-- <div style="cursor:pointer;width:182px !important" @click="addCOntainer" class="addcontainer">
                                                                + Add Container
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div class="uploadDocuwrapper colright mt-0"
									style="
										width: calc(100% - 790px) !important;
										max-width: calc(100% - 790px) !important;
										padding: 16px 0 0 !important;
									">
                                    <div class="deliveryOrderColumn px-4">
                                        <!-- <div class="mt-3" style="
                                            padding-bottom: 7px;
                                            color: #4a4a4a;
                                            font-size: 16px;
                                            font-weight: 600;
                                        ">Document
                                        </div> -->
                                        <small
                                            style="color: red; font-size: 12px; margin-left: 5px"
                                            v-if="errorFile"
                                        >
                                            Invalid File Type
                                        </small>
                                        <span style="display: none" class="fileuploaderror"> </span>
                                        <div
                                        style="display: block"
                                        class="uploadElement flex w-full h-screen items-left justify-left text-left mt-0"
                                        id="app"
                                        >
                                        <div
                                            style="width: 100%; background: #fff !important"
                                            :class="hasContent"
                                            xclass="cDownloadfiles"
                                            @dragover="dragover"
                                            @dragleave="dragleave"
                                            @drop="drop"
                                        >
                                            <input
                                            style="display: none"
                                            type="file"
                                            name="files[]"
                                            id="assetsFieldHandle"
                                            class="w-px h-px opacity-0 overflow-hidden absolute"
                                            @change="onChange"
                                            ref="file"
                                            accept="application/pdf"
                                            />

                                            <label
                                            for="assetsFieldHandle"
                                            class="block cursor-pointer triggerelement"
                                            >
                                            <div class="browserInput2 dragfileElement">
                                                <div class="browseFileDelivery">Browse Or Drop File</div>
                                                <div class="btn-white2 browseButton2">
                                                <div class="wrappIconbutton2 mt-3">
                                                    <span class="iconUploadfile"
                                                    ><i class="ic-upload"></i
                                                    ></span>
                                                    <span class="classUpload">Upload</span>
                                                </div>
                                                </div>
                                            </div>
                                            </label>
                                        </div>

                                        <div
                                            class="flex w-full h-screen items-left justify-left text-left"
                                            style="width: 100%"
                                        >
                                        <!-- <span @click="downloadDocument(69)">Download</span>                               -->
                                        </div>
                                        </div>
                                    </div>

                                    <div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
                                        <v-progress-circular
                                            :size="35"
                                            color="#0171A1"
                                            indeterminate
                                            v-if="isFetching">
                                        </v-progress-circular>
                                    </div>

                                    <div v-if="!isFetching" :style="filelist.length > 0 ? 'height: calc(100% - 40px);' : ''">
                                        <div v-if="filelist.length > 0" style="height: 100%;">
                                            <div class="forthis" v-for="file in filelist" :key="file.name" style="height: 100%;">
                                                <div class="flex align-center justify-end mb-3 ml-0">
                                                    <!-- <div>
                                                        <label for="" class="reviewSubHeader-text document-align d-flex align-center">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6532 6.56315H16.9276C17.2438 6.56315 17.5 6.80836 17.5 7.10971V17.4486C17.5 18.387 16.7038 19.1673 15.721 19.1673H4.3C3.3178 19.1673 2.5 18.387 2.5 17.4486V2.53268C2.5 1.59424 3.3178 0.833984 4.3 0.833984H10.9276C11.2438 0.833984 11.5 1.07862 11.5 1.38055V5.462C11.5 6.07044 12.016 6.56315 12.6532 6.56315ZM6.7 15.1569H13.3C13.6312 15.1569 13.9 14.9008 13.9 14.584C13.9 14.2672 13.6312 14.0111 13.3 14.0111H6.7C6.3688 14.0111 6.1 14.2672 6.1 14.584C6.1 14.9008 6.3688 15.1569 6.7 15.1569ZM6.7 12.8652H13.3C13.6312 12.8652 13.9 12.6091 13.9 12.2923C13.9 11.9755 13.6312 11.7194 13.3 11.7194H6.7C6.3688 11.7194 6.1 11.9755 6.1 12.2923C6.1 12.6091 6.3688 12.8652 6.7 12.8652ZM6.7 10.5736H13.3C13.6312 10.5736 13.9 10.3169 13.9 10.0007C13.9 9.6844 13.6312 9.42773 13.3 9.42773H6.7C6.3688 9.42773 6.1 9.6844 6.1 10.0007C6.1 10.3169 6.3688 10.5736 6.7 10.5736ZM16.117 5.41732H13.2724C12.9562 5.41732 12.7 5.17211 12.7 4.87076V2.15456C12.7 1.93284 12.8392 1.73289 13.054 1.64925C13.267 1.56446 13.5142 1.61143 13.6774 1.76727L16.522 4.48347C16.6858 4.63987 16.735 4.87591 16.6462 5.0793C16.558 5.2844 16.3492 5.41732 16.117 5.41732Z" fill="#6D858F"/>
                                                            </svg>
                                                            <span class="ml-2" style="color: #4a4a4a;">{{ file.name }}</span>
                                                        </label>
                                                    </div> -->
                                                    <div class="d-flex align-center pr-4">
                                                        <button type="button" style="cursor:pointer;" class="btn-white replace-btn" @click="handleReplace">
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.29882 3.63602C5.5221 3.91096 5.46577 4.30383 5.173 4.51352C4.0217 5.33812 3.33333 6.61527 3.33333 8.00078C3.33333 10.1904 5.04317 12.005 7.27733 12.3312L6.80474 11.887C6.54439 11.6425 6.54439 11.2461 6.80474 11.0015C7.06509 10.757 7.4872 10.757 7.74755 11.0015L9.41421 12.5668C9.67456 12.8113 9.67456 13.2077 9.41421 13.4522L7.74755 15.0174C7.4872 15.2619 7.06509 15.2619 6.80474 15.0174C6.54439 14.7729 6.54439 14.3765 6.80474 14.132L7.36685 13.6046C4.35077 13.3075 2 10.912 2 8.00078C2 6.22047 2.88588 4.57685 4.36444 3.51786C4.65721 3.30817 5.07554 3.36107 5.29882 3.63602ZM6.58579 2.54938L8.25245 0.984158C8.5128 0.739656 8.93491 0.739656 9.19526 0.984158C9.43559 1.20985 9.45407 1.56499 9.25072 1.8106L9.19526 1.86958L8.63315 2.397C11.6492 2.69406 14 5.08961 14 8.00078C14 9.70363 13.1899 11.2835 11.8189 12.347C11.5351 12.5672 11.1149 12.5296 10.8804 12.263C10.6459 11.9964 10.6859 11.6018 10.9698 11.3816C12.0373 10.5535 12.6667 9.3261 12.6667 8.00078C12.6667 5.81116 10.9568 3.99659 8.72267 3.6704L9.19526 4.11459C9.45561 4.35909 9.45561 4.75551 9.19526 5.00001C8.95494 5.22571 8.57679 5.24307 8.31526 5.0521L8.25245 5.00001L6.58579 3.4348C6.34546 3.2091 6.32698 2.85397 6.53033 2.60836L6.58579 2.54938L8.25245 0.984158L6.58579 2.54938Z" fill="#0171A1"/>
                                                            </svg>
                                                            <span class="ml-1">Replace</span>
                                                        </button>
                                                        <button type="button" class="btn-white replace-btn ml-2" @click="removeFileUpload">
															<svg style="position: relative;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1528 2.84639C13.3933 3.08689 13.3933 3.47681 13.1528 3.71731L8.87076 7.99935L13.1528 12.2814C13.3933 12.5219 13.3933 12.9118 13.1528 13.1523C12.9123 13.3928 12.5224 13.3928 12.2819 13.1523L7.99984 8.87027L3.7178 13.1523C3.4773 13.3928 3.08738 13.3928 2.84688 13.1523C2.60638 12.9118 2.60638 12.5219 2.84688 12.2814L7.12891 7.99935L2.84688 3.71731C2.60638 3.47681 2.60638 3.08689 2.84688 2.84639C3.08738 2.60589 3.4773 2.60589 3.7178 2.84639L7.99984 7.12843L12.2819 2.84639C12.5224 2.60589 12.9123 2.60589 13.1528 2.84639Z" fill="#F93131"/>
                                                            </svg>
															<span class="ml-1" style="color: #f93131;">Remove</span>
														</button>
                                                    </div>
                                                </div>
                                                <div class="flex thedata" disabled="" style="height: 100%;">
                                                    <object style="overflow: hidden ! important;"
                                                    :data="iframefile2(file)" frameborder="0" width="100%" height="100%"></object>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <v-row class="clRow" style="padding-bottom:4px !important">
                                <v-col class="leftcol" md="7"
                                    style="width: 76% !important;
                                        min-width: 29% !important;
                                        max-width: 54% !important;
                                        padding: 20px !important;">

                                </v-col>

                                <v-col
                                    class="uploadDocuwrapper colright"
                                    >

                                </v-col>
                            </v-row>        -->
                        </v-form>

                        <template>
                            <div class="text-center"> <!-- ma-2 -->
                                <v-snackbar
                                v-model="snackbar"
                                :timeout="timeout"
                                >
                                <div v-if="snackbarcontent">
                                Selected containers has been moved to another DO
                                <span
                                    style="cursor:pointer;"
                                    @click="viewdo">
                                    View DO
                                    <img
                                        src="@/assets/icons/arrow-right-delivery.svg">
                                    </span>
                                </div>
                                <div v-else>
                                    Delivery Order has been successfully saved.
                                </div>
                                </v-snackbar>
                            </div>
                        </template>
                    </div>
                </v-card-text>

                <v-card-actions class="mt-0" style="border-top: 1px solid #EBF2F5;padding-top: 16px;">
                    <div class="flex flex-row">
                        <button type="button"
                        style="height:41px;font-size:14px;"
                        class="btn-blue"
                        @click="saveEdit"
                        :disabled="isSubmittingForm">
                            {{ isSubmittingForm ? 'Saving...' : 'Save' }}
                        </button>
                        <button type="button" :disabled="isSubmittingForm" class="btn-white cancel-btn" style="color: #F93131;height:41px;font-size:14px;" @click="cancelEdit">
                            <span style="color:#4a4a4a;">Cancel</span>
                        </button>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <ContainerDialog
            @saveContainer="saveContainer"
            :dialogData.sync="dialog"
            :ContainerSize="ContainerSize"
            :lastIndexData="lastIndexData"
        />

        <EditContainers
            @updateContainer="updateContainer"
            :dialogData.sync="showedit"
            :ContainerSize="ContainerSize"
            :editdata="keysvalue"
        />

        <CreatePicker
            :dialogDatepicker="dialogDatepicker"
            :defaultData ="pickerDefaultdata"
            @passbackdata="getdata"
            @close="closeDialogpicker"
        />

        <!-- <Confirm
            @cancelDialog="cancelDialog"
            @confirmDialog="continueDialog"
            :dialogData.sync="dialogConfirm"
            :isSaving.sync="isSavingConfirm"
            :location="location"
        /> -->

        <Movedialognotallowed
            :dialogData.sync="notallowed"
            :movetype="movetype"
            @close="closenotallowed"
        />
        <DialogCancel
            :dialogData.sync="showcancel"
            @close="closecancel"
            @confirmCancel="confirmCancel"
        />
        <ShowNotificationMoveDO
            :dialogData.sync="shownotification"
            @hideNotification="usernotification"
        />

        <SnackbarContainersave
            :dialogData.sync="showcontaineradded"
        />

        <Showdialogupdatebox
            :dialogData.sync="showconfirmafterupdate"
        />

        <Showdialogupdatebox
            @confirmDialog="continueDialogUpdateBox"
            :dialogData.sync="dialogConfirm"
            :isSaving.sync="isSavingConfirm"
            :deliveryID="DO_ID"
            :containersnumber="containers_number"
            :countshipment="countshipment"
            :shipmentIDS="shipmentIDS"
            :confirmmessage="confirm_message"
            :location="location"
        />
        <!-- SHOW REQUIRED MODAL IF NOW CONTAINER -->
        <Containerrequired
            :dialogData.sync="dialogrequired"
            @close ="() => { dialogrequired = false }"
        />

        <!-- SHOW CONTAINER VALIDATION -->
        <Containervalidate
            :dialogData.sync="dialogcontainervalidate"
            @close ="() => { dialogcontainervalidate = false }"
        />

        <!-- SHOW THE DO OPTION TO CREATE OR MOVE TO EXISTING -->
        <MoveDialog
            :dialogData.sync="dialogAccepted"
            @movecontainer="createdo"
            @clickContinue="continueClick"
        />

        <!-- SHOW ALL THE DOS TO CHOOSE FROM -->
        <ShowSelectMovetoExisting
            :dialogData.sync="showSelectMovetoExisting"
            :locType="isdialogDos"
            @save="saveDOcontainer"
        />
     </div>
</template>
<script>
import axios from "axios";
import {mapGetters,mapActions } from 'vuex'
const poBaseUrl = process.env.VUE_APP_PO_URL
const baseUrl = process.env.VUE_APP_BASE_URL;
import globalMethods from '../../../../utils/globalMethods'
import VueTagsInput from '@johmun/vue-tags-input';
import ContainerDialog from './DialogContainer.vue'
import EditContainers from './EditDialogContainer.vue'
//import ListRendering2 from '../newlistRender.vue'
import Containerrequired from './Onecontainerrequired.vue'
import Containervalidate from './Containervalidate.vue'
import MoveDialog from './MoveDialog.vue'
import ShowSelectMovetoExisting from "./MovetoexistingDialog.vue";
import CreatePicker from "../../../Createpicker.vue";
import Movedialognotallowed from "./NotallowedDialog.vue";
import DialogCancel from "./CancelDialog.vue";
import ShowNotificationMoveDO from "./ShowNotificationMoveDO.vue";
import SnackbarContainersave from "../snackbar/containersave.vue";
import Showdialogupdatebox from "./Showdialogupdatebox.vue";
import moment from "moment";
//import Confirm from './confirm.vue'
import _ from "lodash";
import jQuery from 'jquery'

// import DxDateBox from 'devextreme-vue/date-box';

export default {
    name: "edit_containers",
    props: ['item','openDialog', 'pageClass'],
    components: {
        VueTagsInput,
        ContainerDialog,
       // ListRendering2,
        //Confirm,
        ShowSelectMovetoExisting,
        MoveDialog,
        EditContainers,
        CreatePicker,
        Movedialognotallowed,
        DialogCancel,
        ShowNotificationMoveDO,
        SnackbarContainersave,
        Showdialogupdatebox,
        Containerrequired,
        Containervalidate,
        // DxDateBox
    },
    data(){
        return {
        isDateRequired: false,
        attribute: {
            highlight: {
                style: {
                    backgroundColor: '#0171A1', // blue
                    borderRadius: '4px'
                },
                contentStyle: {
                    color: '#ffffff', // color of the text
                    borderRadius: '4px'
                }
            },
        },
        masks: {
            weekdays: "WWW"
        },
        isSubmittingForm: false,
        isFetching: 0,
        startfetching: 0,
        isdialogDos:1,
        hasfileni:'',
        alreadyload: false,
        refreshfile:true,
        isdatareload:false,
        ismoving_delivery:false,
        getallDosids:[], // all data after selected moving do
        showselect:true,
        hideshowmove:0,
        dialogAccepted:0,

        showSelectMovetoExisting:0,
        checkboxitem:1,
        hascheck:0,
        checkboxall:[],
        reload:1,
        checkboxcontainers:'',
        calceladding: false,
        haserrorContainers: false,
        dialogrequired:false,
        dialogcontainervalidate: false,
        loadingLocationPlaces: false,
        places: [],
        searchPlace: "",
        delivery_location:'',
        containers:{
            container_number: "",
            container_size: "",
            container_weight: "",
            container_volume: "",
            container_qty: "",
            checkbox:0,
            lfd: "",
            uid: ""
        },
        errorMessagetext:{
            container:'',
            Weight:'',
            Volume:'',
            Quantity:'',
            Sizes:'',
        },
        typeoflocation:'',
        pickup_location:'',
        hasNewChassis: 0,
        newChassis: "",
        iscustomer:1,
        filenameobj:'',
        hasfile:0,
        docuID:0,
        customers:[],
        terminals:[],
        carriers:[],
        ContainerSize:[],
        confirm_message:[],
        countshipment:0,
        containers_number:[],
        shipmentIDS:[],
        DO_ID:'',
        showconfirmafterupdate:0,
        showcontaineradded:0,
        snackbarcontent: false,
        timeout:2000,
        thelinkreturn: 0,
        thdid:'',
        sid:'',
        snackbar:false,
        triggerloc:'',
        istriggerdfrommodal:0,
        shownotification:0,
        showcancel:0,
        getlistasstring:[],
        haschangesform:0,
        doInitialvalue:null,
        dofinalvalue:null,
        initcontainerListdata:null,
        finalcontainerListdata:null,
        getunchecked: [],
        defaultlistdata:[],
        movetype:0,
        notallowed:0, // if use added container, must be saved first
        updated:0,
        location:'edit',
        movetoexisting:0,
        istriggerededit:0,
        existingdeliveryobject:[],
        selectedExistingDeliveryID:0,
        selectedshifl_ref: 0,
        iscreate:0,
        ismove:0,
        allshipmentedits:[],
        pickerDefaultdata:'',
        dialogDatepicker:false,
        keytovalue: 0,
        keysvalue: [],
        editdata: [],
        showedit: 0,
        disableEditContainers: 1, // chana advice disable containers edit for now
        orderdeliveryID:'',
        refresh:0,
        callna: '',
        errorstate: 0,
        notpermited:false,
        calleditableELe: false,
        modaletd:false,
        container_commodity:'',
        customerdropdown: '',
        allDeliveryData: [],
        currentindexshow:99999,
        containerEmptytext: 'Container is empty. Add container before you save changes',
        roottablecontainer:'blueteam',
        containerList2:[],
        containerList:[],
         HBL_NUMBER: '',
        uploadCompleted: true,
        uploadTrigger:false,
        noerrorForm:true,
        contactsModel:'',
        CarrierModel:'',
        VesselModel:'',
        responseDatatempt:'',
        serverObject:[],
        objectServerid:'',
        hasContent:'hasContentFiles2',
        filelist:[],
        errorFile: false,
        comodity: '',
        resetform : false,
        shipmentmsg: 'The shipment was created!',
        Truckers:false,
        noerror: false,
        testsubmit:'',
        formFieldlength:0,
        startloading: 0,
        clickOrigin:'',
        clicksubmit: false,
        submitisclick:false,
        selectedmilestone:[],
        customer: [],
        mbl: '',
        containerNumber : '',
        terminalAt :'',
        CarrierRules:[
            v => !!v || 'Customer is required',
        ],
        VesselRules:[
            v => !!v || 'Customer is required',
        ],
        customerRules: [
            v => !!v || 'Customer is required',
        ],
        mblRules: [
            v => !!v || 'Mbl# is required',
        ],
        fieldrequired: [
            v => !!v || 'This field is required',
        ],
        containerNumberRules: [
            v => !!v || 'Container # is required',
        ],
        notembl: '',
        noterules:[
            (v) => {
                if (v == null){
                    return true;
                }else if(v !='') {
                    if(v.length > 200)
                    return  'Should not exceed the 200 allowed characters'
                }
                return true;
            }
        ],
        TerminalAtRules: [
            v => !!v || 'Terminal At is required',
        ],
        containerSizeModel:[],
        containersize: '',
        containersizeRules: [
            v => !!v || 'Container size is required',
        ],
        deliverTo: '',
        delivertoRules:[
            v => !!v || 'Deliver to is required',
        ],
        weight: '',
        weightRules:[
            v => !!v || 'Weight is required',
        ],
        customerpoNumber:'',
        customerpoNumberRules:[
            v => !!v || 'Customer PO# is required',
        ],
        containerTypeField:'',
        containerTypeRules:[
            v => !!v || 'Container Type is required',
        ],
        select: '',
        page:1,
        isAdding:0,
        selected:[],
        customerSelected:'',
        containertypeSelected:'',
        terminalatSelected:'',
        containersizeSelected:'',
        currentDropdownState:'',
        trucker_idSelected: '',
        valid:true,
        currentComboState:'',
        tabscurrent:0,
        tab: null,
        tabsNavigation: [
          'Header Info',
        ],
        date_etd:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: null,
        menu: false,
        modal: false,
        menu2: false,
        checkboxColor: '#0171A1',
        container_type: '',
        select_terminal_at:'',
        itemsDropdown: [],
        dialog: false,
        dialogConfirm: false,
        isSavingConfirm: false,
		search: "",
        searchData: '',
        selectedTasks: [],
        containerType: [],
        containerSize: [],
        TERMINAL_AT: [],
        ex4: [],
        truckers: [],
        newdata:[],
        myclass: '',
        myclass2: '',
        options: [],
        optionsReferrence: [],
        documents: [],
        document: null,
        documentProto: document,
        warehouseEmailAddress:'',
        customerReferrenceNumber: '',
        moreFilesError: false,
        submit: false,
        isLoading: false,
         tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
          tagsInput2: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered Customer Referrence by pressing the "Enter" or "," key in your keyboard.'
        },
        tagsValidation: [
        {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
        }
        ],
         tagsValidation2: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+$/),
            disableAdd: true
            }
        ],
        arrayNotEmptyRules: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        arrayNotEmptyRulesRefference: [
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        validateDaterules: [
          (v) =>
            {
              if(v==='') return "This field is required"
              if(!this.validateDate(v)){
                return "Invalid Date ex: YYYY-mm-dd"
              } return true
            }
         ],
         rules: [(v) => !!v || "Input is required."],
         lastIndexData: null,
         isShowETAError: false,
        isShowMBLError: false,
        isShowCustomerError: false,
        isShowDeliveryError: false,
        consigneeLists: [],
        selectedConsignee: null,
        newConsignee: "",
        hasNewConsignee: 0,
    }},
    watch: {
        // hasfileni(c){
        //     if(c){
        //        this.getFileURL(c);
        //     }
        // },
        searchPlace(val) {
            if (val) {
                this.getDeliveryLocation(val);
            }
        },
        filenameobj(c){
            if(c){
                // this.downloadDocument(this.docuID)
            }
        },
        openDialog(c){
            if(c){
                //this.hasfileni = '';
                this.showselect = true;
                //this.filelist = [];
                this.callpopulatedialog(this.item.id)
            }else{
                this.filelist = [];
            }
        },
        snackbar(c){
            if(!c){
               location.reload();
            }
        },
        updated(c){
            if(c){
                this.notallowed = 1;
                this.updated = 1;
                if(c==2){
                    this.notallowed = 1;
                    this.updated = 1;
                }else if(c==3){
                    this.movetype = 3
                }else if(c==4){
                    this.movetype = 4
                }else  if(c==5){
                    this.movetype = 5
                }
            }
        },
        optionsReferrence:{
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
         options: {
            handler: function (val) {
                if(val)
               this.checkobjects();
            },
            deep: true
        },
        items: function(newVal){
          this.getPrelims(newVal)
        },
        filelist(c){
            this.hasfileni = '';
            if (c.length == 0) this.hasContent = "hasContentFiles2";
            else this.hasContent = "cDownloadfiles hidethisdiv";
        },
        ismove(){
            this.istriggerededit = 1;
        },
        // hasContent(nv) {
        //     console.log(nv)
        // }
	},
    computed: {
        headers(){ return [
        {
            text: "Container #",
            align: "start",
            value: "container_number",
            sortable: false,
            width: "25%",
            fixed: true,
        },
        {
            text: 'Size',
            align: "end",
            value: "container_size",
            sortable: false,
            width: "17%",
            fixed: true,
        },
        {
            text: "Weight",
            align: "end",
            value: "container_weight",
            sortable: false,
            width: "15%",
            fixed: true,
        },
        {
            text: "Volume",
            align: "end",
            value: "container_volume",
            sortable: false,
            width: "15%",
            fixed: true,
        },
        {
            text: "Quantity",
            align: "end",
            value: "container_qty",
            sortable: false,
            width: "15%",
            fixed: true,
        },
        {
            text: "",
            align: "end",
            value: "actions",
            sortable: false,
            width: "8%",
            fixed: true,
            class:'alex'
            },
        ]},
         getSearchCompany: {
			get() {
				return this.newChassis !== null ? this.newChassis.toString() : this.newChassis;
			},
			set(input) {
				let temp = [];

				this.itemsDropdownComputed.map((list) => {
					temp.push(list.company_name);
				});
				if(temp.includes(input)) {
					this.hasNewChassis = 0;
				}
				else if(this.newChassis === "" || this.newChassis === null) {
					this.hasNewChassis = 0;
				}
				else {
					this.hasNewChassis = 1;
				}
				this.newChassis = input;
			},
		},
        openDialogCom:{
            get(){
            return this.openDialog;
          }
    },
        ...mapGetters({
            getCreateloading: 'containers/getPoLoading',
            formdetails: 'containers/getcontainerFormDetails',
            getPoLoading: 'containers/getPoLoading',
            getAllConsignees: 'containers/getAllConsignees',
            getAllConsigneesLoading: 'containers/getAllConsigneesLoading',
            getCreateConsigneeLoading: 'containers/getCreateConsigneeLoading'
        }),
        itemsDropdownSize:{
            get () {
                return this.ContainerSize
            },
        },
        itemsDropdownComputed:{
            get(){
                return this.customers
            }
        },
        terminalsDropdown:{
            get(){
                return this.terminals
            }
        },
        carrierDropdown:{
            get(){
                return this.carriers
            }
        },
        searchedConsignee: {
			get() {
				return this.newConsignee !== null && this.newConsignee !== '' ? this.newConsignee.toString() : this.newConsignee;
			},
			set(input) {
				let temp = [];
				this.consigneeLists.map((list) => {
					temp.push(list.name);
				});
				if(temp.includes(input)) {
					this.hasNewConsignee = 0;
				}
				else if(this.newConsignee === "" || this.newConsignee === null) {
					this.hasNewConsignee = 0;
				}
				else {
					this.hasNewConsignee = 1;
				}
				this.newConsignee = input;
			},
		},
    },

    methods: {
        clearDate() {
            this.date = null
            this.isDateRequired = false
        },
        checkKeyUpVal(e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                this.$refs.eta_ref_do.hidePopover(); // close popover on click enter
            }
        },
        async onSelectCustomer(selected) {
            if (selected !== null && selected !== '') {
                this.consigneeLists = []
                await this.fetchConsigneeAction(selected)
            }
        },
        async handleNewConsignee() {
            if(this.newConsignee === null || this.newConsignee === "") {
                this.notificationCustom("Please enter consignee name");
            } else {        
                let data = {
                    name: this.newConsignee,
                    customer_id: this.customerdropdown.id,
                };
                await this.createConsignee(data).then(async (res) => {
                    if (res) {
                        let data = res.data
                        this.notificationMessageCustomSuccess(res.message)
                        this.selectedConsignee = data.id
                        this.consigneeLists = []
                        await this.fetchConsigneeAction(data.customer_id)
                    }
                }) 
            }
        },
        async fetchConsigneeAction(id) {
            await this.fetchConsignees(id)
            if (typeof this.getAllConsignees !== 'undefined' && this.getAllConsignees !== null) {
                this.consigneeLists = this.getAllConsignees.results
            }
            this.$refs.consigneeRef.blur();
        },
        checkFormErrors() {
            // check required fields and show error after submitting
            let hasErrors = false

            // ETA
            if (this.date === null || this.date === "") {
                this.isShowETAError = true
            } else {
                this.isShowETAError = false
            }
            // MBL
            if (this.mbl === null || this.mbl === "") {
                this.isShowMBLError = true
            } else {
                this.isShowMBLError = false
            }
            // CUSTOMER NAME
            if (this.customerdropdown === null || this.customerdropdown === "") {
                this.isShowCustomerError = true
            } else {
                this.isShowCustomerError = false
            }
            // GOODS DELIVERY LOCATION
            if (this.delivery_location === null || this.delivery_location === "") {
                this.isShowDeliveryError = true
            } else {
                this.isShowDeliveryError = false
            }

            if (!this.isShowDeliveryError && !this.isShowMBLError && !this.isShowCustomerError && !this.isShowETAError) {
                hasErrors = false
            } else {
                hasErrors = true
            }

            return hasErrors
        },
        checkErrorShowing(e, field) {
            if (e !== "") {
                if (field === 'mbl') {
                    this.isShowMBLError = false
                } else if (field === 'customer_name') {
                    this.isShowCustomerError = false
                } else if (field === 'delivery_location') {
                    this.isShowDeliveryError = false
                } else if (field === 'eta') {
                    this.isShowETAError = false
                    this.isDateRequired = true

                    if (this.date !== null && this.date !== "") {
                        // this.$refs.eta_ref_do.value_.setFullYear(moment().year())
                        if (this.$refs.eta_ref_do.value_.getFullYear() === 2001) {
                            this.$refs.eta_ref_do.value_.setFullYear(moment().year())
                        }
                    }
                }
            }
        },
        onFilter(item, queryText){
            if(item.nickname){
                 return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.nickname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
            }
        return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
                || item.firms_code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())

        },
        iframefile2(file){
            // reset the this.hasfileni in filelist watch
            if(this.hasfileni !=''){
                return (this.hasfileni);
            }else{
                this.hasfileni = URL.createObjectURL(file) + "#toolbar=0";
                return URL.createObjectURL(file) + "#toolbar=0";
           }
        },
        saveDOcontainer(item){
            if(item.state !== 'createdo'){
                this.showSelectMovetoExisting = 0
                this.createdo(item)
            }
        },
        continueClick(x){
          this.dialogAccepted = 0;
          if(x.chosen){
            // SHOW THE MODAL MOVE TO EXISTING DIALOG IF THE CHOSEN IS THE RADIO 2
            this.showSelectMovetoExisting = 1 // show move dialog existing show
            this.dialogAccepted = 0; // move dialog hidden
          }else{
            // CHOSEN RADIO 1
            this.dialogAccepted = 0; // move dialog hidden
            this.createdo(x);
          }
        },
        createdo_inform(item){
            if(item.state == 'createdo'){
                this.dialog = 0
                this.hideshowmove = 1;
                this.$emit('movecontainer',{state: 'createdo'});
            }else{
                this.dialog = 0
                this.hideshowmove = 1;
                this.$emit('movecontainer',{
                                state: 'moveDo',
                                shifl_ref:item.shifl_ref,
                                selected: item.selected
                            }
                        );
                this.$emit('movecontainer',{state: 'moveDo',shifl_ref:item.shifl_ref,selected: item.selected});
            }
        },
        getcheckall(){
            let check = 1;
            if(typeof this.checkboxall[0] =='undefined'){
                check = 0;
            }
            this.containerList.map((e)=>{
                e.checkbox = check
            })

            this.checkboxcontainers = check == 1 ? 1 : 0;
            this.reload = 0;
            this.$nextTick(() => {
                this.reload = 1;
                this.hascheck = check
            });
        },
        checkselected(){
            let hascheckdetec =0;
            this.containerList.map((e)=>{
            if(e.checkbox == 1 ){
                hascheckdetec = 1
            }
            })
            this.checkboxitem =0;
            //this.hascheck = hascheckdetec == 1 ? 1 : 0
            this.reload = 0;
                this.$nextTick(() => {
                    this.reload = 1;
                    this.hascheck  = hascheckdetec == 1 ? 1 : 0
                });

        },
        clearselection(){
            this.containerList.map((e)=>{
                if(e.checkbox == 1 ){
                    e.checkbox =0
                }
            })
            this.checkboxall =[];
            this.hascheck = 0
            this.reload = 0;
            this.$nextTick(() => {
                this.reload = 1;
            });
            this.checkboxcontainers =0;
        },
        moveotherdo(){
            let allselected = 0;
            this.getallDosids= [];
            this.containerList.map((e)=>{
                if(e.checkbox == 1 ){
                    allselected+=1
                    this.getallDosids.push(e)
                }
            })

            if(this.isAdding){
                this.shownotification = 1;
            } else if(this.containerList.length == allselected){
                this.notallowed = 1
                this.movetype = 3;
            }else{
                this.isdialogDos = this.item.id
                this.dialogAccepted = true; // dialog to display the option to move
            }
        },
        checkvalue(item,name){
          console.log(item,name);
            let haserror = 0;
            // if (item =='' || isNaN(item)) {
            //     this.haserrorContainers = true;
            //     haserror = 1;
            //     if(name=='Weight'){
            //         this.errorMessagetext['Weight'] = 'Weight must a numeric value';
            //     }else if(name=='Volume'){
            //         this.errorMessagetext['Volume'] = 'Volume must a numeric value';
            //     }else if(name=='Quantity'){
            //         this.errorMessagetext['Quantity'] = 'Quantity must a numeric value';
            //     }
            // }else{
            //     this.haserrorContainers = false;
            //     if(name=='Weight'){
            //         this.errorMessagetext['Weight'] = '';
            //     }else if(name=='Volume'){
            //         this.errorMessagetext['Volume'] = '';
            //     }else if(name=='Quantity'){
            //         this.errorMessagetext['Quantity'] = '';
            //     }
            // }

            return haserror;
        },
        validatesize(text){
            let value = 0;
            if(typeof text =='undefined'){
                value = 1;
            }else if(text !=''){
                if(text=='NaN'){
                    value = 1;
                }else if(isNaN(text)){
                    value = 1;
                }
            }else if(text==''){
                value = 1;
            }

            if(value==1){
                this.haserrorContainers = true
                this.errorMessagetext['Sizes'] = 'Size is required.';
            } else{
                this.haserrorContainers = false
                this.errorMessagetext['Sizes'] = '';
            }
            return value;
        },
        validateContainerNumber(text){
            let haserror = 0;  // no error
            // let msg = "Container #,First 4 characters are alpha and followed by 7 digits.";
            let msg = "Container Number's first 4 characters are alpha and followed by 7 digits.";
            if(text !='' && typeof text !='undefined'){

                if(text.length == 11) {
                    if (
                    /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
                    /^[0-9]*$/.test(text.substring(4, text.length))
                    ) {
                        msg = '';
                        this.haserrorContainers = false
                        this.errorMessagetext['container'] = '';
                    }  else{
                        this.haserrorContainers = true;
                        haserror  = 1;
                        this.errorMessagetext['container'] = msg;
                    }
                }else{
                    this.haserrorContainers = true;
                    haserror  = 1;
                    this.errorMessagetext['container'] = msg;
                }
            }else if(text ===''){
                haserror  = 1;
                this.errorMessagetext['container'] = msg;
            }
            return haserror;
        },
        cancelAdding(){
        this.calceladding= true;
        this.isAdding = false;
        this.errorMessagetext= {
            container:'',
            Weight:'',
            Volume:'',
            Quantity:'',
            Sizes:'',
        }

        this.containers = {
            container_number: "",
            container_size: "",
            container_weight: "",
            container_volume: "",
            container_qty: "",
            checkbox:0,
            lfd: "",
            uid: ""
        }
        this.hassblickvalidate = 0;
        },
        removeContainer(i){
            if(this.containerList.length > 1){
                this.containerList.splice(i, 1)
                this.haserrorContainers = false
                this.errorMessagetext = {
                    container:''
                }
            }else{
                this.dialogrequired = true;
            }
        },
        async getDeliveryLocation(val) {
            if (val.length > 4) {
                this.loadingLocationPlaces = true;

                await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/`+val+`.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`)
                    .then((response) => response.json())
                    .then((json) => {
                        this.loadingLocationPlaces = false
                        this.places = [...json.features]
                    })
                    .catch((e) => {
                        this.loadingLocationPlaces = false;
                        console.log(e);
                    });
            }
        },
        clearSearchPlaces() {
            this.places = [];
        },
        sizecheck(thechecker){
            let value = (thechecker==='')? false:true;
            if(this.hassblickvalidate){
                if(value==false){
                    this.errorMessagetext['Sizes'] = 'Size is required.';
                } else{
                    value = true;
                    this.errorMessagetext['Sizes'] = '';
                }
            }
            if(value){
               this.errorMessagetext['Sizes'] = '';
            }
            return value;
        },
        rulesCheck(thechecker,name){
            let msg = '';
            let value = false;
            if(isNaN(thechecker)){
                msg = name + ' must be numeric';
            }else{
                msg = '';
                value  = true;
            }

            if(thechecker==''){
                msg = name + ' must be numeric';
                value = false;
            }

            if(this.hassblickvalidate){
                 if(name=='Weight'){
                    this.errorMessagetext['Weight'] = msg;
                 }else if(name=='Volume'){
                    this.errorMessagetext['Volume'] = msg;
                 }else if(name=='Quantity'){
                    this.errorMessagetext['Quantity'] = msg;
                 }
            }
            return value;
        },
        age(text){
            let value = false;

        let msg = "First 4 characters are alpha and followed by 7 digits.";
        if (text.length == 11) {
            if (
            /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
            /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
                msg = '';
                value = true;
            }
        }
        if(this.hassblickvalidate){
            this.errorMessagetext['container'] = msg;
        }
        return value;
        },
        addCOntainer(){
            if(this.isAdding){
                this.containerList.push(this.containers)
                this.isAdding = false
                this.containers = {
                    container_number: "",
                    container_size: "",
                    container_weight: "",
                    container_volume: "",
                    container_qty: "",
                    checkbox:0,
                    lfd: "",
                    uid: ""
                }
            }
            this.isAdding = true;
        },
        select_company(){
            return this.initialcompany;
        },
        cancelEdit(){
            if (this.ismoving_delivery) {
                this.showcancel = 1
            } else {
                this.$emit("closeDialog");
                this.$emit('refresh',true)
                this.clearFieldsDialog()
            }
        },
        getFileURL(c) {
            //this.refreshfile = false;
            this.iframefile = URL.createObjectURL(c);
        },
        clearFieldsDialog() {
          this.$refs.form.resetValidation()
          this.date = null
          this.mbl = ""
          this.CarrierModel = ""
          this.VesselModel = ""
          this.customerdropdown = ""
          this.optionsReferrence = [];
          this.options = [];
          this.containerList = [];
          this.warehouseEmailAddress = '';
          this.containers = {
              container_number: "",
              container_size: "",
              container_weight: "",
              container_volume: "",
              container_qty: "",
              lfd: "",
              uid: ""
          }
          this.terminalAt = ""
          this.delivery_location = ""
          this.container_commodity = ""
          this.notembl = ""
          this.hasfileni = ""
          this.isShowETAError = false
        this.isShowMBLError = false
        this.isShowCustomerError = false
        this.isShowDeliveryError = false
          this.containerList = []
          this.hascheck = 0
          this.checkboxall = []
          this.haserrorContainers = false;
          this.errorMessagetext = {
            container:'',
            Weight:'',
            Volume:'',
            Quantity:'',
            Sizes:'',
          }
          this.selectedConsignee = null
          this.isDateRequired = false
        },
        closedialog(){
            if(this.ismoving_delivery){
                this.showcancel = 1
            }else{
                this.$emit("closeDialog");
                this.$emit('refresh',true)
                this.clearFieldsDialog()
            }
        },
        checklastcontainer(){
            let hassfounderror = false;
            let c=0;
            if(this.isAdding){
                this.containerList.push(this.containers)
                this.isAdding = false
            }
            // let valem = '';
            Object.values(this.containerList).forEach((val) => {
                // if(typeof val.container_size =='undefined'){
                //     valem = '';
                // }else{
                //     valem = val.container_size
                // }
              c +=this.validateContainerNumber(val.container_number)
                  // this.validatesize(valem) +
                  // this.checkvalue(val.container_weight,'Weight') +
                  // this.checkvalue(val.container_volume,'Volume') +
                  // this.checkvalue(val.container_qty,'Quantity')
            })

            if(c > 0){
                hassfounderror = true;
            }
            return hassfounderror;
        },
        saveEdit(){
            // haserrorContainers = true ; naay error
            // checkcontainer = true ; naay error
            if(this.checklastcontainer() || this.haserrorContainers){
                this.dialogcontainervalidate = true;
            } else if (this.checkFormErrors()) {
                this.notificationCustom("Please fill the required fields.")
            } else{
                this.clicsubmit();
            }         
        },
        viewdo(){
             window.location.href = '/delivery-order/'+ this.thelinkreturn + '/' + this.sid;
        },
        usernotification(){
            this.istriggerdfrommodal = 1;
            this.shownotification = 0;
        },
        classCheck(cate){
            if(cate.error == 1){
                return 'triggerclasserror';
            }
            return '';
        },
        isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); },
        calleditable(){
            return this.calleditableELe;
        },
        edit(){
           return this.calleditableELe = true;
        },
        clickDownload(){
            this.gotoDownload();
        },
        uploadAgain(){
            jQuery(document).find('.triggerelement').trigger('click');
        },
        timetopass(){
            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                    return o.text
                }) : []
                return finalEmailAddress;
        },
         timetopassreference(){
            // let finalEmailAddress = (this.optionsReferrence.length > 0) ? this.optionsReferrence.map(o => {
            //         return o.text
            //     }) : []
            //     return finalEmailAddress;
            let finalrefrenceNumber = this.optionsReferrence.length > 0 ?  this.optionsReferrence.map(({text}) => ({customer_ref_number:text})) :[]
            return finalrefrenceNumber;
        },
        ...mapActions({
			fetchFormdetails: 'containers/fetchallformdetails',
            fetchConsignees: 'containers/fetchConsignees',
            createConsignee: 'containers/createConsignee'
        }),
        ...globalMethods,
        blurevent(){
           this.isdatareload = true;
           this.haschages()
           this.checkobjects()
        },
        currentshow(index){
            if(index ==  this.currentindexshow)
            return 1;
        },
        checkeverything(){
            let getcustomerref  = JSON.stringify(this.timetopassreference());
            let contactlist     = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;
            let changes;

            if(this.doInitialvalue !=this.dofinalvalue){
                changes = 1
            }else{
                changes = 0
            }

            if(this.triggerloc=='cancel'){
                return changes;
            }else{
                this.haschangesform = changes
            }

            this.containerlistchanges();
        },
        // magamit if click sa save sa taas
        checkobjects(){
            if((this.mbl !== null && this.mbl.length > 0)
                && (this.customerdropdown) !=''
                && this.CarrierModel !=''
                && this.VesselModel !=''
                && this.terminalAt !=''
                //&& this.options.length > 0
                && this.optionsReferrence.length > 0)
            {
                this.noerror = 1
                this.noerrorForm = false;
            }else{
                this.noerror = 0
                this.noerrorForm = true;
            }
        },
         async callpopulatedialog(){
            this.startfetching = 1;
            this.containerList = [];
            var that = this;
            var validEmailAddress = [],ref = [];
            this.isLoading = true

            let url = "get-do-edit-accepted"

            if (this.item.reviewStatus === "Pending") {
                url = "get-incoming-do-by-id"
            }

            await axios.get(`${poBaseUrl}/${url}/` + this.item.id)
            // .get(`${poBaseUrl}/get-do-edit-accepted/` + this.item.id)            
            .then(function (response) {
                if (response.data) {
                    let getID = 0;
                    if(response.data.deliveryorder_docu.length > 0){
                        getID = response.data.deliveryorder_docu.find(obj => {
                        return obj;
                        });
                        that.hasfile = 0
                        that.docuID = getID.id;
                        that.filenameobj = getID.filename
                        // call here
                        that.downloadDocument(getID.id, getID)
                    }else{
                        that.filelist = [];
                    }

                  let etaDate = response.data.result[0].eta

                  that.carriers = response.data.carrier
                  that.ContainerSize= response.data.sizes
                  //console.log(response.data.carrier);
                  that.terminals = response.data.terminal;
                  // that.date = moment(response.data.result[0].eta).utc(false).format("YYYY-MM-DD");
                  that.date = etaDate !== "" && etaDate !== null ? moment(etaDate).utc(false).format("YYYY-MM-DD") : null;
                  //// alert(response.data.result.eta)
                  that.mbl = response.data.result[0].mbl_number;
                  that.pickup_location = response.data.result[0].location_from;

                  that.customers=response.data.customer
                  that.tempcustomer=response.data.customer
                  that.company(response);

                  if(response.data.result[0].customer != null){
                    that.customerdropdown = response.data.result[0].customer
                    that.selectedConsignee = response.data.result[0].consignee_id
                    that.fetchConsigneeAction(that.customerdropdown)
                  }

                  that.terminalAt = response.data.result[0].terminal_id ?? null

                  that.typeoflocation = response.data.result[0].source_of_creation;
                  if(response.data.result[0].delivery_location !=='' ){
                    that.delivery_location = response.data.result[0].delivery_location ?? '';
                    if(that.delivery_location != null){
                        that.getDeliveryLocation(that.delivery_location);
                    }
                  }else{
                    that.delivery_location = '';
                  }

                  //description


                  if(response.data.result[0].mode!==''){
                    that.delivery_mode = response.data.result[0].mode
                  }

                  that.options = that.contacts(response,validEmailAddress);
                  that.CarrierModel = response.data.result[0].carrier_id;

                  //   that.orderdeliveryID =  response.data.data.shifl_ref
                     that.VesselModel =  response.data.result[0].vessel

                  /*
                    var refer = response.data.result[0].customer_ref_number
                    if( refer.length > 0 ){
                        (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {
                                    this.crn_tags.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                        })
                    }

                  */
                    var refer = response.data.result[0].customer_ref_number
                    if(refer !==null){
                    if( refer.length > 0 ){
                        (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {
                                    ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                        })
                    }

                    that.optionsReferrence = ref
                    }
                  //   that.terminalAt = response.data.data.terminal_id

                  if(response.data.result[0].container_commodity != null) {
                      that.container_commodity = response.data.result[0].container_commodity;
                  }

                    if(response.data.result[0].description != null) {
                        that.notembl = response.data.result[0].description;
                    }

                    //var getcontainersfromdelivery = response.data.delivery_containers;

                    // get the containers
                    let reviewStatus = response.data.result[0].reviewStatus

                    if (reviewStatus === "Accepted") {
                        let get_the_containers = response.data.result[0].deliveryordershipment;

                        if(get_the_containers !=null){
                            if(get_the_containers.length > 0){
                                get_the_containers.map((wea,i) => {
                                    if (wea!==null) {
                                        that.containerList.push({
                                            id:i,
                                            container_id : wea.shipment_id,
                                            container_number: wea.container_number,
                                            container_size: parseInt(wea.size),
                                            container_volume: wea.volume,
                                            container_weight: wea.weight,
                                            container_qty: wea.qty,
                                            delivery_to :  '', // response.data.data.deliver_to ,
                                            //lfd: wea.lfd,
                                            checkbox:0,
                                            error_0:0
                                        })
                                    }
                                })
                            }
                        }
                    } else {
                        let get_the_containers = response.data.result[0].containers;

                        if (response.data.result[0].source_of_creation == 'CF'  ||
                            response.data.result[0].source_of_creation == 'BO') {
                            that.pcontain(get_the_containers, 'ac', response);
                        } else if (response.data.result[0].source_of_creation == 'SD'){
                            let sdcontainers = response.data.result[0].containers_group_bookings;
                            that.pcontain(sdcontainers, 'bc', response);
                        }
                    }

                    that.refresh = 0;
                    that.isLoading = false

                    that.$nextTick(() => {
                        that.refresh = 1;
                    });
                    that.startfetching = 0;

                    }
            }).catch(error => {
                console.log(error)
                that.startfetching = 0;
            })
        },
        pcontain(containers,loc,response){
            let that = this;
            if(containers !=null){
            if(containers.length > 0){
                let containersListdraft  = JSON.parse(containers)
                let source_of_creation = response.data.result[0].source_of_creation

                // console.log(source_of_creation);

                containersListdraft.map((wea,i) => {
                    if (wea!==null) {
                        that.containerList.push({
                            id: i,
                            container_id :( loc == 'bc' ) ? '' : ( response.data.result[0].source_of_creation == 'BO' ? wea.container_id : '' ) ,
                            container_number: (loc == 'bc') ?  wea.container_num : wea.Container,
                            container_size: (loc == 'bc') ? wea.size : parseInt(wea.Sizes),
                            container_volume: (loc == 'bc') ? wea.cbm : wea.VolumeNumberofCBM,
                            container_weight: (loc == 'bc') ? wea.kg : wea.WeightNumberofKG,
                            container_qty: (loc == 'bc') ? wea.cartons : wea.CartonCount,
                            delivery_to :  '',
                            checked:0,
                            error_0:0
                        })

                        if (source_of_creation === "SD") {
                            that.containerList[i].uid = typeof wea.id !== "undefined" ? wea.id : ""
                        }

                        if (source_of_creation === "CF") {
                            that.containerList[i].lfd = typeof wea.lfd !== "undefined" ? wea.lfd : ""
                        }
                    }
                })
            }}
        },
        getdata(item){
            if(this.currentstatepicker == 'date'){
                this.date = item;
            }
        },
        haschages(){
            let getcustomerref = JSON.stringify(this.timetopassreference());
            let contactlist = JSON.stringify(this.timetopass())
            this.dofinalvalue   = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;

            if(this.doInitialvalue !=this.dofinalvalue){
               this.haschangesform = 1
            }else{
               this.haschangesform = 0
            }

        },
       async createdo(item){
        var that = this;
            that.ismoving_delivery = true;
            if(item.state=='createdo'){
                that.cleancontainerlist(that.containerList);
                that.ismove = 1; // copy ug magbuhat ug bag-ong delivery

                // clear all fields
                this.date = null
                this.mbl = ""
                this.CarrierModel = ""
                this.VesselModel = ""
                this.customerdropdown = ""
                this.optionsReferrence = [];
                this.options = [];
                this.warehouseEmailAddress = '';
                this.terminalAt = ""
                this.delivery_location = ""
                this.container_commodity = ""
                this.notembl = ""
                this.hasfileni = ""
                this.isShowETAError = false
                this.hascheck = 0
                this.checkboxall = []
                this.haserrorContainers = false;
                this.errorMessagetext = {
                    container:'',
                    Weight:'',
                    Volume:'',
                    Quantity:'',
                    Sizes:'',
                }
            }else if(item.state=='moveDO'){
                that.movetoexisting = 1;
                that.selectedExistingDeliveryID = item.selected; // Do id
                that.selectedshifl_ref = item.shifl_ref; // Do id
                // Fetch the containers of the selected DO
                // SelectedExistingDeliveryID
                // kuhaon para e merge ang duha selected ug moving do containers


                   // lihug dili e delete kay magamit ni puhon
                    //gamit e merge ang selected DO with the current DO to be moved
                    await axios.get(`${poBaseUrl}/gethipmentbydeliveryorderid/`+item.selected)
                    .then(function (response) {
                        if (response.data) {
                            var getobject = _.filter(that.containerList,function(o){return o.checked;});
                            that.containerList = getobject
                            that.existingdeliveryobject = response.data.data
                            response.data.data.map(wea => {
                                if (wea!==null) {
                                    // push to get the existing containers
                                that.pushobject(wea);
                                }
                            })
                            }
                    }).catch(error => {
                        console.log(error)
                    })


                var validEmailAddress = [],ref = [];
                this.isLoading = true

            await axios
            .get(`${poBaseUrl}/get-do-edit-accepted/` + item.selected
            )
            .then(function (response) {
                if (response.data) {
                    let getID = 0;
                    if(response.data.deliveryorder_docu.length > 0){
                        getID = response.data.deliveryorder_docu.find(obj => {
                        return obj;
                        });
                        that.hasfile = 0
                        that.docuID = getID.id;
                        that.filenameobj = getID.filename
                    }

                  that.carriers = response.data.carrier
                  that.ContainerSize= response.data.sizes
                  //console.log(response.data.carrier);
                  that.terminals = response.data.terminal;
                  that.date = moment(response.data.result[0].eta).utc(false).format("YYYY-MM-DD");
                  //// alert(response.data.result.eta)
                  that.mbl = response.data.result[0].mbl_number;
                  that.pickup_location = response.data.result[0].location_from;

                  that.customers=response.data.customer
                  that.tempcustomer=response.data.customer
                  that.company(response);

                  if(response.data.result[0].customer !=null){
                    that.customerdropdown = response.data.result[0].customer
                  }

                  that.terminalAt = response.data.result[0].terminal_id ?? null

                  that.typeoflocation = response.data.result[0].source_of_creation;
                  if(response.data.result[0].delivery_location !=='' ){
                    that.delivery_location = response.data.result[0].delivery_location ?? '';
                    if(that.delivery_location != null){
                        that.getDeliveryLocation(that.delivery_location);
                    }
                  }else{
                    that.delivery_location = '';
                  }

                  //description


                  if(response.data.result[0].mode!==''){
                    that.delivery_mode = response.data.result[0].mode
                  }

                  that.options = that.contacts(response,validEmailAddress);
                  that.CarrierModel = response.data.result[0].carrier_id;

                  //   that.orderdeliveryID =  response.data.data.shifl_ref
                     that.VesselModel =  response.data.result[0].vessel

                  /*
                    var refer = response.data.result[0].customer_ref_number
                    if( refer.length > 0 ){
                        (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {
                                    this.crn_tags.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                        })
                    }

                  */
                    var refer = response.data.result[0].customer_ref_number
                    if(refer !==null){
                    if( refer.length > 0 ){
                        (typeof refer === "object" ? refer :  JSON.parse(refer)).map(wea => {
                                if (wea!==null) {
                                    ref.push({text: wea.customer_ref_number,tiClasses:["ti-valid"]})
                                }
                        })
                    }

                    that.optionsReferrence = ref
                    }
                  //   that.terminalAt = response.data.data.terminal_id

                  if(response.data.result[0].container_commodity != null) {
                      that.container_commodity = response.data.result[0].container_commodity;
                  }

                    if(response.data.result[0].description != null) {
                        that.notembl = response.data.result[0].description;
                    }

                    //var getcontainersfromdelivery = response.data.delivery_containers;
                    that.containerList = [];
                    that.containerList = that.getallDosids // restore the selected dos
                    that.containerList.map((wea) => {
                        if (wea!==null) {
                            wea.checkbox = 0
                        }
                    })

                    // get the containers
                    let get_the_containers = response.data.result[0].deliveryordershipment;
                    if(get_the_containers !=null){
                        if(get_the_containers.length > 0){
                            get_the_containers.map((wea,i) => {
                                if (wea!==null) {
                                    that.containerList.push({
                                        id:i,
                                        container_id : wea.shipment_id,
                                        container_number: wea.container_number,
                                        container_size: parseInt(wea.size),
                                        container_volume: wea.volume,
                                        container_weight: wea.weight,
                                        container_qty: wea.qty,
                                        delivery_to :  '',
                                        checkbox:0,
                                    })
                                }
                            })
                        }
                    }
                    that.showselect = false;
                    that.refresh = 0;
                    that.isLoading = false

                    that.$nextTick(() => {
                        that.refresh = 1;
                    });
                    that.startfetching = 0;

                    }
            }).catch(error => {
                console.log(error)
            })

                //this.cleancontainerlist(that.containerList);
                this.ismove = 2;
            }
        },
        closeDialogpicker(){
            this.dialogDatepicker = false;
        },
        deleteClear(i){
            this.containerList.splice(i, 1);
            this.checkobjects()
        },
        cleancontainerlist(clList){
            this.containerList = _.filter(clList,function(o){return o.checkbox;});
        },
        pushobject(wea){
             var that = this;
             that.containerList.push({
                container_id : wea.shipment_id,
                container_number: wea.container_number,
                container_size:wea.size,
                container_volume: wea.volume,
                container_weight: wea.weight,
                container_qty: wea.qty,
                delivery_to :  wea.address, // response.data.data.deliver_to ,
                checkbox:0,
                error_0:0
                //error_1:0,error_2:0,error_3:0,error_4:0
            })
            // console.log(this.containerList)
            that.refresh = 0;
            that.$nextTick(() => {
                that.refresh = 1;
            });
        },
        saveForm(){
            //var redirectid = 0;
            this.startloading = 1;
            this.isSubmittingForm = true
            this.sid = this.$route.params.sid;
            this.thdid = this.$route.params.id;

            let formData = new FormData(document.getElementById('sform'))
            formData.append('xmbl',this.mbl);

            if(this.CarrierModel !== null && typeof this.CarrierModel !=='object'){
                var carriene = this.carriers.find((d) => d.id == this.CarrierModel);
                this.CarrierModel = carriene;
            } else {
              this.CarrierModel = ""
            }

            let terminalData = ""
            if (this.terminalAt !== null && typeof this.terminalAt=='object') {
              terminalData = this.terminalAt.id
            }

            let eta = this.date !== null ? moment(this.date).format("YYYY-MM-DD") : ""

            formData.append("ETA", eta);
            formData.append('flagCreatedFE',1);
            formData.append('xcustomer',(typeof this.customerdropdown=='object') ? this.customerdropdown.id : this.customerdropdown);
            formData.append('xcontacts[]',this.timetopass());
            formData.append('xcarrier', (typeof this.CarrierModel=='object') ? this.CarrierModel.id : this.CarrierModel);
            //formData.append('xcarrier_name',(this.CarrierModel.name));
            formData.append('xvessel',this.VesselModel);

            formData.append('do_move_mode',this.ismove);
            if(this.ismove==2){
                //redirectid = this.selectedExistingDeliveryID
                formData.append('existing_do_id',this.selectedExistingDeliveryID); // selected delivery order
                formData.append('xedit_id_of_moved_do',this.item.id); // id of the delivery order which containers to be moved
            }
            // do_move_mode
            // 1 is move or copy
            // 2 is move to existing
            // 0 is default
            formData.append('xreferrence[]', JSON.stringify(this.timetopassreference()));
            formData.append('xlocation_at', terminalData); // Change into ID
            // formData.append('xlocation_at_name',this.terminalAt.name);
            formData.append('xnote_mbl',this.notembl);
            formData.append('xdelivery_location', this.delivery_location)
            formData.append('xcontainerList', JSON.stringify(this.containerList));
            formData.append('container_commodity',(this.container_commodity));
            formData.append('xedit',this.item.id);
            formData.append("consignee_id", this.selectedConsignee);

            // this.filelist.map((file, key) => {
            //     formData.append(`files[${key}]`, file);
            // });

            let url = "orderdelivery-update"
            if (this.item.reviewStatus === "Pending" || this.item.reviewStatus === "Rejected") {
                url = `edit-pending-do/${this.item.id}`
                formData.append("_method", "PUT");
            }

            axios.post(`${poBaseUrl}/${url}`, formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                this.isSubmittingForm = false
            }).then(response => {
                this.startloading = 0;
                this.isSubmittingForm = false
                this.showcontaineradded = 0;
                if(response){
                    this.DO_ID            = response.DO_ID;
                    this.shipmentIDS      = response.shipment_id
                    this.containers_number= response.containers_number
                    this.countshipment    = response.count_shipment
                    this.confirm_message  = response.confirm_message

                    this.dialogConfirm = true;
                    this.thelinkreturn = response.DO_ID;
                    this.snackbarcontent = true;
                    if(this.ismove==1){
                       // this.snackbar = true;
                     }else if(this.ismove==2){
                        //this.snackbar = true;
                        this.isSavingConfirm = true;
                    }else{
                        //this.snackbar = true;
                        this.snackbarcontent = false;
                    }
                }
            })
        },
        clicsubmit(){
            if( this.$refs.form.validate() ){
                //this.dialogConfirm = true;
                if(this.containerList.length === 0 ){
                    this.dialogrequired = true;
                } else {
                    this.saveForm();
                    // console.log(this.containerList, 'here');
                }
            }
        },
        goback(){
            location.reload(true);
        },
        cancelDialog(item){
            if(item.isCancel)
            this.dialogConfirm = false;
        },
        continueDialog(item){
            if(item.isConfirmed){
               // this.dialogConfirm = false;
               this.dialogConfirm = false;
               //location.reload();
               /*if(item.isConfirmed){
                    this.saveForm();
                }*/
            }
        },
        continueDialogUpdateBox(){
            location.reload(true);
        },
        saveUpdated(item){
            if(item.updated)  {
               this.containerList = item.data;
            }

            if(item.hasError){
                this.noerror = 0
                this.noerrorForm = true;
            } else {
                this.noerror = 1
                this.noerrorForm = false;
            }
        },
        validateDate(dateStr){
            let str = dateStr.split('T');
            dateStr = str[0];
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            if (dateStr.match(regex) === null) {
            return false;
            }
            const date = new Date(dateStr);
            const timestamp = date.getTime();
            if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
            }
            return date.toISOString().startsWith(dateStr);
        },
        showcustom(state,m){
            this.pickerDefaultdata = state;
            this.currentstatepicker = m;
            this.dialogDatepicker = true;
        },
        checklengthdata(item){
            console.log(item)
            if(item.value===0)
            {
                 this.containerList = [];
            }
            this.checkobjects()
        },
        emitedit(data){
            this.showedit = data.show;
            this.keysvalue = data.data[data.index]
            this.keytovalue = data.index
        },
         updateContainer(item){
            console.log(item)
            //this.containerList[this.keytovalue] =  item
            var sizesize  = (item.container_size)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            if(typeof getsize !='undefined') {
                item.container_size = String(getsize.id)
            }
            this.containerList[this.keytovalue] =  item

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {
                data = {
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            "delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }
                dis.getlistasstring.push(data);
            })


            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });

            this.checkobjects()
            this.checkeverything();
        },
        saveContainer(item){
            var sizesize  = (item.container_size)
            var dt  = (item.delivery_to)
            var getsize = this.ContainerSize.find(e=>{
                if(e.id == sizesize){
                    return e
                }
            })

            item.container_size = (getsize.id)
            this.lastIndexData = dt
            this.containerList.push(item);

            let data = [];
            var dis  = this;
            this.getlistasstring = [];
            this.containerList.map((e)=>
            {
                data = {
                            "container_id":e.container_id,
                            "container_number":e.container_number,
                            "container_size":String(e.container_size),
                            "container_volume":e.container_volume,
                            "container_weight":e.container_weight,
                            "container_qty":e.container_qty,
                            "delivery_to":e.delivery_to,
                            "error_0":0,
                            "checked":0
                        }
                dis.getlistasstring.push(data);
            })

            this.refresh = 0;
            this.$nextTick(() => {
                this.refresh = 1;
            });
           // this.updated = 1
            this.checkobjects()
            this.checkeverything();
            this.showcontaineradded = 1;
           // this.addContainerNotification('<span style="color:#16B442 !important" class="text-message">Container was successfully added</span>')
        },
        confirmCancel(){
            if(this.triggerloc !=''){
                this.redirect();
            }else{
                location.reload()
            }
        },
        closecancel(){
            this.showcancel = 0
        },
        cancel() {
            if(this.ismove){
                this.showcancel = 1
            }else{
                this.triggerloc='cancel'
                if(this.checkeverything()){
                    this.showcancel = 1
                }else{
                    this.redirect();
                }
            }
        },
        formReset(){
             this.$router.push('/containers')
        },
        closenotallowed(c){
            if(c){
                this.notallowed = 0;
            }
        },
        redirect(){
            if(this.$route.params.sid){
                this.$router.push('/container/edit/'+this.$route.params.sid)
            }else{
                this.$router.push('/containers')
            }
        },
        containerlistchanges(){
            this.finalcontainerListdata = this.getlistasstring;
            var einit = (this.initcontainerListdata);
            var finalval = JSON.stringify(this.finalcontainerListdata);
            let loctype;

            if(einit === finalval ){
                loctype = 0;
            }else{
                loctype = 1;
            }

            if(this.triggerloc=='cancel'){
              return loctype;
            }else{
              this.haschangesform = loctype;
            }
        },
        addContainer(){
            var s = this.containerList;
            console.log(s);
            this.dialog = true;
        },
        dataDown(value){
            this.formFieldlength++;
            this.currentDropdownState = value
            this.currentSelectTab = value
        },
        checkindex(index){
            this.currentindexshow = index
            jQuery('.tablecontainer').css({'background':'none'})
            jQuery(".blueteam_"+index).css({'background':'#F0FBFF'});
        },
        getPrelims: function(data) {
          let response = data;
          let customers = (response.data.customers);

          let DataCustomer=[];
              customers.forEach((value) => {
              DataCustomer.push({id:value.id ,name:value.company_name});
          });

          this.itemsDropdown = DataCustomer;

          let terminal_at = (response.data.terminal);
              terminal_at.forEach((value) => {
              this.TERMINAL_AT.push({id:value.id,name:value.name});
          });

          let size = (response.data.size);
              size.forEach((value) => {
              this.containerSize.push({id:value.id,name:value.name});
          });

          let containertype = (response.data.type);
              containertype.forEach((value) => {
              this.containerType.push({id:value.id,name:value.name});
          });

          let truckersID = (response.data.trucker);
              truckersID.forEach((value) => {
              this.truckers.push({id:value.id,name:value.name});
          });
          this.startloading = 0;
        },
        onChange() {
            // this.filelist = [...this.$refs.file.files];
            // let table = this.filelist;
            // let errorCount = 0;
            // let keys = [];

            // Object.keys(table)
            // .forEach(function eachKey(key){
            //     if(table[key].type !='application/pdf')
            //     {
            //         keys.push(key)
            //         errorCount++;
            //     }
            // });

            // if(errorCount> 0) {
            //     this.errorFile =1
            //     this.allFieldsValid = false;
            // }  else{
            //     this.errorFile =0
            //     this.allFieldsValid = true;
            // }

            // keys.forEach(e => {
            //     this.filelist.splice(e, keys.length+1)
            // });

            // if(this.filelist.length > 1) {
            //     this.filelist.splice(1, this.filelist.length)
            //     this.moreFilesError = true
            // }
            // else {
            //     this.moreFilesError = false
            // }

            let files = this.$refs.file.files;

            if (!files.length) {
                return false;
            }

            if (files[0].type !== "application/pdf") {
                this.notificationCustom("Invalid File Type")
            } else {
                this.filelist = files
            }
        },
        remove(i,e) {
        this.uploadTrigger = false;
        try {
            axios.delete(`${poBaseUrl}/document/remove/` + e.id)
            .then((response) => {
                if(response.status === 200) {
                    this.hasContent = 'hasContentFiles2'
                    this.documents = []
                }

                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.responseDatatempt = response;
            })

            this.filelist.splice(i, 1);
            if( this.filelist.length == 0){
                this.errorFile = 0
                this.allFieldsValid = true;
            }
        } catch (err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
    },
    handleAddContainer(){
        this.hassblickvalidate = 1;
        if(this.$refs.containerForm.validate()) {
            this.hassblickvalidate = 0;
            this.containerList.push(this.containers)
            this.isAdding = false
            this.containers = {
                container_number: "",
                container_size: "",
                container_weight: "",
                container_volume: "",
                container_qty: "",
                lfd: "",
                uid: ""
            }
        }
    },
    gotoDownload(){
        let passdata = this.objectServerid.id
        this.isFetching = 1;
        if(passdata !==''){
            axios({
                url: `${poBaseUrl}/newshipment/download/${passdata}`,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                this.isFetching = 0;
                window.URL = window.URL || window.webkitURL;
                const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data],{type: "application/octet-stream"}));
                    link.setAttribute('download', 'Delivery_order.pdf');
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link);
            })
        }
    },
    contacts(response,validEmailAddress){
         if(  response.data.result[0].contacts !=='null' && response.data.result[0].contacts !=null){
            var s = JSON.parse(response.data.result[0].contacts);
                s.map(wea => {
                    if (wea!==null) {
                        validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                    }
                })
            return validEmailAddress
        }
    },
    company(response){
            var that = this;
            if(response.data.result[0].company_name !=null && response.data.result[0].company_name !=''){
                // const newFirstElement = {'company_name':response.data.result[0].company_name,id:999999}
                // that.initialcustomer_name = response.data.result[0].company_name;
                // that.customers = [newFirstElement].concat(that.customers)
                that.initialcompany = response.data.result[0].company_name
            } else {
                that.initialcompany = "Select Customer"
            }
        },
    downloadDocument(documentID, data){
        //alert(this.hasfileni)
        let passdata = documentID
        let that = this;
        this.isFetching = 1;
        let c = [];

        let is_generated = data.is_generated === 1 ? true : false

        if(passdata !==''){
            if (!is_generated) {
                axios({
                    url: `${poBaseUrl}/document/download/${passdata}`,
                    method: 'GET',
                    responseType: 'blob'
                })
                .then((response) => {
                    this.isFetching = 0;
                    window.URL = window.URL || window.webkitURL;
                    //const link = document.createElement('a');
                    var json =(response.data);
                    c[0] = new File([json], that.filenameobj , { lastModified: new Date().getTime(), type: 'application/pdf' })
                    // console.log(c)
                    this.filelist = c;
                }).catch(error => {
                    console.log(error);
                    this.isFetching = 0;
                })
            } else {
                this.callGeneratedDocument(data)
            }
        }
    },
    callGeneratedDocument(data) {
        let that = this;
        this.isFetching = 1;
        let c = [];

        let payload = {
            path: data.filepath,
            original_name: data.original_name
        }

        axios.post(`${baseUrl}/v2/shipment/do-document`, payload, {
            responseType: 'blob'
        })
        .then((response) => {
            this.isFetching = 0;
            window.URL = window.URL || window.webkitURL;
            //const link = document.createElement('a');
            var json =(response.data);
            c[0] = new File([json], that.filenameobj , { lastModified: new Date().getTime(), type: 'application/pdf' })
            console.log(c)
            this.filelist = c;
        }).catch(error => {
            console.log(error);
            this.isFetching = 0;
        })
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    async callUPdocument(data){
        if(data)
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        var formData = new FormData();
        let idparam  = (this.selectedExistingDeliveryID !=0) ? this.selectedExistingDeliveryID : this.$route.params.id;
        try {
            formData.append(`deliveryOrderId`, idparam)
            data.map((file, key) => {
                formData.append(`files[${key}]`, file)
            })
            await axios.post(`${poBaseUrl}/document/upload_file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    this.filelist = []
                    this.documents = response.data.data
                }
                return response.data
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
      },
      handleReplace() {
        this.$refs.file.click()
        //this.document = documentID
        // jQuery(document).find(".triggerelement").trigger("click");
        //jQuery(document).find('#replaceFieldHandle').trigger('click');
      },
      onReplace() {
        let file = document.getElementById('replaceFieldHandle');
        this.filelist = file.files
        let table = this.filelist;
        let errorCount = 0;

        Object.keys(table)
        .forEach(function eachKey(key){
            if(table[key].type !='application/pdf')
            {
                errorCount++;
            }
        });

        if(errorCount> 0) {
            this.errorFile =1
            this.allFieldsValid = false;
            this.filelist = []
        } else{
            this.errorFile =0
            this.allFieldsValid = true;
            this.replaceDocument()
        }
      },
      replaceDocument() {
        this.hasContent = 'cDownloadfiles hidethisdiv'
        this.uploadCompleted = false;
        this.uploadTrigger = true;
        this.serverObject = [];
        this.documents = []

        var formData = new FormData();
        try {
            formData.append(`files[0]`, this.filelist[0])

            axios.post(`${poBaseUrl}/document/replace_file/${this.document}`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                this.filelist = []
                this.documents = response.data.data
                this.uploadCompleted = true
                this.uploadTrigger = false
            }).catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            }).then(response => {
                this.uploadCompleted = true;
                this.serverObject = response.data;
                this.objectServerid =  response.data.find(d => d.id !='')
            })
        }
        catch(err) {
            document.querySelector('.fileuploaderror').innerHTML = err.message;
            setTimeout(()=>{
                document.querySelector('.fileuploaderror').style.display = 'none';
            }, 5000)
        }
      },
    handleETAValueChange(e) {
        if (e.value === null) {
            this.isShowETAError = true
        } else {
            this.isShowETAError = false
            this.date = this.date !== '' && this.date !== null ? moment(this.date).format("YYYY-MM-DD") : ""
        }
    },
    removeFileUpload() {
        this.filelist = [];
        this.hasfileni = "";
    }
    },
    updated(){
        if(this.ismove==1){
            if(this.istriggerdfrommodal==0){
                this.shownotification = 1;
            }
        }
    },
    async mounted(){
        // await this.getOrderbyId()
        // this.checkobjects()
        // this.initcontainerListdata = JSON.stringify(this.containerList);
        // let getcustomerref = JSON.stringify(this.timetopassreference());
        // let contactlist = JSON.stringify(this.timetopass())
        // this.doInitialvalue = JSON.stringify(this.date+this.mbl+this.customerdropdown+this.CarrierModel+this.VesselModel+ this.terminalAt+ this.container_commodity+ this.notembl)+getcustomerref+contactlist;
        this.$store.dispatch("page/setPage", "Dos");
    }
};
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/containers/containersTable.scss';
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../../assets/scss/buttons.scss';
@import "../../../../assets/scss/vc_calendar.scss";
@import "../../../../assets/scss/pages_scss/deliveryorder/createDo.scss";

.dialogClassDos.create-do {
	.v-card {
        .v-card__title {
            border-bottom: 2px solid #ebf2f5 !important;
        }

		.v-card__text {
            .deliveryOrderColumn {
                width: 100% !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
                margin-bottom: 0 !important;
                padding-top: 0 !important;
            }

            .text-item-label {
                font-family: 'Inter-SemiBold', sans-serif !important;
                font-size: 10px !important;
                text-transform: uppercase !important;
            }

            .v-input {
                .v-input__control {
                    .v-input__slot {
                        padding: 4px 12px !important;

                        &::after {
                            background-color: #4a4a4a !important;
                            border-color: #4a4a4a !important;
                        }
                    }
                }

                &.icon-append-custom {
                    .v-input__control {
                        .v-input__slot {
                            .v-select__slot {
                                .v-input__append-inner {
                                    margin-top: 4px !important;

                                    i {
                                        color: #4a4a4a !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &.textarea-commodity {
                    .v-input__control {
                        .v-input__slot {
                            padding-top: 8px !important;
                            padding-right: 4px !important; // to set padding outside the textarea

                            textarea {
                                font-size: 14px !important;
                                line-height: 20px;
                                padding-right: 12px;
                                font-family: 'Inter-Regular', sans-serif;
                            }
                        }
                    }
                }

                &.create-do-eta {
                    .v-input__control {
                        .v-input__slot {
                            .v-input__append-inner {
                                padding-left: 0;

                                .v-input__icon {
                                    .ic-calendar {
                                    padding-top: 6px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.select-consignee.v-input--is-disabled {
                    .v-input__control {
                        .v-input__slot {
                            background-color: #EBF2F5;
                            border-color: #EBF2F5 !important;
                            border-radius: 4px;
                        }
                    }
                }

                input {
                    font-family: 'Inter-Regular', sans-serif !important;
                    color: #4a4a4a !important;
                }
            }

            .row-locations {
                .box {
                    display: flex;
                    flex-direction: column;

                    .text-item-label {
                        margin-bottom: 4px;
                    }

                    .v-input {
                        &.Goodsdeliverylocation {
                            .v-input__control {
                                height: 100%;

                                .v-input__slot{
                                    height: 100%;
                                    padding: 8px 12px !important;

                                    .v-select__slot {
                                        min-height: 60px !important;

                                        .v-select__selections {
                                            align-self: flex-start;
                                            flex-wrap: wrap;
                                        }

                                        input {
                                            align-self: flex-start;
                                            padding: 0;
                                        }

                                        .v-label {
                                            color: #4a4a4a!important;
                                            font-family: "Inter-Regular", sans-serif;
                                            font-size: 14px;
                                            position: unset !important;
                                            overflow: unset !important;
                                            white-space: normal !important;
                                            height: 100% !important;
                                            padding: 0;
                                        }

                                        .v-input__append-inner{
                                            display: block !important;

                                            .v-input__icon {
                                                height: 20px;

                                                i {
                                                    transition: none !important;
                                                    transform: none !important;

                                                    &.mdi-menu-down {
                                                        width: 26px !important;

                                                        &::before {
                                                            content: '' !important;
                                                            background-image: url('/icons/search-address.svg') !important;
                                                            background-position: center !important;
                                                            background-repeat: no-repeat !important;
                                                            background-size: cover !important;
                                                            width: 16px !important;
                                                            height: 18px !important;
                                                            opacity: .9;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.container-starting-location {
                                .v-menu__content {
                                    .v-list {
                                        padding: 0 !important;

                                        .v-list-item {
                                            border-bottom: 1px solid #EBF2F5;
                                            padding: 6px 16px;

                                            .listDropdown {
                                                color: #4a4a4a !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // dxdatebox ETA
            .create-do-eta-dxdate {
                height: 42px;
                border: 1px solid #B4CFE0;
                border-radius: 4px;

                input {
                    font-family: 'Inter-Regular', sans-serif;
                    caret-color: #4a4a4a;
                    color: #4a4a4a;
                    padding-left: 12px;
                }

                .dx-placeholder {
                    color: #B4CFE0 !important;

                    &::before {
                        padding-left: 12px;
                    }
                }

                .dx-texteditor-buttons-container {
                    .dx-dropdowneditor-button {
                        width: 45px;

                        .dx-dropdowneditor-icon {
                            width: 45px;
                            color: #4a4a4a;

                            &::before {
                            content: "";
                            background-image: url('/icons/calendar-black.svg') !important;
                            background-position: center !important;
                            background-repeat: no-repeat !important;
                            background-size: cover !important;
                            width: 18px !important;
                            height: 18px !important;
                            }
                        }
                    }
                }
            }

            .add-containers-table.containerListingTable.edit-do {
                table {
                    thead {
                        tr {
                            th {
                                padding: 0 8px;
                                box-shadow: none !important;
                                font-size: 12px !important;
                                height: 40px;

                                &:first-child {
                                    padding-left: 0;
                                    padding-right: 0 !important;
                                    width: 5% !important;
                                    min-width: 5% !important;
                                }

                                .v-input {
                                    margin-top: 0 !important;

                                    &.container-checkbox-custom {
                                        padding: 0 12px !important;

                                        .v-input__control {
                                            min-height: unset !important;
                                            
                                            .v-input__slot {
                                                padding: 0 !important;
                                                background-color: #f7f7f7 !important;
                                                border: none !important;

                                                .v-input--selection-controls__input {
                                                    margin-right: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:nth-child(2) {
                                    padding-left: 12px;
                                }

                                span {
                                    font-size: 12px !important;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                .v-input {
                                    .v-input__control {
                                        .v-input__slot {
                                            padding: 0 !important;

                                            .v-select__slot {
                                                padding: 2px 0 2px 8px !important;

                                                .v-select__selection--comma {
                                                    font-size: 14px !important;
                                                }
                                            }

                                            .v-input__append-inner {
                                                padding-top: 14px;
                                            }

                                            .v-text-field__suffix {
                                                color: #819fb2;
                                                line-height: unset;
                                                padding-right: 8px;
                                            }
                                        }
                                    }

                                    &.text-input-end {
                                        input {
                                            text-align: right !important;
                                        }
                                    }

                                    &.container-checkbox-custom {
                                        .v-input__control {
                                            .v-input__slot {
                                                padding: 0 !important;
                                            }
                                        }
                                    }
                                }

                                .checkboxrow {
                                    padding-left: 11px !important;

                                    .v-input {
                                        .v-input__control {
                                            .v-input__slot {
                                                padding: 0 !important;

                                                .v-input--selection-controls__input {
                                                    margin-right: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    i {
                        &.mdi-checkbox-blank-outline {
                            width: 26px !important;
                            padding: 3px !important;

                            &::before {
                                content: '' !important;
                                background-image: url('/checkbox-empty-icon-1.svg') !important;
                                background-position: center !important;
                                background-repeat: no-repeat !important;
                                background-size: cover !important;
                                width: 16px !important;
                                height: 16px !important;
                            }
                        }

                        &.mdi-checkbox-marked {
                            width: 26px !important;
                            padding: 3px !important;

                            &::before {
                                content: '' !important;
                                background-image: url('/checkbox-filled-icon-1.svg') !important;
                                background-position: center !important;
                                background-repeat: no-repeat !important;
                                background-size: cover !important;
                                width: 16px !important;
                                height: 16px !important;
                            }
                        }
                    }
                }
            }

            .add-containers-table.containerListingTable.edit-do.update-do {
                table {
                    thead {
                        tr {
                            th {
                                &:first-child {
                                    padding-left: 12px !important;
                                    padding-right: 8px !important;
                                    width: 25% !important;
                                    min-width: 25% !important;
                                }
                            }
                        }
                    }
                }
            }

			/* Scrollbar */
			&::-webkit-scrollbar {
				width: 12px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background-color: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background-color: #e2e2e2;
				border-radius: 6px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background-color: #e2e2e2;
			}
		}
	}
}

.loading-wrapper-do {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
    background-color: #4A4A4A40;
}

.p-form-errors {
    color: #ff5252;
    font-size: 12px;
    font-family: 'Inter-Regular', sans-serif;
    margin-top: 4px;
    margin-bottom: 0 !important;
}
</style>
<style>
    .moveotherdo {
        color: rgb(1, 113, 161);
        font-size: 14px;
        width:100%;
    }

    .CLEAR_selection {
        color: rgb(1, 113, 161);
        cursor: pointer;
        text-align: center;
        padding: 8px 16px;
    }
    .holderheadercontainer {
        display: flex;
        margin: 4px 0 0;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .holderheadercontainer.is_moving {
        flex-direction: unset;
    }
    .mainWrapperListContainers .containertable .v-input__slot{
            border:0px !important;
    }
   /* New style */
    .topheader {
        padding-left: 19px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 4px
    }
    .topheader li {
        color:#6D858F !important;
        display: inline-block;
        width: 19%;
        word-break: break-all;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600px;
    }
    .addContainer {
        border-bottom: 1px solid #eee;
        border-top: 0px solid #eee;
        font-size: 14px;
        margin-top: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
    }
    .leftcol {
        border-right: 1px solid #eee;
    }
    .data-element .item {
        border-bottom: 0px solid #eee;
        padding: 6px
    }
    .headerContainer {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;

        color: #4A4A4A;
    }
    .tablecontainer {
        padding:0 5px;
        border-bottom: 1px solid #EBF2F5;
        border-top: 1px solid #EBF2F5;
        font-size: 14px;
    }
    .tablecontainer:hover{
        background: rgb(240, 251, 255) none repeat scroll 0% 0% !important
    }
    .tablecontainer li {
        display: inline-block;
        width: 19%;
        word-break: break-all;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 3px;
    }

    .uploadDocuwrapper  {
        margin-top: 7px !important;
    }

    .containers-wrappers {
        padding-top: 8px !important;
    }

    .container-create {
        padding-top: 0px !important;
    }

    .container-create .toolbHeader .v-toolbar__content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .toolbHeader {
        margin-bottom: 4px;
    }

    .container-create .toolbHeader{
        background: none !important;
    }

    .NewContainerShipmentTitle {
        font-size: 24px;
        font-weight: 600;
    }

.colright {
    width: 67.7% !important;
    padding-left: 11px !important;
    padding-top: 13px !important;
    padding-right: 16px !important;
}
    .deliveryOrderColumn {
        background-color: #fff;
        padding: 0px;
        width:  97.6%;
        margin-left: 8px;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .checkboxrow { padding-left: 16px;}
    .vue-tags-input {
        max-width: 100% !important;
    }

    .ti-new-tag-input {
        caret-color: #000 !important;
    }

    .ti-tags .ti-tag {
        background:#F7F7F7;
        color:#4A4A4A
    }

    div.ti-input {
        border-radius: 4px;
        border: 1px solid #B4CFE0 !important;
        width: 100% !important;
    }

    .uploadElement .hasContentFiles2 {
        background: #fff !important;
        padding:6px !important;
        display: block !important;
        height: 106px !important;
        border:2px dashed #B4CFE0 !important;
        border-radius: 5px;
    }

    .browserInput2 {
     width: 100%;
}

.move_other_do {
    border: 1.6px solid rgb(180, 207, 224);
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
}
.browseFileDelivery {
    width: 100% !important;
    text-align: center;
    color:#B4CFE0 !important;
    padding-top: 12px;
    font-size: 14px !important;
}

.browseButton2 {
    width: 79px;
    font-size: 12px;
    margin:0 auto;
    margin-top: 6px;
}

.wrappIconbutton2 {
    border: 1px solid #B4CFE0;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    padding: 5px;
    margin-top:21px
}
.classUpload {
     font-size: 12px;
    color: #0171A1;
    position: relative;
    left: 6px;
}

/* .tablecontainer li:hover {
    background: #fff;
} */



.iconUploadfile {
    color:#0171A1;
}

.rowItemUploaded {
    background: #F7F7F7 !important;
    border-radius: 6px;
    padding: 10px;
    height: 64px;
}

.mainwrapitem {
    border: 0px !important;
}

.uploadCreateNew {
    position: relative;
    top: 3px;
    left: -1px;
    height: 15px
}

.hidethisdiv {
    display: none !important;
}

.reupload {
    float: right;
    font-size: 14px !important;
    color: rgb(1, 113, 161);
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 0px;
    margin-top: 3px;
    margin-left: 0px;
    margin-right: 1px;
}
 .ic-calendar::before{
    font-size:16px !important;
}

.lastchild:hover {
    background: none !important;
}

 .ic-chevron-down::before {
    font-size: 16px !important
}

.document-btn {
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
}

.replace-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0171A1;
}
.containerListingTable .cinput_containers {
    caret-color: orange;
    outline:none;
    width:111px
}

.strtinglocationh {
    display: inline-block;
    height: 8.8px;
    position: relative;
    border-right: 1px solid;
    margin-right: 3px;
    margin-left: 3px;
}
.Goodsdeliverylocation .v-select__slot label {
	position: revert !important;
    font-family: "Inter-Regular", sans-serif !important;
    font-size: 14px !important;
}
</style>
