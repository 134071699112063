<template>
	<div>
		<v-dialog v-model="dialog" scrollable max-width="560px" content-class="my-custom-dialog" persistent>
			<v-card>
				<v-card-title class="modal-header">
					<span class="headline">Delivery Order Details</span>

					<div class="d-flex align-center">
						<v-menu offset-y bottom left content-class="modal-details-dialog-menu">
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" class="truckerMoreBtn">
									<img src="@/assets/icons/more-blue.svg" alt="" />
								</v-btn>
							</template>
							<v-list >
								<v-list-item
									v-if="tabtype == 0"
									class="trucking-archived-wrapper"
									style="min-height: 30px !important;"
								>
									<v-list-item-title
										@click="handleEdit(items)"
										class="trucking-archived-item"
									>
										<svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
										</svg> <span style="margin-left: 6px;">Edit</span>
									</v-list-item-title>
								</v-list-item>
								
								<v-list-item
									v-if="tabtype == 1"
									class="trucking_DropDown"
								>
									<v-list-item-title
										@click="handleEdit(items)"
										class="trucking_DropDownEdit d-flex align-center"
										style="font-size: 14px; padding: 8px 16px !important;"
									>
										<svg
											width="14"
											height="14"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z"
												fill="#6D858F"
											/>
										</svg>
										<span style="margin-left: 10px; font-size: 14px !important;">Edit</span>
									</v-list-item-title>
									<v-list-item-title
										class="trucking_DropDownEdit d-flex align-center"
										style="font-size: 14px; padding: 8px 16px 10px !important; border-bottom: 1px solid #E1ECF0;"
										@click="passParent('archive')"
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
												fill="#6D858F"
											/>
										</svg>
										<span style="margin-left: 8px; font-size: 14px !important;">Archive</span>
									</v-list-item-title>
									<v-list-item-title
										@click="passParent('cancel')"
										class="trucking_DropDownEdit d-flex align-center mt-1"
										style="font-size: 14px; padding: 8px 16px !important;"
									>
										<span class="d-flex align-center" style="margin-left: 3px;">
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M11.1528 0.84639C11.3933 1.08689 11.3933 1.47681 11.1528 1.71731L6.87076 5.99935L11.1528 10.2814C11.3933 10.5219 11.3933 10.9118 11.1528 11.1523C10.9123 11.3928 10.5224 11.3928 10.2819 11.1523L5.99984 6.87027L1.7178 11.1523C1.4773 11.3928 1.08738 11.3928 0.846878 11.1523C0.606379 10.9118 0.606379 10.5219 0.846878 10.2814L5.12892 5.99935L0.846879 1.71731C0.60638 1.47681 0.60638 1.08689 0.846879 0.84639C1.08738 0.605891 1.4773 0.605891 1.7178 0.84639L5.99984 5.12843L10.2819 0.84639C10.5224 0.605891 10.9123 0.605891 11.1528 0.84639Z"
													fill="#F93131"
												/>
											</svg>
										</span>										
										<span style="margin-left: 10px; font-size: 14px !important;">Cancel & Archive</span>
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									v-if="tabtype == 2"
									class="trucking_DropDown"
								>
									<!-- <v-list-item-title
									@click="handleEdit(items)"
									class="trucking_DropDownEdit"                 
								>
									<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
									</svg> Edit
								</v-list-item-title> -->
									<v-list-item-title class="" @click="passParent('unarchive')">
										<svg
											style="position: relative; top: 3px;"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
												fill="#6D858F"
											/>
										</svg>
										Unarchive
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<span
							@click="closemodal"
							class="btn-close closemodale"
							aria-hidden="true">
							<svg
								width="14"
								height="14"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M15.7294 0.270561C16.0902 0.631309 16.0902 1.2162 15.7294 1.57694L9.30638 8L15.7294 14.4231C16.0902 14.7838 16.0902 15.3687 15.7294 15.7294C15.3687 16.0902 14.7838 16.0902 14.4231 15.7294L8 9.30638L1.57695 15.7294C1.2162 16.0902 0.631309 16.0902 0.270561 15.7294C-0.0901871 15.3687 -0.0901871 14.7838 0.270561 14.4231L6.69362 8L0.270562 1.57694C-0.0901859 1.2162 -0.090186 0.631309 0.270562 0.270561C0.63131 -0.090187 1.2162 -0.0901869 1.57695 0.270561L8 6.69361L14.4231 0.270561C14.7838 -0.0901869 15.3687 -0.090187 15.7294 0.270561Z"
									fill="#4a4a4a"
								/>
							</svg>
						</span>
					</div>
				</v-card-title>
					
				<v-card-text>
					<div class="modal-body">
						<div class="do-top-content do-body-content">
							<div class="do-reference font-semibold">{{ getreference() }}</div>

							<div class="do-main-content">
								<v-row>
									<v-col cols="12" sm="6">
										<div class="do-content">
											<div class="do-content-title">MBL</div>
											<div class="do-content-data text-uppercase">
												{{ items.mbl_number !== null && items.mbl_number !== "" ? items.mbl_number : '-' }}
											</div>
										</div>

										<div class="do-content">
											<div class="do-content-title">ETA</div>
											<div class="do-content-data">{{ formatDate(items.eta) }}</div>
										</div>
									</v-col>

									<v-col cols="12" sm="6">
										<div class="do-content">
											<div class="do-content-title">Received</div>
											<div class="do-content-data">{{ datenow(items.created_at) }}</div>
										</div>

										<div class="do-content" v-if="tabtype !== 0">
											<div class="do-content-title">Opened</div>
											<div class="do-content-data">
												{{ datenow(this.items.opened_at) }}
												<!-- {{ datenow(this.items.date_of_changed_status) }} -->
											</div>
										</div>

										<div class="do-content" v-if="this.items.closed_at !== null">
											<div class="do-content-title">Closed</div>
											<div class="do-content-data">{{ datenow(this.items.closed_at) }}</div>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>		

						<div class="do-top-content do-body-content pb-0 no-border-bottom">
							<div class="do-document mb-4">
								<div class="do-sub-title">DOCUMENT</div>

								<div v-if="checkifhasdocument()" class="d-flex justify-space-between align-center"
									style="background:#F7F7F7;width:100%;padding: 8px;border-radius: 4px">
									<div class="d-flex align-center">
										<span class="documenticon">
											<img src="@/assets/icons/document-black.svg" alt="" width="20px" height="20px">
										</span>

										<div class="pl-3" style="font-size: 14px; color: #4a4a4a;">
											{{ items.deliveryorderdocument.filename }}
										</div>
									</div>

									<div style="display: flex; align-items: center; flex-direction: row-reverse;" class="pr-2">
										<div @click="showifclickdiv" style="cursor:pointer;">
											<span class="d-flex align-center"> 
												<span class="font-medium mr-1" style="color: #0171A1; font-size: 12px;">
													Preview
												</span> 
												<span class="d-flex align-center">
													<img src="@/assets/icons/preview-icon.svg" alt="" width="12px" height="12px">
												</span>
											</span>										

											<!-- <span v-else>
												Hide 
												<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M4 1.89258L6.984 5.10861C7.16277 5.30128 7.45881 5.29594 7.63152 5.09932C7.79235 4.91622 7.78932 4.63094 7.62314 4.45183L4.31957 0.891392C4.14464 0.702869 3.85535 0.702869 3.68043 0.891392L0.37686 4.45183C0.210672 4.63094 0.207648 4.91622 0.368478 5.09932C0.541187 5.29594 0.837225 5.30128 1.016 5.10861L4 1.89258Z" fill="#0171A1"/>
												</svg>
											</span> -->
										</div>

										<div @click="downloadfile(items)" class="d-flex align-center mr-4" style="cursor:pointer;">
											<span class="font-medium mr-1" style="color: #0171A1; font-size: 12px;">
												Download
											</span> 
											<span class="d-flex align-center">
												<img src="@/assets/icons/downloadBlue.svg" alt="" width="13px" height="13px">
											</span
										></div>																	
									</div>										
								</div>

								<div v-else>
									<div style="display:flex; align-items:center; background:#F7F7F7; width:100%; padding: 8px; border-radius: 4px">
										<span class="documenticon">
											<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6532 6.56315H14.9276C15.2438 6.56315 15.5 6.80836 15.5 7.10971V17.4486C15.5 18.387 14.7038 19.1673 13.721 19.1673H2.3C1.3178 19.1673 0.5 18.387 0.5 17.4486V2.53268C0.5 1.59424 1.3178 0.833984 2.3 0.833984H8.9276C9.2438 0.833984 9.5 1.07862 9.5 1.38055V5.462C9.5 6.07044 10.016 6.56315 10.6532 6.56315ZM4.7 15.1569H11.3C11.6312 15.1569 11.9 14.9008 11.9 14.584C11.9 14.2672 11.6312 14.0111 11.3 14.0111H4.7C4.3688 14.0111 4.1 14.2672 4.1 14.584C4.1 14.9008 4.3688 15.1569 4.7 15.1569ZM4.7 12.8652H11.3C11.6312 12.8652 11.9 12.6091 11.9 12.2923C11.9 11.9755 11.6312 11.7194 11.3 11.7194H4.7C4.3688 11.7194 4.1 11.9755 4.1 12.2923C4.1 12.6091 4.3688 12.8652 4.7 12.8652ZM4.7 10.5736H11.3C11.6312 10.5736 11.9 10.3169 11.9 10.0007C11.9 9.6844 11.6312 9.42773 11.3 9.42773H4.7C4.3688 9.42773 4.1 9.6844 4.1 10.0007C4.1 10.3169 4.3688 10.5736 4.7 10.5736ZM14.117 5.41732H11.2724C10.9562 5.41732 10.7 5.17211 10.7 4.87076V2.15456C10.7 1.93284 10.8392 1.73289 11.054 1.64925C11.267 1.56446 11.5142 1.61143 11.6774 1.76727L14.522 4.48347C14.6858 4.63987 14.735 4.87591 14.6462 5.0793C14.558 5.2844 14.3492 5.41732 14.117 5.41732Z" fill="#4A4A4A"/>
											</svg>																	
										</span>
										<span class="pl-3" style="font-size: 14px; color: #4a4a4a;">
											No associated document
										</span>																	
									</div>
								</div>
							</div>

							<div class="pt-4">
								<div class="do-reference font-semibold">Shipment</div>
								<div class="do-main-content">
									<v-row>
										<v-col cols="12" sm="12">
											<div class="do-content">
												<div class="do-content-title">Carrier</div>
												<div class="do-content-data">{{ getParticularData("carrier") }}</div>
											</div>

											<div class="do-content">
												<div class="do-content-title">Vessel</div>
												<div class="do-content-data">{{ getParticularData("vessel") }}</div>
											</div>

											<div class="do-content">
												<div class="do-content-title">Starting Location</div>
												<div class="do-content-data">{{ getParticularData("pickup_location") }}</div>
											</div>

											<div class="do-content">
												<div class="do-content-title">Delivery Location</div>
												<div class="do-content-data">{{ getParticularData("delivery_location") }}</div>
											</div>

											<div class="do-content">
												<div class="do-content-title">Commodity</div>
												<div class="do-content-data">{{ getParticularData("commodity") }}</div>
											</div>
										</v-col>
									</v-row>
								</div>
							</div>
						</div>

						<div class="do-top-content do-body-content pb-0 no-border-bottom">
							<div class="do-reference font-semibold">Stakeholders</div>
							<div class="do-main-content">
								<v-row>
									<v-col cols="12" sm="12">
										<div class="do-content">
											<div class="do-content-title">Customer</div>
											<div class="do-content-data">{{ getParticularData("customer") }}</div>
										</div>

										<div class="do-content">
											<div class="do-content-title">Consignee</div>
											<div class="do-content-data">{{ getParticularData("consignee") }}</div>
										</div>

										<div class="do-content">
											<div class="do-content-title">Customer Reference</div>
											<div class="do-content-data" v-if="customerReferenceLists.length > 0">
												<span v-for="(ref, i) in customerReferenceLists" :key="i">
													<span class="reference-num" v-if="(i <= 2)">
														{{ ref.customer_ref_number }}
													</span>
													<span class="reference-num" v-if="i === 3">
														+{{ customerReferenceLists.length - 3 }}
													</span>
												</span>
											</div>

											<div v-else>-</div>
										</div>

										<div class="do-content">
											<div class="do-content-title">Contacts</div>
											<div class="do-content-data" v-if="customerContacts.length > 0">
												<span v-for="(contact, i) in customerContacts" :key="i">
													<span class="reference-num" v-if="i === 0">
														{{ contact }}
														<img style="padding-top:2px;" src="@/assets/icons/email_icon_send.svg" class="ml-1" width="14px" height="12px">
													</span>
													<span class="reference-num" v-if="i === 1">
														+{{ customerContacts.length - 1 }}
													</span>
												</span>
											</div>
											<div v-else>-</div>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>	

						<div class="do-top-content do-body-content no-border-bottom">
							<div class="do-reference font-semibold">Notes</div>
							<div class="do-main-content">
								<v-row>
									<v-col cols="12" sm="12">
										<p class="mb-0" style="color: #4a4a4a; font-size: 14px;">
											{{ getParticularData("notes") }}
										</p>
									</v-col>
								</v-row>
							</div>
						</div>	

						<div class="do-top-content do-body-content no-border-bottom">
							<div class="do-reference font-semibold">Containers</div>
							<div class="do-main-content pt-2 mb-4">
								<v-data-table
									:headers="doModalHeaders"
									:items="containerLists"
									:items-per-page="5000"
									@page-count="pageCount = $event"
									mobile-breakpoint="769"
									item-key="id"
									class="container-lists-view-table elevation-1"
									:class="containerLists !== undefined && containerLists.length === 0 ? 'no-data-table-logs' :''"
									hide-default-footer
									fixed-header>

									<template v-slot:[`item.container_number`]="{ item }">
										<div class="delivery-order-details">
											<div class="delivery-label-unit">
												{{ item.container_number !== null ? item.container_number : '--' }}
											</div>
										</div>
									</template>

									<template v-slot:[`item.container_size`]="{ item }">
										<div class="delivery-order-details">
											<div class="delivery-label-unit">
												{{ getCurrentSizeValue(item.container_size) }}
											</div>
										</div>
									</template>

									<template v-slot:[`item.container_status`]="{ item }">
										<div class="delivery-order-details">
											<span class="delivery-label-unit status" v-if="getContainerStatus(item) !== ''">
												{{ getContainerStatus(item) }}
											</span>
											<span v-else>-</span>
										</div>
									</template>
								</v-data-table>
							</div>
						</div>	
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<ShowPreviewDoc 
			:dialogData="isShowPreview" 
			@close="closePreview"
			:items="items"
			@downloadfile="downloadfile" />
	</div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import _ from "lodash"
import globalMethods from '../../../utils/globalMethods';
import iziToast from "izitoast"
import ShowPreviewDoc from './ShowPreviewDoc.vue'

const poBaseUrl = process.env.VUE_APP_PO_URL;

export default {
	name: "Modaldetails",
	props: ["items", "defaultmodal", "tabtype", "terminals", "carriers", "customers"],
	components: {
		ShowPreviewDoc
	},
	computed: {
		dialog: {
			get() {
				return this.defaultmodal;
			},
			set(val) {
				this.$emit("update:defaultmodal", val)
			}
		},
		customerReferenceLists() {
			let data = []

			if (this.items !== null) {
				if (typeof this.items.customer_ref_number !== "undefined") {
					data = this.referenceparse(this.items.customer_ref_number)
				}
			}
			return data
		},
		customerContacts() {
			let data = []

			if (this.items !== null) {
				if (typeof this.items.contacts !== "undefined") {
					data = this.emailsparse(this.items.contacts)
				}
			}
			return data
		},
		containerLists() {
			let data = []

			if (this.items !== null) {
				let reviewStatus = this.items.reviewStatus

				if (reviewStatus === "Accepted") {
					let get_the_containers = this.items.deliveryordershipment;

					if(get_the_containers !=null){
						if(get_the_containers.length > 0){
							get_the_containers.map((wea,i) => {
								if (wea!==null) {
									data.push({
										id:i,
										container_id : wea.shipment_id,
										container_number: wea.container_number,
										container_size: parseInt(wea.size),
										container_volume: wea.volume,
										container_weight: wea.weight,
										container_qty: wea.qty,
										delivery_to :  '',
										checkbox:0,
										error_0:0,
										container_status: ''
									})
								}
							})
						}
					}
				} else {
					let get_the_containers = this.items.containers;
					let loc = ""

					if (this.items.source_of_creation == 'CF'  ||
						this.items.source_of_creation == 'BO') {
						loc = "ac"
					} else if (this.items.source_of_creation == 'SD'){
						get_the_containers = this.items.containers_group_bookings;
						loc = "bc"
					}

					if (typeof get_the_containers !== "undefined" && get_the_containers !== null) {
						if (get_the_containers.length > 0) {
							let containersListdraft  = JSON.parse(get_the_containers)
							let source_of_creation = this.items.source_of_creation

							containersListdraft.map((wea,i) => {
								if (wea !== null) {
									data.push({
										id:i,
										container_id :(loc == 'bc' ) ? '' : (source_of_creation == 'BO' ? wea.container_id : '' ) ,
										container_number: (loc == 'bc') ?  wea.container_num : wea.Container,
										container_size: (loc == 'bc') ? wea.size : parseInt(wea.Sizes),
										container_volume: (loc == 'bc') ? wea.cbm : wea.VolumeNumberofCBM,
										container_weight: (loc == 'bc') ? wea.kg : wea.WeightNumberofKG,
										container_qty: (loc == 'bc') ? wea.cartons : wea.CartonCount,
										delivery_to :  '',
										checked:0,
										error_0:0,
										container_status: ''
									})

									if (source_of_creation === "CF") {
										data[i].lfd = wea.lfd
									}
								}
							})
						}
					}
				}
			}

			return data
		}
	},
	watch: {
		// showifclick(c) {
		// 	if (c) {
		// 		if (this.items.deliveryorderdocument != null) {
		// 			this.downloadDocument(this.items.deliveryorderdocument.id);
		// 		}
		// 	}
		// },
		// defaultmodal(c) {
		// 	if (c) {
		// 		this.filelist = [];
		// 		//let pickuplocation = this.items.location_from
		// 		// let delivery_location = "";

		// 		// if(this.items.source_of_creation=='CF'){
		// 		// pickuplocation = this.items.location_from // delivery location
		// 		// delivery_location = this.items.delivery_location;
		// 		// }

		// 		// carrier name
		// 		// let carrier = this.carriers.find(
		// 		// 	(find) => find.id == this.items.carrier_id
		// 		// );
		// 		// let carrierName = "";
		// 		// if (carrier != null) {
		// 		// 	if (carrier.name != "") {
		// 		// 		carrierName = carrier.name;
		// 		// 	}
		// 		// }

		// 		// terminal name
		// 		// let findterminal = this.terminals.find(
		// 		// 	(find) => find.id == this.items.terminal_id
		// 		// );
		// 		// let thepickupname = "";
		// 		// if (findterminal != null) {
		// 		// 	if (findterminal.map_location != "") {
		// 		// 		thepickupname = findterminal.name;
		// 		// 	}
		// 		// }

		// 		// let elem = [
		// 		// 	{
		// 		// 		id: 1,
		// 		// 		name: "RECEIVED ON",
		// 		// 		TEXT: moment(this.items.created).format("MMM DD, Y"),
		// 		// 	},
		// 		// 	{
		// 		// 		id: 2,
		// 		// 		name: "OPENED ON",
		// 		// 		TEXT: moment(this.items.date_of_changed_status).format(
		// 		// 			"MMM DD, Y"
		// 		// 		),
		// 		// 	},
		// 		// 	{
		// 		// 		id: 4,
		// 		// 		name: "ETA",
		// 		// 		TEXT: moment(this.items.eta).format("MMM DD, Y"),
		// 		// 	},
		// 		// 	{ id: 5, name: "MLB/AWB", TEXT: this.items.mbl_number },
		// 		// 	{
		// 		// 		id: 6,
		// 		// 		name: "CUSTOMER",
		// 		// 		TEXT: this.items.customerdelivery?.company_name ?? "--",
		// 		// 	},
		// 		// 	{
		// 		// 		id: 7,
		// 		// 		name: "CUSTOMER CONTACTS",
		// 		// 		TEXT: this.emailsparse(this.items),
		// 		// 	},
		// 		// 	{
		// 		// 		id: 8,
		// 		// 		name: "PICKUP LOCATION",
		// 		// 		TEXT: thepickupname ?? "--",
		// 		// 	},
		// 		// 	{
		// 		// 		id: 9,
		// 		// 		name: "DELIVERY LOCATION",
		// 		// 		TEXT: delivery_location ?? "--",
		// 		// 	},
		// 		// 	{ id: 10, name: "VESSEL", TEXT: this.items.vessel ?? "--" },
		// 		// 	{ id: 11, name: "CARRIER", TEXT: carrierName ?? "--" },
		// 		// 	{
		// 		// 		id: 12,
		// 		// 		name: "COMMODITY",
		// 		// 		TEXT: this.items.container_commodity ?? "--",
		// 		// 	},
		// 		// 	{
		// 		// 		id: 13,
		// 		// 		name: "NOTES",
		// 		// 		TEXT: this.items.description ?? "--",
		// 		// 	},
		// 		// ];
		// 		// this.detailsInformation = elem;
		// 		//console.log(this.detailsInformation)
		// 	}
		// },
	},
	data() {
		return {
			filelist: [],
			showifclick: 0,
			reference: "704475",
			status: "Available",
			UPDATE_AT_TIME: "09:34 PM, Jun 10, 2020",
			detailsInformation: [],
			doModalHeaders: [
				{
					text:'CONTAINER #',
					align: 'start',
					sortable: false,
					value: 'container_number',
					fixed: true,
					width: "30%" 
				},
				{
					text:'SIZE',
					align: 'end',
					sortable: false,
					value: 'container_size',
					fixed: true,
					width: "15%" 
				},
				{
					text:'STATUS',
					align: 'center',
					sortable: false,
					value: 'container_status',
					fixed: true,
					width: "20%" 
				},
			],
			sizes: [],
			isDownloadingFile: 0,
			isShowPreview: false,
			carriersLists: []
		};
	},
	methods: {
		...globalMethods,
		...mapActions({ }),
		getFileURL(file) {
			return URL.createObjectURL(file);
		},
		showifclickdiv() {
			// this.showifclick = this.showifclick ? 0 : 1;
			// if (this.items.deliveryorderdocument != null) {
			// 	this.downloadDocument(this.items.deliveryorderdocument.id);
			// }

			this.isShowPreview = true
		},
		closePreview() {
			this.isShowPreview = false
		},
		checkifhasdocument() {
			return typeof this.items.deliveryorderdocument !== "undefined" && this.items.deliveryorderdocument !== null ? true : false;
		},
		downloadDocument(documentID) {
			let passdata = documentID;
			this.isFetching = 1;
			let c = [];
			if (passdata !== "") {
				axios({
					url: `${poBaseUrl}/document/download/${passdata}`,
					method: "GET",
					responseType: "blob",
				}).then((response) => {
					this.isFetching = 0;
					window.URL = window.URL || window.webkitURL;
					var json = response.data;
					c[0] = new File([json], "file.pdf", {
						lastModified: new Date().getTime(),
						type: "application/pdf",
					});
					this.filelist = c;
				});
			}
		},
		downloadfile(item) {
			let passdata = item.deliveryorderdocument.id;
			// this.isFetching = 1;
			this.isDownloadingFile = 1;
			let toastData = {
				message: 'Downloading file...',
				timeout: 2000,
				class: 'downloading-file-toast'
			}
			this.notificationCustomAlert(toastData)

			if (passdata !== "") {
				axios({
					url: `${poBaseUrl}/document/download/${passdata}`,
					method: "GET",
					responseType: "blob",
				}).then((response) => {
					// this.isFetching = 0;
					this.isDownloadingFile = 0;
					window.URL = window.URL || window.webkitURL;
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(
						new Blob([response.data], {
							type: "application/octet-stream",
						})
					);
					document.body.appendChild(link);
					// link.setAttribute('target', '_blank');
					// link.setAttribute("download", "Delivery_order.pdf");
					link.setAttribute("download", item.deliveryorderdocument.filename);
					link.click();
					document.body.removeChild(link);

					var toast = document.querySelector('.iziToast.downloading-file-toast');
					iziToast.hide({}, toast);
					this.notificationMessageCustomSuccess("File has been downloaded!")
				});
			}
		},
		emailsparse(items) {
			let parse = items;
			if (parse !== null && parse !== "null") {
				if (parse.length) {
					const data = JSON.parse(parse);
					return data
				}
			} else return []
			return [];
		},
		referenceparse(items) {
			let parse = items;
			if (parse !== null) {
				if (typeof parse === "string") {
					if (parse.length) {
						const data = JSON.parse(parse);
						return data;
					}
				} else {
					return parse
				}
			} else return []
			return [];
		},
		datenow(c) {
			return c !== null && c !== "" ? moment(c).format("h:mm A, MMM DD, YYYY") : "-";
		},
		touppercase(item) {
			if (item !== null) {
				return item.replace(/_+/g, " ");
			}
		},
		getreference() {
			return "DO ID #" + this.items.shifl_ref;
		},
		closemodal() {
			this.$emit("update:defaultmodal", false);
			this.$emit("datastatus", false);
		},
		check() {
			console.log(this.items);
		},
		passParent(item) {
			this.$emit("archivetype", item);
		},
		handleEdit(items) {
			this.$emit("editDO", items);
		},
		formatDate(date) {
			return date !== null && date !== "" ? moment(date).format("MMM DD, YYYY") : ""
		},
		getTerminalName(item) {
			if(this.tabtype == 0) {
				if(item.source_of_creation == 'SD') {
					if(typeof this.terminals != 'undefined') {
						let findterminal = this.terminals.find((find) => find.firms_code == item.central_firms_code);
						if(findterminal != null) {
							if(findterminal.map_location != '') {
								return findterminal.map_location
							}
						}
					}
					return '';
				}
				else {
					return item.terminal?.name ?? '';
				}
			}
			else {
				return item.terminal?.name ?? '';
			}
		},
		getCustomerName(item) {
			if(this.tabtype == 0) {
				if(item.source_of_creation == 'SD') {
					if(item.reviewStatus === 'Pending') {
						return 'Shifl DO';
					}
					return item.customerdelivery?.company_name ?? '--';
				}
				else if(item.source_of_creation == 'CF') {
					return item.company_name ?? '--';
				}
				else if(item.source_of_creation == null) {
					return '--';
				}
				let f = this.customers.find((find) => find.id == item.customer);
				return f?.company_name ?? '--'
			}
			else {
				return item.customerdelivery?.company_name ?? '--';
			}
		},
		getParticularData(field) {
			let value = ""

			if (field === "pickup_location") {
				value = this.getTerminalName(this.items)

			} else if (field === "delivery_location") {
				value = this.items.delivery_location ?? "";

			} else if (field === "carrier") {
				let carrier = this.carriersLists.find(
					(find) => find.id == this.items.carrier_id
				);
				let carrierName = "";
				if (carrier != null) {
					if (carrier.name != "") {
						carrierName = carrier.name;
					}
				}
				value = carrierName ?? "";

			} else if (field === "vessel") {
				value = this.items.vessel ?? "";

			} else if (field === "commodity") {
				value = this.items.container_commodity ?? "";

			} else if (field === "customer") {
				value = this.getCustomerName(this.items)

			} else if (field === "consignee") {
				// value = this.items.consignee_name ?? ""
				value = this.items.consignee?.name ?? '-';
				
			} else if (field === "notes") {
				value = this.items.description ?? ""
			}

			value = value !== "" ? value : '-'

			return value
		},
		async getSizes() {
            await axios.get(`${process.env.VUE_APP_PO_URL}/get-sizes`)
			.then((response) => {
				this.sizes = response.data.data
			})
			.catch((error) => {
				console.log(error)
			})
        },
		async getCarrierLists() {
            await axios.get(`${process.env.VUE_APP_PO_URL}/newcarriers`)
			.then((response) => {
				this.carriersLists = response.data.data
			})
			.catch((error) => {
				console.log(error)
			})
        }, 
		getCurrentSizeValue(size) {
			if (size !== null && size !== "") {
				let findSize = _.find(this.sizes, e => size === e.id)
				if (findSize !== undefined) {
					return findSize.name
				}
			}

			return "-"
		},
		getContainerStatus(item) {
			let dispatch_schedule = null
			let status = "Pending"

			let { shipments } = this.items

			if (shipments.length > 0) {
				let id = item.container_id
				let findShipment = _.find(shipments, e => e.id === id)
				let currentLeg = null

				if (findShipment !== undefined) {
					dispatch_schedule = JSON.parse(findShipment.dispatch_schedule);
					let legs = dispatch_schedule ? dispatch_schedule.legs || [] : []

					if (legs.length > 0) {
						for (let i = legs.length - 1; i >= 0; i--) {
							let arrivalAtDelivery = legs[i].arrival_at_delivery_location_date

							if (legs[i].completed || (arrivalAtDelivery !== null && arrivalAtDelivery !== "" && 
								arrivalAtDelivery !== "Invalid date")) {
								currentLeg = legs[i];
								break;
							}
						}			

						if (currentLeg !== null) {
							if (legs.length === 1) {
								if (currentLeg.type === "Yard") {
									status = "Picked Up"
								} else {
									if (currentLeg.type === "Customer") {
										status = "Delivered"
									} else if (currentLeg.type === "To Port" || currentLeg.type === "Empty Return") {
										status = "Returned"
									}
								}
							} else {
								if (currentLeg.type === "Customer") {
									status = "Delivered"
								} else if (currentLeg.type === "To Port" || currentLeg.type === "Empty Return") {
									status = "Returned"
								} else if (currentLeg.type === "Yard") {
									status = "Picked Up"
								}
							}
						} else {
							for (let i = legs.length - 1; i >= 0; i--) {
								let departureFromPickUpLoc = legs[i].departure_from_pickup_location_date

								if (departureFromPickUpLoc !== null && departureFromPickUpLoc !== "" && 
									departureFromPickUpLoc !== "Invalid Date") {
									currentLeg = legs[i];
									break;
								}
							}
							
							if (currentLeg !== null) {
								status = "Picked Up"
							}
						}
					} 
				}
			}

			return status
		}
	},
	mounted() {
		if (this.sizes.length === 0) {
			this.getSizes()
		}

		if (this.carriersLists.length === 0) {
			this.getCarrierLists()
		}
	},
	updated() {}
};
</script>

<style lang="scss">
.v-dialog.my-custom-dialog:not(.v-dialog--fullscreen) {
	max-height: 100%;
    height: 100%;
    right: 0!important;
    margin: 0;
    position: absolute!important;
    border-radius: 0!important;
}

.v-dialog.my-custom-dialog {
	.font-medium {
		font-family: "Inter-Medium", sans-serif !important;
	}

	.font-semibold {
		font-family: "Inter-SemiBold", sans-serif !important;
	}

	.v-card__title {
		&.modal-header {
			padding: 14px 20px !important;
			border-bottom-width: 1px !important;

			.modalheader_text {
				font-size: 20px;
				font-family: 'Inter-Medium', sans-serif !important;
				margin-bottom: 0 !important;
				color: #4a4a4a;
				font-weight: unset !important;
			}
		}
	}

	.v-card__text {
		padding: 0 !important;

		&::-webkit-scrollbar {
            width: 10px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d3d3d3; 
			border-radius: 4px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #d3d3d3; 
        }
	}

	.modal-body {
		input {
			width: 200px;
			padding: 8px;
			border: 1px solid #ddd;
			color: #888;
			outline: 0;
			font-size: 14px;
			font-weight: bold;
		}

		.do-body-content {
			padding: 20px;
			border-bottom: 1px solid #D8E7F0;

			&.no-border-bottom {
				border-bottom: none !important;
			}

			&.do-top-content {		
				.do-reference {
					color: #4a4a4a;
					font-size: 16px;
					margin-bottom: 10px;
				}

				.do-main-content {
					.do-content {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						padding-bottom: 8px;

						.do-content-title {
							color: #6D858F;
							font-size: 14px;
							width: 30%;
							font-family: "Inter-Medium", sans-serif !important;
						}

						.do-content-data {
							color: #4a4a4a;
							font-size: 14px;
							width: 70%;

							.reference-num {
								padding: 2px 8px;
								border: 1px solid #D8E7F0;
								border-radius: 12px;
								color: #4a4a4a;
								margin-right: 8px;
								font-size: 12px;
							}
						}
					}
				}
			}

			.do-document {
				.do-sub-title {
					color: #6D858F;
					font-size: 12px;
					font-family: "Inter-Medium", sans-serif !important;
					margin-bottom: 4px;
				}
			}

			.v-data-table {
				&.container-lists-view-table {
					box-shadow: none !important;

					.v-data-table__wrapper {
						table {
							thead {
								tr {
									th {
										text-transform: uppercase;
										color: #6d858f;
										padding: 8px 12px;
										font-family: "Inter-Regular", sans-serif;
										box-shadow: none!important;
										background-color: #f7f7f7;
										height: 38px;
										font-size: 12px !important;
									}
								}
							}

							tbody {
								tr {
									&:hover {
										background-color: transparent !important;
									}
									td {
										border: 1px solid #EBF2F5 !important;
										border-top: none !important;
										font-size: 14px !important;
										padding: 8px 12px !important;
										color: #4a4a4a !important;
										height: 36px;

										&:nth-child(2) {
											border-left: none !important;
											border-right: none !important;
										}

										.delivery-order-details {
											.delivery-label-unit {
												&.status {
													font-size: 12px;
													font-family: "Inter-Medium", sans-serif !important;
													background-color: #F0FBFF;
													color: #0171A1;
													padding: 4px 10px;
													border-radius: 50px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.modal-footer {
		padding: 10px 20px;
	}
}

.drayagewrapper {
	padding: 24px;
	font-family: "Inter-Regular", sans-serif;
	height: 100%;
}

.header_top {
	.v-toolbar__content {
		padding-left: 0px !important;
	}
}

.v-autocomplete__content {
	.v-list-item--active {
		background: #f0fbff !important;
		&:before {
			background: #f0fbff !important;
		}
	}
}
.drayage-table {
	.v-data-table__wrapper {
		table {
			thead {
				th {
					border-bottom: 2px solid #ebf2f5 !important;
					font-size: 14px !important;
					color: #6d858f !important;
					text-transform: none !important;
					&:first-child {
						padding-left: 18px !important;
					}
				}
			}

			tbody {
				.statusDrayage_ {
					text-align: center;
				}

				.rowclass {
					cursor: pointer;
				}

				.nodatatable {
					&:hover {
						background: none !important;
					}
				}

				.schedule {
					color: #4a4a4a;
				}

				.pickupon {
					color: #4a4a4a;
				}

				td {
					border-bottom: 2px solid #ebf2f5 !important;

					&:first-child {
						padding-left: 20px !important;
					}
					.ref {
						color: #0171a1;
						font-family: "Inter";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
					}

					.containerID {
						color: #6d858f;
					}

					.commonRule {
						color: #4a4a4a;
					}

					.AvailableStatus {
						background: #f0fbff;
						color: #0171a1;
					}

					.status_drayage {
						padding: 6px 8px;
						border-radius: 30px;
						font-size: 12px;
					}

					.DeliveredStatus {
						background: #ebfaef;
						color: #16b442;
					}

					.PendingAvailabilityStatus {
						background: #fff9f0;
						color: #d68a1a;
						// width: 144px;
						// display: block;
					}

					.IntransitStatus {
						background: #f5f0fc;
						color: #6119cc;
					}

					.statusDrayage_ {
						text-align: center;
					}

					.searchnoresearch {
						// width: 417px;
						margin: 0px auto;
						margin-bottom: 0px;
						// margin-top: 206px;
						// margin-bottom: 300px;

						img {
							margin-bottom: 14px;
						}

						h2 {
							margin-bottom: 12px;
							color: #4a4a4a;
							font-size: 20px !important;
						}

						p {
							color: #6d858f;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.header_top {
	padding-left: 0px !important;
	.v-toolbar__content {
		.v-toolbar__title {
			font-size: 24px;
		}
	}
}

.headerTable {
	border-bottom: 2px solid #ebf2f5 !important;
	border-top: 3px solid #ebf2f5 !important;

	.v-toolbar__content {
		padding-right: 4px;
	}

	.btnDownloadreport {
		border: 1px solid #b4cfe0 !important;

		.downloadReport {
			color: #0171a1;
			font-family: "Inter";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			text-transform: none;
			margin-left: 6.4px;
		}
	}
}
.searchtab {
	width: 240px;
	margin-right: 9px;
	.v-input__control {
		width: 240px;
		.v-input__slot {
			width: 240px;
		}
	}
}

.search-component-wrapper {
	.v-input__control {
		.v-input__slot {
			width: 100% !important;
		}
	}
}

.drayageDropdownstatus {
	margin-right: 10px;
	width: 220.8px;
	.v-text-field {
		.v-input__control {
			.v-input__slot::after {
				background-color: currentColor;
				border-color: currentColor;
				border-style: solid;
				border-width: 0 !important;
				transform: scaleX(0);
			}
		}
	}

	.v-select__slot {
		.v-input__append-inner {
			.v-input__icon--append {
				i {
					font-size: 26px !important;
				}
			}
		}
	}
}
.modale {
	&:before {
		content: "";
		display: none;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
}
.opened {
	&:before {
		display: block;
	}
}

.opened {
	.modal-dialog {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		top: 0;
	}
}

.modal-dialog {
	background: #fefefe;
	border: #333333 solid 0px;
	border-radius: 0px;
	margin-left: -200px;
	position: fixed;
	right: 0px;
	height: 100%;
	top: -100%;
	z-index: 11;
	width: 560px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	-webkit-transform: translate(0, -500%);
	-ms-transform: translate(0, -500%);
	transform: translate(0, -500%);
	-webkit-transition: -webkit-transform 0.3s ease-in-out;
	-moz-transition: -moz-transform 0.3s ease-in-out;
	-o-transition: -o-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;

	.font-medium {
		font-family: "Inter-Medium", sans-serif !important;
	}

	.font-semibold {
		font-family: "Inter-SemiBold", sans-serif !important;
	}

	.modal-header {
		padding: 14px 20px !important;

		.modalheader_text {
			font-size: 20px;
			font-family: 'Inter-SemiBold', sans-serif !important;
			margin-bottom: 0 !important;
			color: #4a4a4a;
		}
	}

	.modal-body {
		height: calc(100% - 65px);
		overflow-y: scroll;

		input {
			width: 200px;
			padding: 8px;
			border: 1px solid #ddd;
			color: #888;
			outline: 0;
			font-size: 14px;
			font-weight: bold;
		}

		.do-body-content {
			padding: 20px;
			border-bottom: 1px solid #D8E7F0;

			&.no-border-bottom {
				border-bottom: none !important;
			}

			&.do-top-content {		
				.do-reference {
					color: #4a4a4a;
					font-size: 16px;
					margin-bottom: 8px;
				}

				.do-main-content {
					.do-content {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						padding-bottom: 8px;

						.do-content-title {
							color: #6D858F;
							font-size: 14px;
							width: 30%;
							font-family: "Inter-Medium", sans-serif !important;
						}

						.do-content-data {
							color: #4a4a4a;
							font-size: 14px;
							width: 70%;

							.reference-num {
								padding: 2px 8px;
								border: 1px solid #D8E7F0;
								border-radius: 12px;
								color: #4a4a4a;
								margin-right: 8px;
								font-size: 12px;
							}
						}
					}
				}
			}

			.do-document {
				.do-sub-title {
					color: #6D858F;
					font-size: 12px;
					font-family: "Inter-Medium", sans-serif !important;
					margin-bottom: 4px;
				}
			}
		}
	}

	.modal-footer {
		padding: 10px 20px;
	}
}

.checkdropdown {
	width: 14px;
	position: relative;
	float: right;
}

.listDropdown {
	font-size: 14px !important;
}

.v-select--is-menu-active {
	background: transparent !important;
}

.v-input__control {
	.v-input__slot {
		&:before {
			border-color: transparent !important;
		}
	}
}

.drayageDropdownstatus {
	margin-top: 8px;
}

.drayageDropdownstatus {
	.v-input__slot {
		height: 48px;
		border: 1px solid #b4cfe0;
		font-size: 14px;
		padding-left: 10px;
		border-radius: 3px;
	}
}

.drayageDropdownstatus {
	.v-input__append-inner {
		position: relative;
		top: 3px;
		left: -3px;
	}
}

.drayageDropdownstatus {
	.v-input {
		height: 48px;
	}
}

.drayageDropdownstatus {
	.v-input__control {
		height: 48px;
	}
}

/* MODAL */
.DateDetails {
	text-align: left;
	font-size: 14px;
	margin-top: 6px;
}
.detailsfreight {
	padding-left: 21px;
	li {
		padding-bottom: 11px;
		font-size: 14px;
		.detailsicon {
			position: relative;
			top: 4px;
			margin-right: 7px;
		}
		.holderwrapdetails {
			margin-left: 16px;
			font-size: 14px;
			margin-top: 9px;
			span {
				margin-left: 10px;
				background: #fff2f2;
				color: #f93131;
				padding: 2px 6px;
				font-size: 12px;
			}
		}
	}
}
.topdetails {
	padding-top: 16px;
	border-radius: 4px;
}
.updatedat {
	color: #819fb2;
	width: 79px;
	display: inline-block;
	font-size: 14px;
}
.time {
	color: #4a4a4a;
}
.DETAILS_WRAPPER {
	margin-top: 0px;
	table {
		td {
			text-align: left;
			font-size: 14px !important;
			color: #4a4a4a;
			padding-bottom: 1px;
			font-family: "Inter", sans-serif !important;
			&:first-child {
				font-size: 12px !important;
				color: #6d858f;
				width: 208px;
				font-family: "Inter", sans-serif !important;
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
			}
		}
	}
}
.closemodale {
	position: relative;
	right: 0px;
	// width: 10%;
	text-align: right;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.detailsstatus {
	background: #f0fbff;
	color: #0171a1;
}
.common {
	border-radius: 4px;
	font-size: 12px;
	// width: 37%;
	min-width: auto;
	margin-left: 0px;
	margin-right: 10px;
	border: 1px solid #ebf2f5;
	// display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 6px 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 4px;
}

.modal-details-dialog-menu {
	min-width: 130px !important;	

	.v-list {
		.trucking-archived-wrapper {
			min-height: 38px;
			cursor: pointer;
			
			.v-list-item__title {
				font-size: 14px !important;
				display: flex;
				justify-content: flex-start;
				align-items: center;		
				width: 100% !important;		
			}
		}

		.v-list-item__title {
			width: 100% !important;		
		}
	}
}
</style>

<style>
.truckerMoreBtn {
	min-width: 36px !important;
	background-color: #fff !important;
	border: 1px solid #b4cfe0;
	box-shadow: none;
	margin-right: 20px;
	width: 36px;
}
.documenticon {
	width: 40px;
	height: 40px;
	background: rgb(255, 255, 255);
	border: 1px solid rgb(235, 242, 245);
	border-radius: 99px;
	display: inline-block;
	padding-top: 8.5px;
	padding-left: 11px;
}
</style>
