<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialogEdit"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form ref="form" @submit.prevent>
          <v-card-title style="caret-color: transparent">
            <span class="headline">Edit Company Information</span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">COMPANY Name</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.name"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Email Address</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.general_email"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Delivery Order Email Recipients</label>
                <vue-tags-input
                    hide-details="auto"
                    allow-edit-tags
                    :rules="arrayNotEmptyRules"
                    :tags="deliveryOrderTag"
                    :style="deliveryOrderToggle ? '':'background-color:#EBF2F5'"
                    style="min-width:100%!important"
                    :add-on-blur=true
                    :add-on-key="[13, ',']"
                    :validation="tagsValidation"
                    v-model="deliveryOrderEmail"
                    placeholder="Separate multiple email addresses with comma"
                    @tags-changed="newTags => {
                        this.deliveryOrderTag = newTags
                        this.tagsInput.touched = true
                        this.tagsInput.hasError = (this.deliveryOrderTag.length > 0) ? false : true
                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                        if (typeof el!=='undifined') {
                          if (this.tagsInput.hasError)
                            el.classList.add('ti-new-tag-input-error')
                          else
                            el.classList.remove('ti-new-tag-input-error')   
                        }
                    }"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Dot Number</label>
                <v-text-field
                  outlined
                  dense
                  :rules="numberRules"
                  v-model="getCompanyProfile.dot_number"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Mc Number</label>
                <v-text-field
                  outlined
                  dense
                  :rules="numberRules"
                  v-model="getCompanyProfile.mc_number"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-2 pt-1">
                <label class="text-item-label">Phone Number</label>
                <vue-tel-input-vuetify
                  class="text-fields DialogInputfield"
                  type="number"
                  outlined
                  dense
                  single-line
                  hide-details="auto"
                  :valid-characters-only="true"
                  v-model="getCompanyProfile.phone_number"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Address</label>
                <!-- <v-autocomplete
                  :loading="loadingLocationPlaces"
                  :items="places"
                  v-model="getCompanyProfile.street_address"
                  :label="getCompanyProfile.street_address"
                  :search-input.sync="searchPlace"
                  item-text="place_name"
                  no-filter
                  clearable
                  hide-no-data
                  @click:clear="clearSearchPlaces"
                  :rules="inputRules"
                  outlined
                  full-width
                ></v-autocomplete> -->
                <v-textarea
                  class="text-fields DialogInputfield"
                  outlined
                  placeholder="7887 Harber Walks"
                  dense
                  v-model="getCompanyProfile.street_address"
                  hide-details="auto"
                  rows="3"
                  row-height="15"
                >
                </v-textarea>
                <!-- <v-textarea
                  outlined
                  v-model="getCompanyProfile.street_address"
                  :rules="inputRules"
                  class="DialogInputfield"
                  name="address"
                  hide-details="auto"
                  rows="2"
                >
                </v-textarea> -->
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">Country</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.county"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">State</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.state"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">City</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.city"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="pb-3 pt-1">
                <label class="text-item-label">Zip Code</label>
                <v-text-field
                  outlined
                  dense
                  :rules="inputRules"
                  v-model="getCompanyProfile.zip_code"
                  class="text-fields DialogInputfield"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions style="padding-left: 11px; margin-top: 5px;border-top: 3px solid #ebf2f5;">
            <!-- <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn @click="updateCompanyProfileApi" :disabled="isSaving" class="btn-blue" text>
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        v-if="isSaving"
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Update Profile
                    </span>
                  </span>
                </span>
              </v-btn>

              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col> -->
            <div class="buttonActions d-flex justify-start align-center pr-0 px-3" style="width: 100%;">
              <v-btn @click="updateCompanyProfileApi" :disabled="isSaving" class="btn-blue" text>
                <span class="btn-color">
                  <span color="#fff">
                    <span>
                      <v-progress-circular
                        v-if="isSaving"
                        :size="15"
                        color="#fff"
                        :width="2"
                        indeterminate
                        style="margin-right: 3px"
                      >
                      </v-progress-circular>
                      Update Profile
                    </span>
                  </span>
                </span>
              </v-btn>
              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import VueTagsInput from '@johmun/vue-tags-input';
import axios from 'axios';
import globalMethods from '../../../../../utils/globalMethods';

const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  components: {
    VueTelInputVuetify,
    VueTagsInput,
  },
  props: ["dialogEdit"],
  data: () => {
    return {
      getCompanyProfileTest: {
        name: "jhon Doe",
        general_email: "help@domain.com",
        dot_number: "1231234343",
        mc_number: "4324232323",
        phone_number: "+923369624425",
        street_address: "vill kinggergali tehsil dagger",
        county: "some where",
        state: "kpk",
        city: "Buner",
        zip_code: 2500,
      },
      loadingLocationPlaces: false,
      errorFound: 0,
      addressChanged: false,
      places: [],
      errorMessage: "",
      errorContent: "",
      errorSaving: 0,
      searchPlace: "",
      valid: true,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      numberRules: [
        (v) => !!v || "Input is required.",
        (v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed.",
      ],
      telProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Type phone number",
        label: "Type phone number",
        autocomplete: "off",
        maxLen: 25,
        preferredCountries: ["US", "CN"],
        enabledCountryCode: true,
        dropdownOptions: {
          showDialCodeInSelection: true,
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      arrayNotEmptyRules: [
        (v) => !!v || "Email is required",
        () => this.deliveryOrderTag.length > 0 || "Make sure to supply at least 1 email." 
      ],
      tagsValidation: [
        {
          classes: 't-new-tag-input-text-error',
          rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
          disableAdd: true
        }
      ],
      deliveryOrderNotification: {
        emails: []
      },
      deliveryOrderToggle: true,
      deliveryOrderTag: [],
      deliveryOrderEmail: '',
      tagsInput: {
        touched: false,
        hasError: false,
        errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        //errorMessage: ''
      },      
      documentProto: document,
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters("profile", ["getLoading", "getCompanyProfile"]),
  },
  watch: {
    dialogEdit(c) {
      if (!c || c) {
        this.errorFound = 0;
        this.getCompanyProfileAsync();
      }
    },
    getdetails(newValue) {
      if (newValue.status === 1) {
        this.$emit("close");
      }
    },
    searchPlace(val) {
      if (val) {
        this.getDeliveryLocation(val);
      }
    },
    getCompanyProfile(v){
      if(v.do_email_recipients){
        let doEmail = JSON.parse(v.do_email_recipients);
        if(doEmail.emails && doEmail.emails.length > 0){
          let em = [];
          doEmail.emails.map((email) => {
            em.push({
              text: email,
              tiClasses: ["ti-valid"]
            });
          });
          this.deliveryOrderTag = em;
          this.deliveryOrderNotification.emails = doEmail.emails;
        }
      }
    }
  },
  methods: {
    ...mapActions("profile", ["updateCompanyApiRequest","getCompanyProfileAsync"]),
    ...globalMethods,
    close() {
      this.$emit("close");
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            const customInput = [
              {
                place_name: val,
                id: Date.now(),
              },
            ];
            this.places = [...json.features, ...customInput];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;

            const customInput = [
              {
                place_name: val,
                id: Date.now(),
              },
            ];
            this.places = customInput;
            console.log(e);
          });
      }
    },
    clearSearchPlaces() {
      this.places = [];
      this.selectedPlace = "";
    },

    // TODO: Form validation before submission.
    updateCompanyProfileApi() {
      this.isSaving = true;
      if (this.$refs.form.validate()) {
        let deliveryOrderNotifEmails = [];
        if(this.deliveryOrderTag.length > 0){
          this.deliveryOrderTag.map(({text}) => {
            deliveryOrderNotifEmails.push(text);
          });
        }else{
          this.deliveryOrderToggle = false;
        }
        let deliveryOrderNotifValue = {
          emails: deliveryOrderNotifEmails,
        }

        this.getCompanyProfile.do_email_recipients = JSON.stringify(deliveryOrderNotifValue);

        axios.put(`${APIBaseUrl}/update-company-profile/${this.getCompanyProfile.id}`, this.getCompanyProfile)
          .then((res) => {
            if (res.status === 200) {
              this.getCompanyProfileAsync()
              this.dispatchNotifcation(res.data.message)
              this.$emit("close");
            }
            this.isSaving = false;
          })
          .catch((err) => {
            this.dispatchNotifcationError(err.message)
            this.isSaving = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../../assets/scss/pages_scss/drivers/driverAddDialog.scss";
.v-autocomplete__content.v-menu__content {
  border-radius: 4px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
