<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="590px"
      content-class="add-supplier-dialog add-driver-dialog  dialogYard"
      :retain-focus="false"
    >
      <v-card class="add-supplier-card">
        <v-form
          id="createdriver"
          ref="form"
          v-model="valid"
          action="#"
          @submit.prevent=""
        >
          <v-card-title style="caret-color: transparent">
            <span class="headline">New Yard </span>

            <button icon dark class="btn-close" @click="close">
              <v-icon>mdi-close</v-icon>
            </button>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col
                style="color: red"
                v-if="errorFound"
                cols="12"
                sm="12"
                md="12"
                class="pb-3 pt-1"
              >
                {{ errorMessage }}
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pb-3 pt-1">
                <label class="text-item-label">Name</label>
                <v-text-field
                  outlined
                  dense
                  placeholder="Enter Yard Name"
                  :rules="inputRules"
                  v-model="name"
                  name="name"
                  hide-details="auto"
                  style="font-size: 14px !important"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-2" md="12">
                <!-- <label class="text-item-label">Address</label>
                <v-textarea
                  placeholder="Enter Yard Address"
                  outlined
                  :rules="inputRules"
                  v-model="newYard.address"
                  name="address"                 
                  hide-details="auto"         
                  style="min-height:76px;
                         font-size:14px !important;"
                >
                </v-textarea> -->
                <label class="text-item-label">Address</label>
                <v-autocomplete
                  :loading="loadingLocationPlaces"
                  v-model="mapLocation"
                  :search-input.sync="searchPlace"
                  placeholder="Enter Yard Address"
                  :items="places"
                  background-color="white"
                  item-text="place_name"
                  no-filter
                  :rules="inputRules"
                  clearable
                  outlined
                  hide-details="auto"
                  dense
                  @click:clear="clearSearchPlaces"
                  @change="changeDeliveryLocationState"
                  hide-no-data
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            style="
              padding-left: 4px;
              padding-left: 11px;
              margin-top: 5px;
              border-top: 2px solid #ebf2f5;
            "
          >
            <!-- <v-col class="text-left buttonActions" style="padding-right: 0px">
              <v-btn class="btn-blue" text @click="save">
                <span>
                  <span color="#fff">
                    <v-progress-circular
                      :size="15"
                      color="#fff"
                      :width="2"
                      indeterminate
                      v-if="getCreateYardLoading"
                      style="margin-right: 3px"
                    >
                    </v-progress-circular>
                    Add
                  </span>
                </span>
              </v-btn>

              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
            </v-col> -->
            <div class="text-right buttonActions" style="padding:0px">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 ml-4">
                              <v-btn class="btn-blue" text @click="save">
                                <span>
                                  <span color="#fff">
                                    <v-progress-circular
                                      :size="15"
                                      color="#fff"
                                      :width="2"
                                      indeterminate
                                      v-if="getCreateYardLoading"
                                      style="margin-right: 3px"
                                    >
                                    </v-progress-circular>
                                    Add
                                  </span>
                                </span>
                              </v-btn>
                            </div>
                            <div class="col-md-4">
                              <v-btn class="btn-white" text @click="close"> Cancel </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["dialogData", "dialog"],
  data: () => {
    return {
      name: "",
      address: "",
      mapLocation: "",
      searchPlace: "",
      places: [],
      callApiForDeliveryLoc: true,
      loadingLocationPlaces: false,
      errorMessage: "",
      errorFound: 0,
      check: "",
      errorContent: "heeee",
      errorSaving: 0,
      valid: false,
      inputRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      isFetching: true,
    };
  },

  computed: {
    ...mapGetters({
      getCreateYardLoading: "yards/getCreateYardLoading",
      get_alert_box_text: "yards/get_alert_box_text",
      getdetails: "yards/getdetails",
    }),
  },
  watch: {
    dialog(c) {
      if (!c) {
        this.$refs.form.resetValidation();
      } else {
        this.errorMessage = "";
      }
    },
    searchPlace(val) {
      if (val && this.callApiForDeliveryLoc) {
        this.getDeliveryLocation(val);
      }
    },
  },
  methods: {
    ...mapActions("yards", ["addNewYard"]),
    close() {
      this.setDefault();
      this.$emit("close");
    },
    closeForm() {
      if (this.getCreateYardLoading) {
        this.setDefault();
        this.$emit("close");
      }
    },
    async save() {
      if(this.$refs.form.validate()){

        if (!this.places.length > 0) return;
      const selectedPlace = this.places.find(
        (element) => element.place_name === this.mapLocation
      );

      this.errorFound = 0;
      if (this.$refs.form.validate()) {
        const newYard = {
          name: this.name,
          address: this.mapLocation,
          map_location: this.mapLocation,
          longitude: selectedPlace.geometry
            ? selectedPlace.geometry.coordinates[0]
            : null,
          latitude: selectedPlace.geometry
            ? selectedPlace.geometry.coordinates[1]
            : null,
        };
        await this.addNewYard(newYard);
        // await this.addNewYard(this.newYard);
        if (this.getdetails.status) {
          this.close();
          this.$emit("syncAction", {
            created: 0,
            msg: this.get_alert_box_text,
          });
        } else {
          this.errorFound = 1;
          this.errorMessage = this.getdetails.msg;
        }
      }
      }
    },
    async getDeliveryLocation(val) {
      if (val.length >= 3) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
    setDefault() {
      this.name = "";
      this.address = "";
      this.mapLocation = "";
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #b4cfe0;
  border-width: 1px;
}
</style>
