var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplier-content-wrapper driver-content-wrapper"},[_c('div',{staticClass:"driverHeader",staticStyle:{"background":"none !important","display":"block !important","padding-bottom":"0px"}},[_c('div',{staticClass:"classHeadTop"},[_c('router-link',{staticClass:"gobacknow",attrs:{"to":"/drivers/01"}},[_c('img',{attrs:{"width":"6.5px","src":require("@/assets/icons/arrowLeft.svg")}}),_vm._v(" Go Back ")])],1),_c('v-toolbar',{staticClass:"containerdetailheader"},[(_vm.completed)?_c('v-toolbar-title',{staticClass:"titleheader"},[_c('span',{staticClass:"nameheader",staticStyle:{"font-size":"24px","width":"auto","margin-right":"12px","display":"inline-block","color":"#4A4A4A"}},[_c('span',{on:{"click":_vm.showLegs}}),_vm._v(" "+_vm._s(_vm.driver_name)+" ")]),[(_vm.getLocFrom !== 'notactive')?_c('span',[(_vm.connectedStatus==1)?_c('span',{staticClass:"connectedDriver commonDriverStatus"},[_vm._v(" Connected ")]):_c('span',{staticClass:"notconnectedDriver commonDriverStatus"},[_vm._v(" Not Connected ")])]):_c('span',[(_vm.activatedStatus)?_c('span',{staticClass:"notactiveDriver commonDriverStatus"},[_vm._v(" Activated ")]):_c('span',{staticClass:"notactiveDriver commonDriverStatus"},[_vm._v(" Deactivated ")])]),_c('span',{staticClass:"doprodownreport",staticStyle:{"background":"none !important"}},[_c('span',{class:_vm.checktheclass()},[_c('span',{staticClass:"scheduletext"},[_vm._v(" Notifications ")]),_c('span',{staticClass:"ON_text"},[_vm._v(" "+_vm._s(_vm.onAndOffText())+" ")]),_c('span',[_c('v-menu',{attrs:{"nudge-width":138,"nudge-left":"126","nudge-top":"-10","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"position":"relative","top":"4.1px"}},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/Chevron Down2.svg")}})])]}}],null,false,1813876802)},[_c('v-list',[_c('div',{staticClass:"dropdownreport"},[_c('ul',{staticClass:"listmenu"},[_c('li',{staticStyle:{"text-align":"center"},on:{"click":_vm.ONandOFF}},[_c('span',{staticClass:"headtextlist sendreport"},[_vm._v(" "+_vm._s(_vm.onAndOffDropdown())+" ")])])])])])],1)],1)])])]],2):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"btn-white",staticStyle:{"margin-right":"6px"},attrs:{"color":"primary"},on:{"click":_vm.sendEmail}},[_c('img',{staticStyle:{"margin-right":"4px"},attrs:{"src":require("@/assets/icons/editpencil.svg")}}),_vm._v(" Send Email ")]),_c('v-menu',{attrs:{"offset-y":"","nudge-width":"130","nudge-left":"130"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-white"},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/more-blue.svg"),"alt":""}})])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"trucking_DropDown",staticStyle:{"padding-left":"10px"}},[(_vm.getLocFrom !== 'notactive')?_c('v-list-item-title',{on:{"click":function($event){return _vm.runAction(_vm.connectedStatus ? 'Disconnect' : 'Connect')}}},[_vm._v(" "+_vm._s(_vm.connectedStatus ? 'Disconnect' : 'Connect')+" ")]):_vm._e(),_c('v-list-item-title',{on:{"click":function($event){return _vm.runAction(_vm.activatedStatus ? 'Deactivate' : 'Activate')}}},[_vm._v(" "+_vm._s(_vm.dropdownStatus)+" ")])],1)],1)],1)],1),(_vm.completed)?_c('div',{staticClass:"Headerbottomtext",staticStyle:{"background":"none !important","position":"relative","top":"-15px","font-size":"12px"}},[_c('span',{staticClass:"reportscheduletag"},[_c('span',{staticStyle:{"color":"#6D858F","font-style":"normal","font-weight":"600","font-size":"12px"}},[_vm._v("DRIVER ID")]),_c('span',{staticClass:"shiflkey"},[_vm._v(_vm._s(_vm.driver_id !=='' || _vm.driver_id !=null ? '#'+_vm.driver_id : ''))])]),_c('span',{staticClass:"telephoneholder"},[_c('img',{attrs:{"src":require("@/assets/icons/phone.svg")}}),_c('span',{staticClass:"shiflkey"},[_vm._v(_vm._s(_vm.phone_number))])]),_c('span',{staticClass:"emailholder"},[_c('img',{attrs:{"src":require("@/assets/icons/email.svg")}}),_c('span',{staticClass:"shiflkey"},[_vm._v(_vm._s(_vm.getemailsdata))])])]):_vm._e()],1),_c('v-data-table',{staticClass:"suppliers-table driver-table elevation-1",attrs:{"headers":_vm.headers,"mobile-breakpoint":"400","items":_vm.drivers,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('i',{staticClass:"classsort"})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',[_c('v-tab',[_vm._v("Schedule History")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.name !== "" ? item.name + " " + item.last_name : "--"))])]}},{key:"item.shiflcontainer",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.containers(item))}})]}},{key:"item.pickup_scheduled_date_time",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.pickupon(item))}})]}},{key:"item.delivery_scheduled_date_time",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.deliveryon(item))}})]}},{key:"no-data",fn:function(){return [(_vm.getDriverLoading)?_c('div',{staticClass:"no-data-preloader mt-4"},[(_vm.getDriverLoading)?_c('v-progress-circular',{attrs:{"size":40,"color":"#0171a1","indeterminate":""}}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)}),((typeof _vm.drivers !='undefined'))?_c('Pagination',{attrs:{"pageData":_vm.page,"lengthData":_vm.pageCount,"isMobile":_vm.isMobile},on:{"update:pageData":function($event){_vm.page=$event},"update:page-data":function($event){_vm.page=$event},"update:lengthData":function($event){_vm.pageCount=$event},"update:length-data":function($event){_vm.pageCount=$event}}}):_vm._e(),_c('Dialog',{attrs:{"dialogData":_vm.dialogHolder,"driverID":_vm.driverID,"bus":_vm.bus,"fetchCompleted":_vm.fetchCompleted},on:{"update:dialogData":function($event){_vm.dialogHolder=$event},"update:dialog-data":function($event){_vm.dialogHolder=$event},"update:fetchCompleted":function($event){_vm.fetchCompleted=$event},"update:fetch-completed":function($event){_vm.fetchCompleted=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }