<template>
    <v-dialog 
        persistent 
        v-model="dialog" 
        max-width="480px" 
        content-class="rejectDialog"  
        :retain-focus="false"
        >
        <v-card 
          style="padding-bottom:8px">
          <v-card-text 
            class="pt-5 pb-0 contentbody" 
            style="caret-color: transparent;
                   padding-bottom:0px !important"
            >            
            
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9999 43.6004C33.9292 43.6004 43.5999 33.9297 43.5999 22.0004C43.5999 10.071 33.9292 0.400391 21.9999 0.400391C10.0706 0.400391 0.399902 10.071 0.399902 22.0004C0.399902 33.9297 10.0706 43.6004 21.9999 43.6004ZM21.9999 40.3609C11.8597 40.3609 3.6394 32.1406 3.6394 22.0004C3.6394 11.8602 11.8597 3.63989 21.9999 3.63989C32.1401 3.63989 40.3604 11.8602 40.3604 22.0004C40.3604 32.1406 32.1401 40.3609 21.9999 40.3609ZM21.992 25.242C22.812 25.2425 23.4901 24.6336 23.5979 23.8431L23.6128 23.6233L23.6206 11.7417C23.6212 10.8471 22.8965 10.1215 22.0019 10.1209C21.1819 10.1203 20.5038 10.7293 20.396 11.5198L20.3811 11.7396L20.3733 23.6212C20.3728 24.5157 21.0975 25.2414 21.992 25.242ZM22.0009 32.796C23.192 32.796 24.1576 31.8304 24.1576 30.6392C24.1576 29.448 23.192 28.4824 22.0009 28.4824C20.8097 28.4824 19.8441 29.448 19.8441 30.6392C19.8441 31.8304 20.8097 32.796 22.0009 32.796Z" fill="#F93131"/>
            </svg>



            <h2 
              class="delivery_header"
              style="">Reject DO</h2>

            <p 
              style="padding-bottom: 0px;
                     margin-bottom: 5px;">
                    Please confirm that you have reviewed and rejected the delivery order request from the customer.
            </p>

            <p style="margin-top:19px;">
              <strong>Select a reason</strong><br>
              <span style="color:#6D858F;font-size:12px;">Customer will see reason for rejection.</span>
            </p>
            <div class="checkboxes dialogTextarea" v-if="refresh">
              <!-- <v-checkbox class="checkboxni" color="#4A4A4A" label="Upon Customer Request"></v-checkbox>
              <v-checkbox label="Unsupported Delivery Location"></v-checkbox>
              <v-checkbox label="Unsupported Pickup Location"></v-checkbox> 
              <v-checkbox @click="writemessage" label="Write my own message"></v-checkbox>  -->

              <div class="item-radio"><input type="radio"  @click="checkotherradio(1)" value="1" name="radio" class="radio-wrap"/> Upon Customer Request</div>
              <div class="item-radio"><input type="radio"  @click="checkotherradio(2)" value="2" name="radio" class="radio-wrap"/> Unsupported Delivery Location</div>
              <div class="item-radio"><input type="radio"  @click="checkotherradio(3)" value="3" name="radio" class="radio-wrap"/> Unsupported Pickup Location</div>
              <div class="item-radio"><input type="radio" @input="writemessage(4)" value="4" name="radio" class="radio-wrap"/> Write my own message</div>

              <div>                           
                 <v-textarea 
                  class="dialogTextareaReject"
                  no-resize
                  auto-grow
                  style="padding-left:4px;
                         resize:none !important;
                         border: 0px solid #B4CFE0;
                         border-radius: 4px; style-border:none !important" 
                  v-if="choosenwrite"
                  v-model="reasonmessage"
                  name="input-7-1"                  
                  value="The document you sent is not the correct one."                
                ></v-textarea>
              </div>
              <p>&nbsp;</p>
            </div>
            
          </v-card-text>
          <v-card-actions 
            style="border-top:0px;"
            >
            <v-btn 
                @click="continueDialog(item)" 
                class="btn-blue"
                >
                <span>
                    <span>
                       Reject
                    </span>
                </span>
            </v-btn>      
             <v-btn 
                @click="close" 
                class="btn-white"
                >
                <span>
                    <span>
                       Close
                    </span>
                </span>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
export default {
    name: 'Shownotification',
    data(){
      return {
        reasonmessage:'',
        reasonCode:0,
        choosenwrite:false,
        colorstrong: '#55a1c1',
        colorcontainers:'#4A4A4A',
        errotype:0,
        refresh:1,
      }
    },
    props: ['dialogData','item'], 
    computed: { 
        dialog: {
            get () {               
              return this.dialogData
            } ,
            set(value) {
                if (!value) {
                    this.$emit("update:dialogData",0);
                } else {
                    this.$emit("update:dialogData",1);
                }
            },       
        },                       
    },
    methods: {      
      async continueDialog() { 
          let formData = new FormData();
          let reason_1= 'Upon Customer Request'
          let reason_2= 'Unsupported Delivery Location'
          let reason_3= 'Unsupported Pickup Location'
          var reasontype = '';
          var reasonID=4;

          if(this.reasonCode == 1){
            reasontype = reason_1
            reasonID = 1;
          }else if(this.reasonCode==2){
            reasontype = reason_2
            reasonID = 2;
          }else if(this.reasonCode==3){
            reasontype = reason_3
            reasonID = 3;
          }else{
            reasontype = this.reasonmessage
          }

          let reason = {
            reason_id: reasonID,
            reason_text: reasontype
          }

          formData.append('do_id',this.item.id)
          formData.append('reason',JSON.stringify(reason))
        await axios
              .post(`${process.env.VUE_APP_PO_URL}/fe-rejectdo`, formData)
              .then((response) => {
                if(response){
                 this.$emit("submitreject");
                 }
              })
              .catch((error) => {
                  this.errorMessage = error.message;
                  console.error("There was an error!", error);
              })

         this.$emit("closeDialog");
        },  
        close() {    
          this.refresh = 0;
          this.$nextTick(() => {
            this.refresh = 1;
          });        
          this.choosenwrite = false; 
          this.$emit("closeDialog"); 
        },
        checkotherradio(item){
          this.reasonCode = item
          this.choosenwrite = false;
        },
        writemessage(item){
          this.reasonCode = item
          this.choosenwrite = true;
        }            
    }, 
}
</script>
<style lang="scss">
@import '../../../../assets/scss/pages_scss/dialog/globalDialog.scss';
//@import '../../../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';
.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}
</style>
<style>
.checkboxni {
  color:#4A4A4A;
}
.containerstyle {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:10px
}
.containerUL{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px
}
.IDclass{
    margin-bottom:0px;
    padding-bottom:0px;
    margin-top:5px;
    margin-bottom:11px
}

.idulClass{
    padding-left:0px !important;
    margin:0px;
    display:flex;
    flex-wrap:wrap;
    font-size:13px;
}

.shipmentClass{
    padding-top:5px;
    padding-bottom:0px;
}

.delivery_header {
   margin-top: 7px;
margin-bottom: 9px
}

.checkboxes .v-input--checkbox {
  padding-top:0px !important;
  margin-top: 0px !important;
}

.checkboxes .v-input__control{
  height: 44px !important
} 

.checkboxes .v-input__control .v-input__slot label{
  color:#4A4A4A
} 

.checkboxes .v-input__control .v-text-field__slot input{
  color:#4A4A4A !important;
}
.checkboxes .v-textarea .v-input__control .v-text-field__slot textarea{
  height: 60px !important;
  color: #4A4A4A !important;
  padding: 7px 7px 11px;
  font-size: 13px !important;
  line-height: 17px;
  border: 1px solid rgb(180, 207, 224);
  border-radius: 4px;
}

.item-radio {
  padding-bottom: 10px !important;
}

.v-dialog__content .dialogTextarea .v-text-field  .v-input__control  .v-input__slot::after{
  border-style: none !important; 
}
</style>