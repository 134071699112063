<template>
  <div class="ssupplier-container yard-container common-container-el">
    <!-- View All Yard Items  -->
    <YardDesktopTable
      v-if="renderComponent"
      :isMobile="isMobile"
      @addYardDialog="addYardDialog"
      @editYard="editYardItem($event)"
      @showDeleteDialog="showDeleteDialog($event)"
    />

    <!-- Add Yard Item  -->
    <YardDialog
      @setToDefault="setToDefault"
      @syncAction="updateddialog"
      :dialog="dialog"
      @close="close"
    />

    <!-- Edit Yard Item  -->

    <YardEditDialog
      @setToDefault="setToDefault"
      :dialogEdit="dialogEdit"
      :editYardItemList="editYardItemList"
      @syncAction="updateddialog"
      @close="close"
    />

    <!-- Delete Yard Item  -->

    <YardDeleteDialog
      :deleteYardItemList="deleteYardItemList"
      :dialogDelete="dialogDelete"
      @syncAction="updateddialog"
      @setToDefault="setToDefault"
      @close="close"
    />
  </div>
</template>

<script>
import YardDesktopTable from "../components/Tables/Yards/YardDesktopTable.vue";
import YardDialog from "../components/YardComponents/YardDialog.vue";
import YardDeleteDialog from "../components/YardComponents/YardDeleteDialog.vue";
import YardEditDialog from "../components/YardComponents/YardEditDialog.vue";
import globalMethods from "../utils/globalMethods";
export default {
  name: "YardsI",
  components: {
    YardDesktopTable,
    YardDialog,
    YardEditDialog,
    YardDeleteDialog,
  },
  data: () => ({
    renderadddialog: false,
    renderComponent: true,
    isMobile: false,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    isdeleted: 0,
    editYardItemList: {
      name: "",
      address: "",
    },
    deleteYardItemList: "",
  }),
  methods: {
    ...globalMethods,
    updateddialog(c) {
      let text = c.created ? c.msg : c.msg;
      if (c.created == 3) {
        this.showDeleteNotificationBox(
          '<i  class="ic-delete"></i>&nbsp;' +
            '<span style="margin-left:4px;" class="text-message">' +
            text +
            "!</span>"
        );
      } else {
        this.dispatchNotifcation(
          '<i style="font-size:11px" class="ic-check-mark"></i>&nbsp;' +
            '<span  style="margin-left:4px;" class="text-message">' +
            text +
            "!</span>"
        );
      }
    },
    onClickChild(value) {
      console.log(value); // someValue
      this.dai;
    },
    setToDefault() {
      this.close();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogEdit = false;
    },
    editYardItem(item) {
      this.dialogEdit = true;
      this.editYardItemList = Object.assign({}, item);
    },
    addYardDialog() {
      this.dialog = true;
    },
    showDeleteDialog(item) {
      this.deleteYardItemList = item;
      this.dialogDelete = true;
    },
  },
  async mounted() {
    this.$store.dispatch("page/setPage", "yards");
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages_scss/yard/yard.scss";
@import "../assets/scss/pages_scss/yard/yard_css.scss";
@import "../assets/scss/buttons.scss";
@import "../assets/css/dialog_styles/dialogHeader.css";
@import "../assets/css/dialog_styles/dialogBody.css";
@import "../assets/css/dialog_styles/dialogFooter.css";
.v-select--is-menu-active {
  background: #fff !important;
}
</style>
