<template>
    <v-dialog persistent scrollable v-model="dialogEdit" max-width="590px" content-class="add-customer-dialog add-c" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">            
            <v-card-title>
                <span class="headline">Edit Customer</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" id="updateCustomer" v-model="valid" action="#" @submit.prevent="">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <p v-if="errorSaving" style="color:red">{{errorContent}}</p>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-2 pt-0">
                            <label class="text-item-label">Name <span style="color: red;">*</span></label>
                            <v-text-field
                                placeholder="Enter Full Name"
                                outlined
                                class="text-fields DialogInputfield"
                                :class="hasCustomerError ? 'error-field' : ''"
                                v-model="editedItem.name"
                                hide-details="auto">
                            </v-text-field>

                            <p class="mb-0 p-form-errors-customer" style="height: 12px; line-height: 12px;">
                                {{ hasCustomerError ? "Input is required" : "" }}                                
                            </p>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-4 pt-0">
                            <label class="text-item-label">Phone Number</label>
                            <!-- <vue-tel-input-vuetify
                                class="text-fields DialogInputfield"
                                type="number"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :valid-characters-only="true"
                                :rules="numberRules"
                                v-bind="telProps"
                                v-model="editedItem.phone_number"/> -->

                            <vue-tel-input
								v-model="editedItem.phone_number"
								mode="international"
								defaultCountry="us"
								validCharactersOnly
								:autoDefaultCountry="true"
								:dropdownOptions="vueTelDropdownOptions"
								:inputOptions="vueTelInputOptions">
								
								<template v-slot:arrow-icon>
									<v-icon class="ml-1">mdi-chevron-down</v-icon>
								</template>
							</vue-tel-input>
                        </v-col>

                        <v-col class="pb-2 pt-2" cols="12" sm="12" md="12">
                            <label class="text-item-label">Email Address</label>
                            <div class="tags-email-wrapper mb-1">
                                <vue-tags-input
                                    hide-details="auto"
                                    :rules="arrayNotEmptyRules"
                                    :tags="options"
                                    :add-on-blur=true
                                    :add-on-key="[13, ',']"
                                    :validation="tagsValidation"
                                    v-model="warehouseEmailAddress"
                                    class="DialogInputfield"
                                    placeholder="Enter Email Address"
                                    @tags-changed="newTags => {
                                        this.options = newTags
                                        this.tagsInput.touched = true
                                        this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                        if (typeof el !== 'undefined') {
                                            if (this.tagsInput.hasError)
                                                el.classList.add('ti-new-tag-input-error')
                                            else
                                                el.classList.remove('ti-new-tag-input-error')
                                        }
                                    }"
                                />
                            </div>

                            <span style="color: #819FB2; font-size: 12px;">
                                Press the "Enter" or "," key in your keyboard to confirm the email address
                            </span>

                            <div style="height: 14px;">
                                <div v-if="tagsInput.touched" class="v-text-field__details">
                                    <div class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div v-if="(options.length > 0) && warehouseEmailAddress!==''" class="v-messages__message">
                                                {{
                                                    tagsInput.errorMessage
                                                }}
                                            </div>

                                            <div v-if="options.length == 0 && warehouseEmailAddress!==''" class="v-messages__message">
                                                {{
                                                    tagsInput.errorMessage
                                                }}
                                            </div>
                                            <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                Please provide at least 1 valid email address.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <label class="text-item-label">Company key</label>
                            <v-text-field
                                placeholder="Company Key"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="editedItem.central_customer_key"
                                :rules="companyRules"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions style="border-top: 2px solid #EBF2F5 !important;">
                <div class="buttonActions d-flex justify-start align-center pr-0" style="width: 100%;">
                    <v-btn class="btn-blue" text @click="save">
                        <span>
                            <span>
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px" >
                                </v-progress-circular>
                                Update
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                        Cancel
                    </v-btn>
                </div>
            </v-card-actions>           
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
// import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import VueTagsInput from '@johmun/vue-tags-input';

import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"
import jQuery from 'jquery'
import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL

export default {
    name: 'SupplierDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData', 'getCurrentPage'],
    components: {
        // VueTelInputVuetify,
        VueTagsInput,
        VueTelInput
    },
    data: () => ({
        errorContent:'',
        checkerrror: 'naaerror',
        isFetching:0,
        errorSaving:0,
        customeridhere: 1222,
        currentEditID:'',
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "Input is required."
        ],
        emailRules:[
            //(v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        companyRules: [
            (v) => v.split(' ').filter(function(n) { return n != '' }).length <= 1 || "Company key should not contain any spaces!"
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 16,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        numberRules: [
            //(v) => !!v || "Input is required.",
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        customersEmail:'',
        tagsValidation: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        documentProto: document,
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        warehouseEmailAddress: '',
        arrayNotEmptyRules: [
            //(v) => !!v || "Email is required",
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        validEmailAddress:'touchmealex@gmail.com',
        hasCustomerError: false,
        vueTelDropdownOptions: {
			showDialCodeInSelection: true,
			showDialCodeInList: true,
			showFlags: true,
            showSearchBox: false,
			// showSearchBox: true,
		},
		vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: false,
		},
    }),
    computed: {
        ...mapGetters({
            getCustomers: 'customers/getCustomers',
            getCustomersLoading: 'customers/getCustomerLoading'
        }),
        dialogEdit: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        },
         optionsFiltered: {
            get() {
                let validEmailAddress = []
                if (this.editedItem.emails !== null && this.editedItem.emails.length > 0) {
                    this.editedItem.emails.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    })
                }
                return validEmailAddress
            },
            set(value) {
                this.options = value
            }
        },

    },
    methods: {
        ...mapActions({
             fetchCustomersv2: 'customers/fetchCustomersv2'
        }),
        ...globalMethods,
        generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
        },

        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$refs.form.resetValidation()
            this.$emit('update:dialogData', false)
            this.hasCustomerError = false
        },
        async save() {
            if (this.editedItem.name === '') {
                this.hasCustomerError = true
            } else {
                this.hasCustomerError = false
            }

            if (!this.tagsInput.touched)
                this.tagsInput.touched = true
            this.$refs.form.validate()
            this.tagsInput.hasError = (this.options.length > 0) ? false : false

            //this.generateErrorMessage()
            setTimeout(async () => {
                if (this.$refs.form.validate() && !this.hasCustomerError) {
                    if (!this.tagsInput.hasError) {
                        try {
                            jQuery('.ti-new-tag-input').trigger(
                                jQuery.Event( 'keyup', { keyCode: 13, which: 13 } )
                            )

                            let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                            return o.text
                            }) : []

                            await this.updateCustomer(finalEmailAddress)

                        } catch (e) {
                            this.notificationError(e)
                        }
                    }
                }
            }, 200)
        },
        setToDefault() {
            this.$emit('setToDefault')
        },
       async updateCustomer(emailaddress){
            let dis =  this;
            let formData = new FormData(document.getElementById('updateCustomer'))
            formData.append('name', this.editedItem.name)
            formData.append('central_customer_key', this.editedItem.central_customer_key)
            formData.append('email', emailaddress);
            formData.append('pnumber',this.editedItem.phone_number)
            this.isFetching = 1;
            var id = this.editedItemData.customer_id;
            axios.post(`${APIBaseUrl}/updateCustomer/${id}`,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                if(typeof error.error !=undefined){
                    this.errorContent = error.msg
                }
            }).then(response => {
                if(response){
                    dis.notificationMessageCustomSuccess("Customer has been updated.")
                    dis.$emit('update:notifyupdate', 1);
                    dis.close()

                    if (this.$router.currentRoute.name === "Customer-Details") {
                        this.$emit("callUpdatedDetails")
                    } else {
                        this.fetchCustomersv2(this.getCurrentPage);
                    }
                    
                    if(dis.errorContent !=''){
                        dis.errorSaving = 1;
                    }
                }
                dis.isFetching = 0;
            })
        },
        emailchecker(email){
           var  filter = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            if (filter.test(email)) {
                return true;
            }else{
                return false;
            }
        }
    },
    watch: {
         tagsInput(value) {
            if ( value.hasError) {
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            } else {
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            }
        },
        dialogEdit(value, oldValue) {
            this.tagsInput.hasError = false
            this.tagsInput.touched = false
            jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            if (typeof el!=='undefined') {
                let el = document.getElementsByClassName('ti-input')[0]
                el.classList.remove('ti-new-tag-input-error')
            }

            if ( !value ) {
                this.options = []
                this.warehouseEmailAddress = ''
            } else if(value && !oldValue) {
                if (this.editedIndex==-1) {
                    this.options = []
                } else {
                    let validEmailAddress = []
                    if (this.editedItem.emails !== null && Object.keys(this.editedItem.emails).length > 0) {
                        var obj = this.editedItem.emails;
                        var result = Object.keys(obj).map((key) => [Number(key), obj[key]]);
                        result.forEach((value) => {
                             if(this.emailchecker(value[1])){
                                validEmailAddress.push({text: value[1],tiClasses:["ti-valid"]})
                             }
                        })
                    }
                    this.options = validEmailAddress
                }
            }
        }
    },
    updated() {
        // remove dash in modal edit
        if(this.editedItem.phone_number == '--'){
            this.editedItem.phone_number = '';
        }

        if(this.editedItem.central_customer_key == '--'){
            this.editedItem.central_customer_key = '';
        }

        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/customers/customerAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.p-form-errors-customer {
  color: #ff5252; 
  font-size: 12px; 
  font-family: 'Inter-Regular', sans-serif;
  margin-top: 8px;
  margin-bottom: 0 !important;
}

.taho {
    border:2px solid red !important;
}
</style>
