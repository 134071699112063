<template>
	<div class="supplier-content-wrapper">
		<div class="" style="background:none !important">
			<router-link class="gobacknow mb-2" to="/customers" style="color: #0171a1 !important;">
				<img width="8px" height="10px" src="@/assets/icons/arrowLeft.svg"> <span class="ml-2">Go Back</span>
			</router-link>
			<v-toolbar class="containerdetailheader customer">
				<v-toolbar-title class="titleheader customer">
					<div class="d-flex align-center">
						<span class="nameheader"
							style=" font-size:24px;
									width: auto;
									margin-right:12px;
									color:#4A4A4A;
									font-family: 'Inter-Medium', sans-serif !important;">
							<span>
								<!-- <img src="@/assets/icons/IconArrowleft.svg"> -->
							</span> {{ company_name_details }}
						</span>
					</div>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<!-- <v-btn
					color="primary"
					class="btn-white"
					@click="editCustomer"
				>
					<img
						style="margin-right:4px;"
						src="@/assets/icons/editpencil.svg"
					> Edit Customers
				</v-btn> -->

				<span style="cursor: not-allowed;">
					<v-btn 
						color="primary" 
						class="btn-white" 
						style="margin-right:6px; pointer-events: none;"
						@click="sendEmail"
						disabled>
						<span class="d-flex align-center">
							<img style="margin-top:0;" src="@/assets/icons/email_icon_send.svg" class="mr-1" width="16px" height="12px">
							Send Email
						</span>
					</v-btn> 
				</span>
				

				<v-menu offset-y nudge-width="130" nudge-left="130">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" class="btn-white" style="min-width: 40px; width: 40px !important;">
							<img src="@/assets/icons/more-blue.svg" alt="" />
						</v-btn>
					</template>

					<v-list>
						<v-list-item style="min-height: 35px;" @click="editCustomer">              
							<v-list-item-title style="color: #4a4a4a; font-size: 14px !important;" class="d-flex align-center">
								<img src="@/assets/icons/editpencil.svg" style="top:0;" class="mr-2" width="12px" height="12px"> 
								Edit Customer
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>   

				<!-- <v-btn
						style="margin-left:4px;"
						color="primary"
						class="btn-white"
						@click.stop="addCustomer">
						<img style="margin-right:4px;"
						src="@/assets/icons/email_icon_send.svg">
						Send Email
					</v-btn> -->
			</v-toolbar>
			<div class="Headerbottomtext customer mb-4">
				<span class="reportscheduletag" v-if="getcompanykey !== null && getcompanykey !== '' && getcompanykey !== '--'">
					<span style="color:#6D858F;
								font-style: normal;
								font-weight: 600;
								font-size: 12px;">SHIFL KEY</span>
					<span class="shiflkey pr-1">{{getcompanykey}}</span>
				</span>
				<span class="telephoneholder-customer px-2" v-if="getphone !== ''">
					<img src="@/assets/icons/phone.svg">
					<span class="shiflkey">{{getphone}}</span>
				</span>
				<span class="emailholder-customer" v-if="getemailsdata !== ''">
					<img src="@/assets/icons/email.svg">
					<span class="shiflkey pr-1">{{getemailsdata}}</span>
				</span>
				<span class="emailholder pl-1" v-if="getHashId">
					<a class="shiflkey pl-0" @click="copyVisibilityLink"> Copy Visibility Link </a>
				</span>

				<v-tooltip v-if="hidebutton" top>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" class="doprodownreport">
							<span :class="the_checkclass">
								<span class="scheduletext"> Report Scheduling </span>
								<span class="ON_text">
									<!-- {{getManageReporttext()}} -->
									{{ ON_TEXT }}
								</span>
								<span>
									<v-menu offset-y nudge-width="240" nudge-left="230" nudge-top="-7" 
										content-class="report-scheduling-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on" class="d-flex justify-center align-center">
												<img src="@/assets/icons/Chevron Down2.svg">
											</span>
										</template>

										<v-list flat>										
											<div class="dropdownreport">
												<ul class="listmenu">
													<li>
														<span @click="sendReport" class="headtextlist sendreport"> 
															Send Report
														</span>
													</li>
													<li v-if="allDataresponse!=''" class="listmenuborderline">
														<!-- <p class="list_item" style="font-size:10px;"> 
															Last sent on : {{allDataresponse}}
														</p> -->

														<p style="font-size:10px; color: #6D858F;" class="pt-1 mb-3"> 
															Sent last time: {{ allDataresponse }}
														</p>
													</li>
													<li @click="manageschedule" class="cmanage">
														<span class="cmon_item">Manage Schedule </span>
													</li>

													<li class="cmanage">
														<span @click="reportdownload" class="cmon_item">
															Download Report 
														</span>
													</li>
												</ul>
											</div>
										</v-list>
									</v-menu>
								</span>
							</span>
						</span>
					</template>
					<span class="tooltip">
						<span
							class="tooltiptext"
							style="font-size:10px;"
						> Sent last time: {{allDataresponse}}
						</span>
					</span>
				</v-tooltip>
			</div>
		</div>
		<v-data-table
			:headers="headers"
			mobile-breakpoint="769"
			:items="databaheader"
			class="table_customer elevation-1"
			:page.sync="page"
			:items-per-page="itemsPerPage"
			@page-count="pageCount = $event"
			hide-default-footer
			fixed-header
			:loading="false"
			:class="{mobile: isMobile}"
			:custom-sort="customSort"
		>
			<template v-slot:[`header.eta`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:[`header.terminal.address`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:[`header.dispatch.legs`]="{ header }">
				<div class="d-flex justify-space-between align-center">{{ header.text }}<i class="classsort-icon"></i></div>
			</template>
			<template v-slot:top>
				<v-tabs class="tabcontainers">
					<v-tab class="title-tab">Containers <span
							class="numbericon"
							style="border-radius:40px;background:#0171A1;color:#fff;"
						>
							{{databaheader.length}}
						</span>
					</v-tab>
				</v-tabs>
			</template>
			<!-- <template v-slot:[`item.address`]="{ item }">
					<span class="tets"
						v-html="check_ref_containers(item)">
					</span><br />
				</template>  -->
			<!-- <template v-slot:[`item.thedriver`]="{ item }">
					<span
						v-html="getdriverhtml(item.thedriver)">
					</span>
				</template>  -->
			<!-- <template v-slot:[`item.terminal.address`]="{ item }">
					<span v-html="callpickupon(item,1)"></span>
				</template>  -->
			<!-- <template v-slot:[`item.dispatch.legs`]="{ item }">
						<span
						v-html="callpickupon(item,0)">
					</span>
				</template>  -->
			<template v-slot:body="{ items }">
				<tbody v-if="items.length > 0">
					<tr
						@click="showAlert(item)"
						v-for="item in items"
						:key="item.id"
					>
						<td>
							<span
								class="tets"
								v-html="check_ref_containers(item)"
							>
							</span><br />
						</td>
						<td>
							<span>
								{{ getDateFormated(item.eta) }}
							</span>
						</td>
						<td>
							<span v-html="getdriverhtml(item.thedriver)">
							</span>
						</td>
						<td>
							<span v-html="callpickupon(item,1)"></span>
						</td>
						<td>
							<span v-html="callpickupon(item,0)">
							</span>
						</td>
					</tr>
				</tbody>
				<!-- <tbody v-else>
					<tr>
						<td style="text:center" colspan="12">
							<div style="width:100%;text-align:center;">
								<v-progress-circular
									:size="40"
									color="#0171a1"
									indeterminate
									v-if="hidebutton==0"
								>
								</v-progress-circular>
								<div v-if="hidebutton" style="color: #4a4a4a;"> No data available </div>
							</div>
						</td>
					</tr>
				</tbody> -->
			</template>
			<template v-slot:no-data>
				<div class="no-data-preloader mt-4">
					<v-progress-circular
						:size="40"
						color="#0171a1"
						indeterminate
					>
					</v-progress-circular>
				</div>
			</template>
		</v-data-table>
		<Pagination
			v-if="databaheader.length !==0"
			:pageData.sync="page"
			:lengthData.sync="pageCount"
		/>
		<sendReport
			@updatetooltip="updatetooltip"
			:dialogData="dialogData"
			@close="closeSendreport"
			:data="dataDialog"
		/>
		<ManageReport
			:dialogData="dialogManage"
			:tooggle.sync="toggleonandoff"
			:toggleswitch.sync="metavalue"
			@close="closeManageDialog"
		/>
		<CustomerEditDialog
			:notifyupdate.sync="notifyupdate"
			:editedIndexData.sync="editedIndex"
			:dialogData.sync="dialogEdit"
			:editedItemData.sync="editedItem"
			@callUpdatedDetails="callUpdatedDetails"
		/>
	</div>
</template>
<script>
import axios from "axios";
import {
	mapActions,
	mapGetters
}
from 'vuex'
import Pagination from '../../Pagination.vue'
const APIBaseUrl = process.env.VUE_APP_PO_URL
import sendReport from './dialog/sendReport.vue'
import ManageReport from './dialog/ManageReport.vue'
import CustomerEditDialog from '../../CustomerComponents/CustomerEditDialog.vue'
import _ from "lodash";
import moment from "moment";
import globalMethods from '../../../utils/globalMethods'

export default {
	name: "CustomerDesktopTable",
	props: ['reloadcomponent',
            'items',
            'drivers',
            'companykey',
            'phone',
            'emails',
            'companyname'],
	components: {
		Pagination,
		sendReport,
		ManageReport,
		CustomerEditDialog
	},
	data() {
		return {
			detailsReport: {},
			driverdetails: this.drivers,
			hidebutton: 1,
			the_checkclass: 'off_value',
			ON_TEXT: 'OFF',
			getcompanykey: '',
			getphone: '',
			databaheader: [],
			getemailsdata: '',
			getHashId: null,
			tago: 0,
			reload: 0,
			company_name_details: '',
			hideme: 0,
			hasshipment: true,
			allDataresponse: '',
			hasData: false,
			checkdisable: true,
			refreshclass: 0,
			refreshmanage: 0,
			metavalue: [{
				"toggle": 0,
				"copy": 0
			}],
			toggleonandoff: 0,
			editedIndex: -1,
			notifyupdate: 0,
			dialogEdit: false,
			editedItem: {
				company_name: "",
				phone: "",
				address: "",
				emails: null,
				central_customer_key: ""
			},
			dataDialog: {
				name: this.companyname,
				email: this.emails
			},
			dialogData: false,
			dialogManage: false,
			typeGet: 'Customer',
			thedriver: '',
			isMobile: true,
			page: 1,
			startfetching: 1,
			pageCount: 0,
			itemsPerPage: 9,
			headers: [
				{
					text: "Container/Ref#",
					align: "start",
					value: "address",
					sortable: false,
					width: "10%",
					fixed: true
        },
				{
					text: "ETA",
					align: "start",
					value: "eta",
					sortable: true,
					width: "8%",
					fixed: true
				},
				{
					text: "Schedule ID/Driver",
					align: "start",
					sortable: false,
					value: "thedriver",
					width: "10%",
					fixed: true
        },
				{
					text: "Pickup On",
					align: "start",
					value: "terminal.address",
					sortable: true,
					width: "16%",
					fixed: true
        },
				{
					text: "Delivery On",
					align: "start",
					value: "dispatch.legs",
					sortable: true,
					width: "16%",
					fixed: true
        },

      ],
			search: '',
			Customers: [],
		}
	},
	computed: {
		...mapGetters({
			getCustomers: 'customers/getCustomers',
			getCustomersLoading: 'customers/getCustomerLoading',
			getEditviewData: "customers/getViewDataCustomers",
			getmanagereportschedule: "customers/getmanagereportschedule",
			getLastSentReport: 'customers/getLastSentReport',
			getlastsentreportcount: 'customers/getlastsentreportcount',
			getdetailsdata: 'customers/getdetailsdata',
			getCustomerdata: 'customers/getcustomerdetails',
		}),
		on_add_off: {
			get() {
				return this.getlastsentreportcount ? true : false;
			}
		},
		getdefault: {
			get() {
				var metavalue = this.metavalue;
				if(this.refreshmanage) {
					return this.metavalue;
				}
				else {
					if(typeof this.getmanagereportschedule != 'undefined') {
						if(Object.keys(this.getmanagereportschedule).length > 0) {
							Object.values(this.getmanagereportschedule).map((value) => {
								try {
									metavalue = JSON.parse(value[0].meta_value);
								}
								catch (error) {
									metavalue = {
										"state": false,
										"copy": false
									};
								}
							});
						}
					}
				}
				return metavalue;
			}
		},
		getManageReport: {
			get() {
				// var metavalue = this.metavalue;
				// // IF NOT ZERO
				// if(this.refreshmanage){
				//     return this.metavalue.state ? 'ON' : 'OFF'
				// }else{
				//     if(typeof this.getmanagereportschedule !='undefined'){
				//         if(Object.keys(this.getmanagereportschedule).length > 0){
				//             Object.values(this.getmanagereportschedule).map((value) =>{
				//                 //console.log(value[0].status)
				//                 try{
				//                    // metavalue = JSON.parse(value[0].meta_value);
				//                     metavalue = value[0].status; //metavalue['state'];
				//                 }catch(error){
				//                     metavalue = 0;
				//                 }
				//             });
				//         }
				//     }
				//     return  (metavalue) ? 'ON' : 'OFF';
				// }
				return 'OFF';
			}
		},
		header2() {
			var theresult;
			if(this.items.length) {
				var dis = this;
				Object.values(this.items).map(function(f) {
					if(f.dispatch.legs.length) {
						_.map(Object.values(f.dispatch.legs), function(o) {
							if(o.type == dis.typeGet) {
								theresult = dis.getCustomerdata.drivers.filter(e => o.driver_id == e.id);
								if(theresult.length) {
									f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
								}
							}
						});
					}
				});
			}
			return this.items;
		},
		databaheader3: {
			get() {
				var theresult;
				if(this.items.length) {
					var dis = this;
					Object.values(this.items).map(function(f) {
						if(f.dispatch.legs.length) {
							_.map(Object.values(f.dispatch.legs), function(o) {
								if(o.type == dis.typeGet) {
									theresult = dis.driverdetails.filter(e => o.driver_id == e.id);
									if(theresult.length) {
										f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
									}
								}
							});
						}
					});
				}
				return this.items;
			},
			set(value) {
				return value;
			}
		},
	},
	watch: {
		toggleonandoff(c) {
			this.metavalue = c;
			this.refreshmanage = (c.state != true) ? 3 : 4
			this.refreshclass = 1;
			//console.log(c)
			this.ON_TEXT = c.state == true ? 'ON' : 'OFF';
			this.the_checkclass = c.state == true ? 'reportscheduletag' : 'off_value';
			this.hideme = 1
			this.$nextTick(() => {
				this.hideme = 0;
			});
			//console.log(c)
		},
	},
	created() {},
	methods: {
		...mapActions({
			fetchCustomers: 'customers/fetchCustomers',
			getManageReportSetting: 'customers/getManageReportSetting',
			//fetchLastSentReport: 'customers/fetchLastSentReport'
		}),
		...globalMethods,
		sendEmail() {},
		copyVisibilityLink() {
			let dis = this
			navigator.clipboard.writeText(`${window.location.origin}/customer-visibility/${this.getHashId}/containers`).then(function() {
				dis.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Customer Visibility Link Copied!</span>')
				console.log('Async: Copying to clipboard was successful!');
			}, function(err) {
			console.error('Async: Could not copy text: ', err);
			});
		},
		getDateFormated(date){
			if(date)
				return moment(date).utc(true).format('MMM DD YYYY')
			return "--"
		},
		getDateTimeFormated(date){
			if(date)
				return moment(date).utc(true).format('h:mmA, MMM DD YYYY')
			return "--"
		},
		// header
		drivercolumn(items) {
			// console.log(this.drivers)
			var theresult;
			if(items.length) {
				var dis = this;
				Object.values(items).map(function(f) {
					if(f.dispatch.legs.length) {
						//console.log(f.dispatch.legs)
						_.map(Object.values(f.dispatch.legs), function(o) {
							if(o.type == dis.typeGet) {
								theresult = dis.driverdetails.filter(e => o.driver_id == e.id);
								if(theresult.length) {
									f.thedriver = o.unique_id == null ? '--' + '==' + theresult[0].name : o.unique_id + '==' + theresult[0].name
								}
							}
						});
					}
				});
				return items;
			}
			return items;
		},
		updatetooltip(response) {
			if(Object.keys(response.sent_last_time).length) {
				this.allDataresponse = (response.sent_last_time !== '') ? new Date(response.sent_last_time).toLocaleString() : '';
			}
		},
		getManageReporttext() {
			var metavalue = this.metavalue;
			// IF NOT ZERO
			if(this.refreshmanage) {
				return this.metavalue.state ? 'ON' : 'OFF'
			}
			else {
				if(typeof this.getmanagereportschedule != 'undefined') {
					if(Object.keys(this.getmanagereportschedule).length > 0) {
						Object.values(this.getmanagereportschedule).map((value) => {
							//console.log(value[0].status)
							try {
								// metavalue = JSON.parse(value[0].meta_value);
								metavalue = value[0].status; //metavalue['state'];
							}
							catch (error) {
								metavalue = 0;
							}
						});
					}
				}
				return (metavalue) ? 'ON' : 'OFF';
			}
		},
		doprodownreport() {
			return 'showhide'
		},
		checkclass() {
			this.signalnow = 0;
			var metavalue = this.metavalue;
			if(this.refreshclass == 1) {
				this.signalnow = 1;
				return (metavalue.state) == 1 ? 'reportscheduletag' : 'off_value';
			}
			else {
				if(typeof this.getmanagereportschedule != 'undefined') {
					if(Object.keys(this.getmanagereportschedule).length > 0) {
						Object.values(this.getmanagereportschedule).map((value) => {
							try {
								//metavalue = JSON.parse(value[0].meta_value);
								metavalue = value[0].status; //metavalue['state'];
							}
							catch (error) {
								metavalue = 0;
							}
						});
					}
				}
				this.signalnow = 1;
				return (metavalue) ? 'reportscheduletag' : 'off_value';
			}
		},
		manageschedule() {
			this.dialogManage = true;
		},
		closeManageDialog() {
			this.dialogManage = false;
		},
		closeSendreport() {
			this.dialogData = false;
		},
		addCustomer() {
			this.$emit('addCustomer')
		},
		async editCustomer() {
			let array = [];
			if(typeof this.getEditviewData.items == 'undefined') {
				await axios.get(`${APIBaseUrl}/getCustomerById/` + this.$route.params.id).then((response) => {
					return response;
				}).catch(error => {
					this.response = error;
				}).then(response =>  {
					if(typeof response != 'undefined') {
						response.data.forEach((value) => {
							var text = value.emails;
							const myArray = text.split(",");
							let m = {
								customer_id: value.id,
								name: value.compnay_name,
								phone_number: value.phone == null ? '--' : value.phone,
								central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
								hashId: value.hashId,
								emails: Object.assign({}, myArray),
								emailsaddress: value.emails == '' ? '--' : value.emails,
							}
							array.push(m)
						});
						this.editedItem = Object.assign({}, array[0]);
						this.dialogEdit = true;
					}
				})
			} else {
				this.editedItem = Object.assign({}, this.getEditviewData.items);
				this.dialogEdit = true;
			}
		},
		deleteCustomer(item) {
			this.$emit('deleteCustomer', item)
		},
		editItem(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		sendReport() {
			this.dialogData = true;
		},
		async customerListing() {
			let array = [];
			var letme = this;
			await axios.get(`${APIBaseUrl}/customersList`).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					response['data'].forEach((value) => {
						var text = value.emails;
						const myArray = text.split(",");
						let m = {
							customer_id: value.id,
							central_customer_key: value.central_customer_key ? value.central_customer_key : '--',
							hashId: value.hashId,
							name: value.compnay_name,
							phone_number: value.phone == null ? '--' : value.phone,
							emails: Object.assign({}, myArray),
							emailsaddress: value.emails == '' ? '--' : value.emails,
						}
						array.push(m)
					});
					letme.Customers = array;
				}
				this.startfetching = false
			})
		},
		showdropdown() {
			this.menu = true;
		},
		getemails(emails) {
			let lenghkeys = Object.keys(emails).length;
			let newkeys = [];
			if(Object.keys(emails).length) {
				let i = 0;
				for(i = 0; i < lenghkeys; i++) {
					newkeys.push(emails[Object.keys(emails)[i]].email)
				}
				let unshuffled = newkeys;
				let shuffled = unshuffled.map(value => ({
					value,
					sort: Math.random()
				})).sort((a, b) => a.sort - b.sort).map(({
					value
				}) => value)
				return shuffled[0];
			}
			return '';
		},
		getdriverhtml(item) {
			var var3 = item;
			if(typeof var3 != 'undefined') {
				const str = var3.split("==");
				if(typeof str[0] != "undefined" && typeof str[1] != "undefined") var mvar = (str[0] == null) ? '-' : str[0];
				return ('<span class="ref font-medium">' + mvar + '</span><br><span class="containerID">' + str[1] + "</span>");
			}
			return '-'
		},
		check_ref_containers(item) {
			var var3 = item.trucker_container + '-' + item.shifl_ref;
			const str = var3.split("-");
			if(typeof str[0] != "undefined" && typeof str[1] != "undefined") return ('<span class="ref font-medium">' + str[0] + '</span><br><span class="containerID">' + str[1] + "</span>");
		},
		goback() {
			this.$router.push("/customers");
		},
		deleteItem(item) {
			this.editedIndex = this.suppliers.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.suppliers.splice(this.editedIndex, 1);
			this.closeDelete();
		},
		toUpperCase(str) {
			return str.charAt(0).toUpperCase() + str.slice(1)
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		onResize() {
			if(window.innerWidth < 769) {
				this.isMobile = true
			}
			else {
				this.isMobile = false
			}
		},
		setToDefault() {
			this.editedItem = this.defaultItem
			this.close()
			this.dialog = true
		},
		callpickupon(item, type_loc) {
			let theaddress = '-';
			let _date_time = '-';
			let dispatch_legs = item.dispatch.legs
			let scheduled_or_actual = '';
			let dis = this;
			if(typeof item.dispatch.legs != 'undefined') {
				if(!_.isEmpty(item.dispatch.legs)) {
					let parr = (dispatch_legs)
					let getar = (parr)
					if(getar.length > 0) {
						_.map(Object.values(getar), function(o) {
							if(o.type == dis.typeGet) {
								if(type_loc) {
									// pickup location
									if(item.terminal === null) {
										theaddress = '-';
									}
									else {
										// theaddress = item.terminal.address;
										theaddress = 
											`${item.terminal.firms_code} | ${item.terminal.name} | ${item.terminal.map_location}`
									}
									if(o.arrival_at_pickup_location_date_time !== null) {
										let toggle = o.arrival_at_pickup_location_date_time;
										let newtime = moment(toggle).utc(true).format('h:mmA, MMM DD YYYY');
										_date_time = newtime;
									}
									else {
										// check if arrival_at_delivery_location_date_time is not empty
										if(o.arrival_at_delivery_location_date_time == null) {
											scheduled_or_actual = '<span class="scheduled_tag">[Scheduled] </span>';
										}
										let newtime = moment(o.pickup_scheduled_date_time).utc(true).format('h:mmA, MMM DD YYYY');
										_date_time = newtime;
									}
								}
								else {
									// delivery
									theaddress = o.address;
									if(o.arrival_at_delivery_location_date_time !== null) {
										// ang pickup location actual na, if scheduled ra sya walay arrival
										_date_time = moment(o.arrival_at_delivery_location_date_time).utc(true).format('h:mmA, MMM DD YYYY');
									}
									else {
										scheduled_or_actual = '<span class="scheduled_tag">[Scheduled] </span>';
										_date_time = moment(o.delivery_scheduled_date_time).utc(true).format('h:mmA, MMM DD YYYY');
									}
								}
							}
						});
					}
				}
			}
			return '<div>' + theaddress + '</div>' + '<span>' + scheduled_or_actual + _date_time + '</span>'
		},
		getdriver(item) {
			var dis = this;
			if(!_.isEmpty(item)) {
				if(item.length > 0) {
					var thedriver = '';
					_.map(Object.values(item), function(o) {
						if(o.type == dis.typeGet) {
							thedriver = o.driver;
						}
					});
					var getdriver = this.driverdetails.filter((find) => find.id == thedriver);
					return getdriver;
				}
				else {
					return '--';
				}
			}
			return '--';
		},
		async reportdownload() {
			axios({
				url: `${APIBaseUrl}/customer/directDownload/` + this.$route.params.id,
				method: "GET",
				responseType: "blob",
			}).then((response) => {
				window.URL = window.URL || window.webkitURL;
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data], {
					type: "application/octet-stream",
				}));
				let theDate = new Date().valueOf();
				link.setAttribute("download", "customer_report_" + theDate + "_.xlsx");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.snackbar = true;
			});
		},
		getPickupDeliveryDateTime(item, type_loc) {
			let _date_time = null;
			let dispatch_legs = item.dispatch.legs
			let dis = this;
			if(typeof item.dispatch.legs != 'undefined') {
				if(!_.isEmpty(item.dispatch.legs)) {
					let parr = (dispatch_legs)
					let getar = (parr)
					if(getar.length > 0) {
						_.map(Object.values(getar), function(o) {
							if(o.type == dis.typeGet) {
								if(type_loc) {
									if(o.arrival_at_pickup_location_date_time !== null) {
										_date_time = o.arrival_at_pickup_location_date_time
									}
									else {
										// check if arrival_at_delivery_location_date_time is not empty
										_date_time = o.pickup_scheduled_date_time
									}
								}
								else {
									// delivery
									if(o.arrival_at_delivery_location_date_time !== null) {
										// ang pickup location actual na, if scheduled ra sya walay arrival
										_date_time = o.arrival_at_delivery_location_date_time
									}
									else {
										_date_time = o.delivery_scheduled_date_time
									}
								}
							}
						});
					}
				}
			}
			return _date_time
		},
		customSort(items, index, isDesc) {
			if(index[0] != 'undefined'){
				const distantFuture = new Date(8640000000000000)
				items.sort((a, b) => {
					if(["dispatch.legs",  "terminal.address", "eta"].includes(index[0])){
							let aDate = null, bDate = null
							if(index[0] === "terminal.address") {
								aDate = this.getPickupDeliveryDateTime(a, 1)
								bDate = this.getPickupDeliveryDateTime(b, 1)
							}else if(index[0] === "dispatch.legs"){
								aDate = this.getPickupDeliveryDateTime(a, 0)
								bDate = this.getPickupDeliveryDateTime(b, 0)
							}else if(index[0] == "eta"){
								aDate = a.eta
								bDate = b.eta
							}
							aDate = aDate ? new Date(aDate) : distantFuture
							bDate = bDate ? new Date(bDate) : distantFuture
							if(isDesc[0] === true) return aDate.getTime() - bDate.getTime()
							else if(isDesc[0] === false) return bDate.getTime() - aDate.getTime()
					}

				});
			}

			return items
		},
		async callUpdatedDetails() {
			await axios.get(`${APIBaseUrl}/customer/details/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					if(Object.keys(response.data).length) {
						this.driverdetails = (response.data.driver);
						let itemreturn = this.drivercolumn(response.data.data);
						this.databaheader = itemreturn
						this.getcompanykey = response.data.company_key
						this.company_name_details = response.data.company_name;
						this.getHashId = response.data.hashId;
						this.getphone = response.data.company_phone;
						if(response.data.getemails.length) {
							this.getemailsdata = response.data.getemails[0].email
						}
						if(response.data.last_report_count) {
							//this.checkdisable = false
						}
						if(response.data.count) {
							//this.hasData = response.data.count
						}
					}
				}
			})
		}
	},
	async mounted() {
		let data = this.getdetailsdata;
		if(typeof data.items !== 'undefined') {
			//this.databaheader = data.items.details.data;
			let itemreturn = this.drivercolumn(data.items.details.data);
			this.databaheader = itemreturn
			this.company_name_details = data.items.name;
			this.getphone = data.items.phone_number;
			this.getcompanykey = data.items.central_customer_key;
			this.getemailsdata = data.items.details.getemails;
			this.getHashId = data.items.hashId;
			this.allDataresponse = (data.items.lastreport.data !== '') ? new Date(data.items.lastreport.data).toLocaleString() : 'None';
			if(typeof data.items.getManageReportSetting.data[0] != 'undefined') {
				this.ON_TEXT = data.items.getManageReportSetting.data[0].status == 1 ? 'ON' : 'OFF';
				this.the_checkclass = data.items.getManageReportSetting.data[0].status == 1 ? 'reportscheduletag' : 'off_value';
			}
		}
		else {
			this.hidebutton = 0;
			await axios.get(`${APIBaseUrl}/customer/details/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					//console.log(response);
					if(Object.keys(response.data).length) {
						this.driverdetails = (response.data.driver);
						let itemreturn = this.drivercolumn(response.data.data);
						this.databaheader = itemreturn
						this.getcompanykey = response.data.company_key
						this.company_name_details = response.data.company_name;
						this.getHashId = response.data.hashId;
						this.getphone = response.data.company_phone;
						if(response.data.getemails.length) {
							this.getemailsdata = response.data.getemails[0].email
						}
						if(response.data.last_report_count) {
							//this.checkdisable = false
						}
						if(response.data.count) {
							//this.hasData = response.data.count
						}
						//alldata = response.data.data
					}
					// this.hidefirst = 1
				}
			})
			//await this.fetchLastSentReport(this.$route.params.id);
			var alldata = '';
			await axios.get(`${APIBaseUrl}/getlastreport/` + this.$route.params.id).then((response) => {
				return response;
			}).catch(error => {
				this.response = error;
			}).then(response => {
				if(typeof response != 'undefined') {
					if(Object.keys(response.data).length) {
						if(response.data.last_report_count) {
							this.checkdisable = false
						}
						if(response.data.count) {
							this.hasData = response.data.count
						}
						alldata = response.data.data
					}
					// this.hidefirst = 1
				}
			})
			this.allDataresponse = (alldata !== '') ? new Date(alldata).toLocaleString() : '';
		}
		this.dataDialog.name = this.company_name_details
		this.dataDialog.email = this.getemailsdata
		// GET THE REPORT DATA SETTINGS
		await this.getManageReportSetting(this.$route.params.id);
		let dis = this;
		if(typeof this.getmanagereportschedule != 'undefined') {
			if(Object.keys(this.getmanagereportschedule).length > 0) {
				Object.values(this.getmanagereportschedule).map((value) => {
					try {
						if(value.length === 0) {
							dis.metavalue = {
								state: false,
								copy: false,
								frequency: 1,
								day: '',
								time: ''
							}
						}
						else {
							dis.metavalue = {
								state: (value[0].status) ? true : false,
								copy: value[0].copy ? true : false,
								frequency: value[0].frequency,
								day: value[0].day,
								time: value[0].time
							}
						}
						dis.ON_TEXT = dis.metavalue.state == true ? 'ON' : 'OFF';
						dis.the_checkclass = dis.metavalue.state == true ? 'reportscheduletag' : 'off_value';
						this.hidebutton = 1;
					}
					catch (error) {
						console.log(error)
					}
				});
			}
		}
	},
};
</script>
<style lang="scss">
.containerdetailheader.customer {
    background: none !important;
	height: auto !important;

    .v-toolbar__content {
        padding-left: 0 !important;
		height: auto !important;
		padding-bottom: 0 !important;

        .titleheader.customer {
            width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
        }
    }
}

.customer-wrapper {
	.v-data-table {
		&.table_customer {
			box-shadow: none !important;
			border: 1px solid #EBF2F5 !important;
			border-bottom: none !important;

			.v-data-table__wrapper {
				overflow-y: auto;
				height: calc(100vh - 338px);

				table {
					thead {
						tr {
							th {
								color: #819FB2 !important;
								font-size: 14px !important;
								padding: 8px 16px !important;
								height: 40px !important;
								box-shadow: none !important;
								border-bottom: 2px solid #EBF2F5 !important;

								span {
									color: #819FB2 !important;
									font-size: 14px !important;
								}
							}
						}
					}

					tbody {
						tr {
							td {
								border-bottom: 1px solid #EBF2F5 !important;
								padding: 10px 16px !important;
							}
						}
					}
				}
			}
		}
	}

	.pagination-wrapper {
		border: 1px solid #EBF2F5 !important;
	}
}

.Headerbottomtext.customer {
    font-size: 12px !important;
    margin-top: 4px;
    padding-left: 0;
	display: flex;
    justify-content: flex-start;
    align-items: center;
	height: 28px;

	.doprodownreport {
		padding-left: 8px;

		.reportscheduletag {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #EBFAEF;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #16B442;
			text-transform: uppercase;
			height: 28px;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #16B442;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}

		.off_value {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			padding: 4px 10px 4px 12px;
			border-radius: 50px;
			font-size: 10px;
			border: 1px solid #D8E7F0;
			text-transform: uppercase;
			height: 28px;

			.scheduletext {
				color: #4A4A4A;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}

			.ON_text {
				color: #6D858F;
				margin-left: 5px;
				margin-right: 8px;
				font-family: 'Inter-SemiBold', sans-serif !important;
			}
		}
	}
}

.telephoneholder-customer {
	padding-left: 5px;
	display: flex;
    justify-content: flex-start;
    align-items: center;

	img {
		width: 16px;
		height: 16px;
    }
}

.emailholder-customer {
	padding-left: 5px;
	display: flex;
    justify-content: flex-start;
    align-items: center;

	img {
		width: 17px;
		height: 17px;
    }
}

.titleheader {
    padding-left: 0;
}

.gobacknow {
    text-decoration: none !important;
    font-size: 14px;
}
.mdi-arrow-up {
    display: none !important;
}
.font-medium {
    color: #4A4A4A;
}
.containerID {
    color: #6D858F;
}
.classsort-icon {
    background: url("../../../assets/icons/Icon_sort.svg") no-repeat !important;
    width: 15px;
    height: 10px;
    display: block;
    // margin-left: 4px;
    // position: relative;
    // top: 3px;
    // left: 1px;
    // float: right;
}

.tabcontainers {
    border-bottom: 2px solid #EBF2F5;
}

.tabcontainers .title-tab {
	font-weight: unset !important;
}


.tabcontainers .title-tab.v-tab--active {
	color: #0171a1 !important;
	border-bottom: 2px solid #0171a1 !important;
}

.tabcontainers .v-tabs-slider-wrapper {
	display: none !important;
}

.title-tab {
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: none !important;
	letter-spacing: 0 !important;
	font-family: 'Inter-SemiBold', sans-serif !important;
}

.table_customer {
    margin-top: 0;
}

.table_customer table thead th {
    text-transform: none !important;
    font-size: 14px !important;
}
.shiflkey {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 6px;
    color: #4A4A4A;
}

.dropdownreport {
    padding: 2px 16px 0;
}
.headtextlist {
    font-size: 14px;
    color: #0171A1;
    cursor: pointer;
}
.listmenuborderline {
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    padding-bottom: 0;
    .list_item {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #6D858F;
        margin-bottom: 4px !important;
        padding-bottom: 6px;
    }
}
.listmenu {
    padding-left: 0 !important;
    .cmanage {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.cmon_item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.numbericon {
    border-radius: 40px;
    background: rgb(1, 113, 161);
    color: rgb(255, 255, 255);
    width: 20px;
    margin-left: 6px;
    font-size: 12px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.doprodownshow {
    display: inline-block;
}
.scheduled_tag {
    color: #6D858F;
    font-weight: 700;
    font-size: 14px;
}

.v-tooltip__content {
    background: #4A4A4A !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #4A4A4A transparent transparent transparent;

    }
}
</style>
<style>
.showhideme {
	display: inline-block !important;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #4A4A4A transparent transparent transparent;
}
</style>
