  <template>
  <div>
  <v-data-table
      :headers="headers"
      mobile-breakpoint="400"
      :items="drivers"
      class="suppliers-table driver-table elevation-1"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      fixed-header
    >
        <template v-slot:[`header.name`]="{ header }">
			{{ header.text }}<i class="classsort"></i>
		</template>
        <template v-slot:top>
        <v-toolbar flat>
            <Tab
                :pageCurrent="pageCurrent"
                @currentPage="currenttab"
                :countTab="countTab"
            />
          <v-spacer></v-spacer>
          <Search
            placeholder="Search Drivers"
            className="search custom-search"
            :inputData.sync="search"
          />
        </v-toolbar>
      </template>
      <!--
            <template v-slot:[`item.driver_id`]="{ item }">
                <p class="mb-0">{{ item.driver_id !== '' ? item.driver_id : '--' }}</p>
            </template>  -->

      <template v-slot:[`item.name`]="{ item }">
        <p class="mb-0">{{ item.name !== "" ? item.name + " " + item.last_name : "--" }}</p>
      </template>



      <template v-slot:[`item.email`]="{ item }">
        <p class="mb-0">{{ item.email !== "" ? item.email : "--" }}</p>
      </template>

      <!-- <template v-slot:[`item.phone_number`]="{ item }">
        <p class="mb-0">
          {{ item.phone_number !== "" ? item.phone_number : "--" }}
        </p>
      </template> -->


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            class="connect btn-white-driver"
            height="36"
            elevation="0"
            @click="actionDriver(item,'activate')"
        >Activate
        </v-btn>
        <v-menu offset-y nudge-width="100" nudge-left="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="truckerMoreBtn">
              <img src="@/assets/icons/more-blue.svg" alt="" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="trucking_DropDown">
              <v-list-item-title
                class="trucking_DropDownEdit"
                @click="editDriver(item, item.id)"
                >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L4.05846 10.7591C3.73929 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254548 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24092 7.94154L8.59893 0.58353C9.37697 -0.19451 10.6384 -0.19451 11.4165 0.58353ZM7.89447 2.69657L1.94531 8.64592C1.75381 8.83742 1.61796 9.07736 1.55227 9.3401L1.18306 10.8169L2.6599 10.4477C2.92263 10.382 3.16258 10.2462 3.35407 10.0547L9.30303 4.10513L7.89447 2.69657ZM9.30332 1.28791L8.59875 1.9923L10.0073 3.40085L10.7121 2.69668C11.1011 2.30766 11.1011 1.67693 10.7121 1.28791C10.3231 0.898893 9.69234 0.898893 9.30332 1.28791Z" fill="#6D858F"/>
                </svg>
                Edit
              </v-list-item-title>
              <v-list-item-title
                class=""
                @click="viewDriver(item, item.id)"
                >
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00044 2.88073C9.63611 2.88073 10.9621 4.25058 10.9621 5.94037C10.9621 7.63016 9.63611 9.00001 8.00044 9.00001C6.36478 9.00001 5.03881 7.63016 5.03881 5.94037C5.03881 4.25058 6.36478 2.88073 8.00044 2.88073ZM8.00044 4.0281C6.97815 4.0281 6.14942 4.88425 6.14942 5.94037C6.14942 6.99649 6.97815 7.85265 8.00044 7.85265C9.02273 7.85265 9.85147 6.99649 9.85147 5.94037C9.85147 4.88425 9.02273 4.0281 8.00044 4.0281ZM8.00044 0.200012C11.4163 0.200012 14.3651 2.60948 15.1832 5.98608C15.2577 6.29344 15.0769 6.60497 14.7794 6.6819C14.4819 6.75884 14.1803 6.57205 14.1059 6.2647C13.4107 3.39577 10.9039 1.34738 8.00044 1.34738C5.09577 1.34738 2.58813 3.39754 1.89419 6.26816C1.81988 6.57555 1.51843 6.76251 1.22088 6.68574C0.923328 6.60897 0.742357 6.29755 0.816666 5.99015C1.6334 2.61156 4.58307 0.200012 8.00044 0.200012Z" fill="#6D858F"/>
                </svg>
                View
              </v-list-item-title>
              <!-- <v-list-item-title
                class=""
                @click="sendEmail(item, item.id)"
                >
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.95 0.400024C14.1926 0.400024 15.2 1.36433 15.2 2.55387V9.44618C15.2 10.6357 14.1926 11.6 12.95 11.6H3.04999C1.80735 11.6 0.799988 10.6357 0.799988 9.44618V2.55387C0.799988 1.36433 1.80735 0.400024 3.04999 0.400024H12.95ZM14.3 3.81258L8.22815 7.23286C8.11079 7.29894 7.96994 7.30995 7.84475 7.2659L7.77183 7.23286L1.69999 3.8143V9.44618C1.69999 10.1599 2.3044 10.7385 3.04999 10.7385H12.95C13.6956 10.7385 14.3 10.1599 14.3 9.44618V3.81258ZM12.95 1.26156H3.04999C2.3044 1.26156 1.69999 1.84015 1.69999 2.55387V2.81406L7.99999 6.36179L14.3 2.81319V2.55387C14.3 1.84015 13.6956 1.26156 12.95 1.26156Z" fill="#6D858F"/>
                </svg>
                Send Email
              </v-list-item-title> -->
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

       <template v-slot:no-data>
        <div class="no-data-preloader mt-4" v-if="startfetching">
          <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
          >
          </v-progress-circular>
        </div>
        <div v-else>
            No data
        </div>
      </template>
    </v-data-table>

    <Pagination
      v-if="typeof drivers !== 'undefined' && drivers.length > 0"
      :pageData.sync="page"
      :lengthData.sync="countData"
      :isMobile="isMobile"
    />
    </div>
</template>
<script>
import { mapActions} from "vuex";
import Pagination from "../../../Pagination.vue";
import Search from "../../../Search.vue";
import Tab from "./tab.vue";
import globalMethods from '../../../../utils/globalMethods'
import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL
export default {
  name: "Connected",
  props: ["items",
          "isMobile",
          "itemData",
          "reloadcomponent",
          "pageCurrent",
          "startfetching",
          "allcount",
          'bus',
          'countTab'],
  components: {
      Pagination,
    Search,
    Tab
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 158,
    headers: [
        {
            text: "Name",
            align: "start",
            value: "name",
            sortable: true,
            width: "17%",
            fixed: true,
        },

        {
            text: "Email",
            align: "start",
            value: "email",
            sortable: false,
            width: "22%",
            fixed: true,
        },
        {
            text: "",
            align: "start",
            value: "actions",
            sortable: false,
            width: "8%",
            fixed: true,
        },
    ],
    DriverData: [],
    search: "",
  }),
  computed: {
    countData:{
        get(){
            return this.allcount
        }
    },
    drivers: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("items", value);
      },
    },
  },
  watch: {
    page(nv){
       this.$emit('update:page', nv)
    }
  },
  created() {},
  methods: {
    ...mapActions({
      passData: "drivers/passData",
      passLocFrom: "drivers/passLocFrom",
      detailNotification: "drivers/detailNotification",
    }),
    ...globalMethods,
    actionDriver(item,type){
        let url = '';
        console.log(item)
        if(type=='activate'){
            url = 'activateDriver';
        }else if(type =='deactivate') {
            url = 'deactivateDriver';
        }else{
            url = 'disconnectDriver';
        }
        axios
        .put(`${poBaseUrl}/`+url+'/'+item.id)
        .then((response) => {
            if(response){
                this.dispatchNotifcation(
                    '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
                    'Driver has been successfully ' + type + 'd' +
                    "</span>"
                );
            }
            this.$emit('reloadpage',2)
        })
        .catch((error) => {
            console.log(error);
        });
    },
    sendEmail(item,id){
      this.$emit('sendEmail',id);
    },
    currenttab(cur){
       this.$emit("currentPage",cur);
    },
    async viewDriver(item, id) {
        if(item){
          await this.passData(item)
          await this.detailNotification(item)
          await this.passLocFrom('notactive');
          this.$router.push("/drivers-details/" + id+"/03").catch(()=>{});
        }
    },
    editDriver(item, id) {
        this.bus.$emit('changeIt',{
          id:id,
          updatelisting:item,
          editPassdata:id,
          editDriver:item
        });
    },
  },
  async mounted() {
    //set current page
    this.$store.dispatch("page/setPage", "drivers");
  },
  updated() {},
};
</script>
