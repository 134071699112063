<template>
    <v-dialog persistent scrollable v-model="dialog" max-width="590px" content-class="add-customer-dialog add-c" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-card-title>
                <span class="headline">Add Customer</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" id="createCustomer" v-model="valid" action="#" @submit.prevent="">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <p v-if="errorSaving" style="color:red">{{errorContent}}</p>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-2 pt-0">
                            <label class="text-item-label">Name <span style="color: red;">*</span></label>
                            <v-text-field
                                placeholder="Enter Full Name"
                                outlined
                                class="text-fields DialogInputfield"
                                :class="hasCustomerError ? 'error-field' : ''"
                                v-model="customerName"
                                hide-details="auto">
                            </v-text-field>
                            <!-- :rules="rules" -->

                            <p class="mb-0 p-form-errors-customer" style="height: 12px; line-height: 12px;">
                                {{ hasCustomerError ? "Input is required" : "" }}                                
                            </p>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-4 pt-0">
                            <label class="text-item-label">Phone Number</label>
                            <!-- <vue-tel-input-vuetify
                                class="addextclass text-fields myphonelcass DialogInputfield"
                                type="number"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :valid-characters-only="true"
                                :rules="numberRules"
                                v-bind="telProps"
                                v-model="editedItem.phone" /> -->

                            <vue-tel-input
								v-model="editedItem.phone"
								mode="international"
								defaultCountry="us"
								validCharactersOnly
								:autoDefaultCountry="true"
								:dropdownOptions="vueTelDropdownOptions"
								:inputOptions="vueTelInputOptions">
								
								<template v-slot:arrow-icon>
									<v-icon class="ml-1">mdi-chevron-down</v-icon>
								</template>
							</vue-tel-input>
                        </v-col>

                        <v-col class="pb-2 pt-2" cols="12" sm="12" md="12">
                            <label class="text-item-label">Email Address</label>
                            <div class="tags-email-wrapper mb-1">
                                <vue-tags-input
                                    hide-details="auto"
                                    :rules="arrayNotEmptyRules"
                                    :tags="options"
                                    :add-on-blur=true
                                    :class="myclass"
                                    class="DialogInputfield"
                                    :add-on-key="[13, ',']"
                                    :validation="myclass.length > 0 ? tagsValidation : tagsValidation"
                                    v-model="warehouseEmailAddress"
                                    placeholder="Enter Email Address"

                                    @tags-changed="newTags => {
                                        this.myclass = 'noclass';
                                        this.options = newTags
                                        this.tagsInput.touched = true
                                        this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                        let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                        if (typeof el!=='udnefined') {
                                            /*if (this.tagsInput.hasError)
                                                el.classList.add('ti-new-tag-input-error')
                                            else
                                                el.classList.remove('ti-new-tag-input-error')*/
                                        }
                                    }"
                                />
                            </div>

                            <span style="color: #819FB2; font-size: 12px;">
                                Press the "Enter" or "," key in your keyboard to confirm the email address
                            </span>

                            <div style="height: 14px;">
                                <div v-if="tagsInput.touched" class="v-text-field__details">
                                    <div class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div v-if="(options.length > 0) && warehouseEmailAddress!==''" class="v-messages__message">
                                                {{
                                                    tagsInput.errorMessage
                                                }}
                                            </div>

                                            <div v-if="options.length == 0 && warehouseEmailAddress!==''" class="v-messages__message">
                                                {{
                                                    tagsInput.errorMessage
                                                }}
                                            </div>
                                            <!-- <div v-if="options.length == 0 && warehouseEmailAddress==''" class="v-messages__message">
                                                Please provide at least 1 valid email address.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </v-col>                        

                        <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                            <label class="text-item-label">Company Key</label>
                            <v-text-field
                                placeholder="Company Key"
                                outlined
                                class="text-fields DialogInputfield"
                                v-model="company_key"
                                :rules="companyRules"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions style="border-top: 2px solid #EBF2F5 !important;">
                <!-- <v-col class="text-right buttonActions" style="padding:0px" cols="6"> -->
                <div class="buttonActions d-flex justify-start align-center pr-0" style="width: 100%;">
                    <v-btn style="caret-color:transparent" class="btn-blue" text @click="save">
                        <span>
                            <span style="caret-color:transparent">
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    v-if="isFetching"
                                    style="margin-right:3px"
                                    >
                                </v-progress-circular>
                                Add Customer
                            </span>
                        </span>
                    </v-btn>
                    <v-btn class="btn-white" text @click="close" v-if="!isMobile">
                        Cancel
                    </v-btn> 
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
// import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue"
import VueTagsInput from '@johmun/vue-tags-input';

import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"

import globalMethods from '../../utils/globalMethods'
const APIBaseUrl = process.env.VUE_APP_PO_URL
import jQuery from 'jquery'
export default {
    name: 'SupplierDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData', 'getCurrentPage'],
    components: {
        // VueTelInputVuetify,
        VueTagsInput,
        VueTelInput
    },
    data: () => ({
        typeError:'', // type of error return from api
        myclass: '',
        isFetching:0,
        customerName:'',
        errorContent: '',
        company_key: '',
        errorSaving: 0,
        valid: true,
        telInputOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
        },
        options: [],
        value: [],
        isMobile: false,
        rules: [
            (v) => !!v || "Input is required."
        ],
        companyRules: [
            (v) => v.split(' ').filter(function(n) { return n != '' }).length <= 1 || "Company key should not contain any spaces!"
        ],
        emailRules:[
             //(v) => !!v || "Email is required.",
             v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        telProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Type phone number",
            label: 'Type phone number',
            autocomplete: "off",
            maxLen: 16,
            preferredCountries: ["US", "CN"],
            enabledCountryCode: true,
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true
            },
            validCharactersOnly: true
        },
        numberRules: [
            //(v) => !!v || "Input is required.",
            (v) => !v || /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed."
        ],
        tagsValidation: [
            {
            classes: 't-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        tagsValidation3: [
            {
            classes: 'ttt-new-tag-input-text-error',
            rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
            disableAdd: true
            }
        ],
        documentProto: document,
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        warehouseEmailAddress:'',
         arrayNotEmptyRules: [
            //(v) => !!v || "Email is required",
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email."
        ],
        customersEmail: '',
        hasCustomerError: false,
        vueTelDropdownOptions: {
			showDialCodeInSelection: true,
			showDialCodeInList: true,
			showFlags: true,
            showSearchBox: false,
			// showSearchBox: true,
		},
		vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: false,
		},
    }),
    computed: {
        ...mapGetters({
            getCustomers: 'customers/getCustomers',
            getCustomersLoading: 'customers/getCustomerLoading',
            //getCreateLoading: 'customers/getCreateCustomerLoading'
        }),
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        } ,
         className(){
            return 'taginpuerrorfound';
        } ,
        optionsFiltered: {
            get() {
                let validEmailAddress = []

                if (this.editedItem.emails !== null && this.editedItem.emails.length > 0) {
                    this.editedItem.emails.map(wea => {
                        if (wea!==null) {
                            validEmailAddress.push({text: wea,tiClasses:["ti-valid"]})
                        }
                    })
                }

                return validEmailAddress
            },
            set(value) {
                this.options = value
            }
        },
    },
    watch: {
        customerName(v) {
            if (v !== "") {
                if (this.hasCustomerError)
                    this.hasCustomerError = false                
            }
        }
    },
    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            fetchCustomersv2: 'customers/fetchCustomersv2',
            createCustomers:'customers/createCustomers',
        }),
         generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
        },
        ...globalMethods,
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$refs.form.resetValidation()
            this.tagsInput.touched = false
            this.options = [],
            this.customerName = ''
            this.company_key = ''
            this.warehouseEmailAddress = ''
            this.$emit('update:dialogData', false)
            this.hasCustomerError = false
        },
        async save() {
            if (this.customerName === '') {
                this.hasCustomerError = true
            } else {
                this.hasCustomerError = false
            }

            // check the movel
            if(this.warehouseEmailAddress !==''){
              this.myclass =  !this.checkvalidEmailblur(this.warehouseEmailAddress)
                ?  this.className : 'validinputs'
            }

            if (!this.tagsInput.touched)
                this.tagsInput.touched = true

            this.$refs.form.validate()
            this.tagsInput.hasError = (this.options.length > 0) ? false : false

            //this.generateErrorMessage()
            setTimeout(async () => {
                if (this.$refs.form.validate() && !this.hasCustomerError) {
                  if (!this.tagsInput.hasError) {
                    try {
                        jQuery('.ti-new-tag-input').trigger(
                            jQuery.Event( 'keyup', { keyCode: 13, which: 13 } )
                        )

                        let finalEmailAddress = (this.options.length > 0) ? this.options.map(o => {
                            return o.text
                        }) : []

                        await this.addCustomer(finalEmailAddress);

                    } catch (e) {
                        console.log(e)
                    }
                } else{
                    //alert('going else')
                }
                }
            }, 200)
        },

        setToDefault() {
            this.$emit('setToDefault')
        },
        async addCustomer(email){
            this.errorSaving = 0;
            var dis = this;
            // await this.createCustomers({
            //         name:this.customerName,
            //         email: email.join(),
            //         pnumber: this.editedItem.phone
            // });

            // this.dispatchNotifcation('<i class="ic-check-mark"></i>&nbsp;<span class="text-message">Customer has been added.</span>')

            // this.tagsInput.touched = 0;
            // this.$refs['form'].resetValidation()
            // this.options = [];
            // this.customerName = '';
            // this.$emit('update:notifysavedparent',1);
            // this.close()

            let formData = new FormData(document.getElementById('createCustomer'))
            formData.append('name', this.customerName)
            formData.append('central_customer_key', this.company_key)
            formData.append('email',email.join());
            formData.append('pnumber',this.editedItem.phone)
            this.isFetching = 1;
            await axios.post(`${APIBaseUrl}/createCustomer`,formData)
            .then((response) => {
                return response.data
            }).catch(error => {
                if(typeof error.type !=undefined) this.typeError = error.type
                if(typeof error.error !=undefined){
                    this.errorContent = error.msg
                }
            }).then(response => {
                dis.startloading =0;
                if(response){
                    dis.tagsInput.touched = 0;
                    dis.$refs['form'].resetValidation()
                    dis.options = [];
                    dis.customerName = '';
                    dis.company_key = ''
                    dis.$emit('update:notifysavedparent',1);
                    dis.close()
                    dis.notificationMessageCustomSuccess("Customer has been added.")
                    this.fetchCustomersv2(this.getCurrentPage) // fetch updated customer lists
                    // this.editedIndexData = -1
                }else {
                    if(dis.errorContent !=''){
                        dis.errorSaving = 1;
                    }
                }
                this.isFetching = 0;
            })
        },
        checkvalidEmailblur(mail){
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return mail.match(validRegex)
        }
    },
    updated() {

        if(this.dialog == 0){
            this.errorSaving = 0;
        }

        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/customers/customerAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.p-form-errors-customer {
  color: #ff5252; 
  font-size: 12px; 
  font-family: 'Inter-Regular', sans-serif;
  margin-top: 8px;
  margin-bottom: 0 !important;
}
</style>

<style scoped>
.text-field{
    color: red !important;
    /* add whatever properties you want */
}
</style>
